import React from "react";
import Link from "../Link";
import PropTypes from "prop-types";

const Card = (props) => {
  const [isEn, setIsEn] = React.useState(false);

  React.useEffect(() => {
    setIsEn(typeof window !== "undefined" && window.$isEn);
  }, []);

  const renderCardContent = () => {
    return (
      <Link
        className="fui-card-action"
        to={props.link}
        target={props.target}
        data-fet-key={props.fetKey}
        data-recommend-tracking={props["data-recommend-tracking"]}
      >
        {props.tag ? (
          <div className={`tag tag-${props.tag.color}`}>{props.tag.text}</div>
        ) : null}
        {props.image ? (
          <div className="fui-card-image">
            <img
              src={props.image}
              srcSet={props.retinaImage || props.image}
              alt={props.alt}
            />
          </div>
        ) : (
          ""
        )}
        <div className="fui-card-caption">
          <div className="fui-card-content">
            <h6 className="fui-card-title">
              {!props.icon ? (
                ""
              ) : (
                <div className="icon">
                  <img src={props.icon} alt={props.alt} />
                </div>
              )}
              <div className="text">{props.title}</div>
            </h6>
            {!props.description ? (
              ""
            ) : (
              <p
                className="fui-card-description"
                dangerouslySetInnerHTML={{ __html: props.description }}
              ></p>
            )}
            {!props.price ? (
              ""
            ) : (
              <h6
                className="fui-card-price"
                dangerouslySetInnerHTML={{ __html: props.price }}
              ></h6>
            )}
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className={`fui-card ${props.className ? props.className : ""}`}>
      {renderCardContent()}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  retinaImage: PropTypes.string,
  image: PropTypes.string,
  target: PropTypes.string,
  alt: PropTypes.string,
  meta: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  price: PropTypes.string,
  tag: PropTypes.shape({
    color: PropTypes.string,
    text: PropTypes.string,
  }),
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
};

export default Card;
