import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import CheckGroup from '../../components/form/CheckGroup';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Modal from 'react-modal';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

class AddressChangeSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.form = React.createRef();
    this.state = {
      detail: [
        {
          title: '住家電話',
          content: '02-8008***8'
        },
        {
          title: '公司電話',
          content: '02-7723***3'
        },
        {
          title: '行動電話',
          content: '無'
        },
        {
          title: '帳單住址',
          content: '台北市中和區****** '
        }
      ],
      // mergeDetail: {
      //   account: {
      //     title: '住家電話',
      //     content: '02-8008***8'
      //   },
      //   origin: {
      //     title: '公司電話',
      //     content: '02-7723***3'
      //   },
      //   merged: {
      //     title: '行動電話',
      //     content: '無'
      //   }
      // },
      type: 'success',
      description: `<h4 class="mb-1">變更完成</h4><p class="mt-0 mb-4">您已成功變更帳單地址/電話資訊，預計 <span class="is-text-accent">10 分鐘</span> 生效。</p>`,
      promotion: {
        title: '你的專屬優惠',
        cards: [
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
            title: '本月影片免費看!',
            description: '話題電影、日劇、韓劇等等應有盡有',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
            title: '中午不知道吃什麼？',
            description: 'Uber Eats 送遠傳新客優惠金200元',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
            title: '連假打算出國？',
            description: '遠遊卡吃到飽上網不再煩惱用量 ',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
            title: '本月影片免費看!',
            description: '話題電影、日劇、韓劇等等應有盡有',
            link: '#',
          },
        ]
      },
      isMember: true,
      step: 2,
      mergable: false,
      canSubmit: false,
      modalOpen: false,
      authCode: '',
      phoneNumber: '0926-899-868',
      account: '859909700',
      form: {
        account: { value: '', required: false },
      },
      mergeNumber: [
        { value: '0', label: '0916-000-995' },
        { value: '1', label: '0928-789-789' },
        { value: '2', label: '0912-477-477' },
        { value: '3', label: '0988-654-654' },
        { value: '4', label: '0988–092-000' },
        { value: '5', label: '0936-282-525' },
        { value: '6', label: '0936-282-525' },
        { value: '7', label: '03-1234567' }
      ],
      linkNumber: [
        {
          type: 'cellphone',
          number: '0936-123-995'
        },
        {
          type: 'cellphone',
          number: '0931-456-995'
        },
        {
          type: 'landline',
          number: '02-1234567'
        },
        {
          type: 'landline',
          number: '02-8887777'
        },
      ]
    }
  }

  onChange = (name, value) => {
    // console.log(`name, value: `, name, value);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }

  handleChange = () => {
    this.setState({
      authCode: this.input.current.value,
    });
  }

  submitAuthCode = () => {
    // console.log(`this.authCode: `, this.input.current.value);
    this.setState({
      modalOpen: false,
      step: 2
    })
  }
  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    this.setState({ canSubmit: false });
  }
  submit = model => {
    // console.log('submitted');
  };
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '#'
          }} />

        <section className="e-service-result is-success mt-4">
          <div className="fui-container">
            <Grid container>
              <Grid item xs={12}>
                {
                  this.state.type !== 'fail' ? (
                    <img src={'/resources/cbu/e-service/images/completed.png'} srcSet={'/resources/cbu/e-service/images/completed@2x.png'} alt='' />
                  ) : (
                      <img src={'/resources/cbu/e-service/images/failed.png'} srcSet={'/resources/cbu/e-service/images/failed@2x.png'} alt='' />
                    )
                }

                <div className="content">
                  <h4 className="mb-0 mt-3">{this.state.title || ''}</h4>
                  <p className='body1' dangerouslySetInnerHTML={{
                    __html: this.state.description || ''
                  }}></p>
                </div>
              </Grid>
            </Grid>
            <div className="paper">
              <h4 className='align-left'>變更明細</h4>
              <table className="text-wrap odd bill-table table-fixed">
                <tbody>
                  {this.state.detail.map((row, i) => {
                    return (
                      <tr key={row.title + i}>
                        <td className="align-center is-bg-white is-text-medium">{row.title}</td>
                        <td className="align-left  pl-md-5 pl-2">{row.content}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="button-group">
              <div className='fui-buttons two-buttons mt-md-5 mt-2 pt-2'>
                <Button className='' link="#" btnStyle='primary' size='large'>返回變更帳單地址/電話</Button>
                <Button className='' link="#" btnStyle="secondary" size='large'>回個人專區</Button>
              </div>
            </div>

          </div>
        </section>
        <SectionCarousel1 {...this.state.promotion} />
      </main >
    )
  }
}

export default AddressChangeSuccess;