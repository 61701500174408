import React from 'react';
import Tab from '../tab/Tab'
import TabPane from '../tab/TabPane'
import PropTypes from 'prop-types';

class PanelTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: this.props.activeTab ? this.props.activeTab : 0
        }

        this.tabChange = this.tabChange.bind(this);
        this.tabComponent = React.createRef();
    }

    rand() {
        return Math.ceil(Math.random() * 100)
    }

    tabChange(i, tab, dataSet) {
        this.setState({
            currentTab: i
        })
        return this.props.onClick ? this.props.onClick(i, tab, dataSet) : null;
    }

    render() {
        return (
            <div className={`fui-panel-tab ${!!this.props.className ? this.props.className : ''}`}>
                <Tab ref={this.tabComponent} {...this.props.tabs} default={this.props.activeTab} onChange={this.tabChange} />
                <div className="fui-tab-body" >
                    {
                        this.props.children.map((child, i) => (
                            <TabPane active={this.state.currentTab === i} key={`${this.props.tabs.name}-panel-tab-pane-${i}`}>
                                {child}
                            </TabPane>
                        ))
                    }
                </div>
            </div>
        )
    }
}

PanelTab.propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.shape({
        name: PropTypes.string,
        list: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired
            })
        )
    }),
    activeTab: PropTypes.number,
    onClick: PropTypes.func,
    tabContent: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.string
        })
    ),
}

export default PanelTab;