import React from 'react';
import PropTypes from 'prop-types';
import PanelList from '../panelContent/PanelList'

class ReceiptCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.radioButton = React.createRef();
    this.radioButton2 = React.createRef();
    this.collapse = React.createRef();
    this.addressInput = React.createRef();
    this.state = {
      layout: this.props.layout || 'record',
      open: false,
      minHeight: 0,
      collapseHeight: 0
    }
  }

  componentDidMount() {
    this.setCollapseHeight();

    window.addEventListener('resize', e => {
      this.setCollapseHeight()
    })
  }
  openCollapse = () => {
    this.setCollapseHeight();
    this.setState({ open: true })
  }

  openInvoice = () => {
    this.props.openInvoice()
  }

  setCollapseHeight() {
    this.setState({
      collapseHeight: this.collapse.current.children[0].clientHeight
    });
  }

  render() {
    return (
      <div className="receipt-collapse">
        <h4 className="date">{this.props.date}</h4>
        <div className="main">
          <h5>
            {this.props.name}
            <div onClick={this.openInvoice} className='d-inline ml-1' role="button"><i className="icon-information i-24"></i></div>
          </h5>
          {
            this.state.layout === 'record' ? (
              <div className="meta">
                <span>交易類型：{this.props.meta.type}</span><br className="d-block d-md-none" />
                <span>訂單編號：{this.props.meta.number}</span><br className="d-block d-md-none" />
                <span>發票號碼：{!this.props.meta.hasReceipt ? '不需開立發票' : '需開立發票'}</span>
              </div>
            ) : null
          }
          {
            this.state.layout === 'ticket' ? (
              <div className="meta">
                <span>載據編號：{this.props.meta.deviceNo}</span><br className="d-block d-md-none" />
                <span>中獎/兌換期限：{this.props.meta.expireAt}</span><br className="d-block d-md-none" />
                <span>發票類型：{this.props.meta.type == '索取紙本發票' ? <a onClick={() => this.props.openModal()} className='is-text-accent is-underline'>{this.props.meta.type}</a> : this.props.meta.type}</span><br className="d-block d-md-none" />
                <span>折讓單：{this.props.meta.debitNote}</span>
              </div>
            ) : null
          }
          <div className="fui-collapse">
            <div className="collapse-body" ref={this.collapse} style={{
              height: `${this.state.open ? this.state.collapseHeight : 0}px`
            }}>
              <PanelList prefix='bulleted' list={this.props.detail} />
            </div>
          </div>
        </div>
        <div className="extra">
          <div className="main">
            {
              this.state.open ? (
                <div role="button" onClick={e => this.setState({ open: false })}>
                  <span>收合詳細資訊</span>
                  <i className="icon-minus"></i>
                </div>
              ) : (
                  <div role="button" onClick={this.openCollapse}>
                    <span>點選看詳細資訊</span>
                    <i className="icon-plus"></i>
                  </div>
                )
            }
          </div>
          <div className="price">
            金額 <span className="amount">{this.props.amount} 元</span>
          </div>
        </div>
      </div >
    )
  }
}

ReceiptCollapse.propTypes = {
  date: PropTypes.string,
  name: PropTypes.string,
  layout: PropTypes.string,
  meta: PropTypes.object,
  amount: PropTypes.number,
  detail: PropTypes.array,
  openInvoice: PropTypes.func
}

export default ReceiptCollapse;