import React from 'react';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import PanelList from '../../components/panelContent/PanelList';
import LabelInput from '../../components/form/LabelInput';
import * as Validation from '../../utils/validation';

class ReplaceCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      canSubmit: false,
      isEmpty: false,
      form: {
        id_number: { value: '', required: true }
      }
    }
  }

  nextStep = () => {
    this.setState({
      step: this.state.step + 1
    })
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm,
    });
  }

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };

  submit = () => {
    this.props.history.push('/resultController/ResultPage', {
      type: 'success',
      pageName: '漫遊與門號服務',
      title: '申請完成',
      description: `您已完成申請，我們將迅速為您寄出新卡。提醒您，補卡將於下月帳單收取 300 元費用，謝謝！`,
      actions: [
        {
          text: '返回遺失補卡',
          link: '/roamingConfigController/ReplaceCard',
          btnStyle: 'primary'
        },
        {
          text: '返回個人專區',
          link: '#',
          btnStyle: 'secondary'
        }
      ]
    })
  }

  render() {
    const { step } = this.state;
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="遺失補卡"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="form-section">
          <div className="fui-container">
            <h4 className='is-text-darkgray50'>SIM卡申請掛失後，您可在網站上申請補卡，我們將迅速為您寄出新卡至帳單地址。(若需寄至其他地址請透過客服中心處理)</h4>
            <div className="paper mb-2">
              <h4 className='my-2'>補卡須知</h4>
              <PanelList
                prefix='bulleted'
                list={[
                  { text: '計費方式：於下月帳單中收取300元補卡費用' }
                ]}
              />
            </div>
            {
              step === 1 ? (
                <div>
                  {this.state.isEmpty ? <div className="paper">
                    <p className="is-text-darkgray50 m-0 body">
                      您尚未申請掛失服務，請先至下方進行掛失服務之申請，謝謝！
                    </p>
                  </div> : null}
                  <div className="page-action mt-7">
                    <Button btnStyle='primary' size='large' onClick={this.nextStep}>申請掛失服務</Button>
                  </div>
                </div>
              ) : null
            }
            {
              step === 2 ? (
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <h4 className='mt-5'>為保護您個人資料的安全，請輸入門號登記者的證照號碼進行確認</h4>
                      <LabelInput
                        className="mb-2 hide-required"
                        name='id_number'
                        required={this.state.form.id_number.required}
                        label='證照號碼'
                        validations={{
                          idNumberValid: Validation.idNumberValid
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入證照號碼'
                        }}
                        placeholder='請輸入證照號碼'
                        value={this.state.form.id_number.value}
                        onChange={this.onChange}
                      />
                      <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                        身分說明：<br />
                          個人戶：身分證字號<br />
                          公司戶：統一編號或稅籍編號<br />
                          外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                      </p>
                      <div className='page-action'>
                        <Button onClick={this.submit} className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>確認</Button>
                      </div>
                    </Grid>
                  </Grid>
                </Formsy>
              ) : null
            }
          </div>
        </section>

        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            若您的門號狀態為：欠費限話、暫停、損壞瑕疵補卡等情形，請至遠傳門市或來電客服中心處理。
          `}
        />
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ isEmpty: false })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.4.1.1_漫遊與門號服務_遺失補卡_主頁</Button>
              <Button onClick={() => this.setState({ isEmpty: true })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.4.1.4_漫遊與門號服務_遺失補卡_空值頁</Button>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default ReplaceCard;