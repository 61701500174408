import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Formsy from 'formsy-react';
import RadioGroup from '../../components/form/RadioGroup';
import NavAnchor from '../../components/partials/NavAnchor';
import LabelInput from '../../components/form/LabelInput';

import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import Select from '../../components/form/Select';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import numberFormatter from '../../utils/numberFormatter';

class prepaidRoamingByBankPage extends React.Component {
  constructor(props) {
    super(props);
    this.bankInput = React.createRef()
    this.taxNumberInput = React.createRef()
    this.state = {
      canSubmit: false,
      form: {
        id_number: { value: '', required: true },
        receipt: { value: '1', required: true },
        receipt_extra_2: { value: '', required: true },
        receipt_extra_3: { value: '', required: true },
        bank_code: { value: '', required: true },
        bank_account: { value: '', required: true },
        agree: { value: false, required: true },
        receipt_format: { value: '2', require: false },
        tax_id_numbers: { value: null, require: false },
        receipt_title: { value: '1', require: false },
        receipt_send_way: { value: '1', require: false },
        receipt_send_code: { value: '', require: false },
        receipt_send_phone: { value: '', require: false },
        receipt_send_city: { value: '', require: false },
        receipt_send_area: { value: '', require: false },
        receipt_send_address: { value: '', require: false },
        receipt_send_receiver: { value: '', require: false },
        receipt_send_email: { value: '', require: false }
      }
    }
  }

  componentDidMount() {
    this.setInputFilter(this.bankInput.current.getElementsByTagName('input')[0], function (value) {
      return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
    });
    this.setInputFilter(this.taxNumberInput.current.getElementsByTagName('input')[0], function (value) {
      return /^\w*$/.test(value); // Allow digits and '.' only, using a RegExp
    });
  }

  setInputFilter = (textbox, inputFilter) => {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;

    this.setState({
      form: newForm
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  submit = () => {
    // this.props.history.push('/prepaidRoamingBalanceController/prepaidRoamingByBankPage');
  };

  render() {
    return (
      <main>


        <NavAnchor
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <FormBanner
          title="預付卡漫遊上網加購"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="prepaid-roaming">
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
            <div className="fui-container">
              <h4 className="is-text-darkgray70 my-0">請確認購買內容，並填寫付款資訊</h4>
              <div className='mb-5'>
                <h4 className="is-text-darkgray50 mt-5 mb-2">
                  購買內容
                </h4>
                <div className="fui-table-response">
                  <table>
                    <thead>
                      <tr>
                        <th>購買門號</th>
                        <th>方案名稱</th>
                        <th>金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0926-899-868</td>
                        <td>超4代易付180MB_60天_$180(1.2GB組合)</td>
                        <td>$180.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr className='my-5' />
                <h4 className="is-text-darkgray50 mb-2">
                  付款資料
                </h4>

                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div className="form-group">
                      <label className="">銀行代碼</label>
                      <Select
                        required={true}
                        name="bank_code"
                        label="請選擇"
                        options={[
                          { text: 'text', value: '1' }
                        ]}
                        value={this.state.form.bank_code.value}
                        onChange={this.onChange} />
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="with-note">
                  <Grid item xs={12} md={6} ref={this.bankInput}>
                    <LabelInput

                      label="銀行帳號(限本人)"
                      name="bank_account"
                      value={this.state.form.bank_account.value}
                      onChange={this.onChange}
                    />
                    <small className="form-note is-text-gray100">本付款機制採用SSL 256 bites安全加密機制</small>
                  </Grid>
                </Grid>

                <hr className="mb-5" />
                <h4 className="is-text-darkgray50 mb-2">
                  發票明細
                </h4>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className="form-group">
                      <label className="">發票捐贈</label>
                      <div className="radio-buttons">
                        <div className="radio-group receipt-radio">
                          <div>
                            <label className="radio-button">
                              <p>不捐贈</p>
                              <input
                                type='radio'
                                name="receipt"
                                value='1'
                                checked={this.state.form.receipt.value === '1'}
                                onChange={e => this.onChange('receipt', e.currentTarget.value)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div>
                            <label className="radio-button">
                              <p>
                                捐贈，選擇單位
                              </p>
                              <input
                                type='radio'
                                name="receipt"
                                value='2'
                                checked={this.state.form.receipt.value === '2'}
                                onChange={e => this.onChange('receipt', e.currentTarget.value)} />
                              <span className="checkmark"></span>
                            </label>
                            <Select
                              name="receipt_extra_2"
                              label="請選擇"
                              disabled={this.state.form.receipt.value !== '2'}
                              value={this.state.form.receipt_extra_2.value}
                              onChange={this.onChange}
                              options={[
                                { text: '111', value: '111' }
                              ]}
                            />
                          </div>

                          <div>
                            <label className="radio-button">
                              <p>
                                捐贈，填寫愛心碼
                              </p>
                              <input
                                type='radio'
                                name="receipt"
                                value='3'
                                checked={this.state.form.receipt.value === '3'}
                                onChange={e => this.onChange('receipt', e.currentTarget.value)}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <div>
                              <Link to="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC603W/" target='_blank' className='search-code is-text-accent'>查詢愛心碼</Link>
                              <input
                                type="text"
                                name="receipt_extra_3"
                                disabled={this.state.form.receipt.value !== '3'}
                                placeholder="請填寫"
                                value='1'
                                onChange={e => this.onChange('receipt_extra_3', e.currentTarget.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <small className="is-text-darkgray50">※捐贈發票後，不提供索取紙本發票及更改捐贈對象</small>
                    </div>
                  </Grid>
                </Grid>





                {this.state.form.receipt.value === '1' ? <div className="receipt">
                  <Grid container>
                    <Grid item xs={12}>
                      <RadioGroup
                        label='發票格式'
                        name="receipt_format"
                        options={[
                          { label: '二聯式發票', value: '1' },
                          { label: '三聯式發票', value: '2' }
                        ]}
                        default={this.state.form.receipt_format.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <div ref={this.taxNumberInput}>
                        <LabelInput

                          label="統一編號"
                          name="tax_id_numbers"
                          placeholder='請輸入統一編號'
                          validationError={{
                            isRequired: '請輸入統一編號'
                          }}
                          value={this.state.form.tax_id_numbers.value}
                          onChange={this.onChange}
                        />
                      </div>
                      <LabelInput
                        type="text"
                        name='receipt_title'
                        label='發票抬頭'
                        placeholder=' 請輸入發票抬頭'
                        value={this.state.form.receipt_title.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid><div className="form-group">
                    <label className=''>發票寄送方式</label>
                    <div className="input-row">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                          <Select
                            name="receipt_send_way"
                            label="請選擇"
                            options={[
                              { text: 'FETnet 會員', value: '1' },
                              { text: '紙本郵寄', value: '2' },
                              { text: '手機載具', value: '3' },
                              { text: '自然人憑證', value: '4' }
                            ]}
                            value={this.state.form.receipt_send_way.value}
                            onChange={this.onChang}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                          <Select
                            name="receipt_send_city"
                            label="縣市"
                            options={[
                              { text: '台北市', value: '1' },
                              { text: '新北市', value: '2' }
                            ]}
                            value={this.state.form.receipt_send_city.value}
                            onChange={this.onChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Select
                            name="receipt_send_area"
                            label="鄉鎮市區"
                            options={[
                              { text: '台北市', value: '1' },
                              { text: '新北市', value: '2' }
                            ]}
                            value={this.state.form.receipt_send_area.value}
                            onChange={this.onChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <LabelInput
                            type='text'
                            label=''
                            name='receipt_send_address'
                            placeholder='請輸入中獎發票寄送地址'
                            validationError={"請輸入中獎發票寄送地址"}
                            value={this.state.form.receipt_send_address.value}
                            onChange={this.onChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <LabelInput
                            label=''
                            type='text'
                            name='receipt_send_receiver'
                            placeholder='請輸入收件人'
                            validationError={"請輸入收件人"}
                            value={this.state.form.receipt_send_receiver.value}
                            onChange={this.onChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <LabelInput
                            label=''
                            type='text'
                            name='receipt_send_email'
                            placeholder='請輸入電子郵件信箱'
                            validationError={"請輸入電子郵件信箱"}
                            value={this.state.form.receipt_send_email.value}
                            onChange={this.onChange}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div> : null}


                <CheckboxCollapse
                  name="agree"
                  label="本人已詳閱及了解此注意事項並同意遵守"
                  validationError='請詳閱及了解此注意事項'
                  validations={{
                    isChecked: form => form.agree
                  }}
                  checked={this.state.form.agree.value}
                  value={this.state.form.agree.value}
                  getCheckbox={e => this.onChange('agree', e)}
                  collapseContent={`
                    <p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。</p>
                    <p>使用「全國繳費平台」繳納遠傳電信服務費用注意事項：</p>
                    <p>
                    1.限以門號申請本人活存帳戶使用此服務。<br/>
                    2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                    3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                    4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                    5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。
                    </p>
                  `}
                />
              </div>

              <div className="page-action">
                <Button btnStyle="primary" size="large" disabled={!this.state.canSubmit} onClick={this.submit}>確認付款</Button>
              </div>
            </div>
          </Formsy>
        </section>
      </main >
    )
  }
}

export default prepaidRoamingByBankPage;