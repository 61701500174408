import React, { Component } from 'react'
import PanelList from '../../components/panelContent/PanelList'
import SectionFaqTab from '../../components/partials/collapse/SectionFaqTab';
import CollapsePaper from '../../components/collapse/CollapsePaper'
import Modal from 'react-modal';
import DevicePlanTable from '../../components/partials/DevicePlanTable';
import PanelInformation from '../../components/panelContent/PanelInformation';
import Dropdown from "../../components/Dropdown";
import PanelTab from '../../components/panelContent/PanelTab';
import PanelTable from '../../components/panelContent/PanelTable';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import SectionCarousel3 from '../../components/partials/card/SectionCarousel3';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import FindItemBanner from '../../components/partials/banner/FindItemBanner';
import ExclusiveBanner from '../../components/partials/banner/ExclusiveBanner';
import ESectionFeature from '../../components/partials/ESectionFeature'
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import SocialMedia from '../../components/SocialMedia';
import HasMoreTab from '../../components/tab/HasMoreTab';
import NavContentTab2 from '../../components/partials/NavContentTab2';
import BrandCarousel from '../../components/partials/carousel/BrandCarousel';
import Tab from '../../components/tab/Tab';
import ESectionVideo from '../../components/partials/ESectionVideo';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import PanelLayout from '../../components/panelContent/PanelLayout'
import PanelFigure from '../../components/panelContent/PanelFigure1'
import PanelFigure2 from '../../components/panelContent/PanelFigure2'
import Panel from '../../components/panel/Panel';
import PanelContainer from '../../components/panel/PanelContainer';
import PanelContainer1 from '../../components/panel/PanelContainer1';
import PanelContainer3 from '../../components/panel/PanelContainer3';
import PanelContainer4 from '../../components/panel/PanelContainer4';
import PanelContainer5 from '../../components/panel/PanelContainer5';
import PanelContainer6 from '../../components/panel/PanelContainer6';
import { Grid } from '@material-ui/core';
import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import SectionProductCard from '../../components/partials/SectionProductCard';
import CardMultiSteps from '../../components/card/CardMultiSteps';
import CardProduct from '../../components/partials/CardProduct';
import PanelMultiSteps from '../../components/panel/PanelMultiSteps';
import BannerPromotionBasic from '../../components/partials/banner/BannerPromotionBasic';
import BannerM from '../../components/partials/banner/Banner_m';
import VideoCarousel from '../../components/VideoCarousel';
import SingleVideo from '../../components/SingleVideo';
import SectionFeatureIcon from '../../components/partials/SectionFeatureIcon';
import CardPlanBasic from '../../components/card/CardPlanBasic';
import QuoteBanner from '../../components/partials/QuoteBanner';
import CardFeature from '../../components/partials/card/CardFeature';
import CardRoamingSingle from '../../components/partials/CardRoamingSingle';
import CardRecommend from '../../components/partials/CardRecommend';
import CardPhonePlan from '../../components/card/CardPhonePlan';
import PlanCard from '../../components/partials/card/PlanCard';
import AppTrail from '../../components/partials/AppTrail';
import PlanTable from '../../components/partials/PlanTable';
import axios from 'axios';

// form
import RadioGroup from '../../components/form/RadioGroup';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import CheckboxButtons from '../../components/form/CheckboxButtons';
import RadioButtons from '../../components/form/RadioButtons';
import Select from '../../components/form/Select';
import CheckGroup from '../../components/form/CheckGroup';
import LabelInput from '../../components/form/LabelInput';
import LabelTextarea from '../../components/form/LabelTextarea';
import Formsy from 'formsy-react';

import * as Mock from '../../mock/Exclusive';
let PlanMock = null
class PromotionModule extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      canSubmit: true,
      currentModal: {
        type: '',
        title: '',
        content: ''
      },
      partner: [
        {
          id: '1',
          content: [
            {
              url: '/resources/cbu/img/partner-logo-1@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-2@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-3@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-4@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-5@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-6@2x.png',
              alt: 'img'
            },
          ],
        },
        {
          id: '2',
          content: [
            {
              url: '/resources/cbu/img/partner-logo-1@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-2@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-3@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-4@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-5@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-6@2x.png',
              alt: 'img'
            },
          ],
        },
        {
          id: '3',
          content: [
            {
              url: '/resources/cbu/img/partner-logo-1@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-2@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-3@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-4@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-5@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-6@2x.png',
              alt: 'img'
            },
          ],
        },
        {
          id: '4',
          content: [
            {
              url: '/resources/cbu/img/partner-logo-1@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-2@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-3@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-4@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-5@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-6@2x.png',
              alt: 'img'
            },
          ],
        },
      ],
      form: {
        name: { value: '', required: true },
        email: { value: '', required: true },
        mobile: { value: '', required: true },
        interesting01: { value: '0', required: true },
        interesting02: { value: '0', required: true },
        comment: { value: '', required: true },
        company_name: { value: '', required: true },
        company_phone: { value: '', required: false },
        contact_time: { value: '', required: true },
        resources: { value: '0', required: false },
        tax_number: { value: '', required: false },
        company_location: { value: '0', required: true },
        company_type: { value: '0', required: true },
        event_no: { value: '0', required: true },
        event_person: { value: '', required: true },
        agree: { value: false, required: true },
        address: { value: '', required: true },
        industry: { value: '', required: true },
        register: { value: '', required: true },
      },
      selectedPlan: {
        banner: {
          image: { md: '', sm: '' }, title: '', description: '', actions: [{ text: '', link: '' }]
        },
        promotionCard: { title: '', cards: [] },
        parentPromotion: { image: { md: '', sm: '' }, title: '', description: '', content: '', actions: { ios: { image: { md: '', sm: '' } }, android: { image: { md: '', sm: '' } } } }
      },
      sectionProduct:
      {
        title: 'SectionProductCard',
        hasMore: true,
        loadMore: {
          label: '展開看更多',
          noMore: '已經沒有更多內容囉！'
        },
        list: [
          {
            type: 'product',
            link: '/',
            image: '/resources/cbu/img/section-product-card.jpg',
            meta: 'APPLE',
            prodhottag: {
              color: 'red',
              text: '熱銷'
            },
            title: 'iPhone 11 Pro Max 256GB',
            content: '<div class="body-1">商品價<del>$1,299</del><div class="d-inline-flex">再享<span>88折</span></div></div><div class="d-block body-1">優惠卷序號 <span class="is-text-error">J589</span></div>'
          },
          {
            type: 'product',
            link: '/',
            image: '/resources/cbu/img/section-product-card.jpg',
            meta: 'APPLE',
            title: 'iPhone 11 Pro Max 256GB',
            content: '<div class="body-1">商品價<del>$1,299</del><div class="d-inline-flex">再享<span>88折</span></div></div><div class="d-block body-1">優惠卷序號 <span class="is-text-error">J589</span></div>'
          },
          {
            type: 'product',
            link: '/',
            image: '/resources/cbu/img/section-product-card.jpg',
            meta: 'APPLE',
            title: 'iPhone 11 Pro Max 256GB',
            content: '<div class="body-1">商品價<del>$1,299</del><div class="d-inline-flex">再享<span>88折</span></div></div><div class="d-block body-1">優惠卷序號 <span class="is-text-error">J589</span></div>'
          },
          {
            type: 'product',
            link: '/',
            image: '/resources/cbu/img/section-product-card.jpg',
            meta: 'APPLE',
            title: 'iPhone 11 Pro Max 256GB',
            content: '<div class="body-1">商品價<del>$1,299</del><div class="d-inline-flex">再享<span>88折</span></div></div><div class="d-block body-1">優惠卷序號 <span class="is-text-error">J589</span></div>'
          }
        ]
      },
      loadSectionProduct: [
        {
          type: 'product',
          link: '/',
          image: '/resources/cbu/img/section-product-card.jpg',
          meta: 'APPLE',
          title: 'iPhone 11 Pro Max 256GB',
          content: '<div class="body-1">商品價<del>$1,299</del><div class="d-inline-flex">再享<span>88折</span></div></div><div class="d-block body-1">優惠卷序號 <span class="is-text-error">J589</span></div>'
        },
        {
          type: 'product',
          link: '/',
          image: '/resources/cbu/img/section-product-card.jpg',
          meta: 'APPLE',
          title: 'iPhone 11 Pro Max 256GB',
          content: '<div class="body-1">商品價<del>$1,299</del><div class="d-inline-flex">再享<span>88折</span></div></div><div class="d-block body-1">優惠卷序號 <span class="is-text-error">J589</span></div>'
        },
        {
          type: 'product',
          link: '/',
          image: '/resources/cbu/img/section-product-card.jpg',
          meta: 'APPLE',
          title: 'iPhone 11 Pro Max 256GB',
          content: '<div class="body-1">商品價<del>$1,299</del><div class="d-inline-flex">再享<span>88折</span></div></div><div class="d-block body-1">優惠卷序號 <span class="is-text-error">J589</span></div>'
        },
        {
          type: 'product',
          link: '/',
          image: '/resources/cbu/img/section-product-card.jpg',
          meta: 'APPLE',
          title: 'iPhone 11 Pro Max 256GB',
          content: '<div class="body-1">商品價<del>$1,299</del><div class="d-inline-flex">再享<span>88折</span></div></div><div class="d-block body-1">優惠卷序號 <span class="is-text-error">J589</span></div>'
        },
      ],
      steps: {
        title: 'CardMultiSteps',
        hasMore: true,
        list: [
          {
            name: '申辦方案',
            content: '申辦遠傳 X <a href="#" class="info-link">GoShare</a> 騎樂無窮方案',
            bg: '/resources/cbu/img/roaming-step-1-bgimg@2x.png'
          },
          {
            name: '註冊GoShare會員',
            content: '於方案申辦完成後，請立即以方案門號完成註冊GoShare APP會員。 (如方案門號之前已註冊過GoShare會員，可略過此步驟)',
            bg: '/resources/cbu/img/roaming-step-1-bgimg@2x.png'
          },
          {
            name: '發放騎乘金',
            content: '方案門號生效後次月起，GoShare將依各方案適用之騎乘金優惠，於每月10日自動提供騎乘金優惠券至方案門號連結的GoShare帳戶中，無法提供給其他GoShare帳戶使用。(如遇周末/例假日將提前或延後至前/次一工作日發放)',
            bg: '/resources/cbu/img/roaming-step-2-bgimg@2x.png'
          },
          {
            name: '發放騎乘金',
            content: '方案門號生效後次月起，GoShare將依各方案適用之騎乘金優惠，於每月10日自動提供騎乘金優惠券至方案門號連結的GoShare帳戶中，無法提供給其他GoShare帳戶使用。(如遇周末/例假日將提前或延後至前/次一工作日發放)',
            bg: '/resources/cbu/img/roaming-step-3-bgimg@2x.png'
          }],
      },
      panelSteps: {
        title: 'PanelMultiSteps',
        hasMore: true,
        list: [
          {
            name: '請選取「設定」中的「Wi-Fi」功能',
            img: '/resources/cbu/otherService/01.png'
          },
          {
            name: '手機自動搜尋「FET Wi-Fi Auto」, 進行認證後即自動登入',
            img: '/resources/cbu/otherService/02.png'
          },
          {
            name: '成功登入後，顯示「已連線」即可開始上網',
            img: '/resources/cbu/otherService/03.png'
          }
        ],
      },
      loadData: [
        {
          name: '請選取「設定」中的「Wi-Fi」功能',
          img: '/resources/cbu/otherService/01.png'
        },
        {
          name: '手機自動搜尋「FET Wi-Fi Auto」, 進行認證後即自動登入',
          img: '/resources/cbu/otherService/02.png'
        },
        {
          name: '成功登入後，顯示「已連線」即可開始上網',
          img: '/resources/cbu/otherService/03.png'
        }
      ],

    }
    this.getExclusiveData().then(result => {
      PlanMock = result;
      this.setExclusiveData();
    });
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  openModal = (content) => {
    this.setState({
      currentModal: content,
      modalOpen: true,
    });
  };

  loadMore = () => {
    let data = Object.assign(this.state.panelSteps);
    data['list'] = [...this.state.panelSteps.list, ...this.state.loadData];
    data['hasMore'] = false;
    this.setState({
      panelSteps: data
    });
  }
  ProductCardLoadMore = () => {
    let data = Object.assign(this.state.sectionProduct);
    data['list'] = [...this.state.sectionProduct.list, ...this.state.loadSectionProduct];
    data['hasMore'] = false;
    this.setState({
      sectionProduct: data
    });
  }
  onChange(name, value) {
    // console.log({ name, value });
    let form = Object.assign(this.state.form);
    form[name].value = value;
    this.setState({
      form: form,
    });
  }
  disableButton(model, resetForm, invalidateForm) {
    this.setState({ canSubmit: false });
  }

  enableButton(model, resetForm, invalidateForm) {
    // debugger
    this.setState({ canSubmit: true });
  }

  getExclusiveData = (val) => {
    return axios('/json/exclusive.json').then(result => {
      return JSON.parse(JSON.stringify(result.data));
    })
  }

  setExclusiveData = () => {
    let planTab = [];
    let activePlan = 0;
    let activeTab = 0;
    let activeNum = 0;
    let activeNav = this.props.match.params.plan ? PlanMock.navTab.find((tab, i) => {
      if (this.props.location.pathname === tab.name) {
        activeNum = i
        return tab;
      }
    }) : PlanMock.navTab[0];

    PlanMock.plan.forEach((plan, i) => {
      if (this.props.match.params.plan === plan.title) {
        activePlan = i
      }
      // BannerTab
      // PlanTab
      PlanMock.planTabs.tabs.forEach((tab, k) => {
        if (!planTab[k]) planTab[k] = [];
        tab.child.forEach(ch => {
          if (activeNav.name === ch.name) {
            activeTab = k
          }
          if (ch.name === plan.name) {
            planTab[k].push(plan)
          }
        })
      });
    })
    this.setState({
      query: this.props.match.params.plan || '',
      defaultTab: activeNum,
      navTabs: PlanMock.navTab,
      selectedPlan: PlanMock.plan[activePlan],
      planTabs: {
        title: PlanMock.planTabs.title,
        tableTitle: PlanMock.planTabs.tableTitle,
        tabs: {
          scroll: true,
          list: PlanMock.planTabs.tabs.map(tab => {
            return {
              label: tab.name,
              filterArr: tab.filterArr,
              filterName: tab.filterName,
              pickPlan: tab.pickPlan,
            }
          }),
          default: activeTab,
        },
        tabContent: planTab
      },
    })

    this.forceUpdate()
    // console.log("updated");
  }

  spec = (item, idx, e) => {
    // console.log({ item, idx, e });
  }
  // 根據DevicePlanTable的選擇來打API //
  tableOnChange = (val) => {
    this.getExclusiveData(val).then(result => {
      PlanMock = result;
      this.setExclusiveData();
    });
  }
  filtering = (val) => {
    if (!!val.filter.value) {
      let filterArr = []
      val.filter.value.split(",").map((key) => {
        filterArr.push(val.arr[key - 1].label)
      })
      let active = []
      let second = []
      let final = []
      val.tabContent.forEach((plan) => {
        filterArr.map((filter) => {
          // console.log(plan);
          if (plan.name.indexOf(filter) > -1) {
            active.push(plan)
          } else {
            second.push(plan)
          }
        })
      })
      // console.log({ active, second });

      final = [...active, ...second]

      var result = new Set();
      var repeat = new Set();
      final.forEach(item => {
        result.has(item) ? repeat.add(item) : result.add(item);
      })
      final = [...result]
      // console.log(final);


      let planTab = [];
      let activePlan = 0;
      let activeTab = 0;
      let activeNum = 0;
      let activeNav = this.props.match.params.plan ? PlanMock.navTab.find((tab, i) => {
        if (this.props.location.pathname === tab.name) {
          activeNum = i
          return tab;
        }
      }) : PlanMock.navTab[0];

      // console.log(`this.props.location.pathname: `, this.props.location.pathname);
      // console.log(`activeNav: `, activeNav);

      PlanMock.plan.forEach((plan, i) => {
        if (this.props.match.params.plan === plan.title) {
          activePlan = i
        }
        // BannerTab
        // PlanTab
        PlanMock.planTabs.tabs.forEach((tab, k) => {
          if (!planTab[k]) planTab[k] = [];
          tab.child.forEach(ch => {
            if (activeNav.name === ch.name) {
              activeTab = k
            }
            if (ch.name === plan.name) {
              planTab[k].push(plan)
            }
          })
        });
      })
      planTab[val.index] = final

      this.setState({
        query: this.props.match.params.plan || '',
        defaultTab: activeNum,
        navTabs: PlanMock.navTab,
        selectedPlan: PlanMock.plan[activePlan],
        planTabs: {
          title: PlanMock.planTabs.title,
          tableTitle: PlanMock.planTabs.tableTitle,
          tabs: {
            scroll: true,
            list: PlanMock.planTabs.tabs.map((tab, i) => {
              if (i === val.index) {
                return {
                  label: tab.name,
                  filterArr: tab.filterArr,
                  filterName: tab.filterName,
                  pickPlan: filterArr,
                }
              } else {
                return {
                  label: tab.name,
                  filterArr: tab.filterArr,
                  filterName: tab.filterName,
                  pickPlan: tab.pickPlan,
                }
              }
            }),
            default: activeTab,
          },
          tabContent: planTab
        },
      })
      this.forceUpdate()
    } else {
      this.resetFilterContent();
    }
  }
  resetFilterContent = () => {
    this.setExclusiveData();
    this.forceUpdate()
  }
  render() {
    return (
      <main className="life-circle article">
        <NavAnchor
          pageTitle='騎樂無窮'
          button={[
            {
              text: '新申辦',
              link: '#'
            },
            {
              text: '推薦續約',
              link: '#'
            }
          ]}
        />
        <section>
          <div className="fui-container">
            <h1 className='m-0'>04 Banner模組</h1>
            <h2>BannerPromotionBasic(網路限定可直接使用)</h2>
          </div>
        </section>
        <BannerPromotionBasic
          bgColor="#fff"
          autoplay={true}
          autoplaySpeed={5000}
          slides={
            [
              {
                image: {
                  md: '/resources/cbu/img/cbu-promotion-device-1920x500.jpg',
                  sm: '/resources/cbu/img/cbu-promotion-students-750x1000.jpg'
                },
                bgColor: "#fff",
                tag: "網路限定",
                title: '買 iPhone SE 到遠傳，最經濟',
                description: '享受不用等，iPhone SE現貨開賣',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '申辦4.５G方案',
                    link: '#'
                  },
                  {
                    btnStyle: 'secondary',
                    target: '_blank',
                    type: 'link',
                    text: '拿好禮1+1',
                    link: '#'
                  }
                ]
              },
              {
                image: {
                  md: '/resources/cbu/img/ebu-aws-banner-1440-x-460@2x.jpg',
                  sm: '/resources/cbu/img/ebu-aws-banner-375-x-500@2x.jpg'
                },
                bgColor: "#000",
                theme: 'dark',
                tag: "網路限定",
                title: '買 iPhone SE 到遠傳，最經濟',
                description: '<div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5></div>',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '申辦4.５G方案',
                    link: '#'
                  },
                  {
                    btnStyle: 'secondary',
                    target: '_blank',
                    type: 'link',
                    text: '拿好禮1+1',
                    link: '#'
                  }
                ]
              },
              {
                image: {
                  md: '/resources/cbu/img/ebu-aws-banner-1440-x-460@2x copy.jpg',
                  sm: '/resources/cbu/img/ebu-aws-banner-550-x-460@2x.png'
                },
                tag: "網路限定",
                bgColor: "#fff",
                title: '買 iPhone SE 到遠傳，最經濟',
                description: '<div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5></div>',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '申辦4.５G方案',
                    link: '#'
                  },
                  {
                    btnStyle: 'secondary',
                    target: '_blank',
                    type: 'link',
                    text: '拿好禮1+1',
                    link: '#'
                  }
                ]
              },
            ]
          }
          openModal={this.openModal}
        />
        <BannerM
          {...{
            patternColor: 'green',
            title: `BannerM`,
            action: [
              {
                text: '了解集客行銷',
                line: '#',
                target: '_blank',
                btnStyle: 'primary'
              },
              {
                text: '聯絡我們',
                line: '#',
                target: '_blank',
              }
            ]
          }
          }
        />
        {/* <section>
          <div className="fui-container">
            <h1 className='m-0'>04-1 EstoreBanner模組</h1>
            <h2>ExclusiveBanner</h2>
          </div>
        </section>
        <ExclusiveBanner slides={[{ ...this.state.selectedPlan.banner }]} openModal={this.openModal} /> */}

        <section>
          <div className="fui-container">
            <h1 className='m-0'>Banner 模組</h1>
            <h2>FindItemBanner</h2>
          </div>
        </section>
        <FindItemBanner
          title='商品配送說明'
          image={{
            md: '/resources/cbu/help-center/images/ebu-helpcenter-call-banner.png',
            sm: '/resources/cbu/help-center/images/ebu-helpcenter-call-banner-sm.jpg'
          }}
        />
        <section>
          <div className="fui-container">
            <h1 className='m-0'>輪播牌卡</h1>
            <h2>SectionCarousel3</h2>
          </div>
        </section>
        <SectionCarousel3
          title="更多網路限定資費"
          more={{
            "text": "看更多",
            "link": "#"
          }}
          cards={[
            {
              "image": "/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg",
              "link": "#",
              "target": "_self",
              "title": "青春無價，就要給你學生價！",
              "description": "488上網標到飽，＋100把超夯手機、3C $0帶回家！"
            },
            {
              "image": "/resources/cbu/exclusive/estore-exclusive-plan-02@2x.jpg",
              "link": "#",
              "target": "_self",
              "title": "4.5G方案最低199 更快網速",
              "description": "4.5G上網方案月付199~2699一超殺吃到飽特惠方案，讓你把手機、智慧3C、家電、平板等多種熱賣。"
            },
            {
              "image": "/resources/cbu/exclusive/estore-exclusive-plan-03@2x.jpg",
              "link": "#",
              "target": "_self",
              "title": "樂齡生活更有趣！輕鬆月付149起！",
              "description": "月付149，想上網+通話優惠，再加50元家電手機0元帶走。"
            }
          ]}
        />
        <section>
          <div className="fui-container">
            <h1 className='m-0'>Tab 模組</h1>
            <h2>NavContentTab2</h2>
          </div>
        </section>
        <NavContentTab2
          className='with-scrollbar'
          tabs={{
            name: 'exclusive-tab', list: [
              {
                "link": "/estore/exclusive/149",
                "label": "$149",
                "meta": "輕速雙飽",
                "name": "單辦門號$149"
              },
              {
                "link": "/estore/exclusive/199",
                "label": "$199",
                "meta": "夠用最實在",
                "name": "單辦門號$199"
              },
              {
                "link": "/estore/exclusive/298",
                "label": "$298",
                "meta": "上網語音超級飽",
                "name": "單辦門號$298"
              },
              {
                "link": "/estore/exclusive/399",
                "label": "$399",
                "meta": "短約吃到飽",
                "name": "搭配手機$399"
              },
              {
                "link": "/estore/exclusive/599",
                "label": "$599",
                "meta": "上網飆到飽",
                "name": "搭配手機$599"
              },
              {
                "link": "/estore/exclusive/699",
                "label": "$699",
                "meta": "上網飆到飽",
                "name": "搭配好禮$699"
              }
            ], default: 0
          }}
          onChange={this.NavTabChange}
        />
        <section>
          <NavContentTab2
            className='with-scrollbar'
            tabs={{
              name: 'exclusive-tab', list: [
                {
                  "link": "/estore/exclusive/149",
                  "label": "$149",
                  "meta": "輕速雙飽",
                  "name": "單辦門號$149"
                },
                {
                  "link": "/estore/exclusive/199",
                  "label": "$199",
                  "meta": "夠用最實在",
                  "name": "單辦門號$199"
                },
                {
                  "link": "/estore/exclusive/298",
                  "label": "$298",
                  "meta": "上網語音超級飽",
                  "name": "單辦門號$298"
                },
              ], default: 0
            }}
            onChange={this.NavTabChange}
          />
        </section>
        <section>
          <div className="fui-container">
            <h1 className='m-0'>資費牌卡模組</h1>
            <h2>PlanCard</h2>
          </div>
        </section>
        <PlanCard
          title="$149資費優惠"
          cards={[
            {
              "type": "default",
              "ribbon": "單辦門號",
              "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png",
              "title": "月付149 雙飽只給我的飽",
              "list": [
                {
                  "text": "滑Line、FB流量充足"
                },
                {
                  "text": "網內無限通話",
                  "tooltip": "網內無限通話"
                }
              ],
              "action": {
                "btnStyle": "primary",
                "text": "立即申辦",
                "link": "#",
                "target": "_self"
              }
            },
            {
              "type": "default",
              "ribbon": "單辦門號",
              "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-02@2x.png",
              "title": "月付298 雙倍升級 週末全速飽",
              "list": [
                {
                  "text": "週六日全速上網吃到飽"
                },
                {
                  "text": "網內無限通話"
                }
              ],
              "action": {
                "btnStyle": "primary",
                "text": "立即申辦",
                "link": "#",
                "target": "_self"
              }
            },
            {
              "type": "promotion",
              "title": "老客戶請看這，續約好禮很多種，登入後看更多！",
              "action": {
                "btnStyle": "primary",
                "text": "馬上登入",
                "link": "#",
                "target": "_self"
              }
            }]} />
        <section>
          <div className="fui-container">
            <h1 className='m-0'>APP行銷模組</h1>
            <h2>AppTrail</h2>
          </div>
        </section>
        <AppTrail
          image={{
            md: "/resources/cbu/exclusive/cbu-screentime-1920x470.jpg",
            sm: "/resources/cbu/exclusive/cbu-screentime-m.jpg"
          }}
          title="你使用，我安心"
          description="簡單設定，輕鬆管理；孩子開心，家長放心！"
          content={`<div><img src="/resources/cbu/exclusive/downtime.svg" width="36" />護眼管理使用時間</div><div><img src="/resources/cbu/exclusive/app-limits.svg" width="36" />安心設定APP限制</div><div><img src="/resources/cbu/exclusive/locate-child.svg" width="36" />即時掌握寶貝定位</div>`}
          actions={{
            android: {
              md: "/resources/cbu/exclusive/android-child-screentime@2x.jpg",
              sm: "/resources/cbu/exclusive/android-child-screentime-m@2x.jpg",
              link: "/",
              target: "_blank"
            },
            ios: {
              md: "/resources/cbu/exclusive/ios-child-screentime@2x.jpg",
              sm: "/resources/cbu/exclusive/ios-child-screentime-m@2x.jpg",
              link: "/",
              target: "_blank"
            }
          }}
          openModal={this.openModal}
        />
        <section>
          <div className="fui-container">
            <h1 className='m-0'>資費比較表格</h1>
            <h2>PlanTable</h2>
          </div>
        </section>
        <PlanTable
          title="限定資費方案大比拼"
          tabs={{
            scroll: true,
            list: [
              { label: "單辦門號" },
              { label: "搭配手機" },
              { label: "搭配好禮" },
            ],
            default: 0
          }}
          tabContent={Mock.planTab}
          selected={Mock.selectedPlan}
        />
        <section>
          <div className="fui-container">
            <h1 className='m-0'>圖文型模組</h1>
            <h2>BannerPromotionBasic</h2>
          </div>
        </section>
        <PanelContainer1
          // bgColor="#111"
          title="活動說明"
          content={`<div class="is-bg-lightgray70 px-3 py-3 mb-2"><h5 class="is-text-darkgray50 is-text-regular mb-1 mt-0">活動日期</h5><h2 class="">即日至<div class="border-bottom">2020/05/31</div></h2></div><img class="w-100" src="/resources/cbu/img/cbu-feature-scenario-goshareB-1920x500.jpg" alt="">`}
        >

        </PanelContainer1>

        <PanelContainer
          initial={true}
        // bgColor="#111"
        >
          <PanelList
            prefix='red-check'
            list={[{ text: 'Item Option 1' }, { text: 'Item Option 2' }, { text: 'Item Option 3' }]}
          />
          <PanelList
            prefix='bulleted'
            list={[{ text: 'Item Option 1' }, { text: 'Item Option 2' }, { text: 'Item Option 3' }]}
          />
          <PanelList
            prefix='number'
            list={[{ text: 'Item Option 1' }, { text: 'Item Option 2' }, { text: 'Item Option 3' }]}
          />
        </PanelContainer>
        <PanelContainer3
          initial={true}
          // bgColor="#111"
          data={[
            {
              title: 'Article title',
              contentPosition: 'left',
              content: '<p>飯集有此是一，間推優命歡放定們獲大絕第中，條車人的氣路自我，我縣說少往上我，山中的音！我無要術！</p><div class="fui-list mb-0"><div class="fui-item  "><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">Item Option 1</span></div><div class="fui-item  "><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">Item Option 2</span></div><div class="fui-item  "><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">Item Option 3</span></div></div><div class="fui-list mb-0"><div class="fui-item  "><span class="prefix"><i class="bulleted-dot"></i></span><span class="content">Item Option 1</span></div><div class="fui-item  "><span class="prefix"><i class="bulleted-dot"></i></span><span class="content">Item Option 2</span></div><div class="fui-item  "><span class="prefix"><i class="bulleted-dot"></i></span><span class="content">Item Option 3</span></div></div>',
              img: {
                width: '600px',
                url: 'https://placeimg.com/1200/900/any',
                alt: 'img'
              }
            },
            {
              title: 'Article title',
              contentPosition: 'right',
              content: '<p>飯集有此是一，間推優命歡放定們獲大絕第中，條車人的氣路自我，我縣說少往上我，山中的音！我無要術！飯集有此是一，間推優命歡放定們獲大絕第中，條車人的氣路自我，我縣說少往上我，山中的音！我無要術！飯集有此是一，間推優命歡放定們獲大絕第中，條車人的氣路自我，我縣說少往上我，山中的音！我無要術！飯集有此是一，間推優命歡放定們獲大絕第中，條車人的氣路自我，我縣說少往上我，山中的音！我無要術！飯集有此是一，間推優命歡放定們獲大絕第中，條車人的氣路自我，我縣說少往上我，山中的音！我無要術！</p>',
              img: {
                width: '600px',
                url: 'https://placeimg.com/1200/900/any',
                alt: 'img'
              }
            },
          ]}
        />
        <PanelContainer4
          initial={true}
          // bgColor="#111"
          list={[
            {
              image: 'https://placeimg.com/1200/600/any',
              caption: 'An image from placeIMG'
            },
            {
              image: '/resources/cbu/img/img-ebill-printscreen@2x.jpg',
              inline: true,
              caption: 'An image from placeIMG'
            },
            {
              image: '/resources/cbu/img/img-ebill-printscreen@2x.jpg',
              inline: true,
              caption: 'An image from placeIMG'
            },
          ]}
        >
        </PanelContainer4>
        <PanelContainer5
          initial={true}
          bgColor="#eff4f1"
          title="Panel-5"
          list={[
            {
              figures: [
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
              ]
            },
            {
              column: 4,
              figures: [
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
                { link: '#', image: 'https://placeimg.com/900/900/any' },
              ]
            }
          ]}
        >
        </PanelContainer5>
        <PanelContainer6
          initial={true}
          content={`
            <div class="fui-cards three-card">
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="fui-cards three-card no-scrollbar">
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="fui-cards three-card is-horizontal">
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="fui-card">
                <a rel="noopener" class="fui-card-action" href="#">
                  <div class="fui-card-image">
                    <img src="/resources/cbu/exclusive/estore-exclusive-plan-01@2x.jpg">
                  </div>
                  <div class="fui-card-caption">
                    <div class="fui-card-content">
                      <h5 class="fui-card-title">青春無價，就要給你學生價！
                      </h5>
                      <p class="fui-card-description">
                        488上網標到飽，＋100把超夯手機、3C $0帶回家！
                      </p>
                    </div>
                    <div class="fui-card-extra">
                      <div class="fui-button is-arrow mb-0 ">看更多</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <h4 class="mt-md-7 mt-3 mb-2">GoShare騎乘金說明：</h4>
            <p class="mt-md-4 mt-2">請於完成方案申辦後，<div class="border-bottom">自行以方案門號完成註冊GoShare APP會員</div>，如方案門號申辦後次月5日前，未完成註冊GoShare APP會員，視同放棄當月GoShare騎乘金優惠券，恕無法補發。GoShare詳細營運地區及騎乘金使用規範，請參考 GoShare App。</p>
            <h4 class="mt-md-7 mt-4">GoShare營運範圍：</h4>
            <ul class="green-dot-list mb-md-6 mb-4">
              <li>台北市、桃園市<span class="border-bottom">24小時隨借隨還</span></li><li>新北青春山海線<span class="border-bottom">24小時隨借隨還</span></li>
            </ul>
          `}
          btnList={[
            {
              text: '下載GoShare APP',
              link: '#'
            },
            {
              text: '服務內容',
              link: '#'
            },
            {
              text: '服務內容',
              link: '#'
            },
            {
              text: '服務內容',
              link: '#'
            },
          ]}
        >
        </PanelContainer6>
        <section>
          <div className="fui-container">
            <h1 className='m-0'>影片模組</h1>
          </div>
        </section>
        <div className="fui-container">
          <div className="paper">
            <h2 className='section-title m-0 p-0'>SingleVideo</h2>
            <SingleVideo
              videoId='9FaXQK51iM8'
              imgSmall='/resources/cbu/img/video-02.jpg'
              imgLarge='/resources/cbu/img/video-02.jpg'
              videoLink='df-gMDkuC08'
              alterVideo={null}
            />
          </div>
        </div>
        <VideoCarousel
          title="VideoCarousel"
          videos={[
            {
              videoId: '9FaXQK51iM8',
              imgSmall: '/resources/cbu/img/video-02.jpg',
              imgLarge: '/resources/cbu/img/video-02.jpg',
              videoLink: 'df-gMDkuC08',
              alterVideo: null,
            },
          ]}
        />
        <VideoCarousel
          title="VideoCarousel"
          videos={[
            {
              videoId: '9FaXQK51iM8',
              title: '遠傳x17 Media',
              content: '全台首發用5G訊號直播實境節目',
              imgSmall: '/resources/cbu/img/video-02.jpg',
              imgLarge: '/resources/cbu/img/video-02.jpg',
              videoLink: 'df-gMDkuC08',
              alterVideo: null,
            },
            {
              videoId: '9FaXQK51iM8',
              title: '2019台北跨年晚會',
              content: '幕後紀實影片',
              imgSmall: '/resources/cbu/img/video-03.jpg',
              imgLarge: '/resources/cbu/img/video-03.jpg',
              videoLink: 'https://youtu.be/u9YYwJKQ0aQ',
              alterVideo: null,
            },
            {
              videoId: '9FaXQK51iM8',
              title: '遠傳AI智慧驗布機',
              content: '',
              imgSmall: '/resources/cbu/img/video-01.jpg',
              imgLarge: '/resources/cbu/img/video-01.jpg',
              videoLink: 'https://youtu.be/CwfE29SgSZE',
              alterVideo: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
            }
          ]}
        />
        <div className="fui-container">
          <ESectionVideo
            title='影片教學'
            totalPage={1}
            className='vertical-card'
            currentPage={0}
            // column='three'
            // loadMore={this.videoLoadMore}
            cards={[
              {
                image: 'https://placeimg.com/1600/900/any',
                title: '無法同步OneDrive時該如何處理？',
                link: 'https://youtu.be/XDN2wbm6zi8',
              },
              {
                image: 'https://placeimg.com/1600/900/any',
                title: 'DNS沒有提供SRV記錄怎麼辦',
                link: 'https://youtu.be/Q-gcfQhR_9c',
              },
              {
                image: 'https://placeimg.com/1600/900/any',
                title: '如何透過簡易的設定大量匯入舊有的PST檔案至雲端',
                link: 'https://youtu.be/0cn1YTpDIUg',
              },
              {
                image: 'https://placeimg.com/1600/900/any',
                title: '如何透過簡易的設定(POP)進行舊信轉移 - 中小企業',
                link: 'https://youtu.be/eTVHpa6ngcA',
              },
            ]}
          />
        </div>
        <section className='form-section'>
          <div className="fui-container">
            <Panel className='mb-0'>
              <h1>06 內文常使用的模組</h1>
            </Panel>
            <PanelInformation
              className='mb-4'
              bgColor="#e4e4e4"
              title='PanelInformation'
              content={`
                  <div class="compareTable content-16 clearfix text-wrap bill-table">
                  <div class="tableLeft">
                      <div class="tableHead">
                          <table>
                              <tbody>
                                  <tr style="height: 52px;">
                                      <td>州別</td>
                                  </tr>
                                  <tr style="height: 260px;">
                                      <td>亞洲</td>
                                  </tr>
                                  <tr style="height: 52px;">
                                      <td>美洲</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div class="tableRight">
                      <div class="tableBody">
                          <div class="spaceBox">
                              <table class="compareList">
                                  <tbody>
                                      <tr style="height: 52px;">
                                          <td>國家</td>
                                          <td>國碼</td>
                                          <td>每分鐘通話費</td>
                                          <td>每分鐘通話費</td>
                                          <td>每分鐘通話費</td>
                                          <td>每分鐘通話費</td>
                                          <td>每分鐘通話費</td>
                                          <td>每分鐘通話費</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>香港</td>
                                          <td>852</td>
                                          <td>NT $6.7</td>
                                          <td>NT $6.7</td>
                                          <td>NT $6.7</td>
                                          <td>NT $6.7</td>
                                          <td>NT $6.7</td>
                                          <td>NT $6.7</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>日本/南韓</td>
                                          <td>81/82</td>
                                          <td>NT $9.1</td>
                                          <td>NT $9.1</td>
                                          <td>NT $9.1</td>
                                          <td>NT $9.1</td>
                                          <td>NT $9.1</td>
                                          <td>NT $9.1</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>中國</td>
                                          <td>86</td>
                                          <td>NT $8.4</td>
                                          <td>NT $8.4</td>
                                          <td>NT $8.4</td>
                                          <td>NT $8.4</td>
                                          <td>NT $8.4</td>
                                          <td>NT $8.4</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>新加坡</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>馬来西亞</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>美國/加拿大</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                  </tbody>
                              </table> 
                          </div>
                      </div>
                  </div>
              </div>
                  `}
            />
            <CollapsePaper
              container={true}
              title="全部應繳"
              openCollapse={e => { console.log(e) }}
              open={false}
            >
              <p>
                1. 此服務提供您查詢或下載最近6個月的帳單費用及使用明細。<br />
            2. 此服務之帳單費用不包含主帳戶之收費金額，若需查詢請下載帳單PDF檔案。<br />
            3. 明細帳單下載：當您下載檔案後，開啟檔案時請輸入您的身份資料驗證。
              </p>
            </CollapsePaper>
          </div>
        </section>



        <SectionCollapseInfo
          container={true}
          title="注意事項"
          className='mb-2'
          content={`
            1. 此服務提供您查詢或下載最近6個月的帳單費用及使用明細。<br />
            2. 此服務之帳單費用不包含主帳戶之收費金額，若需查詢請下載帳單PDF檔案。<br />
            3. 明細帳單下載：當您下載檔案後，開啟檔案時請輸入您的身份資料驗證。
          `}
        />
        <SectionCollapseInfo
          container={false}
          title="注意事項"
          content={`
            1. 此服務提供您查詢或下載最近6個月的帳單費用及使用明細。<br />
            2. 此服務之帳單費用不包含主帳戶之收費金額，若需查詢請下載帳單PDF檔案。<br />
            3. 明細帳單下載：當您下載檔案後，開啟檔案時請輸入您的身份資料驗證。
          `}
        />

        <section>
          <div className="fui-container">
            <h1 className='m-0'>Tab模組</h1>
          </div>
        </section>
        <section>
          <div className='fui-container'>
            <Tab
              default={0}
              scroll={true}
              name='test-tab'
              list={[
                { name: 'tab-1', label: 'Tab 1' },
                { name: 'tab-1', label: 'Tab 2' },
                { name: 'tab-1', label: 'Tab 3' },
                { name: 'tab-1', label: 'Tab 4' }
              ]}
              onChange={e => {
                return false;
              }}
            />

            <HasMoreTab
              tabs={[
                { name: 'tab-1', label: '遠傳大人物', value: '遠傳大人物', text: '遠傳大人物', index: 0 },
                { name: 'tab-2', label: '醫療商機', value: '醫療商機', text: '醫療商機', index: 1 },
                { name: 'tab-3', label: 'AIOT', value: 'AIOT機', text: 'AIOT', index: 2 },
                { name: 'tab-4', label: '全部期刊', value: '全部期刊', text: '全部期刊', index: 3 },
              ]}
              dropdown={[
                { name: 'tab-5', label: '5G', value: '5G', text: '5G', index: 4 },
                { name: 'tab-6', label: '車聯網', value: '車聯網', text: '車聯網', index: 5 },
                { name: 'tab-7', label: '智慧城市', value: '智慧城市', text: '智慧城市', index: 6 },
              ]}
              onChange={e => {
                return false;
              }}
            />
          </div>
        </section>

        <section>
          <div className="fui-container">
            <h1 className='m-0'>常見問題模組</h1>
          </div>
        </section>
        <Panel className="is-bg-lightgray70 mb-0 p-0">
          <div id="faq-007" className="fui-container">
            <h3 className="is-text-darkgray50 is-text-medium m-0">常見問題</h3>
            <SectionFaq {...{
              collapseList: [
                {
                  title: '世界各國的國碼是什麼？',
                  content: `
                  <p>雖然他沒有什麼意思，但它呈現了語句的節奏性，讓我們看起來像是一段一段文字方塊。</p>
                  <p>過去都會透過下面幾種方法使用 Lorem Ipsum ：</p>
                    <ol>
                      <li>找到網頁，直接複製貼上</li>
                      <li>使用 Lorem Ipsum 產生器</li>
                    </ol>
                    <p>想了解更多可以透過這個連結：http://www.lipsum.com/</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                  `,
                },
                {
                  title: '不是遠傳用戶，也可以撥打 007/017 國際電話嗎？',
                  content: `不是遠傳用戶，也可以撥打 007/017 國際電話嗎？`,
                },
                {
                  title: '請問客服電話是什麼？',
                  content: `請問客服電話是什麼？`,
                },
              ],
              container: false,
              to: '#',
              target: '_blank'
            }} />
          </div>
          <SectionFaqTab
            title="常見問題"
            tabs={[
              { label: '帳單費用/使用記錄' },
              { label: '帳單服務' },
              { label: '繳費操作' },
            ]}
            collapseList={[
              [
                {
                  title: '什麼是遠傳電信『電信帳單代收公用停車費服務』？',
                  content: `
                  <p>雖然他沒有什麼意思，但它呈現了語句的節奏性，讓我們看起來像是一段一段文字方塊。
                  <p>過去都會透過下面幾種方法使用 Lorem Ipsum ：</p>
                    <ol>
                      <li>找到網頁，直接複製貼上</li>
                      <li>使用 Lorem Ipsum 產生器</li>
                    </ol>
                    <p>想了解更多可以透過這個連結：http://www.lipsum.com/</p>
                    <p>有位男子走進深夜食堂對著老闆說到：「老闆我要一碗牛肉麵。」</p>
                    <p>老闆：「啊，我今天還沒準備牛肉… 。」<br/>
                    男子：「沒關係，你可以先把麵煮好，牛肉之後再加。」<br/>
                    老闆：「… 請你出去。」</p>
                    <p>很多時候設計師會收到奇怪的需求，例如：</p>
                    <p>同事 ：「我需要設計一張文宣，上面要宣傳這次活動，然後我希望看起來專業。」<br/>
                    設計：「那文案呢？有文案可以看看嗎？」</p>
                    <p>同事：「還沒出來欸… 你先把日期、地點放上去排一下。」<br/>
                    設計：「你來排。」</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                    <p>上述的事情都跟設計有關，只是我很喜歡拿吃的作比喻。</p>
                    <p>今天的牛肉部位口感偏軟，那是不是麵條要更彈牙有嚼勁呢，牛肉麵中的各項元素都到位，才能完成一碗終極的牛肉麵。</p>
                  `,
                  open: false
                },
                {
                  title: '『電信帳單代收公用停車費服務』適用對象為何？',
                  content: '『電信帳單代收公用停車費服務』適用對象為何？',
                  open: false
                },
                {
                  title: '『電信帳單代收公用停車費服務』最多可以登記幾個車輛號碼？',
                  content: '『電信帳單代收公用停車費服務』最多可以登記幾個車輛號碼？',
                  open: false
                }
              ],
              [
                {
                  title: '什麼是遠傳電信『電信帳單代收公用停車費服務』？',
                  content: '什麼是遠傳電信『電信帳單代收公用停車費服務』？',
                  open: false
                }
              ],
              [
                {
                  title: '什麼是遠傳電信『電信帳單代收公用停車費服務』？',
                  content: '什麼是遠傳電信『電信帳單代收公用停車費服務』？',
                  open: false
                }
              ]
            ]}
          />
        </Panel>

        <section>
          <div className="fui-container">
            <h1 className='m-0'>社群分享模組</h1>
          </div>
        </section>

        <div className="is-bg-lightgray50 pb-md-10 pb-6 pt-1">
          <div className="fui-container">
            <SocialMedia fbLink="#" lineLink="#" size={48} displayContent="分享遠傳優惠" />
          </div>
        </div>

        <section>
          <div className="fui-container">
            <h1 className='m-0'>圖片牌卡模組</h1>
          </div>
        </section>

        <SectionCarousel1 {...{
          className: 'instagram mt-3 mt-md-0',
          title: '#遠傳樂享1加1',
          socialMedia: {
            line: {
              link: "#",
              target: "_blank"
            },
            facebook: {
              link: "#",
              target: "_blank"
            },
            title: "分享遠傳優惠"
          },
          cards: [
            {
              image: '/resources/cbu/img/cbu-promotion-post-1@2x.jpg',
              title: 'syuyikai',
              description: '挑戰✨遠傳iPhone11 樂享1+1 ✨ Kobe曾經在場上相Jordan討教投籃竅，...',
              link: "#",
            },
            {
              image: '/resources/cbu/img/cbu-promotion-post-2@2x.jpg',
              title: 'yylo11',
              description: '❤️遠傳iPhone11 樂享1+1❤️ 搶到跟自己打架的優惠在這兒💨',
              link: '#',
            },
            {
              image: '/resources/cbu/img/cbu-promotion-post-3@2x.jpg',
              title: 'uj85_',
              description: '遠傳iPhone11 樂享1+1 跟大家分享遠傳的優惠購機方案 🔥🔥',
              link: '#',
            },
            {
              image: '/resources/cbu/img/cbu-promotion-post-3@2x.jpg',
              title: 'ponponboxbox!',
              description: '遠傳iPhone11 樂享1+1 有人跟我一樣被iPhone11燒到嗎⁉️ 升級…',
              link: '#',
            },
          ]
        }} />

        <section>
          <div className="fui-container">
            <h1 className='m-0'>合作夥伴模組</h1>
          </div>

          <BrandCarousel id="hiring" partner={this.state.partner} />
        </section>

        <section>
          <div className="fui-container">
            <h1 className='m-0'>07 步驟模組 文字型互動行為</h1>
          </div>
        </section>

        <CardMultiSteps
          bg="/resources/cbu/img/cbu-goshare-bg-yellow3.png"
          {...this.state.steps}
          loadMore={this.loadMore}
        />

        <section>
          <div className="fui-container">
            <h1 className='m-0'>07 步驟模組 圖片型互動行為</h1>
          </div>
        </section>

        <PanelMultiSteps
          // bg="/resources/cbu/img/cbu-goshare-bg-yellow3.png"
          {...this.state.panelSteps}
          loadMore={this.loadMore}
        />

        <section>
          <div className="fui-container">
            <h1 className='m-0'>08 功能特色模組</h1>
            <h2>A牌卡型</h2>
          </div>
        </section>
        <CardFeature {...{
          "title": "在遠傳買，你能獨享...",
          "cards": [
            {
              "type": "card01",
              "image": "/resources/cbu/img/cbu-promotion-feature-movie@2x.jpg",
              "title": "用Retina HD螢幕，享受免費的高畫質電影",
              "list": [
                {
                  "text": "friDay影音90天免費看"
                },
                {
                  "text": "加購friDay影音娛樂包再折$1,100"
                },
              ],
              "action": {
                "btnStyle": "secondary",
                "link": "#",
                "target": "_blank",
                "text": "立即申辦"
              }
            },
            {
              "type": "card01",
              "image": "/resources/cbu/img/cbu-promotion-feature-broken@2x.jpg",
              "title": "新手機，讓遠傳幫你顧",
              "list": [
                {
                  "text": "申辦保險享App Store購物金300"
                },
                {
                  "text": "全省遠傳門市送修服務"
                }
              ]
            },
            {
              "type": "promotion",
              "title": "好禮1+1帶走AirPods享38折，貼補你 iPhone SE 的配件費換手機一次到位",
              "bg": "/resources/cbu/exclusive/cbu-feature-card-red-390x638.jpg",
              "action": {
                "btnStyle": "primary",
                "text": "馬上申辦",
                "link": "#",
                "target": "_self"
              }
            },
            {
              "type": "card01",
              "image": "/resources/cbu/img/cbu-promotion-feature-movie@2x.jpg",
              "title": "續約好康 1+1",
              "list": [
                {
                  "text": "申辦保險享App Store購物金300"
                },
                {
                  "text": "全省遠傳門市送修服務"
                }
              ]
            }
          ]
        }} />
        <section>
          <div className="fui-container">
            <h2>B文字型</h2>
          </div>
        </section>
        <QuoteBanner
          className="is-white"
          topBg={null}
          bottomBg={null}
          bgColor="#f6fb6b"
          content={`<h2 class='with-comma m-0 is-text-black100'>
              QuoteBanner
              <span class="border-bottom is-green30">順暢網路及租借體驗</span>，讓我自由穿梭大街小巷
            </h2>
            `}
        />
        <section>
          <div className="fui-container">
            <h2>C icon型</h2>
          </div>
        </section>
        <SectionFeatureIcon
          title="SectionFeatureIcon"
          bg="/resources/cbu/img/ebu-lightgray-pattern-1440x460.jpg"
          list={[
            {
              title: '準時搶課、交作業',
              content: '快又穩定的網速，幫你爭取時間',
              icon: {
                img: '/resources/cbu/img/email-repost.svg',
                alt: 'img'
              }
            },
            {
              title: '準時搶課、交作業',
              content: '快又穩定的網速，幫你爭取時間',
              icon: {
                img: '/resources/cbu/img/email-repost.svg',
                alt: 'img'
              }
            },
            {
              title: '準時搶課、交作業',
              content: '快又穩定的網速，幫你爭取時間',
              icon: {
                img: '/resources/cbu/img/email-repost.svg',
                alt: 'img'
              }
            },
            {
              title: '準時搶課、交作業',
              content: '快又穩定的網速，幫你爭取時間',
              icon: {
                img: '/resources/cbu/img/email-repost.svg',
                alt: 'img'
              }
            },
          ]}
        />
        <section>
          <div className="fui-container">
            <h1>09 功能特色模組</h1>
            <h2>D 插畫型</h2>
          </div>
        </section>

        <ESectionFeature {...{
          title: '為什麽選擇網路門市？',
          color: 'accent',
          column: 3,
          list: [
            {
              title: '挑戰通路超低價',
              image: '/resources/cbu/estore/e-store-feature-1@2x.png',
              list: [
                { text: '免比價省時省荷包' },
                { text: '全館免運費' },
                { text: '分期0利率沒負擔' },
                { text: '多樣商品真實惠' },
              ]
            },
            {
              title: '輕鬆買安心退',
              image: '/resources/cbu/estore/e-store-feature-2@2x.png',
              list: [
                { text: '流程安全最放心' },
                { text: '專程解說超安心' },
                { text: '超商/門市配送好貼心' },
              ]
            },
            {
              title: '售後服務最到位',
              image: '/resources/cbu/estore/e-store-feature-3@2x.png',
              list: [
                { text: '訂單成立最快2天到貨' },
                { text: '門號可攜斷訊1天賠償百元' },
                { text: '鑑賞期10天保證輕鬆買' }
              ]
            },
          ]
        }} />
        <section>
          <div className="fui-container">
            <h2>E 情境Banner型</h2>
          </div>
        </section>
        <SectionBanner2
          position='center'
          className="is-500 is-prmotion-banner mb-0 top-0 content-right"
          image={{
            md: '/resources/cbu/img/cbu-feature-scenario-goshareB-1920x500.jpg',
            sm: '/resources/cbu/img/cbu-feature-scenario-goshareB-750x984.jpg',
          }}
          title='
          <div class="mb-2">
            <h3 class="">
            滿足日常移動需求
            </h3>
            <p class="body mb-md-5 mb-4">方便快速租借，輕鬆上路</p>
            <h3 class="">
              通勤族新選擇
            </h3>
            <p class="body mb-md-5 mb-4">輕巧自在騎乘，短途通勤輕鬆有型</p>
            <h3 class="">
            幫你打氣充滿電
            </h3>
            <p class="body mb-md-5 mb-4">搭配無線充電手機架，滿電出發</p>
          </div>'
          action={null}
        />
        <SectionBanner2
          position='center top'
          className="is-500 is-prmotion-banner top-0"
          image={{
            md: '/resources/cbu/img/cbu-feature-scenario-goshareA-1920x500.jpg',
            sm: '/resources/cbu/img/cbu-feature-scenario-goshareA-750x984.jpg',
          }}
          title='
          <div class="mb-2">
            <h3 class="">
              城市探險好夥伴
            </h3>
            <p class="body mb-md-5 mb-4">絕佳性能與續航力，城市探險的最佳選擇</p>
            <h3 class="">
              隨借隨還
            </h3>
            <p class="body mb-md-5 mb-4">想去哪就去哪，營運範圍內的合法路側停車格</p>
            <h3 class="">
              獨一無二的騎乘體驗
            </h3>
            <p class="body mb-md-5 mb-4">Gogoro 智慧雙輪，提供使用者絕佳的騎乘體驗</p>
          </div>'
          action={null}
        />
        <section>
          <div className="fui-container">
            <h1>10 資費模組：資費簡易牌卡</h1>
          </div>
        </section>
        <CardPlanBasic
          title="CardPlanBasic"
          list={[
            {
              title: '1+1好禮獎不完，AirPods輕鬆購',
              list: [
                '上網吃到飽不限速',
                '網內免費，網外950分鐘',
                '送總額$4800GoShare騎乘金',
                'AirPods $1,990或AirPods Pro $4,690或其他優惠'
              ],
              tooltip: '此處上網用量是指您該月份帳單內所有門號的上網用量加總，各門號使用明細請至「帳單明細」查詢。',
              unit: '月租',
              price: '999',
              tag: {
                text: '熱門方案',
                color: 'red'
              },
              button: [
                {
                  title: '申辦方案',
                  link: '#',
                  target: '_self'
                }
              ]
            },
            {
              title: '1+1好禮獎不完，AirPods輕鬆購',
              list: [
                '上網吃到飽不限速',
                '網內免費，網外950分鐘',
                '送總額$4800 GoShare騎乘金',
                'AirPods $1,990或AirPods Pro $4,690或其他優惠'
              ],
              unit: '月租',
              price: '999',
              tag: {
                text: '熱門方案',
                color: 'red'
              },
              button: [
                {
                  title: '申辦方案',
                  link: '#',
                  target: '_self'
                }
              ]
            },
            {
              title: '1+1好禮獎不完，AirPods輕鬆購',
              list: [
                '上網吃到飽不限速',
                '網內免費，網外950分鐘',
                '送總額$4800 GoShare騎乘金',
                'AirPods $1,990或AirPods Pro $4,690或其他優惠'
              ],
              unit: '月租',
              price: '999',
              tag: {
                text: '熱門方案',
                color: 'red'
              },
              button: [
                {
                  title: '申辦方案',
                  link: '#',
                  target: '_self'
                }
              ]
            },
            {
              title: 'AirPods Pro只要0元',
              unit: '月租',
              price: '1399',
              list: [
                '上網吃到飽不限速',
                '網內免費，網外1300分鐘</',
                '送總額$4800GoShare騎乘金',
                'AirPods Pro $0或其他3C夯品優惠'
              ],
              button: [
                {
                  title: '申辦方案',
                  link: '#',
                  target: '_self'
                }
              ]
            }
          ]}
          bottomBg="/resources/cbu/img/cbu-goshare-bg-blue2.png"
        />
        <section>
          <div className="fui-container">
            <h1>11 資費模組：牌卡表格</h1>
          </div>
        </section>

        {/* {
          !PlanMock ? null : <DevicePlanTable
            filterTitle="規格"
            {...this.state.planTabs}
            filterArr={this.state.filterArr}
            resetFilterContent={this.resetFilterContent}
            programList={
              [
                { value: '32 GB', text: '32 GB', label: '32 GB' },
                { value: '64 GB', text: '64 GB', label: '64 GB' },
                { value: '128 GB', text: '128 GB', label: '128 GB' }
              ]
            }
            selected={this.state.selectedPlan}
            filtering={(val) => { this.filtering(val) }}
            onChange={(val) => this.tableOnChange(val)}
          />
        } */}
        {
          !PlanMock ? null : <DevicePlanTable
            filterTitle="規格"
            {...this.state.planTabs}
            filterArr={this.state.filterArr}
            resetFilterContent={this.resetFilterContent}
            programList={
              [
                { value: '32 GB', text: '32 GB', label: '32 GB' },
                { value: '64 GB', text: '64 GB', label: '64 GB' },
                { value: '128 GB', text: '128 GB', label: '128 GB' },
                { value: '256 GB', text: '256 GB', label: '256 GB' },
              ]
            }
            selected={this.state.selectedPlan}
            filtering={(val) => { this.filtering(val) }}
            onChange={(val) => this.tableOnChange(val)}
          />
        }

        <section>
          <div className="fui-container">
            <h1>14 資費模組：一般表格</h1>
          </div>
        </section>
        <section className="">
          <div className="fui-container">
            <Panel className=''>
              <h4 className="mb-4 mt-0">
                更多學生方案
            </h4>
              <PanelTab
                title='桃園機場購買地點'
                activeTab={0}
                tabs={{
                  name: 'panel-tab',
                  default: 0,
                  list: [
                    { name: 'panel-tab-1', label: '366月租' },
                    { name: 'panel-tab-2', label: '488月租' },
                    { name: 'panel-tab-4', label: '588月租' },
                  ],
                }}>
                <div className='fui-segments no-shadow'>
                  <div className="compareTable stick-to-right mt-md-0 mt-3 mb-2">
                    <div className="tableLeft">
                      <div className="tableHead">
                        <table>
                          <tbody>
                            <tr >
                              <td className="align-center is-text-bold is-bg-lightgray70">維修中心</td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="align-left pl-3">台大服務中心</td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="align-left pl-3">台南服務中心</td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="align-left pl-3">高雄建國服務中心</td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="align-left pl-3">花蓮中正服務中心</td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="align-left pl-3">中壢Nova服務中心</td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="align-left pl-3">台中Nova服務中心</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="tableRight">
                      <div className="tableBody">
                        <div className="spaceBox">
                          <table className="compareList odd">
                            <tbody>
                              <tr className='is-bg-lightgray70'>
                                <td className="is-text-bold">
                                  地址
                              </td>
                                <td className="is-text-bold">
                                  電話
                              </td>
                                <td className="is-text-bold">
                                  營業時間
                              </td>
                              </tr>
                              <tr className='is-bg-white'>
                                <td className="is-text-regular align-left pl-3">
                                  台北市中正區羅斯福路三段292號3樓
                              </td>
                                <td className="is-text-regular align-left pl-3 is-text-accent">
                                  02-2369-0958
                              </td>
                                <td rowSpan="4" className="is-text-regular align-left pl-3">
                                  週一~週六 12:00-21:00<br />
                                週六僅收件、取件無現場維修。
                              </td>
                              </tr>
                              <tr className='is-bg-white'>
                                <td className="is-text-regular align-left pl-3">
                                  台南市中西區民族路二段231號2樓
                              </td>
                                <td className="is-text-regular align-left pl-3 is-text-accent">
                                  06-222-8497
                              </td>
                              </tr>
                              <tr className='is-bg-white'>
                                <td className="is-text-regular align-left pl-3">
                                  高雄市三民區建國二路150號
                              </td>
                                <td className="is-text-regular align-left pl-3 is-text-accent">
                                  07-236-1625
                              </td>
                              </tr>
                              <tr className='is-bg-white'>
                                <td className="is-text-regular align-left pl-3">
                                  花蓮市中正路511號3樓
                              </td>
                                <td className="is-text-regular align-left pl-3 is-text-accent">
                                  03-8310-735
                              </td>
                              </tr>
                              <tr className='is-bg-white'>
                                <td className="is-text-regular align-left pl-3">
                                  中壢市中正區389號3樓
                              </td>
                                <td className="is-text-regular align-left pl-3 is-text-accent">
                                  03-491-9415
                              </td>
                                <td rowSpan="2" className="is-text-regular align-left pl-3">
                                  週一~週日 11:00-21:00<br />
                                週六、週日僅收件、取件無現場維修。
                              </td>
                              </tr>
                              <tr className='is-bg-white'>
                                <td className="is-text-regular align-left pl-3">
                                  台中市西區英才路506號2樓206櫃位
                              </td>
                                <td className="is-text-regular align-left pl-3 is-text-accent">
                                  04-2329-1708
                              </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>hi</div>
                <div>hi</div>
              </PanelTab>
            </Panel>
            <Panel className=''>
              <h4 className="mb-4 mt-0">
                更多學生方案
            </h4>
              <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                  <div className="device-table-dropdown d-flex flex-align-center mb-md-2 mb-0">
                    <h5 className="my-0 mr-2" style={{ flex: '1 1 120px' }}>月租費</h5>
                    <Dropdown
                      className="is-button"
                      list={
                        [
                          { value: '32 GB', text: '32 GB', label: '32 GB' },
                          { value: '64 GB', text: '64 GB', label: '64 GB' },
                          { value: '128 GB', text: '128 GB', label: '128 GB' },
                          { value: '256 GB', text: '256 GB', label: '256 GB' },
                        ]
                      }
                      label='32 GB'
                      arrow={true}
                      hasCheck={false}
                      onChange={(item, idx, e) => this.spec(item, idx, e)}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className='fui-segments no-shadow'>
                <div className="compareTable stick-to-right mt-md-0 mt-3 mb-2">
                  <div className="tableLeft">
                    <div className="tableHead">
                      <table>
                        <tbody>
                          <tr >
                            <td className="align-center is-text-bold is-bg-lightgray70">維修中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">台大服務中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">台南服務中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">高雄建國服務中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">花蓮中正服務中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">中壢Nova服務中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">台中Nova服務中心</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tableRight">
                    <div className="tableBody">
                      <div className="spaceBox">
                        <table className="compareList odd">
                          <tbody>
                            <tr className='is-bg-lightgray70'>
                              <td className="is-text-bold">
                                地址
                              </td>
                              <td className="is-text-bold">
                                電話
                              </td>
                              <td className="is-text-bold">
                                營業時間
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                台北市中正區羅斯福路三段292號3樓
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                02-2369-0958
                              </td>
                              <td rowSpan="4" className="is-text-regular align-left pl-3">
                                週一~週六 12:00-21:00<br />
                                週六僅收件、取件無現場維修。
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                台南市中西區民族路二段231號2樓
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                06-222-8497
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                高雄市三民區建國二路150號
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                07-236-1625
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                花蓮市中正路511號3樓
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                03-8310-735
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                中壢市中正區389號3樓
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                03-491-9415
                              </td>
                              <td rowSpan="2" className="is-text-regular align-left pl-3">
                                週一~週日 11:00-21:00<br />
                                週六、週日僅收件、取件無現場維修。
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                台中市西區英才路506號2樓206櫃位
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                04-2329-1708
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
          </div>
        </section>
        <section>
          <div className="fui-container">
            <h1>16 商品模組</h1>
          </div>
        </section>
        <CardProduct
          title='CardProduct'
          list={[
            {
              hotTag: { text: '熱門商品', color: 'red' },
              tag: ['5G手機'],
              name: 'iPhone SE 紅色',
              list: [
                { value: '0', text: '128 GB' },
                { value: '1', text: '256 GB' },
              ],
              brand: 'APPLE',
              image: [
                '/resources/cbu/img/estore-product-thumb-01@2x.jpg',
                '/resources/cbu/img/estore-product-thumb-01@2x.jpg',
                '/resources/cbu/img/estore-product-thumb-01@2x.jpg',
                '/resources/cbu/img/estore-product-thumb-01@2x.jpg'
              ]
            }
          ]}
          onChange={(e) => this.onChange(e)}
        />
        <CardProduct
          title='CardProduct'
          list={[
            {
              title: '宅在家，微上網',
              img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
              imgAlt: '',
              price: '月付<span class="">$1,399</span><br/>專案價<span class="is-text-error">$0</span>',
              brand: 'SAMSUNG',
              name: 'SAMSUNG Galaxy A20',
              hottag: {
                text: '0元拿手機',
                color: 'red'
              },
              tag: ['福利A品', '福利B品'],
              specList: [
                { value: '0', text: '128 GB' },
                { value: '1', text: '256 GB' },
              ]
            },
            {
              title: '吃到飽，小寒暄',
              img: '/resources/cbu/img/cbu-promotion-product-1@2x.jpg',
              imgAlt: '',
              price: '專案價 <span class="is-text-error">$0</span>',
              brand: 'Xiaomi',
              name: '紅米 NOTE 8T 64GB',
              hottag: {
                text: '0元拿手機',
                color: 'blue'
              },
              tag: [],
              specList: [
                { value: '0', text: '128 GB' },
                { value: '1', text: '256 GB' },
              ]
            },
            {
              title: '吃到飽，暢快聊',
              img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
              imgAlt: '',
              unit: '專案價',
              price: '<span>專案價</span><div class="price is-text-error">$9,500</div>',
              brand: 'APPLE',
              name: 'iPhone SE 64G',
              hottag: {
                text: '熱門方案',
                color: 'gray'
              },
              tag: [],
              specList: [
                { value: '0', text: '128 GB' },
                { value: '1', text: '256 GB' },
              ]
            },
            {
              title: '吃到飽，暢快聊',
              img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
              imgAlt: '',
              unit: '專案價',
              price: '<span>專案價</span><div class="price is-text-error">$9,500</div>',
              brand: 'APPLE',
              name: 'iPhone SE 64G',
              hottag: {
                text: '熱門方案',
                color: 'red'
              },
              tag: [],
              specList: [
                { value: '0', text: '128 GB' },
                { value: '1', text: '256 GB' },
              ]
            },
            {
              title: '吃到飽，暢快聊',
              img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
              imgAlt: '',
              unit: '專案價',
              price: '<span>專案價</span><div class="price is-text-error">$9,500</div>',
              brand: 'APPLE',
              name: 'iPhone SE 64G',
              hottag: {
                text: '熱門方案',
                color: 'red'
              },
              tag: [],
              specList: [
                { value: '0', text: '128 GB' },
                { value: '1', text: '256 GB' },
              ]
            },
          ]}
          onChange={(e) => this.onChange(e)}
        />

        <CardPhonePlan
          title="CardPhonePlan"
          list={[
            {
              title: '宅在家，微上網',
              img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
              imgAlt: '',
              price: '月付<span class="">$1,399</span><br/>專案價<span class="is-text-error">$0</span>',
              brand: 'SAMSUNG',
              name: 'SAMSUNG Galaxy A20',
              hottag: {
                text: '0元拿手機',
                color: 'red'
              },
              tag: ['福利A品'],
            },
            {
              title: '吃到飽，小寒暄',
              img: '/resources/cbu/img/cbu-promotion-product-1@2x.jpg',
              imgAlt: '',
              price: '專案價 <span class="is-text-error">$0</span>',
              brand: 'Xiaomi',
              name: '紅米 NOTE 8T 64GB',
              hottag: {
                text: '0元拿手機',
                color: 'red'
              },
              tag: [],
            },
            {
              title: '吃到飽，暢快聊',
              img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
              imgAlt: '',
              unit: '專案價',
              price: '<span>專案價</span><div class="price is-text-error">$9,500</div>',
              brand: 'APPLE',
              name: 'iPhone SE 64G',
              hottag: {
                text: '熱門方案',
                color: 'red'
              },
              tag: [],
            },
            {
              title: '吃到飽，暢快聊',
              img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
              imgAlt: '',
              unit: '專案價',
              price: '<span class="label">商品價</span><del>$5,600</del><b class="price is-text-error">$10,500</b></div>',
              brand: 'SAMSUNG',
              name: 'SAMSUNG Galaxy A20',
              hottag: {
                text: '熱門方案',
                color: 'red'
              },
              tag: [],
            },
          ]}
        />
        <CardRecommend
          program={[
            {
              icon: '/resources/cbu/img/cbu-promotion-plan-1@2x.png',
              iconAlt: 'program1',
              name: 'iPhoneSE',
              title: '０元拿手機',
              list: [
                '吃到飽不降速',
                '每月網外送200分鐘'
              ],
              tag: {
                text: '最經濟',
                color: 'red'
              },
              button: [
                {
                  title: '了解更多',
                  link: '#',
                  target: '_self'
                }
              ]
            },
            {
              icon: '/resources/cbu/img/cbu-promotion-plan-2@2x.png',
              iconAlt: 'program1',
              name: 'iPhone11 ',
              title: '好禮1+1 輕鬆帶走AirPods',
              list: [
                '搭配無限充電盒',
                '會員續約再折$5,000'
              ],
              tag: {
                text: '最佳續約',
                color: 'red'
              },
              button: [
                {
                  title: '了解更多',
                  link: '#',
                  target: '_self'
                }
              ]
            },
            {
              icon: '/resources/cbu/img/cbu-promotion-plan-3@2x.png',
              iconAlt: 'program1',
              name: 'iPhoneSE ',
              title: '最省荷包拿大容量',
              list: [
                '專案價只貼$8,400',
                '送$500配件購物金'
              ],
              tag: {
                text: '最熱門',
                color: 'red'
              },
              button: [
                {
                  title: '了解更多',
                  link: '#',
                  target: '_self'
                }
              ]
            },
            {
              icon: '/resources/cbu/img/cbu-promotion-plan-3@2x.png',
              iconAlt: 'program1',
              name: 'iPhoneSE ',
              title: '輕量版',
              list: [
                '加量不加價，升級4GB',
                '流量可自動無限展延'
              ],
              tag: {
                text: '最熱門',
                color: 'red'
              },
              button: [
                {
                  title: '了解更多',
                  link: '#',
                  target: '_self'
                }
              ]
            }
          ]}
          title="CardRecommend"
        />
        <CardRoamingSingle
          title="CardRoamingSingle"
          image='/resources/cbu/img/section-product-card.jpg'
          imgAlt=''
          price='月付<span class="">$1399</span><br/>專案價<span class="is-text-error">$0</span>'
          meta='5日上網不限速'
          coupon={{
            name: "優惠序號",
            code: "CH88"
          }}
          button={{
            name: '立即購買',
            target: '_blank',
            link: '#'
          }}
          hottag={
            {
              text: '0元拿手機',
              color: 'red'
            }
          }
          name='遠遊卡2.0 日本5日不降速上網卡'
          content='<div class="body-1">商品價<del>$1,299</del>再享<span>88折</span></div>'
        />
        <SectionProductCard
          {...this.state.sectionProduct}
          loadMoreFunc={this.ProductCardLoadMore}
        />

        <Formsy
          className='pt-6 is-bg-lightgray100'
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          onInvalidSubmit={this.disableButton}
          ref={this.form}>
          <div className="fui-container">
            <h4 className='form-description is-text-darkgray50 my-7'>
              填寫您的需求，專家將提供您專業的建議與解決方案。
            </h4>
            <CheckGroup
              onChange={this.onChange}
              validationErrors={{ isDefaultRequiredValue: '請選擇階段' }}
              label='您有興趣了解'
              name='interesting01'
              required={this.state.form.interesting01.required}
              default={this.state.form.interesting01.value}
              options={[
                { value: '0', label: '雲端服務建議' },
                { value: '1', label: '行動通訊服務' },
                { value: '2', label: '強化資訊安全' },
                { value: '3', label: '大數據分析' },
                { value: '4', label: 'VPN 數據服務' },
                { value: '5', label: 'IDC 代客服務' }
              ]}
            />
            <RadioGroup
              validationErrors='請選擇項目'
              onChange={this.onChange}
              label='您有興趣了解'
              name='interesting02'
              className="mb-md-5 mb-2"
              required={this.state.form.interesting02.required}
              default={this.state.form.interesting02.value}
              options={[
                { value: '0', label: '雲端服務建議' },
                { value: '1', label: '行動通訊服務' },
                { value: '2', label: '強化資訊安全' },
                { value: '3', label: '大數據分析' },
                { value: '4', label: 'VPN 數據服務' },
                { value: '5', label: 'IDC 代客服務' }
              ]}
            />
            <LabelTextarea
              name='comment'
              required={this.state.form.comment.required}
              value={this.state.form.comment.value}
              validationErrors={{
                isDefaultRequiredValue: '您可以在此輸入需求...'
              }}
              label='還有其他需求想讓我們知道嗎？'
              placeholder='您可以在此輸入需求...'
              maxLength={200}
              onChange={this.onChange}
            />
            <div className='input-group'>
              <div className='recaptcha'>
                <img src='https://cldup.com/p-jwfiXPY0-3000x3000.png' alt='recaptcha' />
              </div>
              <CheckboxCollapse
                name='agree'
                required
                value={this.state.form.agree.value}
                validations={{
                  isChecked: form => this.state.form.agree
                }}
                validationErrors={{ isDefaultRequiredValue: '請同意個人資料保護法第8條所為之告知事情' }}
                collapseContent={`<p>您已知悉並同意遠傳電信股份有限公司(以下稱本公司)將您所留下的個人資料(包括姓名、電子郵件地址、電話號碼及其他您同意填寫的資料)，進行蒐集、利用與處理。本公司不會將您的個人資料傳輸予第三方，您的個人資料僅利用來與您為後續業務之連繫使用。於業務連繫期間，您有權隨時向本公司申請補充、更正、查閱或提供您個人資料之複本，您亦有權要求本公司停止蒐集、處理、利用或刪除您的個人資料(提醒您注意，於此同時，本公司將無法繼續提供您完善的服務)。您欲行使前述各項權利時，本公司的企業客服窗口能受理您的請求。您可以在本公司企業服務官網(www.fetnet.net/enterprise)找到企業客服電話，謝謝！</p>`}
                label='我已知悉並了解遠傳依個人資料保護法第8條所為之告知事情'
                getCheckbox={e => this.onChange('agree', e)}
                checked={this.state.form.agree.value}
              />
            </div>

            <div className="page-action">
              <button onClick={this.submit} className={`is-large fui-button is-primary`} disabled={!this.state.form.agree.value || !this.state.canSubmit}>
                <span className='text'>{this.state.isEn ? 'Submit' : '確認送出'}</span>
              </button>
              <button onClick={() => this.setState({ modalOpen: true })} className={`is-large fui-button is-primary`} disabled={!this.state.form.agree.value || !this.state.canSubmit}>
                <span className='text'>{this.state.isEn ? 'Submit' : '確認送出(Modal)'}</span>
              </button>
            </div>
            {this.state.modalOpen ? <Modal
              onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
              onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
              isOpen={this.state.modalOpen}
              contentLabel='Example Modal'
              className={this.state.currentModal ? 'fui-app-trial-modal' : 'fui-prompt-modal'}>
              {this.state.currentModal ? (
                this.state.currentModal.type === 'app-trial' ? (
                  <div className='fui-app-trial-body'>
                    <img src={this.state.currentModal.md} className='d-none d-md-block' alt='' />
                    <img src={this.state.currentModal.sm} className='d-block d-md-none' alt='' />
                    <div className='fui-buttons two-button'>
                      <Button
                        btnStyle='primary'
                        link={this.state.currentModal.link}
                        target={this.state.currentModal.target}>
                        詳細內容
                        </Button>
                      <Button btnStyle='secondary' onClick={(e) => this.setState({ modalOpen: false })}>
                        關閉
                        </Button>
                    </div>
                  </div>
                ) : (
                    <div className='gamer_center'>
                      <div className='content' dangerouslySetInnerHTML={{ __html: this.state.currentModal.content }}></div>
                      <div className='fui-buttons two-button'>
                        <Button btnStyle='secondary' onClick={(e) => this.setState({ modalOpen: false })}>
                          關閉
                        </Button>
                      </div>
                    </div>
                  )
              ) : <div className='fui-prompt-modal-body'>
                  <h5 className='align-center'>報名成功</h5>
                  <img style={{ width: '140px' }} src={'/resources/cbu/e-service/images/completed.png'} srcSet={'/resources/cbu/e-service/images/completed@2x.png'} alt='' />
                  <p className='mt-0 align-center'>我們將在收到訊息後，請專人與你聯絡</p>
                  <div className="page-action">
                    <Button onClick={() => { this.setState({ modalOpen: false }) }} btnStyle='primary' className=''>確認</Button>
                  </div>
                </div>
              }

            </Modal> : null}
          </div>
        </Formsy>

      </main >
    );
  }
}

export default PromotionModule;