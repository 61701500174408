export const FooterMock = {
  ad: {
    image: {
      md: '/resources/ebu/images/ebu-medium-ad-01.jpg',
      sm: '/resources/ebu/images/ebu-medium-ad-01-sm.jpg',
    },
    title: '您隨身的雲端辦公室，無論在哪都能使用一流的高生產力工具！',
    action: {
      text: '了解Office 365輕享',
      link: '#',
    }
  },
  contactInfo: `
        <p class="text-sm phone-promo mb-0">
            遠傳手機直撥 <em>888</em> 或 <em>123</em><br/>
        </p>
        <p class="text-sm">
            MVPN 用戶撥 99888 或 123 (免費)<br/>
            市話撥 449-9365 (市內計費)。 
        </p>
    `,
  moreContact: {
    text: '更多聯絡方式',
    link: '#'
  },
  social: {
    facebook: '#',
    youtube: '#',
    instagram: '#'
  },
  brand: {
    img: '/resources/common/images/fetnet-footer-logo.png',
    link: '/ebu/index'
  },
  menu: [
    {
      title: '關於',
      list: [
        { link: '#', text: '關於遠傳' },
        { link: '#', text: '企業社會責任' },
        { link: '#', text: '菁英招募' },
        // { link: '#', text: '電子型錄下載' },
      ],
    },
    {
      title: '合作',
      list: [
        { link: '#', text: '合作提案' },
        { link: '#', text: '數位行銷服務' },
      ],
    },
    {
      title: '條款相關',
      list: [
        { link: '#', text: '營業規章及服務契約' },
        { link: '#', text: '隱私權政策' },
        { link: '#', text: '著作權政策' },
      ],
    },
    {
      title: '相關網站',
      list: [
        { link: '#', text: '全虹購物城' },
        { link: '#', text: 'CONEXUS' },
        { link: '#', text: '亞東證券理財網' },
        { link: '#', text: '遠東人網站' },
        { link: '#', text: '行政院消保會' },
      ],
    },
  ],
  copyright: '遠傳電信版權所有 © Far EasTone<span class="d-none d-md-inline"> Telecommunications Co., Ltd</span>.',
  lang: { value: 'zh-TW', text: '繁體中文' },
  langMenu: [
    { value: '/ebu/index', text: '繁體中文' },
    { value: '/en/ebu/index', text: 'English' },
  ],
};
