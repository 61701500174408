import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import CheckGroup from '../../components/form/CheckGroup';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Modal from 'react-modal';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'success',
      promotion: {
        title: '你的專屬優惠',
        cards: [
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
            title: '本月影片免費看!',
            description: '話題電影、日劇、韓劇等等應有盡有',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
            title: '中午不知道吃什麼？',
            description: 'Uber Eats 送遠傳新客優惠金200元',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
            title: '連假打算出國？',
            description: '遠遊卡吃到飽上網不再煩惱用量 ',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
            title: '本月影片免費看!',
            description: '話題電影、日劇、韓劇等等應有盡有',
            link: '#',
          },
        ]
      }
    }
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />

        <section className="e-service-result is-success mt-4">
          <div className="fui-container">
            <Grid container>
              <Grid item xs={12}>
                {
                  this.state.type !== 'fail' ? (
                    <img src={'/resources/cbu/e-service/images/completed.png'} srcSet={'/resources/cbu/e-service/images/completed@2x.png'} alt='' />
                  ) : (
                      <img src={'/resources/cbu/e-service/images/failed.png'} srcSet={'/resources/cbu/e-service/images/failed@2x.png'} alt='' />
                    )
                }

                <div className="content">
                  <h4 className="mb-0 mt-3">{this.state.title || ''}</h4>
                  <div className='body1'>
                    <h4 className="mb-1">完成申請</h4><p className="mt-0 mb-4">您已完成 4G VolET 申請，月租費將於您下期的帳單內收取。謝謝。提醒您，服務已申請完成，但您仍需於您的手機上開啟 VoLTE 設定，方可使用</p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="paper">
              <h4 className='align-left'>設定詳情</h4>
              <table className="text-wrap gray-th bill-table table-fixed">
                <tbody>
                  <tr>
                    <th className="align-center">項目</th>
                    <th className="align-center">狀態</th>
                  </tr>
                  <tr>
                    <td className="align-center">VolET設定</td>
                    <td className="align-center">開啟</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='fui-buttons two-buttons mt-md-5 mt-2 pt-2'>
              <Button className='d-block d-md-inline-block' link="#" btnStyle='primary' size='large'>返回 VoLTE 設定</Button>
              <Button className='d-block d-md-inline-block' link="#" btnStyle="secondary" size='large'>返回個人專區</Button>
            </div>


          </div>
          <SectionCarousel1 {...this.state.promotion} />
        </section>
      </main >
    )
  }
}

export default Success;