import React, { Component } from 'react'

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import NavContentTab2 from '../../components/partials/NavContentTab2';
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import Panel from '../../components/panel/Panel';
import PanelTab from '../../components/panelContent/PanelTab';
import ApplicationSection from '../../components/partials/ApplicationSection'
import * as Mock from '../../mock/Application.js';
class AddValue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSubTab: 0,
            tabs: {
                name: 'add-value-tab',
                icon: true,
                title: false,
                list: [
                    {
                        name: 'add-value',
                        label: '預付卡儲值',
                        link: '/prepaid/add-value',
                        icon: '/resources/cbu/prepaid/images/prepaid-add-value.svg',
                        focusIcon: '/resources/cbu/prepaid/images/prepaid-add-value-focus.svg',
                    },
                    {
                        name: 'application',
                        label: '預付卡申辦',
                        link: '/prepaid/application',
                        icon: '/resources/cbu/prepaid/images/prepaid.svg',
                        focusIcon: '/resources/cbu/prepaid/images/prepaid-focus.svg',
                    },
                ],
                default: 1
            }
        }
        this.programSetting = {
            dots: false,
            infinite: false,
            arrows: true,
            slidesToShow: 3,
            draggable: true,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        arrows: true,
                        slidesToShow: 1,
                        variableWidth: true,
                    },
                },
            ],
        };
    }
    changeMainTab = value => {
        this.setState({
            currentMainTab: value,
        });
        return value === 0 ? this.props.history.push('/cbu/5g') : null
    };
    changeSubTab = value => {
        this.setState({
            currentSubTab: value,
        });
    };
    render() {
        return (
            <main className="">

                <NavAnchor
                    pageTitle='<a href="#">預付卡</a>'
                    button={{
                        link: '#',
                        text: '立即儲值',
                    }}
                    tabs={[
                        { label: '預付卡方案', link: '#addValue' },
                        { label: '為什麼是預付卡', link: '#whyPrepaid' },
                        { label: '申辦方式', link: '#wayOfApply' },
                        { label: '更多優惠', link: '#promotion' },
                        { label: '常見問題', link: '#help' }
                    ]}
                />
                <Breadcrumb
                    breadcrumb={[
                        { text: '首頁', link: '/' },
                        { text: '預付卡', link: '/' }
                    ]}
                    color='white'
                />
                <SectionBanner2
                    image={{
                        md: '/resources/cbu/prepaid/images/cbu-prepaid-banner-02-1920x470.jpg',
                        sm: '/resources/cbu/prepaid/images/cbu-prepaid-banner-02-750x660.jpg',
                    }}
                    title='超省$1.8 親子輕鬆講'
                    description='網內、網外或市話，每分鐘只要$1.8'
                    action={{
                        text: '了解更多',
                        link: '#',
                    }} />
                <NavContentTab2 tabs={this.state.tabs} onChange={e => this.changeMainTab(e)} />
                <ApplicationSection
                    id="addValue"
                    h1="預付卡方案免綁約、零負擔"
                    cards={Mock.tabContent[0].tabContent.cards}
                    title={Mock.tabContent[0].tabContent.title}
                    ad={{
                        patternColor: 'green',
                        title: `現在就到離你最近的門市申辦預付卡吧！`,
                        action: [
                            {
                                text: '查詢遠傳門市',
                                line: '#',
                                btnStyle: 'primary',
                            },
                            {
                                text: '查詢全虹門市',
                                line: '#',
                            },
                        ]
                    }}
                    applySteps={
                        {
                            title: '申辦預付卡，3步驟輕鬆完成！',
                            subtitle: '申辦成功後，可再依上網或講電話需求，儲值語音或上網',
                            background: '/resources/cbu/prepaid/images/cbu-prepaid-step-1920x470.jpg',
                            mobileBackground: '/resources/cbu/prepaid/images/cbu-prepaid-step-mobile.jpg',
                        }
                    }
                    carousel={{
                        title: '更多優惠',
                        id: "promotion",
                        cards: [
                            {
                                image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
                                title: '超省$1.8親子輕鬆講',
                                description: '網內、網外或市話，每分鐘只要$1.8元，家人、朋友，怎麼撥打都划算。',
                                link: '#',
                            },
                            {
                                image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
                                title: '超省$1.8親子輕鬆講',
                                description: '網內、網外或市話，每分鐘只要$1.8元，家人、朋友，怎麼撥打都划算。',
                                link: '#',
                            },
                            {
                                image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
                                title: '超省$1.8親子輕鬆講',
                                description: '網內、網外或市話，每分鐘只要$1.8元，家人、朋友，怎麼撥打都划算。',
                                link: '#',
                            },
                            {
                                image: '/resources/cbu/prepaid/images/prepaid-sale-promotion-02.jpg',
                                title: '超省$1.8親子輕鬆講',
                                description: '網內、網外或市話，每分鐘只要$1.8元，家人、朋友，怎麼撥打都划算。',
                                link: '#',
                            },
                        ],
                        more: { text: '看更多', link: '#' },
                    }}
                />
                <Panel className="is-bg-lightgray70 px-0">
                    <div className="fui-container" id="help">
                        <h3 className="is-text-darkgray50 is-text-medium m-0">常見問題</h3>
                        <PanelTab
                            activeTab={1}
                            tabs={{
                                name: 'panel-tab',
                                default: 1,
                                list: [
                                    { name: 'panel-tab-1', label: '預付卡儲值' },
                                    { name: 'panel-tab-2', label: '預付卡申辦' },
                                ],
                            }}>
                            <SectionFaq {...{
                                collapseList: [
                                    {
                                        title: '語音通話的購買通路有哪些？',
                                        content: `語音通話的購買通路有哪些？`,
                                    },
                                    {
                                        title: '巨量型上網的上網傳輸量沒用完怎麼辦？',
                                        content: `巨量型上網的上網傳輸量沒用完怎麼辦？`,
                                    },
                                    {
                                        title: '每個儲值方式都可享有優惠嗎？  ',
                                        content: `每個儲值方式都可享有優惠嗎？  `,
                                    },
                                ],
                                to: '#',
                                container: false
                            }} />
                            <SectionFaq {...{
                                collapseList: [
                                    {
                                        title: '門號卡的效期是多久？',
                                        content: `門號卡的效期是多久？`,
                                    },
                                    {
                                        title: '一張身份證可以申請幾個門號卡？',
                                        content: `一張身份證可以申請幾個門號卡？`,
                                    },
                                    {
                                        title: '20歲以下的人可以購買預付卡嗎？  ',
                                        content: `20歲以下的人可以購買預付卡嗎？  `,
                                    },
                                ],
                                to: '#',
                                container: false
                            }} />
                        </PanelTab>
                    </div>
                </Panel>
            </main>
        );
    }
}

export default AddValue;