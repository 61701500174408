import React from 'react';
import Button from '../../Button';
import Link from '../../Link';
import PropTypes from 'prop-types';

class ExtraLinkCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
    };

    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        let isMobile = window.innerWidth < 960;
        this.setState({ isMobile: isMobile });
        // console.log(this.state.isMobile);
      });
  }
  renderButton = () => {
    const url = typeof window !== 'undefined' && window.location.origin + this.props.content[0].link;
    if (this.props.cardType === 'default' && !this.state.isMobile) {
      return (
        <Button link={url} target={this.props.target} className='m-0' size='large' btnStyle='primary'>
          {typeof window !== 'undefined' && window.$isEn ? 'More' : '看更多'}
        </Button>
      );
    } else {
      return (
        <Button link={url} target={this.props.target} className='m-0 fui-button is-arrow'>
          {typeof window !== 'undefined' && window.$isEn ? 'More' : '看更多'}
        </Button>
      );
    }
  };

  openFbLink = (link) => {
    window.open(`//www.facebook.com/sharer.php?u=${encodeURIComponent(window.location.origin + link)}`);
  };
  openLineLink = (link) => {
    window.open(`//social-plugins.line.me/lineit/share?url=${encodeURIComponent(window.location.origin + link)}`);
  };

  render() {
    const mainCardUrl = typeof window !== 'undefined' && window.location.origin + this.props.content[0].link;
    return (
      <div
        className={`fui-card extra-link-card ${this.props.cardType === 'default' ? 'flex-column' : 'flex-row'}`}
        id={this.props.id}>
        <div className='img-wrapper'>
          <div className='main-body'>
            <Link
              to={mainCardUrl}
              className='main-bg horizontal'
              style={{
                backgroundImage: `url(${this.props.content[0].imgHorizontal})`,
              }}></Link>
            <Link
              to={mainCardUrl}
              className='main-bg vertical'
              style={{
                backgroundImage: `url(${this.props.content[0].imgVertical})`,
              }}></Link>
            {/* <img className="main-bg horizontal" src={this.props.content[0].imgHorizontal} srcSet={this.props.content[0].retinaImg} alt='' />
            <img className="main-bg vertical" src={this.props.content[0].imgVertical} srcSet={this.props.content[0].retinaImg} alt='' /> */}
            {/* <img
              src={process.env.PUBLIC_URL + '/resources/ebu/images/pattern-card.png'}
              alt=''
              className='periotic-pattern'
            /> */}

            <a href={mainCardUrl}>
              <div className='periodic-content'>
                <div>
                  {Number(this.props.content[0].period) !== -1 && !isNaN(Number(this.props.content[0].period)) ? (
                    <h5 className='my-0 is-text-white'>{this.props.content[0].period}</h5>
                  ) : null}
                  <p className='my-0 body-1 is-text-white'>{this.props.content[0].period}</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className='card-wrapper'>
          {this.props.content[0] ? (<div className='card-body'>
            <a href={mainCardUrl}>
              <h4>{this.props.content[0].title}</h4>
            </a>
            <div className='card-bottom'>
              {this.renderButton()}
              <div className='social'>
                <i onClick={() => this.openFbLink(this.props.content[0].link)} className={`icon-facebook-sm`}></i>
                <i onClick={() => this.openLineLink(this.props.content[0].link)} className={`icon-line-sm`}></i>
              </div>
            </div>
          </div>) : ('')}
          <ul className='card-list'>
            {this.props.content[1] ? (<li>
              <a href={this.props.content[1].link} target={this.props.target}>
                {this.props.content[1].title}
              </a>
            </li>) : ('')}
            {this.props.content[2] ? (<li>
              <a href={this.props.content[2].link} target={this.props.target}>
                {this.props.content[2].title}
              </a>
            </li>) : ('')}
          </ul>
        </div>
      </div>
    );
  }
}

ExtraLinkCard.propTypes = {
  cardType: PropTypes.string,
  id: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string,
      retinaImg: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
      period: PropTypes.string,
      date: PropTypes.string,
    })
  ),
};
export default ExtraLinkCard;
