import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
class OrderQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      invalid: false
    }
    this.input = React.createRef();
  }
  onChange = (e) => {
    const val = e.target.value;
    if (e.target.validity.valid) this.setState({ id: e.target.value });
    else if (val === '' || val === '-') this.setState({ id: val });
  }
  render() {
    return (
      <main className="my-order has-bg">
        <Breadcrumb
          color='black'
          breadcrumb={[
            { text: '個人首頁', link: '#' },
            { text: '個人專區', link: '#' },
            { text: '我的訂單', link: '#' },
          ]}
        />
        <section className="my-order-wrapper">
          <div className="fui-container">
            <h2>我的訂單</h2>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} className="d-flex">
                <div className="paper">
                  <h2>查詢網路門市訂單？</h2>
                  <h5 className="is-text-darkgray50">免登入快速查詢你的訂單</h5>
                  <h6>訂單編號／身分證字號</h6>
                  <div className={`input-wrapper ${this.state.invalid ? 'invalid' : ''}`}>
                    <input
                      type='text'
                      ref={this.input}
                      placeholder="請輸入訂單編號／身份證字號"
                      name="id"
                      value={this.state.id}
                      onBlur={this.onChange}
                      onChange={this.onChange}
                      onKeyUp={this.onChange}
                      pattern="^[0-9a-zA-Z]+$"
                    />
                    {this.state.invalid ? <span className="error-message">
                      請輸入英、數字
                    </span> : null}
                  </div>
                  <div className={`fui-button is-large is-primary m-0 ${this.state.id && !this.state.invalid ? null : 'disabled'}`}>
                    <span className='text'>送出</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="d-flex">
                <div className="paper">
                  <h2>查詢所有訂單？ </h2>
                  <h5 className="is-text-darkgray50">使用會員身份登入可以查詢你所有的訂單（包括friDay購物的訂單）</h5>
                  <div className='fui-button is-large is-primary m-0'>
                    <span className='text'>FET ID 登入</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

        </section>
      </main>
    );
  }
}

export default OrderQuery;