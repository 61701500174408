import React from 'react';
import Tab from './Tab';
import PropTypes from 'prop-types';

class DropdownTab extends React.Component {
  constructor(props) {
    super(props);
    this.menu = React.createRef();
    this.state = {
      menuHeight: 0,
      currentTab: this.props.default || 0,
      menuOpen: false,
    };

    this.setMenuHeight = this.setMenuHeight.bind(this);
  }

  componentDidMount() {
    this.setMenuHeight();
    this.setState({
      menuOpen: false,
      currentTab: this.props.default || 0,
    });
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', (e) => {
        this.setMenuHeight();
      });
    }
  }
  //Infinite loop
  componentDidUpdate(prevProps) {
    if (this.props.default !== prevProps.default) {
      this.setState({
        menuOpen: false,
        currentTab: this.props.default || 0,
      });
    }
  }

  setMenuHeight() {
    if (!this.menu.current) return;

    this.setState({
      menuHeight: this.menu.current.children[0].clientHeight,
    });
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
    if (!this.state.menuOpen === true) {
      document.body.classList.add('dropdown-open');
    } else {
      document.body.classList.remove('dropdown-open');
    }
  }

  closeMenu() {
    this.setState({
      menuOpen: false,
    });
    document.body.classList.remove('dropdown-open');
  }

  onChange(e) {
    this.toggleMenu();
    this.setState({
      currentTab: e,
    });
    this.props.onChange(e);
  }

  render() {
    return (
      <div className='fui-tab-container mobile-dropdown'>
        <button className='display' onClick={(e) => this.toggleMenu(e)}>
          <span>{this.props.tabs.list[this.state.currentTab].label}</span>
          <i className='icon-chevron-down'></i>
        </button>
        <div className='fui-menu' ref={this.menu} style={{ height: this.state.menuOpen ? this.state.menuHeight : 0 }}>
          <Tab
            {...this.props.tabs}
            default={this.props.default}
            className={`${this.state.menuOpen ? 'is-open' : ''}`}
            onChange={(e) => this.onChange(e)}
          />
        </div>
      </div>
    );
  }
}

DropdownTab.propTypes = {
  default: PropTypes.number,
  tabs: PropTypes.shape({
    name: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        focusIcon: PropTypes.string,
        label: PropTypes.string.isRequired,
        link: PropTypes.string,
      })
    ),
  }),
  onChange: PropTypes.func,
};

export default DropdownTab;
