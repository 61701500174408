import React, { Children } from 'react';
import PropTypes from 'prop-types';

class CollapsePaper extends React.Component {
    constructor(props) {
        super(props);
        // console.log(`props`, props);
        this.body = React.createRef();
        this.state = {
            contentHeight: 0,
            open: this.props.open || false
        }

        this.collapseOpen = this.collapseOpen.bind(this)
        this.setContentHeight = this.setContentHeight.bind(this)
    }

    setContentHeight() {
        this.setState({
            contentHeight: this.body.current.clientHeight
        })
    }

    componentDidMount() {
        window.addEventListener('resize', e => {
            this.setContentHeight()
        })
        setTimeout(() => {
            this.setContentHeight()
        }, 1500)
    }

    componentDidUpdate(nextProps) {
        if (this.props.onChange && (nextProps.open !== this.state.open || this.props.open !== this.state.open)) {
            this.setState({
                open: this.props.open
            })
        }
    }

    collapseOpen() {
        this.setContentHeight()
        if (this.props.onChange) {
            this.props.onChange(!this.state.open)
        } else {
            this.setState({
                open: !this.state.open
            })
        }
        if (this.props.openCollapse)
            this.props.openCollapse(this.props.keyVal);
        this.forceUpdate();
    }

    render() {
        return (
            <div onClick={!!this.props.date ? this.collapseOpen : null} className={`fui-collapse paper ${this.state.open ? 'is-open' : ''} ${this.props.className}`}>
                {!!this.props.date ?
                    <div className="d-flex flex-row-md flex-column has-date">
                        <div className='body is-text-darkgray50 my-0 mr-5'>{this.props.date}</div>
                        <div
                            role="button"
                            // onClick={this.collapseOpen}
                            className="collapse-header" />
                        <div className="has-date-body" dangerouslySetInnerHTML={{ __html: this.props.title }} />
                    </div>
                    : <div
                        role="button"
                        onClick={this.collapseOpen}
                        className={`collapse-header`} dangerouslySetInnerHTML={{ __html: this.props.title }} />}
                <div
                    className="collapse-body"
                    style={{
                        height: this.state.open ? this.state.contentHeight : 0
                    }}>
                    <div className="p-0" ref={this.body}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

CollapsePaper.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool,
    children: PropTypes.any,
    openCollapse: PropTypes.func,
    date: PropTypes.string,
}

export default CollapsePaper