import React from 'react';
import Slider from 'react-slick';
import Button from '../../Button';
import Modal from 'react-modal';

import PropTypes from 'prop-types';

class ExclusiveBanner extends React.Component {
  constructor(props) {
    super(props);

    this.slideSetting = {
      dots: true,
      infinite: false,
      arrows: false,
      slidesToShow: 1,
      autoplaySpeed: 5000,
      draggable: true,
      adaptiveHeight: true,
    };
    this.state = {
      modalOpen: false,
    };
  }

  setModalContent = (content) => {
    this.props.openModal({
      type: 'exclusive-promotion',
      ...content,
    });
  };
  render() {
    return (
      <div className='fui-banner is-exclusive'>
        <Slider {...this.slideSetting}>
          {this.props.slides.map((slide, i) => (
            <div className='estore-banner-item' key={`estore-banner-${i}`}>
              <div className='bg'>
                <img src={slide.image.md} alt={slide.title} className='d-none d-sm-block' />
                <img src={slide.image.sm} alt={slide.title} className='d-block d-sm-none' />
              </div>
              <div className='fui-container'>
                <div className='caption'>
                  <div className='tag'>
                    <div className='tag-bg'></div>
                    {slide.tag}
                  </div>
                  <h1>{slide.title}</h1>
                  <h5>{slide.description}</h5>
                  <div className='action'>
                    {slide.actions.map((act, j) =>
                      act.type === 'link' ? (
                        <Button
                          link={act.link}
                          target={act.target}
                          btnStyle={act.btnStyle}
                          key={`estore-banner-${i}-btn-${j}`}
                          size='large'>
                          {act.text}
                        </Button>
                      ) : (
                        <Button
                          onClick={(e) => this.setModalContent(act.modalContent)}
                          btnStyle={act.btnStyle}
                          key={`estore-banner-${i}-btn-${j}`}
                          size='large'>
                          {act.text}
                        </Button>
                      )
                    )}
                  </div>
                </div>
              </div>

              <div className='fui-arrow d-block d-sm-none'>
                <i className='icon-chevron-down'></i>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

ExclusiveBanner.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      tag: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          link: PropTypes.string,
          target: PropTypes.string,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
};

export default ExclusiveBanner;
