import React from 'react';
import Button from '../../Button';

import PropTypes from 'prop-types';

class SectionBanner2 extends React.Component {
  getPosition = () => {
    let position = this.props.position;
    if (position === 'center') {
      return '60% center';
    } else {
      return position;
    }
  };
  render() {
    return (
      <section
        id={this.props.id ? this.props.id : ''}
        className={`fui-banner is-product is-style-2 ${this.props.bgColor || this.props.topBg ? '__bg-transparent' : ''
          } ${this.props.className ? this.props.className : ''}`}>
        <div className='banner-img'>
          <div
            className='d-none d-sm-block'
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + this.props.image.md})`,
              backgroundPosition: this.getPosition(),
            }}></div>
          <div
            className='d-block d-sm-none'
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + this.props.image.sm})`,
              backgroundPosition: this.getPosition(),
            }}></div>
        </div>
        {/* <img src={this.props.image.md} srcSet={this.props.image.retinaMd || this.props.image.md} className='d-none d-sm-block' alt={this.props.title} />
                <img src={this.props.image.sm} srcSet={this.props.image.retinaSm || this.props.image.sm} className="d-block d-sm-none" alt={this.props.title} /> */}
        <div className='fui-container'>
          <div className='caption'>
            <p className='sub-title is-text-medium mb-1' dangerouslySetInnerHTML={{ __html: this.props.subTitle }}></p>
            <div className='' dangerouslySetInnerHTML={{ __html: this.props.title }}></div>
            {!!this.props.description ? (
              <div className='subtitle' dangerouslySetInnerHTML={{ __html: this.props.description }}></div>
            ) : null}
            {!!this.props.action ? (
              !!this.props.action.length ? (
                this.props.action.map((item, n) => {
                  return (
                    <Button key={item.text + n} {...item} btnStyle={n === 0 ? 'primary' : 'secondary'}>
                      {item.text}
                    </Button>
                  );
                })
              ) : (
                  <Button {...this.props.action} btnStyle='primary'>
                    {this.props.action.text}
                  </Button>
                )
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}

SectionBanner2.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  subTitle: PropTypes.string,
  position: PropTypes.string,
  image: PropTypes.shape({
    md: PropTypes.string,
    retinaMd: PropTypes.string,
    sm: PropTypes.string,
    retinaSm: PropTypes.string,
  }),
  action: PropTypes.any,
  onChange: PropTypes.func,
  bottomBg: PropTypes.string,
  bgColor: PropTypes.string,
  topBg: PropTypes.string,
};

export default SectionBanner2;
