import React from 'react';

import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

import * as Mock from '../../mock/EServiceIndex';

class prepaidDepositSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      canSubmit: false
    }
  }

  enableButton = (e) => {
    this.setState({ canSubmit: true });
  }

  disableButton = (e) => {
    this.setState({ canSubmit: false });
  }

  render() {
    return (
      <main>
        
        <NavAnchor
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <section className="e-service-result is-success">
          <div className="fui-container">
            <Grid>
              <Grid xs={12}>
                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed@2x.png'} alt='' />
                <div className="content">
                  <h4 className="mb-0 mt-3">儲值完成</h4>
                  <p className='body1'>您已完成預付卡門號 <a href='tel:0926899868' className="is-text-accent" target="_blank">0926-899-868</a> 儲值 <span className="is-text-accent">$100</span></p>
                  
                </div>
              </Grid>
            </Grid>

            <div className="paper align-left mb-4">
              <h3 className="mt-1">發票明細</h3>
              <div className="fui-table-response">
                <table>
                  <tr>
                    <th width="50%">發票捐贈</th>
                    <td className='align-left'>捐贈單位：財團法人早產兒基金會</td>
                  </tr>
                  <tr>
                    <th>發票寄送方式</th>
                    <td className='align-left'>電子郵件：showmin.li@gmail.com</td>
                  </tr>
                  <tr>
                    <th>發票格式</th>
                    <td className='align-left'>
                      三聯式發票<br/>
                      統一發票：13456708<br/>
                      發票抬頭：遠傳股份有限公司
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className='action align-center mt-5'>
              <Button btnStyle='primary' size='large'>儲值記錄查詢</Button>
              <Button link='/' btnStyle='secondary' size='large'>預付卡餘額</Button>
            </div>
          </div>
        </section>
        <SectionCarousel1 {...Mock.promotionCard} />
      </main>
    );
  }
}

export default prepaidDepositSuccess;
