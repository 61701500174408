import React from "react";
import Link from "../Link";
import PropTypes from "prop-types";
import { adClickTracking } from "../../utils/ad-tracking";

const Card = (props) => {
  const [isEn, setIsEn] = React.useState(false);

  React.useEffect(() => {
    setIsEn(typeof window !== "undefined" && window.$isEn);
  }, []);

  const renderCardContent = () => {
    return props.link ? (
      <Link
        className="fui-card-action"
        to={props.link}
        target={props.target}
        data-aa-tracking-id={`${props.title}`}
        data-fet-key={props.fetKey}
        data-recommend-tracking={props["data-recommend-tracking"]}
        onClick={(event) => {
          // [AA Tracking]
          // if (window && typeof window.ctrf === 'function') window.ctrf(event || {}, `${props.title}`);
          props.adData && adClickTracking("contentAD", props.adData);
        }}
      >
        {props.tag ? (
          <div className={`tag tag-${props.tag.color}`}>{props.tag.text}</div>
        ) : null}

        {props.image ? (
          <div className="fui-card-image">
            <img
              className="lazyload"
              data-src={process.env.PUBLIC_URL + props.image}
              data-srcset={
                process.env.PUBLIC_URL + (props.retinaImage || props.image)
              }
              alt={props.alt}
            />
          </div>
        ) : (
          ""
        )}
        <div className="fui-card-caption">
          <div className="fui-card-content">
            {!props.public_at ? (
              ""
            ) : (
              <div className="fui-card-date">{props.public_at}</div>
            )}
            {!props.meta ? (
              ""
            ) : (
              <div className="fui-card-meta">{props.meta}</div>
            )}
            {!props.subtitle ? (
              ""
            ) : (
              <div className="fui-card-subtitle body-2">{props.subtitle}</div>
            )}
            <h5 className="fui-card-title">
              {!props.icon ? (
                ""
              ) : (
                <div className="icon">
                  <img
                    className="lazyload"
                    data-src={process.env.PUBLIC_URL + props.icon}
                    alt={props.meta}
                  />
                </div>
              )}
              <div className="text">{props.title}</div>
            </h5>
            {!props.description ? (
              ""
            ) : (
              <p
                className="fui-card-description"
                dangerouslySetInnerHTML={{ __html: props.description }}
              ></p>
            )}
          </div>
          <div className="fui-card-extra">
            <div
              className={`fui-button is-arrow mb-0 ${
                props.className &&
                props.className.indexOf("promotion-article") > -1
                  ? "is-reverse"
                  : ""
              }`}
            >
              {props.action ? props.action.text : isEn ? "more" : "看更多"}
            </div>
          </div>
        </div>
      </Link>
    ) : (
      <div className="fui-card-action">
        {props.image ? (
          <div className="fui-card-image">
            <img
              className="lazyload"
              data-src={process.env.PUBLIC_URL + props.image}
              data-srcset={
                process.env.PUBLIC_URL + (props.retinaImage || props.image)
              }
              alt={props.meta}
            />
          </div>
        ) : (
          ""
        )}
        <div className="fui-card-caption">
          <div className="fui-card-content">
            {!props.public_at ? (
              ""
            ) : (
              <div className="fui-card-date">{props.public_at}</div>
            )}
            {!props.meta ? (
              ""
            ) : (
              <div className="fui-card-meta">{props.meta}</div>
            )}
            <h4 className="fui-card-title">
              {!props.icon ? (
                ""
              ) : (
                <div className="icon">
                  <img
                    className="lazyload"
                    data-src={process.env.PUBLIC_URL + props.icon}
                    alt={props.meta}
                  />
                </div>
              )}
              {props.title}
            </h4>
            {!props.description ? (
              ""
            ) : (
              <p
                className="fui-card-description"
                dangerouslySetInnerHTML={{ __html: props.description }}
              ></p>
            )}
          </div>
          <div className="fui-card-extra">
            <div
              className={`fui-button is-arrow mb-0 ${
                props.className &&
                props.className.indexOf("promotion-article") > -1
                  ? "is-reverse"
                  : ""
              }`}
            >
              {props.action ? props.action.text : isEn ? "More" : "看更多"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`fui-card ${props.className ? props.className : ""}`}
      id={props.id}
    >
      {renderCardContent()}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  retinaImage: PropTypes.string,
  tag: PropTypes.shape({
    color: PropTypes.string,
    text: PropTypes.string,
  }),
  image: PropTypes.string,
  meta: PropTypes.string,
  alt: PropTypes.string,
  icon: PropTypes.string,
  public_at: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
};

export default Card;
