import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import Button from '../Button';
import PropTypes from 'prop-types';
class CardCollapseSingle extends Component {
  constructor(props) {
    super(props);
    this.collapse = React.createRef();
    this.state = {
      collapseHeight: 218,
      bodyHeight: 0,
      collapse: false
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    setTimeout(() => {
      this.setHeight()
    }, 1000);
  }
  setHeight() {
    this.setState({
      bodyHeight: this.collapse.current.children[0].clientHeight,
    });
  }
  updateDimensions = () => {
    this.setHeight()
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  toggleCollapse = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };
  render() {
    return (
      <div style={{ marginBottom: 12 }}>
        <div className="fui-collapse paper">
          <div
            className='collapse-body'
            ref={this.collapse}
            style={{
              height: this.state.collapse ? this.state.bodyHeight : this.state.collapseHeight,
            }}>
            <div className='collapse-wrapper'>
              <div className="head-img">
                <img src={this.props.img.url} alt="img-01" />
              </div>
              <div className="collapse-content">
                <h5>
                  <img className='d-inline-block d-md-none' src={this.props.img.url} alt={this.props.img.alt} />
                  {this.props.title}
                </h5>
                <div className="body is-text-darkgray50 with-comma">
                  {this.props.quote}
                </div>
                <div className="body" dangerouslySetInnerHTML={{ __html: this.props.content }} />
                {this.props.link ? (<Button link={this.props.link} className='mt-4 mb-0 fui-button is-arrow is-text-accent' target={this.props.target}>
                  {this.props.linkText ? this.props.linkText : "繼續閱讀"}
                </Button>) : ''}
              </div>
            </div>
          </div>
        </div>
        <div className='collapse-trigger align-center main-shadow'>
          <button
            className='fui-button is-text mb-0 pr-0 mr-0'
            onClick={(event) => {
              this.toggleCollapse();
            }}>

            {!this.state.collapse ? (
              <div className='align-center'>
                <span className='text'>
                  展開
                  </span>
                <i className='icon-plus mr-0'></i>
              </div>
            ) : (
                <div className='align-center'>
                  <span className='text'>
                    收合
                    </span>
                  <i className='icon-minus mr-0'></i>
                </div>
              )}
          </button>
        </div>
      </div>
    );
  }
}
CardCollapseSingle.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  quote: PropTypes.string,
  content: PropTypes.string,
  link: PropTypes.string,
  img: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
}
export default CardCollapseSingle;