import React, { Component } from 'react';
import Formsy from 'formsy-react';
import Button from '../../components/Button';
import RadioButtonsWithPrice from '../../components/form/RadioButtonsWithPrice';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

class MultiRadioCard extends Component {
  constructor(props) {
    super(props);
    this.card = React.createRef();
    this.state = {
      list: this.props.list,
      form: this.props.form,
    };
  }
  onChange = (name, value) => {
    let form = Object.assign(this.state.form);
    form[name].value = value;
    // console.log(name, value, form[name]);
    this.setState({
      form: form,
    });
  };
  submit = (target) => {
    console.log(target);
    if (target === '1') {
      let val = this.state.form.options1.value;
      // console.log(this.props.option1[val - 1].link);
      window.location.href = `${this.props.option1[val - 1].link}`;
    } else {
      let val = this.state.form.options2.value;
      // console.log(this.props.option2[val - 1].link);
      window.location.href = `${this.props.option2[val - 1].link}`;
    }
  };
  render() {
    return (
      <section className='multi-radio-card'>
        <div className='fui-container mt-4'>
          <h2 className='mt-0 '>{this.props.title}</h2>
          <Formsy
            onValid={() => {
              this.setState({ formCanSubmit: true });
            }}
            onInvalid={() => {
              this.setState({ formCanSubmit: false });
            }}>
            <Slider
              {...{
                dots: false,
                infinite: false,
                arrows: false,
                slidesToShow: 2,
                draggable: true,
                responsive: [
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 2,
                      variableWidth: true,
                    },
                  },
                  {
                    breakpoint: 640,
                    settings: {
                      slidesToShow: 1,
                      variableWidth: true,
                    },
                  },
                ],
              }}
              className='horizontal-cards'>
              <div className='fui-card'>
                <div className='wrapper'>
                  <img src={this.state.list[0].icon} alt={this.state.list[0].alt} />
                  <h2 className='' ref={this.card}>
                    {this.state.list[0].title}
                  </h2>
                  <RadioButtonsWithPrice
                    name='options1'
                    options={this.props.option1}
                    selected='0'
                    getSelected={(v) => this.onChange('options1', v)}
                  />
                  <div className='page-action'>
                    <Button onClick={() => this.submit('1')} btnStyle='primary' size='medium' className='mb-0' link='#'>
                      {this.state.list[0].btnText}
                    </Button>
                  </div>
                </div>
              </div>

              <div className='fui-card'>
                <div className='wrapper'>
                  <img src={this.state.list[1].icon} alt={this.state.list[1].icon} />
                  <h2 className='' ref={this.card}>
                    {this.state.list[1].title}
                  </h2>
                  <RadioButtonsWithPrice
                    name='options2'
                    options={this.props.option2}
                    selected='0'
                    getSelected={(v) => this.onChange('options2', v)}
                  />
                  <div className='page-action'>
                    <Button onClick={() => this.submit('2')} btnStyle='primary' size='medium' className='mb-0' link='#'>
                      {this.state.list[1].btnText}
                    </Button>
                  </div>
                </div>
              </div>
            </Slider>
          </Formsy>
        </div>
      </section>
    );
  }
}
MultiRadioCard.propTypes = {
  title: PropTypes.string,
  option1: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      price: PropTypes.number,
      link: PropTypes.string,
    })
  ),
  option2: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      price: PropTypes.number,
      link: PropTypes.string,
    })
  ),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  form: PropTypes.shape({
    options1: PropTypes.shape({
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
    options2: PropTypes.shape({
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
  }),
};
export default withRouter(MultiRadioCard);
