import React, { Component } from 'react';
import Link from '../../Link';
import PropTypes from 'prop-types';

export class CollapseMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEn: false,
      isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
      showMore:
        this.props.searchTagItem && this.props.searchTagItem.index && this.props.searchTagItem.index >= 10
          ? true
          : false,
      toggle: false,
      toggleMenu: false,
      activeLevel: [0, -1],
      openLevel: -1,
      currentGroup: null,
      currentSelected: null,
      currentThirdSelected: null,
    };
  }

  componentDidMount() {
    let menu = this.props.menu;

    this.selectedItem(this.props.searchTagItem);
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        this.setState({
          isMobile: window.innerWidth < 960,
        });
      });
    this.setState({
      currentGroup: menu,
      activeLevel: [0, 2],
      currentSelected: menu.content[0].content[2],
      currentThirdSelected: menu.content[0].content[2].content[1],
    });
  }

  componentDidUpdate() {
    if ((typeof window !== 'undefined' && window.$isEn) !== this.state.isEn) {
      this.setState({
        isEn: typeof window !== 'undefined' && window.$isEn,
      });
    }
  }
  searchMenu = (list, parent, val) => {
    // console.log({ list, parent, val });
    var result = {
      index: 0,
      parentIndex: -1,
      hasValue: false,
    };

    if (!val) {
      result = {
        hasValue: true,
        parentIndex: list && list.length && list[0].content && list[0].content.length ? 0 : -1,
        index: 0,
        item: list && list.length && list[0].content && list[0].content.length ? list[0].content[0] : list[0],
      };
    } else {
      for (var i in list) {
        let menu = list[i];
        if (menu.id === val.id) {
          // console.log(menu, i, this.props.searchTagItem.id)
          // console.log(menu, i)
          result = {
            hasValue: true,
            parentIndex: parent,
            index: Number(i),
            item: menu,
          };
          break;
        } else {
          if (!!menu.content && !result.hasValue) {
            result = this.searchMenu(menu.content, Number(i), val);
          }
        }
      }
    }

    return result;
  };

  selectedItem = (val, event, item) => {
    let toggle = this.state.toggleMenu;
    let open = this.state.openLevel;
    let level = this.state.activeLevel;
    // console.log({ val, event, item });
    let selected = this.searchMenu(this.props.menu.content, -1, val);
    // console.log(`selected: `, selected);
    this.setState({
      currentSelected: selected.item
    })
    if (this.props.onClickTag) this.props.onClickTag(val.id);
    // 尋找被點選或 props 帶入的內容
    if (event && selected.parentIndex > -1) {
      let el = event.currentTarget.parentNode.children;
      let temp = event.currentTarget.classList.contains('active')
      if (temp === false) {
        el.forEach((item) => {
          item.classList.remove('active');
        })
        // event.currentTarget.className += 'active'
      } else {
        if (this.state.currentSelected.id.indexOf(val.id) == -1) {
          el.forEach((item) => {
            item.classList.remove('active');
          })
        }

      }
    }

    // 沒有子項目或不是 click event
    if ((selected.item && !selected.item.content) || !event) {
      if (!this.props.searchTagItem && !event) {
        let target = this.props.menu.content.find((item) => {
          return item.link === (window ? window.location.pathname : null);
        });
        selected = this.searchMenu(this.props.menu.content, -1, target);
      }

      this.setState({
        toggle: false,
        toggleMenu: selected.parentIndex > -1 ? true : false,
        openLevel: selected.parentIndex !== open && selected.parentIndex > -1 ? selected.parentIndex : open,
        activeLevel: [
          selected.parentIndex === -1 ? selected.index : selected.parentIndex,
          selected.parentIndex !== -1 ? selected.index : -1,
        ],
        currentSelected: selected.item,
      });

      this.props.selectMenu(selected.item);
    } else {
      // console.log(this.state.currentSelected);
      // console.log(event.currentTarget.classList);
      // console.log(`selected: `, selected);
      // console.log('有子項目或是 click event');
      // 有子項目或是 click event
      if (!!val.content) {
        // toggle = open === selected.index ? !toggle : true;
        let el = event.currentTarget.parentNode.classList.value;
        // console.log(el);
        if (el === 'menu-active') {
          event.currentTarget.parentNode.classList.remove('menu-active')
          this.setState({
            toggle: false,
            openLevel: selected.index,
            toggleMenu: false,
          });
        } else {
          this.setState({
            openLevel: val.parentIndex > -1 ? val.parentIndex : val.index,
            // openLevel: selected.parentIndex,
            toggleMenu: true,
            activeLevel: [
              selected.parentIndex === -1 ? selected.index : selected.parentIndex,
              selected.parentIndex !== -1 ? selected.index : -1,
            ],
            // currentSelected: selected.item,
          });
        }

      } else {
        // console.log('no child');
        toggle = open === selected.index ? !toggle : true;
        this.setState({
          toggle: false,
          openLevel: selected.index,
          toggleMenu: false,
        });
      }

    }
    this.forceUpdate();
  };

  toggleMenu = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  toggleStatus = (val) => {
    let toggle = this.state.toggleMenu;
    let level = this.state.openLevel;
    // console.log({ val, toggle });
    return val === level && toggle ? 'menu-active' : '';
  };

  getActiveStatus = (val, parent) => {
    let active = this.state.activeLevel;
    // console.log({ active, val, parent });
    // return ((parent && active[0] === parent) && active[1] === val) || (!parent && active[0] === val) ? 'active' : '';
    if (!parent && parent !== 0) {
      return active[0] === val && active[1] === -1 ? 'active' : '';
    } else {
      return active[0] === parent && active[1] === val ? 'active' : '';
    }
  };
  thirdSelecttion = (third, event) => {
    console.log(third);
    this.setState({
      currentThirdSelected: third,
      toggleMenu: false,
      toggle: false,
    })
    this.forceUpdate()
  }
  getThirdStatus = (third) => {
    let current = this.state.currentThirdSelected
    // console.log({ current });
    if (!!current) {
      // console.log("third.id: " + third.id);
      // console.log("current.id: " + current.id);
      return third.id === current.id ? 'active' : ''
    }
  }
  toggleActive = (event) => {
    event.preventDefault();
    event.currentTarget.parentNode.parentNode.classList.toggle('active');
  }

  render() {
    return (
      <div className={`fui-collapse-menu ${this.props.usage}`}>
        <div className={`collapse-menu-group ${this.state.toggle ? 'menu-active' : ''}`}>
          <div className='collapse-menu-header'>
            <h6 className='group-name' onClick={this.toggleMenu}>
              {this.props.menu.name}
              <i className={`icon-${this.state.toggle ? 'minus' : 'plus'}`} />
            </h6>
            {!!this.state.currentSelected ? (
              <div className='current-select-item'>
                <p className='body1'>{this.state.currentSelected.name}</p>
              </div>
            ) : null}
          </div>
          <ul>
            {this.props.menu.content.map((list, j) => {
              if (this.props.showMore === false || (this.state.showMore || (j < 10 && !this.state.showMore))) {
                if (!!list.content) {
                  return (
                    <li className={`${this.toggleStatus(j, 1)}`} key={`collapse-menu-child-${j}`}>
                      <p className='body1' onClick={(event) => this.selectedItem({ index: j, ...list }, event)}>
                        {list.name} {list.count ? `(${list.count})` : null}
                        <i className={`icon-chevron-down`} />
                      </p>
                      <div className='item-group'>
                        {list.content.map((item, k) => {
                          return item.link ? (
                            this.state.isMobile && this.getActiveStatus(k, j) ? null : (
                              <Link
                                to={item.link}
                                className={`${this.getActiveStatus(k, j)} d-flex flex-align-center`}
                                key={k + item.name}
                              >
                                {item.name}
                                <h1>{JSON.stringify(item.content)}</h1>
                              </Link>)
                          ) : (
                              this.state.isMobile && this.getActiveStatus(k, j) ? (
                                <div
                                  key={k}
                                  onClick={(event) => this.selectedItem({ parentIndex: j, index: k, ...item }, event)}
                                  className={this.getActiveStatus(k, j)}>
                                  <p>
                                    {item.name}
                                  </p>
                                  <div className="third-level">
                                    {item.content ? item.content.map((third, num) => {
                                      return (
                                        <p key={num + third.name} data-name="third-level-item" onClick={(event) => { this.thirdSelecttion(third, event) }} >
                                          {third.name}
                                        </p>
                                      )
                                    }) : null}
                                  </div>
                                </div>
                              ) : (
                                  <div
                                    key={k}
                                    onClick={(event) => this.selectedItem({ parentIndex: j, index: k, ...item }, event)}
                                    className={this.getActiveStatus(k, j)}>
                                    <p>
                                      {item.name}
                                      {item.content ? <i className={`icon-chevron-down`} onClick={(event) => { this.toggleActive(event) }} /> : null}
                                    </p>
                                    {item.content ? <div className="third-level">
                                      {item.content ? item.content.map((third, num) => {
                                        return (
                                          <p className={`${this.getThirdStatus(third)}`} key={num + third.name} data-name="third-level-item" onClick={(event) => { this.thirdSelecttion(third, event) }} >
                                            {third.name}
                                          </p>
                                        )
                                      }) : null}
                                    </div> : null}
                                  </div>
                                )
                            );
                        })}
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li key={list.id}>
                      {list.link ? this.state.isMobile && this.getActiveStatus(j, null) ? null : (
                        <Link
                          exact
                          to={list.link}
                          className={`body1 hahaha ${this.getActiveStatus(j, null)}`}
                          onClick={(event) => this.selectedItem({ level: 0, index: j, ...list }, event)}>
                          {/* <h2>{this.state.activeLevel[0] === j ? 'true' : 'false'}</h2> */}
                          {list.name} {list.count ? `(${list.count})` : null}
                        </Link>
                      ) : (
                          this.state.isMobile && this.getActiveStatus(j, null) ? null : (
                            <p
                              className={`body1 ${this.getActiveStatus(j, null)}`}
                              onClick={(event) => this.selectedItem({ level: 0, index: j, ...list }, event)}>
                              {list.name} {list.count ? `(${list.count})` : null}
                            </p>
                          )
                        )}
                    </li>
                  );
                }
              }
            })}
            {(this.props.showMore !== false) && !this.state.showMore && this.props.menu.content.length > 10 ? (
              <li>
                <p className='more-btn' onClick={(e) => this.setState({ showMore: true })}>
                  <span className='text'>{this.state.isEn ? 'More' : '看更多'}</span> <i className='icon-plus'></i>
                </p>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    );
  }
}

CollapseMenu.propTypes = {
  menu: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string.isRequired,
        link: PropTypes.string,
        content: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.any,
            name: PropTypes.string,
            link: PropTypes.string,
          })
        ),
      })
    ),
  }),
  searchTagItem: PropTypes.object,
  selectMenu: PropTypes.func,
  onClickTag: PropTypes.func,
  showMore: PropTypes.bool
};
export default CollapseMenu;
