import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Slider from 'react-slick';
class CardPhonePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <section className={`fui-product-section pb-5 ${this.props.className ? this.props.className : ''}`}>
        <div className='fui-container mt-md-2 mt-4'>
          <h2 className="mt-0 mb-0">{this.props.title}</h2>
          <Slider {...{
            dots: false,
            infinite: false,
            arrows: true,
            slidesToShow: 4,
            draggable: true,
            responsive: [
              {
                breakpoint: 960,
                settings: {
                  arrows: true,
                  slidesToShow: 1,
                  variableWidth: true,
                },
              },

              {
                breakpoint: 768,
                settings: {
                  arrows: true,
                  slidesToShow: 2,
                  variableWidth: true
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: true,
                  slidesToShow: 1,
                  variableWidth: true
                }
              }
            ],
          }}
            className='horizontal-cards is-program mb-0 width-166'>
            {this.props.list.map((card, idx) => (
              <div key={idx} className='fui-card is-card-program mb-0 is-height-382'>
                {card.hottag ? <div className={`tag tag-${card.hottag.color}`}>{card.hottag.text}</div> : null}
                <img src={card.img} alt={card.imgAlt} />

                <div className="d-inline">
                  <div className="body-2 is-text-darkgray50 is-text-regular m-0">
                    {card.brand}
                  </div>
                  {card.tag.map((tag, i) => (
                    <span className='inline-tag' key={`product-tag-${i}`}>
                      {tag}
                    </span>
                  ))}
                  <h6 className='m-0 is-text-regular'>{card.name}</h6>
                </div>
                <div className='price-wrapper'>
                  <div dangerouslySetInnerHTML={{ __html: card.price }} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}

CardPhonePlan.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  list: PropTypes.array
}
export default CardPhonePlan;