import React from 'react';
import { Grid } from '@material-ui/core';
import PanelLayout from '../../components/panelContent/PanelLayout'
import PropTypes from 'prop-types';

class Panel extends React.Component {
  render() {
    return (
      <div className="fui-container">
        <div
          className={`fui-panel-container ${this.props.className ? this.props.className : ''} ${this.props.initial ? 'no-shadow no-bg' : ''}`}
          style={this.props.bgColor ? ({ backgroundColor: this.props.bgColor }) : null}
        >
          {this.props.data.map((item, i) => {
            return (
              <PanelLayout
                key={i}
                imageWidth={item.img.width}
                image={item.img.url}
                alt={item.img.alt}
                title={item.title}
                content={item.content}
                contentPosition={item.contentPosition}
              >
              </PanelLayout>
            )
          })}
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  title: PropTypes.string,
  bgColor: PropTypes.string,
  initial: PropTypes.bool,
  content: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      width: PropTypes.string,
      image: PropTypes.string,
      alt: PropTypes.string,
      content: PropTypes.string,
      contentPosition: PropTypes.string,
    })
  )
}

export default Panel;