import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import raf from 'raf';
import { getTargetRect, scrollTo } from './util';

export default class DetectAnchor extends PureComponent {
  static defaultProps = {
    items: [], // 受監控 element id
    itemsName: [], // 受監控 element wording
    offsetTop: 0,
    componentTag: 'ul',
    style: {},
    container: window,
  };

  static propTypes = {
    items: propTypes.arrayOf(propTypes.string),
    itemsName: propTypes.arrayOf(propTypes.string),
    activeClass: propTypes.string.isRequired,
    offsetTop: propTypes.number,
    componentTag: propTypes.string,
    style: propTypes.object,
    container: propTypes.any,
    className: propTypes.string,
    children: propTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      detectElements: [], // 受監控 element, maybe empty
      elementStatus: [], // 受監控 element status
      elementActive: '', // 受監控 element index
    };
    this.flag = true; // 點擊 anchor nav, scroll 時不監聽 scroll event
    this.scheduledAnimationFrame = false; // 管理 raf callback, 防止同一 callback 被執行多次
  }

  componentDidMount() {
    this.buildDetectElements(this.props.items);
    // 監控 scroll event
    this.listener = this.props.container.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    // this.props.container.removeEventListener('scroll', this.onScroll);
    this.listener && this.listener.remove();
  }

  componentDidUpdate(prevProps) {
    // 判斷 items 是否更新
    if (prevProps.items !== this.props.items
      && this.isDiff(prevProps.items, this.props.items)) {
      // 重新生成受監控 element
      this.buildDetectElements(this.props.items);
    }
  }

  // 判斷是否一樣
  isDiff = (before, after) => {
    if (before.length !== after.length) return false;
    // 先排序
    const beforeTemp = [...before].sort(), afterTemp = [...after].sort();
    for (let i = 0; i < beforeTemp.length; i++) {
      if (beforeTemp[i] !== afterTemp[i]) {
        return false;
      }
    }
    return true;
  };

  // 生成受監控 element
  buildDetectElements = ids => {
    let elements = [], elementStatus = [];
    for (let i = 0; i < ids.length; i++) {
      elements.push(document.getElementById(ids[i]));
      elementStatus.push(false);
    }
    this.setState({ detectElements: elements, elementStatus });
    this.detect(elements);
  };

  // 處理 scroll event
  onScroll = () => {
    if (this.scheduledAnimationFrame) return;
    this.scheduledAnimationFrame = true;
    const { detectElements } = this.state;
    this.flag && raf(() => {
      this.scheduledAnimationFrame = false;
      this.detect(detectElements);
    });
  };

  // 監控 element status
  detect = detectElements => {
    const containerRect = getTargetRect(this.props.container);
    const containerOffset = this.props.container !== window
      ? this.props.container.scrollTop
      : (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);
    const elementStatus = detectElements.map(el => this.isActive(el, containerRect, containerOffset));
    // 判斷 element status 是否變化
    const { elementStatus: oldElementStatus } = this.state;
    if (elementStatus.length !== oldElementStatus.length || !elementStatus.every((item, index) => item === oldElementStatus[index])) {
      this.setState({ elementStatus });
      const i = Object.keys(elementStatus).filter(k => elementStatus[k])
      // console.log(i)
      // this.state.elementActive = i
    }
  };

  // 判斷當前受監控 element 是否 active, 從 offset 判斷
  isActive = (detectElement, containerRect, containerOffset) => {
    if (detectElement) {
      const detectElementRect = getTargetRect(detectElement);
      const position = containerRect.top + this.props.offsetTop + containerOffset;
      return (detectElementRect.top <= position) && (position <= detectElementRect.bottom);
    }
    return false; // 受監控可能為空
  };

  // 受監控 element click event
  handleClickElement = index => {
    const { detectElements } = this.state;
    // 修改 status
    let elementStatus = [];
    for (let i = 0; i < detectElements.length; i++) {
      i === index ? elementStatus.push(true) : elementStatus.push(false);
    }
    this.setState({ elementStatus });
    const detectElement = detectElements[index];
    if (!detectElement) return;

    this.flag = false;
    const containerRect = getTargetRect(this.props.container);
    const elementRect = getTargetRect(detectElement);
    // click element offset top with container
    const offset = elementRect.top - containerRect.top;
    const containerOffset = this.props.container !== window
      ? this.props.container.scrollTop
      : (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);
    // manual scroll
    let start = null;
    // scroll event
    const frameFunc = () => {
      if (!start) start = Date.now();
      const progress = Date.now() - start;
      const scrollTop = containerOffset + ((progress / 500)
        * (offset - this.props.offsetTop - containerOffset));
      if (progress < 500) {
        // window.requestAnimationFrame(frameFunc);
        scrollTo(this.props.container, scrollTop);
        raf(frameFunc);
      } else {
        scrollTo(this.props.container, Math.ceil(offset - this.props.offsetTop));
        this.flag = true;
        this.scheduledAnimationFrame = false;
      }
    };
    // window.requestAnimationFrame(frameFunc);
    raf(frameFunc);
  };

  render() {
    const {
      activeClass, componentTag: Tag, style, className, children,
    } = this.props;
    const { elementStatus } = this.state;

    const items = React.Children.map(children, (child, index) => {
      if (!child) return;
      const childClass = classNames({
        [`${child.props.className}`]: child.props.className,
        [`${activeClass}`]: elementStatus[index],
      });
      return React.cloneElement(child, {
        className: childClass,
        onClick: () => this.handleClickElement(index),
      });
    });
    return (
      <Tag className={className || ''} style={style}>
        {items}
      </Tag>
    );
  }
}