import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

class ZipcodeBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', e => {
        this.setState({
          isMobile: window.innerWidth < 768
        })
      })
  }

  render() {
    return (
      <div className='fui-banner is-zipcode' style={{
        backgroundImage: `url(${this.state.isMobile ? this.props.bgImg.sm : this.props.bgImg.md})`,
        height: !this.state.isMobile ? (this.props.height.desktop + 'px') : (this.props.height.mb + 'px')
      }}>
        <div className='fui-container d-flex flex-align-center'>
          <Grid container spacing={2} justify='center'>
            <Grid item xs={12} sm={12} md={10}>
              <h1 className='m-0' dangerouslySetInnerHTML={{ __html: this.props.title }}></h1>
              <div dangerouslySetInnerHTML={{ __html: this.props.description }}></div>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

ZipcodeBanner.propTypes = {
  bgImg: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  height: PropTypes.object,
}

export default ZipcodeBanner;