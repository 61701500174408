import React from 'react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import RadioGroup from '../../components/form/RadioGroup';
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import * as Validation from '../../utils/validation';


class FilteringSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: 'id',
      step: 1,
      step2Validation: false,
      ismail: [
        {
          value: '0',
          label: '是',
        },
        {
          value: '1',
          label: '否',
        },
      ],
      form: {
        setting: { value: 'false', required: false },
        status: { value: 'true', required: false },
        id_number: { value: '', required: false },
      },
      canSubmit: false
    }
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm,
    });
  };
  defaultFormOnChange = (name, value) => {
    let result = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    console.log(result);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = result;
    this.setState({
      form: newForm,
    });
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="來電過濾設定"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="usage-rate pt-md-7 pt-4 no-bg">
          <div className="fui-container">
            <div className="fui-segments no-shadow is-bg-initial">
              {this.state.step == 1 ? <div>
                <h4 className="is-text-darkgray50 mb-md-8 mb-4">
                  來電過濾服務幫您過濾掉無法顯示號碼、刻意隱藏號碼或國際電話的來電。
                </h4>
                <div className="paper mt-md-4 mt-2 mb-2 pb-md-7 pb-4">
                  <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-2">
                    <h4 className='m-0 is-text-medium'>設定須知</h4>
                  </div>
                  <ul className="green-dot-list is-text-medium mb-0">
                    <li>可設定門號數：50門</li>
                    <li>計費方式：每月30元，使用不足一個月按比例計算</li>
                    <li>公司戶及企業用戶不開放申請</li>
                    <li className='m-0'>開啟此服務後可進行 <a href="#" className="info-link">過濾名單設定</a></li>
                  </ul>
                </div>
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                  <div className="paper pt-md-7 pt-4 pb-md-6 pb-2 mb-0 border--bottom">
                    <RadioGroup
                      label='目前設定'
                      name="setting"
                      className='mb-0'
                      options={[
                        { label: '開啟', value: 'true' },
                        { label: '關閉', value: 'false' }
                      ]}
                      default={this.state.form.setting.value}
                      onChange={this.onChange}
                    />
                  </div>
                  {this.state.form.setting.value == 'true' ? <div className="paper pt-md-7 pt-4 pb-md-6 pb-2">
                    <RadioGroup
                      label='設定過濾方式'
                      name="status"
                      className='mb-0'
                      options={[
                        { label: '轉入語音信箱', value: 'true' },
                        { label: '直接掛斷電話', value: 'false' }
                      ]}
                      default={this.state.form.status.value}
                      onChange={this.onChange}
                    />
                  </div> : null}
                </Formsy>
                {!this.state.step2Validation ? <div className='page-action'><Button
                  onClick={() => this.setState({ step2Validation: true })}
                  link="#"
                  className='mb-lg-0 mb-md-2 mb-0'
                  btnStyle='primary'
                  size='large'>
                  下一步
                  </Button></div> : null}

                {this.state.step2Validation ? <div className="mt-7">
                  <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <h4 className='mb-3'>為保護您個人資料的安全，請輸入門號登記者的證照號碼進行確認</h4>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <LabelInput
                          className="mb-2"
                          name='id_number'
                          required={this.state.form.id_number.required}
                          label='證照號碼'
                          validations={{
                            idNumberValid: Validation.idNumberValid
                          }}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入證照號碼'
                          }}
                          placeholder='請輸入證照號碼'
                          value={this.state.form.id_number.value}
                          onChange={this.onChange}
                        />
                        <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                          身分說明：<br />
                          個人戶：身分證字號<br />
                          公司戶：統一編號或稅籍編號<br />
                          外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                        </p>
                        <div className='page-action'>
                          <Button
                            onClick={() => { this.props.history.push('/filtering/success?filter=' + this.state.form.setting.value + '&status=' + this.state.form.status.value) }}
                            className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`}
                            btnStyle='primary'
                            size='large'>
                            確認
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Formsy>
                </div> : null}
              </div> : null}
            </div>
          </div>
        </section>
      </main >
    )
  }
}

export default FilteringSetting;