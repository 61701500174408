import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import CollapseDiscount from '../../components/collapse/CollapseDiscount';
import Ruler from '../../components/partials/Ruler';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import AppPromotion from '../../components/partials/AppPromotion';
import Link from '../../components/Link';
import Button from '../../components/Button';
import CollapsePaper from '../../components/collapse/CollapsePaper';
import PanelList from '../../components/panelContent/PanelList';
import PanelTab from '../../components/panelContent/PanelTab';
import Panel from '../../components/panel/Panel';
import LinkItem from '../../components/item/LinkItem';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import NewsCard from '../../components/partials/card/NewsCards';
import SectionFaqTab from '../../components/partials/collapse/SectionFaqTab';

import * as Mock from '../../mock/ProfileMock';

class ProfileLogin extends Component {
  constructor(props) {
    super(props);
    this.collapse = React.createRef();
    this.state = {
      open: false,
      minHeight: 0,
      minHeight: window.innerWidth < 768 ? 240 : 160,
      collapseHeight: 0,
      collapseOpen: false,
    }

  }
  componentDidMount() {
    this.setCollapseHeight();
    window.addEventListener('resize', e => {
      this.setCollapseHeight()
    })
  }
  openCollapse = () => {
    this.setCollapseHeight();
    this.setState({ open: true })
    // console.log(this.collapse.current.children[0]);
  }

  setCollapseHeight() {
    this.setState({
      collapseHeight: this.collapse.current.children[0].clientHeight
    });
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='生活圈會員'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '生活圈會員', link: '/' },
          ]}
          color='black'
        />
        {/* Postpaid */}
        <section className="login-recommend pb-0">
          <div className="fui-container">
            <h2 className="mb-2">早安！</h2>
            <h4 className="is-text-medium is-text-darkgray50 mb-3">瞧瞧你目前的生活圈會員等級及相關資訊！</h4>
            <div className="paper">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={4} className="">
                  <div className="member-badge is-bg-lightgray70">
                    <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-3.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-3@2x.png'} alt="三星會員" />
                    <div className="d-flex flex-column">
                      <small className="is-text-darkgray70">
                        目前等級：
                      </small>
                      <h2>三星會員</h2>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                  <small className="is-text-darkgray70">目前累積遠傳幣：</small>
                  <div className="level-info">
                    <div className="d-inline-flex">
                      <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/fcoin.svg'} alt="遠傳幣" />
                      <h1 className="is-text-accent">
                        3,250
                      </h1>
                    </div>
                    <div className="body float-right is-text-medium">
                      距離四星會員1,300
                    </div>
                  </div>
                  <Ruler
                    className="mb-md-4 mb-1"
                    percentage={60}
                    size="small"
                  />
                  <small className="is-text-darkgray70">
                    累積區間：2020/01/01-2020/06/01
                  </small>
                </Grid>
              </Grid>
            </div>
          </div>
        </section>

        <section className='fui-member-info ribbon-bg pt-0'>
          <div className="fui-container">
            <CollapsePaper className="mb-2" title="遠傳心生活會員回饋計畫">
              <div className='subtitle'>
                遠傳心生活會員回饋計畫是遠傳推出的全新會員制度，目的是讓您能在遠傳電信以及我們提供各項服務的過程中，享受實質的回饋與尊榮感。當您持您所使用的門號進入遠傳心生活APP即成為遠傳心生活會員，便開始享有會員基本回饋(詳見下表說明)，您於基本回饋中累積的遠傳幣，或於遠傳電信年資累計達到指定門檻時即可於次月升等，並開始享有該等級的回饋，關於會員分級制度可查看【遠傳心生活+會員機制與各等級條件說明】。
              </div>
            </CollapsePaper>
            <CollapsePaper className="mb-2" title="遠傳幣說明">
              <div className='pt-2'>
                <PanelList
                  prefix='bulleted'
                  list={[
                    { text: ' 遠傳幣屬性：計等與不計等。計等遠傳幣可由生活+會員基本回饋中獲得，遠傳幣交易明細會以「首登禮/升等禮/基本回饋/基本回饋(含生日禮)」註記。由其他行銷活動獲得的遠傳幣則為不計等遠傳幣，交易明細會以「活動回饋」註記。' },
                    { text: ' 遠傳幣效期：遠傳幣效期可分為1天、3天、7天、15天、30天、1年、3年共7類。你可從遠傳幣交易明細中的「到期日」確認該幣的到期時間。' },
                    { text: '遠傳幣兌換：遠傳幣價值不分屬性或效期均為1遠傳幣=1元，您可用於有提供遠傳幣折抵的商品或行銷活動上，直接折抵您有效的遠傳幣，折抵的遠傳幣記錄可從遠傳幣交易明細中確認。' },
                  ]}
                />
              </div>
            </CollapsePaper>
            <CollapsePaper title="遠傳心生活會員機制">
              <div className='pt-2'>
                <div className="subtitle">
                  遠傳心生活會員機制與各等級條件說明
                </div>
                <PanelList
                  prefix='bulleted'
                  list={[
                    { text: '會員等級：遠傳心生活會員共分為5個等級，各等級名稱與圖示如下：' }
                  ]}
                />
              </div>
              <div className="member-levels">
                <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-1.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-1@2x.png'} width='120px' alt="level-1" />
                <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-2.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-2@2x.png'} width='120px' alt="level-2" />
                <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-3.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-3@2x.png'} width='120px' alt="level-3" />
                <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-4.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/lv-4@2x.png'} width='120px' alt="level-4" />
                <img src={process.env.PUBLIC_URL + '/resources/common/member-icon/vip.png'} srcSet={process.env.PUBLIC_URL + '/resources/common/member-icon/vip@2x.png'} width='120px' alt="level-vip" />
              </div>
              <PanelList
                prefix='bulleted'
                list={[
                  { text: '評等機制：會員每月評等，15日給予新等級。' },
                  { text: '會員升等：該月評等結果優於上月等級時，則於當月15日進行升等，等級效期以新等級重新計算：若新等級等同上月等級或低於上月等級，則維持原有等級與原效期。' }
                ]}
              />
            </CollapsePaper>
            <h2 className="mt-5">遠傳心生活會員各等級條件說明及回饋項目</h2>

            <div className='paper mb-0'>
              <PanelTab tabs={{
                name: 'member-benefits-tab',
                list: [
                  { label: '一星' },
                  { label: '二星' },
                  { label: '三星' },
                  { label: '四星' },
                  { label: 'VIP' },
                ]
              }}>
                <div className='member-benefits-content'>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/star.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>如何成為三星會員</h5>
                      <div className='is-text-darkgray50'>
                        電信年資達5年或過去六個月曾累積達300遠傳幣。
                      </div>
                    </div>
                  </div>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/fcoin-outline.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>基本回饋累點</h5>
                      <div className='is-text-darkgray50'>
                        電信帳單-回饋 0.3%<br />
                        小額代收代付-回饋 0.1%<br />
                        friDay Video/ Music/ shopping-回饋 0.3%
                      </div>
                    </div>
                  </div>
                  <div className='member-benefits-item'>
                    <div className='icon'>
                      <img src={'/resources/common/member-icon/gift.svg'} alt='' />
                    </div>
                    <div className='content'>
                      <h5>權益回饋累點</h5>
                      <div className='is-text-darkgray50'>
                        <PanelList
                          prefix='bulleted'
                          list={[
                            { text: '生日禮-依會員生日月當月消費之基本回饋累點2倍點數回饋，回饋上限1000fcoin，達上限後不予發放點數。' },
                            { text: '註冊禮-用戶註冊3日內發放20遠傳幣。' },
                            { text: '升等禮-等級晉升發放30遠傳幣 (會員升等後3日內發放)。' }
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='member-benefits-content'></div>
                <div className='member-benefits-content'></div>
                <div className='member-benefits-content'></div>
                <div className='member-benefits-content'></div>
              </PanelTab>
            </div>
            <div className="collapse-info">
              <div className="main">
                <div className="fui-collapse" ref={this.collapse} style={{
                  height: `${this.state.open ? this.state.collapseHeight : 0}px`
                }}>
                  <div className="fui-collapse-body">
                    <div className="d-block">
                      <h5 className="m-0">回饋項目說明</h5>
                      <ul className="green-dot-list">
                        <li>新增：將你想過瀟掉的號碼填入空攔位中，可按「新增名單」一次填寫多筆資料，最多填寫50筆門號，填完後按下「儲存變更」即可。</li>
                        <li>修改：若想修改黑名單號碼，可按「修改」，這時原本無法更改的號碼將變成可修改的狀態，修改完成後按下「確認修改」，並按下「儲存變更」即可。</li>
                        <li>刪除：如果想要刪除已經設定好的號碼，可按「刪除」，確認刪除成功後，按下「儲存變更即可」。</li>
                        <li>門號請依來電時顯示之號碼輸入：
                                                        <ol>
                            <li>(1)行動電話需為十碼,例扣：0936-xxx-xxx</li>
                            <li>(2)一般室話需含區域號碼,例如：02-xxxxxxx</li>
                            <li>(3)國際電話需由國碼開始輸入：例如:86137-xxxxxxxxxx(如行動電話號碼及區域碼首位数宇為0,需去除)。</li>
                          </ol>
                        </li>
                        <li>無法顯示號碼來電與刻意隱藏來電：如果你不希望系統過濾掉「無法顯示號碼」以及「刻意用手機際就號碼」的來電，請勾選「接聽所有隱藏號碼來電」的選項，勾選後記得按下「儲存變更」權。</li>
                        <li>拒接國際電話來電：如果你不想接到國際電話的來電,請取消勾選「接聽所有國際號碼來電」的選項，按下「儲存變更」即完成設定。</li>
                        <li>目前暫無法提供智慧型手機及平板電腦設定黑名單。</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="extra">
                {
                  this.state.open ? (
                    <div className="expand-btn" role="button" onClick={e => this.setState({ open: false })}>
                      <span>回饋項目說明</span>
                      <i className="icon-minus"></i>
                    </div>
                  ) : (
                      <div className="expand-btn" onClick={this.openCollapse}>
                        <span>回饋項目說明</span>
                        <i className="icon-plus"></i>
                      </div>
                    )
                }
              </div>
            </div >
          </div>
        </section>

        <SectionCarousel1 {...Mock.promotionCard} />
        <AppPromotion
          bgImage='/resources/cbu/help-center/images/bg-all.png'
          title="加入遠傳生活圈，滿載好康"
          subtitle="立即下載App，享好康回饋！"
          description={`
            <p>
              以遠傳門號快速登入, 上網流量即時看，查帳單、繳帳單，一鍵完成！
              <br />
              還有許多用戶獨享驚喜優惠
            </p>`
          }
          icon='/resources/cbu/help-center/images/img-logo-fet.png'
          android={{
            qrCode: '/resources/cbu/help-center/images/qr-android.jpg',
            icon: '/resources/cbu/help-center/images/google-play.png',
            link: '/',
            title: '支援 Android 7 以上版本'
          }}
          ios={{
            qrCode: '/resources/cbu/help-center/images/qr-ios.png',
            icon: '/resources/cbu/help-center/images/app-store.png',
            link: '/',
            title: '支援 iOS 10 以上版本'
          }}
        />
        <SectionFaqTab {...Mock.faqTab2} />
      </main>
    );
  }
}

export default ProfileLogin;