import React from 'react'
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: this.props.checked }
    this.changeValue = this.changeValue.bind(this);
    this.currentValue = null
  }

  passCheckedVal = (checked) => {
    this.props.onChange(this.props.name, checked);
  }
  changeValue = event => {
    this.props.setValue(event.target.checked);

    this.passCheckedVal(event.target.checked)
  }

  render() {
    const errorMessage = this.props.getErrorMessage();
    return (
      <label className={`checkbox ${this.props.className}`}>
        <input type="checkbox" onChange={this.changeValue} name={this.props.name} value={this.props.getValue()} defaultChecked={this.props.checked} />
        <div className="content">{this.props.label}</div>
        <div className='error-message'>{errorMessage}</div>
      </label>
    );
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default withFormsy(Checkbox);