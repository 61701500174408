import React from 'react';
import PropTypes from 'prop-types';
import Link from './Link';

class HotWord extends React.Component {
  constructor(props) {
    super(props);
    this.keywords = React.createRef();
    this.state = {
      currentTab: 0,
      currentScroll: 0,
      scroller: false,
    };

    this.setScroller = this.setScroller.bind(this);
    this.doScroll = this.doScroll.bind(this);
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', (e) => this.setScroller);
    }
  }

  componentDidMount() {
    this.setScroller();
  }

  detectLast() {
    if (!this.keywords.current) return false;
    return (
      this.state.currentScroll + this.keywords.current.clientWidth >= this.keywords.current.children[0].clientWidth
    );
  }

  doScroll = (dir) => {
    let clientW = this.keywords.current.clientWidth;
    if (typeof window !== 'undefined' && window.isIE) {
      if (dir === 'left') this.keywords.current.scrollLeft = this.keywords.current.scrollLeft - clientW;
      if (dir === 'right') this.keywords.current.scrollLeft = this.keywords.current.scrollLeft + clientW;
    } else {
      if (dir === 'left')
        this.keywords.current.scrollTo({
          left: this.keywords.current.scrollLeft - clientW,
          top: 0,
          behavior: 'smooth',
        });
      if (dir === 'right')
        this.keywords.current.scrollTo({
          left: this.keywords.current.scrollLeft + clientW,
          top: 0,
          behavior: 'smooth',
        });
    }
    this.setState({ currentScroll: this.keywords.current.scrollLeft });
  };

  setScroller() {
    let outer = this.keywords.current.clientWidth;
    let inner = this.keywords.current.children[0].clientWidth;

    if (outer < inner) {
      this.setState({ scroller: true });

      this.keywords.current.addEventListener('scroll', (e) => {
        this.setState({ currentScroll: e.target.scrollLeft });
      });
    }
  }

  render() {
    return (
      <div className='keyword-container'>
        <div
          className={`keyword-list ${this.state.scroller ? 'is-scroller' : ''} ${
            this.state.currentScroll > 0 ? 'is-show-before' : ''
          } ${this.detectLast() ? 'is-hide-after' : ''}`}>
          <div className='label'>{this.label}</div>
          <div className='keyword-content' ref={this.keywords}>
            <div className='keyword-group'>
              {this.props.hotword.map((word, i) => (
                <Link
                  role='button'
                  key={`hot-search-${i}`}
                  to={`${this.props.path}?actionName=SEARCH&searchKey=${word}`}
                  className='fui-button is-label'>
                  {word}
                </Link>
              ))}
            </div>
          </div>
          {this.state.scroller ? (
            <div className='keyword-action'>
              <button
                onClick={(e) => {
                  this.doScroll('left');
                }}
                className={`fui-button is-text ${this.state.currentScroll === 0 ? 'disabled' : ''}`}>
                <i className='icon-chevron-left'></i>
              </button>
              <button
                onClick={(e) => {
                  this.doScroll('right');
                }}
                className={`fui-button is-text ${this.detectLast() ? 'disabled' : ''}`}>
                <i className='icon-chevron-right'></i>
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

HotWord.propTypes = {
  path: PropTypes.string,
  hotword: PropTypes.arrayOf(PropTypes.string),
};

export default HotWord;
