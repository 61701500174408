import React from 'react';
import Item from './Item';
import PropTypes from 'prop-types';

class ListItem extends React.Component {
    renderItem() {
        return this.props.list.map((item, i) => (
             <Item key={`list-item-${i}`} prefix={this.props.prefix}>{item.text}</Item>
        ))
    }

    render() {
        return (
            <div className={`fui-item with-image with-list ${this.props.className || ''}`}>
                <div className="image">
                    <img src={process.env.PUBLIC_URL + this.props.image} srcSet={process.env.PUBLIC_URL + (this.props.retinaImage || this.props.image)} alt={this.props.image} />
                </div>
                <div className='content'>
                    <h4>{this.props.title}</h4>
                    <div className="fui-list">
                        {this.renderItem()}
                    </div>
                </div>
            </div>
        )
    }
}

ListItem.propTypes = {
    prefix: PropTypes.string,
    className: PropTypes.string,
    retinaImage: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(Item).isRequired
}

export default ListItem;