import React, { Component } from 'react'
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import AdaptHeightBanner from '../../components/partials/banner/AdaptHeightBanner';
import CollapsePaper2 from '../../components/partials/collapse/CollapsePaper2';
import SectionAd3 from '../../components/partials/SectionAd3';
import Panel from '../../components/panel/Panel';
import PanelTab from '../../components/panelContent/PanelTab';
import Link from '../../components/Link';
import * as Mock from '../../mock/AddValue.js';
class PrepaidAddValue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: {},
            list: [
                { name: 'panel-tab-1', label: 'APP儲值' },
                { name: 'panel-tab-2', label: '月租帳單儲值' },
                { name: 'panel-tab-3', label: '門市儲值' },
                { name: 'panel-tab-4', label: '超商儲值' },
                { name: 'panel-tab-5', label: '其他儲值' },
            ],
            col: [
                {
                    title: '<h6 class="h6-title">遠傳行動客服 APP 儲值</h6>',
                    content: `<h6 className="paper-h6">可儲值方案</h6>
                                    <ul className="green-dot-list">
                                        <li>語音通話方案</li>
                                    </ul>
                                    <h6 className="paper-h6">儲值優惠</h6>
                                    <ul className="green-dot-list">
                                        <li>儲值語音通話 $300 回饋 500 MB 上網流量</li>
                                        <li>儲值語音通話 $500 回饋 1.2 GB 上網流量</li>
                                        <li>儲值語音通話 $1,000 回饋 2.4 GB 上網流量</li>
                                    </ul>
                                    <h6 className="paper-h6">儲值方式（擇一即可）</h6>
                                    <ul className="green-dot-list">
                                        <li>扣款月租門號(不限與預付卡同申請人)或信用卡的登記人，攜帶身分證至遠傳門市申請。</li>
                                        <li>預付卡門號直播777，向客服索取紙本申請表，填寫完成並傳真或郵寄至遠傳。</li>
                                    </ul>
                                    <h6 className="paper-h6">儲值條件</h6>
                                    <ul className="green-dot-list mb-5 pb-2">
                                        <li>
                                            符合以下其中一種狀況，將會自動扣款所登記的月租門號或信用卡
                                        <ol>
                                                <li>
                                                    <div className="circle">1</div>
                                                餘額低於$50
                                            </li>
                                                <li>
                                                    <div className="circle">2</div>
                                                門號效期短於25天
                                            </li>
                                            </ol>
                                        </li>
                                    </ul>
                                    <section className="fui-section-collapse is-static">
                                        <div className="fui-collapse is-open">
                                            <div role="button" className="collapse-header">貼心提醒</div>
                                            <div className="collapse-body">
                                                <article>
                                                    1. 本人如欲終止預付卡儲值自動扣款，須親洽門市或致電客服中心辦理取消。<br />
                                                    2. 預付卡儲值自動扣款限個人戶申辦(即月租型門號之登記人須為自然人)，月租型門號需啟用超過90天，且月租型門號及指定之預付卡門號皆須為正常使用狀態。<br />
                                                    3. 申辦預付卡儲值自動扣款、預付卡儲值定期扣款後，月租型門號或預付卡門號有任何異動(停機、一退一租、月租型轉至預付卡或預付卡轉至月租型等)，視同取消本專案。<br />
                                                    4. 已生效信用卡扣款的預付卡門號，若需變更其他信用卡號，需重新申辦。<br />
                                                    5. 若客戶已申辦預付卡儲值自動扣款成功，將於申辦後依客戶所選定之項目進行信用卡儲值，並於儲值成功後以簡訊通知申請之預付卡門號，且儲值金額將列入客戶當月信用卡帳單中收取。<br />
                                                    6. 若客戶的預付卡門號是限制客戶通話、暫時停機或永久停機狀態，將導致儲值失敗。若永久停機則信用卡扣款功能取消。永久停機恢復正常使用後系統不恢復此功能，必需重新申請。<br />
                                                    7. 申請人須自行注意所提供的信用卡付款狀態，若因信用卡付款不成功導致門號無法順利儲值，遠傳不負擔相關責任。<br />
                                                    8. 同一證號之月租型用戶，限指定一門預付卡門號做為儲值對象，且被指定的預付卡門號不能再被其他月租型門號指定參加上述任一扣款方式。<br />
                                                    9. 若客戶申辦預付卡儲值自動扣款成功，當指定之預付卡門號餘額低於$50或當門號有效期到期前25天，將依客戶所選定之項目進行自動儲值，並於儲值成功後以簡訊通知客戶(申請預付卡儲值自動扣款之月租型及預付卡門號)，自動儲值金額將列入客戶的月租型帳單中收取。<br />
                                                    10. 若客戶的月租型門號或指定的預付卡門號不是正常使用中，將導致儲值失敗；儲值失敗當月不再捕扣，待月租門號或指定儲入的易付卡門號恢復正常後將繼續扣款。<br />
                                                    11. 透過月租型帳單儲值，每月月租帳單之儲值金額上限為2000元，且儲值後不得請求退還或將儲值金額抵扣指定預付卡門號以外之其他門號的帳款。<br />
                                                    12. 透過月租型門號儲值易付卡但未繳清月租帳單者，無法申請易付卡退費。<br />
                                                    13. 申辦預付卡儲值定期扣款6個月內不得取消或異動，6個月後欲取消或異動請洽客服中心辦理（月租型門號直撥888）。<br />
                                                    14. 預付卡儲值定期扣款申辦成功後次月起，每月1日依據您勾選的項目進行定期定額儲值，並於儲值成功後以簡訊通知，且定期定額扣款的儲值金額將列入當月月租型帳單中收取。<br />
                                                    15. 為保護帳戶安全，月租門號年資需超過3個月才可透過語音直撥878，單次扣款儲值預付卡，且每月被儲值易付卡之累計總金額不得超過5000元。<br />
                                                    16. 儲值成功後，展延門號效期186天。
                                                </article>
                                            </div>
                                        </div>
                                    </section>`
                },
                {
                    title: '<h6 class="h6-title">遠傳行動客服 APP 儲值</h6>',
                    content: `<h6 className="paper-h6">可儲值方案</h6>
                                    <ul className="green-dot-list">
                                        <li>語音通話方案</li>
                                    </ul>
                                    <h6 className="paper-h6">儲值優惠</h6>
                                    <ul className="green-dot-list">
                                        <li>儲值語音通話 $300 回饋 500 MB 上網流量</li>
                                        <li>儲值語音通話 $500 回饋 1.2 GB 上網流量</li>
                                        <li>儲值語音通話 $1,000 回饋 2.4 GB 上網流量</li>
                                    </ul>
                                    <h6 className="paper-h6">儲值方式（擇一即可）</h6>
                                    <ul className="green-dot-list">
                                        <li>扣款月租門號(不限與預付卡同申請人)或信用卡的登記人，攜帶身分證至遠傳門市申請。</li>
                                        <li>預付卡門號直播777，向客服索取紙本申請表，填寫完成並傳真或郵寄至遠傳。</li>
                                    </ul>
                                    <h6 className="paper-h6">儲值條件</h6>
                                    <ul className="green-dot-list mb-5 pb-2">
                                        <li>
                                            符合以下其中一種狀況，將會自動扣款所登記的月租門號或信用卡
                                        <ol>
                                                <li>
                                                    <div className="circle">1</div>
                                                餘額低於$50
                                            </li>
                                                <li>
                                                    <div className="circle">2</div>
                                                門號效期短於25天
                                            </li>
                                            </ol>
                                        </li>
                                    </ul>
                                    <section className="fui-section-collapse is-static">
                                        <div className="fui-collapse is-open">
                                            <div role="button" className="collapse-header">貼心提醒</div>
                                            <div className="collapse-body">
                                                <article>
                                                    1. 本人如欲終止預付卡儲值自動扣款，須親洽門市或致電客服中心辦理取消。<br />
                                                    2. 預付卡儲值自動扣款限個人戶申辦(即月租型門號之登記人須為自然人)，月租型門號需啟用超過90天，且月租型門號及指定之預付卡門號皆須為正常使用狀態。<br />
                                                    3. 申辦預付卡儲值自動扣款、預付卡儲值定期扣款後，月租型門號或預付卡門號有任何異動(停機、一退一租、月租型轉至預付卡或預付卡轉至月租型等)，視同取消本專案。<br />
                                                    4. 已生效信用卡扣款的預付卡門號，若需變更其他信用卡號，需重新申辦。<br />
                                                    5. 若客戶已申辦預付卡儲值自動扣款成功，將於申辦後依客戶所選定之項目進行信用卡儲值，並於儲值成功後以簡訊通知申請之預付卡門號，且儲值金額將列入客戶當月信用卡帳單中收取。<br />
                                                    6. 若客戶的預付卡門號是限制客戶通話、暫時停機或永久停機狀態，將導致儲值失敗。若永久停機則信用卡扣款功能取消。永久停機恢復正常使用後系統不恢復此功能，必需重新申請。<br />
                                                    7. 申請人須自行注意所提供的信用卡付款狀態，若因信用卡付款不成功導致門號無法順利儲值，遠傳不負擔相關責任。<br />
                                                    8. 同一證號之月租型用戶，限指定一門預付卡門號做為儲值對象，且被指定的預付卡門號不能再被其他月租型門號指定參加上述任一扣款方式。<br />
                                                    9. 若客戶申辦預付卡儲值自動扣款成功，當指定之預付卡門號餘額低於$50或當門號有效期到期前25天，將依客戶所選定之項目進行自動儲值，並於儲值成功後以簡訊通知客戶(申請預付卡儲值自動扣款之月租型及預付卡門號)，自動儲值金額將列入客戶的月租型帳單中收取。<br />
                                                    10. 若客戶的月租型門號或指定的預付卡門號不是正常使用中，將導致儲值失敗；儲值失敗當月不再捕扣，待月租門號或指定儲入的易付卡門號恢復正常後將繼續扣款。<br />
                                                    11. 透過月租型帳單儲值，每月月租帳單之儲值金額上限為2000元，且儲值後不得請求退還或將儲值金額抵扣指定預付卡門號以外之其他門號的帳款。<br />
                                                    12. 透過月租型門號儲值易付卡但未繳清月租帳單者，無法申請易付卡退費。<br />
                                                    13. 申辦預付卡儲值定期扣款6個月內不得取消或異動，6個月後欲取消或異動請洽客服中心辦理（月租型門號直撥888）。<br />
                                                    14. 預付卡儲值定期扣款申辦成功後次月起，每月1日依據您勾選的項目進行定期定額儲值，並於儲值成功後以簡訊通知，且定期定額扣款的儲值金額將列入當月月租型帳單中收取。<br />
                                                    15. 為保護帳戶安全，月租門號年資需超過3個月才可透過語音直撥878，單次扣款儲值預付卡，且每月被儲值易付卡之累計總金額不得超過5000元。<br />
                                                    16. 儲值成功後，展延門號效期186天。
                                                </article>
                                            </div>
                                        </div>
                                    </section>`
                },
            ]
        }
    }
    changeMainTab = value => {
        this.setState({
            currentMainTab: value,
        });
        return value === 0 ? this.props.history.push('/cbu/5g') : null
    };
    openCollapse = (key) => {
        console.log(key);
    }
    componentDidMount() {
        // let result = {};
        // this.state.list.map((item) => {
        //     result = [result, ...item]
        // })
        // this.setState({
        //     open: result
        // });
    }
    render() {
        return (
            <main className="prepaid ribbon-bg-wrapper">
                <NavAnchor
                    pageTitle='預付卡'
                    button={{
                        text: '立即儲值',
                        link: '#'
                    }} />
                <Breadcrumb
                    breadcrumb={[
                        { text: '首頁', link: '/' },
                        { text: '預付卡', link: '/' },
                        { text: '預付卡儲值方式', link: '/' },
                    ]}
                    color='white'
                />
                <AdaptHeightBanner
                    height={{
                        desktop: 360,
                        mobile: 180
                    }}
                    bgImg={{
                        md: '/resources/cbu/prepaid/images/cbu-prepaid-promotion-1920x360.jpg',
                        sm: '/resources/cbu/prepaid/images/cbu-prepaid-promotion-750x360.jpg',
                    }}
                    title='預付卡儲值方式' />
                <Panel className="is-bg-lightgray70">
                    <div className="fui-container">
                        <PanelTab
                            tabs={{
                                name: 'panel-tab',
                                list: this.state.list,
                            }}>
                            <div>
                                <CollapsePaper2 {...{
                                    collapseList: [
                                        {
                                            title: `<h6 class="h6-title">遠傳行動客服 APP 儲值</h6>`,
                                            content: `<h6 class="paper-h6">可儲值方案</h6>
                                <ul class="green-dot-list">
                                    <li>計量型上網方案</li>
                                    <li>計日型上網方案</li>
                                    <li>語音通話方案</li>
                                </ul>
                                <h6 class="paper-h6">儲值優惠</h6>
                                <ul class="check-list mb-3">
                                    <li>儲值語音通話 $300 回饋 500 MB 上網流量</li>
                                    <li>儲值語音通話 $500 回饋 1.2 GB 上網流量</li>
                                    <li>儲值語音通話 $1,000 回饋 2.4 GB 上網流量</li>
                                </ul>
                                <h6 class="paper-h6">儲值步驟</h6>
                                <ul class="step-list">
                                    <li>
                                        <div class="circle">1</div>
                                        <h6>下載【遠傳行動客服APP】</h6>
                                    </li>
                                    <li>
                                        <div class="circle">2</div>
                                        <h6>開啟【遠傳行動客服APP】後，請以易付卡門號與密碼登入</h6>
                                    </li>
                                    <li>
                                        <div class="circle">3</div>
                                        <h6>選擇「上網儲值」或「語音儲值」</h6>
                                    </li>
                                    <li>
                                        <div class="circle">4</div>
                                        <h6>選擇「儲值方案」，再選擇以下方式之一付款：月租帳單、信用卡號、銀行帳號；上網儲值亦可從門號內餘額扣抵</h6>
                                    </li>
                                    <li>
                                        <div class="circle">5</div>
                                        <h6>儲值確認完成後，門號就可上網或撥打使用</h6>
                                    </li>
                                </ul>
                                <section class="fui-section-collapse is-static">
                                    <div class="fui-collapse is-open">
                                        <div role="button" class="collapse-header">注意事項</div>
                                        <div class="collapse-body">
                                            <article>
                                                1.易付出國漫遊上網服務僅適用於4G用戶。
                                                    <br />
                                                    2.自申購成功日起30日內有效，於有效期限內抵達國外當地後開機並確認登錄於指定業者(港澳：Hutchison，新加坡：Starhub)優惠網；收到漫遊上網啟用生效簡訊即開始計算上網時數。
                                                </article>
                                        </div>
                                    </div>
                                </section>`,
                                        },
                                    ],
                                    to: '#',
                                    target: '_blank',
                                    container: false
                                }} />
                            </div>

                            <div>
                                <CollapsePaper2 {...{
                                    collapseList: [
                                        {
                                            title: `<h6 class="h6-title">自動扣款</h6>`,
                                            content: `<h6 class="paper-h6">可儲值方案</h6>
                                    <ul class="green-dot-list">
                                        <li>語音通話方案</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值優惠</h6>
                                    <ul class="green-dot-list">
                                        <li>儲值語音通話 $300 回饋 500 MB 上網流量</li>
                                        <li>儲值語音通話 $500 回饋 1.2 GB 上網流量</li>
                                        <li>儲值語音通話 $1,000 回饋 2.4 GB 上網流量</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值方式（擇一即可）</h6>
                                    <ul class="green-dot-list">
                                        <li>扣款月租門號(不限與預付卡同申請人)或信用卡的登記人，攜帶身分證至遠傳門市申請。</li>
                                        <li>預付卡門號直播777，向客服索取紙本申請表，填寫完成並傳真或郵寄至遠傳。</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值條件</h6>
                                    <ul class="green-dot-list mb-5 pb-2">
                                        <li>
                                            符合以下其中一種狀況，將會自動扣款所登記的月租門號或信用卡
                                        <ol>
                                                <li>
                                                    <div class="circle">1</div>
                                                餘額低於$50
                                            </li>
                                                <li>
                                                    <div class="circle">2</div>
                                                門號效期短於25天
                                            </li>
                                            </ol>
                                        </li>
                                    </ul>
                                    <section class="fui-section-collapse is-static">
                                        <div class="fui-collapse is-open">
                                            <div role="button" class="collapse-header">貼心提醒</div>
                                            <div class="collapse-body">
                                                <article>
                                                    1. 本人如欲終止預付卡儲值自動扣款，須親洽門市或致電客服中心辦理取消。<br />
                                                    2. 預付卡儲值自動扣款限個人戶申辦(即月租型門號之登記人須為自然人)，月租型門號需啟用超過90天，且月租型門號及指定之預付卡門號皆須為正常使用狀態。<br />
                                                    3. 申辦預付卡儲值自動扣款、預付卡儲值定期扣款後，月租型門號或預付卡門號有任何異動(停機、一退一租、月租型轉至預付卡或預付卡轉至月租型等)，視同取消本專案。<br />
                                                    4. 已生效信用卡扣款的預付卡門號，若需變更其他信用卡號，需重新申辦。<br />
                                                    5. 若客戶已申辦預付卡儲值自動扣款成功，將於申辦後依客戶所選定之項目進行信用卡儲值，並於儲值成功後以簡訊通知申請之預付卡門號，且儲值金額將列入客戶當月信用卡帳單中收取。<br />
                                                    6. 若客戶的預付卡門號是限制客戶通話、暫時停機或永久停機狀態，將導致儲值失敗。若永久停機則信用卡扣款功能取消。永久停機恢復正常使用後系統不恢復此功能，必需重新申請。<br />
                                                    7. 申請人須自行注意所提供的信用卡付款狀態，若因信用卡付款不成功導致門號無法順利儲值，遠傳不負擔相關責任。<br />
                                                    8. 同一證號之月租型用戶，限指定一門預付卡門號做為儲值對象，且被指定的預付卡門號不能再被其他月租型門號指定參加上述任一扣款方式。<br />
                                                    9. 若客戶申辦預付卡儲值自動扣款成功，當指定之預付卡門號餘額低於$50或當門號有效期到期前25天，將依客戶所選定之項目進行自動儲值，並於儲值成功後以簡訊通知客戶(申請預付卡儲值自動扣款之月租型及預付卡門號)，自動儲值金額將列入客戶的月租型帳單中收取。<br />
                                                    10. 若客戶的月租型門號或指定的預付卡門號不是正常使用中，將導致儲值失敗；儲值失敗當月不再捕扣，待月租門號或指定儲入的易付卡門號恢復正常後將繼續扣款。<br />
                                                    11. 透過月租型帳單儲值，每月月租帳單之儲值金額上限為2000元，且儲值後不得請求退還或將儲值金額抵扣指定預付卡門號以外之其他門號的帳款。<br />
                                                    12. 透過月租型門號儲值易付卡但未繳清月租帳單者，無法申請易付卡退費。<br />
                                                    13. 申辦預付卡儲值定期扣款6個月內不得取消或異動，6個月後欲取消或異動請洽客服中心辦理（月租型門號直撥888）。<br />
                                                    14. 預付卡儲值定期扣款申辦成功後次月起，每月1日依據您勾選的項目進行定期定額儲值，並於儲值成功後以簡訊通知，且定期定額扣款的儲值金額將列入當月月租型帳單中收取。<br />
                                                    15. 為保護帳戶安全，月租門號年資需超過3個月才可透過語音直撥878，單次扣款儲值預付卡，且每月被儲值易付卡之累計總金額不得超過5000元。<br />
                                                    16. 儲值成功後，展延門號效期186天。
                                                </article>
                                            </div>
                                        </div>
                                    </section>`,
                                        },
                                        {
                                            title: `<h6 class="h6-title">定期扣款</h6>`,
                                            content: `<h6 class="paper-h6">可儲值方案</h6>
                                    <ul class="green-dot-list">
                                        <li>語音通話方案</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值方式（擇一即可）</h6>
                                    <ul class="green-dot-list">
                                        <li>扣款月租門號(不限與預付卡同申請人)或信用卡的登記人，攜帶身分證至遠傳門市申請。</li>
                                        <li>月租門號直播888，向客服索取「遠傳預付卡定期儲值設定同意書」紙本申請表->填寫完成並傳真至+886-2-77235893或郵寄遠傳作業中心。</li>
                                    </ul>
                                    <section class="fui-section-collapse is-static">
                                        <div class="fui-collapse is-open">
                                            <div role="button" class="collapse-header">貼心提醒</div>
                                            <div class="collapse-body">
                                                <article>
                                                    1. 本人如欲終止預付卡儲值自動扣款，須親洽門市或致電客服中心辦理取消。<br />
                                                2. 預付卡儲值自動扣款限個人戶申辦(即月租型門號之登記人須為自然人)，月租型門號需啟用超過90天，且月租型門號及指定之預付卡門號皆須為正常使用狀態。<br />
                                                3. 申辦預付卡儲值自動扣款、預付卡儲值定期扣款後，月租型門號或預付卡門號有任何異動(停機、一退一租、月租型轉至預付卡或預付卡轉至月租型等)，視同取消本專案。
                                                </article>
                                            </div>
                                        </div>
                                    </section>`,
                                        },
                                        {
                                            title: `<h6 class="h6-title">單次扣款</h6>`,
                                            content: `<h6 class="paper-h6">可儲值方案</h6>
                                    <ul class="green-dot-list">
                                        <li>語音通話方案</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值優惠</h6>
                                    <ul class="green-dot-list">
                                        <li>儲值語音通話 $300 回饋 500 MB 上網流量</li>
                                        <li>儲值語音通話 $500 回饋 1.2 GB 上網流量</li>
                                        <li>儲值語音通話 $1,000 回饋 2.4 GB 上網流量</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值方式（擇一即可）</h6>
                                    <ul class="green-dot-list">
                                        <li>月租型門號直撥878儲值(月租門號年資需超過3個月才可使用)。</li>
                                    </ul>
                                    <section class="fui-section-collapse is-static">
                                        <div class="fui-collapse is-open">
                                            <div role="button" class="collapse-header">貼心提醒</div>
                                            <div class="collapse-body">
                                                <article>
                                                    1. 本人如欲終止預付卡儲值自動扣款，須親洽門市或致電客服中心辦理取消。<br />
                                                2. 預付卡儲值自動扣款限個人戶申辦(即月租型門號之登記人須為自然人)，月租型門號需啟用超過90天，且月租型門號及指定之預付卡門號皆須為正常使用狀態。<br />
                                                3. 申辦預付卡儲值自動扣款、預付卡儲值定期扣款後，月租型門號或預付卡門號有任何異動(停機、一退一租、月租型轉至預付卡或預付卡轉至月租型等)，視同取消本專案。
                                                </article>
                                            </div>
                                        </div>
                                    </section>`,
                                        }
                                    ],

                                    to: '#',
                                    target: '_blank',
                                    container: false
                                }} />
                            </div>
                            <CollapsePaper2 {...{
                                collapseList: [
                                    {
                                        title: `<h6 class="h6-title">遠傳/全虹門市儲值</h6>`,
                                        content: `
                                        <h6 class="paper-h6">可儲值方案</h6>
                                <ul class="green-dot-list">
                                    <li>計量型上網方案</li>
                                    <li>計日型上網方案</li>
                                    <li>語音通話方案</li>
                                </ul>
                                <h6 class="paper-h6">儲值優惠</h6>
                                <ul class="green-dot-list">
                                    <li>儲值語音通話 $300 回饋 500 MB 上網流量</li>
                                    <li>儲值語音通話 $500 回饋 1.2 GB 上網流量</li>
                                    <li>儲值語音通話 $1,000 回饋 2.4 GB 上網流量</li>
                                </ul>
                                <h6 class="paper-h6">儲值條件</h6>
                                <ul class="green-dot-list">
                                    <li>至全省遠傳門市、全虹門市，提供門市人員要儲值的預付卡門號及儲值方案，立即為您進行儲值</li>
                                    <li>儲值僅需提供門號，不需另備證件</li>
                                </ul>
                                <div class="d-block pt-1">
                                    <a href="#" class='fui-button is-text pr-0'>
                                        <span>查看遠傳門市</span>
                                        <i class='icon-chevron-right ml-1'></i>
                                    </a>
                                </div>
                                <div class="d-block pt-1">
                                    <a href="#" class='fui-button is-external-link is-text pr-0'>
                                        <span>查看全虹門市</span>
                                        <img src="/resources/cbu/prepaid/images/external.svg" alt="external link" />
                                    </a>
                                </div>`,
                                    },
                                ],
                                to: '#',
                                target: '_blank',
                                container: false
                            }} />

                            <CollapsePaper2 {...{
                                collapseList: [
                                    {
                                        title: `<h6 class="h6-title">儲值卡儲值</h6>`,
                                        content: `
                                        <h6 class="paper-h6">可儲值方案</h6>
                                    <ul class="green-dot-list">
                                        <li>計量型上網方案</li>
                                        <li>計日型上網方案</li>
                                        <li>語音通話方案</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值步驟</h6>
                                    <ul class="step-list">
                                        <li>
                                            <div class="circle">1</div>
                                            <h6>至全省四大超商：統一、全家、萊爾富及OK便利商店的機台，操作「電信商品>預付卡儲值」</h6>
                                        </li>
                                        <li>
                                            <div class="circle">2</div>
                                            <h6>至超商櫃台繳費完成後，便利超商人員會交付載有14碼儲值密碼的熱感應儲值卡</h6>
                                        </li>
                                        <li>
                                            <div class="circle">3</div>
                                            <h6>預付卡門號手機直撥777(免付費)，或由市話撥0936-000-777(需付費)，進入語音服務系統</h6>
                                        </li>
                                        <li>
                                            <div class="circle">4</div>
                                            <h6>依循語音指示輸入熱感應儲值卡之「儲值密碼」</h6>
                                        </li>
                                        <li>
                                            <div class="circle">5</div>
                                            <h6>完成儲值作業，系統將確認目前易付卡擁有的門號餘額/上網方案及有效期限</h6>
                                        </li>
                                        <li>
                                            <div class="circle">6</div>
                                            <h6>操作完後，此儲值卡即失效</h6>
                                        </li>
                                    </ul>`,
                                    },
                                    {
                                        title: `<h6 class="h6-title">全家便利商店_立即儲</h6>`,
                                        content: `
                                        <h6 class="paper-h6">可儲值方案</h6>
                                    <ul class="green-dot-list">
                                        <li>計量型上網方案</li>
                                        <li>計日型上網方案</li>
                                        <li>語音通話方案</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值步驟</h6>
                                    <ul class="step-list">
                                        <li>
                                            <div class="circle">1</div>
                                            <h6>至全家便利超商機台操作，選擇「電信商品>預付卡儲值」</h6>
                                        </li>
                                        <li>
                                            <div class="circle">2</div>
                                            <h6>選擇「立即儲產品」，並輸入門號，繳費完成10分鐘後，系統自動完成儲值作業</h6>
                                        </li>
                                    </ul>
                                    <section class="fui-section-collapse is-static">
                                        <div class="fui-collapse is-open">
                                            <div role="button" class="collapse-header">貼心提醒</div>
                                            <div class="collapse-body">
                                                <article>
                                                    1. 請務必保留發票與銷貨明細表，以利日後查詢。
                                                </article>
                                            </div>
                                        </div>
                                    </section>`,
                                    },
                                ],
                                to: '#',
                                target: '_blank',
                                container: false
                            }} />

                            <CollapsePaper2 {...{
                                collapseList: [
                                    {
                                        title: `<h6 class="h6-title">777 語音系統專線儲值</h6>`,
                                        content: `
                                        <h6 class="paper-h6">可儲值方案</h6>
                                    <ul class="green-dot-list">
                                        <li>計量型上網方案</li>
                                        <li>計日型上網方案</li>
                                        <li>語音通話方案</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值優惠</h6>
                                    <ul class="green-dot-list">
                                        <li>儲值語音通話 $300 回饋 500 MB 上網流量</li>
                                        <li>儲值語音通話 $500 回饋 1.2 GB 上網流量</li>
                                        <li>儲值語音通話 $1,000 回饋 2.4 GB 上網流量</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值步驟</h6>
                                    <h6 class="is-text-darkgreen">上網方案</h6>
                                    <ul class="step-list">
                                        <li>
                                            <div class="circle">1</div>
                                            <h6>易付卡門號直撥777(免付費)</h6>
                                        </li>
                                        <li>
                                            <div class="circle">2</div>
                                            <h6>按5再按1(計量型)或2(計日型)，選擇上網型式</h6>
                                        </li>
                                        <li>
                                            <div class="circle">3</div>
                                            <h6>依語音指示及選項儲值上網方案</h6>
                                        </li>
                                    </ul>
                                    <h6 class="paper-h6 is-text-darkgreen">語音通話方案</h6>
                                    <ul class="step-list">
                                        <li>
                                            <div class="circle">1</div>
                                            <h6>易付卡門號直撥777(免付費)</h6>
                                        </li>
                                        <li>
                                            <div class="circle">2</div>
                                            <h6>按1再按2，選擇「信用卡語音儲值」服務</h6>
                                        </li>
                                        <li>
                                            <div class="circle">3</div>
                                            <h6>輸入門號申辦人之身分證字號末9碼</h6>
                                        </li>
                                        <li>
                                            <div class="circle">4</div>
                                            <h6>通過語音系統認證後，即可進行信用卡語音儲值服務</h6>
                                        </li>
                                    </ul>
                                    <section class="fui-section-collapse is-static">
                                        <div class="fui-collapse is-open">
                                            <div role="button" class="collapse-header">貼心提醒</div>
                                            <div class="collapse-body">
                                                <article>
                                                    1.請務必於易付卡門號有效日期前一天進行儲值，以保有您專屬門號的權益。<br />
                                                    2.回饋之上網無法購買遠傳其他加值服務，且取消門號或門號失效後，無法退費。
                                                </article>
                                            </div>
                                        </div>
                                    </section>`,
                                    },
                                    {
                                        title: `<h6 class="h6-title">ATM櫃員機儲值</h6>`,
                                        content: `
                                        <h6 class="paper-h6">可儲值方案</h6>
                                    <ul class="green-dot-list">
                                        <li>語音通話方案</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值優惠</h6>
                                    <ul class="green-dot-list">
                                        <li>儲值語音通話 $300 回饋 500 MB 上網流量</li>
                                        <li>儲值語音通話 $500 回饋 1.2 GB 上網流量</li>
                                        <li>儲值語音通話 $1,000 回饋 2.4 GB 上網流量</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值步驟</h6>
                                    <ul class="step-list">
                                        <li>
                                            <div class="circle">1</div>
                                            <h6>持任一銀行/郵局金融卡到全台ATM機台或各銀行網路銀行</h6>
                                        </li>
                                        <li>
                                            <div class="circle">2</div>
                                            <h6>選擇跨行轉帳</h6>
                                        </li>
                                        <li>
                                            <div class="circle">3</div>
                                            <h6>銀行代碼輸入「812台新銀行」</h6>
                                        </li>
                                        <li>
                                            <div class="circle">4</div>
                                            <h6>轉帳帳號輸入：「8668+儲值門號（共14碼）」</h6>
                                        </li>
                                        <li>
                                            <div class="circle">5</div>
                                            <h6>轉帳金額輸入：$300/$500/$1000（三選一）</h6>
                                        </li>
                                        <li>
                                            <div class="circle">6</div>
                                            <h6>輸入後確認，完成轉帳後門號即可撥打使用</h6>
                                        </li>
                                    </ul>
                                    <section class="fui-section-collapse is-static">
                                        <div class="fui-collapse is-open">
                                            <div role="button" class="collapse-header">貼心提醒</div>
                                            <div class="collapse-body">
                                                <article>
                                                    1. 跨行轉帳手續費$15於儲值成功後自動回饋於通話金。<br />
                                                    2. 請保存收據請以便日後查核。<br />
                                                    3. 轉帳手續費$15於儲值成功後自動回饋於通話金。
                                                </article>
                                            </div>
                                        </div>
                                    </section>`,
                                    },
                                    {
                                        title: `<h6 class="h6-title">Happy Go點數兌換儲值</h6>`,
                                        content: `
                                        <h6 class="paper-h6">可儲值方案</h6>
                                    <ul class="green-dot-list">
                                        <li>語音通話方案</li>
                                    </ul>
                                    <h6 class="paper-h6">儲值步驟</h6>
                                    <ul class="green-dot-list">
                                        <li>易付卡門號直撥868，按1進入「兌換快樂購紅利積點」</li>
                                    </ul>
                                    <h6 class="paper-h6">兌換內容</h6>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>HAPPY GO點數兌換</th>
                                                <th>兌換通話金</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>200點</td>
                                                <td>$50通話金</td>
                                            </tr>
                                            <tr>
                                                <td>400點</td>
                                                <td>$100通話金</td>
                                            </tr>
                                            <tr>
                                                <td>1200點</td>
                                                <td>$300通話金</td>
                                            </tr>
                                            <tr>
                                                <td>2000點</td>
                                                <td>$500通話金</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <section class="fui-section-collapse is-static">
                                        <div class="fui-collapse is-open">
                                            <div role="button" class="collapse-header">貼心提醒</div>
                                            <div class="collapse-body">
                                                <article>
                                                    1. 4G易付卡不適用儲值累計HAPPY GO點數回饋；遠傳電信保留隨時修訂、暫停或終止本活動內容之權利。<br />
                                                    2. 兌換之通話金無法購買遠傳其他加值服務，且取消門號或門號失效後，無法退費。<br />
                                                    3. 透過手機直撥868兌換成功後，點數兌換每個月結算一次，於每月25日結算，每月25日（含）前成功兌換者，遠傳將於結算日後8個工作天內儲值到您輸入的門號。<br />
                                                    4. 請務必於易付卡門號有效日期前一天進行儲值，以保有您專屬門號的權益。<br />
                                                    5. 若啟用密碼連續輸入5次錯誤，請停止輸入並與客戶服務人員聯絡。
                                                </article>
                                            </div>
                                        </div>
                                    </section>`,
                                    },
                                ],
                                to: '#',
                                target: '_blank',
                                container: false
                            }} />

                        </PanelTab>
                        <Link to={'#'} className='fui-button is-text pr-0'>
                            <i className='icon-chevron-left mr-1'></i>
                            <span>回到預付卡儲值</span>
                        </Link>
                    </div>
                </Panel>
                <SectionAd3 {...{
                    patternColor: 'green',
                    title: `透過遠傳官網儲值，立即享有網路限定優惠`,
                    action: [{
                        text: '立即儲值',
                        line: '#',
                    }]
                }} />
            </main >
        );
    }
}
export default PrepaidAddValue;