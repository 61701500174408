import React from 'react';
import Card from '../../card/Card';

import PropTypes from 'prop-types';

class WithIconCard extends React.Component {
  render() {
    return (
      <section>
        <div className='fui-container'>
          <div dangerouslySetInnerHTML={{ __html: this.props.title }}></div>
          <div className='fui-cards is-with-icon-card'>
            {this.props.cards.map((card, i) => (
              <Card id={`interestCard-${i + 1}`} {...card} key={`with-icon-card-${i}`} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

WithIconCard.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      retinaImage: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
      target: PropTypes.string, // _self || _blank
    })
  ),
};

export default WithIconCard;
