import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
class LifeCircleHashtags extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div className='life-circle-head'>
        <div className="life-circle-head--wrapper">
          <div className="align-center">
            <h1 className='is-text-bold' dangerouslySetInnerHTML={{ __html: this.props.title }} />
            <div className="is-text-darkgray50 body" dangerouslySetInnerHTML={{ __html: this.props.content }} />
            <div className="hashtags">
              {this.props.tags.map((item, i) => {
                return (
                  <div onClick={() => { this.props.history.push(item.link) }} key={item.text + i} className='tag'>
                    <img src="/resources/cbu/img/hash-head.svg" alt="tag-head" className="tag-head" />
                    <div className='tag-body'>
                      <img src={`/resources/cbu/img/hash-style-` + ((i % 4) + 1) + `.svg`} alt="icon" className="tag-icon" />
                      {item.text}
                    </div>
                    <img src="/resources/cbu/img/hash-end.svg" alt="tag-end" className="tag-end" />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
LifeCircleHashtags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
    })
  ),
}
export default withRouter(LifeCircleHashtags);