import React, { Component } from 'react'
import Slider from 'react-slick';
import Dropdown from "../../components/Dropdown";
import Button from '../../components/Button';
import PropTypes from 'prop-types';
class CardProductMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: this.props.list,
      rentalSlideSetting: {
        dots: false,
        infinite: false,
        arrows: true,
        slidesToShow: this.props.list.length > 3 ? 4 : this.props.list.length,
        draggable: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              arrows: true,
              slidesToShow: 1,
              variableWidth: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: true,
              slidesToShow: 1,
              variableWidth: true
            }
          }
        ],
      }
    }
  }
  spec = (item, idx, e) => {
    this.setState({
      selected: item.text
    })
    this.props.onChange(item)
  }
  onChange = (e, item, index) => {
    let newData = Object.assign(this.state.list);
    newData[index].selected = e.text;
    this.setState({
      list: newData,
    });
  };
  render() {
    // console.log(this.props.list);
    return (
      <section className='fui-product-section card-product-multiple pb-5'>
        <div className='fui-container mt-md-2 mt-4'>
          <h2 className="mt-0 mb-0">{this.props.title}</h2>
          <Slider {...this.state.rentalSlideSetting}
            className='horizontal-cards is-program mb-0'>
            {this.state.list.map((card, index) => (
              <div key={index} className='fui-card is-card-program mb-0 is-height-460'>
                {card.hottag ? <div className={`tag tag-${card.hottag.color}`}>{card.hottag.text}</div> : null}
                <img src={card.img} alt={card.imgAlt} />

                <div className="d-inline">
                  <div className="body-2 is-text-darkgray50 is-text-regular m-0">
                    {card.brand}
                  </div>
                  {card.tag.map((tag, i) => (
                    <span className='inline-tag' key={`product-tag-${i}`}>
                      {tag}
                    </span>
                  ))}
                  <h6 className='m-0 is-text-regular'>{card.name}</h6>
                </div>
                <div className="product-spec d-flex flex-align-center">
                  <p className='d-inline-block m-0' style={{ flex: '1 1 64px' }}>規格</p>
                  <Dropdown
                    className="is-button ml-0 d-inline-block"
                    list={card.specList}
                    label={card.selected ? card.selected : card.specList[0].text}
                    arrow={true}
                    hasCheck={false}
                    onChange={(e, item) => this.onChange(e, item, index)}
                  />
                </div>
                <Button btnStyle='primary' className='m-0 w-100' link="#" target="_blank">立即購買</Button>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}
CardProductMultiple.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array
}
export default CardProductMultiple;