import React from 'react';
import Collapse from '../../collapse/Collapse';
import Link from "../../Link";
import Tab from '../../tab/Tab';
import TabPane from '../../tab/TabPane';

import PropTypes from 'prop-types';

class SectionFaqTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      currentOpen: false,
      current: -1,
    };
    this.collapseChnage = this.collapseChnage.bind(this);
  }

  collapseChnage(open, index) {
    this.setState({
      current: index,
      currentOpen: open,
    });
  }

  tabChange = (index) => {
    this.setState({
      currentTab: index
    })
  } 

  render() {
    return (
      <section className='fui-faq-collapse' id={this.props.id}>
        <div className='fui-container'>
          {this.props.title ? (
            <h3
              className='is-text-darkgray50'
              dangerouslySetInnerHTML={{
                __html: this.props.title || '',
              }}></h3>
          ) : (
            ''
          )}
          {this.props.tabs.length > 1 ? (
            <React.Fragment>
              <Tab name='fui-faq-collapse-tab' list={this.props.tabs} onChange={this.tabChange} />
              {this.props.collapseList.map((list, i) => (
                <TabPane active={this.state.currentTab === i} key={`collapseList-${i}`}>
                  <div className='fui-collapse-group'>
                    {list.map((collapse, i) => (
                      <Collapse
                        key={`collapse-group-${i}`}
                        title={collapse.title}
                        content={collapse.content}
                        open={this.state.current === i ? this.state.currentOpen : false}
                        onChange={(open) => this.collapseChnage(open, i)}
                      />
                    ))}
                  </div>
                </TabPane>
              ))}
            </React.Fragment>
          ) : null}

          {this.props.tabs.length === 1 ? (
            <React.Fragment>
              {this.props.collapseList.map((list, i) => (
                <div className='fui-collapse-group'>
                  {list.map((collapse, i) => (
                    <Collapse
                      key={`collapse-group-${i}`}
                      title={collapse.title}
                      content={collapse.content}
                      open={this.state.current === i ? this.state.currentOpen : false}
                      onChange={(open) => this.collapseChnage(open, i)}
                    />
                  ))}
                </div>
              ))}
            </React.Fragment>
          ) : null}

          <p className='is-text-darkgray50'>
            還有不了解的嗎？
            <br />到 <Link to={this.props.helpCenterUrl || ''}>幫助中心</Link> 可以替你解答更多！
          </p>
        </div>
      </section>
    );
  }
}

SectionFaqTab.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ),
  collapseList: PropTypes.array
};

export default SectionFaqTab;
