import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

class AdaptHeightBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        this.setState({
          isMobile: window.innerWidth < 768,
        });
      });
  }

  render() {
    return (
      <div
        className='fui-banner is-adaptive'
        style={{
          backgroundImage: `url(${this.state.isMobile ? this.props.bgImg.sm : this.props.bgImg.md})`,
          height: `${this.state.isMobile ? this.props.height.mobile : this.props.height.desktop}px`,
        }}
        id={this.props.id}>
        <div className='fui-container'>
          <h1 dangerouslySetInnerHTML={{ __html: this.props.title }}></h1>
        </div>
      </div>
    );
  }
}

AdaptHeightBanner.propTypes = {
  bgImg: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default AdaptHeightBanner;
