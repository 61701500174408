import React, { Component } from 'react';
import NavAnchor from '../../components/partials/NavAnchor';
import LifeCircleCarousel from '../../components/partials/card/LifeCircleCarousel';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1_AEM';
import Slider from 'react-slick';
import NewsCard from '../../components/partials/card/NewsCards';
import MCuration from '../../components/partials/MCuration';
import SectionTabBannerCard from '@ui/partials/SectionTabBannerCard';

import * as Mock from '../../mock/LifeCircle';
import * as CurationMock from '../../mock/LifeCircle';
import * as FiveGMock from '@mock/FiveGMock'

class Module3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curationArticle: CurationMock.curationArticle[0].list,
    };

    this.milestoneSetting = {
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 3,
      draggable: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 1,
            variableWidth: true,
          },
        },
      ],
    };
  }

  render() {
    return (
      <main className='life-circle article five-g'>
        <NavAnchor
          pageTitle='騎樂無窮'
          button={[
            {
              text: '新申辦',
              link: '#',
            },
            {
              text: '推薦續約',
              link: '#',
            },
          ]}
        />
        <LifeCircleCarousel {...Mock.articleLifeCircleCard1} />
        <SectionCarousel1
          {...{
            className: 'instagram mt-3 mt-md-0',
            title: `<div class="MuiGrid-root panel-layout MuiGrid-container MuiGrid-spacing-xs-5 justify-center"><div class="MuiGrid-root MuiGrid-item MuiGrid-grid-md-4"><div class="align-center"><img src="/resources/cbu/img/photo01.png" width="70%" alt="img"></div>
          </div>
          <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-md-8"><div class="fui-collapse-body "><article class="align-left"><h5>網美A:</h5>
          <p>我在鎮瀾宮!!遠傳5G真的很快!! 看測速截圖就知道!!給女生買禮物最頭痛了，何況對方要是還是追求質感、見過世面又對生活細節特別講究的女生！沒有關係，這裡也替你準備了清單，就按照這份清單下單吧！絕對讓你在年終交換禮物或取悅女伴時不漏氣！</p>
          </article></div>
          </div>
          </div>
          `,
            cards: [
              {
                image: '/resources/cbu/img/cbu-promotion-post-1@2x.jpg',
                title: 'syuyikai',
                description: '挑戰✨遠傳iPhone11 樂享1+1 ✨ Kobe曾經在場上相Jordan討教投籃竅，...',
                link: '#',
              },
              {
                image: '/resources/cbu/img/cbu-promotion-post-2@2x.jpg',
                title: 'yylo11',
                description: '❤️遠傳iPhone11 樂享1+1❤️ 搶到跟自己打架的優惠在這兒💨',
                link: '#',
              },
              {
                image: '/resources/cbu/img/cbu-promotion-post-3@2x.jpg',
                title: 'uj85_',
                description: '遠傳iPhone11 樂享1+1 跟大家分享遠傳的優惠購機方案 🔥🔥',
                link: '#',
              },
              {
                image: '/resources/cbu/img/cbu-promotion-post-3@2x.jpg',
                title: 'ponponboxbox!',
                description: '遠傳iPhone11 樂享1+1 有人跟我一樣被iPhone11燒到嗎⁉️ 升級…',
                link: '#',
              },
            ],
          }}
        />
        <SectionTabBannerCard {...FiveGMock.sectionTabBannerCard} />
        <div className='five-g'>
          <section className='milestone fui-horzonal-cards is-narrow is-bg-lightgray100'>
            <div className='bg'>
              <img
                src='/resources/ebu/images/ebu-5g-timeline-bkg-800x600.jpg'
                className='bg-image d-none d-md-block'
                alt='img'
              />
              <img
                src='/resources/ebu/images/ebu-5g-timeline-bkg-800x600.jpg'
                className='bg-image d-block d-md-none'
                alt='img'
              />
            </div>
            <div className='fui-container'>
              <h2 className='is-text-darkgray50 section-title p-0'>遠傳5G里程碑</h2>
              <Slider {...this.milestoneSetting} className='horizontal-cards'>
                {Mock.milestone.map((card, idx) => (
                  <div key={idx} className='milestone-card fui-card'>
                    <h4>{card.year}</h4>
                    <ul>
                      {card.list.map((item) => (
                        <li key={item}>
                          <img src='/resources/ebu/images/check@2x.png' alt='check' />
                          <div className='text'>{item}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </Slider>
            </div>
          </section>
        </div>
        <div className='five-g'>
          <NewsCard {...Mock.fiveGNewscards} />
        </div>

        <MCuration />
      </main>
    );
  }
}

export default Module3;
