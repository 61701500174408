import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Slider from 'react-slick';
import Dropdown from '../../components/Dropdown';
import Button from '../../components/Button';
import PropTypes from 'prop-types';
class CardProductSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sldierSetting: {
        dots: true,
        infinite: false,
        arrows: true,
        slidesToShow: 1,
        draggable: true,
        adaptiveHeight: false,
      },
      selectedList: this.props.list[0].list[0].text,
    };
  }
  spec = (item, idx, e) => {
    this.setState({
      selectedList: item.text,
    });
    this.props.onChange(item);
  };
  render() {
    return (
      <section className='fui-product-section py-5'>
        <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
          <div className='fui-container'>
            <h2 className='mb-6'>{this.props.title}</h2>
            <div className='fui-panel position-relative main-shadow'>
              <div className={`tag tag-${this.props.list[0].hotTag.color}`}>{this.props.list[0].hotTag.text}</div>
              <Grid container className='pt-md-2 pb-md-6 pb-4'>
                <Grid item xs={12} sm={12} md={6}>
                  {/* 產品圖 */}
                  <Slider {...this.state.sldierSetting} className='fui-product-slider'>
                    {this.props.list[0].image.map((img, i) => (
                      <img src={img} alt='' key={`fui-product-slider-${i}`} />
                    ))}
                  </Slider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className='px-lg-0 px-4'>
                  {/* 產品內容 */}
                  <h6 className='is-text-darkgray50 is-text-regular m-0'>{this.props.list[0].brand}</h6>
                  <h2>
                    {this.props.list[0].tag.map((tag, i) => (
                      <span className='inline-tag' key={`product-tag-${i}`}>
                        {tag}
                      </span>
                    ))}
                    {this.props.list[0].name}
                  </h2>
                  <div className='product-spec d-flex flex-align-center'>
                    <p className='d-inline-block m-0' style={{ flex: '1 1 64px' }}>
                      規格
                    </p>
                    <Dropdown
                      className='is-button ml-0 d-inline-block'
                      list={this.props.list[0].list}
                      label={this.state.selectedList}
                      arrow={true}
                      hasCheck={false}
                      onChange={(e, item, idx) => this.spec(e, item, idx)}
                    />
                  </div>
                  <div className='page-action mt-2'>
                    <Button btnStyle='primary' className='mb-0' link='#' target='_blank'>
                      立即購買
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Formsy>
      </section>
    );
  }
}
CardProductSingle.propTypes = {
  title: PropTypes.string,
  hotTag: PropTypes.string,
  tag: PropTypes.array,
  name: PropTypes.string,
  list: PropTypes.array,
  brand: PropTypes.string,
  image: PropTypes.array,
};
export default CardProductSingle;
