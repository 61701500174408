import React from 'react';
import Button from './Button';

class Cookie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agree: window.localStorage.getItem("cookieAgree")
    }
  }

  agreeCookie = () => {
    window.localStorage.setItem("cookieAgree", true);
    this.setState({
      agree: true
    })
  }

  render() {
    return (
      !this.state.agree ? (
        <div className="fui-cookie" id={this.props.id}>
          <div className="fui-container">
            <div className="content">
              <h5>Cookie之使用</h5>
              <div className='body-2'>為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導致網站某些功能無法正常執行。</div>
            </div>
            <div className="extra">
              <Button btnStyle="secondary" reverse={true} data-aa-tracking-id="我知道了" onClick={(event) => {
                this.agreeCookie();
                // [AA Tracking]
                if (window && typeof window.ctrf === 'function') window.ctrf(event || {}, `我知道了`);
              }}>
                我知道了
              </Button>
            </div>
          </div>
        </div>
      ) : null
    )
  }
}

export default Cookie;