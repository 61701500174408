import React from 'react';
import { Grid } from '@material-ui/core';
import Slider from "react-slick";
import Link from '../../Link';
import PropTypes from 'prop-types';

class ToolsItemCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
    }
  }

  render() {
    const settings = {
      arrows: true,
      dots: false,
      infinite: false,
      slidesToShow: 7,
      slidesToScroll: 1,
      draggable: false,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            variableWidth: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            variableWidth: true
          }
        }
      ]
    }

    return (
      <section className={`fui-horzonal-cards tools-list-carousel ${this.props.className ? this.props.className : ''}`}>
        <div className="fui-container">
          <Grid container>
            <Grid item xs={12} md={12}>
              {
                this.props.title ? (
                  <div>
                    <h2 className="section-title mb-0">{this.props.title}</h2>
                  </div>
                ) : ''
              }
              <div className="tools-item">
                <Slider {...settings} className="horizontal-cards">
                  {this.props.item.map((slide, index) => {
                    return (
                      <div key={slide.title} className='slide'>
                        <Link
                          to={slide.link}
                          target={slide.target || '_self'}
                          data-fet-key={`MC_Home_Item${index + 1}_${slide.title}`}>
                          <img src={slide.image} alt={slide.alt} className='' />
                          <h6 className='my-0'>{slide.title}</h6>
                          <div className='body-1'>{slide.description}</div>
                        </Link>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    )
  }
}

ToolsItemCarousel.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  item: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string,
      alt: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
      target: PropTypes.string,
    })
  )
}

export default ToolsItemCarousel;