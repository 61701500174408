import React from 'react';
import NavTab from '../../components/partials/NavTab';
import FormBanner from '../../components/partials/banner/FormBanner';
import HelpCenterContent from '../../components/helpCenterContent/HelpCenterContent';
import * as Mock from '../../mock/HelpCenterFaq';
class TermsOfService extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
      requestPayload: {},
      requestPayloadIsReady: false
    };
  }

  componentDidMount() {
    this.setState({
      requestPayload: {
        menu: Mock.menu,
        faq: Mock.faq
      },
      requestPayloadIsReady: true
    });
  }
  changeTab = () => {
    console.log('changed tab');

  }

  render() {
    const { requestPayload, requestPayloadIsReady } = this.state;

    return (
      <main className="help-center">
        <NavTab onChange={this.changeTab} {...{
          pageTitle: '幫助中心',
          tabs: {
            name: 'help-center',
            list: [
              { name: 'tab-1', label: '個人用戶', link: '/help-center' },
              { name: 'tab-2', label: '商務用戶', link: 'http://fetnet-demo.aja.com.tw/help-center' },
              { name: 'tab-3', label: '聯絡我們', link: '/help-center/contact' },
              { name: 'tab-4', label: '服務條款', link: '#' },
            ],
          },
          button: null,
          default: 3
        }} />
        <FormBanner
          title="服務條款"
          className="is-helpcenter"
          image={{
            md: '/resources/cbu/img/ebu-survey-banner-1440-x-240-02-copy-2@2x.jpg',
            sm: '/resources/cbu/img/ebu-survey-banner-375-x-170-01-copy-6@2x.jpg',
          }} />
        {
          requestPayloadIsReady &&
          <HelpCenterContent templateType={1} payload={requestPayload} ></HelpCenterContent>
        }
      </main>
    );
  }
}

export default TermsOfService;
