import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import NavAnchor from '../components/partials/NavAnchor';
import ESectionContent from '../components/partials/ESectionContent';
import Panel from '../components/panel/Panel';
import PanelTitle1 from '../components/panelContent/PanelTitle1';
import PanelButton from '../components/panelContent/PanelButton';

class EbuSitemap extends React.Component {
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='遠傳個人服務網站地圖'
          tabs={[
            { label: '商品', link: '#product' },
            { label: '資費', link: '#program' },
            { label: '個人專區', link: '#personal' },
            { label: '遠傳生活圈', link: '#lifeCircle' },
            { label: '5G', link: '#5G' },
            { label: '幫助中心', link: '#helpCenter' },
            { label: '優惠', link: '#discount' },
          ]}
        />

        <ESectionContent id='product' title='商品'>
          <Panel>
            <Grid container id='mobile-service'>
              <Grid item xs={12} sm={12} md={12}>
                <PanelTitle1 title='商品' />
                <div className='panel-button-list'>
                  <PanelButton link='#' text='手機' />
                  <PanelButton link='#' text='平板、3C' />
                  <PanelButton link='#' text='智慧家電' />
                  <PanelButton link='#' text='配件' />
                  <PanelButton link='#' text='出國上網卡' />
                  <PanelButton link='#' text='網路門市總覽' />
                </div>
              </Grid>
            </Grid>

            <hr />
            <PanelTitle1 title='行動應用' />
            <Grid container id='internet-application'>
              <Grid item xs={12} sm={6} md={3}>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='手機保險' />
                  <PanelButton link='#' text='Bobee守護寶' />
                  <PanelButton link='#' text='愛講定位手錶' />
                  <PanelButton link='#' text='遠傳全能行動管家' />
                  <PanelButton link='#' text='一號多機' />
                  <PanelButton link='#' text='來電答鈴' />
                  <PanelButton link='#' text='愛講智慧音箱' />
                  <PanelButton link='#' text='數位萬事通' />
                  <PanelButton link='#' text='行動裝置險(月繳)' />
                  <PanelButton link='#' text='行動裝置險(年繳)' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='簡訊‧MMS' />
                  <PanelButton link='#' text='來電答鈴' />
                  <PanelButton link='#' text='來電過濾' />
                  <PanelButton link='#' text='APP總覽' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <PanelTitle1 title='數位・加值服務' />
            <Grid container id='cloud-service'>
              <Grid item xs={12} sm={6} md={3}>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='friDay影音' />
                  <PanelButton link='#' text='friDay 音樂' />
                  <PanelButton link='#' text='friDay 購物' />
                  <PanelButton link='#' text='friDay相片書' />
                  <PanelButton link='#' text='friDay 57' />
                  <PanelButton link='#' text='遠傳x潔衣家洗衣服務' />
                  <PanelButton link='#' text='愛講智慧音箱' />
                  <PanelButton link='#' text='數位萬事通' />
                  <PanelButton link='#' text='行動裝置險(月繳)' />
                  <PanelButton link='#' text='行動裝置險(年繳)' />
                </div>
              </Grid>
            </Grid>
          </Panel>
        </ESectionContent>
        <ESectionContent id='program' title='資費'>
          <Panel>
            <PanelTitle1 title='網路限定' />
            <Grid container>
              <Grid item xs={12}>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='$499飆網吃到飽' />
                  <PanelButton link='#' text='$399追劇影片好滿足' />
                  <PanelButton link='#' text='$199夠用最實在' />
                  <PanelButton link='#' text='$149輕速雙飽' />
                  <PanelButton link='#' text='$599上網飆到飽' />
                  <PanelButton link='#' text='$699輕速雙飽' />
                  <PanelButton link='#' text='推薦續約' />
                  <PanelButton link='#' text='網路門市總覽' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <PanelTitle1 title='主要資費' />
            <Grid container>
              <Grid item xs={12}>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='4.5G資費' />
                  <PanelButton link='#' text='預付卡' />
                  <PanelButton link='#' text='國際電話007' />
                  <PanelButton link='#' text='WiFi上網' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <PanelTitle1 title='出國/來台上網' />
            <Grid container>
              <Grid item xs={12}>
                <Link to='#' className='sub-link-title'>
                  <h4>國際漫遊/出國上網</h4>
                </Link>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='月租用戶原號漫遊' />
                  <PanelButton link='#' text='想去哪？就搜哪！' />
                  <PanelButton link='#' text='預付卡原號漫遊' />
                  <PanelButton link='#' text='出國上網卡 - 遠遊卡' />
                  <PanelButton link='#' text='來台旅客上網卡' />
                  <PanelButton link='#' text='漫遊開關設定' />
                  <PanelButton link='#' text='機上漫遊' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <PanelTitle1 title='家用上網' />
            <Grid container>
              <Grid item xs={12}>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='遠傳大無線' />
                  <PanelButton link='#' text='遠傳大寬頻光纖' />
                </div>
              </Grid>
            </Grid>
          </Panel>
        </ESectionContent>
        <ESectionContent id='personal' title='個人專區'>
          <Panel>
            <PanelTitle1 title='帳單/費用' />
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <Link to='#' className='sub-link-title'>
                  <h4>帳單服務</h4>
                </Link>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='本期應繳金額' />
                  <PanelButton link='#' text='未結帳金額/上網用量' />
                  <PanelButton link='#' text='繳費/代收交易/發票查詢' />
                  <PanelButton link='#' text='4G上網用量加購' />
                  <PanelButton link='#' text='帳單代收設定/額度管理' />
                  <PanelButton link='#' text='4G即時用量查詢' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Link to='#' className='sub-link-title'>
                  <h4>繳費服務</h4>
                </Link>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='線上繳費' />
                  <PanelButton link='#' text='銀行帳號繳費' />
                  <PanelButton link='#' text='信用卡繳費' />
                  <PanelButton link='#' text='手機條碼繳費' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Link to='#' className='sub-link-title'>
                  <h4>帳單通知設定</h4>
                </Link>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='帳務及繳費通知設定' />
                  <PanelButton link='#' text='「合併帳單」申請' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <PanelTitle1 title='合約費率' />
            <Grid container>
              <Grid item xs={12}>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='目前費率查詢' />
                  <PanelButton link='#' text='費率異動/變更' />
                  <PanelButton link='#' text='推薦費率查詢' />
                  <PanelButton link='#' text='合約資訊查詢' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <PanelTitle1 title='漫遊與門號服務' />
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <Link to='#' className='sub-link-title'>
                  <h4>出國漫遊</h4>
                </Link>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='漫遊通話/上網設定' />
                  <PanelButton link='#' text='漫遊上網包申辦' />
                  <PanelButton link='#' text='7日/15日漫遊優惠申請' />
                  <PanelButton link='#' text='漫遊上網用量查詢' />
                  <PanelButton link='#' text='漫遊加購記錄' />
                  <PanelButton link='#' text='漫遊旅遊包購買記錄' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Link to='#' className='sub-link-title'>
                  <h4>門號功能設定</h4>
                </Link>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className='panel-button-list'>
                      <PanelButton className="d-none d-sm-block" link='#' text='未接提醒' />
                      <PanelButton className="d-none d-sm-block" link='#' text='語音信箱' />
                      <PanelButton className="d-none d-sm-block" link='#' text='VoLTE' />
                      <PanelButton link='#' text='來電答鈴' />
                      <PanelButton link='#' text='來電過濾' />
                      <PanelButton className="d-none d-sm-block" link='#' text='行動上網' />
                      <PanelButton className="d-none d-sm-block" link='#' text='話中插接' />
                      <PanelButton className="d-none d-sm-block" link='#' text='指定轉接' />
                      <PanelButton className="d-none d-sm-block" link='#' text='隱藏號碼' />
                      <PanelButton className="d-none d-sm-block" link='#' text='撥打國際電話' />
                      <PanelButton className="d-block d-sm-none" link='#' text='停車費代收設定' />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <div className='panel-button-list'>
                      <PanelButton className="d-none d-sm-block" link='#' text='撥打限制' />
                      <PanelButton className="d-none d-sm-block" link='#' text='電信特殊號碼查詢' />
                      <PanelButton className="d-none d-sm-block" link='#' text='停車費代收設定' />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="d-none d-sm-block" item xs={12} sm={6} md={3}>
                <Link to='#' className='sub-link-title'>
                  <h4>緊急協助</h4>
                </Link>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='掛失服務' />
                  <PanelButton link='#' text='遺失補卡' />
                  <PanelButton link='#' text='恢復通話' />
                  <PanelButton link='#' text='PUK解卡密碼查詢' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <PanelTitle1 title='交易/異動記錄' />
            <Grid container>
              <Grid item xs={12}>
                <div className='panel-button-list'>
                  <PanelButton className="d-none d-sm-block" link='#' text='門號/服務異動記錄' />
                  <PanelButton className="d-none d-sm-block" link='#' text='設備維修進度查詢' />
                  <PanelButton className="d-none d-sm-block" link='#' text='通訊問題反應記錄' />
                  <PanelButton className="d-none d-sm-block" link='#' text='門市預約記錄' />
                  <PanelButton className="d-none d-sm-block" link='#' text='免費課程報名記錄' />
                  <PanelButton link='#' text='停車費代收記錄查詢' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <PanelTitle1 title='預付卡專區' />
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <Link to='#' className='sub-link-title'>
                  <h4>儲值/加購</h4>
                </Link>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='語音儲值' />
                  <PanelButton link='#' text='上網儲值' />
                  <PanelButton link='#' text='儲值卡儲值' />
                  <PanelButton link='#' text='漫遊上網加購' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Link to='#' className='sub-link-title'>
                  <h4>交易/記錄查詢</h4>
                </Link>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='餘額/上網/到期日查詢' />
                  <PanelButton link='#' text='儲值交易查詢' />
                  <PanelButton link='#' text='預付卡加購交易查詢' />
                  <PanelButton link='#' text='發票記錄查詢' />
                  <PanelButton link='#' text='小額代收/網路門市交易查詢' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <PanelTitle1 title='帳戶服務' />
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <Link to='#' className='sub-link-title'>
                  <h4>帳戶管理</h4>
                </Link>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='個人資料維護' />
                  <PanelButton link='#' text='快速登入設定' />
                  <PanelButton link='#' text='密碼變更' />
                  <PanelButton link='#' text='付款工具維護' />
                  <PanelButton link='#' text='電子發票設定' />
                  <PanelButton link='#' text='更改手機號碼' />
                  <PanelButton link='#' text='訂閱電子報' />
                  <PanelButton link='#' text='已領取序號查詢' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Link to='#' className='sub-link-title'>
                  <h4>我的訂單</h4>
                </Link>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='網路門市訂單' />
                  <PanelButton link='#' text='friDay購物訂單' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Link to='#' className='sub-link-title'>
                  <h4>HAPPYGO優惠</h4>
                </Link>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='HappyGo點數' />
                  <PanelButton link='#' text='HappyGo折抵' />
                  <PanelButton link='#' text='Happy Go 折抵記錄查詢' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <Grid container>
              <Grid item xs={12} className='align-right'>
                <PanelButton link='#' text='個人專區首頁' />
              </Grid>
            </Grid>
          </Panel>
        </ESectionContent>
        <ESectionContent id='lifeCircle' title='遠傳生活圈'>
          <Panel>
            <Grid container>
              <Grid item xs={12}>
                <PanelTitle1 title='主題策展' />
                <div className='panel-button-list'>
                  <PanelButton link='#' text='生活圈策展' />
                  <PanelButton link='#' text='異鄉遊子' />
                  <PanelButton link='#' text='追劇人生' />
                  <PanelButton link='#' text='斜槓老爸' />
                  <PanelButton link='#' text='樂活自在' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <Grid container>
              <Grid item xs={12}>
                <PanelTitle1 title='我的生活圈' />
                <div className='panel-button-list'>
                  <PanelButton link='#' text='我的遠傳幣' />
                  <PanelButton link='#' text='會員等級說明' />
                  <PanelButton link='#' text='白金會員生活禮' />
                </div>
              </Grid>
            </Grid>
            <hr />
            <Grid item xs={12} className='align-right'>
              <PanelButton link='#' text='遠傳生活圈首頁' />
            </Grid>
          </Panel>
        </ESectionContent>
        <ESectionContent id='5G' title='5G'>
          <Panel>
            <Grid container>
              <Grid item xs={12}>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='前進心5G' />
                </div>
              </Grid>
            </Grid>
          </Panel>
        </ESectionContent>
        <ESectionContent id='helpCenter' title='幫助中心'>
          <Panel>
            <Grid container>
              <Grid item xs={12}>
                <PanelTitle1 title='常見問題' />
                <div className='panel-button-list'>
                  <PanelButton link='#' text='帳單/繳費/合約' />
                  <PanelButton link='#' text='網路購物與商品' />
                  <PanelButton link='#' text='遠傳生活圈' />
                  <PanelButton link='#' text='月租型' />
                  <PanelButton link='#' text='網路購物與商品' />
                  <PanelButton link='#' text='其他常見問題' />
                </div>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <PanelTitle1 title='門市服務' />
                <div className='panel-button-list'>
                  <PanelButton link='#' text='找門市' />
                  <PanelButton link='#' text='門市預約及查詢' />
                  <PanelButton link='#' text='預約門市換號' />
                  <PanelButton link='#' text='服務申請證件提醒' />
                </div>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <PanelTitle1 title='客服中心' />
                <div className='panel-button-list'>
                  <PanelButton link='#' text='客服信箱' />
                  <PanelButton link='#' text='服務公告' />
                  <PanelButton link='#' text='遠傳生活圈APP' />
                </div>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <PanelTitle1 title='資訊學習' />
                <div className='panel-button-list'>
                  <PanelButton link='#' text='手機/平板操作說明' />
                  <PanelButton link='#' text='免費學習課程' />
                  <PanelButton link='#' text='APP總覽' />
                </div>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <PanelTitle1 title='維修保固' />
                <div className='panel-button-list'>
                  <PanelButton link='#' text='保固查詢' />
                  <PanelButton link='#' text='網路預約報修' />
                  <PanelButton link='#' text='網路預約報修查詢' />
                  <PanelButton link='#' text='維修進度查詢' />
                </div>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <PanelTitle1 title='其他服務' />
                <div className='panel-button-list'>
                  <PanelButton link='#' text='網內外門號查詢' />
                  <PanelButton link='#' text='電信特殊號碼查詢' />
                  <PanelButton link='#' text='網路涵蓋率查詢' />
                  <PanelButton link='#' text='固網電子帳單' />
                  <PanelButton link='#' text='寬頻服務' />
                </div>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <PanelTitle1 title='服務條款' />
                <div className='panel-button-list'>
                  <PanelButton link='#' text='隱私權條款' />
                  <PanelButton link='#' text='著作權條款' />
                  <PanelButton link='#' text='遠傳生活圈會員條款' />
                </div>
              </Grid>
              <Grid item xs={12} className='align-right'>
                <hr />
                <PanelButton link='#' text='幫助中心首頁' />
              </Grid>
            </Grid>
          </Panel>
        </ESectionContent>
        <ESectionContent id='discount' title='優惠'>
          <Panel>
            <Grid container>
              <Grid item xs={12}>
                <div className='panel-button-list'>
                  <PanelButton link='#' text='熱門優惠' />
                  <PanelButton link='#' text='4.5G語音＋上網' />
                  <PanelButton link='#' text='網路門市限定' />
                  <PanelButton link='#' text='家用上網' />
                  <PanelButton link='#' text='遠傳預付卡' />
                  <PanelButton link='#' text='出國上網' />
                  <PanelButton link='#' text='用戶專屬優惠' />
                  <PanelButton link='#' text='friDay優惠' />
                </div>
              </Grid>
            </Grid>
          </Panel>
        </ESectionContent>
      </main>
    );
  }
}

export default EbuSitemap;
