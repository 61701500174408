import React from 'react';
import { Grid } from '@material-ui/core';
import KeywordInput from '../../KeywordInput';
import HotWord from '../../HotWord';
import PropTypes from 'prop-types';

class HelpCenterBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        this.setState({
          isMobile: window.innerWidth < 768,
        });
      });
  }

  render() {
    return (
      <div
        className={this.props.isFaq ? `fui-banner is-help-center is-faq` : `fui-banner is-help-center`}
        style={{
          backgroundImage: `url(${this.state.isMobile ? this.props.bgImg.sm : this.props.bgImg.md})`,
        }}>
        <div className='caption'>
          <div className='fui-container'>
            <Grid container justify='center'>
              <Grid item xs={12} sm={10} md={8}>
                <h1>{this.props.title}</h1>

                <KeywordInput keyword={this.state.keyword} {...this.props.keyword} />

                {this.props.hot && this.props.hotword ? <HotWord {...this.props.hot} /> : null}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

HelpCenterBanner.propTypes = {
  title: PropTypes.string,
  bgImg: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  keyword: PropTypes.shape({
    defaultKeyword: PropTypes.arrayOf(PropTypes.string),
    path: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  hot: PropTypes.shape({
    hotword: PropTypes.arrayOf(PropTypes.string),
    path: PropTypes.string,
  }),
};

export default HelpCenterBanner;
