import React from 'react';
import Item from '../item/LinkItem';
import Menu from '../Menu';
import PropTypes from 'prop-types';
import { isMobile } from "../..//utils/device-utils";

const ResponseCollapse = (props) => {
  const [currentCollapse, setCurrentCollapse] = React.useState(0);
  const [isCollapseOpen, setIsCollapseOpen] = React.useState(props.open ? props.open : false);

  const collapseOpen = (event, index) => {
    if (index !== currentCollapse) {
      setCurrentCollapse(index);
      setIsCollapseOpen((isCollapseOpen) => {
        // [AA Tracking]
        if (isMobile() && window && typeof(window.ctrf) === 'function') window.ctrf(event || {}, `Tab_展開`);
        return true
      });
    } else {
      setIsCollapseOpen((isCollapseOpen) => {
        // [AA Tracking]
        if (isMobile() && window && typeof(window.ctrf) === 'function') window.ctrf(event || {}, `${!isCollapseOpen ? 'Tab_展開' : 'Tab_收合'}`);
        return !isCollapseOpen
      });
    }
  };

  const renderItem = () =>
    props.list.map((item, idx) => (
      <Item {...item} key={`${props.title}-collapse-item-${idx}`}>
        {item.text}
      </Item>
    ));

  return (
    <div
      className={`fui-collapse response-collapse ${currentCollapse === 0 && isCollapseOpen ? 'is-open' : ''}`}
      id={props.id}>
      <h6
        role='button'
        data-aa-tracking-id={`${!isCollapseOpen ? "Tab_展開" : "Tab_收合"}`}
        onClick={(e) => collapseOpen(e, 0)}
        className={`collapse-header mobile-collapse-header pointer-none`}>
        {props.title}
      </h6>
      <Menu className='mobile-collapse'>{renderItem()}</Menu>
    </div>
  );
};

ResponseCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
      target: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
  open: PropTypes.bool,
};

export default ResponseCollapse;
