import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

class PanelFigure2 extends React.Component {
    render() {
        return (
            <div className={`fui-gallery ${this.props.column ? (this.props.column===4 ? 'four-column' : 'three-column') : ''}`}>
                {
                    this.props.figures.map((figure, i) => (
                        <figure className="fui-figure" key={`panel-gallery-${i}`}>
                            <NavLink to={figure.link}>
                                <img src={process.env.PUBLIC_URL + figure.image} srcSet={process.env.PUBLIC_URL + (figure.retinaImage || figure.image)} alt={figure.alt} />
                            </NavLink>
                        </figure>
                    ))
                }
            </div>
        )
    }
}

PanelFigure2.propTypes = {
    column: PropTypes.number, // 3 | 4
    figures: PropTypes.arrayOf(
        PropTypes.shape({
            retinaImage: PropTypes.string,
            image: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
            alt: PropTypes.string
        })
    )
}

export default PanelFigure2;