import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';

import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import TextInput from '../../components/form/TextInput';
import LabelInput from '../../components/form/LabelInput';
import RadioGroup from '../../components/form/RadioGroup';
import Select from '../../components/form/Select';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import numberFormatter from '../../utils/numberFormatter';
import Tooltip from '../../components/Tooltip';

const options = [
  {
    id: '1',
    name: '超4代易付無限飆網 1 日',
    amount: 100,
    usage: '',
    description: '可連續上網 24 小時',
  },
  {
    id: '2',
    name: '超4代易付無限飆網 3 日',
    amount: 300,
    usage: '',
    description: '可連續上網 72 小時',
  },
  {
    id: '3',
    name: '超4代易付無限飆網 5 日',
    amount: 500,
    usage: '',
    description: '可連續上網 120 小時',
  },
  {
    id: '4',
    name: '超4代易付飆網 1.2GB 組合(效期 60 天)',
    amount: 180,
    usage: '180.00 GB',
    description: '可上網用量共 1.2GB (內含用量 180MB，及贈送用量 1048.8MB / 效期 60 天)',
  },
  {
    id: '5',
    name: '超4代易付飆網 1.2GB 組合(效期 60 天)',
    amount: 300,
    usage: '300.00 GB',
    description: '可上網用量共 2.2GB (內含用量 300MB，及贈送用量 1952.8MB / 效期 60 天)',
  },
  {
    id: '6',
    name: '超4代易付飆網 1.2GB 組合(效期 90 天)',
    amount: 699,
    usage: '699.00 GB',
    description: '可上網用量共 5GB (內含用量 180MB，及贈送用量 4421MB / 效期 90 天)',
  },
  {
    id: '7',
    name: '超4代易付飆網 8GB 組合(效期 185 天)',
    amount: 1000,
    usage: '4.00 GB',
    description: '可連續上網72小時',
  },
  {
    id: '8',
    name: '易付 4.5GB 飆網包 20GB(效期 12 0天)',
    amount: 1399,
    usage: '5.00 GB',
    description: '可連續上網72小時',
  },
  {
    id: '9',
    name: '超4代易付30日無線飆網_$899(60GB後降速)',
    amount: 899,
    usage: '',
    description: '可連續上網72小時',
  }
]

class prepaidExtraBuy extends React.Component {
  constructor(props) {
    super(props);

    this.validCode = React.createRef();
    this.creditGroup = React.createRef();
    this.bankAccount = React.createRef();
    this.state = {
      step: 1,
      canSubmit1: false,
      canSubmit2: false,
      form_1: {
        prepaid_option: { value: '1', required: true },
        payment: { value: '1', required: true },
      },
      form_2: {
      }
    }
  }

  componentDidMount() {

  }

  setInputFilter = (textbox, inputFilter) => {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
  }

  onChange1 = (name, value) => {
    let newForm = Object.assign(this.state.form_1);
    newForm[name].value = value;

    this.setState({
      form_1: newForm
    })

    switch (newForm.payment.value) {
      case "1":
        this.setState({
          form_2: {}
        });
        break;
      case "2":
        this.setState({
          form_2: {
            receipt: { value: '1', required: true },
            receipt_extra_2: { value: '', required: true },
            receipt_extra_3: { value: '', required: true },
            bank_code: { value: '', required: true },
            bank_account: { value: '', required: true },
            agree: { value: false, required: true },
          }
        });

        break;

      case "3":
        this.setState({
          form_2: {
            receipt: { value: '1', required: true },
            receipt_extra_2: { value: '', required: true },
            receipt_extra_3: { value: '', required: true },
            credit_number_1: { value: "", required: true },
            credit_number_2: { value: "", required: true },
            credit_number_3: { value: "", required: true },
            credit_number_4: { value: "", required: true },
            credit_year: { value: null, required: true },
            credit_month: { value: null, required: true },
            credit_code: { value: '', required: true },
            agree: { value: false, required: true },
          }
        });

        break;
      case "4":
        this.setState({
          form_2: {
            msisdn: { value: '', required: true },
            id_number: { value: '', required: true },
          }
        });
        break;
    }
  }

  onChange2 = (name, value) => {
    let newForm = Object.assign(this.state.form_2);
    newForm[name].value = value;

    this.setState({
      form_2: newForm
    })
  }

  disableButton1 = () => {
    this.setState({
      canSubmit1: false,
    });
  };

  enableButton1 = () => {
    this.setState({
      canSubmit1: true,
    });
  };

  disableButton2 = () => {
    this.setState({
      canSubmit2: false,
    });
  };

  enableButton2 = () => {
    this.setState({
      canSubmit2: true,
    });
  };

  StepSubmit = () => {
    window.scrollTo({
      top: 0, left: 0,
      behavior: 'smooth'
    })
    this.setState({
      step: 2
    })

    switch (this.state.form_1.payment.value) {
      case '2':
        this.setInputFilter(this.bankAccount.current.getElementsByTagName('input')[0], function (value) {
          return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
        });
        break;
      case '3':
        let creditInputs = Array.from(this.creditGroup.current.getElementsByTagName('input'));
        creditInputs.map((input, i) => {
          this.setInputFilter(input, function (value) {
            return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
          });

          return input.addEventListener('keyup', e => {
            let index = creditInputs.indexOf(e.target);
            // console.log('input:' + index)
            if (e.currentTarget.value.length === 4 && index < 3) {
              creditInputs[index + 1].focus();
            }
          })
        })

        this.setInputFilter(this.validCode.current.getElementsByTagName('input')[0], function (value) {
          return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
        });
        break;
    }
  };

  submit = () => {
    // this.setState({
    //   step: 2
    // })
  };

  render() {
    const { step, form_1, form_2 } = this.state;
    return (
      <main>

        <NavAnchor
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="上網儲值"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />
        <section className={`page-body ${step === 1 ? 'd-block' : 'd-none'}`}>
          <Formsy onValid={this.enableButton1} onInvalid={this.disableButton1}>
            <div className="fui-container">
              <div className='paper'>
                <Grid container>
                  <Grid item xs={7} md={7}>
                    <h4 className="mb-2 mt-0">儲值門號與餘額</h4>
                  </Grid>
                  <Grid item xs={5} md={5} className='align-right'>
                    <Button btnStyle='secondary' size='small'>查詢上網用量</Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="rate-plan-box">
                      <h5>門號</h5>
                      <h2>0926-899-868</h2>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="rate-plan-box">
                      <h5>餘額</h5>
                      <h2>$209.12</h2>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className='paper'>
                <h4 className="mt-0 mb-2">請選擇上網用量方案</h4>
                <div className="fui-table-response">
                  <table className="option-table">
                    <thead>
                      <tr>
                        <th>選擇</th>
                        <th className="name">方案</th>
                        <th>金額</th>
                        <th>用量</th>
                        <th className='description'>說明</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        options.map((opt, i) => (
                          <tr key={`option-table-${i}`}>
                            <td>
                              <label>
                                <input
                                  id={`prepaid-${i}`}
                                  type="radio"
                                  name="prepaid_option"
                                  value={opt.id}
                                  checked={form_1.prepaid_option.value === opt.id}
                                  onChange={e => this.onChange1('prepaid_option', opt.id)}
                                />
                                <div className="radio"></div>
                              </label>
                            </td>
                            <td><label htmlFor={`prepaid-${i}`}>{opt.name}</label></td>
                            <td align="center"><label htmlFor={`prepaid-${i}`}>$ {numberFormatter(opt.amount)}</label></td>
                            <td align="center"><label htmlFor={`prepaid-${i}`}>{opt.usage}</label></td>
                            <td><label htmlFor={`prepaid-${i}`}>{opt.description}</label></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                <div className='mt-5'>
                  <RadioGroup
                    label='請選擇付款方式'
                    name="payment"
                    options={[
                      { label: '餘額扣抵(剩餘$209.12)', value: '1' },
                      { label: '銀行帳號', value: '2' },
                      { label: '信用卡', value: '3' },
                      { label: '月租帳單', value: '4' },
                    ]}
                    default={form_1.payment.value}
                    onChange={this.onChange1}
                  />
                </div>
              </div>

              <div className="page-action">
                <Button btnStyle="primary" size='large' disabled={!this.state.canSubmit1} onClick={this.StepSubmit}>下一步</Button>
              </div>
            </div>
          </Formsy>
        </section>
        <section className={`page-body ${step === 2 ? 'd-block' : 'd-none'}`}>
          <Formsy onValid={this.enableButton2} onInvalid={this.disableButton2}>
            <div className="fui-container">
              <h4 className="is-text-darkgray70 my-0">請確認儲值內容，並填寫付款資訊</h4>
              <div className='is-text-accent text-sm'>
                <span className='required-dot'></span>必填
              </div>
              <div className='mb-5'>
                <h4 className="is-text-darkgray50 mt-5 mb-2">
                  儲值內容
                </h4>
                <div className="fui-table-response">
                  <table>
                    <thead>
                      <tr>
                        <th>儲值門號</th>
                        <th>方案名稱</th>
                        <th>金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0926-899-868</td>
                        <td>超4代易付180MB_60天_$180(1.2GB組合)</td>
                        <td>$180.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr className='my-5' />
                <h4 className="is-text-darkgray50 mb-2">
                  付款資料
                </h4>
                {
                  form_1.payment.value === '1' ? (
                    <div>
                      <div className="fui-table-response mb-5 pb-md-3">
                        <table>
                          <thead>
                            <tr>
                              <th>付款方式</th>
                              <th>餘額</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>預付卡可用餘額</td>
                              <td>${numberFormatter(209.11)}</td>
                            </tr>
                          </tbody>
                        </table>
                        <small className="is-text-gray100">※提醒您，加購金額將由此門號的可用餘額中扣除。</small>
                      </div>
                    </div>
                  ) : null
                }

                {
                  form_1.payment.value === '3' ? (
                    <div>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <div className="form-group">
                            <label className="is-required">信用卡卡號</label>
                            <div className="credit-input" ref={this.creditGroup}>
                              <input
                                type='tel'
                                name='credit_number[]'
                                required={true}
                                maxLength='4'
                                value={form_2.credit_number_1.value}
                                onChange={e => this.onChange2('credit_number_1', e.currentTarget.value)} />
                              <span>-</span>
                              <input
                                type='tel'
                                name='credit_number[]'
                                required={true}
                                maxLength='4'
                                value={form_2.credit_number_2.value}
                                onChange={e => this.onChange2('credit_number_2', e.currentTarget.value)} />
                              <span>-</span>
                              <input
                                type='tel'
                                name='credit_number[]'
                                required={true}
                                maxLength='4'
                                value={form_2.credit_number_3.value}
                                onChange={e => this.onChange2('credit_number_3', e.currentTarget.value)} />
                              <span>-</span>
                              <input
                                type='tel'
                                name='credit_number[]'
                                required={true}
                                maxLength='4'
                                value={form_2.credit_number_4.value}
                                onChange={e => this.onChange2('credit_number_4', e.currentTarget.value)} />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <div className="form-group mb-2">
                            <label className="is-required">信用卡有效日期</label>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Select
                                  name="credit_month"
                                  required={true}
                                  label={"月"}
                                  value={form_2.credit_month.value}
                                  options={[
                                    { text: '01', value: '01' },
                                    { text: '02', value: '02' },
                                    { text: '03', value: '03' },
                                    { text: '04', value: '04' },
                                    { text: '05', value: '05' },
                                    { text: '06', value: '06' },
                                    { text: '07', value: '07' },
                                    { text: '08', value: '08' },
                                    { text: '09', value: '09' },
                                    { text: '10', value: '10' },
                                    { text: '11', value: '11' },
                                    { text: '12', value: '12' }
                                  ]}
                                  onChange={this.onChange2} />
                              </Grid>
                              <Grid item xs={6}>
                                <Select
                                  name="credit_year"
                                  required={true}
                                  label={"年"}
                                  value={form_2.credit_year.value}
                                  options={[
                                    { text: '20', value: '20' },
                                    { text: '21', value: '21' },
                                    { text: '22', value: '22' },
                                    { text: '23', value: '23' },
                                    { text: '24', value: '24' },
                                    { text: '25', value: '25' },
                                    { text: '26', value: '26' },
                                    { text: '27', value: '27' },
                                    { text: '28', value: '28' },
                                    { text: '29', value: '29' },
                                    { text: '30', value: '30' }
                                  ]}
                                  onChange={this.onChange2} />
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <div className="form-group mb-2">
                            <label className="is-required">
                              檢查碼
                              <Tooltip
                                content={<i className='icon-information i-24 is-text-business100'></i>}
                                trigger="click"
                                tooltip={`信用卡背面三碼數字`}
                              />
                            </label>
                            <div className='credit-input' ref={this.validCode}>
                              <TextInput
                                type='tel'
                                name='credit_code'
                                required={true}
                                maxLength="3"
                                minLength="3"
                                validationError={"請輸入檢查碼"}
                                value={form_2.credit_code.value}
                                onChange={val => this.onChange2('credit_code', val)}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured.png'}
                        srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured@2x.png'}
                        alt='' height="32" className="mr-1" />
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb.png'}
                        srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb@2x.png'}
                        alt='' height="32" className="mr-1" />
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard.png'}
                        srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard@2x.png'}
                        alt='' height="32" className="mr-1" />
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa.png'}
                        srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa@2x.png'}
                        alt='' height="32" className="mr-1" />
                    </div>
                  ) : null
                }

                {
                  form_1.payment.value === '4' ? (
                    <Grid container className='pb-5 mb-5'>
                      <Grid item xs={12} md={6}>
                        <LabelInput
                          label="月租門號(限本人)"
                          name="msisdn"
                          required={true}
                          value={form_2.msisdn.value}
                          onChange={this.onChange2}
                        />
                        <small className="form-note is-text-gray100">※捐贈發票後，不提供索取紙本發票及更改捐贈對象</small>
                      </Grid>
                    </Grid>
                  ) : null
                }
                {
                  form_1.payment.value === '2' ? (
                    <div>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <div className="form-group">
                            <label className="is-required">銀行代碼</label>
                            <Select
                              required={true}
                              name="bank_code"
                              label="請選擇"
                              options={[
                                { text: 'BankCode', value: '1' }
                              ]}
                              value={form_2.bank_code.value}
                              onChange={this.onChange2} />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container className="with-note">
                        <Grid item xs={12} md={6} ref={this.bankAccount}>
                          <LabelInput
                            required={true}
                            label="銀行帳號(限本人)"
                            name="bank_account"
                            value={form_2.bank_account.value}
                            onChange={this.onChange2}
                          />
                          <div className="is-text-sm form-note is-text-gray100">本付款機制採用SSL 256 bites安全加密機制</div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : null
                }

                {
                  form_1.payment.value === '4' ? (
                    <div>
                      <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                      <Grid container>
                        <Grid item md={6}>
                          <LabelInput
                            name='id_number'
                            required={form_2.id_number.required}
                            validationErrors={{
                              isDefaultRequiredValue: '請輸入證照號碼'
                            }}
                            label='證照號碼'
                            placeholder='請輸入證照號碼'
                            value={form_2.id_number.value}
                            onChange={this.onChange2}
                          />
                          <div className='body1 is-text-darkgray50'>
                            身分說明：
                            <br />
                            個人戶：身分證字號
                            <br />
                            公司戶：統一編號或稅籍編號
                            <br />
                            外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : null
                }

                {
                  form_1.payment.value !== '1' && form_1.payment.value !== '4' ? (
                    <div>
                      <hr className="mb-5" />
                      <h4 className="is-text-darkgray50 mb-2">
                        發票明細
                      </h4>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <div className="form-group">
                            <label className="is-required">發票捐贈</label>
                            <div className="radio-buttons">
                              <div className="radio-group receipt-radio">
                                <div>
                                  <label className="radio-button">
                                    <p>不捐贈</p>
                                    <input
                                      type='radio'
                                      name="receipt"
                                      value='1'
                                      checked={form_2.receipt.value === '1'}
                                      onChange={e => this.onChange2('receipt', e.currentTarget.value)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                <div>
                                  <label className="radio-button">
                                    <p>
                                      捐贈，選擇單位
                                    </p>
                                    <input
                                      type='radio'
                                      name="receipt"
                                      value='2'
                                      checked={form_2.receipt.value === '2'}
                                      onChange={e => this.onChange2('receipt', e.currentTarget.value)} />
                                    <span className="checkmark"></span>
                                  </label>
                                  <Select
                                    name="receipt_extra_2"
                                    label="請選擇"
                                    disabled={form_2.receipt.value !== '2'}
                                    value={form_2.receipt_extra_2.value}
                                    onChange={this.onChange2}
                                    options={[
                                      { text: '111', value: '111' }
                                    ]}
                                  />
                                </div>
                                <div>
                                  <label className="radio-button">
                                    <p>
                                      捐贈，填寫愛心碼
                                    </p>
                                    <input
                                      type='radio'
                                      name="receipt"
                                      value='3'
                                      checked={form_2.receipt.value === '3'}
                                      onChange={e => this.onChange2('receipt', e.currentTarget.value)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      name="receipt_extra_3"
                                      disabled={form_2.receipt.value !== '3'}
                                      placeholder="請填寫"
                                      value={form_2.receipt_extra_3.value}
                                      onChange={e => this.onChange2('receipt_extra_3', e.currentTarget.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <small className="is-text-darkgray50">※捐贈發票後，不提供索取紙本發票及更改捐贈對象</small>
                          </div>
                        </Grid>
                      </Grid>

                      <CheckboxCollapse
                        name="agree"
                        label="本人已詳閱及了解此注意事項並同意遵守"
                        validationError='請詳閱及了解此注意事項'
                        validations={{
                          isChecked: form => form.agree
                        }}
                        checked={form_2.agree.value}
                        value={form_2.agree.value}
                        getCheckbox={e => this.onChange2('agree', e)}
                        collapseContent={`
                          <p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。</p>
                          <p>使用「全國繳費平台」繳納遠傳電信服務費用注意事項：</p>
                          <p>
                          1.限以門號申請本人活存帳戶使用此服務。<br/>
                          2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                          3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                          4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                          5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。
                          </p>
                          <p>本人已詳閱及了解上述注意事項並同意遵守</p>
                        `}
                      />
                    </div>
                  ) : null
                }

              </div>

              <div className="page-action">
                <Button btnStyle="primary" size="large" disabled={form_1.payment.value !== '1' && !this.state.canSubmit2} onClick={this.submit}>確認</Button>
              </div>
            </div>
          </Formsy>
        </section>
        {
          step === 1 ? (
            <SectionCollapseInfo
              title="貼心小叮嚀"
              content={`
                超4代易付飆網計日型僅適用於遠傳4.5G易付卡。<br/>
                超4代易付飆網1.2GB：內含贈送數據流量1048.8MB及購買數據流量180MB，自加購日起60天內有效，逾期未用完之贈送數據流量將歸零。<br/>
                超4代易付飆網2.2GB：內含贈送數據流量1952.8MB及購買數據流量300MB，自加購日起60天內有效，逾期未用完之贈送數據流量將歸零。<br/>
                超4代易付飆網5GB：內含贈送數據流量4421MB及購買數據流量699MB，自加購日起90天內有效，逾期未用完之贈送數據流量將歸零。
              `}
            />
          ) : null
        }

      </main>
    )
  }
}

export default prepaidExtraBuy;