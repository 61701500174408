import React from 'react';
import Slider from 'react-slick';
import Link from '../../Link';

import ArrowLeftWhite from '../../animateArrow/ArrowLeftWhite';
import ArrowRightWhite from '../../animateArrow/ArrowRightWhite';
import ProductBgArrow from '../../animateArrow/ProductBgArrow';
import Card from '../../card/Card';

import PropTypes from 'prop-types';

const SectionCarousel1 = (props) => {
  const [setting] = React.useState({
    dots: false,
    infinite: false,
    slidesToShow: 3,
    draggable: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 2,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ],
  });

  const renderBg = () => {
    switch (props.bgStyle) {
      case 'arrow':
        return (
          <div className='section-bg'>
            <ProductBgArrow />
          </div>
        );
      default:
        return (
          <div className='section-bg'>
            <ArrowLeftWhite />
            <ArrowRightWhite />
          </div>
        );
    }
  };

  return (
    <section className={`fui-horzonal-cards ${!!props.className ? props.className : ''}`} id={props.id || ''}>
      {renderBg()}
      <div className='fui-container'>
        {!!props.title ? <div dangerouslySetInnerHTML={{ __html: props.title }}></div> : null}
        <Slider {...setting} className='horizontal-cards'>
          {props.cards.map((card, idx) => (
            <Card key={idx} {...card} style={{ width: 270 }} />
          ))}
        </Slider>
        {props.more && props.more.text && props.more.link ? (
          <div className='align-center'>
            <Link to={props.more.link} className='fui-more'>
              {props.more.text}
              <br />
              <i className='icon-chevron-down'></i>
            </Link>
          </div>
        ) : (
          ''
        )}
        {!!props.socialMedia ? (
          <div className='d-flex flex-row mt-4 flex-align-center mb-md-0 mb-8'>
            <p className='my-0 mr-2'>{props.socialMedia.title}</p>
            <a href={props.socialMedia.line.link} target={props.socialMedia.line.target}>
              <img src='/resources/common/images/line-share.svg' alt='line' />
            </a>
            <a href={props.socialMedia.facebook.link} target={props.socialMedia.facebook.target}>
              <img src='/resources/common/images/facebook-share.svg' alt='facebook' />
            </a>
          </div>
        ) : null}
      </div>
    </section>
  );
};

SectionCarousel1.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.array,
  bgStyle: PropTypes.string,
  more: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
};

export default SectionCarousel1;
