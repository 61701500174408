import React, { Component } from 'react';
import Link from '../Link';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
class ServiceEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: this.props.list
    }
  }
  render() {
    return (
      <section className="service-entry">
        <div className="fui-container">
          <h2>更多服務</h2>
          <Grid container className='list-container' spacing={2}>
            {this.state.list.map((item, i) => {
              return (
                <Grid item xs={12} sm={this.state.list.length === 1 ? 6 : (12 / this.state.list.length)} key={item.title + i}>
                  {!!item.phone ? <a href={`tel:${item.phone}`}>
                    <div className="fui-card is-bg-white">
                      <p className='body'>{item.title}</p>
                      {!!item.phone ? <small className="is-text-gray100">{item.phone}</small> : null}
                    </div>
                  </a> : <Link
                    to={item.link}
                    target='_blank'
                  >
                      <div className="fui-card is-bg-white">
                        <p className='body'>{item.title}</p>
                        {!!item.phone ? <small className="is-text-gray100">{item.phone}</small> : null}
                      </div>
                    </Link>}

                </Grid>
              )
            })}

          </Grid>
        </div>
      </section>
    );
  }
}
ServiceEntry.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
}
export default ServiceEntry;