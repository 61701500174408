export const navBar = {
  pageTitle: '網路限定',
  button: [
    {
      text: '新申辦',
      link: '/'
    },
    {
      text: '推薦續約',
      link: '/'
    }
  ]
};

export const breadcrumb = [
  {link: '/', text: '首頁'},
  {link: '/estore', text: '網路門市'},
  {link: '/estore/exclusive', text: '網路限定'}
];

export const faq = {
  title: '常見問題',
  tabs: [
    { label: '申辦門號' },
    { label: '購物' },
    { label: '售後' },
  ],
  collapseList: [
    [
      {
        title: '網路門市申辦攜碼移入生效時間與實體門市之差異?',
        content: '網路門市申辦攜碼移入生效時間與實體門市之差異?',
      },
      {
        title: '我是否可以幫他人在網路門市申辦門號？',
        content: '我是否可以幫他人在網路門市申辦門號？',
      },
      {
        title: '如何查詢申辦進度？',
        content: '如何查詢申辦進度？',
      }
    ],
  ]
}

export const footerPromotion = {
  image: {
    md: '/resources/cbu/estore/cbu-promotion-banner-05-1920x156.jpg',
    sm: '/resources/cbu/estore/cbu-promotion-banner-05-750x540.jpg'
  },
  title: '網路門市限定 週三驚爆閃購日，AirPods、藍牙耳機週週1折起',
  action: {
    text: '立即領折扣序號',
    link: '/'
  }
}

export const selectedPlan = {
  "name": "單辦門號$149",
  "title": "149",
  "unit": "月租",
  "action": {
    "text": "申辦方案",
    "link": "#"
  },
  "detail": {
    "duration": [
      "24個月"
    ],
    "throughput": "2GB<br/>超量後 降速1Mbps",
    "intranet": "免費",
    "offnet": "1.8/分鐘",
    "cityTalk": "1.8/分鐘",
    "limitedGift": ""
  },
  "banner": {
    "image": {
      "md": "/resources/cbu/exclusive/cbu-exclusive-kv-01-1920x500.jpg",
      "sm": "/resources/cbu/exclusive/cbu-exclusive-kv-01-750x900.jpg"
    },
    "tag": "網路限定",
    "title": "$149平日輕速飽，週末全速飽",
    "description": "網內還可講到飽",
    "actions": [
      {
        "btnStyle": "primary",
        "text": "馬上申辦",
        "link": "#",
        "target": "_blank",
        "type": "link"
      },
      {
        "btnStyle": "secondary",
        "text": "刮開每月折100",
        "link": "#",
        "type": "modal",
        "content": ""
      }
    ]
  },
  "promotionCard": {
    "title": "$149資費優惠",
    "cards": [
      {
        "type": "default",
        "ribbon": "單辦門號",
        "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png",
        "title": "月付149 雙飽只給我的飽",
        "list": [
          {
            "text": "滑Line、FB流量充足"
          },
          {
            "text": "網內無限通話",
            "tooltip": "網內無限通話"
          }
        ],
        "action": {
          "btnStyle": "primary",
          "text": "立即申辦",
          "link": "#",
          "target": "_self"
        }
      },
      {
        "type": "default",
        "ribbon": "單辦門號",
        "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-02@2x.png",
        "title": "月付298 雙倍升級 週末全速飽",
        "list": [
          {
            "text": "週六日全速上網吃到飽"
          },
          {
            "text": "網內無限通話"
          }
        ],
        "action": {
          "btnStyle": "primary",
          "text": "立即申辦",
          "link": "#",
          "target": "_self"
        }
      },
      {
        "type": "promotion",
        "title": "老客戶請看這，續約好禮很多種，登入後看更多！",
        "action": {
          "btnStyle": "primary",
          "text": "馬上登入",
          "link": "#",
          "target": "_self"
        }
      }
    ]
  },
  "parentPromotion": {
    "image": {
      "md": "/resources/cbu/exclusive/cbu-screentime-1920x470.jpg",
      "sm": "/resources/cbu/exclusive/cbu-screentime-m.jpg"
    },
    "title": "你使用，我安心",
    "description": "簡單設定，輕鬆管理；孩子開心，家長放心！",
    "content": "\n          <div><img src=\"/resources/cbu/exclusive/downtime.svg\" width=\"36\" />護眼管理使用時間</div>\n          <div><img src=\"/resources/cbu/exclusive/app-limits.svg\" width=\"36\" />安心設定APP限制</div>\n          <div><img src=\"/resources/cbu/exclusive/locate-child.svg\" width=\"36\" />即時掌握寶貝定位</div>\n        ",
    "actions": {
      "android": {
        "md": "/resources/cbu/exclusive/android-child-screentime@2x.jpg",
        "sm": "/resources/cbu/exclusive/android-child-screentime-m@2x.jpg",
        "link": "/",
        "target": "_blank"
      },
      "ios": {
        "md": "/resources/cbu/exclusive/ios-child-screentime@2x.jpg",
        "sm": "/resources/cbu/exclusive/ios-child-screentime-m@2x.jpg",
        "link": "/",
        "target": "_blank"
      }
    }
  },
  "note": {
    "title": "備註說明",
    "content": "備註說明"
  }
}

export const planTab = [
  [
    {
      "name": "單辦門號$149",
      "title": "149",
      "unit": "月租",
      "action": {
        "text": "申辦方案",
        "link": "#"
      },
      "detail": {
        "duration": [
          "24個月"
        ],
        "throughput": "2GB<br/>超量後 降速1Mbps",
        "intranet": "免費",
        "offnet": "1.8/分鐘",
        "cityTalk": "1.8/分鐘",
        "limitedGift": ""
      },
      "banner": {
        "image": {
          "md": "/resources/cbu/exclusive/cbu-exclusive-kv-01-1920x500.jpg",
          "sm": "/resources/cbu/exclusive/cbu-exclusive-kv-01-750x900.jpg"
        },
        "tag": "網路限定",
        "title": "$149平日輕速飽，週末全速飽",
        "description": "網內還可講到飽",
        "actions": [
          {
            "btnStyle": "primary",
            "text": "馬上申辦",
            "link": "#",
            "target": "_blank",
            "type": "link"
          },
          {
            "btnStyle": "secondary",
            "text": "刮開每月折100",
            "link": "#",
            "type": "modal",
            "content": ""
          }
        ]
      },
      "promotionCard": {
        "title": "$149資費優惠",
        "cards": [
          {
            "type": "default",
            "ribbon": "單辦門號",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png",
            "title": "月付149 雙飽只給我的飽",
            "list": [
              {
                "text": "滑Line、FB流量充足"
              },
              {
                "text": "網內無限通話",
                "tooltip": "網內無限通話"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "單辦門號",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-02@2x.png",
            "title": "月付298 雙倍升級 週末全速飽",
            "list": [
              {
                "text": "週六日全速上網吃到飽"
              },
              {
                "text": "網內無限通話"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "promotion",
            "title": "老客戶請看這，續約好禮很多種，登入後看更多！",
            "action": {
              "btnStyle": "primary",
              "text": "馬上登入",
              "link": "#",
              "target": "_self"
            }
          }
        ]
      },
      "parentPromotion": {
        "image": {
          "md": "/resources/cbu/exclusive/cbu-screentime-1920x470.jpg",
          "sm": "/resources/cbu/exclusive/cbu-screentime-m.jpg"
        },
        "title": "你使用，我安心",
        "description": "簡單設定，輕鬆管理；孩子開心，家長放心！",
        "content": "\n          <div><img src=\"/resources/cbu/exclusive/downtime.svg\" width=\"36\" />護眼管理使用時間</div>\n          <div><img src=\"/resources/cbu/exclusive/app-limits.svg\" width=\"36\" />安心設定APP限制</div>\n          <div><img src=\"/resources/cbu/exclusive/locate-child.svg\" width=\"36\" />即時掌握寶貝定位</div>\n        ",
        "actions": {
          "android": {
            "md": "/resources/cbu/exclusive/android-child-screentime@2x.jpg",
            "sm": "/resources/cbu/exclusive/android-child-screentime-m@2x.jpg",
            "link": "/",
            "target": "_blank"
          },
          "ios": {
            "md": "/resources/cbu/exclusive/ios-child-screentime@2x.jpg",
            "sm": "/resources/cbu/exclusive/ios-child-screentime-m@2x.jpg",
            "link": "/",
            "target": "_blank"
          }
        }
      },
      "note": {
        "title": "備註說明",
        "content": "備註說明"
      }
    },
    {
      "name": "單辦門號$199",
      "title": "199",
      "unit": "月租",
      "action": {
        "text": "申辦方案",
        "link": "#"
      },
      "detail": {
        "duration": [
          "24個月"
        ],
        "throughput": [
          "3.2GB",
          "3.2GB"
        ],
        "intranet": [
          "200 分鐘",
          "200 分鐘"
        ],
        "offnet": [
          "20 分鐘",
          "20 分鐘"
        ],
        "cityTalk": [
          "5 分鐘",
          "5 分鐘"
        ],
        "limitedGift": [
          "",
          ""
        ]
      },
      "banner": {
        "image": {
          "md": "/resources/cbu/exclusive/cbu-exclusive-kv-01-1920x500.jpg",
          "sm": "/resources/cbu/exclusive/cbu-exclusive-kv-01-750x900.jpg"
        },
        "tag": "網路限定",
        "title": "$199夠用最實在",
        "description": "搭門號或搭家電任你選",
        "actions": [
          {
            "btnStyle": "primary",
            "text": "馬上申辦",
            "link": "#",
            "target": "_self",
            "type": "link"
          }
        ]
      },
      "promotionCard": {
        "title": "$199資費優惠",
        "cards": [
          {
            "type": "default",
            "ribbon": "單辦門號",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png",
            "title": "月付149 雙飽只給我的飽",
            "list": [
              {
                "text": "滑Line、FB流量充足"
              },
              {
                "text": "網內無限通話"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "送0元家電",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-03@2x.png",
            "title": "微上網送0元家電",
            "list": [
              {
                "text": "月付$199好禮0元帶回家",
                "tooltip": "月付$199好禮0元帶回家"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "搭手機",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-04@2x.png",
            "title": "網內互打200分鐘免費",
            "list": [
              {
                "text": "3.2GB網路傳輸量",
                "tooltip": "免費送225分鐘"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "promotion",
            "title": "老客戶請看這，續約好禮很多種，登入後看更多！",
            "action": {
              "btnStyle": "primary",
              "text": "馬上登入",
              "link": "#",
              "target": "_self"
            }
          }
        ]
      },
      "note": {
        "title": "備註說明",
        "content": "備註說明"
      }
    },
    {
      "name": "單辦門號$298",
      "title": "298",
      "unit": "月租",
      "action": {
        "text": "申辦方案",
        "link": "#"
      },
      "detail": {
        "duration": [
          "30個月"
        ],
        "throughput": "2GB，周末吃到飽<br/>超量後 降速1Mbps",
        "intranet": "免費",
        "offnet": "20分鐘<br/>(超過 $1.8 / 分鐘)",
        "cityTalk": "$1.8 / 分鐘",
        "limitedGift": ""
      },
      "banner": {
        "image": {
          "md": "/resources/cbu/exclusive/cbu-exclusive-kv-01-1920x500.jpg",
          "sm": "/resources/cbu/exclusive/cbu-exclusive-kv-01-750x900.jpg"
        },
        "tag": "網路限定",
        "title": "$298平日輕速飽，週末全速飽",
        "description": "網內還可講到飽",
        "actions": [
          {
            "btnStyle": "primary",
            "text": "馬上申辦",
            "link": "#",
            "target": "_self",
            "type": "link"
          },
          {
            "btnStyle": "secondary",
            "text": "刮開每月折100",
            "link": "#",
            "type": "modal"
          }
        ]
      },
      "promotionCard": {
        "title": "$298資費優惠",
        "cards": [
          {
            "type": "default",
            "ribbon": "單辦門號",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png",
            "title": "月付149 雙飽只給我的飽",
            "list": [
              {
                "text": "滑Line、FB流量充足"
              },
              {
                "text": "網內無限通話"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "送0元家電",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-03@2x.png",
            "title": "微上網送0元家電",
            "list": [
              {
                "text": "月付$199好禮0元帶回家",
                "tooltip": "月付$199好禮0元帶回家"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "搭手機",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-04@2x.png",
            "title": "網內互打200分鐘免費",
            "list": [
              {
                "text": "3.2GB網路傳輸量",
                "tooltip": "免費送225分鐘"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "promotion",
            "title": "老客戶請看這，續約好禮很多種，登入後看更多！",
            "action": {
              "btnStyle": "primary",
              "text": "馬上登入",
              "link": "#",
              "target": "_self"
            }
          }
        ]
      },
      "note": {
        "title": "備註說明",
        "content": "備註說明"
      }
    },
    {
      "name": "單辦門號$399",
      "title": "399",
      "unit": "月租",
      "action": {
        "text": "申辦方案",
        "link": "#"
      },
      "detail": {
        "duration": [
          "12個月",
          "24個月"
        ],
        "throughput": "20GB",
        "intranet": "免費",
        "offnet": "60 分鐘",
        "cityTalk": "",
        "limitedGift": ""
      },
      "banner": {
        "image": {
          "md": "/resources/cbu/exclusive/cbu-exclusive-kv-01-1920x500.jpg",
          "sm": "/resources/cbu/exclusive/cbu-exclusive-kv-01-750x900.jpg"
        },
        "tag": "網路限定",
        "title": "$399上網20GB",
        "description": "網內免費，網外60分鐘",
        "actions": [
          {
            "btnStyle": "primary",
            "text": "馬上申辦",
            "link": "#",
            "target": "_self",
            "type": "link"
          }
        ]
      },
      "promotionCard": {
        "title": "$399資費優惠",
        "cards": [
          {
            "type": "default",
            "ribbon": "單辦門號",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png",
            "title": "月付149 雙飽只給我的飽",
            "list": [
              {
                "text": "滑Line、FB流量充足"
              },
              {
                "text": "網內無限通話"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "送0元家電",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-03@2x.png",
            "title": "微上網送0元家電",
            "list": [
              {
                "text": "月付$199好禮0元帶回家",
                "tooltip": "月付$199好禮0元帶回家"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "搭手機",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-04@2x.png",
            "title": "網內互打200分鐘免費",
            "list": [
              {
                "text": "3.2GB網路傳輸量",
                "tooltip": "免費送225分鐘"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "promotion",
            "title": "老客戶請看這，續約好禮很多種，登入後看更多！",
            "action": {
              "btnStyle": "primary",
              "text": "馬上登入",
              "link": "#",
              "target": "_self"
            }
          }
        ]
      },
      "note": {
        "title": "備註說明",
        "content": "備註說明"
      }
    },
    {
      "name": "單辦門號$499",
      "title": "499",
      "unit": "月租",
      "action": {
        "text": "申辦方案",
        "link": "#"
      },
      "meta": "輕速雙飽",
      "detail": {
        "duration": [
          "12個月",
          "24個月"
        ],
        "throughput": "合約期間<br/>吃到飽不降速",
        "intranet": "每通前 5 分鐘<br/>免費",
        "offnet": "30 分鐘<br/>( 24 個月約再送 80 分鐘 )",
        "cityTalk": "",
        "limitedGift": "禮券250元 / 500元"
      },
      "banner": {
        "image": {
          "md": "/resources/cbu/exclusive/cbu-exclusive-kv-01-1920x500.jpg",
          "sm": "/resources/cbu/exclusive/cbu-exclusive-kv-01-750x900.jpg"
        },
        "tag": "網路限定",
        "title": "$499平日輕速飽，週末全速飽",
        "description": "網內還可講到飽",
        "actions": [
          {
            "btnStyle": "primary",
            "text": "馬上申辦",
            "link": "#",
            "target": "_self",
            "type": "link"
          },
          {
            "btnStyle": "secondary",
            "text": "刮開每月折100",
            "link": "#",
            "type": "modal"
          }
        ]
      },
      "promotionCard": {
        "title": "$499資費優惠",
        "cards": [
          {
            "type": "default",
            "ribbon": "單辦門號",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png",
            "title": "月付149 雙飽只給我的飽",
            "list": [
              {
                "text": "滑Line、FB流量充足"
              },
              {
                "text": "網內無限通話"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "送0元家電",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-03@2x.png",
            "title": "微上網送0元家電",
            "list": [
              {
                "text": "月付$199好禮0元帶回家",
                "tooltip": "月付$199好禮0元帶回家"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "搭手機",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-04@2x.png",
            "title": "網內互打200分鐘免費",
            "list": [
              {
                "text": "3.2GB網路傳輸量",
                "tooltip": "免費送225分鐘"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "promotion",
            "title": "老客戶請看這，續約好禮很多種，登入後看更多！",
            "action": {
              "btnStyle": "primary",
              "text": "馬上登入",
              "link": "#",
              "target": "_self"
            }
          }
        ]
      },
      "note": {
        "title": "備註說明",
        "content": "備註說明"
      }
    }
  ],
  [
    {
      "name": "搭配手機$399",
      "title": "399",
      "unit": "月租",
      "action": {
        "text": "申辦方案",
        "link": "#"
      },
      "detail": {
        "duration": [
          "30 個月"
        ],
        "throughput": "6GB<br/>合約前 3 個月<br/>上網吃到飽",
        "intranet": "免費",
        "offnet": "20 分鐘",
        "cityTalk": "",
        "limitedGift": "熱銷手機<br/>0 元起"
      },
      "banner": {
        "image": {
          "md": "/resources/cbu/exclusive/cbu-exclusive-kv-01-1920x500.jpg",
          "sm": "/resources/cbu/exclusive/cbu-exclusive-kv-01-750x900.jpg"
        },
        "tag": "網路限定",
        "title": "$149平日輕速飽，週末全速飽",
        "description": "網內還可講到飽",
        "actions": [
          {
            "btnStyle": "primary",
            "text": "馬上申辦",
            "link": "#",
            "target": "_self",
            "type": "link"
          },
          {
            "btnStyle": "secondary",
            "text": "刮開每月折100",
            "link": "#",
            "type": "modal"
          }
        ]
      },
      "promotionCard": {
        "title": "$399資費優惠",
        "cards": [
          {
            "type": "default",
            "ribbon": "單辦門號",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png",
            "title": "月付149 雙飽只給我的飽",
            "list": [
              {
                "text": "滑Line、FB流量充足"
              },
              {
                "text": "網內無限通話"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "送0元家電",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-03@2x.png",
            "title": "微上網送0元家電",
            "list": [
              {
                "text": "月付$199好禮0元帶回家",
                "tooltip": "月付$199好禮0元帶回家"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "搭手機",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-04@2x.png",
            "title": "網內互打200分鐘免費",
            "list": [
              {
                "text": "3.2GB網路傳輸量",
                "tooltip": "免費送225分鐘"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "promotion",
            "title": "老客戶請看這，續約好禮很多種，登入後看更多！",
            "action": {
              "btnStyle": "primary",
              "text": "馬上登入",
              "link": "#",
              "target": "_self"
            }
          }
        ]
      },
      "note": {
        "title": "備註說明",
        "content": "備註說明"
      }
    },
    {
      "name": "搭配手機$599",
      "title": "599",
      "unit": "月租",
      "action": {
        "text": "申辦方案",
        "link": "#"
      },
      "detail": {
        "duration": [
          "30 個月"
        ],
        "throughput": "合約期間<br/>吃到飽不降速",
        "intranet": "免費",
        "offnet": "30 分鐘",
        "cityTalk": "",
        "limitedGift": "熱銷手機<br/>0 元起"
      },
      "banner": {
        "image": {
          "md": "/resources/cbu/exclusive/cbu-exclusive-kv-01-1920x500.jpg",
          "sm": "/resources/cbu/exclusive/cbu-exclusive-kv-01-750x900.jpg"
        },
        "tag": "網路限定",
        "title": "$149平日輕速飽，週末全速飽",
        "description": "網內還可講到飽",
        "actions": [
          {
            "btnStyle": "primary",
            "text": "馬上申辦",
            "link": "#",
            "target": "_self",
            "type": "link"
          },
          {
            "btnStyle": "secondary",
            "text": "刮開每月折100",
            "link": "#",
            "type": "modal"
          }
        ]
      },
      "promotionCard": {
        "title": "$399資費優惠",
        "cards": [
          {
            "type": "default",
            "ribbon": "單辦門號",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png",
            "title": "月付149 雙飽只給我的飽",
            "list": [
              {
                "text": "滑Line、FB流量充足"
              },
              {
                "text": "網內無限通話"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "送0元家電",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-03@2x.png",
            "title": "微上網送0元家電",
            "list": [
              {
                "text": "月付$199好禮0元帶回家",
                "tooltip": "月付$199好禮0元帶回家"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "搭手機",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-04@2x.png",
            "title": "網內互打200分鐘免費",
            "list": [
              {
                "text": "3.2GB網路傳輸量",
                "tooltip": "免費送225分鐘"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "promotion",
            "title": "老客戶請看這，續約好禮很多種，登入後看更多！",
            "action": {
              "btnStyle": "primary",
              "text": "馬上登入",
              "link": "#",
              "target": "_self"
            }
          }
        ]
      },
      "note": {
        "title": "備註說明",
        "content": "備註說明"
      }
    },
    {
      "name": "搭配手機$699",
      "title": "699",
      "unit": "月租",
      "action": {
        "text": "申辦方案",
        "link": "#"
      },
      "detail": {
        "duration": [
          "30 個月"
        ],
        "throughput": "合約期間<br/>吃到飽不降速",
        "intranet": "免費",
        "offnet": "200 分鐘",
        "cityTalk": "",
        "limitedGift": "熱銷手機<br/>0 元起"
      },
      "banner": {
        "image": {
          "md": "/resources/cbu/exclusive/cbu-exclusive-kv-01-1920x500.jpg",
          "sm": "/resources/cbu/exclusive/cbu-exclusive-kv-01-750x900.jpg"
        },
        "tag": "網路限定",
        "title": "$149平日輕速飽，週末全速飽",
        "description": "網內還可講到飽",
        "actions": [
          {
            "btnStyle": "primary",
            "text": "馬上申辦",
            "link": "#",
            "target": "_self",
            "type": "link"
          },
          {
            "btnStyle": "secondary",
            "text": "刮開每月折100",
            "link": "#",
            "type": "modal"
          }
        ]
      },
      "promotionCard": {
        "title": "$399資費優惠",
        "cards": [
          {
            "type": "default",
            "ribbon": "單辦門號",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png",
            "title": "月付149 雙飽只給我的飽",
            "list": [
              {
                "text": "滑Line、FB流量充足"
              },
              {
                "text": "網內無限通話"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "送0元家電",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-03@2x.png",
            "title": "微上網送0元家電",
            "list": [
              {
                "text": "月付$199好禮0元帶回家",
                "tooltip": "月付$199好禮0元帶回家"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "搭手機",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-04@2x.png",
            "title": "網內互打200分鐘免費",
            "list": [
              {
                "text": "3.2GB網路傳輸量",
                "tooltip": "免費送225分鐘"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "promotion",
            "title": "老客戶請看這，續約好禮很多種，登入後看更多！",
            "action": {
              "btnStyle": "primary",
              "text": "馬上登入",
              "link": "#",
              "target": "_self"
            }
          }
        ]
      },
      "note": {
        "title": "備註說明",
        "content": "備註說明"
      }
    }
  ],
  [
    {
      "name": "搭配好禮$699",
      "title": "699",
      "unit": "月租",
      "meta": "上網語音飽",
      "action": {
        "text": "申辦方案",
        "link": "#"
      },
      "detail": {
        "duration": [
          "36 個月"
        ],
        "throughput": "1.2GB",
        "intranet": "50 分鐘",
        "offnet": "20 分鐘",
        "cityTalk": "",
        "limitedGift": "熱銷好禮<br/>多選一"
      },
      "banner": {
        "image": {
          "md": "/resources/cbu/exclusive/cbu-exclusive-kv-01-1920x500.jpg",
          "sm": "/resources/cbu/exclusive/cbu-exclusive-kv-01-750x900.jpg"
        },
        "tag": "網路限定",
        "title": "$149平日輕速飽，週末全速飽",
        "description": "網內還可講到飽",
        "actions": [
          {
            "btnStyle": "primary",
            "text": "馬上申辦",
            "link": "#",
            "target": "_self",
            "type": "link"
          },
          {
            "btnStyle": "secondary",
            "text": "刮開每月折100",
            "type": "modal",
            "modalContent": ""
          }
        ]
      },
      "promotionCard": {
        "title": "$399資費優惠",
        "cards": [
          {
            "type": "default",
            "ribbon": "單辦門號",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-01@2x.png",
            "title": "月付149 雙飽只給我的飽",
            "list": [
              {
                "text": "滑Line、FB流量充足"
              },
              {
                "text": "網內無限通話"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "送0元家電",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-03@2x.png",
            "title": "微上網送0元家電",
            "list": [
              {
                "text": "月付$199好禮0元帶回家",
                "tooltip": "月付$199好禮0元帶回家"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "default",
            "ribbon": "搭手機",
            "image": "/resources/cbu/exclusive/estore-exclusive-plan-icon-04@2x.png",
            "title": "網內互打200分鐘免費",
            "list": [
              {
                "text": "3.2GB網路傳輸量",
                "tooltip": "免費送225分鐘"
              }
            ],
            "action": {
              "btnStyle": "primary",
              "text": "立即申辦",
              "link": "#",
              "target": "_self"
            }
          },
          {
            "type": "promotion",
            "title": "老客戶請看這，續約好禮很多種，登入後看更多！",
            "action": {
              "btnStyle": "primary",
              "text": "馬上登入",
              "link": "#",
              "target": "_self"
            }
          }
        ]
      },
      "note": {
        "title": "備註說明",
        "content": "備註說明"
      }
    }
  ]
]