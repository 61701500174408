import React from 'react';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import SectionFaqTab from '../../components/partials/collapse/SectionFaqTab';
import ArrowLeftWhite from '../../components/animateArrow/ArrowLeftWhite';

import Item from '../../components/item/Item';
import ImageContent from '../../components/partials/ImageContent';

class parkingfeeInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0
    }
  }

  render() {
    const { currentTab } = this.state
    return (
      <main>

        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <Breadcrumb
          color='white'
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '漫遊與門號服務', link: '/' },
            { text: '停車費代收設定', link: '/' },
          ]} />

        <section className='fui-banner is-parkgin-fee'>
          <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/cbu-eservice-banner-parking-1920x470.jpg'} className="d-none d-sm-block" alt="" />
          <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/cbu-eservice-banner-parking-750x900.jpg'} className="d-block d-sm-none" alt="" />
          <div className='caption'>
            <div className="fui-container">
              <h1>停車費代收設定</h1>
              <div className='subtitle'>
                <p>
                  <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/parkingfee-check.png'} alt='check' width="30px" height='30px' /> <span>停車單再也不會變成罰單</span>
                </p>
                <p>
                  <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/parkingfee-check.png'} alt='check' width="30px" height='30px' /> <span>不用在馬路找便利商店繳停車費</span>
                </p>
                <p>
                  <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/parkingfee-check.png'} alt='check' width="30px" height='30px' /> <span>一次設定，不再擔心停車單遺失</span>
                </p>
              </div>
              <Button btnStyle='primary' size='large' link='/parkingfeeController/parkingfeeInitPage'>馬上設定</Button>
            </div>
          </div>
        </section>

        <div className="fui-image-content-layout">
          <div className="bg">
            <ArrowLeftWhite />
          </div>
          <ImageContent
            img={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/parking-fee-setting.jpg'}
            retinaImg={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/parking-fee-setting@2x.jpg'}
            meta="申請步驟"
            title="代收設定好簡單"
            content="申請電信帳單代收停車費，只要填寫車輛資料並設定代收縣市。目前代收的地區已超過10個縣市，您可於登入後，輕鬆設定好各縣市路邊停車格及台北市公有停車場。"
            imgPlace='left'
          />
          <ImageContent
            img={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/parking-fee-remind.jpg'}
            retinaImg={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/parking-fee-remind@2x.jpg'}
            meta="申請成功後"
            title="停車不再需要自行持單繳停車費"
            content="送出設定申請成功後，將發送簡訊至您的門號提醒您，不再擔心停車單遺失。"
          />
          <ImageContent
            img={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/parking-fee-pay.jpg'}
            retinaImg={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/parking-fee-pay@2x.jpg'}
            meta="定時扣繳不再收罰單"
            title="停車收單，自動繳納"
            content="停車繳費單開立後無需繳費，將依各縣市扣款日期完成代繳。更棒的是，繳費明細線上隨時查。"
            imgPlace='left'
          />
        </div>

        <SectionCarousel1
          title='你的專屬優惠'
          cards={[
            {
              image: process.env.PUBLIC_URL + '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
              title: '本月影片免費看!',
              description: '話題電影、日劇、韓劇等等應有盡有',
              link: '#',
            },
            {
              image: process.env.PUBLIC_URL + '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
              title: '中午不知道吃什麼？',
              description: 'Uber Eats 送遠傳新客優惠金200元',
              link: '#',
            },
            {
              image: process.env.PUBLIC_URL + '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
              title: '連假打算出國？',
              description: '遠遊卡吃到飽上網不再煩惱用量 ',
              link: '#',
            },
            {
              image: process.env.PUBLIC_URL + '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
              title: '本月影片免費看!',
              description: '話題電影、日劇、韓劇等等應有盡有',
              link: '#',
            },
          ]}
        />
        <SectionFaqTab
          title="常見問題"
          tabs={[
            { label: '帳單費用/使用記錄' },
            { label: '帳單服務' },
            { label: '繳費操作' },
          ]}
          collapseList={[
            [
              {
                title: '什麼是遠傳電信『電信帳單代收公用停車費服務』？',
                content: '什麼是遠傳電信『電信帳單代收公用停車費服務』？',
                open: false
              },
              {
                title: '『電信帳單代收公用停車費服務』適用對象為何？',
                content: '『電信帳單代收公用停車費服務』適用對象為何？',
                open: false
              },
              {
                title: '『電信帳單代收公用停車費服務』最多可以登記幾個車輛號碼？',
                content: '『電信帳單代收公用停車費服務』最多可以登記幾個車輛號碼？',
                open: false
              }
            ],
            [
              {
                title: '什麼是遠傳電信『電信帳單代收公用停車費服務』？',
                content: '什麼是遠傳電信『電信帳單代收公用停車費服務』？',
                open: false
              }
            ],
            [
              {
                title: '什麼是遠傳電信『電信帳單代收公用停車費服務』？',
                content: '什麼是遠傳電信『電信帳單代收公用停車費服務』？',
                open: false
              }
            ]
          ]}
        />
      </main>
    )
  }
}

export default parkingfeeInfoPage;