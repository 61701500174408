import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import PanelInformation from '../../components/panelContent/PanelInformation';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Panel from '../../components/panel/Panel';
class Transfer extends Component {
  constructor(props) {
    super(props);
    this.panel = React.createRef();
    this.state = {
    }
  }
  render() {
    return (
      <main className="">
        <Header />
        <NavAnchor
          pageTitle='<a href="#">漫遊與門號服務</a>'
          button={{
            link: '#',
            text: '回個人專區',
          }}
        />
        <Breadcrumb
          color="white"
          breadcrumb={[
            { link: '/ebu', text: '個人首頁' },
            { link: '', text: '個人專區' },
            { link: '', text: '指定轉接說明' },
          ]}
        />
        <SectionBanner2
          position='right'
          className="is-360"
          image={{
            md: '/resources/cbu/img/cbu-eservice-call-forwarding-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-eservice-call-forwarding-750x400.jpg',
          }}
          title='<div class="mt-5 is-text-white">指定轉接說明</div>'
          action={null}
        />
        <div className="ribbon-bg pt-md-7 pt-4">
          <div className='fui-container'>
            <h4 className="is-text-darkgray50 mt-0 mb-7">轉接方式共有四種：直接轉接、無應答轉接、忙線轉接、無法接通轉接，可依您不同的需求進行設定。</h4>
            <div className="paper">
              <PanelInformation
                className="py-0"
                title={`<h5 class="m-0 is-text-black50 is-text-medium">指定轉接須知</h5>`}
                content={`
                  <h6 class="m-0 is-text-regular">設定指定轉接後，所有來話一經轉接到您設定的電話號碼時，轉接費用必須由您支付。</h6>
                  <ol class="order-list mb-md-4">
                      <li>
                          <span class="decimal">1</span>
                          轉接費以秒計費(依個人所選用之費率加以收取通話費用)，但接聽時因為是受話方故不收費。(依個人所選用之費率收取通話費用)
                      </li>
                      <li>
                          <span class="decimal">2</span>
                          發話方需負擔以其電話發話至遠傳或原和信門號費用。若設定轉接者在漫遊時，不僅要負擔撥到受話方之漫遊通話費，亦需負擔漫遊受話費。
                      </li>
                      <li>
                          <span class="decimal">3</span>
                          詳細的國際漫遊通話費率 <a class="info-link">請參考</a>。
                      </li>
                  </ol>`}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">直接轉接</h5>'
                content={`
                  <h6 class="mb-4 mt-0 is-text-regular is-text-black50">在手機未響之前，將來電轉接至指定號碼。可透過下面方式，輸入數字或符號後，再按撥出鍵，來設定或取消此功能。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item d-flex flex-column MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3 h-100">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">**21* + 區域碼 + 電話號碼#</h2>
                        </div>
                      </div>
                      <div class="MuiGrid-root MuiGrid-item d-flex flex-column MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">取消</h4>
                        <div class="is-bg-lightgray70 px-3 py-3 h-100">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">##21#</h2>
                        </div>
                      </div>
                    </div></div>
                  `}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">無應答轉接</h5>'
                content={`
                  <h6 class="mb-4 mt-0 is-text-regular is-text-black50">當電話處於無人接聽狀態，將來電轉接至指定號碼。可透過下面方式，輸入數字或符號後，再按撥出鍵，來設定或取消此功能。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item d-flex flex-column MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3 h-100">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">**61* + 區域碼 + 電話號碼#</h2>
                        </div>
                      </div>
                      <div class="MuiGrid-root MuiGrid-item d-flex flex-column MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">取消</h4>
                        <div class="is-bg-lightgray70 px-3 py-3 h-100">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">##61#</h2>
                        </div>
                      </div>
                    </div>
                    </div>
                  `}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">忙線轉接</h5>'
                content={`
                  <h6 class="mb-4 mt-0 is-text-regular is-text-black50">在電話忙線時，將來電轉接至指定的號碼。可透過下面方式，輸入數字或符號後，再按撥出鍵，來設定或取消此功能。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item d-flex flex-column MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3 h-100">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">**67* + 區域碼 + 電話號碼#</h2>
                        </div>
                      </div>
                      <div class="MuiGrid-root MuiGrid-item d-flex flex-column MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">取消</h4>
                        <div class="is-bg-lightgray70 px-3 py-3 h-100">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">##67#</h2>
                        </div>
                      </div>
                    </div>
                    </div>
                  `}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">無法接通轉接</h5>'
                content={`
                  <h6 class="mb-4 mt-0 is-text-regular is-text-black50">在手機未響之前，將來電轉接至指定號碼。可透過下面方式，輸入數字或符號後，再按撥出鍵，來設定或取消此功能。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item d-flex flex-column MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3 h-100">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">**62* + 區域碼 + 電話號碼#</h2>
                        </div>
                      </div>
                      <div class="MuiGrid-root MuiGrid-item d-flex flex-column MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">取消</h4>
                        <div class="is-bg-lightgray70 px-3 py-3 h-100">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">##62#</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  `}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">市話轉接到行動電話設定</h5>'
                content={`
                  <h6 class="mb-4 mt-0 is-text-regular is-text-black50">若您需要設定的是將市內電話轉接到遠傳或原和信行動電話，請先向市內電話業者申請市話之指定轉接功能，再依照下面方式，輸入數字或符號後，於最後按撥出鍵，來設定或取消此功能。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item d-flex flex-column MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3 h-100">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">*77 + 遠傳/和信電話號碼 + #</h2>
                        </div>
                      </div>
                      <div class="MuiGrid-root MuiGrid-item d-flex flex-column MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">取消</h4>
                        <div class="is-bg-lightgray70 px-3 py-3 h-100">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">#77#</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  `}
              />
            </div>
          </div>
        </div>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
          <ol>
            <li>此服務供遠傳月租型用戶使用，企業方案用戶不適用。</li>
            <li>這項特殊功能可將行動電話來電指定轉接至國內任何其它電話號碼，來電者不會聽到任何訊息說明電話正被轉接如轉接電話被接聽，設定轉接者，需付轉接段的通話費。然而，啟用此功能則語音信箱功能會失效，意即，來電不會再自動被轉接至語音信箱。</li>
            <li>如所輸入的電話無效，則系統留言會說明無法接通。</li>
          </ol>
          `}
        />
      </main>
    );
  }
}
export default Transfer;