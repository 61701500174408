import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../Link';
import PropTypes from 'prop-types';

const Banner_m = (props) => {
  return (
    <section id={props.id} className={`fui-section-promo bg-pattern-${props.patternColor}`}>
      <div className='fui-container'>
        <Grid container justify='center'>
          <Grid item xs={12} sm={12} md={6} className='content'>
            <h4 className='fui-section-promo-title' dangerouslySetInnerHTML={{ __html: props.title }}></h4>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='action'>
            {props.action.map((act, i) => (
              <Link
                key={`ad-action-${i}`}
                to={act.link || '#'}
                target={act.target}
                data-fet-key={act.fetKey}
                className={`fui-button ${
                  act.btnStyle === 'primary' ? 'is-primary' : 'is-secondary'
                } is-medium is-reverse`}>
                <span className='text'>{act.text}</span>
              </Link>
            ))}
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

Banner_m.propTypes = {
  patternColor: PropTypes.string, // only 'blue' or 'gray'
  title: PropTypes.string,
  id: PropTypes.string,
  action: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
      btnStyle: PropTypes.string, // only 'primary' or 'secondary
    })
  ),
};

export default Banner_m;
