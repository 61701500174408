import React, { Component } from 'react'
import LoadMore from '../LoadMore'
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
class CardMultiSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  getColumns = () => {
    let length = this.props.list.length;
    if (length < 5) {
      return 12 / length
    } else {
      return 3
    }
  }
  loadMore = () => {
    // call API 取得更多影片，透過 props 傳回新的 card
    if (this.props.loadMore) this.props.loadMore(this.state.currentPage);
  };
  render() {
    return (
      <section className='promotion-card card-multi-step pb-md-10 pb-6 pt-1' style={!!this.props.bg ? { backgroundImage: `url(${this.props.bg})` } : null}>
        <div className='fui-container mt-md-4 mt-0 pt-2 '>
          <h2 className="mt-0 mb-8">{this.props.title}</h2>
          <Grid container spacing={2}>
            {this.props.list.map((item, i) => {
              return (
                <Grid key={item.name + i} className="mb-md-6 mb-3" item xs={12} sm={12} md={6} lg={this.getColumns()}>
                  <div style={{ backgroundImage: `url(${item.bg})` }} className='fui-card is-card-program d-flex flex-column pt-md-6 pt-4 pb-md-4 pb-0 px-md-4 px-2 is-bg-white align-left'>
                    <div className="circle">{i + 1}</div>
                    {
                      item.name ? 
                      <div>
                        <h3 className="m-0">{item.name}</h3>
                        <p className="is-text-darkgray50 mt-md-2 mt-1 body" dangerouslySetInnerHTML={{ __html: item.content }}></p>
                      </div>
                      : 
                      <p className="is-text-darkgray50 mt-0 body" dangerouslySetInnerHTML={{ __html: item.content }}></p>
                    }
                  </div>
                </Grid>
              )
            })}
          </Grid>
          {this.props.hasMore ? <LoadMore
            moreLabel="展開看更多"
            className="mt-2 mb-0"
            noMoreLabel="已經沒有更多內容囉！"
            click={() => this.props.loadMore()}
            load={this.props.hasMore}
          /> : null}
        </div>
      </section>
    )
  }
}

CardMultiSteps.propTypes = {
  title: PropTypes.string,
  hasMore: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string,
    })
  ),
  bg: PropTypes.string,
  loadMore: PropTypes.func
}
export default CardMultiSteps;