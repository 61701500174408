import React, { Component } from 'react'
import Button from '../../components/Button';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import CollapsePaper from '../../components/collapse/CollapsePaper';
import Link from '../../components/Link';
import { Grid } from '@material-ui/core';

class ContractRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '0926-899-868',
      plan: '4G新絕配199',
      currentTable: [
        {
          title: '月租費',
          content: '199元'
        },
        {
          title: '國內數據傳輸量',
          content: '4.5GB'
        },
        {
          title: '超過數據傳輸量',
          content: '調降上網速率至128Kbps，調降後上網用量不計費'
        },
        {
          title: '不分網內外，內含語音分鐘數',
          content: '170'
        },
        {
          title: '網內簡訊',
          content: '400則'
        },
        {
          title: '網內語音(元/秒)',
          content: '0.07'
        },
        {
          title: '網外語音(元/秒)',
          content: '0.07'
        },
        {
          title: '市話語音(元/秒)',
          content: '0.105'
        },
      ],
    }
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="目前費率查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className="">
          <div className="fui-container">
            <div className="fui-segments">
              <div className="paper mb-0">
                <div className="d-flex justify-between flex-align-center mt-md-4 mt-md-2 mt-0 mb-2">
                  <h4 className='m-0'>您的費率資訊</h4>
                  <Link className='fui-button is-secondary is-tiny mb-0' to="#">
                    <span className='text'>合約到期日查詢</span>
                  </Link>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <div className="is-bg-lightgray70 px-3 py-3">
                      <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                        我的門號
                  </h5>
                      <h2 className='mb-0'>{this.state.phoneNumber}</h2>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="is-bg-lightgray70 px-3 py-3">
                      <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                        費率方案
                  </h5>
                      <h2 className='mb-0'>{this.state.plan}</h2>
                    </div>
                  </Grid>
                </Grid>
                <div className="page-action">
                  <Button link="#" className='mb-md-0' btnStyle='primary' size='large'>立即續約</Button>
                  <Button link="#" className='mb-md-0' btnStyle="secondary" size='large'>推薦費率查詢</Button>
                </div>
                <p className="mt-md-3 mt-2 mb-md-3 mb-0">
                  您已符合續約方案，<a href="#" className="info-link">立即續約</a> 查看專屬優惠方案
                </p>
              </div>
              <CollapsePaper
                title="我的費率內容說明"
                className="collapse-table"
                openCollapse={e => { console.log(e) }}
                open={false}
              >
                <table className="text-wrap odd table-fixed mt-md-0 mt-3 pb-4 d-block">
                  <tbody>
                    {this.state.currentTable.map((row, i) => {
                      return (
                        <tr key={row.title + i}>
                          <td className="align-center is-bg-white is-text-medium">{row.title}</td>
                          <td className="align-left  pl-md-5 pl-2">{row.content}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </CollapsePaper>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default ContractRate;