import React from 'react';

import Button from '../../components/Button';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import FooterAd from '../../components/ad/FooterAd';
import CardFeature from '../../components/partials/card/CardFeature';
import AppTrail from '../../components/partials/AppTrail';
import ExclusiveBanner from '../../components/partials/banner/ExclusiveBanner';
import SectionCarousel3 from '../../components/partials/card/SectionCarousel3';
import PlanTable from '../../components/partials/PlanTable';
import SectionFaqTab from '../../components/partials/collapse/SectionFaqTab';
import NavContentTab2 from '../../components/partials/NavContentTab2';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Modal from 'react-modal';
import axios from 'axios';

import * as Mock from '../../mock/Exclusive';
let PlanMock = null

class Exclusive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      currentModal: null,
      query: '',
      defaultTab: 0,
      navTabs: [],
      selectedPlan: {
        banner: {
          image: { md: '', sm: '' }, title: '', description: '', actions: [{ text: '', link: '' }]
        },
        promotionCard: { title: '', cards: [] },
        parentPromotion: { image: { md: '', sm: '' }, title: '', description: '', content: '', actions: { ios: { image: { md: '', sm: '' } }, android: { image: { md: '', sm: '' } } } }
      },
      planTabs: { tabs: { name: '', list: [] }, tabContent: [] }
    }

    this.getExclusiveData().then(result => {
      PlanMock = result;
      this.setExclusiveData();
    });

  }

  getExclusiveData = () => {
    return axios('/json/exclusive.json').then(result => {
      return JSON.parse(JSON.stringify(result.data));
    })
  }

  componentDidUpdate() {
    if (this.props.match.params.plan !== this.state.query) {
      this.setExclusiveData();
    }
  }

  openModal = (content) => {
    this.setState({
      currentModal: content,
      modalOpen: true
    })
  }

  setExclusiveData = () => {
    let planTab = [];
    let activePlan = 0;
    let activeTab = 0;
    let activeNum = 0;
    let activeNav = this.props.match.params.plan ? PlanMock.navTab.find((tab, i) => {
      if (this.props.location.pathname === tab.link) {
        activeNum = i
        return tab;
      }
    }) : PlanMock.navTab[0];

    PlanMock.plan.forEach((plan, i) => {
      if (this.props.match.params.plan === plan.title) {
        activePlan = i
      }
      // BannerTab
      // PlanTab
      PlanMock.planTabs.tabs.forEach((tab, k) => {
        if (!planTab[k]) planTab[k] = [];
        tab.child.forEach(ch => {
          if (activeNav.name === ch.name) {
            activeTab = k
          }
          if (ch.name === plan.name) {
            planTab[k].push(plan)
          }
        })
      });
    })

    this.setState({
      query: this.props.match.params.plan || '',
      defaultTab: activeNum,
      navTabs: PlanMock.navTab,
      selectedPlan: PlanMock.plan[activePlan],
      planTabs: {
        title: PlanMock.planTabs.title,
        tabs: {
          scroll: true,
          list: PlanMock.planTabs.tabs.map(tab => { return { label: tab.name } }),
          default: activeTab
        },
        tabContent: planTab
      }
    })

    this.forceUpdate()
  }

  NavTabChange = (index) => {
    this.props.history.push({
      pathname: this.state.navTabs[index].link
    })
  }

  render() {
    // const {Mock} = this.state;
    return !PlanMock ? null : (
      <main>
        <NavAnchor {...Mock.navBar} />
        <Breadcrumb breadcrumb={Mock.breadcrumb} />
        <ExclusiveBanner slides={[{ ...this.state.selectedPlan.banner }]} openModal={this.openModal} />
        <NavContentTab2
          className='with-scrollbar'
          tabs={{ name: 'exclusive-tab', list: this.state.navTabs, default: this.state.defaultTab }}
          onChange={this.NavTabChange}
        />
        <div className="ribbon-bg">
          <CardFeature {...this.state.selectedPlan.promotionCard} />
          {
            this.state.selectedPlan.parentPromotion ? (
              <AppTrail {...this.state.selectedPlan.parentPromotion} openModal={this.openModal} />
            ) : null
          }
          <PlanTable {...this.state.planTabs} selected={this.state.selectedPlan} />
          <SectionCollapseInfo {...this.state.selectedPlan.note} />
          <SectionCarousel3 {...PlanMock.moreCards} />
        </div>
        <SectionFaqTab {...Mock.faq} />
        <FooterAd {...Mock.footerPromotion} />
        <Modal
          onAfterOpen={e => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={e => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel="Example Modal"
          className="fui-app-trial-modal">
          {this.state.currentModal ? (
            this.state.currentModal.type === 'app-trial' ? (
              <div className="fui-app-trial-body">
                <img src={this.state.currentModal.md} className='d-none d-md-block' alt="" />
                <img src={this.state.currentModal.sm} className='d-block d-md-none' alt="" />
                <div className="fui-buttons two-button">
                  <Button btnStyle='primary' link={this.state.currentModal.link} target={this.state.currentModal.target}>詳細內容</Button>
                  <Button btnStyle='secondary' onClick={e => this.setState({ modalOpen: false })}>關閉</Button>
                </div>
              </div>
            ) : (
                <div className="gamer_center">
                  <div className="content" dangerouslySetInnerHTML={{ __html: this.state.currentModal.content }}>
                  </div>
                  <div className="fui-buttons two-button">
                    <Button btnStyle='secondary' onClick={e => this.setState({ modalOpen: false })}>關閉</Button>
                  </div>
                </div>
              )) : null
          }

        </Modal>
      </main>
    )
  }
}


export default Exclusive;