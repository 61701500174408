import React, { Component } from 'react'

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import AdaptHeightBanner from '../../components/partials/banner/AdaptHeightBanner';
import PanelTable from '../../components/panelContent/PanelTable';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import NavContentTab2 from '../../components/partials/NavContentTab2';
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import SectionAd3 from '../../components/partials/SectionAd3';
import Panel from '../../components/panel/Panel';
import PanelTab from '../../components/panelContent/PanelTab';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import PanelInformation from '../../components/panelContent/PanelInformation';
import Link from '../../components/Link';
import * as Mock from '../../mock/AddValue.js';
class Program extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    changeMainTab = value => {
        this.setState({
            currentMainTab: value,
        });
        return value === 0 ? this.props.history.push('/cbu/5g') : null
    };
    render() {
        return (
            <main className="prepaid ribbon-bg-wrapper">
                <NavAnchor
                    pageTitle='預付卡'
                    button={{
                        text: '立即儲值',
                        link: '#'
                    }} />
                <Breadcrumb
                    breadcrumb={[
                        { text: '首頁', link: '/' },
                        { text: '預付卡', link: '/' },
                        { text: '計量型上網方案說明', link: '/' },
                    ]}
                    color='white'
                />
                <AdaptHeightBanner
                    height={{
                        desktop: 360,
                        mobile: 180
                    }}
                    bgImg={{
                        md: '/resources/cbu/prepaid/images/cbu-prepaid-banner-1920x360.jpg',
                        sm: '/resources/cbu/prepaid/images/cbu-prepaid-banner-750x360.jpg',
                    }}
                    title='計量型上網方案說明' />
                <Panel className="is-bg-lightgray70">
                    <div className="fui-container">
                        <h2>計量型上網方案</h2>
                        <div className='paper'>
                            <h4 className="mt-3 is-text-medium mb-3">方案總覽</h4>
                            <p>依據你的上網習慣，選擇不同流量的方案，所有方案皆能自動無限延展！</p>
                            <PanelTable
                                content={`<div class="compareTable content-16 clearfix">
                                    <div class="tableLeft">
                                        <div class="tableHead">
                                            <table>
                                                <tbody>
                                                    <tr style="height: 66px;">
                                                        <th></th>
                                                    </tr>
                                                    <tr style="height: 66px;">
                                                        <th>加總網路流量</th>
                                                    </tr>
                                                    <tr style="height: 66px;">
                                                        <th>基本網路流量</th>
                                                    </tr>
                                                    <tr style="height: 66px;">
                                                        <th>贈送網路流量</th>
                                                    </tr>
                                                    <tr style="height: 66px;">
                                                        <th>使用期限</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tableRight">
                                        <div class="tableBody">
                                            <div class="spaceBox">
                                                <table class="compareList">
                                                    <tbody>
                                                        <tr style="height: 66px;">
                                                        <td>羽量版</td>
                                                        <td>輕量版</td>
                                                        <td>基本版</td>
                                                        <td>重量版</td>
                                                        <td>巨量版</td>
                                                        </tr>
                                                        <tr style="height: 66px;">
                                                        <td>2 GB <span style="text-decoration:line-through">1.2 GB</span></td>
                                                        <td>4 GB <span style="text-decoration:line-through">2.2 GB</span></td>
                                                        <td>10 GB <span style="text-decoration:line-through">5 GB</span></td>
                                                        <td>16 GB <span style="text-decoration:line-through">8 GB</span></td>
                                                        <td>20 GB</td>
                                                        </tr>
                                                        <tr style="height: 66px;">
                                                        <td>180 MB</td>
                                                        <td>300 MB</td>
                                                        <td>699 MB</td>
                                                        <td>4096 MB</td>
                                                        <td>5 GB</td>
                                                        </tr>
                                                        <tr style="height: 66px;">
                                                        <td>1048.8 MB</td>
                                                        <td>1966.8 MB</td>
                                                        <td>4421 MB</td>
                                                        <td>4096 MB</td>
                                                        <td>15 GB</td>
                                                        </tr>
                                                        <tr style="height: 66px;">
                                                        <td>60日</td>
                                                        <td>60日</td>
                                                        <td>98日</td>
                                                        <td>185日</td>
                                                        <td>120日</td>
                                                        </tr>
                                                    </tbody>
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </div>`
                                }
                            />
                        </div>
                        <h2>遠傳首創制度，自動延展不浪費</h2>
                        <div className='paper mb-5'>
                            <h4 className="mt-3 is-text-medium mb-3">預付卡上網傳輸量無限遞延</h4>
                            <p>「遠傳預付飆網計量型方案」的購買上網傳輸量，可於門號有效期內自動無限展延，不浪費您的上網額度。</p>
                            <div className="align-center w-100">
                                <img className="w-100" src={process.env.PUBLIC_URL + "/resources/cbu/prepaid/images/plan-storage-infography.png"} alt="plan-storage-infography" />
                            </div>
                        </div>
                        <Link to={'#'} className='fui-button is-text pr-0'>
                            <i className='icon-chevron-left mr-1'></i>
                            <span>回到預付卡儲值</span>
                        </Link>
                    </div>
                </Panel>
                <SectionAd3 {...{
                    patternColor: 'green',
                    title: `透過遠傳官網儲值，立即享有網路限定優惠`,
                    action: [{
                        text: '立即儲值',
                        line: '#',
                    }]
                }} />
                <div className="fui-container">
                    <PanelInformation title={'備註事項'} content={'備註事項'} />
                </div>
            </main>
        );
    }
}
export default Program;