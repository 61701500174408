import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

// import MenuAutocomplete from '../../components/partials/MenuAutocomplete';
import LifeCircleCarousel from '../../components/partials/card/LifeCircleCarousel';
import CardRoamingMulti from '../../components/partials/CardRoamingMulti';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import SectionAd3 from '../../components/partials/SectionAd3';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import * as Mock from '../../mock/OriginNumberRoaming';

class Aircraft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bgImg: {
        md: '/resources/cbu/roaming/cbu-Roamingair-banner-1920x360.jpg',
        sm: '/resources/cbu/roaming/roaming_aircraft-_710x400.jpg',
      },
      keyword: '',
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        this.setState({
          isMobile: window.innerWidth < 768,
        });
      });
  }

  render() {
    return (
      <main className="">
        <NavAnchor
          pageTitle='<a href="#">原號漫遊</a>'
          button={{
            link: '#',
            text: '聯絡我們',
          }}
          // tabs={[
          //   { label: '主題活動', link: '#anchor1' },
          //   { label: '達人帶你玩', link: '#anchor2' },
          //   { label: '漫遊優惠', link: '#anchor3' },
          //   { label: '其他漫遊', link: '#anchor4' },
          // ]}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '原號漫遊', link: '/' },
            { text: '機上漫遊', link: '/' }
          ]}
          color='white'
        />


        <SectionBanner2 
          className="is-origin-number-roaming-country-banner is-360"
          image={{
            md: '/resources/cbu/roaming/cbu-Roamingair-banner-1920x360.jpg',
            sm: '/resources/cbu/roaming/cbu-roamingair-750x640.jpg'
          }}
          tag=""
          subTitle='Aircraft'
          title='機上漫遊'
          description=''
          actions=''
        />

      
        
        <section className="origin-number-roaming aircraft with-bg pb-0">
          <CardRoamingMulti {...Mock.roamingCardAircraft} />

          <SectionCarousel1 {...Mock.promotionCard} 
            className='pb-0'  
          />

          <LifeCircleCarousel {...Mock.hotLocation} />

          <SectionAd3
            patternColor='green'
            title={`不知道原號漫遊怎麼用？別擔心，漫遊小叮嚀讓你一次就上手！`}
            action={[
              {
                text: '了解更多',
                line: '#',
              },
            ]}
          />
          
          
          <SectionCollapseInfo
            title="備註說明"
            content={`
              1. 上述漫遊費用除非特別註明計價單位，其餘皆已換算為「每分鐘 / 新台幣元」表示，因匯率時有變動，收費時按雙方網路經營者實際交易日之匯率換算計收，上述費用僅供參考。<br/>
              2. 本公司依國外業者傳送之通信資料，透過簡訊提供上網用量及費用等相關通知，僅供參考，傳送過程可能因國外業者系統狀況、客戶終端無簡訊收送功能或關機等因素，而發生延遲、無法送達或超過簡訊發送時限而傳送失效等情形，於漫遊期間實際產生之用量及費用，仍以帳單為準。<br/>
              3. 若您出國前已開啟門號的數據漫遊服務，於國外漫遊時，僅在手機上自行關閉行動數據或數據漫遊服務功能，仍可能因手機本身資料自動更新等因素，而有些微傳輸量，產生數據漫遊費用。<br/>
              4. 使用影像電話視漫遊業者網路系統支援程度而決定。<br/>
              5. 漫遊區域與資費表之減價時段為漫遊當地之時間, 而非台灣時間。
            `}
          />
        </section>
      </main>
    );
  }
}

export default Aircraft;
