import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import CheckGroup from '../../components/form/CheckGroup';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Modal from 'react-modal';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.form = React.createRef();
    this.state = {
      type: 'success',
      description: `<h4 class="mb-1">申請完成</h4><p class="mt-0 mb-4">您已完成申請！ <span class="is-text-accent">10分鐘 </span>後請將手機重開機使用。提醒您需於三天內繳費，以免外撥功能再次限制，謝謝</p>`,
      promotion: {
        title: '你的專屬優惠',
        cards: [
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
            title: '本月影片免費看!',
            description: '話題電影、日劇、韓劇等等應有盡有',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
            title: '中午不知道吃什麼？',
            description: 'Uber Eats 送遠傳新客優惠金200元',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
            title: '連假打算出國？',
            description: '遠遊卡吃到飽上網不再煩惱用量 ',
            link: '#',
          },
          {
            image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
            title: '本月影片免費看!',
            description: '話題電影、日劇、韓劇等等應有盡有',
            link: '#',
          },
        ]
      },
    }
  }


  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />

        <section className="e-service-result is-success mt-4">
          <div className="fui-container">
            <Grid container>
              <Grid item xs={12}>
                {
                  this.state.type !== 'fail' ? (
                    <img src={'/resources/cbu/e-service/images/completed.png'} srcSet={'/resources/cbu/e-service/images/completed@2x.png'} alt='' />
                  ) : (
                      <img src={'/resources/cbu/e-service/images/failed.png'} srcSet={'/resources/cbu/e-service/images/failed@2x.png'} alt='' />
                    )
                }

                <div className="content">
                  <h4 className="mb-0 mt-3">{this.state.title || ''}</h4>
                  <p className='body1' dangerouslySetInnerHTML={{
                    __html: this.state.description || ''
                  }}></p>
                </div>
              </Grid>
            </Grid>
            <div className='fui-buttons two-buttons mt-0'>
              <Button className='d-block d-md-inline-block' link="#" btnStyle='primary' size='large'>返回恢復通話</Button>
              <Button className='d-block d-md-inline-block' link="#" btnStyle="secondary" size='large'>返回個人專區</Button>
            </div>


          </div>
        </section>
        <SectionCarousel1 {...this.state.promotion} />
      </main >
    )
  }
}

export default Success;