import React, { Component } from 'react'
class Marker extends Component {
  constructor(props) {
    super(props);
    // console.log(`props`, props);
    this.state = {
      active: false
    }
  }
  clickItem = () => {
    // console.log('click!');
    this.setState({
      active: !this.state.active
    })
  }
  render() {
    return (
      <div className={`marker ${this.props.className}`} onClick={this.props.onClick}></div>
    );
  }
}

export default Marker;