import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';
import Button from '../../components/Button';
import Tooltip from '../../components/Tooltip';

class RoamingCard extends React.Component {

  triggerModal = () => {
    this.props.openModal(this.props.modal);
  };

  renderAircraft = () => {
    return (<React.Fragment>適用航班：<a href="https://www.aeromobile.net/airlines/" rel="noopener noreferrer" target="_blank">請至AeroMobile官網查詢</a></React.Fragment>)
  }

  render() {
    return (
      <div className='fui-card'>
        {
          this.props.tag ?
            <div className={`fui-tag fui-tag-${this.props.tag.color}`}>{this.props.tag.text}</div>
            : null
        }
        <div className='fui-card-image'>
          <img className='lazyload' data-src={process.env.PUBLIC_URL + (this.props.image || this.props.defaultImg)} src={process.env.PUBLIC_URL + (this.props.image || this.props.defaultImg)} alt={this.props.imgAlt || "圖片"} />
        </div>
        <div className='fui-card-caption'>
          <h3 className="title">
            {this.props.title}
          </h3>
          <div className='desc' dangerouslySetInnerHTML={{ __html: this.props.desc }}></div>
          {
            this.props.target ?
              <div className='is-text-medium body-2 target'><img src="/resources/cbu/e-service/images/group.svg" className="align-middle" alt="" /><span>{'適用對象：' + this.props.target}</span></div>
              : null
          }
          {
            this.props.country ?
              <div className='is-text-medium body-2 country'>
                <img src="/resources/cbu/e-service/images/location.svg" className="align-middle" alt="" />
                <span>{this.props.isAircraft ? this.renderAircraft() : "適用國家：" + this.props.country}</span>
                {
                  this.props.tips ?
                    <Tooltip
                      parentNode={null}
                      content={<img src="/resources/cbu/help-center/images/information-gray-24.svg" />}
                      trigger="click"
                      tooltip={this.props.tips}
                    />
                    : null
                }
              </div>
              : null
          }
          {
            this.props.modalText ?
              <div className='underline-link is-text-black50 is-text-medium body-2 action' onClick={this.triggerModal}>{this.props.modalText}</div>
              : null
          }
        </div>
        <div className="fui-card-action">
          <div className="d-block align-right">
            <h1 className="d-inline-block is-text-error">
              {
                this.props.amount ?
                  <small className="is-text-black50 is-text-regular">{this.props.amount}</small>
                  : null
              }
              {`$${this.props.price}`}
            </h1>
          </div>
          <Button btnStyle='primary' className='m-0 w-100' link={this.props.button.link} target={this.props.button.target}>{this.props.button.text || "立即申請"}</Button>
        </div>
      </div>
    )
  }
}

RoamingCard.propTypes = {
  image: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.string,
  desc: PropTypes.string,
  target: PropTypes.string,
  country: PropTypes.string,
  price: PropTypes.string,
  modalText: PropTypes.string,
  modal: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.any,
  }),
  tag: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
  }),
  button: PropTypes.PropTypes.shape({
    text: PropTypes.string,
    target: PropTypes.string,
    link: PropTypes.string,
  }),
  openModal: PropTypes.func,
}
export default RoamingCard;