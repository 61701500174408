import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

class EstorePromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth < 768
    }
  }

  componentDidMount() {
    window.addEventListener('resize', e => {
      this.setState({
        isMobile: window.innerWidth < 768
      })
    })
  }

  render() {
    return (
      <div className="fui-estore-promotion" style={{
        backgroundImage: `url(${this.state.isMobile ? this.props.image.sm : this.props.image.md })`
      }}>
        <div className='promotion-container'>
          <div>{this.props.subtitle}</div>
          <h2>{this.props.title}</h2>
          <Button link={this.props.action.link} target={this.props.action.target} btnStyle='secondary' reverse={true}>
            {this.props.action.text}
          </Button>
        </div>
      </div>
    )
  }
}

EstorePromotion.propTypes = {
  image: {
    md: PropTypes.string,
    sm: PropTypes.string,
  },
  subtitle: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    target: PropTypes.string,
  }),
}

export default EstorePromotion;