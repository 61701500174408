import React from "react";
import Slider from "react-slick";
import { Grid } from "@material-ui/core";
import Button from "../../Button";
import Link from "@ui/Link";
import PropTypes from "prop-types";
import { setDotBtns } from "../../../utils/slider-tracking";
import DotsStyleLoader from "@src/components/contentLoader/dots";

class LifeCircleIndexAdCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== "undefined" && window.innerWidth < 960,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (!this.props["tracking-prefix"]) return;
    if (!this.ref || !this.ref.current) return;

    setDotBtns(this.ref.current, `${this.props["tracking-prefix"]}Belt`, `tab`);
  }

  render() {
    const settings = {
      dots: true,
      accessibility: false,
      adaptiveHeight: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      speed: 500,
      arrows: false,
      autoplaySpeed: 5000,
      cssEase: "linear",
      customPaging: (i) => <button key={i}></button>,
    };

    return !this.props.ready ? (
      <div className="ad-carousel">
        <DotsStyleLoader height={320} />
      </div>
    ) : typeof this.props.slider === "undefined" ? (
      ""
    ) : (
      <div ref={this.ref} className="ad-carousel">
        <Slider {...settings}>
          {this.props.slider.map((slide, index) => {
            return (
              <div key={slide.title} className="slide">
                <Grid container className="h-100">
                  <Grid item xs={12} md={4}>
                    <div className="img h-100 d-none d-md-block">
                      <img src={slide.image} alt={slide.title} />
                    </div>
                    <div className="img h-100 d-md-none d-block">
                      <Link
                        to={slide.action.link}
                        data-fet-key={`MC_Home_Belt${index + 1}_${slide.title}`}
                      >
                        <img src={slide.image} alt={slide.title} />
                      </Link>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <div className="wording d-none d-md-block">
                      <div className="position-relative">
                        <h3 className="is-text-white mt-0 mb-1">
                          {slide.title}
                        </h3>
                      </div>
                      <div>
                        <Button
                          link={slide.action.link}
                          btnStyle="secondary"
                          className="is-reverse"
                          data-fet-key={`MC_Home_Belt${index + 1}_${
                            slide.title
                          }`}
                          data-recommend-tracking={slide.recommendData}
                        >
                          {slide.action.text}
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

LifeCircleIndexAdCarousel.propTypes = {
  slider: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      action: PropTypes.shape({
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }),
      "tracking-prefix": PropTypes.string,
    })
  ),
};

export default LifeCircleIndexAdCarousel;
