import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import Button from '../../components/Button';
import CheckGroup from '../../components/form/CheckGroup';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import ZipcodeBanner from '../../components/partials/banner/ZipcodeBanner';
import Breadcrumb from '../../components/Breadcrumb';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

class MissingCall extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.form = React.createRef();
    this.state = {
      isMember: false,
      step: 1,
      mergable: false,
      canSubmit: false,
      modalOpen: false,
      authCode: '',
      phoneNumber: '0926-899-868',
      account: '859909700',
      form: {
        account: { value: '', required: false },
      },
      mergeNumber: [
        { value: '0', label: '0916-000-995' },
        { value: '1', label: '0928-789-789' },
        { value: '2', label: '0912-477-477' },
        { value: '3', label: '0988-654-654' },
        { value: '4', label: '0988–092-000' },
        { value: '5', label: '0936-282-525' },
        { value: '6', label: '0936-282-525' },
        { value: '7', label: '03-1234567' }
      ],
      linkNumber: [
        {
          type: 'cellphone',
          number: '0936-123-995'
        },
        {
          type: 'cellphone',
          number: '0931-456-995'
        },
        {
          type: 'landline',
          number: '02-1234567'
        },
        {
          type: 'landline',
          number: '02-8887777'
        },
      ]
    }
  }

  onChange = (name, value) => {
    // console.log(`name, value: `, name, value);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }

  handleChange = () => {
    this.setState({
      authCode: this.input.current.value,
    });
  }

  submitAuthCode = () => {
    // console.log(`this.authCode: `, this.input.current.value);
    this.setState({
      modalOpen: false,
      step: 2
    })
  }
  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    this.setState({ canSubmit: false });
  }
  submit = model => {
    // console.log('submitted');
  };
  render() {
    return (
      <main className='prepaid'>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <Breadcrumb
          color="white"
          breadcrumb={[
            { link: '/ebu', text: '個人首頁' },
            { link: '', text: '個人專區' },
            { link: '', text: '指定轉接說明' },
          ]}
        />
        <SectionBanner2
          position='right'
          className="is-360"
          image={{
            md: '/resources/cbu/img/cbu-eservice-miss-call-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-eservice-miss-call-750x400.jpg',
          }}
          title='<div class="mt-5 is-text-white">未接提醒</div>'
          action={null}
        />
        <section className="query-index ribbon-bg">
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mb-md-4 mb-6 pb-md-2 pb-0">當您手機關機或無訊號時，遠傳會先幫您記錄這段時間未接來電的相關訊息，當您重新啟動或連線時，將以簡訊通知您。</h4>
            <div className="paper mt-md-4 mt-2 mb-md-2 mb-0">
              <div className="d-flex justify-between flex-align-center mt-md-4 mt-md-2 mt-0 mb-2">
                <h4 className='m-0'>未接提醒須知</h4>
              </div>
              <ul className="green-dot-list mb-md-4 mb-3">
                <li>費用：免月租費，當用戶使用話中插接功能，接聽第三方電話，發話方雖為通話保留狀態，系統仍持續計費。</li>
              </ul>
              <Grid container spacing={2}>
                <Grid className='d-none d-md-block' item xs={4} />
                <Grid item xs={12} sm={4}>
                  <figure className="fui-figure">
                    <img src="/resources/cbu/img/ios-miss-call@2x.jpg" alt="missing-call" />
                    <figcaption className='mb-md-4 mb-0'>當您手機關機或無訊號時，幫您記錄這段時間未接來電的相關訊息，並以簡訊通知您。</figcaption>
                  </figure>
                </Grid>
              </Grid>
            </div>
          </div>
        </section>


        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
          <p class="m-0">下列狀況，用戶將不會收到「未接提醒」服務的簡訊告知。</p>
          <ol>
            <li>來電者已在語音信箱留言。</li>
            <li>來電者設定隱藏發話號碼。</li>
          </ol>
          `}
        />
      </main >
    )
  }
}

export default MissingCall;