import React, { Component } from 'react'
import Tab from '../tab/Tab';
import Link from '../Link';
import HasMoreTab from '../tab/HasMoreTab';
import TabPane from '../tab/TabPane';
import PropTypes from 'prop-types';

class SectionCard2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      tabs: [],
      dropdown: []
    }
  }
  tabChange = (index) => {
    this.setState({
      currentTab: index
    })
  }
  HasMoreTabsChange = (val) => {
    this.setState({
      currentTab: val.index
    })
  }
  componentDidMount() {
    this.generateTab();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.tabs) !== 
        JSON.stringify(this.props.tabs)) {
          this.generateTab();
        }
  }


  generateTab() {
    let tabs = this.props.tabs
    let newtabs = []
    let dropdown = []
    if (tabs.length > 5) {
      for (let i = 0; i < 5; i++) {
        newtabs.push(tabs[i])
      }
      for (let j = 5; j < tabs.length; j++) {
        dropdown.push(tabs[j])
      }
      this.setState({
        tabs: newtabs,
        dropdown: dropdown
      })
    } else {
      this.setState({
        tabs: this.props.tabs
      })
    }
  }

  render() {
    return (
      <section className="section-card-2">
        <div className="fui-container mt-4">
          <div dangerouslySetInnerHTML={{
              __html: this.props.title
            }}></div>
          {!!!this.state.dropdown ? <Tab
            name="fui-faq-collapse-tab"
            list={this.props.tabs}
            onChange={this.tabChange}
          /> :
            <HasMoreTab
              tabs={this.state.tabs}
              dropdown={this.state.dropdown}
              onChange={this.HasMoreTabsChange}
              defaultSelected={this.state.tabs[0]}
            />}
          {
            this.props.list.map((list, i) => (
              <TabPane active={this.state.currentTab === i} key={`collapseList-${i}`}>
                {list.map((card, idx) => (
                  <div className='fui-card' key={idx}>
                    <Link
                      to={card.link.url}
                      target={card.link.target}
                      data-fet-key={card.link.fetKey || null}>
                      <div className="fui-card-action" style={{ cursor: 'pointer' }}>
                        <div className="fui-card-caption">
                          <div className="fui-card-content">
                            <small className="is-text-darkgray50">
                              {card.meta}
                            </small>
                            <h4>{card.title}</h4>
                            <div className="body" dangerouslySetInnerHTML={{
                              __html: card.content
                            }}></div>
                            <small className="mb-3 is-text-error">{card.tips}</small>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </TabPane>
            ))
          }
        </div>
      </section>
    );
  }
}
SectionCard2.propTypes = {
  list: PropTypes.array,
  tabs: PropTypes.array
}

export default SectionCard2;