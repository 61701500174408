import React from 'react';
import DropdownTab from '../tab/DropdownTab';
import Button from '../Button';
import PropTypes from 'prop-types';

class NavTab extends React.Component {
  constructor(props) {
    super(props);
    this.elem = React.createRef();
    this.state = {
      defaultTop: 52,
      scrollPos: 0,
      default: this.props.default || 0,
    };
  }

  componentDidMount() {
    let header = document.getElementsByTagName('header');
    this.setState({
      defaultTop: header.length ? header[0].clientHeight : 0,
    });

    this.props.tabs.list.map((item, i) => {
      if (item.link === window.location.pathname) {
        this.setState({
          default: i,
        });
      }
    });

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', (e) => {
        if (!this.elem.current) return;
        this.setState({
          defaultTop: header.clientHeight,
        });
      });

      window.addEventListener('scroll', (e) => {
        if (!this.elem.current) return;

        let headerDom = Array.from(document.getElementsByTagName('header'));
        let defaultTop = headerDom.length ? headerDom[0].clientHeight : 0;
        let scrollY = window.scrollY || document.documentElement.scrollTop;
        let header = headerDom[0].clientHeight;

        if (scrollY > this.state.scrollPos && scrollY > 0) {
          headerDom.map((dom) => {
            return (dom.style.transform = `translateY(${-scrollY < -header ? -header : -scrollY}px)`);
          });

          if (scrollY >= header) {
            this.elem.current.classList.add('is-fixed');
          }
        }

        if (scrollY < this.state.scrollPos && scrollY < defaultTop + header) {
          this.elem.current.classList.remove('is-fixed');
          headerDom.map((dom) => {
            return (dom.style.transform = `translateY(${-scrollY >= 0 ? 0 : -scrollY}px)`);
          });
        }

        this.setState({ scrollPos: scrollY });
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.default !== this.props.default) {
      this.setState({
        default: this.props.default || 0,
      });
    }
  };

  handleChange(value) {
    // console.log(`value: `, value);
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <div className='fui-nav-tab' ref={this.elem}>
        <div className='fui-container'>
          <div className='content'>
            <div className='subtitle' dangerouslySetInnerHTML={{ __html: this.props.pageTitle || '' }}></div>
            {this.props.tabs && this.props.tabs.list && this.props.tabs.list.length ? (
              <DropdownTab tabs={this.props.tabs} default={this.state.default} onChange={(e) => this.handleChange(e)} />
            ) : null}
          </div>
          <div className='extra'>
            {this.props.buttons && this.props.buttons.length
              ? this.props.buttons.map((button, i) => (
                <Button
                  key={`nav-tab-extra-${i}`}
                  {...button}
                  size='small'
                  data-fet-key={button.fetKey || ''}
                  btnStyle={button.btnStyle}
                  target={button.target || '_self'}>
                  {button.text}
                </Button>
              ))
              : ''}
            {!this.props.button === false && this.props.button.text ? (
              <Button
                {...this.props.button}
                data-fet-key={this.props.button.fetKey || ''}
                size='small'
                btnStyle='secondary'>
                {this.props.button.text}
              </Button>
            ) : (
                ''
              )}
          </div>
        </div>
      </div>
    );
  }
}

NavTab.propTypes = {
  showAll: PropTypes.bool,
  pageTitle: PropTypes.string,
  default: PropTypes.number,
  tabs: PropTypes.shape({
    name: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        focusIcon: PropTypes.string,
        label: PropTypes.string.isRequired,
        link: PropTypes.string,
      })
    ),
  }),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

export default NavTab;
