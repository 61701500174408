import React from 'react';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import PanelList from '../../components/panelContent/PanelList';
import LabelInput from '../../components/form/LabelInput';
import * as Validation from '../../utils/validation';
import Modal from 'react-modal';
class PUKSearch extends React.Component {
  constructor(props) {
    super(props);
    this.simcode = React.createRef();
    this.state = {
      canSubmit: false,
      modalOpen: false,
      puk1: '',
      puk2: '',
      simcodeError: false,
      form: {
        sim_number: { value: '', required: true }
      }
    }
  }
  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm,
      simcodeError: false
    });
  }

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };

  submit = () => {
    // this.setState({
    //   modalOpen: true,
    //   puk1: '12345678',
    //   puk2: '98765412'
    // })
  }
  // Server 驗證 SIM Code
  sendToServer = (model, resetForm, invalidateForm) => {
    if (model.sim_number == '1234') {
      this.setState({
        modalOpen: true,
        puk1: '12345678',
        puk2: '98765412'
      })
    } else {
      this.simcode.current.state.isValid = false;
      this.setState({
        simcodeError: true
      })
    }
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle="漫遊與門號服務"
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="PUK解卡密碼查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="form-section">
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton} onValidSubmit={this.sendToServer} ref={this.form}>
            <div className="fui-container">
              <h4 className='is-text-darkgray50'>當您連續三次輸入手機PIN碼錯誤時，SIM卡將自動上鎖，此服務提供您查詢PUK以便解除鎖卡。</h4>
              <div className="paper">
                <h4 className='my-2'>查詢須知</h4>
                <PanelList
                  prefix='number'
                  list={[
                    { text: '連續三次輸入PIN1碼錯誤，SIM卡會自動上鎖，系統會暫時中止各項服務。' },
                    { text: '連續三次輸入PIN2碼錯誤，此時與PIN2碼相關之功能將無法使用，您仍可正常收發話。' }
                  ]}
                />
              </div>
              <div className="paper mb-7">
                <h4>SIM解碼</h4>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <LabelInput
                      ref={this.simcode}
                      className="mb-2 hide-required"
                      name='sim_number'
                      required={this.state.form.sim_number.required}
                      label='SIM卡序號末四碼'
                      validationErrors={{
                        isDefaultRequiredValue: '請輸入SIM卡序號末四碼'
                      }}
                      placeholder='請輸入SIM卡序號末四碼'
                      value={this.state.form.sim_number.value}
                      onChange={this.onChange}
                    />
                    {this.state.simcodeError ? <span className="error-message" style={{ marginTop: -2, display: 'block' }}>末四碼輸入錯誤，請重新操作</span> : null}
                  </Grid>
                </Grid>
              </div>
              <div className='page-action'>
                <Button onClick={this.submit} className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>查詢PUK解卡密碼 </Button>
              </div>
            </div>

          </Formsy>
        </section>
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <Formsy
              onValid={() => { this.setState({ emailCanSubmit: true }) }}
              onInvalid={() => { this.setState({ emailCanSubmit: false }) }}
              ref={this.emailChangeForm}
            >
              <h5 className='align-center'>查詢成功</h5>
              <img
                src={'/resources/cbu/e-service/images/completed.png'}
                srcSet={'/resources/cbu/e-service/images/completed@2x.png'}
                alt=''
                style={{ width: 140 }}
              />
              <p className='mt-0 mb-0'>您的PUK1解鎖密碼為：<span className="is-text-accent is-text-medium">{this.state.puk1}</span></p>
              <p className='mt-0 mb-4'>您的PUK2解鎖密碼為：<span className="is-text-accent is-text-medium">{this.state.puk2}</span></p>
              <div className="page-action">
                <Button onClick={() => { this.setState({ modalOpen: false }) }} link="#" className={`mb-lg-0 mb-2`} btnStyle='primary'>確定</Button>
              </div>
            </Formsy>
          </div>
        </Modal>
      </main>
    )
  }
}

export default PUKSearch;