import axios from 'axios';

export const breadcrumb = [
  { link: '/help-center', text: '商務總覽' },
  { link: '/help-center/faq', text: '熱門常見問題' },
];
export const hotword = [
  '遠傳物聯網',
  '雲端運算',
  '主機代管',
  '大寬頻企業',
  'Office 365',
  '007 國際電話',
  '007 國際電話',
  '007 國際電話',
  '大寬頻企業',
];

export const menu = {
  id: 1,
  name: '常見問題分類',
  content: [
    {
      id: 11,
      name: '熱門常見問題',
      link: '/help-center/faq',
    },
    {
      id: 12,
      name: '007國際電話',
      link: '/help-center/faq/007',
    },
    {
      id: 13,
      name: '1807國內長途電話',
      link: '/help-center/faq/1807',
    },
    {
      id: 14,
      name: 'Office365',
      link: '/help-center/faq/office365',
    },
    {
      id: 15,
      name: '大寬頻ADSL',
      link: '/help-center/faq/adsl',
    },
    {
      id: 16,
      name: '大寬頻企業光纖',
      link: '/help-center/faq/web',
    },
    {
      id: 17,
      name: '主機代管',
      link: '/help-center/faq/server',
    },
    {
      id: 18,
      name: '中小企業資訊委外',
      link: '/help-center/faq/outsource',
    },
    {
      id: 19,
      name: '視訊會議雲',
      link: '/help-center/faq/cammeeting',
    },
    {
      id: 20,
      name: '雲端運算',
      link: '/help-center/faq/cloud',
    },
    {
      id: 21,
      name: '雲端電話/網路會議',
      link: '/help-center/faq/webmeeting',
    },
    {
      id: 22,
      name: '遠傳物聯網',
      link: '/help-center/faq/iot',
    },
  ],
};

export const faq = [
  {
    name: '遠傳物聯網',
    more: {
      text: '看更多',
      link: '#',
    },
    subTitle: '<p class="mb-2">遠傳電信股份有限公司(以下稱「遠傳電信」) / 新世紀資通股份有限公司(以下稱「新世紀資通」)絕對尊重用戶的隱私權，為使用戶瞭解遠傳電信 / 新世紀資通如何蒐集、應用及保護您所提供的個人或公司資訊，請詳閱下列之隱私權保護政策（PrivacyPolicy），這份文件將告訴您 ，遠傳電信 / 新世紀資通在遠傳網際網路(<a href="#" target="_blank">www.fetet.net</a>) (以下稱「FETnet」)蒐集的資料、運用方式，以及我們的隱私權保護政策。</p>',
    content: [
      {
        title: '一、使用者資料之蒐集',
        content: `
          <p>使用者申請遠傳電信 / 新世紀資通或FETnet網站上之各項服務，遠傳電信 / 新世紀資通在使用者的同意下所蒐集之個人資料係為確認使用者身份及提供有關行動加值服務之用，其範圍如下： 使用者註冊成為遠傳行動電話用戶之FETnet會員時，需提供門號、身份證字號或統一編號、暱稱、電子郵件、職業或行業別、教育程度、婚姻、個人收入或營 業額、興趣等真實個人或公司之資料。 使用者註冊成為非遠傳行動電話用戶之FETnet會員時，需提供姓名或公司名稱、身份證字號或統一編號、出生年月日、性別、使用門號公司、暱稱、電子郵 件、職業或行業別、教育程度、婚姻、個人收入或營業額、興趣等真實個人或公司之資料。</p>
          <ul class="blue-dot-list">
            <li>
              網路活動：FETnet進行網路活動或調查時，可能會請使用者提供姓名或公司名稱、身份證字號或統一編號、電話、Email及住址等。
            </li>
            <li>廣告連結服務：在FETnet刊登廣告之廠商，或與FETnet連結的網站，也可能蒐集使用者個人或公司資料。各自廣告主或連結網站，應有個別的隱私權保護政策，FETnet不負任何連帶責任。</li>
            <li>線上購物：在遠傳網際網路門市進行線上購物，使用者仍受遠傳網際網路隱私權保護。但您若連結至他網消費，則對方應有其個別的隱私權保護政策，遠傳網際網路不負任何連帶責任。</li>
          </ul>
          <p>除了上述，FETnet會保留使用者在上網瀏覽或查詢時，在系統上產生的相關記錄，包括：IP位址、使用時間、瀏覽器、瀏覽及點選記錄等。</p>
          <p>除了使用者主動登錄網站所提供的的個人資料之外，使用者如在本公司網站或服務中的聊天室，討論版等類似場域主動提供之個人資料如電子郵件，姓名等。這種形式的資料提供，不在本隱私權保護政策的範圍之內。</p>
        `,
        detailLink: '#',
      },
      {
        title: '二、使用者資料之使用',
        content: `
          <p>本公司絕不會在未經您同意之情況下，任意將您的個人資料提供他人。但以下情況，本公司得在符合本政策原則下，與第三人共用您的資料：</p>
          <ul class="blue-dot-list">
            <li>
              為了提供其他服務或優惠權益，需要與提供該服務或優惠第三者共用您的資料時，會在活動時提供充分說明，您可以自由選擇是否接受這項特定服務或優惠。
            </li>
            <li>
              您在提出申請時，已同意將您的個人資料提供給本公司之關係企業。
            </li>
            <li>
              司法單位/政府機關因公眾安全依法要求本公司提供特定個人資料時，本公司將依司法單位/政府機關合法正式的程序，以及對本公司所有使用者安全考量下做可能必要的配合。
            </li>
          </ul>
        `,
        detailLink: '#',
      },
      {
        title: '三、使用者資料之修改',
        content: `
          <p>當您在本網站中主動註冊成為會員後，您可以隨時利用您的帳號（遠傳行動電話用戶為行動電話號碼；非遠傳行動電話用戶為自設的帳號）和密碼更改您所輸入的任何個人或公司資料。但過往在本網站之服務內容記錄、交易資料等不在此更改範圍內。</p>
        `,
        detailLink: '#',
      },
      {
        title: '四、連結網頁之使用',
        content: `
          <p>本網站的網頁可能提供其他網站的網路連結，使用者可經由本網站所提供的連結，點選進入其他網站。但本公司並不保護使用者於該等連結網站中的隱私權。</p>
        `,
        detailLink: '#',
      },
      {
        title: '五、隱私權保障條款的例外',
        content: `
          <p>若使用者因犯罪嫌疑，經司法機關依法偵查時，本網站將協助配合，並提供使用者之相關資料。</p>
        `,
        detailLink: '#',
      },
      {
        title: '六、Cookie之運用',
        content: `
          <p>為了讓系統能夠辨識使用者的資料，系統將以Cookie方式記錄使用者行為。伺服器會將所需的資訊經由瀏覽器寫入使 用者的硬碟中，下次瀏覽器在要求伺服器傳回網頁時，會將Cookie的資料先傳給伺服器，伺服器可依據Cookie的資料判斷使用者，網頁伺服器可針對使 用者之不同喜好來執行不同動作或傳回特定的資訊。</p>
          <p>遠傳網際網路會在下列情況下，在您的電腦中寫入或讀取Cookies資料：</p>
          <ul class="blue-dot-list">
            <li>
              為提供個別化的服務，方便用戶使用。
            </li>
            <li>
              統計分析瀏覽模式，以改善服務。
            </li>
          </ul>
        `,
        detailLink: '#',
      },
      {
        title: '七、隱私權保障條款的修正權利',
        content: `
          <p>FETnet隱私權保護政策將因應科技發展趨勢、相關法規之修訂或其他環境變遷等因素而為適當之修改，以落實保障使用者隱私權之立意。該修改過之條款亦將立即刊登於網站上。</p>
        `,
        detailLink: '#',
      },
      {
        title: '八、資訊網路安全機制說明',
        content: `
          <p>FETnet會員登入機制採取SSO (Single Sign On) 機制，由遠傳內部資料庫及主機做會員驗證及認證動作。非會員無法使用及登入任何服務。加入會員均須填寫相關資料及簡訊授權開通動作。</p>
          <p>登入機制並整合6碼圖形驗證碼，杜絕一切機器人式登入攻擊及防駭。</p>
          <p>與客戶或交易有關的頁面均採用https加密方式進行，防制任何資料竊取行為。</p>
          <p>遠傳網路均有多層防火牆，可實質保護主機安全。</p>
          <p>當您在登入頁面勾選了「保持自動登入30天」 並且登入以後，系統會將登入資料加密儲存在您的電腦中，只要您沒有清除cookie，沒有主動選擇登出，並且使用同一台電腦，在30天內使用FETnet 網站，進入需要登入的服務頁面時，系統會自動將您的登入資料送回主機進行認證，讓您保持自動登入，方便使用各項服務。</p>
          <p>請妥善保管您的密碼及或任何個人資料，不要將任何個人資料，尤其是密碼提供給任何人。在您完成購物、取閱電子郵件等程序後，務必記得登出帳戶，若您是與他人共享電腦或使用公共電腦，切記要關閉瀏覽器視窗，以防止他人讀取您的個人資料或信件。</p>
          <p>因此若您使用公用/共用電腦，請不要勾選「保持自動登入30天」，並且記得每次離開時點選登出鍵，保護您的資訊安全。</p>
        `,
        detailLink: '#',
      },
      {
        title: '九、著作權保護措施',
        content: `
          <p>1. 遠傳電信股份有限公司</p>
          <ul class="blue-dot-list">
            <li>
              本公司向來尊重智慧財產權，網友如於網路空間任意上傳、下 載、轉貼未經授權之影像、圖片、音樂或文章，或利用P2P等軟體非法傳輸未經授權之影像、圖片、音樂或文章，本公司將依著作權法「第六章之一網路服務提供 者之民事免責事由」相關規定執行著作權保護措施，例如移除或使他人無法進入，如經本公司認定網友有三次涉有侵權情事，本公司將終止全部或部分服務。詳情請 參閱:經濟部智慧財產局網站 <a href="#" >http://www.tipo.gov.tw/</a>
            </li>
            <li>
              本公司接收著作權侵害通知窗口資訊如下：</br>
              聯繫窗口之姓名或名稱: 遠傳電信客服信箱</br>
              地址: 台北市內湖區瑞光路468號</br>
              聯絡電話: (02)4495888</br>
              傳真號碼: (02)7720-3231</br>
              電子郵件信箱: <a href="#">ecare@fareastone.com.tw</a>
            </li>
          </ul>
          <p>2. 新世紀資通股份有限公司</p>
          <ul class="blue-dot-list">
            <li>
              本公司向來尊重智慧財產權，網友如於網路空間任意上傳、下 載、轉貼未經授權之影像、圖片、音樂或文章，或利用P2P等軟體非法傳輸未經授權之影像、圖片、音樂或文章，本公司將依著作權法「第六章之一網路服務提供 者之民事免責事由」相關規定執行著作權保護措施，例如移除或使他人無法進入，如經本公司認定網友有三次涉有侵權情事，本公司將終止全部或部分服務。詳情請 參閱:經濟部智慧財產局網站 <a href="#">http://www.tipo.gov.tw/</a>
            </li>
            <li>
              本公司接收著作權侵害通知窗口資訊如下：</br>
              聯繫窗口之姓名或名稱: 新世紀資通著作權檢舉信箱</br>
              地址: 台北市內湖區瑞光路218號</br>
              聯絡電話: (02)449-5123</br>
              傳真號碼: (02)2659-6655</br>
              電子郵件信箱: <a href="#">ntd@fareastone.com.tw</a>
            </li>
          </ul>
          
        `,
        detailLink: '#',
      },
      {
        title: '個人資料保護法告知內容',
        content: `
          <p>1. 遠傳電信股份有限公司(下稱”本公司”)，因經營電信業務與電信加值網路業務而蒐集、處理或利用之個人資料時，皆以尊重您的權益為基礎，並以誠實信用之方式及以下原則為之：</p>
          <p>本公司蒐集您個人資料之目的係為行銷、 非公務機關依法定義務所進行個人資料之蒐集處理及利用、信用卡、現金卡、轉帳卡或電子票證業務、契約、類似契約或其他法律關係事務、政令宣導、個人資料之 合法交易業務、旅外國人急難救助、消費者、客戶管理與服務、消費者保護、帳務管理及債權交易業務、會計與相關服務、經營電信業務與電信加值網路業務、資 （通）訊服務、資（通）訊與資料庫管理、資通安全與管理、網路購物及其他電子商務服務、廣告或商業行為管理、影視、音樂與媒體管理、徵信、調查、統計與研 究分析、其他經營合於營業登記項目或組織章程所定之業務、其他諮詢與顧問服務等。</p>
          <p>本公司僅會請您提供主管機關公告之個人 資料類別，屬電信業務或電信加值網路業務之執行(包含加值服務、各項優惠措施、行銷、活動訊息、市場調查、帳務處理、欠費催繳或其它電信相關作業等事項) 必要之個人資料，包含但不限載於各項申請文件之客戶本人(或其代表人) 及法定代理人之姓名、身分證字號、其他足資辨識身分之證明文件、住址、聯絡電話、電子信箱、出生年月日及其它相關必要資料。</p>
          <p>本公司蒐集您之個人資料只會在服務契約成立前階段、契約存續期間或經您同意之期間被處理或利用，惟相關法令另有保存期限規定者從之。您的個人資料會於中華民國境內、境外（主管機關禁止者不在此限）地區，供本公司與本公司之委外廠商處理及利用。利用方式將會以符合蒐集特定目的及法令規定範圍之方式為之。</p>
          <p>本公司保有您的個人資料時，您可以依個 人資料保護法規定行使查詢、閱覽、製給複製本、補充更正、請求停止蒐集、處理或利用及刪除您的個人資料。為保障您的個人資料安全，行使前揭權利時，須由本 人向本公司客戶服務聯繫窗口或直營門市檢具身份證明文件提出申請並填具本公司作業申請表。相關申請資料提供之方式、處理期限、查詢費用等事項，均依法令、 本公司營業規章及服務契約相關規定辦理。本公司得依個人資料保護法第10條、第11條規定，執行業務所必須及法定保存期間等考量，核決是否接受您的申請。</p>
          <p>您可以自由選擇是否提供您的個人資料。若您選擇不提供個人資料或是提供不完全時，基於健全電信業務與電信加值網路業務之執行，本公司將可能無法核准申辦或提供您完整的服務。</p>
        `,
        detailLink: '#',
      },
    ],
  },
  {
    name: '雲端運算',
    more: {
      text: '看更多',
      link: '#',
    },
    content: [
      {
        title: '要新增SSL 加密憑證時，是否可申請額外的IP?',
        content: `
          <p>新世紀資通股份有限公司(下稱"本公司")，因經營雲端運算服務業務而蒐集、處理或利用之個人資料時，皆以尊重 <a href='#' target="_blank">您的權益</a> 為基礎，並以誠實信用之方式及以下原則為之：</p>
          <p>本公司蒐集您個人資料之目的係為行銷、 非公務機關依法定義務所進行個人資料之蒐集處理及利用、信用卡、現金卡、轉帳卡或電子票證業務、契約、類似契約或其他法律關係事務、政令宣導、個人資料之 合法交易業務、旅外國人急難救助、消費者、客戶管理與服務、消費者保護、帳務管理及債權交易業務、會計與相關服務、經營電信業務與電信加值網路業務、資 （通）訊服務、資（通）訊與資料庫管理、資通安全與管理、網路購物及其他電子商務服務、廣告或商業行為管理、影視、音樂與媒…</p>
        `,
        detailLink: '/help-center/faq/detail/1',
      },
      {
        title: '有關遠傳雲端運算服務，相關的隱私權保護政策為何?',
        content: `
          <p>新世紀資通股份有限公司(下稱"本公司")，因經營雲端運算服務業務而蒐集、處理或利用之個人資料時，皆以尊重 <a href='#' target="_blank">您的權益</a> 為基礎，並以誠實信用之方式及以下原則為之：</p>
          <p>本公司蒐集您個人資料之目的係為行銷、 非公務機關依法定義務所進行個人資料之蒐集處理及利用、信用卡、現金卡、轉帳卡或電子票證業務、契約、類似契約或其他法律關係事務、政令宣導、個人資料之 合法交易業務、旅外國人急難救助、消費者、客戶管理與服務、消費者保護、帳務管理及債權交易業務、會計與相關服務、經營電信業務與電信加值網路業務、資 （通）訊服務、資（通）訊與資料庫管理、資通安全與管理、網路購物及其他電子商務服務、廣告或商業行為管理、影視、音樂與媒…</p>
        `,
        detailLink: '/help-center/faq/detail/1',
      },
    ],
  },
];

export const searchResult = [
  {
    title: '如何申請NB-IoT服務?',
    content: `
        <p>新世紀資通股份有限公司(下稱"本公司")，因經營雲端運算服務業務而蒐集、處理或利用之個人資料時，皆以尊重 <a href='#' target="_blank">您的權益</a> 為基礎，並以誠實信用之方式及以下原則為之：</p>
        <p>本公司蒐集您個人資料之目的係為行銷、 非公務機關依法定義務所進行個人資料之蒐集處理及利用、信用卡、現金卡、轉帳卡或電子票證業務、契約、類似契約或其他法律關係事務、政令宣導、個人資料之 合法交易業務、旅外國人急難救助、消費者、客戶管理與服務、消費者保護、帳務管理及債權交易業務、會計與相關服務、經營電信業務與電信加值網路業務、資 （通）訊服務、資（通）訊與資料庫管理、資通安全與管理、網路購物及其他電子商務服務、廣告或商業行為管理、影視、音樂與媒…</p>
      `,
    detailLink: '/help-center/faq/detail/1',
  },
  {
    title: 'NB-IoT適用的服務類型?',
    content: `
        <p>新世紀資通股份有限公司(下稱"本公司")，因經營雲端運算服務業務而蒐集、處理或利用之個人資料時，皆以尊重 <a href='#' target="_blank">您的權益</a> 為基礎，並以誠實信用之方式及以下原則為之：</p>
        <p>本公司蒐集您個人資料之目的係為行銷、 非公務機關依法定義務所進行個人資料之蒐集處理及利用、信用卡、現金卡、轉帳卡或電子票證業務、契約、類似契約或其他法律關係事務、政令宣導、個人資料之 合法交易業務、旅外國人急難救助、消費者、客戶管理與服務、消費者保護、帳務管理及債權交易業務、會計與相關服務、經營電信業務與電信加值網路業務、資 （通）訊服務、資（通）訊與資料庫管理、資通安全與管理、網路購物及其他電子商務服務、廣告或商業行為管理、影視、音樂與媒…</p>
      `,
    detailLink: '/help-center/faq/detail/1',
  },
  {
    title: '要新增SSL 加密憑證時，是否可申請額外的IP?',
    content: `
        <p>新世紀資通股份有限公司(下稱"本公司")，因經營雲端運算服務業務而蒐集、處理或利用之個人資料時，皆以尊重 <a href='#' target="_blank">您的權益</a> 為基礎，並以誠實信用之方式及以下原則為之：</p>
        <p>本公司蒐集您個人資料之目的係為行銷、 非公務機關依法定義務所進行個人資料之蒐集處理及利用、信用卡、現金卡、轉帳卡或電子票證業務、契約、類似契約或其他法律關係事務、政令宣導、個人資料之 合法交易業務、旅外國人急難救助、消費者、客戶管理與服務、消費者保護、帳務管理及債權交易業務、會計與相關服務、經營電信業務與電信加值網路業務、資 （通）訊服務、資（通）訊與資料庫管理、資通安全與管理、網路購物及其他電子商務服務、廣告或商業行為管理、影視、音樂與媒…</p>
      `,
    detailLink: '/help-center/faq/detail/1',
  },
  {
    title: '有關遠傳雲端運算服務，相關的隱私權保護政策為何?',
    content: `
        <p>新世紀資通股份有限公司(下稱"本公司")，因經營雲端運算服務業務而蒐集、處理或利用之個人資料時，皆以尊重 <a href='#' target="_blank">您的權益</a> 為基礎，並以誠實信用之方式及以下原則為之：</p>
        <p>本公司蒐集您個人資料之目的係為行銷、 非公務機關依法定義務所進行個人資料之蒐集處理及利用、信用卡、現金卡、轉帳卡或電子票證業務、契約、類似契約或其他法律關係事務、政令宣導、個人資料之 合法交易業務、旅外國人急難救助、消費者、客戶管理與服務、消費者保護、帳務管理及債權交易業務、會計與相關服務、經營電信業務與電信加值網路業務、資 （通）訊服務、資（通）訊與資料庫管理、資通安全與管理、網路購物及其他電子商務服務、廣告或商業行為管理、影視、音樂與媒…</p>
      `,
    detailLink: '/help-center/faq/detail/1',
  }
];

export const detail = {
  title: '什麼是 Office 365？Office 365 與 Microsoft Office 兩者之間有何差異？',
  content: `
    <p>Office 365。 「Office 365」是指包含 Office 應用程式外加其他生產力服務之存取權限的訂閱方案，這些項目必須透過網際網路 (雲端服務) 加以啟用，例如針對企業所提供的商務用 Skype Web 會議與 Exchange Online 代管式電子郵件，以及針對家庭所提供的 OneDrive 額外線上儲存空間與 Skype 通話分鐘方案。</p>
    <p>許多 Office 365 方案也包含最新的桌上型電腦版 Office 應用程式，可供使用者安裝至多台電腦與裝置。 完整安裝的應用程式包括：Word、Excel、PowerPoint、OneNote、Outlook、Publisher 和 Access (Publisher 與 Access 僅提供 PC 版本)。您可以將它們安裝在多個裝置上，包括 PC、Mac、Android 平板、Android 手機、iPad 及 iPhone。 只要您擁有包含桌上型電腦版 Office 的有效 Office 365 訂閱，即可隨時享有最新版本的應用程式。</p>
    <p>僅包含線上版 Office 365 的方案，對於特定企業的需求來說是非常棒的選擇，而且它們相容於桌上型電腦版本的 Office 2010、Office 2007 (功能稍微受限)、Mac 版 Office 2011，以及 Mac 版 Office 2008。</p>
    <p>所有 Office 365 訂閱方案皆按月或按年收費。</p>
    <p>Microsoft Office。 我們仍採用 "Microsoft Office" 作為常用生產力軟體的名稱。 Office 套件可以一次買斷，套件內包含 Word、Excel 和 PowerPoint 等應用程式，只能安裝在一部 PC 或 Mac 上。 這些應用程式並不會自動更新；若要取得最新版本，可在新版本推出後再次購買本產品。 目前提供買斷的最新版本有 Windows 版 Office 2013 和 Mac 版 Office 2011。 這些套件並且不會隨附任何包含在 Office 365 中的雲端服務。</p>
  `,
};

export const docuemntAPI = async (uuid) => {
  return axios('/json/faqMock.json').then(result => {
    return result.data;
  })
}