import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

import Tab from '../../components/tab/Tab'
import TabPane from '../../components/tab/TabPane'
import Button from '../../components/Button';
import Link from '../../components/Link';

import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import CollapsePaper from '../../components/partials/collapse/CollapsePaper';

import * as Mock from '../../mock/OriginNumberRoaming';

class Remind extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0
    }
  }

  changeTab = (value) => {
    this.setState({
      currentTab: value
    })
  }

  render() {
    const { currentTab } = this.state;
    return (
      <main className="">
        <NavAnchor
          pageTitle='<a href="#">原號漫遊</a>'
          button={{
            link: '#',
            text: '聯絡我們',
          }}
        // tabs={[
        //   { label: '主題活動', link: '#anchor1' },
        //   { label: '達人帶你玩', link: '#anchor2' },
        //   { label: '漫遊優惠', link: '#anchor3' },
        //   { label: '其他漫遊', link: '#anchor4' },
        // ]}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '原號漫遊', link: '/' },
            { text: '漫遊小叮嚀', link: '/' }
          ]}
          color='white'
        />


        <SectionBanner2
          className="is-origin-number-roaming-country-banner is-360 only-title"
          image={{
            md: '/resources/cbu/roaming/cbu-10.4Roaming-banner-1920x360.jpg',
            sm: '/resources/cbu/roaming/cbu-10.4Roaming-750x400.jpg',
          }}
          tag=""
          subTitle=''
          title='漫遊小叮嚀'
          description=''
          actions=''
        />

        <section className="origin-number-roaming remind with-bg pb-0 pt-7">

          <div className="fui-container">

            <Tab
              className='origin-number-roaming-tab overflow-auto'
              list={[
                { label: '出國漫遊手機設定' },
                { label: '上網超額提醒服務' },
                { label: '出國撥打電話與客服' },
                { label: '漫遊帳單說明' },
              ]}
              onChange={this.changeTab}
              visible={true}
            />

            <TabPane active={this.state.currentTab === 0}>
              <div className="paper py-lg-6">
                <h2>出國漫遊手機設定</h2>
                <h4>iPhone 系統</h4>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <figure className="fui-figure">
                      <img src="/resources/cbu/roaming/ios-roamimg-step-1@2x.png" alt="" />
                      <figcaption className='mb-md-4 mb-0'>Step 1：請選取「設定」的「行動服務」</figcaption>
                    </figure>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <figure className="fui-figure">
                      <img src="/resources/cbu/roaming/ios-roamimg-step-2@2x.png" alt="" />
                      <figcaption className='mb-md-4 mb-0'>Step 2：請選取「行動數據選項」</figcaption>
                    </figure>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <figure className="fui-figure">
                      <img src="/resources/cbu/roaming/ios-roamimg-step-3@2x.png" alt="" />
                      <figcaption className='mb-md-4 mb-0'>Step 3：開啟「數據漫遊」開關</figcaption>
                    </figure>
                  </Grid>
                </Grid>

                <h4 className="mb-1">Android 系統</h4>
                <p>以Galaxy S20 Ultra手機為例</p>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <figure className="fui-figure">
                      <img src="/resources/cbu/roaming/android-roaming-step-1@2x.png" alt="" />
                      <figcaption className='mb-md-4 mb-0'>Step 1：請選取「設定」的「連接」</figcaption>
                    </figure>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <figure className="fui-figure">
                      <img src="/resources/cbu/roaming/android-roaming-step-2@2x.png" alt="" />
                      <figcaption className='mb-md-4 mb-0'>Step 2：請選取「行動網路」</figcaption>
                    </figure>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <figure className="fui-figure">
                      <img src="/resources/cbu/roaming/android-roaming-step-3@2x.png" alt="" />
                      <figcaption className='mb-md-4 mb-0'>Step 3：開啟「數據漫遊」開關</figcaption>
                    </figure>
                  </Grid>
                </Grid>
              </div>
            </TabPane>


            <TabPane active={this.state.currentTab === 1}>
              <div className="paper py-lg-6">
                <h2>上網超額簡訊提醒</h2>
                <h4>機制說明</h4>
                <ul class="green-dot-list is-text-black100 mt-4">
                  <li>當上網傳輸量達 $1,000、$3,000、$5,000 及 $10,000 時，皆會發送簡訊通知。</li>
                  <li>當上網傳輸量達 $15,000 時，會發送簡訊通知，系統將主動關閉漫遊上網功能。若仍需使用請撥打遠傳國際漫遊服務專線。</li>
                </ul>
                <p className="is-text-medium">國際漫遊服務專線（免費）: <a href="#" className="is-text-black50">+886 936-010-888</a></p>
              </div>
              <div className="paper py-lg-6">
                <h2>網路流量怎麼算</h2>
                <h4>1 GB 流量相當於以下任一行為的使用時間</h4>
                <Grid container>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="fui-card fui-card-with-img">
                      <div className="fui-card-image">
                        <img src="/resources/cbu/roaming/roaming-ig@2x.png" alt="" />
                      </div>
                      <div className="fui-card-caption">
                        <h4 className="fui-card-title">FB/IG 發文打卡</h4>
                        <p className="fui-card-desc">使用約 10 小時</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="fui-card fui-card-with-img">
                      <div className="fui-card-image">
                        <img src="/resources/cbu/roaming/roaming-call@2x.png" alt="" />
                      </div>
                      <div className="fui-card-caption">
                        <h4 className="fui-card-title">Line 語音電話</h4>
                        <p className="fui-card-desc">Line 語音電話</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="fui-card fui-card-with-img">
                      <div className="fui-card-image">
                        <img src="/resources/cbu/roaming/roaming-browse@2x.png" alt="" />
                      </div>
                      <div className="fui-card-caption">
                        <h4 className="fui-card-title">瀏覽網頁</h4>
                        <p className="fui-card-desc">使用約 44 小時</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="fui-card fui-card-with-img">
                      <div className="fui-card-image">
                        <img src="/resources/cbu/roaming/roaming-video@2x.png" alt="" />
                      </div>
                      <div className="fui-card-caption">
                        <h4 className="fui-card-title">Youtube 影片</h4>
                        <p className="fui-card-desc">使用約 5 小時</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="fui-card fui-card-with-img">
                      <div className="fui-card-image">
                        <img src="/resources/cbu/roaming/roaming-music@2x.png" alt="" />
                      </div>
                      <div className="fui-card-caption">
                        <h4 className="fui-card-title">音樂串流</h4>
                        <p className="fui-card-desc">使用約 24 小時</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="fui-card fui-card-with-img">
                      <div className="fui-card-image">
                        <img src="/resources/cbu/roaming/roaming-navi@2x.png" alt="" />
                      </div>
                      <div className="fui-card-caption">
                        <h4 className="fui-card-title">地圖導航</h4>
                        <p className="fui-card-desc">使用約 100 小時</p>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <small className="is-text-gray100">＊各上網行為用量參考基準如下：FB 打卡+上傳照片 (1張) 每次約2MB、上網瀏覽網頁每頁約1.5MB、Google Map導航每小時約10MB、觀看Youtube影片每小時約350MB為例，實際檔案大小依使用狀況而有所變動。</small>
              </div>

            </TabPane>


            <TabPane active={this.state.currentTab === 2}>
              <div className="paper py-lg-6">
                <h2>國外聯絡客服</h2>
                <h4 className="mb-1">方法一：撥打服務專線</h4>
                <div className="is-bg-lightgray70 p-2 p-lg-3 mb-5">
                  <h6 className="mt-0 mb-1 is-text-regular">從全球各地撥打（免費）</h6>
                  <h5 className="my-0"><a href="#" className="d-inline-block animation-underline-link is-text-black50">+886 936-010-888</a></h5>
                </div>

                <h4 className="mb-1">方法二：網路線上客服</h4>
                <div class="is-bg-lightgray70 p-2 p-lg-3 mb-5">
                  <h6 className="mt-0 mb-1 is-text-regular">從遠傳生活圈 APP聯絡客服</h6>
                  <h5 className="my-0">開啟遠傳生活圈 APP 的「客服即時通」</h5>
                </div>

                <small className="is-text-gray100">＊提醒您：國外手機上網的資料傳輸費，依各國漫遊當地業者訂定之費用另外收取</small>

                <div className="align-left mt-3">
                  <Button btnStyle='secondary' size='medium' link='#' className=''>下載遠傳生活圈App</Button>
                </div>
              </div>

              <div className="paper py-lg-6">
                <h2>從國外打電話</h2>
                <h4 className="mb-1">從國外撥打電話回台灣</h4>
                <ul class="green-dot-list is-text-black100 mt-2">
                  <li>撥打電話：+886 + 區域號碼 + 市話/行動電話號碼（皆不需輸入第一碼 0）。</li>
                </ul>
                <h4 className="is-text-darkgray50 is-text-regular mb-1">日本撥打至台灣為例：</h4>

                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="is-bg-lightgray70 p-2 p-lg-3 mb-5">
                      <h6 className="mt-0 mb-1 is-text-regular">撥打市話</h6>
                      <h5 className="my-0"><span className="is-text-black50">+886 + 2 + 1234-5678 </span></h5>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="is-bg-lightgray70 p-2 p-lg-3 mb-5">
                      <h6 className="mt-0 mb-1 is-text-regular">撥打行動電話</h6>
                      <h5 className="my-0"><span className="is-text-black50">+886 + 9 + 1234-5678</span></h5>
                    </div>
                  </Grid>
                </Grid>

                <h4 className="mb-1">從國外撥打電話到其他國家</h4>
                <ul class="green-dot-list is-text-black100 mt-2">
                  <li>撥打電話：+國碼 + 區域號碼 + 市話/行動電話號碼（皆不需輸入第一碼 0）。</li>
                </ul>
                <h4 className="is-text-darkgray50 is-text-regular mb-1">新加坡撥打至香港為例：</h4>

                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="is-bg-lightgray70 p-2 p-lg-3 mb-3">
                      <h6 className="mt-0 mb-1 is-text-regular">撥打市話</h6>
                      <h5 className="my-0"><span className="is-text-black50">+852 + 2 + 1234-567 </span></h5>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="is-bg-lightgray70 p-2 p-lg-3 mb-3">
                      <h6 className="mt-0 mb-1 is-text-regular">撥打行動電話</h6>
                      <h5 className="my-0"><span className="is-text-black50">+852 + 9 + 1234-567 </span></h5>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className="paper py-lg-6">
                <h2>從國外收發簡訊</h2>
                <h4 className="mb-1">從國外發簡訊回台灣</h4>
                <ul class="green-dot-list is-text-black100 mt-2">
                  <li>發送簡訊：行動電話號碼（不需輸入國碼）。</li>
                </ul>
                <h4 className="is-text-darkgray50 is-text-regular mb-1">日本發簡訊至台灣為例：</h4>

                <div className="is-bg-lightgray70 p-2 p-lg-3 mb-3">
                  <h6 className="mt-0 mb-1 is-text-regular">發簡訊至行動電話</h6>
                  <h5 className="my-0"><span className="is-text-black50">0912-345-678 </span></h5>
                </div>


                <h4 className="mb-1">從國外發簡訊到其他國家</h4>
                <ul class="green-dot-list is-text-black100 mt-2">
                  <li>發送簡訊：+ 國碼 + 行動電話號碼（不需輸入第一碼 0）。</li>
                </ul>
                <h4 className="is-text-darkgray50 is-text-regular mb-1">新加坡發簡訊至香港為例：</h4>

                <div className="is-bg-lightgray70 p-2 p-lg-3 mb-3">
                  <h6 className="mt-0 mb-1 is-text-regular">發簡訊至行動電話</h6>
                  <h5 className="my-0"><span className="is-text-black50">+852 + 9 + 1234-567 </span></h5>
                </div>
              </div>
            </TabPane>


            <TabPane active={this.state.currentTab === 3}>
              <div className="paper py-lg-6">
                <h2>數據漫遊帳單</h2>
                <div className="fui-table-response mb-2 mb-lg-4">
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th className="is-bg-white">序號</th>
                        <th>日期</th>
                        <th>起始時間</th>
                        <th>漫遊業者</th>
                        <th>計費方式</th>
                        <th>用量(Byte)</th>
                        <th>金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="is-bg-white">001</td>
                        <td>105/04/01</td>
                        <td>12:05:33</td>
                        <td>日本JPNJP-44010</td>
                        <td>計量</td>
                        <td>Byte</td>
                        <td>78.0312</td>
                      </tr>
                      <tr>
                        <td className="is-bg-white">002</td>
                        <td>105/04/01</td>
                        <td>12:05:33</td>
                        <td>日本JPNJP-44010</td>
                        <td>計量</td>
                        <td>Byte</td>
                        <td>78.0312</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <CollapsePaper
                  title="詳細說明"
                  bgColor="#fafafa"
                  container={false}
                  content={`
                    <div class="collapse-roaming-block remind-info">
                      <p class="body-2 is-text-darkgray50">
                        1. 漫遊上網費用, 依各漫遊國家業者所訂定之費用收取，以傳輸量計價，各漫遊國家費率請參考國際漫遊資費。<br/>
                        2. 若使用「遠傳提供的漫遊優惠網路/業者 (包含日租/計日/計量:7日/15日等優惠網)」，則帳單列示時間為"台灣時間"。<br/>
                        3. 若您出國前已開啟門號的數據漫遊服務，於國外漫遊時，僅在手機上自行關閉行動數據或數據漫遊服務功能，仍可能因手機本身資料自動更新等因素，而有些微傳輸量，產生數據漫遊費用。<br/>
                        4. 若使用〝非〞「遠傳提供的漫遊優惠網路/業者」，則帳單列示時間為"國外當地時間"。<br/>
                        5. 以上帳單為至日本漫遊使用上網範例：(序號001)因為DOCOMO 優惠網路/業者,所以列示時間為台灣時間。(序號002)因為使用非優惠網路/業者, 故列示時間為日本當地時間。
                      </p>
                    </div>
                  `}
                />
              </div>
              <div className="paper py-lg-6">
                <h2>語音/簡訊漫遊帳單</h2>
                <div className="fui-table-response mb-2 mb-lg-4">
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th className="is-bg-white">序號</th>
                        <th>日期</th>
                        <th>起始時間</th>
                        <th>漫遊業者</th>
                        <th>通話類型</th>
                        <th>電話號碼</th>
                        <th>通話時間</th>
                        <th>金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="is-bg-white">001</td>
                        <td>105/04/01</td>
                        <td>12:05:33</td>
                        <td>香港Hutchison</td>
                        <td>漫遊發話(打電話)</td>
                        <td>886936050002</td>
                        <td>00:00:04</td>
                        <td>78.4.4012</td>
                      </tr>
                      <tr>
                        <td className="is-bg-white">002</td>
                        <td>105/04/01</td>
                        <td>12:05:33</td>
                        <td>香港Hutchison</td>
                        <td>漫遊發話</td>
                        <td>6032921333</td>
                        <td>00:03:10</td>
                        <td>11.3121</td>
                      </tr>
                      <tr>
                        <td className="is-bg-white">003</td>
                        <td>105/04/01</td>
                        <td>12:05:33</td>
                        <td>香港Hutchison</td>
                        <td>漫遊發話(打電話)</td>
                        <td>886936050002</td>
                        <td>00:00:04</td>
                        <td>78.4.4012</td>
                      </tr>
                      <tr>
                        <td className="is-bg-white">004</td>
                        <td>105/04/01</td>
                        <td>12:05:33</td>
                        <td>香港Hutchison</td>
                        <td>漫遊受話</td>
                        <td>000</td>
                        <td>00:00:15</td>
                        <td>13.3457</td>
                      </tr>
                      <tr>
                        <td className="is-bg-white">005</td>
                        <td>105/04/01</td>
                        <td>12:05:33</td>
                        <td>香港Hutchison</td>
                        <td>漫遊發話(打電話)</td>
                        <td>886936050002</td>
                        <td>00:00:04</td>
                        <td>78.4.4012</td>
                      </tr>
                      <tr>
                        <td className="is-bg-white">006</td>
                        <td>105/04/01</td>
                        <td>12:05:33</td>
                        <td>香港Hutchison</td>
                        <td>漫遊受話</td>
                        <td>000</td>
                        <td>00:00:15</td>
                        <td>13.3457</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <CollapsePaper
                  title="詳細說明"
                  bgColor="#fafafa"
                  container={false}
                  content={`
                    <div class="collapse-roaming-block remind-info">
                      <p class="body-2 is-text-darkgray50">
                        1. (001) 及 (002) 兩筆費用為漫遊發話的計費。在帳單中所顯示之「電話號碼」，即代表您所撥出之受話者電話號碼。<br/>
                        2. (003) 及 (004) 兩筆費用為漫遊受話計費。因受限於國際業者網路設備功能，漫遊時來話方號碼多數不能顯示正確來話方號碼，可能顯示為國外電信業者所傳送的號碼 (包括顯示不完整號碼、顯示錯誤號碼...等)或是完全不顯示來話號碼，故本公司帳單明細呈現將轉為"其他代表號"或顯示"000"。因前述系統限制故本公司帳單明細無法提供正確來話號碼。<br/>
                        3. (005) - (006) 為您接受一通語音留言之費用，故此兩筆記錄之起始時間均相同。<br/>
                        4. (005) 該筆費用，是由台灣自動接續至漫遊地之國際電話費 (此為漫遊受話)。<br/>
                        5. (006) 該筆費用，是由漫遊地轉接回遠傳電信語音信箱之國際電話費 (此為漫遊發話)；帳單上顯示之電話號碼是您語音信箱的號碼。<br/>
                        6. (007) 為您漫遊時收聽語音信箱之費用，帳單上所顯示之電話號碼即為您的語音信箱收聽號碼。<br/>
                        7. (008) 為於漫遊國家發送簡訊之費用，該筆費用是以發送次數計費，在帳單上所顯示之電話號碼為簡訊服務中心代號，或您實際發送之電話號碼<br/>
                        8. 國際漫遊發送簡訊除前述外，尚需負擔由本公司轉發至收訊端之簡訊處理費，顯示於計次加值服務項目，依收訊端不同可分為: 網內簡訊NT$2.6127元、網外簡訊NT$2.6127元、國際簡訊NT$ 5元。 <br/>
                        9. 漫遊受話有以下特殊狀況，特此說明：當您在漫遊時，所有找您的電話，均需透過本公司網路後再自動接續至您漫遊的地區，因此即使發話人與您身處同一漫遊網，您亦需負擔由台灣自動接續至漫遊地區的國際話費。 
                      </p>
                    </div>
                  `}
                />
              </div>

            </TabPane>
          </div>

          <div className="fui-container">
            <Grid container>
              <Grid item xs={12} sm={12} md={4}>
                <Link to="#" className='paper-link download'>漫遊小叮嚀懶人包 PDF 檔下載</Link>
              </Grid>
            </Grid>
          </div>

          <SectionCollapseInfo
            title="備註說明"
            content={`
              1. 上述漫遊費用除非特別註明計價單位，其餘皆已換算為「每分鐘 / 新台幣元」表示，因匯率時有變動，收費時按雙方網路經營者實際交易日之匯率換算計收，上述費用僅供參考。<br/>
              2. 本公司依國外業者傳送之通信資料，透過簡訊提供上網用量及費用等相關通知，僅供參考，傳送過程可能因國外業者系統狀況、客戶終端無簡訊收送功能或關機等因素，而發生延遲、無法送達或超過簡訊發送時限而傳送失效等情形，於漫遊期間實際產生之用量及費用，仍以帳單為準。<br/>
              3. 若您出國前已開啟門號的數據漫遊服務，於國外漫遊時，僅在手機上自行關閉行動數據或數據漫遊服務功能，仍可能因手機本身資料自動更新等因素，而有些微傳輸量，產生數據漫遊費用。<br/>
              4. 使用影像電話視漫遊業者網路系統支援程度而決定。<br/>
              5. 漫遊區域與資費表之減價時段為漫遊當地之時間, 而非台灣時間。
            `}
          />
        </section>
      </main>
    );
  }
}

export default Remind;
