import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Button from '../../components/Button';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import * as Validation from '../../utils/validation';

import * as FourGService from '../../service/FourGService';
import * as CfgMsgService from '../../service/CfgMsgService';

class fourGExtraBuyPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef()
    this.form2 = React.createRef()
    this.state = {
      step: 1,
      extraBuyItemList: [],
      extraBuyInfo: {},
      form: {
        ic_plan: { value: '', required: true },
      },
      form2: {
        id_number: { value: '', required: true },
      },
      canSubmit: false,
      canSubmit2: false,
      cfgMsg: '',
      showMore: false,
    };

  }

  enableButton = () => {
    if (this.state.step === 1)
      this.setState({
        canSubmit: true,
      });
    else
      this.setState({
        canSubmit2: true,
      });
  };

  disableButton = () => {
    if (this.state.step === 1)
      this.setState({
        canSubmit: false,
      });
    else
      this.setState({
        canSubmit2: false,
      });
  };

  componentDidMount() {

    FourGService.extraBuyInfo().then((data) => {
      let extraBuyItemList = data.extraBuyItemList.reduce((accu, val, index) => {
        let str = val.desc.split('上網計量包')[1];
        let unit = str.indexOf('MB') > -1 ? 'MB' : 'GB';
        let number = str.split(/GB|MB/g)[0];

        accu.push({
          ...val,
          quant: number,
          unit: unit,
        });

        return accu;
      }, []);
      // debugger
      this.onChange('ic_plan', extraBuyItemList[0].quant)
      this.setState({
        extraBuyInfo: data,
        extraBuyItemList: extraBuyItemList
      });
    });

    CfgMsgService.cfgMsg().then((data) => {
      this.setState({
        cfgMsg: data,
      });
    });
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    console.log('onChange', name, value)
    this.setState({
      form: newForm,
    });
  };

  onChange2 = (name, value) => {
    let newForm = Object.assign(this.state.form2);
    newForm[name].value = value;
    console.log('onChange', name, value)
    this.setState({
      form2: newForm,
    });
  };

  nextStep = () => {
    this.setState({ step: 2 });
  }
  submit = () => {
    this.props.history.push('/resultController/ResultPage', {
      type: 'success',
      pageName: '帳單繳費',
      title: '購買成功',
      description: `你已完成上網用量加購 <br/>方案名稱：4G上網計量包3GB / 500`,
      actions: [
        {
          text: '查詢購買記錄',
          link: '#',
          btnStyle: 'primary'
        },
        {
          text: '前往個人專區',
          link: '#',
          btnStyle: 'secondary'
        }
      ]
    })
  }

  render() {
    const { extraBuyInfo, extraBuyItemList, cfgMsg, form } = this.state;

    return (
      <main>

        <NavAnchor
          pageTitle='帳單繳費'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <FormBanner
          title='4G上網用量加購'
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section>
          <div className='fui-container'>
            <section className={`pb-0`}>
              <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form2}>
                {/* <h4 className="is-text-darkgray50">{cfgMsg}</h4> */}
                <h4 className='is-text-darkgray50'>您可於此處購買月租型門號的4.5G上網用量，費用將於您下期帳單收取。</h4>
                <div className='fui-segments'>
                  <div className='fui-segment'>
                    <Grid container>
                      <Grid item xs={5}>
                        <h4>您目前使用的費率</h4>
                      </Grid>
                      <Grid item xs={7} className='align-right'>
                        <Button btnStyle='secondary' size='small'>
                          加購記錄查詢
                          </Button>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className='fui-boards mb-0'>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className='fui-board'>
                          <h5>門號</h5>
                          <h3>4G新絕配199</h3>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className='fui-board'>
                          <h5>門號類別</h5>
                          <h3>每月20日</h3>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className='fui-segments mt-2'>
                  <div className='fui-segment'>
                    <h4>請選擇加購方案</h4>
                    <div className='fui-radio-cards'>
                      {extraBuyItemList.map((plan, i) => (
                        <label
                          role="button"
                          className='radio-card'
                          key={`4g-ic-plan-${i}`}
                        >
                          <input
                            id={`ic_plan-${i}`}
                            type='radio'
                            name='ic_plan'
                            value={plan.quant}
                            onClick={e => this.onChange('ic_plan', plan.quant)}
                            defaultChecked={plan.quant === form.ic_plan.value}
                          />
                          <div className='content'>
                            <div className='ic-plan'>
                              {plan.quant}
                              <small>{plan.unit}</small>
                            </div>
                            <h5>{plan.offerName}</h5>
                            <div className='body'>
                              原價 <b>${plan.normalPrice}</b>
                            </div>
                            {plan.hasDiscount ? (
                              <div className='body is-text-coral100'>
                                優惠價 <b>${plan.discountPrice}</b>
                              </div>
                            ) : null}
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </Formsy>
            </section>
            {
              this.state.step === 2 ? (
                <section className={`pb-0`}>
                  <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                    <h3>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h3>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          name='id_number'
                          required={this.state.form2.id_number.required}
                          label='證照號碼'
                          validations={{
                            idNumberValid: Validation.idNumberValid
                          }}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入證照號碼'
                          }}
                          placeholder='請輸入證照號碼'
                          value={this.state.form2.id_number.value}
                          onChange={this.onChange2}
                        />
                        <div className='body1 is-text-darkgray50'>
                          身分說明：
                            <br />
                            個人戶：身分證字號
                            <br />
                            公司戶：統一編號或稅籍編號
                            <br />
                            外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                          </div>
                      </Grid>
                    </Grid>
                  </Formsy>
                </section>
              ) : null
            }

            {this.state.step === 1 ? (
              <div className='page-action'>
                <Button btnStyle='primary' onClick={this.nextStep} size='large'>
                  下一步
                  </Button>
              </div>
            ) : (
                <div className='page-action'>
                  <Button btnStyle='primary' onClick={this.submit} size='large' disabled={!this.state.canSubmit2}>
                    確認
                  </Button>
                  {/* <Button btnStyle="secondary" size='large'>取消</Button> */}
                </div>
              )}
          </div>
        </section>

        <SectionCollapseInfo
          title='貼心小叮嚀'
          content={`
            1.當月上網用量用完後，降速至128Kbps(月租費$199費率上網用量超過時即暫停服務)，用戶可選擇加購上網計量包。<br/>
            2.當上網用量使用達到70%及98%時，系統將會發送雙向簡訊，提醒用戶並詢問是否需要加購上網用量。
          `}
        />

      </main>
    );
  }
}

export default fourGExtraBuyPage;
