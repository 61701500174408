import React from 'react';
import Link from '../../Link';

import PropTypes from 'prop-types';

class FindItemBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth < 768,
    };
  }

  componentDidMount = () => {
    if (window === 'undefined') return;

    window.addEventListener('resize', (e) => {
      this.setState({
        isMobile: window.innerWidth < 768,
      });
    });
  };

  render() {
    return (
      <div
        className='fui-banner is-find-product lazyload'
        data-bg={process.env.PUBLIC_URL + (this.state.isMobile ? this.props.image.sm : this.props.image.md)}
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + (this.state.isMobile ? this.props.image.sm : this.props.image.md)
          })`,
        }}>
        <div className='fui-container'>
          <h1>{this.props.title}</h1>
        </div>
      </div>
    );
  }
}

FindItemBanner.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
};

export default FindItemBanner;
