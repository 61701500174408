import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

import LifeCircleCarousel from '../../components/partials/card/LifeCircleCarousel';
import BannerPromotionBasic from '../../components/partials/banner/BannerPromotionBasic';
import ArrowMoreTopRightCarousel from '../../components/partials/card/ArrowMoreTopRightCarousel';
import SectionRoamingProductCardCarousel from '../../components/partials/card/SectionRoamingProductCardCarousel';
import ESectionFeature from '../../components/partials/ESectionFeature';
import VideoCarousel from '../../components/VideoCarousel';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

import Tab from '../../components/tab/Tab';
import TabPane from '../../components/tab/TabPane';
import CardMultiSteps from '../../components/card/CardMultiSteps';
import SectionAd3 from '../../components/partials/SectionAd3';
import FooterAd from '../../components/ad/FooterAd';
import SectionFaq from '../../components/partials/collapse/SectionFaq';

import * as Mock from '../../mock/OriginNumberRoaming';

class LocalCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      bgImg: {
        md: '/resources/cbu/roaming/cbu-roaminghome1-banner-1920x470.jpg',
        sm: '/resources/cbu/roaming/cbu-roaminghome1-banner-1920x470.jpg',
      },
      keyword: '',
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        this.setState({
          isMobile: window.innerWidth < 768,
        });
      });
  }


  render() {
    return (
      <main className="">
        <NavAnchor
          pageTitle='<a href="#">出國上網卡 - 遠遊卡</a>'
          button={{
            link: '#',
            text: '立即選購',
          }}
          tabs={[
            { label: '遠遊卡介紹', link: '#anchor1' },
            { label: '主題活動', link: '#anchor2' },
            { label: '達人帶你玩', link: '#anchor3' },
            { label: '旅遊專題', link: '#anchor4' },
            { label: '遠遊卡優惠', link: '#anchor5' },
          ]}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '出國上網卡 - 遠遊卡', link: '/' }
          ]}
          color='white'
        />


        <BannerPromotionBasic
          className="is-origin-number-roaming-localcard-banner"
          bgColor="#fff"
          slides={
            [
              {
                image: {
                  md: '/resources/cbu/roaming/cbu-roamingcard-banner-1920x470.jpg',
                  sm: '/resources/cbu/roaming/cbu-roamingcard-750x720.jpg'
                },
                tag: "",
                title: '遠遊卡 2.0 升級登場',
                description: '一卡在手 ‧ 旅遊上網 ‧ 暢遊全球 gogo',
                theme: 'dark',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '了解更多',
                    link: '#'
                  },

                ]
              },
              {
                image: {
                  md: '/resources/cbu/roaming/cbu-roamingcard-banner-1920x470.jpg',
                  sm: '/resources/cbu/roaming/cbu-roamingcard-750x720.jpg'
                },
                tag: "",
                title: '遠遊卡 2.0 升級登場',
                description: '一卡在手 ‧ 旅遊上網 ‧ 暢遊全球 gogo',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '了解更多',
                    link: '#'
                  },

                ]
              },
              {
                image: {
                  md: '/resources/cbu/roaming/cbu-roamingcard-banner-1920x470.jpg',
                  sm: '/resources/cbu/roaming/cbu-roamingcard-750x720.jpg'
                },
                tag: "",
                title: '遠遊卡 2.0 升級登場',
                description: '一卡在手 ‧ 旅遊上網 ‧ 暢遊全球 gogo',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '了解更多',
                    link: '#'
                  },

                ]
              },
              {
                image: {
                  md: '/resources/cbu/roaming/cbu-roamingcard-banner-1920x470.jpg',
                  sm: '/resources/cbu/roaming/cbu-roamingcard-750x720.jpg'
                },
                tag: "",
                title: '遠遊卡 2.0 升級登場',
                description: '一卡在手 ‧ 旅遊上網 ‧ 暢遊全球 gogo',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '了解更多',
                    link: '#'
                  },

                ]
              },
            ]
          }
        />



        <section className="origin-number-roaming local-card with-bg pb-0 pt-7">

          <div className="fui-container">
            <Tab
              default={this.state.currentTab}
              list={[
                { label: "東北亞" },
                { label: "東南亞 / 中國" },
                { label: "歐美／其他" },
              ]}
              onChange={e => this.setState({ currentTab: e })}
            />

            <TabPane active={this.state.currentTab === 0}>
              <SectionRoamingProductCardCarousel
                {...Mock.sectionProduct}
              />
            </TabPane>
            <TabPane active={this.state.currentTab === 1}>
              <SectionRoamingProductCardCarousel
                {...Mock.sectionProduct}
              />
            </TabPane>
            <TabPane active={this.state.currentTab === 2}>
              <SectionRoamingProductCardCarousel
                {...Mock.sectionProduct}
              />
            </TabPane>
          </div>


          <section id="anchor1" className="pt-0">
            <ESectionFeature {...Mock.sectionFeatureLocalCard} />
          </section>

          <CardMultiSteps
            title='3 步驟輕鬆搞定出國上網'
            list={[
              {
                content: '線上或實體門市購買遠遊卡',
                bg: '/resources/cbu/roaming/roaming-step-1-bgimg.svg'
              },
              {
                content: '抵達國外當地後，將 SIM 卡裝入手機',
                bg: '/resources/cbu/roaming/roaming-step-2-bgimg.svg'
              },
              {
                content: '開啟「行動數據」及「數據漫遊」自動連結國外當地網路',
                bg: '/resources/cbu/roaming/roaming-step-3-bgimg.svg'
              }
            ]}
          />

          <SectionAd3
            patternColor='green'
            title={`除了遠傳網路門市，還有其他線上或實體門市可購買遠遊卡喔！`}
            action={[
              {
                text: '了解更多',
                line: '#',
              },
            ]}
          />

          <section id="anchor2" className="p-0">
            <SectionCarousel1 {...Mock.topicCard} />
          </section>


          <section id="anchor3" className="p-0">
            <VideoCarousel
              className="pt-0"
              title="達人帶你玩"
              videos={[
                {
                  videoId: '9FaXQK51iM8',
                  title: '關西賞櫻超夯打卡景點',
                  content: '日本 5 天遠遊卡只要 $199',
                  imgSmall: '/resources/cbu/roaming/i-stock-970064046.jpg',
                  imgLarge: '/resources/cbu/roaming/i-stock-970064046.jpg',
                  videoLink: 'https://youtu.be/u9YYwJKQ0aQ',
                  alterVideo: null,
                },
                {
                  videoId: '9FaXQK51iM8',
                  title: '關西賞櫻超夯打卡景點22',
                  content: '日本 5 天遠遊卡只要 $199',
                  imgSmall: '/resources/cbu/roaming/i-stock-970064046.jpg',
                  imgLarge: '/resources/cbu/roaming/i-stock-970064046.jpg',
                  videoLink: 'https://youtu.be/u9YYwJKQ0aQ',
                  alterVideo: null,
                },
                {
                  videoId: '9FaXQK51iM8',
                  title: '關西賞櫻超夯打卡景點33',
                  content: '日本 5 天遠遊卡只要 $199',
                  imgSmall: '/resources/cbu/roaming/i-stock-970064046.jpg',
                  imgLarge: '/resources/cbu/roaming/i-stock-970064046.jpg',
                  videoLink: 'https://youtu.be/CwfE29SgSZE',
                  alterVideo: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
                }
              ]}
            />
          </section>

          <section id="anchor4" className="p-0">
            <SectionCarousel1
              className="pt-6"
              {...Mock.travelCard}
            />
          </section>


          <section id="anchor5" className="p-0 overflow-hidden">
            <ArrowMoreTopRightCarousel
              {...Mock.discountCard}
              className="pt-0"
              title="<h2 class='section-title align-left'>漫遊優惠</h2>"
            />
          </section>


          <SectionFaq {...Mock.faqNoTab}
            container={true}
          />

          <FooterAd {...Mock.eventPromoData2} />

        </section>
      </main>
    );
  }
}

export default LocalCard;
