import React from 'react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import RadioGroup from '../../components/form/RadioGroup';
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import CollapsePaper from '../../components/collapse/CollapsePaper';
import * as Validation from '../../utils/validation';


class Internation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: 'id',
      step: 1,
      step2Validation: false,
      ismail: [
        {
          value: '0',
          label: '是',
        },
        {
          value: '1',
          label: '否',
        },
      ],
      form: {
        setting: { value: 'true', required: false },
        agree: { value: 'false', required: false },
        id_number: { value: '', required: false },
        fetnetAccount: { value: '', required: true }
      },
      canSubmit: false,
      detailForm: {
        title: '費用類別明細',
        table: {
          head: ['業者', '指定電信業者', '速博', '中華電信', '亞太電信', '台灣固網'],
          content: [
            ['國際接取碼', '計費單位'],
            ['速博', '元/秒'],
            ['18881', '元/每分'],
            ['007', '元/每秒'],
            ['017', '元/每分'],
            ['009', '元/每分'],
            ['019（限月租型）', '元/每分'],
            ['005', '元/6秒'],
            ['015', '元/每分'],
            ['006', '元/6秒'],
            ['016', '元/每分'],
          ]
        }
      },
    }
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm,
    });
  };
  defaultFormOnChange = (name, value) => {
    let result = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    console.log(result);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = result;
    this.setState({
      form: newForm,
    });
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="撥打國際電話"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="mt-0 pt-md-7 pt-4 no-bg">
          <div className="fui-container">
            <div className="fui-segments no-shadow is-bg-initial">
              {this.state.step == 1 ? <div>
                <h4 className="is-text-darkgray50 mb-md-8 mb-4">
                  此服務提供您開啟或關閉撥打國際電話功能，以及計費和撥號方式之說明
                </h4>
                <div className="paper mt-md-4 mt-2 mb-0 pb-md-7 pb-4">
                  <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-2">
                    <h4 className='m-0'>撥打須知</h4>
                  </div>
                  <ul className="green-dot-list m-0">
                    <li>撥打國際電話定義：不管您所在國家在何處，您當下之所在國家即為「當地」，撥打出去的所有電話全部為「國際電話」。 Ex：您目前所在國家為台灣，撥打至台灣以外的國家皆為國際電話 Ex：您目前所在國家為美國，撥打至美國以外的國家皆為國際電話，包含撥打回台灣。</li>
                  </ul>
                </div>
                <div className="paper mt-2 mb-0 pb-md-7 pb-4">
                  <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-1">
                    <h4 className='m-0'>撥號方式</h4>
                  </div>
                  <div className="body-1">
                    發話方所在地國際接取碼＋受話方國碼＋區域號碼＋電話號碼。若受話當地區域號碼或行動電話號碼其第一碼為0，則0不需撥號。
                  </div>
                  <h4 className='mt-4 mb-2 is-text-darkgray50 is-text-regular'>撥打至美國紐約718-3514321為例</h4>
                  <Grid className='' container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <div className="is-bg-lightgray70 px-3 py-3">
                        <h6 className="is-text-regular m-0">
                          業者為中華電信(國際接取碼為019)
                        </h6>
                        <h2 className='mb-0'>
                          019+國碼+區域碼+電話號碼
                           <div className="d-inline-block is-text-regular body my-0 ml-1">撥出</div>
                        </h2>
                      </div>
                      <small className="is-text-gray100 mt-1 d-block">＊撥打範例，如：019-1-718-3514321</small>
                    </Grid>
                  </Grid>
                </div>
                <CollapsePaper
                  title="各業者國際接取碼查詢與計費單位"
                  className="collapse-table pr-md-3 pr-0"
                  openCollapse={e => { console.log(e) }}
                  open={false}
                >
                  <div className="pb-4">
                    <div className="compareTable content-16 clearfix text-wrap bill-table mt-md-0 mt-3 mb-0">
                      <div className="tableLeft">
                        <div className="tableHead">
                          <table>
                            <tbody>
                              {this.state.detailForm.table.head.map((item, i) => {
                                return (
                                  <tr className='is-bg-white' key={item}>
                                    <td className={`align-center is-text-bold ${i == 0 ? '' : 'colspan2'}`}>{item}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tableRight">
                        <div className="tableBody">
                          <div className="spaceBox">
                            <table className="compareList odd">
                              <tbody>
                                {this.state.detailForm.table.content.map((list, i) => (
                                  <tr key={list + i} className={i == 0 ? 'is-bg-lightgray70' : ''}>
                                    {list.map((item, j) => (
                                      <td key={item + j}>
                                        {item == '速博' ? <a href="#" className="info-link">速博</a> : <div>{item}</div>}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CollapsePaper>
                <div className="paper mt-2">
                  <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                    <RadioGroup
                      label='目前設定'
                      name="setting"
                      className='mb-md-4 mb-0 mt-md-4 mt-0'
                      options={[
                        { label: '開啟', value: 'true' },
                        { label: '關閉', value: 'false' }
                      ]}
                      default={this.state.form.setting.value}
                      onChange={this.onChange}
                    />
                  </Formsy>
                </div>
                {!this.state.step2Validation ? <div className='page-action'><Button
                  onClick={() => this.setState({ step2Validation: true })}
                  link="#"
                  className='mt-3 mb-lg-0 mb-md-2 mb-0'
                  btnStyle='primary'
                  size='large'>
                  下一步
                  </Button></div> : null}

                {this.state.step2Validation ? <div className="mt-7">
                  <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                    {this.state.accountType == 'id' ? <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <h4 className='mb-3'>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <LabelInput
                          className="mb-2"
                          name='id_number'
                          required={this.state.form.id_number.required}
                          label='證照號碼'
                          validations={{
                            idNumberValid: Validation.idNumberValid
                          }}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入證照號碼'
                          }}
                          placeholder='請輸入證照號碼'
                          value={this.state.form.id_number.value}
                          onChange={this.onChange}
                        />
                        <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                          身分說明：<br />
                          個人戶：身分證字號<br />
                          公司戶：統一編號或稅籍編號<br />
                          外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                        </p>
                        <div className='page-action'><Button onClick={() => { console.log('submit!') }} link="#" className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>確認</Button></div>
                      </Grid>
                    </Grid> : <Grid container spacing={2}><Grid item xs={12} md={12}>
                      <h4 className='mb-3'>為保護您個人資料的安全，請輸入遠傳帳戶密碼進行確認</h4>
                    </Grid>
                        <Grid item xs={12} md={6}>
                          <LabelInput
                            className="mb-2"
                            name='fetnetAccount'
                            required={this.state.form.fetnetAccount.required}
                            label='帳戶密碼(遠傳通關密碼)'
                            validations="isLength:8"
                            validationErrors='請輸入密碼'
                            placeholder='請輸入密碼'
                            value={this.state.form.fetnetAccount.value}
                            onChange={this.onChange}
                          />
                          <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                            帳戶密碼說明：<br />
                            1.帳戶密碼為您在遠傳所設置的帳戶授權密碼，亦稱通關密碼。<br />
                            2.如您欲變更密碼或查詢密碼，請洽客服中心或立即使用客服即時通。
                          </p>
                          <div className='page-action'><Button onClick={() => { console.log('submit!') }} link="#" className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>確認</Button></div>
                        </Grid>
                      </Grid>}
                  </Formsy>
                </div> : null}
              </div> : null}
            </div>
          </div>
        </section>
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ accountType: 'id' })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.4.8.3_漫遊與門號服務_行動上網_輸入身分證號</Button>
              <Button onClick={() => this.setState({ accountType: 'fetnet' })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.4.8.2_漫遊與門號服務_行動上網_輸入遠傳帳密</Button>
            </div>
          </div>
        </div>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
          <ol>
            <li>取消此功能，將會一併取消「超值77」費率。</li>
            <li>此功能用戶毋需支付額外月租費，所撥打的國際電話依各固網電信業者所規定之國際電話費率付費。</li>
            <li>至國外漫遊若未開啟此功能，將無法撥電話回台灣或第三國，僅能接收國際電話及收發漫遊當地的電話。</li>
          </ol>
          `}
        />
      </main >
    )
  }
}

export default Internation;