import React, { Component } from 'react'
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import AppPromotion from '../../components/partials/AppPromotion';
import VideoModal from '../../components/VideoModal'
import SectionAd3 from '../../components/partials/SectionAd3';
import BrandCarousel from '../../components/partials/carousel/BrandCarousel'
import Slider from "react-slick";
class LifeCircleApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVideo: '',
      modalOpen: false,
      alterVideo: '',
      video: 'oqCt6yq_xoc',
      partner: [
        {
          id: 1,
          content: [
            {
              url: '/resources/cbu/img/partner-logo-1@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-2@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-3@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-4@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-5@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-6@2x.png',
              alt: 'img'
            },
          ],
        },
        {
          id: 2,
          content: [
            {
              url: '/resources/cbu/img/partner-logo-1@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-2@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-3@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-4@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-5@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-6@2x.png',
              alt: 'img'
            },
          ],
        },
        {
          id: 3,
          content: [
            {
              url: '/resources/cbu/img/partner-logo-1@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-2@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-3@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-4@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-5@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-6@2x.png',
              alt: 'img'
            },
          ],
        },
        {
          id: 4,
          content: [
            {
              url: '/resources/cbu/img/partner-logo-1@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-2@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-3@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-4@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-5@2x.png',
              alt: 'img'
            },
            {
              url: '/resources/cbu/img/partner-logo-6@2x.png',
              alt: 'img'
            },
          ],
        },
      ]
    }
  }
  openVideoModal = data => {
    // console.log(data);

    this.setState({
      modalOpen: true,
      alterVideo: null,
      currentVideo: this.state.video,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      alterVideo: '',
      currentVideo: '',
    });
  };
  render() {
    return (
      <main className="life-circle-app">
        <NavAnchor
          pageTitle='<a href="#">遠傳生活圈</a>'
          button={
            [
              {
                link: '#',
                text: '下載APP',
              },
              {
                link: '#',
                text: '回首頁',
              },
            ]
          }
          tabs={[
            { label: '什麼是生活圈', link: '#life-circle' },
            { label: '滿載好康', link: '#goodie' },
            { label: '招募夥伴', link: '#hiring' }
          ]}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人總覽', link: '/' },
            { text: '遠傳生活圈APP', link: '/' }
          ]}
          color='black'
        />
        <section className="video" id="life-circle">
          <div className="fui-container">
            <h1>什麼是生活圈？</h1>
            <h3 className="is-text-regular">看看遠傳生活圈如何改變你的生活</h3>
            <div className="d-flex justify-center">
              <img onClick={this.openVideoModal} src="/resources/cbu/img/video-placeholder-lifecircle@2x.jpg" alt="video" />
            </div>
          </div>
        </section>
        <AppPromotion
          bgImage='/resources/cbu/help-center/images/bg-all.png'
          title="加入遠傳生活圈，滿載好康"
          subtitle="立即下載App，享好康回饋！"
          id="goodie"
          description={`
            <p>
              以遠傳門號快速登入, 上網流量即時看，查帳單、繳帳單，一鍵完成！
              <br />
              還有許多用戶獨享驚喜優惠
            </p>`
          }
          icon='/resources/cbu/help-center/images/img-logo-fet.png'
          android={{
            qrCode: '/resources/cbu/help-center/images/qr-android.jpg',
            icon: '/resources/cbu/help-center/images/google-play.png',
            link: '/',
            title: '支援 Android 7 以上版本'
          }}
          ios={{
            qrCode: '/resources/cbu/help-center/images/qr-ios.png',
            icon: '/resources/cbu/help-center/images/app-store.png',
            link: '/',
            title: '支援 iOS 10 以上版本'
          }}
        />
        <BrandCarousel id="hiring" partner={this.state.partner} />
        <SectionAd3
          patternColor='green'
          title={`歡迎志同道合的你，申請成為遠傳生活圈合作夥伴，為您帶來更多人潮！`}
          action={[
            {
              text: '我有興趣',
              line: '#',
            },
          ]}
        />
        <VideoModal
          open={this.state.modalOpen}
          alterVideo={this.state.alterVideo}
          videoUrl={this.state.currentVideo}
          onClose={this.closeModal}
        />
      </main>
    );
  }
}

export default LifeCircleApp;