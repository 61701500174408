import React from 'react';
import { Grid } from '@material-ui/core';
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

import LifeCircleIndexMainCarousel from '../../components/partials/carousel/LifeCircleIndexMainCarousel';
import ToolsItemCarousel from '../../components/partials/carousel/ToolsItemCarousel';
import LifeCircleIndexAdCarousel from '../../components/partials/carousel/LifeCircleIndexAdCarousel';
import LifeCirclefCoinCarousel from '../../components/partials/card/LifeCirclefCoinCarousel';
import LifeCircleCouponCarousel from '../../components/partials/card/LifeCircleCouponCarousel';
import LifeCircleGoodsCarousel from '../../components/partials/card/LifeCircleGoodsCarousel';
import LifeCircleNewsCarousel from '../../components/partials/card/LifeCircleIndexNewsCarousel';

import Tab from '../../components/tab/Tab';
import TabPane from '../../components/tab/TabPane';
import NavContentTab2 from '../../components/partials/NavContentTab2';
// import SectionAd2 from '../../components/partials/SectionAd2';
import FooterAd from '../../components/ad/FooterAd';

import Button from '../../components/Button';

import * as Mock from '../../mock/LifeCircle';

class lifeCircle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0
    }
  }

  changeTab = (value) => {
    this.setState({
      currentTab: value
    })
  }

  render() {
    const { currentTab } = this.state;
    return (
      <main className="life-circle index">
        <Header />
        <NavAnchor
          pageTitle='遠傳生活圈'
          button={{
            text: '登入生活圈',
            link: '/',
          }}
        />

        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '遠傳生活圈', link: '' },
          ]}
          color='black'
        />

        <div className="main-banner">
          <TabPane name="open" active={currentTab === 0}>
            <LifeCircleIndexMainCarousel {...Mock.indexMainCarousel} />
          </TabPane>
          <TabPane name="movie" active={currentTab === 1}>
            <LifeCircleIndexMainCarousel {...Mock.indexMainCarousel} />
          </TabPane>
          <TabPane name="love" active={currentTab === 2}>
            <LifeCircleIndexMainCarousel {...Mock.indexMainCarousel} />
          </TabPane>
          <TabPane name="anti" active={currentTab === 3}>
            <LifeCircleIndexMainCarousel {...Mock.indexMainCarousel} />
          </TabPane>
          <TabPane name="health" active={currentTab === 4}>
            <LifeCircleIndexMainCarousel {...Mock.indexMainCarousel} />
          </TabPane>
        </div>


        <NavContentTab2 tabs={{
          name: '',
          icon: false,
          title: true,
          list: [
            {
              name: 'open',
              title: '開箱智慧生活',
              label: '開箱智慧生活',
              link: '',
              target: '',
            },
            {
              name: 'movie',
              title: '影劇話題',
              label: '影劇話題',
              link: '',
              target: '',
            },
            {
              name: 'love',
              title: '大師戀愛論',
              label: '大師戀愛論',
              link: '',
              target: '',
            },
            {
              name: 'anti',
              title: '防疫小確幸',
              label: '防疫小確幸',
              link: '',
              target: '',
            },
            {
              name: 'health',
              title: '健康食代',
              label: '健康食代',
              link: '',
              target: '',
            },
          ]
        }} onChange={e => this.changeTab(e)} />

        <ToolsItemCarousel {...Mock.indexToolsItem} />

        <LifeCircleIndexAdCarousel {...Mock.indexAdBannerCard} />

        <LifeCirclefCoinCarousel {...Mock.indexfCoinChangeCard} />

        <LifeCircleCouponCarousel {...Mock.indexCouponCard} />

        <LifeCircleGoodsCarousel {...Mock.indexGoodsCard} />

        <LifeCircleNewsCarousel {...Mock.indexNewsCard} />

        <LifeCircleNewsCarousel {...Mock.indexEntertainmentCard} />

        <FooterAd {...Mock.indexEventPromoData} />

      </main>
    );
  }
}

export default lifeCircle;
