import React from 'react';
import { Grid } from '@material-ui/core';
import PanelFigure2 from '../../components/panelContent/PanelFigure2'
import PropTypes from 'prop-types';

class Panel extends React.Component {
  render() {
    return (
      <div className="fui-container">
        <div
          className={`fui-panel-container ${this.props.className ? this.props.className : ''} ${this.props.initial ? 'no-shadow no-bg' : ''}`}
          style={this.props.bgColor ? ({ backgroundColor: this.props.bgColor }) : null}
        >
          <h4 className="mt-md-4 mb-2 is-font-bold">
            Panel-5
          </h4>
          {this.props.list.map((item, i) => {
            return (
              <PanelFigure2
                key={i}
                figures={item.figures}
                column={item.column}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
}

export default Panel;