import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import * as Action from './stores/actions';
import { connect } from 'react-redux';

import Loader from './components/Loader';
import EmmaService from './components/Emma';
import Cookie from './components/Cookie';
import GoTop from './components/GoTop';
import Header from './components/partials/header/Default';
import Footer from './components/partials/footer/Default';

// loading animation
import Error from './pages/Error';
import Sitemap from './pages/Sitemap';
import getPhoneBillPaySettingInitPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingInitPage';
import getPhoneBillPaySettingSmsValidateErrorPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingSmsValidateErrorPage';
import getPhoneBillPaySettingOpenPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingOpenPage';
import getPhoneBillPaySettingOpenResultPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingOpenResultPage';
import getPhoneBillPaySettingCheckResetPasswordPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingCheckResetPasswordPage';
import getPhoneBillPaySettingValidateRocidPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingValidateRocidPage';
import getPhoneBillPaySettingSelectAmountPage from './pages/phoneBillPaySettingController/getPhoneBillPaySettingSelectAmountPage';
import RateChangeInitPage from './pages/rateChangeController/RateChangeInitPage';
import getQueryContractsInfoPage from './pages/queryContractsInfoController/getQueryContractsInfoPage';
import getQueryRecordPage from './pages/queryRecordController/getQueryRecordPage';
import fillPrepaidDepositDataPage from './pages/prepaidDepositController/fillPrepaidDepositDataPage';
import prepaidRoamingBuyPage from './pages/prepaidRoamingBalanceController/prepaidRoamingBuyPage';
import prepaidRoamingByBankPage from './pages/prepaidRoamingBalanceController/prepaidRoamingByBankPage';
import prepaidRoamingPayInfo from './pages/prepaidRoamingBalanceController/prepaidRoamingPayInfo';
import prepaidRoamingByCreditPage from './pages/prepaidRoamingBalanceController/prepaidRoamingByCreditPage';
import serviceCoveragePage from './pages/help-center/serviceCoveragePage';

import prepaidExtraBuy from './pages/prepaidDepositController/prepaidExtraBuy';
import prepaidRapidDepositPage from './pages/prepaidDepositController/prepaidRapidDepositPage';
import prepaidDetailQueryPage from './pages/prepaidDepositController/prepaidDetailQueryPage';

import prepaidDepositPage from './pages/prepaidDepositController/prepaidDepositPage';
import depositCardPage from './pages/prepaidDepositController/depositCardPage';
import prepaidDepositSuccess from './pages/prepaidDepositController/prepaidDepositSuccess';
import prepaidDepositFail from './pages/prepaidDepositController/prepaidDepositFail';

import getBarcodePayInitPage from './pages/billBarcodePayController/getBarcodePayInitPage';
import fourGExtraBuyPage from './pages/four4GExtraBuyController/4GExtraBuyPage';
import fourGExtraBuySubmitPage from './pages/four4GExtraBuyController/4GExtraBuySubmitPage';
import ChangePassword from './pages/ChangePassword';
import payPage from './pages/payController/payPage';
import PayNothingPage from './pages/payController/PayNothingPage';
import payDepositPage from './pages/payController/payDepositPage';
import bankDepositPage from './pages/payController/bankDepositPage';
import billBankPayPage from './pages/billBankPayController/billBankPayPage';
import fillBillBankPayDataPage from './pages/billBankPayController/fillBillBankPayDataPage';
import billCreditcardPayPage from './pages/billCreditcardPayController/billCreditcardPayPage';
import fillBillCreditcardPayDataPage from './pages/billCreditcardPayController/fillBillCreditcardPayDataPage';

import HelpCenter from './pages/help-center/HelpCenter';
import HelpCenterContact from './pages/help-center/HelpCenterContact';
import Store from './pages/help-center/Store';
import TermsOfService from './pages/help-center/TermsOfService';
import Copyright from './pages/help-center/Copyright';
import AppList from './pages/help-center/AppList';

import FourPointFive from './pages/ratePlan/FourPointFiveG';
import RatePlan from './pages/ratePlan/RatePlan';
import Notice from './pages/ratePlan/Notice';
import AddValue from './pages/prepaid/AddValue';
import Application from './pages/prepaid/Application';
import Foreign from './pages/prepaid/Foreign';
import ForeignEng from './pages/prepaid/ForeignEng';
import Program from './pages/prepaid/Program';
import ProgramAmount from './pages/prepaid/ProgramAmount';
import ProgramDaily from './pages/prepaid/ProgramDaily';
import PrepaidAddValue from './pages/prepaid/PrepaidAddValue';

import Wifi from './pages/otherService/Wifi';
import WifiManual from './pages/otherService/WifiManual';
import OO7 from './pages/otherService/OO7';

import parkingfeePage from './pages/parkingfeeController/parkingfeePage';
import parkingfeeChangeRecordPage from './pages/parkingfeeController/parkingfeeChangeRecordPage';
import parkingfeeInfoPage from './pages/parkingfeeController/parkingfeeInfoPage';
import parkingfeeInitPage from './pages/parkingfeeController/parkingfeeInitPage';
import ResultPage from './pages/resultController/ResultPage';
import Discount from './pages/Discount';
import DiscountMuitipleMenu from './pages/DiscountMuitipleMenu';
import SearchResult from './pages/SearchResult';
import CbuIndex from './pages/CbuIndex';
import CbuIndex2 from './pages/CbuIndex2';

import NonLoginPrepaidDepositPage from './pages/prepaidDepositController/NonLoginPrepaidDepositPage';

import lifeCircle from './pages/lifeCircle/index';
import drama from './pages/lifeCircle/drama';
import slashie from './pages/lifeCircle/slashie';
import article from './pages/lifeCircle/article';
import ArticleList from './pages/lifeCircle/ArticleList';
import Curation from './pages/lifeCircle/curation';
import LifeCircleApp from './pages/lifeCircle/LifeCircleApp';

import OrderQuery from './pages/order/OrderQuery';
import OrderHistory from './pages/order/OrderHistory';
import OrderHistoryEmpty from './pages/order/OrderHistoryEmpty';
import FridayOrder from './pages/order/FridayOrder';
import FridayOrderEmpty from './pages/order/FridayOrderEmpty';

import Profile1 from './pages/profile/Profile1';
import Profile2 from './pages/profile/Profile2';
import ProfileLogin from './pages/profile/ProfileLogin';
import Profile from './pages/profile/Profile';
import Fcoin from './pages/profile/Fcoin';
import Estore from './pages/estore/Index';

import QueryIndex from './pages/billQuery/QueryIndex';
import QueryIndexNoData from './pages/billQuery/QueryIndexNoData';
import QueryEmpty from './pages/billQuery/QueryEmpty';
import QueryLogin from './pages/billQuery/QueryLogin';
import FeeDetail from './pages/billQuery/FeeDetail';
import CallDetail from './pages/billQuery/CallDetail';

import Exclusive from './pages/estore/Exclusive';
import FindProduct from './pages/estore/FindProduct';
import QueryPay from './pages/billQuery/QueryPay';
import QueryPayEmpty from './pages/billQuery/QueryPayEmpty';
import QueryPayNoBill from './pages/billQuery/QueryPayNoBill';
import QueryInternet from './pages/billQuery/QueryInternet';
import QueryInternetPackage from './pages/billQuery/QueryInternetPackage';
import Query4G from './pages/billQuery/Query4G';
import Query4GNoLimited from './pages/billQuery/Query4GNoLimited';

import MergeBill from './pages/merge-bill/MergeBill';
import MergeSuccess from './pages/merge-bill/MergeSuccess';
import OutOfService from './pages/merge-bill/OutOfService';
import BillNotifySetting from './pages/merge-bill/BillNotifySetting';
import FourGPurchaseRecord from './pages/4GPurchase/FourGPurchaseRecord';
import EBill from './pages/merge-bill/EBill';
import EBillOutOfService from './pages/merge-bill/EBillOutOfService';
import EBillSettingSuccess from './pages/merge-bill/EBillSettingSuccess';

import BillResend from './pages/billQuery/BillResend';
import BillResendSuccess from './pages/billQuery/BillResendSuccess';

import Receipt from './pages/billQuery/Receipt';
import AddressChange from './pages/bill-setting/AddressChange';
import AddressChangeSuccess from './pages/bill-setting/AddressChangeSuccess';
import EmailChange from './pages/bill-setting/EmailChange';
import BillNotifyRecord from './pages/bill-setting/BillNotifyRecord';
import EBillBenefit from './pages/billQuery/EBillBenefit';

import ContractRate from './pages/contract/ContractRate';
import Recommend from './pages/contract/Recommend';
import ReplaceCard from './pages/roamingConfigController/ReplaceCard';
import ResumeCall from './pages/roamingConfigController/ResumeCall';
import ResumeCallSuccess from './pages/roamingConfigController/Success';
import ResumeCallOutOfService from './pages/roamingConfigController/OutOfService';
import ResumeCallOutOfService02 from './pages/roamingConfigController/OutOfService02';
import PUKSearch from './pages/roamingConfigController/PUKSearch';
import VoiceBox from './pages/roamingConfigController/VoiceBox';

import internetSetting from './pages/internet/Setting';
import internetOutOfService from './pages/internet/OutOfService';
import filteringSetting from './pages/filtering/Setting';
import filteringSuccess from './pages/filtering/Success';

import RingSetting from './pages/ring/Setting';
import VoLTESetting from './pages/volte/Setting';
import VoLTESuccess from './pages/volte/Success';
import VoLTEOutOfService from './pages/volte/OutOfService';

import CallWating from './pages/phase3/CallWating';
import Transfer from './pages/phase3/Transfer';
import HiddenNumber from './pages/phase3/HiddenNumber';
import International from './pages/phase3/International';
import MissingCall from './pages/phase3/MissingCall';
import ResctictCall from './pages/phase3/ResctictCall';
import NumberQuery from './pages/phase3/NumberQuery';

import PromotionDevice from './pages/promotion/PromotionDevice';
import PromotionSupport from './pages/promotion/PromotionSupport';
import PromotionPeople from './pages/promotion/PromotionPeople';
import PromotionForm from './pages/promotion/PromotionForm';
import PromotionCoop from './pages/promotion/PromotionCoop';

import RoamingPlanSearch from './pages/roamingPlan/Search';
import RoamingPlanSearchApplied from './pages/roamingPlan/SearchApplied';
import RoamingPlanApply from './pages/roamingPlan/Apply';
import RoamingPlanApplyConfirm from './pages/roamingPlan/ApplyConfirm';
import RoamingPlanHistory from './pages/roamingPlan/History';
import RoamingPlanCancel from './pages/roamingPlan/Cancel';
import RoamingPlanAdd from './pages/roamingPlan/Add';

import Coop from './pages/Coop';

import Bulletin from './pages/Bulletin';

import FetnetApp from './pages/FetnetApp';

import HelpCenterMailbox from './pages/help-center/HelpCenterMailbox';
import HelpCenterMailboxSubmitSuccess from './pages/help-center/MailboxSubmitSuccess';
import HelpCenterMailboxSubmitFail from './pages/help-center/MailboxSubmitFail';

import OriginNumberRoamingIndex from './pages/originNumberRoaming/Index';
import OriginNumberRoamingPrepaid from './pages/originNumberRoaming/Prepaid';
import OriginNumberRoamingCountry from './pages/originNumberRoaming/Country';
import OriginNumberRoamingCountryEmpty from './pages/originNumberRoaming/CountryEmpty';
import OriginNumberRoamingAircraft from './pages/originNumberRoaming/Aircraft';
import OriginNumberRoamingRemind from './pages/originNumberRoaming/Remind';
import OriginNumberRoamingLocalCard from './pages/originNumberRoaming/LocalCard';
import OriginNumberRoamingBuyLocalCard from './pages/originNumberRoaming/BuyLocalCard';

import WatchIntro from './pages/watch';
import WatchPromote from './pages/watch/promote';

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import PromotionModule from './pages/promotion-module/promotionModule';
import PromotionModule2 from './pages/promotion-module/promotionModulePart2';
import PromotionModule3 from './pages/promotion-module/promotionModulePart3';
import PromotionModuleForm from './pages/promotion-module/promotionForm';

// window.history.pushState('', null, './');
let path = '';
// 如果按瀏覽器上一頁，則 reload 畫面
if (typeof window !== 'undefined') {
  window.addEventListener('popstate', function () {
    window.location.reload(true);
  });

  path = window.location.pathname;
}

const App = (state) => {
  const getEmma = () => {
    return {
      text: path.indexOf('/cbu/5g') > -1 ? '我有興趣' : null,
      link: getEmmaLink(),
      show: true,
      useEmmaAvatar: true,
    };
  };
  const getEmmaLink = () => {
    return path.indexOf('/cbu/5g') > -1 ? 'https://enterprise.fetnet.net/content/ebu/tw/5g/form/5G-cbu-form.html' : '';
  };
  const displayEmma = () => {
    let notDisplayGroup = [
      '/404',
      '/under-maintainance',
      '/ebu/news',
      '/ebu/news/newscontent',
      '/ebu/news/newscontentversion2',
      '/ebu/form/micro-form',
      '/ebu/form/medium-form',
      '/ebu/form/large-form',
      '/ebu/form/public-sector-form',
      '/ebu/form/cloud-form',
      '/ebu/form/smart-wifi-form',
      '/ebu/form/event-form',
      '/ebu/form/5g-form',
      '/ebu/form/5g-event',
      '/ebu/5g',
      '/broadcast',
      '/entrymainframepage',
      '/seednet',
      '/seednet-domain',
      '/office365',
      '/mrtg',
      '/ebu/business-login',
      '/error',
      '/module/section',
    ];
    let result = true;
    for (let i = 0; i < notDisplayGroup.length; i++) {
      if (notDisplayGroup[i] === path.toLowerCase()) {
        result = false;
        break;
      } else {
        result = true;
      }
    }
    // console.log(result);

    return result;
  };

  const lazyLoad = (e) => {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')';
    }
  };

  useEffect(() => {
    document.addEventListener('lazybeforeunveil', lazyLoad);
    return () => {
      document.removeEventListener('lazybeforeunveil', lazyLoad);
    };
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Loader />
      <Header />
      <ParallaxProvider>
        <Switch>
          <Route path='/' exact component={CbuIndex} />
          <Route path='/index' exact component={CbuIndex} />
          <Route path='/index2' exact component={CbuIndex2} />

          {/* 5.17.0_客服信箱 */}
          <Route path='/help-center/mailbox' exact component={HelpCenterMailbox} />
          <Route path='/help-center/submit-success' exact component={HelpCenterMailboxSubmitSuccess} />
          <Route path='/help-center/submit-fail' exact component={HelpCenterMailboxSubmitFail} />
          {/* 5.18.0_遠傳生活圈 */}
          <Route path='/fetnet-app' exact component={FetnetApp} />

          {/* 5.19.0_服務公告 */}
          <Route path='/bulletin/' exact component={Bulletin} />

          {/* 7.1.0 */}
          <Route path='/bill-query' exact component={QueryIndex} />
          <Route path='/bill-query-no-data' exact component={QueryIndexNoData} />
          <Route path='/bill-query/empty' exact component={QueryEmpty} />
          <Route path='/bill-query/login' exact component={QueryLogin} />
          <Route path='/bill-query/fee-detail' exact component={FeeDetail} />
          <Route path='/bill-query/call-detail' exact component={CallDetail} />
          {/* 7.1.1.1_帳單與用量_本期應繳金額_主頁-尚有欠款金額 */}
          <Route path='/bill-query/pay' exact component={QueryPay} />
          <Route path='/bill-query/pay-empty' exact component={QueryPayEmpty} />
          <Route path='/bill-query/no-bill' exact component={QueryPayNoBill} />
          {/* 7.1.4.1_帳單與用量_補寄帳單_主頁 */}
          <Route path='/resend-bill' exact component={BillResend} />
          <Route path='/resend-bill/success/' exact component={BillResendSuccess} />

          {/* 7.1.2.1_未出帳金額/上網用量_主頁 */}
          <Route path='/bill-query/internet' exact component={QueryInternet} />
          <Route path='/bill-query/internet-package' exact component={QueryInternetPackage} />
          {/* 7.1.5.1_帳單與用量_4G即時用量查詢_主頁 */}
          <Route path='/bill-query/4g-usage' exact component={Query4G} />
          <Route path='/bill-query/4g-usage-no-limited' exact component={Query4GNoLimited} />

          {/* 7.1.12.1_帳單與用量_「合併帳單」申請_step1 */}
          <Route path='/merge-bill/' exact component={MergeBill} />
          <Route path='/merge-success/' exact component={MergeSuccess} />
          <Route path='/merge-bill/out-of-service' exact component={OutOfService} />
          {/* 7.1.11.1_帳單與用量_帳務及繳費通知設定_未填寫 */}
          <Route path='/merge-bill/setting' exact component={BillNotifySetting} />
          {/* 7.1.7_帳單與用量_4G加購記錄查詢 */}
          <Route path='/4g-purchase-record' exact component={FourGPurchaseRecord} />
          {/* 7.1.6 */}
          <Route path='/four4GExtraBuyController/4GExtraBuyPage' exact component={fourGExtraBuyPage} />
          <Route path='/four4GExtraBuyController/4GExtraBuySubmitPage' exact component={fourGExtraBuySubmitPage} />

          {/* 7.1.8 */}
          <Route path='/phoneBillPaySettingController' exact component={getPhoneBillPaySettingInitPage} />
          <Route
            path='/phoneBillPaySettingController/getPhoneBillPaySettingCheckResetPasswordPage'
            exact
            component={getPhoneBillPaySettingCheckResetPasswordPage}
          />
          <Route
            path='/phoneBillPaySettingController/getPhoneBillPaySettingValidateRocidPage'
            exact
            component={getPhoneBillPaySettingValidateRocidPage}
          />
          <Route
            path='/phoneBillPaySettingController/getPhoneBillPaySettingSelectAmountPage'
            exact
            component={getPhoneBillPaySettingSelectAmountPage}
          />
          <Route
            path='/phoneBillPaySettingController/getPhoneBillPaySettingOpenPage'
            exact
            component={getPhoneBillPaySettingOpenPage}
          />
          <Route
            path='/phoneBillPaySettingController/getPhoneBillPaySettingSmsValidateErrorPage'
            exact
            component={getPhoneBillPaySettingSmsValidateErrorPage}
          />
          <Route
            path='/phoneBillPaySettingController/getPhoneBillPaySettingOpenResultPage'
            exact
            component={getPhoneBillPaySettingOpenResultPage}
          />

          {/* 7.1.9.1_帳單與用量_電信帳單開立發票_一般說明 */}
          <Route path='/receipt' exact component={Receipt} />
          {/* 7.1.10.1_帳單地址/電話變更_主頁 */}
          <Route path='/address-change' exact component={AddressChange} />
          <Route path='/bill-notify-record' exact component={BillNotifyRecord} />
          <Route path='/address-change/success' exact component={AddressChangeSuccess} />
          {/* 7.1.15.1_帳單與用量_電子帳單五大好處_繳費方便 */}
          <Route path='/ebill-benifit' exact component={EBillBenefit} />

          {/* 7.1.16.1_電子帳單申請_身份驗證 */}
          <Route path='/e-bill' exact component={EBill} />
          <Route path='/e-bill/out-of-service' exact component={EBillOutOfService} />
          <Route path='/e-bill/setting-success' exact component={EBillSettingSuccess} />

          {/* 7.1.17.1_帳單與用量_電子帳單變更_主頁 */}
          <Route path='/email-change' exact component={EmailChange} />

          {/* 7.3.0_合約費率_目前費率查詢 */}
          <Route path='/contract/rate' exact component={ContractRate} />

          {/* 7.3.1.1 */}
          <Route path='/rateChangeController/rateChangeInitPage' exact component={RateChangeInitPage} />

          {/* 7.3.2.1 */}
          <Route path='/contract/recommend' exact component={Recommend} />

          {/* 7.3.3 */}
          <Route
            path='/queryContractsInfoController/getQueryContractsInfoPage'
            exact
            component={getQueryContractsInfoPage}
          />

          {/* 7.4.1.1_漫遊與門號服務_遺失補卡 */}
          <Route path='/roamingConfigController/ReplaceCard' exact component={ReplaceCard} />
          {/* 7.4.2.1_漫遊與門號服務_恢復通話  */}
          <Route path='/roamingConfigController/ResumeCall' exact component={ResumeCall} />
          <Route path='/roamingConfigController/success' exact component={ResumeCallSuccess} />

          {/* 7.4.16 */}
          <Route path='/roamingPlan/Search' exact component={RoamingPlanSearch} />
          <Route path='/roamingPlan/SearchApplied' exact component={RoamingPlanSearchApplied} />
          <Route path='/roamingPlan/Apply' exact component={RoamingPlanApply} />
          <Route path='/roamingPlan/ApplyConfirm' exact component={RoamingPlanApplyConfirm} />
          <Route path='/roamingPlan/History' exact component={RoamingPlanHistory} />
          <Route path='/roamingPlan/Cancel' exact component={RoamingPlanCancel} />
          <Route path='/roamingPlan/Add' exact component={RoamingPlanAdd} />

          <Route path='/Coop' exact component={Coop} />

          {/* 7.4.2.4_漫遊與門號服務_恢復通話_復話完成_無權限 */}
          <Route path='/roamingConfigController/out-of-service' exact component={ResumeCallOutOfService} />
          {/* 7.4.2.5_漫遊與門號服務_恢復通話_復話完成_暫時無權限 */}
          <Route path='/roamingConfigController/out-of-service-02' exact component={ResumeCallOutOfService02} />
          {/* 7.4.3.1_漫遊與門號服務_PUK解卡密碼查詢_主頁 */}
          <Route path='/roamingConfigController/PUKSearch' exact component={PUKSearch} />
          {/* 7.4.4.1_漫遊與門號服務_語音信箱_主頁 */}
          <Route path='/roamingConfigController/VoiceBox' exact component={VoiceBox} />

          {/* 7.4.5_漫遊與門號服務_VoLTE */}
          <Route path='/VoLTE/setting' exact component={VoLTESetting} />
          <Route path='/VoLTE/success' exact component={VoLTESuccess} />
          <Route path='/VoLTE/out-of-service' exact component={VoLTEOutOfService} />

          {/* 7.4.6.1_漫遊與門號服務_來電答鈴_主頁 */}
          <Route path='/ring/setting' exact component={RingSetting} />

          {/* 7.4.7.1_漫遊與門號服務_來電過濾_主頁 */}
          <Route path='/filtering/setting' exact component={filteringSetting} />
          <Route path='/filtering/success' exact component={filteringSuccess} />

          {/* 7.4.8_漫遊與門號服務_行動上網 */}
          <Route path='/internet/setting' exact component={internetSetting} />
          <Route path='/internet/out-of-service' exact component={internetOutOfService} />

          {/* phase3 */}
          {/* 7.4.9_漫遊與門號服務_話中插接 */}
          <Route path='/call-waiting' exact component={CallWating} />
          {/* 7.4.10_漫遊與門號服務_指定轉接 */}
          <Route path='/transfer' exact component={Transfer} />
          {/* 7.4.11_漫遊與門號服務_隱藏號碼 */}
          <Route path='/hidden-number' exact component={HiddenNumber} />
          {/* 7.4.12_漫遊與門號服務_撥打國際電話 */}
          <Route path='/international' exact component={International} />
          {/* 7.4.13_漫遊與門號服務_未接提醒 */}
          <Route path='/missing-call' exact component={MissingCall} />
          {/* 7.4.14_漫遊與門號服務_撥打限制 */}
          <Route path='/restrict-call' exact component={ResctictCall} />
          {/* 7.4.15_漫遊與門號服務_電信特殊號碼查詢 */}
          <Route path='/number-query' exact component={NumberQuery} />

          {/* 7.5.6 停車費代收記錄查詢 */}
          <Route path='/parkingfeeController/parkingfeePage' exact component={parkingfeePage} />
          <Route path='/parkingfeeController/parkingfeeChangeRecordPage' exact component={parkingfeeChangeRecordPage} />

          {/* 7.6.0 */}
          <Route path='/prepaidDepositController/prepaidExtraBuy' exact component={prepaidExtraBuy} />
          <Route
            path='/prepaidDepositController/fillPrepaidDepositDataPage'
            exact
            component={fillPrepaidDepositDataPage}
          />
          <Route path='/queryRecordController/getQueryRecordPage' exact component={getQueryRecordPage} />
          {/* 7.6.8 */}
          <Route path='/prepaidDepositController/prepaidRapidDepositPage' exact component={prepaidRapidDepositPage} />

          {/* 7.6.1 */}
          <Route
            path='/prepaidRoamingBalanceController/prepaidRoamingBuyPage'
            exact
            component={prepaidRoamingBuyPage}
          />
          <Route
            path='/prepaidRoamingBalanceController/prepaidRoamingByBankPage'
            exact
            component={prepaidRoamingByBankPage}
          />
          <Route
            path='/prepaidRoamingBalanceController/prepaidRoamingByCreditPage'
            exact
            component={prepaidRoamingByCreditPage}
          />
          <Route
            path='/prepaidRoamingBalanceController/prepaidRoamingPayInfo'
            exact
            component={prepaidRoamingPayInfo}
          />

          {/* 7.6.2 */}
          <Route path='/prepaidDepositController/prepaidDetailQueryPage' exact component={prepaidDetailQueryPage} />

          {/* 7.6.3 */}
          <Route path='/prepaidDepositController/prepaidDepositPage' exact component={prepaidDepositPage} />
          <Route path='/prepaidDepositController/depositCardPage' exact component={depositCardPage} />
          <Route path='/prepaidDepositController/prepaidDepositSuccess' exact component={prepaidDepositSuccess} />
          <Route path='/prepaidDepositController/prepaidDepositFail' exact component={prepaidDepositFail} />

          {/* 7.6.8 預付卡專區_快速儲值(未登入狀態) */}
          <Route
            path='/prepaidDepositController/NonLoginPrepaidDepositPage'
            exact
            component={NonLoginPrepaidDepositPage}
          />

          {/* 7.2.1 */}
          <Route path='/billBarcodePayController/getBarcodePayInitPage' exact component={getBarcodePayInitPage} />

          {/* 5.9.0 */}
          <Route path='/help-center/serviceCoveragePage' exact component={serviceCoveragePage} />

          {/* 8.1.3 */}
          <Route path='/change-password' exact component={ChangePassword} />

          {/* 5.3 */}
          <Route path='/help-center' exact component={HelpCenter} />
          <Route path='/help-center/contact' exact component={HelpCenterContact} />
          <Route path='/help-center/store' exact component={Store} />
          <Route path='/help-center/terms-of-service/privacy' exact component={TermsOfService} />
          <Route path='/help-center/terms-of-service/copyright' exact component={Copyright} />
          <Route path='/help-center/terms-of-service/lifecircle' exact component={Copyright} />
          <Route path='/help-center/app-list' exact component={AppList} />
          {/* 10.1 */}
          <Route path='/rate-plan/4.5G' exact component={FourPointFive} />
          <Route path='/rate-plan/rate-plan' exact component={RatePlan} />
          <Route path='/rate-plan/notice' exact component={Notice} />

          {/* 10.2 - 10.3 */}
          <Route path='/prepaid/add-value' exact component={AddValue} />
          <Route path='/prepaid/application' exact component={Application} />
          <Route path='/prepaid/program' exact component={Program} />
          <Route path='/prepaid/foreign' exact component={Foreign} />
          <Route path='/prepaid/foreign-eng' exact component={ForeignEng} />
          <Route path='/prepaid/prepaid-add-value' exact component={PrepaidAddValue} />
          <Route path='/prepaid/prepaid-amount' exact component={ProgramAmount} />
          <Route path='/prepaid/prepaid-daily' exact component={ProgramDaily} />

          {/* 10.4 */}
          <Route path='/originNumberRoaming/Index' exact component={OriginNumberRoamingIndex} />
          <Route path='/originNumberRoaming/Prepaid' exact component={OriginNumberRoamingPrepaid} />
          <Route path='/originNumberRoaming/Country' exact component={OriginNumberRoamingCountry} />
          <Route path='/originNumberRoaming/CountryEmpty' exact component={OriginNumberRoamingCountryEmpty} />
          <Route path='/originNumberRoaming/Aircraft' exact component={OriginNumberRoamingAircraft} />
          <Route path='/originNumberRoaming/Remind' exact component={OriginNumberRoamingRemind} />

          {/* 10.5 */}
          <Route path='/originNumberRoaming/LocalCard' exact component={OriginNumberRoamingLocalCard} />
          <Route path='/originNumberRoaming/BuyLocalCard' exact component={OriginNumberRoamingBuyLocalCard} />

          {/* 10.6 - 10.8 */}
          <Route path='/otherService/Wifi' exact component={Wifi} />
          <Route path='/otherService/WifiManual' exact component={WifiManual} />
          <Route path='/otherService/007' exact component={OO7} />

          {/* 7.0 個人專區 */}
          <Route path='/profile' exact component={Profile} />
          {/* 7.0.0.1 */}
          <Route path='/profile1' exact component={Profile1} />
          <Route path='/profile2' exact component={Profile2} />
          <Route path='/fcoin' exact component={Fcoin} />
          <Route path='/profile-login' exact component={ProfileLogin} />

          {/* 7.2.0 */}
          <Route path='/payController/payPage' exact component={payPage} />
          <Route path='/payController/payNothingPage' exact component={PayNothingPage} />
          <Route path='/payController/payDepositPage' exact component={payDepositPage} />
          <Route path='/payController/bankDepositPage' exact component={bankDepositPage} />
          <Route path='/billBankPayController/billBankPayPage' exact component={billBankPayPage} />
          <Route path='/billBankPayController/fillBillBankPayDataPage' exact component={fillBillBankPayDataPage} />
          <Route path='/billCreditcardPayController/billCreditcardPayPage' exact component={billCreditcardPayPage} />
          <Route
            path='/billCreditcardPayController/fillBillCreditcardPayDataPage'
            exact
            component={fillBillCreditcardPayDataPage}
          />

          {/* 7.4.22 */}
          <Route path='/parkingfeeController/parkingfeeInfoPage' exact component={parkingfeeInfoPage} />
          <Route path='/parkingfeeController/parkingfeeInitPage' exact component={parkingfeeInitPage} />

          {/* 8.1 */}
          <Route path='/order/order-query' exact component={OrderQuery} />
          <Route path='/order/order-history' exact component={OrderHistory} />
          <Route path='/order/order-history-empty' exact component={OrderHistoryEmpty} />
          <Route path='/order/order-friday' exact component={FridayOrder} />
          <Route path='/order/order-friday-empty' exact component={FridayOrderEmpty} />

          {/* 9.2.0 */}
          <Route path='/discount' exact component={Discount} />
          <Route path='/discount-multiple-menu' exact component={DiscountMuitipleMenu} />
          <Route path='/resultController/ResultPage' exact component={ResultPage} />

          {/* 生活圈 */}
          <Route path='/lifeCircle/index' exact component={lifeCircle} />
          <Route path='/lifeCircle/drama' exact component={drama} />
          <Route path='/lifeCircle/slashie' exact component={slashie} />
          <Route path='/lifeCircle/article' exact component={article} />
          <Route path='/lifeCircle/curation' exact component={Curation} />
          {/* 4.4.1_生活圈_生活圈策展_搜尋 */}
          <Route path='/lifeCircle/article-list' exact component={ArticleList} />
          <Route path='/lifeCircle/app' exact component={LifeCircleApp} />

          {/* 1.5.* */}
          <Route path='/search/result' exact component={SearchResult} />

          {/* 12.0 */}
          {/* 12.0.0_行銷device */}
          <Route path='/promotion/device' exact component={PromotionDevice} />
          {/* 12.1.0_行銷device_支援服務 */}
          <Route path='/promotion/support' exact component={PromotionSupport} />
          {/* 12.2.0_行銷people */}
          <Route path='/promotion/people' exact component={PromotionPeople} />
          {/* 12.4.0_報名表單 */}
          <Route path='/promotion/form' exact component={PromotionForm} />
          {/* 12.6.0_異業合作 */}
          <Route path='/promotion/co-op' exact component={PromotionCoop} />
          {/* 12.7.0_愛講定位手錶_產品介紹 */}
          <Route path='/watch/intro' exact component={WatchIntro} />
          <Route path='/watch/promote' exact component={WatchPromote} />
          {/* sitemap */}
          <Route path='/sitemap' exact component={Sitemap} />

          <Route path='/estore' exact component={Estore} />
          <Route path='/estore/exclusive' exact component={Exclusive} />
          <Route path='/estore/exclusive/:plan' exact component={Exclusive} />
          <Route path='/estore/product' exact component={FindProduct} />
          <Route path='/estore/product/:tab' exact component={FindProduct} />
          <Route path='/estore/product/:tab/:category' exact component={FindProduct} />

          {/* promotion module */}
          <Route path='/promotion-module/' exact component={PromotionModule} />
          <Route path='/promotion-module/02' exact component={PromotionModule2} />
          <Route path='/promotion-module/03' exact component={PromotionModule3} />
          <Route path='/promotion-module/form' exact component={PromotionModuleForm} />

          <Route component={Error} />
        </Switch>
      </ParallaxProvider>
      <Footer adShow={true} />
      <GoTop />

      <EmmaService {...getEmma()} />
      <Cookie />
    </Router>
  );
};

const ReduxExample = connect((state) => state, { ...Action })(App);

export default ReduxExample;
