import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
class PromotionSupport extends Component {
  constructor(props) {
    super(props);
    this.panel = React.createRef();
    this.state = {
      currentTab: 0,
      tab1: {
        head: ['維修中心', '台大服務中心', '台南服務中心', '高雄建國服務中心', '花蓮中正服務中心', '中壢Nova服務中心', '台中Nova服務中心'],
        content: [
          ['地址', '電話', '營業時間'],
          ['報案專線', '內政部'],
          ['婦幼專線', '內政部'],
          ['報時專線', '經濟部'],
          ['海巡服務專線', '行政院海岸巡防署'],
          ['消防專線', '內政部'],
          ['防詐騙專線', '內政部'],
          ['國語氣象預報專線', '中央氣象局'],
          ['閩、客、英語氣象預報', '中央氣象局'],
          ['國道路況專線', '國道公路警察局']
        ]
      },
      tab2: {
        head: ['19XY', '1900', '1911', '1912', '1922', '1950', '1955', '1957', '1968'],
        content: [
          ['敘述', '申請人'],
          ['便民服務專線', '內政部'],
          ['申告緊急停電事故搶修及獲知停電訊息服務專線', '內政部'],
          ['緊急事故報修正油氣安全與油價諮詢	', '經濟部'],
          ['防疫專線', '行政院海岸巡防署'],
          ['全國性消費者服務專線	', '內政部'],
          ['外籍勞工24小時諮詢保護專線', '內政部'],
          ['1957福利諮詢專線', '中央氣象局'],
          ['智慧型國道路況語音查詢系統服務專線	', '中央氣象局']
        ]
      }
    }
  }
  changeTab = (val) => {
    this.setState({
      currentTab: val
    })
  }
  render() {
    return (
      <main className="position-relative">
        <Header />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '寵i獻禮', link: '/' },
            { text: 'iPhone支援服務', link: '/' }
          ]}
          color='white'
        />
        <SectionBanner2
          position='right'
          className="is-360"
          image={{
            md: '/resources/cbu/img/ebu-promotion-support-banner-1440-x-460@2x.jpg',
            sm: '/resources/cbu/img/ebu-promotion-support-banner-750-x-660@2x.jpg',
          }}
          title='<div class="mt-md-5 mt-4">iPhone支援服務</div>'
          action={null}
        />
        <section className=''>
          <div className='tab-content'>
            <div className="fui-container">
              <h2 className="mb-4">
                iPhone支援
              </h2>
              <div className="paper mt-1 mb-0">
                <h3 className='mt-md-4 mb-3'>如何找到支援？</h3>
                <p className="body mb-md-9 mb-4">你可以透過全省 <a href="#" className="info-link">遠傳門市</a>送修，物流時效約3-5個工作天或客戶親送德誼維修中心。<br />德誼維修中心僅能維修Apple產品，非Apple產品請勿送修。</p>
                <h3 className='mb-3'>德誼維修中心服務據點</h3>
                <div className="compareTable stick-to-right mt-md-0 mt-3 mb-2">
                  <div className="tableLeft">
                    <div className="tableHead">
                      <table>
                        <tbody>
                          <tr >
                            <td className="align-center is-text-bold is-bg-lightgray70">維修中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">台大服務中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">台南服務中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">高雄建國服務中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">花蓮中正服務中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">中壢Nova服務中心</td>
                          </tr>
                          <tr className='is-bg-white'>
                            <td className="align-left pl-3">台中Nova服務中心</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tableRight">
                    <div className="tableBody">
                      <div className="spaceBox">
                        <table className="compareList">
                          <tbody>
                            <tr className='is-bg-lightgray70'>
                              <td className="is-text-bold">
                                地址
                              </td>
                              <td className="is-text-bold">
                                電話
                              </td>
                              <td className="is-text-bold">
                                營業時間
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                台北市中正區羅斯福路三段292號3樓
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                02-2369-0958
                              </td>
                              <td rowSpan="4" className="is-text-regular align-left pl-3">
                                週一~週六 12:00-21:00<br />
                                週六僅收件、取件無現場維修。
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                台南市中西區民族路二段231號2樓
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                06-222-8497
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                高雄市三民區建國二路150號
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                07-236-1625
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                花蓮市中正路511號3樓
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                03-8310-735
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                中壢市中正區389號3樓
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                03-491-9415
                              </td>
                              <td rowSpan="2" className="is-text-regular align-left pl-3">
                                週一~週日 11:00-21:00<br />
                                週六、週日僅收件、取件無現場維修。
                              </td>
                            </tr>
                            <tr className='is-bg-white'>
                              <td className="is-text-regular align-left pl-3">
                                台中市西區英才路506號2樓206櫃位
                              </td>
                              <td className="is-text-regular align-left pl-3 is-text-accent">
                                04-2329-1708
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="subtitle-2 mb-mb-9 mb-4">送修前請確認：<br />
                  1. 「關閉尋找我的iPhone」功能，以免無法進行維修。<br />
                  2. 請務必將iPhone透過iTunes進行備份，將聯絡人、照片、電子郵件帳號設定等資料存在iTunes中。你也可透過 iCloud來進行備份（需要Wi-Fi連線）。
                </p>
                <h3 className='mt-4 mb-3'>如何替舊換新？</h3>
                <p className="body mb-md-4 mb-0">透過<a href="#" className="info-link">遠傳估價買回APP</a>，替手機檢測，手機價值立刻呈現。</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}
export default PromotionSupport;