import React from 'react';
import Dropzone from 'react-dropzone';
import Button from '../Button';
import PropTypes from 'prop-types';
require("../../sass/module/_imageUpload.sass")

// for profile picture
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      files: this.props.files,
    };
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    if (!!this.state.files[0]) this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.files !== this.props.files)
      // console.log(!!this.props.files[0]);
      this.setState({
        hasError: !!this.props.files[0],
        files: this.props.files,
      });
  };

  onDrop = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      const message = 'Please submit valid file type';
      this.setState({ warningMsg: message });
    } else {
      this.props.addFile(accepted);
      this.setState({ warningMsg: '' });

      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
      blobPromise.then((value) => {
        // console.log(value);
      });
    }
  };
  showImage = (files) => {
    return (
      <div className="img-wrap"><img src={files.preview} alt={files.name} /></div>
    )
  }
  render() {
    const { files } = this.props;
    return (
      <div className="fui-dropzone">
        <Dropzone onDrop={this.onDrop} multiple={false} accept='.jpg, .png, .jpeg'>
          {({ getRootProps, getInputProps }) => (
            files[0] === null ? <div {...getRootProps()}>
              <input {...getInputProps()} name={this.props.name} accept='.jpg, .png, .jpeg' />
              {!!files[0] ? (
                <div className='upload-preview'>
                  {this.state.hasError ? (
                    <span className='error'>
                      <i className='icon-error'></i>
                      <span className='text'>{'圖片無法辨識，可能是因為反光、陰影、圖片歪斜遮住了證件內容。'}</span>
                    </span>
                  ) : (
                      <span className='check'>
                        <i className='icon-check'></i>
                      </span>
                    )}
                  <button className='fui-button is-secondary' onClick={this.triggerModal}>刪除檔案</button>
                </div>
              ) : (
                  <div className='upload-description'>
                    <img src={'/resources/common/images/upload@2x.png'} width='120' alt='upload' />
                    <p>拖曳檔案到灰色區域或點擊這裡選擇檔案</p>
                    <Button btnStyle='secondary'>上傳檔案</Button>
                  </div>
                )}
            </div> : <div>
                <div className='upload-preview'>
                  <span className='check'>
                    <i className='icon-check'></i>
                  </span>
                  {files[0].name.match(/\.(jpg|jpeg|png)\b/i) != null ? this.showImage(files[0]) : null}
                  <p style={{ wordBreak: 'break-all' }} className='m-0 is-text-gray100' style={{ height: 56 }}>{files[0].name} | {(files[0].size / 1024).toFixed(2)} KB</p>
                  <Button onClick={() => { this.props.deleteFile() }} btnStyle='secondary'>刪除檔案</Button>
                </div>
              </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  name: PropTypes.string,
  files: PropTypes.array,
  addFile: PropTypes.func,
};

export default ImageUpload;
