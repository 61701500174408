import React from 'react';
import { Grid } from '@material-ui/core';
import NavAnchor from '../../components/partials/NavAnchor';
import TabPane from '../../components/tab/TabPane';
import NavTab from '../../components/partials/NavTab';
import Breadcrumb from '../../components/Breadcrumb';
import GroupExtraLinkCard from '../../components/partials/card/LifeCircleGroupExtraLinkCard';
import ExtraLinkCard from '../../components/partials/card/LifeCircleExtraLinkCard.js';
import SelectedArticle from '../../components/SelectedArticle';
import SectionAd5 from '../../components/ad/SectionAd5';
import LoadMore from '../../components/LoadMore';
import SectionAd3 from '../../components/partials/SectionAd3';
import HasMoreTab from '../../components/tab/HasMoreTab';
import FooterAd from '../../components/ad/FooterAd';
import Hashtags from '../../components/LifeCircleHashtags'
// import SectionAd2 from '../../components/partials/SectionAd2';
// import Subscribe from '../../components/partials/Subscribe';
//Data
import * as CurationMock from '../../mock/LifeCircle';
let currentTab = 0;
let hashtags = [
  {
    text: '心5G',
    link: '#123'
  },
  {
    text: '小資享受',
    link: '#234'
  },
  {
    text: '日常瘦身',
    link: '#'
  },
  {
    text: '懶人料理',
    link: '#'
  },
  {
    text: '3C開箱',
    link: '#'
  },
  {
    text: '親子手作樂趣多多',
    link: '#'
  },
  {
    text: '中秋節禮盒必買',
    link: '#'
  },
  {
    text: '輕旅行',
    link: '#'
  },
  {
    text: '手遊',
    link: '#'
  },
  {
    text: '金馬獎',
    link: '#'
  },
  {
    text: '黑科技',
    link: '#'
  },
  {
    text: '像極了愛情',
    link: '#'
  },
]
const Curation = props => {

  const [curationArticle, setCurationArticle] = React.useState(CurationMock.curationArticle[currentTab].list);
  const [currentArticleLoadMore, setCurrentArticleLoadMore] = React.useState(CurationMock.curationArticle[currentTab].loadMore);
  // const [mainTab, setMainTab] = React.useState(0);
  const [isEn, setIsEn] = React.useState(typeof window !== 'undefined' && window.$isEn);

  // React.useEffect(() => {
  //   setIsEn(typeof window !== 'undefined' && window.$isEn);
  // }, []);

  let tabs = [];
  let dropdown = [];
  let tabLength = 5;
  let target = CurationMock.curationTabs.tabs.list;
  for (let i = 0; i < tabLength; i++) {
    tabs.push(target[i]);
  }
  for (let i = tabLength; i < target.length; i++) {
    dropdown.push(target[i]);
  }
  const handleChange = e => {
    currentTab = e.index;
    // console.log(`e`, e);
    setCurrentArticleLoadMore(CurationMock.curationArticle[currentTab].loadMore);
    setCurationArticle(CurationMock.curationArticle[currentTab].list);
  };
  const loadMore = () => {
    let result = CurationMock.curationLoadMoreData;
    let newtech = [...curationArticle, ...result];
    setCurrentArticleLoadMore(false);
    setCurationArticle(newtech);
  };
  return (
    <main className="life-circle tech curation">
      <NavAnchor
        pageTitle='遠傳生活圈'
        // button={[
        //   {
        //     text: '回生活圈',
        //     link: '/',
        //   },
        //   {
        //     text: '回生活圈1',
        //     link: '/',
        //   }
        // ]}
        fcoin={450}
      />

      <Breadcrumb
        breadcrumb={[
          { text: '首頁', link: '/' },
          { text: '遠傳生活圈', link: '/' },
          { text: '生活圈策展', link: '' },
        ]}
        color='black'
      />

      {/* <section id='tech-content' className='fui-container top-section'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <ExtraLinkCard content={CurationMock.curationContent.mainContent} cardType='default' />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <ExtraLinkCard content={CurationMock.curationContent.sideContent[0]} cardType='horizontal' />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <ExtraLinkCard content={CurationMock.curationContent.sideContent[1]} cardType='horizontal' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section> */}
      <Hashtags
        title="生活圈精選主題"
        content="多元生活觀點，科技、生活、旅行、時尚、理財、購物、音樂、電影等專題，最新趨勢與生活美學的揉合，連結簡單生活的初心。"
        tags={hashtags}
      />

      <section className='info'>
        <div id='tech-article' className='fui-container top-section'>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={8}>
              <HasMoreTab
                tabs={tabs}
                dropdown={dropdown}
                onChange={handleChange}
                defaultSelected={{
                  name: 'tab-1',
                  label: '開箱智慧生活',
                  value: '置智慧生活',
                  text: '開箱智慧生活',
                  index: 0,
                }}
              />
              {/* <Tab {...CurationMock.tabs.tabs} onChange={handleChange} /> */}
              <div className='tech-article-wrapper'>
                <GroupExtraLinkCard article={curationArticle} />
                <LoadMore
                  click={loadMore}
                  load={currentArticleLoadMore}
                  moreLabel={isEn ? 'More' : '展開看更多'}
                  noMoreLabel={isEn ? 'No More Article' : '沒有更多文章'}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} className="pb-md-0">
              <SelectedArticle article={CurationMock.selectedArticle.content} title={CurationMock.selectedArticle.title} />
              <div className='promotion-container'>
                <SectionAd5 {...CurationMock.curationPromotion} />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <FooterAd {...CurationMock.indexEventPromoData} />

    </main>
  );
};
export default Curation;
