import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
class OrderPaper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: null
        }
    }
    collapseOpen = () => {
        this.setState({
            currentOpen: !this.state.currentOpen
        })
    }
    getDescription = () => {
        if (!!this.props.list.price) {
            return (
                <div className="d-flex flex-column has-price w-100">
                    <h3 className="">{this.props.list.productName}</h3>
                    <h5 className="">{this.props.list.description}</h5>
                    <h5 className="is-text-coral100 m-0">${this.props.list.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h5>
                </div>
            )
        } else {
            return (
                <div className="d-flex flex-column w-100">
                    <h3 className="mb-0">{this.props.list.productName}</h3>
                    <h5 className="m-0">{this.props.list.description}</h5>
                </div>
            )
        }
    }
    render() {
        return (
            <div className="paper">
                <div className="d-inline">
                    <div className="d-inline-flex">
                        <p className="subtitle is-text-darkgray50 d-inline-block mr-4">訂單編號 : {this.props.list.id}</p>
                        <p className="subtitle is-text-darkgray50 d-inline-block">下單時間 : {this.props.list.timeStamp}</p>
                    </div>
                    <a href={this.props.list.link} target={this.props.list.linkTarget} className="fui-button is-small is-secondary m-0 float-right">
                        <span className='text'>查看明細</span>
                    </a>
                </div>
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={12} lg={6} className="d-flex pb-0">
                        <div className="img-wrapper">
                            <img src={this.props.list.img} alt={this.props.imgAlt} />
                        </div>
                        {this.getDescription()}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <h3 className="is-text-darkgray50">訂單狀態：{this.props.list.orderStatus}</h3>
                        <div className={`status-list ${this.state.currentOpen ? 'd-flex' : 'd-md-flex d-none'}`}>
                            <div className="d-flex justify-between">
                                {this.props.list.arr.map((item, j) => {
                                    let active = item == this.props.list.orderStatus;
                                    let temp = item.split('');
                                    if (item.length == 4 || item.length == 5) {
                                        temp.splice(2, 0, '<br/>');
                                        return (
                                            <div className={`subtitle ${active ? 'active' : ''}`} key={item + j} dangerouslySetInnerHTML={{ __html: temp.join('') }} />
                                        )
                                    } else {
                                        return (
                                            <div className={`subtitle ${active ? 'active' : ''}`} key={item + j}>
                                                {item}
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-block d-md-none py-0 pb-0">
                        <a className="fui-button is-text align-left" onClick={() => { this.collapseOpen() }}>
                            {this.state.currentOpen ? '收合詳細資訊' : '看詳細訂單狀態'}
                            <i className={this.state.currentOpen ? 'icon-minus' : 'icon-plus'} />
                        </a>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
OrderPaper.propTypes = {
    list: PropTypes.shape({
        productName: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.string,
        timeStampid: PropTypes.string,
        orderStatus: PropTypes.string,
        link: PropTypes.string,
        linkTarget: PropTypes.string,
        img: PropTypes.string,
        imgAlt: PropTypes.string,
        arr: PropTypes.array,
    })
}
export default OrderPaper;