import React from 'react';
import Slider from 'react-slick';
import ArrowLeftWhite from '../../animateArrow/ArrowLeftWhite';
import ArrowRightWhite from '../../animateArrow/ArrowRightWhite';
import Card from '../../card/ProductListCard';
import PropTypes from 'prop-types';

const SectionRoamingProductCardCarousel = (props) => {
  const [setting] = React.useState({
    dots: false,
    infinite: false,
    slidesToShow: 3,
    draggable: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 2,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ],
  });


  return (
    <section className={`fui-horzonal-cards section-product-card section-roaming-product-card ${!!props.className ? props.className : ''}`}>
      <div className='section-bg'>
        <ArrowLeftWhite />
        <ArrowRightWhite />
      </div>
      {
        props.title ? 
        <h2 className='section-title align-left'>{props.title}</h2>
        : null
      }
      <Slider {...setting} className='horizontal-cards'>
        {props.cards.map((card, idx) => (
          <Card key={idx} {...card} />
        ))}
      </Slider>
    </section>
  );
};

SectionRoamingProductCardCarousel.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.array,
};

export default SectionRoamingProductCardCarousel;