import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import CollapsePaper from '../../components/collapse/CollapsePaper';
import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

let bill1 = {
  detailLink: '#123',
  date: '2020年04月',
  period: '2020/03/20 至 2020/04/19',
  phoneNumber: '0916-814-747',
  endDate: '2020/04/20',
  fee: [
    {
      title: '電信費用',
      fee: 0,
      isRed: false
    },
    {
      title: '小額代收',
      fee: 120,
      isRed: false
    },
    {
      title: '本期新增總額',
      fee: 120,
      isRed: true
    }
  ],
  detail: {
    title: '特殊通路限定方案_新絕配199',
    table1: {
      sum: {
        title: '電信費用新增總額',
        content: '0元'
      },
      arr: [
        {
          head: {
            title: '月租費',
            content: '1,399.00元'
          },
          body: [
            {
              title: '4G新絕配199月租費',
              content: '1,399.00元',
              tooltip: '此處上網用量是指您該月份帳單內所有門號的上網用量加總，各門號使用明細請至「帳單明細」查詢。'
            },
          ],
        },
        {
          head: {
            title: '加值服務',
            content: '0.00元'
          },
          body: [
            {
              title: 'MVPN月租費60元',
              content: '60.00元',
              tooltip: '此處上網用量是指您該月份帳單內所有門號的上網用量加總，各門號使用明細請至「帳單明細」查詢。'
            },
            {
              title: '遠傳VoLTE語音服務月租費',
              content: '30.00元'
            },
            {
              title: 'MVPN月租費優惠100%',
              content: '-60.00元',
              tooltip: '此處上網用量是指您該月份帳單內所有門號的上網用量加總，各門號使用明細請至「帳單明細」查詢。'
            },
            {
              title: '遠傳VoLTE語音服務月租費優惠100%',
              content: '-30.00元'
            },
          ],
        },
        {
          head: {
            title: '國內通話費',
            content: '0.00元'
          },
          body: [
            {
              title: '項目名稱',
              content: '800元'
            },
            {
              title: '項目名稱優惠',
              content: '-800元'
            }
          ],
        },
        {
          head: {
            title: '國際服務',
            content: '0.00元'
          },
          body: [
            {
              title: '項目名稱',
              content: '800元'
            },
            {
              title: '項目名稱優惠',
              content: '-800元'
            }
          ],
        },
        {
          head: {
            title: '其他優惠及費用',
            content: '0.00元'
          },
          body: [
            {
              title: '4G員工門號優惠1600元',
              content: '-1,399.00元'
            }
          ],
        },
        {
          head: {
            title: 'Friday服務',
            content: '0.00元'
          },
          body: [
            {
              title: '項目名稱',
              content: '800元'
            },
            {
              title: '項目名稱優惠',
              content: '-800元'
            }
          ],
        },
      ]
    },
    table2: {
      sum: {
        title: '小額代收新增總額',
        content: '120元'
      },
      arr: [
        {
          head: {
            title: '項目',
            content: '金額'
          },
          body: [
            {
              title: '小額代收服務費用',
              content: '120.00元'
            },
          ],
        }
      ]
    }
  },
  collapseInfo: {
    info: {
      head: {
        title: '上網優惠及用量資訊',
        content: '可使用量'
      },
      body: [
        {
          title: '員工專用_4G上網吃到飽',
          content: '上網吃到飽'
        },
        {
          title: '本期使用量',
          content: '79.87GB'
        },
      ],
    },
    detail: {
      head: {
        title: '通話項目',
        content: '本期使用'
      },
      body: [
        {
          title: '網內',
          content: '8分7秒'
        },
        {
          title: '他網',
          content: '2分54秒'
        },
        {
          title: '市話',
          content: '27秒'
        }
      ],
    }
  }
}
let bill2 = {
  detailLink: '#123',
  date: '2020年05月',
  period: '2020/04/20 至 2020/05/19',
  phoneNumber: '0916-814-747',
  endDate: '2020/05/20',
  fee: [
    {
      title: '電信費用',
      fee: 1000,
      isRed: false
    },
    {
      title: '小額代收',
      fee: 120,
      isRed: false
    },
    {
      title: '本期新增總額',
      fee: 120,
      isRed: true
    }
  ],
  detail: {
    title: '特殊通路限定方案_新絕配199',
    table1: [
      {
        head: {
          title: '月租費',
          content: '1,399.00元'
        },
        body: [
          {
            title: '4G新絕配199月租費',
            content: '1,399.00元',
            tooltip: '此處上網用量是指您該月份帳單內所有門號的上網用量加總，各門號使用明細請至「帳單明細」查詢。'
          },
        ],
      },
      {
        head: {
          title: '加值服務',
          content: '0.00元'
        },
        body: [
          {
            title: 'MVPN月租費60元',
            content: '60.00元',
            tooltip: '此處上網用量是指您該月份帳單內所有門號的上網用量加總，各門號使用明細請至「帳單明細」查詢。'
          },
          {
            title: '遠傳VoLTE語音服務月租費',
            content: '30.00元'
          },
          {
            title: 'MVPN月租費優惠100%',
            content: '-60.00元',
            tooltip: '此處上網用量是指您該月份帳單內所有門號的上網用量加總，各門號使用明細請至「帳單明細」查詢。'
          },
          {
            title: '遠傳VoLTE語音服務月租費優惠100%',
            content: '-30.00元'
          },
        ],
      },
      {
        head: {
          title: '國內通話費',
          content: '0.00元'
        },
        body: [
          {
            title: '項目名稱',
            content: '800元'
          },
          {
            title: '項目名稱優惠',
            content: '-800元'
          }
        ],
      },
      {
        head: {
          title: '國際服務',
          content: '0.00元'
        },
        body: [
          {
            title: '項目名稱',
            content: '800元'
          },
          {
            title: '項目名稱優惠',
            content: '-800元'
          }
        ],
      },
      {
        head: {
          title: '其他優惠及費用',
          content: '0.00元'
        },
        body: [
          {
            title: '4G員工門號優惠1600元',
            content: '-1,399.00元'
          }
        ],
      },
      {
        head: {
          title: 'Friday服務',
          content: '0.00元'
        },
        body: [
          {
            title: '項目名稱',
            content: '800元'
          },
          {
            title: '項目名稱優惠',
            content: '-800元'
          }
        ],
      },
    ],
    sum: {
      title: '電信費用新增總額',
      content: '0元'
    },
  },
  collapseInfo: `<small>
                      『4G絕配1399』：網內及他網每秒0.07元、市話每秒0.105元。<br/>
※國際漫遊語音及數據費率，依各國漫遊業者訂定之費用收取，詳細請參閱官網www.fetnet.net。<br/>
※若您出國前已開啟門號的數據漫遊服務，於國外漫遊時，僅在手機上關閉行動數據或數據漫遊服務功能，仍可能因手機本身資料自動更新等因素產生些微傳輸量及數據漫遊費用。<br/>
</small>
                      <table class="text-wrap bill-table table-fixed mt-0 mb-4">
                        <tbody>
                          <tr>
                          <th class="align-center-md align-left pl-md-5 pl-1 ">上網優惠及用量資訊</th>
                            <th class=" pr-md-5 pr-0">可使用量</th>
                          </tr>
                          <tr>
                            <td class="align-center-md align-left pl-md-5 pl-1 ">
                              員工專用_4G上網吃到飽
                            </td>
                            <td class=" pr-md-5 pr-0">上網吃到飽</td>
                          </tr>
                          <tr>
                            <td class="align-center-md align-left pl-md-5 pl-1 ">本期使用量</td>
                            <td class=" pr-md-5 pr-0">79.87GB</td>
                          </tr>
                        </tbody>
                    </table>
                    <small>專案優惠明細：<br/>
                    4G絕配1399網內及他網通話優惠170分，市話通話優惠90分鐘*30個月(優惠至109/06/19)。</small>
                    <table class="text-wrap bill-table mt-0 mb-2">
                        <tbody>
                          <tr>
                            <th class="pl-md-5 pl-1 ">通話項目</th>
                            <th class=" pr-md-5 pr-0">本期使用</th>
                          </tr>
                          <tr>
                            <td class="pl-md-5 pl-1 ">
                              網內
                            </td>
                            <td class=" pr-md-5 pr-0">8分7秒</td>
                          </tr>
                          <tr>
                            <td class="pl-md-5 pl-1 ">他網</td>
                            <td class=" pr-md-5 pr-0">2分54秒</td>
                          </tr>
                          <tr>
                            <td class="pl-md-5 pl-1 ">市話</td>
                            <td class=" pr-md-5 pr-0">27秒</td>
                          </tr>
                        </tbody>
                    </table>`
}

class FeeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      canSubmit: false,
      form: {
        id: { value: '', required: true },
      },
      selectPeriod: { value: '1', text: '2020/04月份' },
      periodOption: [
        { value: '1', text: '2020/04月份' },
        { value: '2', text: '2020/05月份' }
      ],
      currentBill: bill1,
      contentHeight: 0,
    }
    this.body = React.createRef();
    this.collapseOpen = this.collapseOpen.bind(this)
    this.setContentHeight = this.setContentHeight.bind(this)
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }

  selectPeriod = (val) => {
    this.setState({
      selectPeriod: val
    })
    if (val.value === '1') {
      this.setState({
        currentBill: bill1
      })
    } else {
      this.setState({
        currentBill: bill2
      })
    }
    // console.log(`val`, val);
  }
  changeTab = () => {
    // console.log('changed tab');

  }

  getTable = (table) => {
    if (!!table) {
      return (
        <table className="text-wrap bill-table table-fixed mb-4">
          {table.arr.map((item) => {
            return (
              <tbody key={item.head.title}>
                <tr>
                  <th className="align-left pl-md-5 pl-1">{item.head.title}</th>
                  <th className="align-right  pr-md-5 pr-1">{item.head.content}</th>
                </tr>

                {item.body.map((bodyItem) => {
                  return (
                    <tr key={bodyItem.title}>
                      <td className="align-left pl-md-5 pl-1">
                        {bodyItem.title}
                        {!!bodyItem.tooltip ? <Tooltip
                          parentNode={null}
                          content={<i className='icon-information is-text-darkgray100 ml-1 d-flex'></i>}
                          trigger="click"
                          tooltip={bodyItem.tooltip}
                        /> : null}

                      </td>
                      <td className="align-right  pr-md-5 pr-1">{bodyItem.content}</td>
                    </tr>
                  )
                })}
              </tbody>
            )
          })}
          <tbody>
            <tr>
              <td colSpan="2" className="px-md-5 px-2 py-4 w-100">
                <div className="d-flex justify-between flex-align-center">
                  <h6 className='m-0 is-text-black100'>{table.sum.title}</h6>
                  <h2 className="is-text-error m-0">{table.sum.content}</h2>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )
    } else {
      return
    }
  }

  setContentHeight() {
    this.setState({
      contentHeight: this.body.current.clientHeight
    })
  }

  collapseOpen() {
    this.setContentHeight()
    this.setState({
      open: !this.state.open
    })

    this.forceUpdate();
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="帳單查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <NavContentTab1
          default={0}
          tabs={{
            name: 'page-tab',
            list: [
              { label: '費用項目' },
              { label: '通話明細' },
            ]
          }}
          onChange={this.changeTab}
        />

        <section className="query-index pb-0">
          <div className="fui-container">
            <Dropdown
              className="is-button d-block d-md-none"
              list={[
                { value: '', text: '2020/04月份' },
                { value: '', text: '2020/05月份' },
              ]}
              label={this.state.selectPeriod.text}
              arrow={true}
              hasCheck={false}
              onChange={(item) => this.selectPeriod(item)}
            />
            <h2 className="mb-0">{this.state.currentBill.date}帳單</h2>
            <div className="d-flex justify-between flex-align-center">
              <div className="body is-text-darkgray50">
                費用計算期間：{this.state.currentBill.period}
              </div>
              <Dropdown
                className="is-button d-none d-md-block"
                list={this.state.periodOption}
                label={this.state.selectPeriod.text}
                arrow={true}
                hasCheck={false}
                onChange={(item) => this.selectPeriod(item)}
              />

            </div>
            <div className="paper mt-md-4 mt-2 mb-0">
              <div className="d-flex justify-between flex-align-center mt-md-4 mt-2 mb-md-4 mb-2">
                <h4 className='m-0'>帳務資訊</h4>
                <Link className='fui-button is-secondary is-small mb-0 mr-0' to={this.state.currentBill.detailLink}>
                  <span className='text'>04月明細帳單下載</span>
                </Link>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      門號
                      </h5>
                    <h2 className='mb-0'>{this.state.currentBill.phoneNumber}</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      結帳日期
                    </h5>
                    <h2 className='mb-0'>{this.state.currentBill.endDate}</h2>
                  </div>
                </Grid>
              </Grid>
              <h4 className='mt-2 mt-md-5'>費用明細</h4>
              <div className="frammer mb-0">
                {this.state.currentBill.fee.map((item, index) => {
                  return (
                    <div className="frammer--list" key={index}>
                      <h5>{item.title}</h5>
                      {item.isRed ? <h2 className='is-text-error'>{item.fee.toLocaleString()}元</h2> : <h4>{item.fee.toLocaleString()}元</h4>}
                    </div>
                  )
                })}
              </div>
              {/* <div className='d-flex fui-buttons two-buttons'>
                {this.state.currentBill.payment ? <Button link={this.state.currentBill.payment} btnStyle='primary' size='large'>我要繳費</Button> : null}
                <Button link={this.state.currentBill.resendPayment} btnStyle="secondary" size='large'>補寄帳單</Button>
              </div> */}
            </div>
          </div>
        </section>
        <section className='no-bg pb-0 mb-2 pt-2'>
          <div className="fui-container">
            <div className="fui-segments">
              <div className="paper mb-0">
                <h4 className="mb-3 mt-md-4 mt-0">電信費用明細</h4>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <div className="program-name is-bg-lightgray70 px-3 py-3 mb-5 pb-2">
                      <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                        專案名稱
                      </h5>
                      <h2 className='mb-0'>{this.state.currentBill.detail.title}</h2>
                    </div>
                    {this.getTable(this.state.currentBill.detail.table1)}
                  </Grid>
                </Grid>
              </div>

              <CollapsePaper
                title="單價說明"
                className="collapse-table"
                openCollapse={e => { console.log(e) }}
                open={false}
              >
                <div className="mt-2 pb-2">
                  <small>
                    『4G絕配1399』：網內及他網每秒0.07元、市話每秒0.105元。<br />
                      ※國際漫遊語音及數據費率，依各國漫遊業者訂定之費用收取，詳細請參閱官網www.fetnet.net。<br />
                      ※若您出國前已開啟門號的數據漫遊服務，於國外漫遊時，僅在手機上關閉行動數據或數據漫遊服務功能，仍可能因手機本身資料自動更新等因素產生些微傳輸量及數據漫遊費用。<br />
                  </small>
                  <table className="text-wrap table-fixed bill-table mt-0 mb-4">
                    <tbody>
                      <tr>
                        <th className="align-center-md align-left pl-md-5 pl-1 ">{this.state.currentBill.collapseInfo.info.head.title}</th>
                        <th className=" pr-md-5 pr-0">{this.state.currentBill.collapseInfo.info.head.content}</th>
                      </tr>
                      {this.state.currentBill.collapseInfo.info.body.map((item) => {
                        return (
                          <tr key={item.title}>
                            <td className="align-center-md align-left pl-md-5 pl-1">
                              {item.title}
                            </td>
                            <td className="pr-md-5 pr-0">{item.content}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <small>專案優惠明細：<br />
          4G絕配1399網內及他網通話優惠170分，市話通話優惠90分鐘*30個月(優惠至109/06/19)。</small>
                  <table className="text-wrap bill-table mt-0 mb-2">
                    <tbody>
                      <tr>
                        <th className="pl-md-5 pl-1 ">{this.state.currentBill.collapseInfo.detail.head.title}</th>
                        <th className=" pr-md-5 pr-0">{this.state.currentBill.collapseInfo.detail.head.content}</th>
                      </tr>
                      {this.state.currentBill.collapseInfo.detail.body.map((item) => {
                        return (
                          <tr key={item.title}>
                            <td className="pl-md-5 pl-1">
                              {item.title}
                            </td>
                            <td className="pr-md-5 pr-0">{item.content}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
              </CollapsePaper>

              {/* <div className={`fui-collapse ${this.state.open ? 'is-open' : ''}`}>
                <div
                  role="button"
                  onClick={this.collapseOpen}
                  className={`collapse-header`} >
                  單價說明
                  </div>
                <div
                  className="collapse-body"
                  style={{
                    height: this.state.open ? this.state.contentHeight : 0
                  }}>
                  <article ref={this.body}>
                    <small>
                      『4G絕配1399』：網內及他網每秒0.07元、市話每秒0.105元。<br />
                      ※國際漫遊語音及數據費率，依各國漫遊業者訂定之費用收取，詳細請參閱官網www.fetnet.net。<br />
                      ※若您出國前已開啟門號的數據漫遊服務，於國外漫遊時，僅在手機上關閉行動數據或數據漫遊服務功能，仍可能因手機本身資料自動更新等因素產生些微傳輸量及數據漫遊費用。<br />
                    </small>
                    <table className="text-wrap bill-table mt-0 mb-4">
                      <tbody>
                        <tr>
                          <th className="align-center-md align-left pl-md-5 pl-1 ">{this.state.currentBill.collapseInfo.info.head.title}</th>
                          <th className=" pr-md-5 pr-0">{this.state.currentBill.collapseInfo.info.head.content}</th>
                        </tr>
                        {this.state.currentBill.collapseInfo.info.body.map((item) => {
                          return (
                            <tr key={item.title}>
                              <td className="align-center-md align-left pl-md-5 pl-1">
                                {item.title}
                              </td>
                              <td className="pr-md-5 pr-0">{item.content}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <small>專案優惠明細：<br />
          4G絕配1399網內及他網通話優惠170分，市話通話優惠90分鐘*30個月(優惠至109/06/19)。</small>
                    <table className="text-wrap bill-table mt-0 mb-2">
                      <tbody>
                        <tr>
                          <th className="pl-md-5 pl-1 ">{this.state.currentBill.collapseInfo.detail.head.title}</th>
                          <th className=" pr-md-5 pr-0">{this.state.currentBill.collapseInfo.detail.head.content}</th>
                        </tr>
                        {this.state.currentBill.collapseInfo.detail.body.map((item) => {
                          return (
                            <tr key={item.title}>
                              <td className="pl-md-5 pl-1">
                                {item.title}
                              </td>
                              <td className="pr-md-5 pr-0">{item.content}</td>
                            </tr>
                          )
                        })
                        }
                      </tbody>
                    </table>
                  </article>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className='no-bg pb-5 mb-md-5 mb-2 pt-0'>
          <div className="fui-container">
            <div className="fui-segments">
              <div className="paper mb-0">
                <h4 className="mb-3 mt-md-3 mt-0">小額代收服務明細</h4>
                {this.getTable(this.state.currentBill.detail.table2)}
              </div>
            </div>
          </div>
        </section>
        <SectionCollapseInfo
          title="注意事項"
          content={`
            1. 此服務提供您查詢或下載最近6個月的帳單費用及使用明細。</br>
            2. 此服務之帳單費用不包含主帳戶之收費金額，若需查詢請下載帳單PDF檔案。</br>
            3. 明細帳單下載：當您下載檔案後，開啟檔案時請輸入您的身份資料驗證。
          `}
        />
      </main >
    )
  }
}

export default FeeDetail;