import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Link from '../../Link';
import Dropdown from '../../Dropdown';
import ResponseCollapse from '../../collapse/ResponseCollapse';

import { FooterMock } from '../../../mock/Footer';

const Footer = props => {
  const [selectLang] = React.useState(
    props.lang ? { ...props.lang } : { value: '/ebu/index', text: '繁體中文' }
  );
  const [footerType, setFooterType] = React.useState('default');
  const [mock, setMock] = React.useState(FooterMock);
  const history = useHistory();
  const location = useLocation();
  const [adShow, setAdShow] = React.useState(true);

  React.useEffect(() => {
    detectFooter(location.pathname);

    return function cleanup() {
      unlisten();
    }
  });

  const unlisten = history.listen(location => {
    detectFooter(location.pathname);
  });

  const changeLang = item => {
    if (typeof window !== 'undefined')
      window.location.pathname = item.value;
  };

  const detectFooter = path => {
    switch (true) {
      case path.indexOf('serviceCoverageController') > -1:
        setFooterType('help-center');
        break;
      case path.indexOf('/promotion-module/02') > -1:
        setFooterType('cooperation');
        setAdShow(false);
        break;
      case path === '/phoneBillPaySettingController':
      case path === '/':
      case path.indexOf('/estore') > -1:
      case path.indexOf('/prepaid') > -1:
      case path.indexOf('/discount') > -1:
      case path.indexOf('/rate-plan/4.5G') > -1:
      case path.indexOf('/otherService/Wifi') > -1:
      case path.indexOf('/lifeCircle/index') > -1:
      case path.indexOf('/lifeCircle/curation') > -1:
      case path.indexOf('/roamingPlan/Search') > -1:
      case path.indexOf('/originNumberRoaming/Index') > -1:
      case path.indexOf('/otherService/007') > -1:
      case path.indexOf('/discount-multiple-menu') > -1:
        setAdShow(true);
        setMock(FooterMock);
        setFooterType('default');
        break;
      default:
        setAdShow(false);
        setFooterType('blank');
    }
  };

  // console.log(footerType);
  switch (footerType) {
    case 'cooperation':
      return (
        <footer className='fui-footer blank-footer cooperation-footer'>
          <div className='fui-container'>
            <Grid container space={1} className='copyright'>
              <Grid className='footer-fetnet' item xs={12} sm={12} md={5}>
                <div className="d-flex">
                  <Link to={mock.brand.link}>
                    <img className='mb-0' src={process.env.PUBLIC_URL + mock.brand.img} alt='FetnetLogo' height='30' />
                  </Link>
                  <div className='links'>
                    <Link to='#'>
                      <small>隱私權政策</small>
                    </Link>
                    <Link to='#'>
                      <small>營業規章</small>
                    </Link>
                    <Link to='#'>
                      <small>聯絡我們</small>
                    </Link>
                    <span className='text-sm is-text-gray100'>遠傳電信版權所有 Copyright © Far EasTone.</span>
                  </div>
                </div>
              </Grid>
              <Grid className='cooperate-logo' item xs={12} sm={12} md={7}>
                <div className="img-wrapper">
                  <img src="/resources/cbu/img/logo-1@3x.png" alt="coop-01" />
                </div>
                <div className="img-wrapper">
                  <img src="/resources/cbu/img/logo-2@3x.png" alt="coop-01" />
                </div>
                <div className="img-wrapper">
                  <img src="/resources/cbu/img/logo-3@3x.png" alt="coop-01" />
                </div>
                <div className="img-wrapper">
                  <img src="/resources/cbu/img/logo-4@3x.png" alt="coop-01" />
                </div>
                <div className="img-wrapper">
                  <img src="/resources/cbu/img/logo-5@3x.png" alt="coop-01" />
                </div>
                <div className="img-wrapper">
                  <img src="/resources/cbu/img/logo-6@3x.png" alt="coop-01" />
                </div>
                <div className="d-block d-md-none border-bottom" />
              </Grid>
            </Grid>
          </div>
        </footer>
      )
    case 'blank':
      return (
        <footer className='fui-footer blank-footer'>
          <div className='fui-container'>
            <Grid container space={1} className='copyright'>
              <Grid className='footer-fetnet' item xs={12} sm={12} md={12}>
                <div className="d-flex">
                  <Link to={mock.brand.link}>
                    <img src={process.env.PUBLIC_URL + mock.brand.img} alt='FetnetLogo' height='30' />
                  </Link>
                  <div className='links'>
                    <Link to='#'>
                      <small>隱私權政策</small>
                    </Link>
                    <Link to='#'>
                      <small>營業規章</small>
                    </Link>
                    <Link to='#'>
                      <small>聯絡我們</small>
                    </Link>
                    <span className='text-sm is-text-gray100' dangerouslySetInnerHTML={{ __html: mock.copyright }}></span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </footer>
      );
    case 'help-center':
      return (
        <footer className='fui-footer blank-footer'>
          <div className='fui-container'>
            <Grid container space={1} className='copyright'>
              <Grid className='footer-fetnet' item xs={12} sm={12} md={12}>
                <div className="d-flex">
                  <Link to={mock.brand.link}>
                    <img src={process.env.PUBLIC_URL + mock.brand.img} alt='FetnetLogo' height='30' />
                  </Link>
                  <div className='links'>
                    <Link to='#'>
                      <small>隱私權政策</small>
                    </Link>
                    <Link to='#'>
                      <small>營業規章</small>
                    </Link>
                    <Link to='#'>
                      <small>聯絡我們</small>
                    </Link>
                    <span className='text-sm is-text-gray100' dangerouslySetInnerHTML={{ __html: mock.copyright }}></span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </footer>
      )
    case 'error':
      return (
        <footer className={`fui-footer pt-4`}>
          <div className='fui-container'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={4}>
                <div dangerouslySetInnerHTML={{ __html: mock.contactInfo }}></div>
                {mock.moreContact ? (
                  <Link to={mock.moreContact.link}>
                    <span className='text'>{mock.moreContact.text}</span> <i className='icon-chevron-right'></i>
                  </Link>
                ) : null}
                <div className='social-links'>
                  <Link to={mock.social.facebook} role='button' className='socail-item'>
                    <i className='icon-facebook'></i>
                  </Link>
                  <Link to={mock.social.youtube} role='button' className='socail-item'>
                    <i className='icon-youtube'></i>
                  </Link>
                  <Link to={mock.social.youtube} role='button' className='socail-item'>
                    <img src={'/resources/common/images/aishiteru.svg'} alt='aishiteru' width="42" />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} lg={8}>
                <Grid container space={1}>
                  {mock.menu.map((menuItem, i) => (
                    <Grid key={`menuItem--${i}`} item xs={12} sm={12} md={3}>
                      <ResponseCollapse title={menuItem.title} list={menuItem.list} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid container space={1} className='copyright'>
              <Grid item xs={12} sm={12} md={12}>
                <Link to={mock.brand.link}>
                  <img src={process.env.PUBLIC_URL + mock.brand.img} alt='FetnetLogo' height='30' />
                </Link>
                <span className='text-sm is-text-gray100' dangerouslySetInnerHTML={{ __html: mock.copyright }}></span>
              </Grid>
            </Grid>
          </div>
        </footer>
      );
    default:
      return (
        <footer className={`fui-footer pt-0`}>
          <div className='fui-container pt-4'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={4}>
                <div dangerouslySetInnerHTML={{ __html: FooterMock.contactInfo }}></div>
                {FooterMock.moreContact ? (
                  <Link to={FooterMock.moreContact.link}>
                    <span className='text'>{FooterMock.moreContact.text}</span> <i className='icon-chevron-right'></i>
                  </Link>
                ) : null}
                <div className='social-links'>
                  <Link to={FooterMock.social.facebook} role='button' className='socail-item'>
                    <img src={'/resources/common/images/facebook.svg'} alt='instagram' width="42" />
                  </Link>
                  <Link to={mock.social.instagram} role='button' className='socail-item'>
                    <img src={'/resources/common/images/instagram.svg'} alt='instagram' width="42" />
                  </Link>
                  <Link to={FooterMock.social.youtube} role='button' className='socail-item'>
                    <img src={'/resources/common/images/youtube.svg'} alt='instagram' width="42" />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} lg={8}>
                <Grid container space={1}>
                  {FooterMock.menu.map((menuItem, i) => (
                    <Grid key={`menuItem--${i}`} item xs={12} sm={12} md={3}>
                      <ResponseCollapse title={menuItem.title} list={menuItem.list} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid container space={1} className='copyright'>
              <Grid item xs={12} sm={12} md={12}>
                <Link to={FooterMock.brand.link}>
                  <img src={process.env.PUBLIC_URL + FooterMock.brand.img} alt='FetnetLogo' height='30' />
                </Link>
                <span className='text-sm is-text-gray100' dangerouslySetInnerHTML={{ __html: FooterMock.copyright }}></span>
              </Grid>
            </Grid>
          </div>
        </footer>
      );
  }
};

export default Footer;
