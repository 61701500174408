import React, { Component } from 'react'
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import Dropdown from "../../components/Dropdown";
import Panel from '../../components/panel/Panel';
import ForeignContent from '../../components/partials/ForeignContent';
import PanelInformation from '../../components/panelContent/PanelInformation';
import { default as MockEng } from '../../mock/ForeignEng.js';

class Foreign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownLabel: 'English',
      currentLang: MockEng
    }
    this.programSetting = {
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 3,
      draggable: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 1,
            variableWidth: true,
          },
        },
      ],
    };
  }

  dropdownChange = value => {
    if (value.text == "English") {
      this.props.history.push('./foreign-eng');
    } else {
      this.props.history.push('./foreign');
    }

  };
  render() {
    return (
      <main className="prepaid">
        <NavAnchor
          pageTitle={this.state.currentLang.NavAnchor.pageTitle}
          button={{
            link: '#',
            text: this.state.currentLang.NavAnchor.button,
          }}
          tabs={this.state.currentLang.NavAnchor.tabs}
        />
        <Breadcrumb
          breadcrumb={this.state.currentLang.Breadcrumb}
          color='white'
        />
        <div className="select-lang">
          <div className="fui-container">
            <Dropdown
              className="is-right is-reverse float-right"
              list={[
                { value: '/ebu/index', text: '繁體中文' },
                { value: '/en/ebu/index', text: 'English' },
              ]}
              label={this.state.dropdownLabel}
              arrow={true}
              hasCheck={true}
              onChange={this.dropdownChange}
            />
          </div>
        </div>
        <SectionBanner2
          image={{
            md: '/resources/cbu/prepaid/images/cbu-tourist-banner-01-1920x470.jpg',
            sm: '/resources/cbu/prepaid/images/cbu-tourist-banner-01-750x720.jpg',
          }}
          title={this.state.currentLang.SectionBanner2.title}
          description={this.state.currentLang.SectionBanner2.description}
          action={null} />
        <ForeignContent
          carousel={this.state.currentLang.tabContent}
          dial={this.state.currentLang.dial}
          pane={this.state.currentLang.dial.panel}
          panelTable={this.state.currentLang.dial.PanelTable}
          panelInformationTitle={this.state.currentLang.dial.PanelInformation.title}
          panelInformationContent={this.state.currentLang.dial.PanelInformation.content}
          whereToBuy={this.state.currentLang.whereToBuy}
        />
        <div id="help" className="is-bg-gray30">
          <div className="fui-container">
            <PanelInformation title={this.state.currentLang.info.title} content={this.state.currentLang.info.content} />
          </div>
        </div>
        <section className="p-0 is-bg-lightgray70">
          <div className="fui-container">
            <Panel className="is-bg-lightgray70 no-shadow px-0">
              <h3 className="is-text-darkgray50 is-text-medium m-0">{this.state.currentLang.qa.title}</h3>
              <SectionFaq to="#" hideHelpCenter={this.state.dropdownLabel == '繁體中文' ? false : true} {...this.state.currentLang.qa.faq} />
            </Panel>
          </div>
        </section>
      </main >
    );
  }
}

export default Foreign;