import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import NavContentTab2 from '../../components/partials/NavContentTab2';
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import Panel from '../../components/panel/Panel';
import PanelTab from '../../components/panelContent/PanelTab';
import PanelFigure1 from '../../components/panelContent/PanelFigure1';
import TabPane from '../../components/tab/TabPane';
import SectionCarousel3 from '../../components/partials/card/SectionCarousel3';
import PanelInformation from '../../components/panelContent/PanelInformation';
import BannerWithLink from '../../components/partials/banner/BannerWithLink';
import { Grid } from '@material-ui/core';
import ServiceTag from '../../components/partials/ServiceTag';
class Receipt extends Component {
    constructor(props) {
        super(props);
        this.panel = React.createRef();
        this.state = {
            currentTab: 0,
            faq: {
                personal: [
                    {
                        title: '為何電信帳單繳費後要開立電子發票？',
                        content: `為何電信帳單繳費後要開立電子發票？`,
                    },
                    {
                        title: '繳費完多久後可以收到電子發票呢？可以到哪裡查詢已經開立的發票？',
                        content: `繳費完多久後可以收到電子發票呢？可以到哪裡查詢已經開立的發票？`,
                    },
                    {
                        title: '溢繳費項是否也會開立發票呢？',
                        content: `溢繳費項是否也會開立發票呢？ `,
                    },
                    {
                        title: '2015/12/31前已經繳費的帳單也會補開發票嗎？',
                        content: `2015/12/31前已經繳費的帳單也會補開發票嗎？ `,
                    },
                    {
                        title: '電子發票中獎後會通知我嗎？',
                        content: `電子發票中獎後會通知我嗎？ `,
                    },
                ],
                company: [
                    {
                        title: '買方營業人何時可申報營業稅？',
                        content: `買方營業人何時可申報營業稅？`,
                    },
                    {
                        title: '公用事業開立「無實體」電子發票，一般公司戶未取得電子發票/折讓單紙本，要如何申報營業稅？',
                        content: `公用事業開立「無實體」電子發票，一般公司戶未取得電子發票/折讓單紙本，要如何申報營業稅？`,
                    },
                    {
                        title: '營業人以載具號碼輸入自有系統，發生折讓時，折讓單上卻顯示電子發票字軌號碼，營業人如何處理？',
                        content: `營業人以載具號碼輸入自有系統，發生折讓時，折讓單上卻顯示電子發票字軌號碼，營業人如何處理？ `,
                    },
                    {
                        title: '我要如何至財政部電子發票整合服務平台(簡稱大平台)下載媒體申報TXT檔？',
                        content: `我要如何至財政部電子發票整合服務平台(簡稱大平台)下載媒體申報TXT檔？ `,
                    },
                    {
                        title: '當期電信帳單本期新增款項金額為負數或零元時，營業稅如何申報？',
                        content: `當期電信帳單本期新增款項金額為負數或零元時，營業稅如何申報？ `,
                    },
                ]
            }
        }
    }
    changeTab = (val) => {
        this.setState({
            currentTab: val
        })
    }
    render() {
        return (
            <main className="prepaid receipt">
                <Header />
                <NavAnchor
                    pageTitle='<a href="#">帳單與用量</a>'
                    button={{
                        link: '#',
                        text: '回個人專區',
                    }}
                />
                <Breadcrumb
                    breadcrumb={[
                        { text: '個人首頁', link: '/' },
                        { text: '個人專區', link: '/' },
                        { text: '電信帳單開立發票', link: '/' }
                    ]}
                    color='black'
                />
                <SectionBanner2
                    position='right'
                    className="is-360 is-text-dark"
                    image={{
                        md: '/resources/cbu/otherService/cbu-eservice-settle-Invoice-1920x360.jpg',
                        sm: '/resources/cbu/otherService/cbu-eservice-settle-Invoice-750x400.jpg',
                    }}
                    title='<div class="mb-2">電信帳單開立發票</div>'
                    action={{
                        text: '下載空白折讓單',
                        link: '#',
                    }}
                />
                <div className="ribbon-bg mt-md-7 mt-4">
                    <section className='p-0'>
                        <div className='tab-content'>
                            {
                                <TabPane active={true}>
                                    <div className='fui-container'>
                                        <PanelTab
                                            ref={this.panel}
                                            onClick={(val) => this.changeTab(val)}
                                            tabs={{
                                                name: 'panel-tab',
                                                list: [
                                                    { name: 'panel-tab-1', label: '一般說明' },
                                                    { name: 'panel-tab-2', label: '公司戶報稅說明專區' }
                                                ],
                                            }}>
                                            <div>
                                                <div className="paper mt-1">
                                                    <PanelInformation
                                                        className="py-0"
                                                        title='<h5 class="m-0 is-text-black50 is-text-medium">電信帳單開立發票流程</h5>'
                                                        content={`
                                                        <ul class="step-list mt-md-3 mt-0">
                                                            <li>
                                                                <div class="circle">1</div>
                                                                <h6>用戶收到電信帳單</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">2</div>
                                                                <h6>繳費</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">3</div>
                                                                <h6>遠傳開立電子發票</h6>
                                                            </li>
                                                            <li class="mb-0 pb-md-3 pb-0 mb-0">
                                                                <div class="circle">4</div>
                                                                <h6>用戶於<a href="#" class="info-link">遠傳官網</a>查詢繳費後的電子發票及載具號碼</h6>
                                                            </li>
                                                        </ul>
                                                        <ul class="green-dot-list is-text-black100 mt-4">
                                                            <li class="">電子發票中獎時,遠傅會主動發送簡訊或e-Mail通知您,您可持該期帳單上的戴具號碼至7-ELEVEN、全家、萊爾富、0K四大超商的多媒體機台列印中獎之電子發票證明聯。</li>
                                                            <li>自107年10月1日起,以銀行/郵局存款帳戶(不含信用卡)，自動扣款繳費成功者,中獎發票小額獎金將直接匯入原扣款銀行/郵局存款帳戶,讓您兌領獎省時又方便!</li>
                                                        </ul>`}
                                                    />
                                                </div>
                                                <div className="paper mt-1">
                                                    <PanelInformation
                                                        className="py-0"
                                                        title='<h5 class="m-0 is-text-black50 is-text-medium">便利商店列印中獎發票操作步驟</h5>'
                                                        content={`
                                                        <h6 class="mb-4 mt-md-3 mt-0 is-text-regular is-text-black50">7-ELEVEN、全家、萊爾富、OK多媒體機台(Kiosk)中獎發票列印操作步驟如下：</h6>
                                                        <ul class="step-list mt-md-3 mt-0 pb-md-6 pb-0">
                                                            <li>
                                                                <div class="circle">1</div>
                                                                <h6>操作多媒體機台</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">2</div>
                                                                <h6>選擇「公用事業」</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">3</div>
                                                                <h6>選擇「電信」</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">4</div>
                                                                <h6>選擇遠傳電信</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">5</div>
                                                                <h6>輸入或掃描載具資訊</h6>
                                                            </li>
                                                            <li class="pb-md-3 pb-0">
                                                                <div class="circle">6</div>
                                                                <h6>勾選及列印中獎電子發票證明聯</h6>
                                                            </li>
                                                        </ul>`}
                                                    />
                                                </div>
                                                <div className="paper mt-1">
                                                    <PanelInformation
                                                        className="py-0"
                                                        title='<h5 class="m-0 is-text-black50 is-text-medium">電信帳單開立電子發票說明</h5>'
                                                        content={`
                                                        <h6 class="mb-4 mt-md-3 mt-0 is-text-regular is-text-black50">配合財政部電子發票政策及響應環保，自105年1月份電信費帳單新增之款項,將於收到激款後開立電子發票,並上傅至財政部電子發票整合服務平台:</h6>
                                                        <ul class="green-dot-list is-text-black100 mb-md-4 mb-2">
                                                            <li>電信服務隨「電信費帳單」收款，並於收到激付款項後三日內開立電子發票。</li>
                                                            <li>小額或加值/數位服務交易随「小額付費帳單」收款，並於交易完成後兩日內開立電子發票或收據。</li>
                                                            <li>用戶可透過遠傳官網「交易/繳費/發票查詢服務」、「遠傳行動客服APP」查詢發票資訊，或至“財政部電子發票整合服務平台、查詢已開立發票。</li>
                                                            <li>使用電子發票將由遠傳提供對獎服務，若有中獎將以簡訊或e-Mail通知用戶，免除發票保存困擾。</li>
                                                            <li>電子發票若索取紙本證明聯將不適用系統自動對獎服務，用戶需自行妥善保管，證明聯正本遺失亦無法再申請補發；電子發票證明聯範本：</li>
                                                        </ul>
                                                        <div class="receipt-img-wrapper pl-md-0 pl-4 pb-md-7 pb-0">
                                                            <div class="is-text-black100 d-flex flex-column mb-md-0 mb-2">
                                                                個人戶：
                                                                 <img class="receipt-img mt-1" src="/resources/common/images/receipt-1.png" alt="receipt-1" />
                                                            </div>
                                                            <div class="d-flex flex-column">
                                                                公司戶：
                                                                 <img class="receipt-img mt-1" src="/resources/common/images/receipt-2.png" alt="receipt-2" />
                                                            </div>
                                                        </div>`}
                                                    />
                                                </div>
                                                <div className="paper mt-1">
                                                    <PanelInformation
                                                        className="py-0"
                                                        title='<h5 class="m-0 is-text-black50 is-text-medium">轉帳代繳用戶發票小額獎金匯入原扣款帳戶</h5>'
                                                        content={`
                                                        <ul class="green-dot-list is-text-black100 mb-md-4 mb-2 mt-md-3 mt-0">
                                                            <li>依財政部105年2月16日公布實施之「公用事業申請辦理代用戶指定帳戶匯入雲端發票中獎獎金作業規定」。由銀行/郵局存款帳戶自動扣款繳交電信費用(不含信用卡帳戶、虛擬帳戶或使用網路銀行轉帳繳費，所開立發票中獎為小額獎金(統一發票四獎4,000元、五獎1,000元六獎200元及雲端發票專屬仟元獎2,000元、雲端發票専屬百元獎500元)者，於「開獎日次月6日」獎金將自動匯入原電信費扣款銀行/郵局帳戶。</li>
                                                            <li>金融/郵政機構回傳電信費扣繳成功資訊至本公司3日内，本公司將完整之用戶扣款帳號併同電子發票資料上傳財政部「電子發票整合服務平台」(簡稱大平台)。</li>
                                                            <li>大平台於開獎月28日提供發票中獎清冊，本公司下載後將寄送中獎通知。</li>
                                                            <li>財政部印刷廠依大平台於開獎月28日提供之發票中獎清冊，於「開獎日次月6日」，將獎金匯款資訊傳送至相關金融/郵政機構匯入原電信費扣款帳戶。(例如107年9~10月發票，11/25開獎，中獎獎金於12/6匯入)</li>
                                                            <li>倘因存款帳號除戶等原因造成獎金匯入失敗，本公司及金融(郵政)機構將另行通知用戶協助查明，財政部印刷廠於第一次匯款日次月20日或領獎期限前一日將中獎獎金補匯入用戶指定帳戶，例如107年9~10月發票，將於1月20日或3月4日再次匯款。</li>
                                                            <li>電信費轉帳代繳用戶發票小額獎金直接匯入原扣款帳戶，如「不同意」，請至本公司官網申請，自生效日起，扣款繳費完成開立之發票中獎時，請自行至四大超商利用多媒體機(KIOSK)，輸入繳費憑證上之「戴具號碼」，列印中獎電子發票證明聯至指定兌換獎金之商店或金融機構兌獎。</li>
                                                            <li>適用電信費轉帳代繳用戶發票小額獎金匯入原扣款帳戶者，無法以四大超商多媒體機(KIOSK)列印中獎電子發票證明聯，且依規定本公司亦不能提供中獎紙本電子發票證明聯，以避免重複兌獎。</li>
                                                            <li>本項服務僅限統一發票四獎4,000元、五獎1,000元、六獎200元及雲端發票専屬仟元獎2,000元，雲端發票專屬百元獎500元獎項，其他發票獎項中獎時，請自行至四大超商多媒體機(KIOSK)列印中獎電子發票證明聯至指定兌換獎金之商店或金融機構兌獎，請妥善保存「電信費繳費通知」，以免影響您的權益!</li>
                                                            <li>已索取過紙本發票或已設定發票捐贈、手機條碼、自然人憑證者，不適用本項服務。</li>
                                                            <li>國稅局宣導電子發票網頁：https://www.einvoice.nat.gov.tw/index!easyKnow</li>
                                                            <li>國稅局宣導公用事業開立發票網頁：https://www.einvoice.nat.gov.tw/ein_upload/html/1448525777920.html?CSRT=11510483369209504719</li>
                                                            <li class="pb-md-6 pb-0">國稅局免付費服務電話：0800-521-988。</li>
                                                        </ul>`}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="paper mt-1">
                                                    <PanelInformation
                                                        className="py-0"
                                                        title='<h5 class="m-0 is-text-black50 is-text-medium">公司戶報稅說明專區</h5>'
                                                        content={`
                                                        <ul class="step-list mt-md-3 mt-0">
                                                            <li>
                                                                <div class="circle">1</div>
                                                                <h6>用戶收到電信帳單</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">2</div>
                                                                <h6>繳費</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">3</div>
                                                                <h6>遠傳開立電子發票</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">4</div>
                                                                <h6>選擇申報方式：1.網路/媒體申報 2.人工申報</h6>
                                                            </li>
                                                            <li class="mb-0 pb-md-3 pb-0">
                                                                <div class="circle">5</div>
                                                                <h6>營業稅申報</h6>
                                                            </li>
                                                        </ul>`}
                                                    />
                                                </div>
                                                <div className="paper mt-1">
                                                    <PanelInformation
                                                        className="py-0"
                                                        title='<h5 class="m-0 is-text-black50 is-text-medium">人工申報</h5>'
                                                        content={`
                                                        <ul class="step-list mt-md-3 mt-0">
                                                            <li>
                                                                <div class="circle">1</div>
                                                                <h6>將帳單上載具號碼資訊(BB+8碼英數字)</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">2</div>
                                                                <h6>填具財政部公佈之「公用事業開立電子發票進項憑證明細表」</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">3</div>
                                                                <h6>附上該明細表及401申報書申報營業稅</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">4</div>
                                                                <h6>選擇遠傳電信</h6>
                                                            </li>
                                                            <li>
                                                                <div class="circle">5</div>
                                                                <h6>輸入或掃描載具資訊</h6>
                                                            </li>
                                                            <li class="mb-0 pb-md-3 pb-0">
                                                                <div class="circle">6</div>
                                                                <h6>勾選及列印中獎電子發票證明聯</h6>
                                                            </li>
                                                        </ul>`}
                                                    />
                                                </div>
                                            </div>
                                        </PanelTab>
                                    </div>
                                </TabPane>
                            }
                        </div>
                    </section>
                </div>
                <Panel className="is-bg-lightgray70 mb-0">
                    <div id="faq" className="fui-container">
                        <h3 className="is-text-darkgray50 is-text-medium m-0">常見問題</h3>
                        {this.state.currentTab === 0 ? <SectionFaq {...{
                            collapseList: this.state.faq.personal,
                            container: false,
                            to: '#',
                            target: '_blank'
                        }} /> : <SectionFaq {...{
                            collapseList: this.state.faq.company,
                            container: false,
                            to: '#',
                            target: '_blank'
                        }} />}
                    </div>
                </Panel>
            </main>
        );
    }
}
export default Receipt;