import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import PanelInformation from '../../components/panelContent/PanelInformation';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Panel from '../../components/panel/Panel';
class HiddenNumber extends Component {
  constructor(props) {
    super(props);
    this.panel = React.createRef();
    this.state = {
    }
  }
  render() {
    return (
      <main className="">
        <Header />
        <NavAnchor
          pageTitle='<a href="#">漫遊與門號服務</a>'
          button={{
            link: '#',
            text: '回個人專區',
          }}
        />
        <Breadcrumb
          color="white"
          breadcrumb={[
            { link: '/ebu', text: '個人首頁' },
            { link: '', text: '個人專區' },
            { link: '', text: '隱藏號碼說明' },
          ]}
        />
        <SectionBanner2
          position='right'
          className="is-360"
          image={{
            md: '/resources/cbu/img/cbu-eservice-hidden-number-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-eservice-hidden-number-750x400.jpg',
          }}
          title='<div class="mt-5 is-text-white">隱藏號碼說明</div>'
          action={null}
        />
        <div className="ribbon-bg pt-md-7 pt-4">
          <div className='fui-container'>
            <h4 className="is-text-darkgray50 mt-0 mb-7">此功能提供您隱藏自身號碼不顯示於對方的手機螢幕上。</h4>
            <div className="paper">
              <PanelInformation
                className="py-0"
                title={`<h5 class="m-0 is-text-black50 is-text-medium">單次通話隱藏</h5>`}
                content={`
                  <h6 class="m-0 is-text-regular">設定費用為免費。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12">
                        <h4 class="mt-3 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">#31# + 區域碼 + 電話號碼#<div class="d-inline-block is-text-regular body my-0 ml-1">撥出</div></h2>
                        </div>
                        <small class="is-text-gray100 mt-1 d-block">＊撥打範例，如：#31#0223456789</small>
                      </div>
                    </div>
                  </div>
                  `}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">每次通話隱藏</h5>'
                content={`
                  <h6 class="mb-0 mt-0 is-text-regular is-text-black50">設定費用為免費。手機直撥 888致電客服啟用每次通話隱藏功能。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12">
                        <h4 class="mt-3 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">888<div class="d-inline-block is-text-regular body my-0 ml-1">撥出</div></h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  `}
              />
            </div>
          </div>
        </div>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
          <ol>
            <li>每次通話隱藏功能僅適用月租型用戶。</li>
            <li>若要取消每次通話隱藏功能，請直撥888致電客服取消。</li>
            <li>各廠牌手機操作可能不同，詳細操作說明請參考手機使用手冊。</li>
          </ol>
          `}
        />
      </main>
    );
  }
}
export default HiddenNumber;