import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

import LifeCircleCarousel from '../../components/partials/card/LifeCircleCarousel';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';

import CollapsePaper from '../../components/partials/collapse/CollapsePaper';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import FooterAd from '../../components/ad/FooterAd';
import SectionAd3 from '../../components/partials/SectionAd3';

import * as Mock from '../../mock/OriginNumberRoaming';

class CountryEmpty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        this.setState({
          isMobile: window.innerWidth < 768,
        });
      });
  }

  render() {
    return (
      <main className="">
        <NavAnchor
          pageTitle='<a href="#">原號漫遊</a>'
          button={{
            link: '#',
            text: '聯絡我們',
          }}
          // tabs={[
          //   { label: '主題活動', link: '#anchor1' },
          //   { label: '達人帶你玩', link: '#anchor2' },
          //   { label: '漫遊優惠', link: '#anchor3' },
          //   { label: '其他漫遊', link: '#anchor4' },
          // ]}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '原號漫遊', link: '/' },
            { text: '波士尼亞赫塞哥維納 Bosnia and Herzegovina', link: '#' }
          ]}
          color='white'
        />


        <SectionBanner2 
          className="is-origin-number-roaming-country-banner"
          image={{
            md: '/resources/cbu/roaming/cbu-Roamingdefaut-banner-1920x360.jpg',
            sm: '/resources/cbu/roaming/cbu-roamingdefaut-750x640.jpg'
          }}
          tag=""
          subTitle='Bosnia and Herzegovina'
          title='波士尼亞赫塞哥維納'
          description=''
          actions=''
        />

        
        <section className="origin-number-roaming country with-bg pb-0">
          
          <div className="fui-container">
            <div className="empty-block is-text-darkgray50">該國家目前尚無漫遊上網方案，僅提供一般漫遊上網費率，如需申請或了解更多資訊，請撥打客服電話 <a href="#" className="hover-underline-link">+886936010888</a></div>
          </div>

          <CollapsePaper
            open={true}
            title="漫遊業者資訊"
            content={`
              <div class="fui-table-response collapse-roaming-block mb-2 mb-lg-4">
                <table class="gray-th">
                  <thead>
                    <tr>
                      <th class="">漫遊網</th>
                      <th class="">系統頻率</th>
                      <th class="">網路碼</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="">SK Telecom</td>
                      <td class="">3G/CDMA/4G(B1-2100/B3-1800/B5-850)</td>
                      <td class="">450 05</td>
                    </tr>
                    <tr>
                      <td class="">KT</td>
                      <td class="">3G/4G(B3-1800,B8-900)</td>
                      <td class="">450 08</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            `}
          />

          <CollapsePaper
            open={true}
            title="漫遊語音/簡訊費率"
            content={`
              <div class="fui-table-response collapse-roaming-block mb-2 mb-lg-4">
                <table class="gray-th">
                  <thead>
                    <tr>
                      <th class="" rowspan="2">漫遊網</th>
                      <th class="" colspan="2">發話至當地</th>
                      <th class="" colspan="2">發話至台灣</th>
                      <th class="" colspan="2">接聽電話</th>
                      <th class="" rowspan="2">發送簡訊</th>
                    </tr>
                    <tr>
                      <th class="">一般</th>
                      <th class="">減價</td>
                      <th class="">一般</td>
                      <th class="">減價</td>
                      <th class="">一般</td>
                      <th class="">減價</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="">SK Telecom</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                    </tr>
                    <tr>
                      <td class="">KT </td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <div class="mt-3 mb-1 is-text-darkgray50 text">註1：減價時段：週一至週六 21:30-8:00及週日</div>
            `}
          />

          <CollapsePaper
            open={true}
            title="漫遊上網費率"
            content={`
              <div class="fui-table-response collapse-roaming-block mb-2 mb-lg-4">
                <table class="gray-th w-lg-50">
                  <thead>
                    <tr>
                      <th class="">漫遊網</th>
                      <th class="">漫遊上網費率</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="">SK Telecom</td>
                      <td class="">優惠費率</td>
                    </tr>
                    <tr>
                      <td class="">KT</td>
                      <td class="">一般費率</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            `}
          />

          <CollapsePaper
            open={true}
            title="3G影像電話費率"
            content={`
              <div class="fui-table-response collapse-roaming-block mb-2 mb-lg-4">
                <table class="gray-th">
                  <thead>
                    <tr>
                      <th class="" rowspan="2">漫遊網</th>
                      <th class="" colspan="2">發話至當地</th>
                      <th class="" colspan="2">發話至台灣</th>
                      <th class="" colspan="2">接聽電話</th>
                    </tr>
                    <tr>
                      <th class="">一般</th>
                      <th class="">減價</th>
                      <th class="">一般</th>
                      <th class="">減價</th>
                      <th class="">一般</th>
                      <th class="">減價</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="">SK Telecom</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                    </tr>
                    <tr>
                      <td class="">KT</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                      <td class="">$29.74</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <div class="mt-3 mb-1 is-text-darkgray50 text">註1：減價時段：週一至週六 21:30-8:00及週日</div>
            `}
          />

          <CollapsePaper
            open={true}
            title="其他資訊"
            content={`
              <div class="collapse-roaming-block other-info">
                <p class="is-text-medium">聯絡我們</p>
                <ul class="green-dot-list">
                  <li>遠傳客服專線 <a href='#' target="_blank" class="is-text-error animate-underline-link">+886936010888</a></li>
                  <li>語音信箱專線 <a href='#' target="_blank" class="is-text-error animate-underline-link">+886936000222</a></li>
                </ul>
                <p class="is-text-medium">實用情報</p>
                <ul class="green-dot-list">
                  <li>國碼：+82</li>
                  <li>緊急電話：112</li>
                  <li>電壓：110/220 伏特</li>
                  <li>國際冠碼：+/002/008</li>
                  <li>時差：+1</li>
                </ul>
              </div>
            `}
          />

          
          <LifeCircleCarousel {...Mock.hotLocation} 
            title='更多推薦國家'
          />

          <SectionAd3
            patternColor='green'
            title={`不知道原號漫遊怎麼用？別擔心，漫遊小叮嚀讓你一次就上手！`}
            action={[
              {
                text: '了解更多',
                line: '#',
              },
            ]}
          />


          <SectionCollapseInfo
            title="備註說明"
            content={`
              1. 上述漫遊費用除非特別註明計價單位，其餘皆已換算為「每分鐘 / 新台幣元」表示，因匯率時有變動，收費時按雙方網路經營者實際交易日之匯率換算計收，上述費用僅供參考。<br/>
              2. 本公司依國外業者傳送之通信資料，透過簡訊提供上網用量及費用等相關通知，僅供參考，傳送過程可能因國外業者系統狀況、客戶終端無簡訊收送功能或關機等因素，而發生延遲、無法送達或超過簡訊發送時限而傳送失效等情形，於漫遊期間實際產生之用量及費用，仍以帳單為準。<br/>
              3. 若您出國前已開啟門號的數據漫遊服務，於國外漫遊時，僅在手機上自行關閉行動數據或數據漫遊服務功能，仍可能因手機本身資料自動更新等因素，而有些微傳輸量，產生數據漫遊費用。<br/>
              4. 使用影像電話視漫遊業者網路系統支援程度而決定。<br/>
              5. 漫遊區域與資費表之減價時段為漫遊當地之時間, 而非台灣時間。
            `}
          />
        
        </section>
      </main>
    );
  }
}

export default CountryEmpty;
