import React from 'react';
import { Grid } from '@material-ui/core';
import LoadMore from '../LoadMore';
import ArticleCards from './card/ArticleCards';
import HasMoreTab from '../tab/HasMoreTab';

//Data
import * as CurationMock from '../../mock/LifeCircle';
let currentTab = 0;
let hashtags = [
  {
    text: '心5G',
    link: '#123',
  },
  {
    text: '小資享受',
    link: '#234',
  },
  {
    text: '日常瘦身',
    link: '#',
  },
  {
    text: '懶人料理',
    link: '#',
  },
  {
    text: '3C開箱',
    link: '#',
  },
  {
    text: '親子手作樂趣多多',
    link: '#',
  },
  {
    text: '中秋節禮盒必買',
    link: '#',
  },
  {
    text: '輕旅行',
    link: '#',
  },
  {
    text: '手遊',
    link: '#',
  },
  {
    text: '金馬獎',
    link: '#',
  },
  {
    text: '黑科技',
    link: '#',
  },
  {
    text: '像極了愛情',
    link: '#',
  },
];
const MCuration = (props) => {
  const [curationArticle, setCurationArticle] = React.useState(CurationMock.curationArticle[currentTab].list);
  const [currentArticleLoadMore, setCurrentArticleLoadMore] = React.useState(
    CurationMock.curationArticle[currentTab].loadMore
  );
  // const [mainTab, setMainTab] = React.useState(0);
  const [isEn, setIsEn] = React.useState(typeof window !== 'undefined' && window.$isEn);

  // React.useEffect(() => {
  //   setIsEn(typeof window !== 'undefined' && window.$isEn);
  // }, []);

  let tabs = [];
  let dropdown = [];
  let tabLength = 5;
  let target = CurationMock.curationTabs.tabs.list;
  for (let i = 0; i < tabLength; i++) {
    tabs.push(target[i]);
  }
  for (let i = tabLength; i < target.length; i++) {
    dropdown.push(target[i]);
  }
  const handleChange = (e) => {
    currentTab = e.index;
    // console.log(`e`, e);
    setCurrentArticleLoadMore(CurationMock.curationArticle[currentTab].loadMore);
    setCurationArticle(CurationMock.curationArticle[currentTab].list);
  };
  const loadMore = () => {
    let result = CurationMock.curationLoadMoreData;
    let newtech = [...curationArticle, ...result];
    setCurrentArticleLoadMore(false);
    setCurationArticle(newtech);
  };
  return (
    <section className='info'>
      <div id='tech-article' className='fui-container top-section'>
        <div className='fui-section-header'>
          <div dangerouslySetInnerHTML={{ __html: '<h2 className="section-title">5G趨勢部落格</h2>' }}></div>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12}>
            <HasMoreTab
              tabs={tabs}
              dropdown={dropdown}
              onChange={handleChange}
              // defaultSelected={{
              //   name: 'tab-1',
              //   label: '開箱智慧生活',
              //   value: '置智慧生活',
              //   text: '開箱智慧生活',
              //   index: 0,
              // }}
            />
            {/* <Tab {...CurationMock.tabs.tabs} onChange={handleChange} /> */}
            <div className='tech-article-wrapper'>
              <ArticleCards article={curationArticle} />
              <LoadMore
                click={loadMore}
                load={currentArticleLoadMore}
                moreLabel={isEn ? 'More' : '展開看更多'}
                noMoreLabel={isEn ? 'No More Article' : '沒有更多文章'}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};
export default MCuration;
