import React from 'react';
import PropTypes from 'prop-types';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.trigger = React.createRef();
    this.state = {
      anchorEl: null,
      isClose: false,
      selectedItem: this.props.label,
      isHandleClick: false,
    };
  }

  handleClick = e => {
    e.preventDefault();
    if (this.state.isClose === true || typeof window === 'undefined') return;
    this.setState({ isHandleClick: true });
    this.forceUpdate();

    if (document.getElementsByClassName('fui-tooltip').length) document.getElementsByClassName('fui-tooltip')[0].removeElement();

    if (!Boolean(this.state.anchorEl)) {
      this.trigger.current.focus();
      this.setState({
        anchorEl: e.currentTarget,
        isHandleClick: false,
      });

      let isMobile = window.innerWidth < 600;
      let tooltip = document.createElement('div');
      let tooltipBody = document.createElement('div');
      let y = window.scrollY || document.documentElement.scrollTop;
      tooltip.className = 'fui-tooltip ' + (!this.props.parentNode ? 'is-nest' : '');
      tooltipBody.className = 'fui-tooltip-body';
      let hasTabAnchor = document.getElementsByClassName('fui-nav-anchor').length > 0 || document.getElementsByClassName('fui-nav-tab').length > 0;
      let hasHeader = document.getElementsByClassName('fui-control-bar').length > 0
      let hasFixed = (document.getElementsByClassName('fui-nav-anchor')[0] && document.getElementsByClassName('fui-nav-anchor')[0].classList.contains('is-fixed')) || (document.getElementsByClassName('fui-nav-tab').length && document.getElementsByClassName('fui-nav-tab')[0].classList.contains('is-fixed'));

      let pnode = !this.props.parentNode ? this.trigger.current : this.props.parentNode.current;
      let rect = pnode.getBoundingClientRect();

      tooltip.style.top = rect.top + pnode.clientHeight + y + (window.innerWidth < 960 ? -(5) : 48) - document.getElementsByTagName('header')[0].clientHeight + (hasTabAnchor ? (window.innerWidth < 960 ? 60 : 60) : 100) + (hasFixed ? (window.innerWidth < 960 ? 0 : 5) : 0) - (hasHeader ? 0 : (window.innerWidth < 960 ? 0 : 40)) + 'px';
      tooltip.style.display = 'block';
      // console.log(rect.left, window.innerWidth);
      // console.log(`hasHeader: `, hasHeader);

      tooltip.style.left = ((rect.left < window.innerWidth / 2) ? 15 : window.innerWidth - 255) + 'px';
      if (rect.left < window.innerWidth / 2) {
        tooltip.style.left = '30px';
      } else {
        tooltip.style.right = '30px';
        tooltip.style.left = 'auto';
      }
      // tooltip.style.left = ((rect.left < window.innerWidth / 2) ? 30 : window.innerWidth - 255) + 'px';

      if (!isMobile) {
        tooltip.style.left = rect.left + 'px';
        if (this.props.parentNode)
          tooltip.style.width = pnode.clientWidth + 'px';
      }

      tooltipBody.innerHTML = `
          ${this.props.tooltip}
      `;

      tooltip.appendChild(tooltipBody)

      let $main = null
      // Array.from(document.getElementById('root').childNodes).map(sec => {
      //   if (sec.querySelectorAll('.tooltip-wrapper').length > 0) {
      //     $main = sec;
      //     $main.appendChild(tooltip)
      //   }
      // })
      $main = document.getElementsByTagName('main')[0]
      $main.appendChild(tooltip)

      if (this.props.onOpen) this.props.onOpen();
    } else {
      this.setState({
        anchorEl: null,
        isHandleClick: false,
      });
    }

    return false
  };

  closeMenu = event => {
    if (this.props.close) this.props.close();
    setTimeout(() => {
      this.setState({
        anchorEl: null,
        isClose: false,
      });
    }, 120);
  };

  readyToClose = item => {
    if (this.state.isHandleClick === true) return;

    if (document.getElementsByClassName('fui-tooltip').length) document.getElementsByClassName('fui-tooltip')[0].removeElement();

    this.setState({
      isClose: true,
    });

    this.forceUpdate();

    setTimeout(() => {
      this.closeMenu();
    }, 50);
  };

  render() {
    return (
      <div className={`fui-tooltip-container ${this.props.className ? this.props.className : ''} ${Boolean(this.state.anchorEl) ? 'is-show' : ''}`}>
        <button
          ref={this.trigger}
          className='fui-button is-text'
          onClick={this.handleClick}
          onBlur={this.readyToClose}
          aria-expanded={Boolean(this.state.anchorEl)}>
          {this.props.content}
        </button>
        {/* <div className="fui-tooltip"
          ref={this.tooltip}
          role='tooltip'
          anchorEl={this.state.anchorEl}
          dangerouslySetInnerHTML={{ __html: this.props.tooltip }}>
        </div> */}
      </div>
    );
  }
}

Tooltip.propTypes = {
  parentNode: PropTypes.object,
  content: PropTypes.node, // click or hover, default click
  trigger: PropTypes.string, // click or hover, default click
  tooltip: PropTypes.string,
  className: PropTypes.string,
  onOpen: PropTypes.func,
  position: PropTypes.string
};

export default Tooltip;
