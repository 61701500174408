import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import NavAnchor from '../../components/partials/NavAnchor';

class OutOfService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }


  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />

        <section className="e-service-result">
          <div className="fui-container">
            <Grid>
              <Grid item sm={12}>
                <img src={'/resources/cbu/e-service/images/illustration-540-px-not-applicable-cbu@2x.png'} srcSet={'/resources/cbu/e-service/images/illustration-540-px-not-applicable-cbu@2x.png'} alt='' />
                <div className="content">
                  <h2 className="is-text-darkgray50 mb-0">你的門號/帳號不適用此服務</h2>
                  <p className='subtitle is-text-darkgray100'>您的手機非 VoLTE 適用手機，如有疑問請洽客服中心，謝謝</p>
                  <div className='action'>
                    <Button btnStyle='primary'>返回個人專區</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </main >
    )
  }
}

export default OutOfService;