import React from 'react';
import PropTypes from 'prop-types';

class Collapse extends React.Component {
    constructor(props) {
        super(props);
        this.body = React.createRef();
        this.state = {
            contentHeight: 0,
            open: this.props.open || false
        }

        this.collapseOpen = this.collapseOpen.bind(this)
        this.setContentHeight = this.setContentHeight.bind(this)
    }

    componentDidMount() {
        this.setContentHeight();
    }

    setContentHeight() {
        this.setState({
            contentHeight: this.body.current.clientHeight + 40
        })
    }

    componentDidUpdate(nextProps) {
        if (this.props.onChange && (nextProps.open !== this.state.open || this.props.open !== this.state.open)) {
            this.setState({
                open: this.props.open
            })
        }
    }

    collapseOpen(event) {
        this.setContentHeight()

        if (this.props.onChange) {
            this.props.onChange(!this.state.open)
        } else {
            this.setState({
                open: !this.state.open
            })
        }

        this.forceUpdate();

        // [AA Tracking]
        if (window && typeof window.ctrf === "function")
            window.ctrf(event || {}, this.state.open ? `Tab_${this.props.title}_收合` : `Tab_${this.props.title}_展開`);
    }

    render() {
        return (
            <div className={`fui-collapse ${this.state.open ? 'is-open' : ''}`}>
                {this.props.date ? <div dangerouslySetInnerHTML={{ __html: this.props.date }} ></div> : null}
                <div
                    role="button"
                    data-aa-tracking-id={this.state.open ? `Tab_${this.props.title}_收合` : `Tab_${this.props.title}_展開`}
                    onClick={(e) => this.collapseOpen(e)}
                    className={`collapse-header`} dangerouslySetInnerHTML={{ __html: this.props.title }} />
                <div
                    className="collapse-body"
                    style={{
                        height: this.state.open ? this.state.contentHeight : 0
                    }}>
                    {
                        this.props.content ? <article ref={this.body} dangerouslySetInnerHTML={{ __html: this.props.content }}></article> : null
                    }
                    {
                        this.props.children ? <article ref={this.body}>{this.props.children}</article> : null
                    }
                </div>
            </div>
        )
    }
}

Collapse.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    children: PropTypes.node,
    date: PropTypes.string,
    open: PropTypes.bool
}

export default Collapse