import React from 'react';
import PropTypes from 'prop-types';

class PanelTitle1 extends React.Component {
    render() {
        return(
            <h3 className="fui-panel-title">{this.props.title}</h3>
        )
    }
}

PanelTitle1.propTypes = { 
    title: PropTypes.string.isRequired
}

export default PanelTitle1;