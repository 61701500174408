import React from 'react';
import Dropdown from '../Dropdown';
import PropTypes from 'prop-types';
class HasMoreTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultLabel: this.props.tabs[0],
      selectDropdown: '',
      tabs: this.props.tabs,
      selectedTab: this.props.defaultSelected || this.props.tabs[0],
    };
    this.changeDropdown = this.changeDropdown.bind(this);
    this.changeSelection = this.changeSelection.bind(this);
    this.onActive = this.onActive.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectDropdown: this.state.defaultLabel,
      selectedTab: this.props.defaultSelected || this.props.tabs[0],
    });
  }

  componentDidUpdate(nextProps) {
    if (nextProps.defaultSelected !== this.props.defaultSelected) {
      this.setState({
        tabs: this.props.tabs,
        selectedTab: this.props.defaultSelected || this.props.tabs[0],
      });
    }
  }

  onActive = () => {
    this.setState({
      selectedTab: 'more',
    });
  };

  changeDropdown(item, index) {
    this.setState({
      selectDropdown: item.text,
    });
    this.props.onChange(item, index);
  }

  changeSelection(item) {
    this.setState({
      selectedTab: item,
    });
    this.props.onChange(item);
    this.forceUpdate();
  }
  getTabs = () => {
    return this.props.tabs.map((item, index) => {
      return (
        <div
          key={item.label}
          className={`tab ${
            !!this.state.selectedTab ? (this.state.selectedTab.label === item.label ? 'active' : '') : ''
          }`}
          value={item.name}
          onClick={() => this.changeSelection(item)}>
          {item.label}
        </div>
      );
    });
  };

  render() {
    return (
      <div className='has-more-tab'>
        <div className='tab-wrapper'>{this.getTabs()}</div>
        {this.props.dropdown.length ? (
          <Dropdown
            label={'更多'}
            list={this.props.dropdown}
            arrow={true}
            selected={this.state.selectDropdown}
            onChange={this.changeDropdown}
            onActive={this.onActive}
            hasCheck={true}
            className={`is-right is-bottom is-reverse ${this.state.selectedTab === 'more' ? 'active' : ''}`}
          />
        ) : null}
      </div>
    );
  }
}
HasMoreTab.propTypes = {
  tabs: PropTypes.array,
  defaultSelected: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
  }),
  dropdown: PropTypes.array,
  onChange: PropTypes.func,
};
export default HasMoreTab;
