import React from 'react';
import Button from '../../Button';
import Item from '../../item/Item';
import Tooltip from '../../Tooltip';
import Slider from 'react-slick';
import PromotionCard from '../../card/PromotionCard';

import PropTypes from 'prop-types';

class PlanCard extends React.Component {
  constructor(props) {
    super(props);

    this.setting = {
      dots: false,
      infinite: false,
      slidesToShow: 3,
      draggable: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToShow: 2,
            variableWidth: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: true,
            slidesToShow: 1,
            variableWidth: true,
          },
        },
      ],
    };
  }

  callAction = (content) => {
    if (this.props.openModal) this.props.openModal(content);
  };

  render() {
    return (
      <section>
        <div className='fui-container'>
          <h2>{this.props.title}</h2>
          <Slider {...this.setting} className='horizontal-cards is-rate-plan'>
            {this.props.cards.map((card, idx) =>
              card.type === 'promotion' ? (
                <PromotionCard {...card} key={`plan-card-${idx}`} />
              ) : (
                <div className='fui-card' key={`plan-card-${idx}`}>
                  <div className='fui-card-action align-center'>
                    <div className='fui-card-caption'>
                      {card.image ? <img src={card.image} alt='img' /> : null}
                      {card.ribbon ? <div className='fui-card-ribbon'>{card.ribbon}</div> : null}
                      <div className='fui-card-content'>
                        <h3 className='fui-card-title'>{card.title}</h3>
                        {card.description ? (
                          <div
                            className='fui-card-description'
                            dangerouslySetInnerHTML={{ __html: card.description }}></div>
                        ) : null}
                        {card.list ? (
                          <div className='fui-list'>
                            {card.list.map((item, i) => (
                              <div className='item-with-tooltip' key={`plan-card-${idx}-item-${i}`}>
                                <Item prefix='check'>{item.text}</Item>
                                {item.tooltip ? (
                                  <Tooltip
                                    content={<i className='icon-information'></i>}
                                    trigger='click'
                                    tooltip={item.tooltip}
                                  />
                                ) : null}
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </div>
                      {card.action ? (
                        <div className='fui-card-extra'>
                          {Array.isArray(card.action) ? (
                            card.action.map((act, i) =>
                              act.modal ? (
                                <Button
                                  btnStyle={act.btnStyle}
                                  link={act.link}
                                  target={act.target}
                                  onClick={(e) => this.callAction(act.modal)}
                                  key={`plan-card-${idx}-${i}`}>
                                  {act.text}
                                </Button>
                              ) : (
                                <Button
                                  btnStyle={act.btnStyle}
                                  link={act.link}
                                  target={act.target}
                                  key={`plan-card-${idx}-${i}`}>
                                  {act.text}
                                </Button>
                              )
                            )
                          ) : (
                            <Button btnStyle={card.action.btnStyle} link={card.action.link} target={card.action.target}>
                              {card.action.text}
                            </Button>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )
            )}
          </Slider>
        </div>
      </section>
    );
  }
}

PlanCard.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      ribbon: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string,
      list: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          tooltip: PropTypes.string,
        })
      ),
      action: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
        target: PropTypes.string,
      }),
    })
  ),
  openModal: PropTypes.func,
};

export default PlanCard;
