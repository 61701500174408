import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import OrderPaper from '../../components/partials/card/OrderPaper'
class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: null,
      currentOpen: false,
      order: [
        {
          productName: 'Samsung Galaxy S9 128GB',
          description: '資費方案：遠傳正職員工們好4G續約活動_新絕配999',
          id: 'TL18000000000',
          timeStamp: '2019/12/31 09:26',
          orderStatus: '資料確認中',
          link: '#',
          linkTarget: '_blank',
          img: '/resources/cbu/img/bitmap-copy@2x.png',
          imgAlt: 'phone',
          arr: ['商品成立', '資料確認中', '出貨準備中', '物流配送中', '已簽收', '門號已開通']
        },
        {
          productName: 'Apple iPad Pro 11 LTE 256GB (2018)',
          description: '資費方案：智慧手機及平板福利品專案_新絕配999',
          id: 'TL18000000001',
          timeStamp: '2019/12/31 09:26',
          orderStatus: '商品成立',
          linkTarget: '_blank',
          link: '#',
          imgAlt: 'phone',
          img: '/resources/cbu/img/rectangle@2x.png',
          arr: ['商品成立', '資料確認中', '出貨準備中', '物流配送中', '已簽收', '門號已開通']
        },
      ]
    }
  }
  tabChange = (i) => {
    this.setState({
      current: i
    })
    if (i == 1) {
      this.props.history.push('/order/order-friday')
    } else {
      this.props.history.push('/order/order-history')
    }
  }
  getOrderList = () => {
    if (!!this.state.order) {
      return (
        this.state.order.map((list) => {
          return (
            <OrderPaper
              list={list}
              key={list.id}
            />
          )
        })
      )
    } else {
      return (
        <div className="paper py-4">
          <div className="is-text-darkgray50 body">
            查無訂單記錄
          </div>
        </div>
      )
    }
  }
  render() {
    return (
      <main className="my-order">
        <FormBanner
          title="我的訂單"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />
        <NavContentTab1
          tabs={{
            name: 'page-tab',
            list: [
              { label: '網路門市' },
              { label: 'friDay購物' },
            ]
          }}
          onChange={this.tabChange}
        />
        <section className="order-status">
          <div className="fui-container">
            {this.getOrderList()}
          </div>
        </section>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
                <ol>
                  <li>我的訂單貼心小叮嚀</li>
                </ol>
              `}
        />
      </main>
    );
  }
}

export default OrderHistory;