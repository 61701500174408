import React from 'react';
import Slider from "react-slick";
import Link from '../../Link';
import Button from '../../Button';

import ArrowLeftWhite from '../../animateArrow/ArrowLeftWhite';
import ArrowRightWhite from '../../animateArrow/ArrowRightWhite';
import ProductBgArrow from '../../animateArrow/ProductBgArrow';
import Card from '../../card/Card';

import PropTypes from 'prop-types';

const LifeCircleNewsCarousel = (props) => {
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true
        }
      }
    ]
  }

  const renderBg = () => {
    switch (props.bgStyle) {
      case 'arrow':
        return <div className="section-bg"><ProductBgArrow /></div>
      default:
        return (
          <div className="section-bg">
            <ArrowLeftWhite />
            <ArrowRightWhite />
          </div>
        )
    }
  }

  return (
    <section className="fui-horzonal-cards news-list" id={props.id}>
      {renderBg()}
      <div className="fui-container">
        <h2 className="section-title">
          {props.title}
        </h2>
        <Slider {...settings} className="horizontal-cards">
          {
            props.cards.map((card, idx) => (
              <Card key={idx} {...card} />
            ))
          }
        </Slider>
        {
          props.more ? (
            <div className="align-center more">
              <Button btnStyle='secondary' link='{props.more.link}' size='' className="">{props.more.text}</Button>
            </div>
          ) : ''
        }
      </div>
    </section>
  );
}

LifeCircleNewsCarousel.propTypes = {
  id: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.objectOf(Card)
  ),
  bgStyle: PropTypes.string,
  more: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string
  })
}

export default LifeCircleNewsCarousel;