import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import Tab from '../../components/tab/Tab';
import TabPane from '../../components/tab/TabPane';
import Tooltip from '../../components/Tooltip';
import Link from '../../components/Link';
import {setInputFilter} from '../../utils/inputFilter';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import LabelInput from '../../components/form/LabelInput';

import Button from '../../components/Button';;

class prepaidRapidDepositPage extends React.Component {
  constructor(props) {
    super(props);
    this.tooltipContent = React.createRef();
    this.bankForm = React.createRef();
    this.creditForm = React.createRef();
    this.phoneNumber = React.createRef();
    this.identityInput = React.createRef();
    this.state = {
      bankAccountCanSubmit: false,
      creditCardCanSubmit: false,
      currentTab: 0,
      bankForm: {
        msid: {value: '', required: true},
        id_number: {value: '', required: true},
      },
    }
  }

  componentDidMount() {
    setInputFilter(this.phoneNumber.current.getElementsByTagName('input')[0], function(value) {
      return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
    });

    setInputFilter(this.identityInput.current.getElementsByTagName('input')[0], function(value) {
      return /^\w*$/.test(value); // Allow digits and '.' only, using a RegExp
    });
  }
  changeTab = (value) => {
    this.setState({
      currentTab: value
    })
  }

  enableButton = () => {
    if (this.state.currentTab === 0)
      this.setState({
        bankAccountCanSubmit: true
      })
    else
      this.setState({
        creditCardCanSubmit: true
      })
  }

  disableButton = () => {
    this.setState({
      bankAccountCanSubmit: false
    })
  }

  bankFormOnChange = (n, v) => {
    let form = Object.assign(this.state.bankForm);
    form[n].value = v;

    this.setState({
      bankForm: form
    })
  }

  bankSubmit = () => {
    this.props.history.push('/billBankPayController/billBankPayPage')
  }

  render () {
    const { currentTab, bankForm, creditForm } = this.state;

    return (
      <main>
        
        <NavAnchor 
          pageTitle="預付卡專區"
          button={{
            text: "回個人專區", link: '/'
          }}
        />
        <Breadcrumb breadcrumb={[
          {link: '/',  text: '個人首頁'},
          {link: '/',  text: '個人專區'},
          {link: '/',  text: '預付卡專區'},
          {link: '/',  text: '預付卡儲值'},
        ]} />
        <section className='fui-login-section'>
          <div className="fui-container">
            <h2 className="my-0">預付卡快速儲值</h2>
            <h4 className="mt-0 is-text-darkgray50">不需輸入遠傳官網帳號及密碼，只要輸入身分資料即可進行預付卡門號或門號儲值。</h4>
            <div className="fui-segments">
              <div className="fui-segment">
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.bankForm}>

                      <div ref={this.phoneNumber}>
                        <LabelInput
                          label="門號"
                          name='msid'
                          required={true}
                          validationErrors={{
                            isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                          }}
                          placeholder='請輸入門號'
                          value={bankForm.msid.value}
                          onChange={this.bankFormOnChange}
                        />
                      </div>
                      <div className='tooltip-container-box'>
                        <span className="float-right tooltip-container" ref={this.tooltipContent}>
                          <Tooltip 
                            content={<i className='icon-information'></i>}
                            trigger="click"
                            tooltip={`
                              <div>身份說明:</div>
                              <ol>
                                <li>個人戶：身分證字號</li>
                                <li>公司戶：統一編號或稅籍編號</li>
                                <li>外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼</li>
                              </ol>
                            `}
                          />
                        </span>
                        <div ref={this.identityInput}>
                          <LabelInput
                            label="身份證號碼／統編"
                            name='id_number'
                            required={true}
                            validationErrors={{
                              isDefaultRequiredValue: '您輸入了錯誤資訊，請再檢查！',
                            }}
                            placeholder='請輸入身份證號碼／統編'
                            value={bankForm.id_number.value}
                            onChange={this.bankFormOnChange}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <img src='https://cldup.com/p-jwfiXPY0-3000x3000.png' alt='recaptcha' width="auto" />
                      </div>

                      <Button btnStyle='primary' size='large' disabled={!this.state.bankAccountCanSubmit} onClick={this.bankSubmit}>確認送出</Button>
                    </Formsy>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Link to="#" className='promotion'>
                      <img src={process.env.PUBLIC_URL + `resources/cbu/e-service/images/bg-login-promotion.png`} srcSet={process.env.PUBLIC_URL + `resources/cbu/e-service/images/bg-login-promotion@2x.png`} width="508" alt="" />
                      <div className="content">
                        <h2 className="is-text-white mt-2 mt-md-4 mb-0 mb-md-3">遠傳易付卡飆網免綁約</h2>
                        <h5 className="is-text-white my-2 my-md-4">新用戶首購上網服務，最高可享雙倍送</h5>
                        <Button btnStyle='primary' reverse={true} className="is-text-accent">了解更多</Button>
                      </div>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </section>
        <SectionCollapseInfo
          title="貼心小叮嚀" 
          content={`
            <ol>
              <li>透過此功能您不需使用遠傳官網的帳號及密碼登入，只要輸入身分資料完成登入檢核就可進行預付卡門號或安心講門號的儲值。</li>
              <li>此快速儲值功能僅提供語音儲值，若要加購上網，請點選「預付卡國內上網加購」並使用遠傳官網的帳號及密碼登入</li>
            </ol>
          `} 
        />
      </main>
    )
  }
}

export default prepaidRapidDepositPage;