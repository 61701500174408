import React, { Component } from 'react'

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import AdaptHeightBanner from '../../components/partials/banner/AdaptHeightBanner';
import PanelTable from '../../components/panelContent/PanelTable';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import NavContentTab2 from '../../components/partials/NavContentTab2';
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import SectionAd3 from '../../components/partials/SectionAd3';
import Panel from '../../components/panel/Panel';
import PanelTab from '../../components/panelContent/PanelTab';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import PanelInformation from '../../components/panelContent/PanelInformation';
import Link from '../../components/Link';
import * as Mock from '../../mock/AddValue.js';
class Program extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  changeMainTab = value => {
    this.setState({
      currentMainTab: value,
    });
    return value === 0 ? this.props.history.push('/cbu/5g') : null
  };
  render() {
    return (
      <main className="prepaid ribbon-bg-wrapper">
        <NavAnchor
          pageTitle='預付卡'
          button={{
            text: '回預付卡',
            link: '#'
          }} />
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '預付卡', link: '/' },
            { text: '預付卡儲值方式', link: '/' },
          ]}
          color='white'
        />
        <AdaptHeightBanner
          height={{
            desktop: 360,
            mobile: 180
          }}
          bgImg={{
            md: '/resources/cbu/prepaid/images/cbu-prepaid-promotion-1920x360.jpg',
            sm: '/resources/cbu/prepaid/images/cbu-prepaid-promotion-750x360.jpg',
          }}
          title='預付卡資費' />
        <Panel className="is-bg-lightgray70">
          <div className="fui-container">
            <PanelTab
              tabs={{
                name: 'panel-tab',
                list: [
                  { name: 'panel-tab-1', label: '易付卡 300型' },
                  { name: 'panel-tab-2', label: '易付卡 500型' },
                  { name: 'panel-tab-3', label: '易付卡超省 1.8型' },
                  { name: 'panel-tab-4', label: '易付卡3日飆網型' },
                  { name: 'panel-tab-5', label: '易付卡5日飆網型' },
                ],
              }}>
              <div className='paper'>
                <h2 className="mb-1">易付卡 300型</h2>
                <h4 className="mt-0 is-text-medium mb-3">售價 $300</h4>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <h4 className="is-text-darkgray50 mt-0 mb-0">通話費</h4>
                  </Grid>
                  <Grid item xs={12} md={6} className='program-internet'>
                    <h4 className="is-text-darkgray50 mt-0 mb-0">網路</h4>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="rate-plan-box">
                      <div className="d-flex">
                        <Grid item xs={12} sm={12} md={6}>
                          國內通話費
                          <h4 className="m-0 is-text-bold is-text-black50 mb-2 mt-1">$100</h4>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          贈送網內通信費
                          <h4 className="m-0 is-text-bold is-text-black50 mb-2 mt-1">$50</h4>
                        </Grid>
                      </div>

                      <div className="d-flex">
                        <Grid item xs={12} sm={12} md={6}>
                          內含贈送
                          <h4 className="m-0 is-text-bold is-text-black50 mb-2 mt-1">$50</h4>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <div className="d-block">網內通信費效期</div>
                          <h4 className="m-0 is-text-bold is-text-black50 mb-2 mt-1 d-inline-block mr-1">30</h4>天
                        </Grid>
                      </div>
                      <div className="d-flex">
                        <Grid item xs={12} sm={12} md={12}>贈送通話費效期</Grid>
                      </div>
                      <div className="d-flex">
                        <Grid item xs={12} sm={12} md={12}>
                          <h4 className="m-0 is-text-bold is-text-black50 mb-2 mt-1 d-inline-block mr-1">6</h4>個月
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <h4 className="is-text-darkgray50 program-internet-mb">網路</h4>
                    <div className="rate-plan-box">
                      <div className="d-flex">
                        <Grid item xs={12} sm={12} md={6}>
                          <div className="d-block">數據傳輸量</div>
                          <h4 className="m-0 is-text-bold is-text-black50 mb-2 mt-1 d-inline-block mr-1">1.2</h4>GB
                      </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <div className="d-block">數據傳數量內容</div>
                          <h4 className="m-0 is-text-bold is-text-black50 mb-2 mt-1 d-inline-block mr-1">180MB 購買，其餘贈送</h4>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <h4 className="mt-4 is-text-medium mb-3">電話費率</h4>
                <PanelTable
                  content={`<div class="compareTable content-16 clearfix">
                <div class="tableLeft">
                    <div class="tableHead">
                        <table>
                            <tbody>
                                <tr style="height: 104px;">
                                    <th></th>
                                </tr>
                                <tr style="height: 52px;">
                                    <th>網內</th>
                                </tr>
                                <tr style="height: 52px;">
                                    <th>網外</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tableRight">
                    <div class="tableBody">
                        <div class="spaceBox">
                            <table class="compareList">
                                <tbody>
                                    <tr style="height: 52px;">
                                      <td colspan="2">語音通信費率</td>
                                      <td colspan="2">影像電話費率</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                      <td>一般時段</td>
                                      <td>減價時段</td>
                                      <td>一般時段</td>
                                      <td>減價時段</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                      <td>0.1元/秒</td>
                                      <td>0.05元/秒</td>
                                      <td>1元/分</td>
                                      <td>1元/分</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                      <td>0.174元/秒</td>
                                      <td>0.0869元/秒</td>
                                      <td>0.3元/秒</td>
                                      <td>0.3元/秒</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>
                </div>
            </div>`}
                />
                <h4 className="mt-4 is-text-medium mb-3">簡訊費率</h4>
                <PanelTable
                  content={
                    `<div class="compareTable content-16 clearfix">
                      <div class="tableLeft">
                          <div class="tableHead">
                              <table>
                                  <tbody>
                                      <tr style="height: 52px;">
                                          <th></th>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <th>網內</th>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <th>網外</th>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      <div class="tableRight">
                          <div class="tableBody">
                              <div class="spaceBox">
                                  <table class="compareList">
                                      <tbody>
                                          <tr style="height: 52px;">
                                            <td>國內簡訊</td>
                                            <td>國際簡訊</td>
                                          </tr>
                                          <tr style="height: 52px;">
                                            <td>0.8708元/則</td>
                                            <td rowspan="2">5元/則</td>
                                          </tr>
                                          <tr style="height: 52px;">
                                            <td>2.6127元/則</td>
                                          </tr>
                                      </tbody>
                                  </table> 
                              </div>
                          </div>
                      </div>
                  </div>`
                  }
                />
                <h4 className="mt-4 is-text-medium mb-3">影音/行動上網費率</h4>
                <PanelTable
                  content={`<div class="compareTable content-16 clearfix">
                <div class="tableLeft">
                    <div class="tableHead">
                        <table>
                            <tbody>
                                <tr style="height: 104px;">
                                    <th></th>
                                </tr>
                                <tr style="height: 52px;">
                                    <th>網內</th>
                                </tr>
                                <tr style="height: 52px;">
                                    <th>網外</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tableRight">
                    <div class="tableBody">
                        <div class="spaceBox">
                            <table class="compareList">
                                <tbody>
                                    <tr style="height: 52px;">
                                      <td colspan="3">多媒體影音訊息</td>
                                      <td rowspan="2">國際多媒體影音訊息</td>
                                      <td rowspan="2">GPRS行動上網</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                      <td>傳輸量 30K以下</td>
                                      <td>傳輸量 31K~300K</td>
                                      <td>傳輸量 300K以上</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                      <td>1元/則</td>
                                      <td>8元/則</td>
                                      <td>8+(n-300)*0.02元/則</td>
                                      <td rowspan="2">8元/則</td>
                                      <td rowspan="2">0.0056元/則</td>
                                    </tr>
                                    <tr style="height: 52px;">
                                      <td>6元/則</td>
                                      <td>12元/則</td>
                                      <td>12+(n-300)*0.02元/則</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>
                </div>
            </div>`}
                />
                <p className="mt-4">* n=實際傳送K數.</p>
                <SectionCollapseInfo
                  title="貼心小叮嚀"
                  content={`
                  1.易付出國漫遊上網服務僅適用於4G用戶。<br/>
                  2.自申購成功日起30日內有效，於有效期限內抵達國外當地後開機並確認登錄於指定業者(港澳：Hutchison，新加坡：Starhub)優惠網；收到漫遊上網啟用生效簡訊即開始計算上網時數。
                `}
                />
              </div>

              <Panel className="">2</Panel>
            </PanelTab>
            <Link to={'#'} className='fui-button is-text pr-0 mt-0'>
              <i className='icon-chevron-left mr-1'></i>
              <span>回到預付卡申辦</span>
            </Link>
          </div>
        </Panel>
        <SectionAd3 {...{
          patternColor: 'green',
          title: `透過遠傳官網儲值，立即享有網路限定優惠`,
          action: [{
            text: '立即儲值',
            line: '#',
          }]
        }} />
        <div className="fui-container">
          <PanelInformation title={'備註事項'} content={'備註事項'} />
        </div>
      </main>
    );
  }
}
export default Program;