import React from 'react';
import Modal from 'react-modal';
import Formsy from 'formsy-react';
import Tooltip from '../../components/Tooltip';
import CheckboxButtons from '../../components/form/CheckboxButtons';
import Tab from '../tab/Tab';
import { Grid } from '@material-ui/core';
import Dropdown from "../../components/Dropdown";
import TabPane from '../tab/TabPane';
import Slider from 'react-slick';
import Button from '../Button';
import PropTypes from 'prop-types';
import * as formatNumber from '../../utils/numberFormatter';

class DevicePlanTable extends React.Component {
  constructor(props) {
    super(props);
    this.planTable = React.createRef();
    this.modalForm = React.createRef();
    this.sliderRef = [];
    this.state = {
      isMobile: window.innerWidth < 960,
      titleHeight: 0,
      selectedList: this.props.programList[0].text,
      defaultChecked: "",
      checkKey: 0,
      filterModal: false,
      currentHover: -1,
      currentTab: this.props.tabs.default ? this.props.tabs.default : 0,
      form: {
        filter: { value: '', required: false },
      }
    }
    this.slideSetting = {
      dots: false,
      infinite: false,
      arrows: true,
      draggable: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            arrows: true,
            slidesToShow: 3,
            variableWidth: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToShow: 2,
            variableWidth: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: true,
            slidesToShow: 1,
            variableWidth: true
          }
        }
      ],
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tabs.default !== this.props.tabs.default) {
      this.setState({ currentTab: this.props.tabs.default || 0 })
    }

    if (this.props.selected.name !== prevProps.selected.name) {
      setTimeout(() => {
        this.sliderRef[this.state.currentTab].slickGoTo(this.hasSelect(this.props.tabContent[this.state.currentTab]))
      }, 300)
    }
  }

  setHover = (index) => {
    this.setState({
      currentHover: index,
    })
  }

  tabChange = (index) => {
    // console.log(index);
    this.onChange('filter', '');
    this.setState({
      currentTab: index
    })
  }

  setSlider = (ref) => {
    this.sliderRef.push(ref)
  }

  hasSelect = (tab) => {
    let index = 0
    tab.forEach((item, i) => {
      if (this.props.selected.name === item.name) {
        index = i;
      }
    })

    return index;
  }
  onChange(name, value) {
    // console.log({ name, value });
    let form = Object.assign(this.state.form);
    form[name].value = value;
    this.setState({
      form: form
    });
  }
  spec = (item, idx, e) => {
    // console.log({ item, idx, e });
    this.setState({
      currentTab: idx,
      selectedList: e,
    })
    this.onChange('filter', '');
    this.props.resetFilterContent();
  }
  getTitleLength = (item) => {
    let length = 0
    let height = 30
    for (let i = 0; i < item.length; i++) {
      if (item[i].displayName) {
        let temp = item[i].displayName.replace(/[^\x00-\xff]/g, "xx").length;
        length = length < temp ? temp : length
      }
    }
    switch (true) {
      case length > 21:
        height = 92
        break;
      case length > 13:
        height = 61
        break;
      default:
        break;
    }
    return height
  }
  updateDimensions = () => {
    this.setState({
      isMobile: window.innerWidth < 960,
    });
    // console.log(this.state.width);
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  getSelected = (item) => {
    let pickPlan = this.props.tabs.list[this.state.currentTab].pickPlan
    let result = false
    pickPlan.map((pick) => {
      if (item.indexOf(pick) !== -1) {
        result = true
      }
    })
    return result
  }
  clear = () => {
    this.onChange('filter', '');
    this.setState({
      defaultChecked: "",
    })
    setTimeout(() => {
      this.setState({
        checkKey: this.state.checkKey + 1
      })
    }, 100);
    this.forceUpdate()
  }

  render() {
    return (
      <section className={`fui-plan-table-container device-plan-table ${this.props.className ? this.props.className : ''}`}>
        <div className="fui-container">
          <h2>{this.props.title}</h2>
          {this.props.programList.length === 1 ? null : (this.props.programList.length > 3 ? <Grid container>
            <Grid item xs={12} sm={12} md={5}>
              <div className="device-table-dropdown mb-md-2 mb-0">
                <h3>{this.props.filterTitle}</h3>
                <Dropdown
                  className="is-button"
                  list={this.props.programList}
                  label={this.state.selectedList}
                  arrow={true}
                  hasCheck={false}
                  onChange={(item, idx, e) => this.spec(item, idx, e)}
                />
              </div>
            </Grid>
          </Grid> :
            <Tab
              list={this.props.programList}
              name='fui-plan-table-tab'
              default={this.state.currentTab}
              onChange={this.tabChange}
            />)
          }
          {
            this.props.tabContent.map((tab, i) => (
              <TabPane active={i === this.state.currentTab} key={`fui-plan-table-${i}`}>
                <div className='fui-plan-table is-promotion-device'>
                  <div className='fui-plan-table-header'>
                    <div className='fui-plan-table-item' style={{ height: this.getTitleLength(tab) + (this.state.isMobile ? 110 : 148) }}>
                      <div className='fui-plan-table-label d-flex flex-column' style={{ height: '100%' }}>
                        {this.props.tabs.list[this.state.currentTab].filterName}
                        <Button
                          onClick={() => { this.setState({ filterModal: true, defaultChecked: this.state.form.filter.value }) }}
                          size="small"
                          btnStyle="secondary"
                          className='plan-table-btn mt-2'
                          reverse={true}
                        >
                          篩選
                        </Button>
                      </div>
                    </div>
                    {this.props.tableTitle.map((item, i) => {
                      return (
                        <div key={item + i} className='fui-plan-table-item'>
                          <div className='fui-plan-table-label'>{item}</div>
                        </div>
                      )
                    })}
                  </div>
                  <div className='fui-plan-table-body' ref={this.planTable}>
                    <Slider {...this.slideSetting} initialSlide={this.hasSelect(tab)} slidesToShow={tab.length >= 4 ? 4 : tab.length} ref={this.setSlider}>
                      {
                        tab.map((item, k) => (
                          <div className={`fui-plan-table-segment  ${this.getSelected(item.name) ? 'is-select' : ''} `} key={`fui-plan-table-${i}-segment-${k}`}>
                            <div className='fui-plan-table-title'>
                              <h5 style={{ height: this.getTitleLength(tab) }}>{item.displayName}</h5>
                              <h1>
                                <small className='mr-1'>{item.unit}</small>
                                <span className="price mr-0 is-text-error">
                                  <span>$</span>{formatNumber.default(item.title)}
                                </span>
                              </h1>
                              <Button
                                target={item.action.target || '_self'}
                                link={item.action.link}
                                btnStyle={this.getSelected(item.name) ? 'primary' : 'secondary'}
                              >
                                {item.action.text}
                              </Button>
                            </div>
                            {!!item.detail.price ? <div>
                              <div
                                onMouseOut={e => this.setHover(-1)}
                                onMouseOver={e => this.setHover(0)}
                                className={`fui-plan-table-item ${this.state.currentHover === 0 ? 'is-hover' : ''}`}>
                                {
                                  !!item.detail.compare ? <div className='diff'>
                                    <div className="diff-item">
                                      <span>$</span>{formatNumber.default(item.detail.compare.price[0].content)}
                                      {!!item.detail.compare.price[0].tooltip ? <Tooltip
                                        parentNode={null}
                                        content={<i className='icon-information'></i>}
                                        trigger="click"
                                        tooltip={item.detail.compare.price[0].tooltip} /> : null}
                                    </div>
                                    <div className="diff-item">
                                      <span>$</span>{formatNumber.default(item.detail.compare.price[1].content)}
                                      {!!item.detail.compare.price[1].tooltip ? <Tooltip
                                        parentNode={null}
                                        content={<i className='icon-information'></i>}
                                        trigger="click"
                                        tooltip={item.detail.compare.price[1].tooltip} /> : null}
                                    </div>
                                  </div> : <div>
                                      <span>$</span>
                                      {formatNumber.default(item.detail.price.content)}
                                      {item.detail.price.tooltip ? <Tooltip
                                        parentNode={null}
                                        content={<i className='icon-information'></i>}
                                        trigger="click"
                                        tooltip={item.detail.price.tooltip} /> : null}
                                    </div>
                                }
                              </div>
                            </div> : null}
                            {!!item.detail.duration ?
                              <div
                                onMouseOut={e => this.setHover(-1)}
                                onMouseOver={e => this.setHover(1)}
                                className={`fui-plan-table-item ${this.state.currentHover === 1 ? 'is-hover' : ''}`}>
                                {
                                  item.detail.compare ? <div className='diff'>
                                    <div className="diff-item">
                                      {item.detail.compare.duration[0].content}
                                      {!!item.detail.compare.duration[0].tooltip ? <Tooltip
                                        parentNode={null}
                                        content={<i className='icon-information'></i>}
                                        trigger="click"
                                        tooltip={item.detail.compare.duration[0].tooltip} /> : null}
                                    </div>
                                    <div className="diff-item">
                                      {item.detail.compare.duration[1].content}
                                      {!!item.detail.compare.duration[1].tooltip ? <Tooltip
                                        parentNode={null}
                                        content={<i className='icon-information'></i>}
                                        trigger="click"
                                        tooltip={item.detail.compare.duration[1].tooltip} /> : null}
                                    </div>
                                  </div> :
                                    item.detail.duration.content.map((du, idx) => (
                                      <div key={`fui-plan-table-${i}-segment-${k}-duration-${idx}`}>{du}
                                        {!!item.detail.duration.tooltip ? <Tooltip
                                          parentNode={null}
                                          content={<i className='icon-information'></i>}
                                          trigger="click"
                                          tooltip={item.detail.duration.tooltip} /> : null}
                                      </div>
                                    ))
                                }

                              </div> : null}
                            <div
                              onMouseOut={e => this.setHover(-1)}
                              onMouseOver={e => this.setHover(2)}
                              className={`fui-plan-table-item ${this.state.currentHover === 2 ? 'is-hover' : ''}`}

                            >
                              {
                                item.detail.compare ? <div className='diff'>
                                  <div className="diff-item">
                                    {item.detail.compare.throughput[0].content}
                                    {!!item.detail.compare.throughput[0].tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.compare.throughput[0].tooltip} /> : null}
                                  </div>
                                  <div className="diff-item">
                                    {item.detail.compare.throughput[1].content}
                                    {!!item.detail.compare.throughput[1].tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.compare.throughput[1].tooltip} /> : null}
                                  </div>
                                </div> :
                                  <div>
                                    <div dangerouslySetInnerHTML={{ __html: item.detail.throughput.content }} />
                                    {item.detail.throughput.tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.throughput.tooltip} /> : null}
                                  </div>
                              }
                            </div>
                            <div
                              onMouseOut={e => this.setHover(-1)}
                              onMouseOver={e => this.setHover(3)}
                              className={`fui-plan-table-item ${this.state.currentHover === 3 ? 'is-hover' : ''}`}

                            >
                              {
                                item.detail.compare ? <div className='diff'>
                                  <div className="diff-item">
                                    {item.detail.compare.intranet[0].content}
                                    {!!item.detail.compare.intranet[0].tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.compare.intranet[0].tooltip} /> : null}
                                  </div>
                                  <div className="diff-item">
                                    {item.detail.compare.intranet[1].content}
                                    {!!item.detail.compare.intranet[1].tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.compare.intranet[1].tooltip} /> : null}
                                  </div>
                                </div> :
                                  <div>
                                    <div dangerouslySetInnerHTML={{ __html: item.detail.intranet.content }} />
                                    {item.detail.intranet.tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.intranet.tooltip} /> : null}
                                  </div>
                              }
                            </div>
                            <div
                              onMouseOut={e => this.setHover(-1)}
                              onMouseOver={e => this.setHover(4)}
                              className={`fui-plan-table-item ${this.state.currentHover === 4 ? 'is-hover' : ''}`}
                            >
                              {
                                item.detail.compare ? <div className='diff'>
                                  <div className="diff-item">
                                    {item.detail.compare.offnet[0].content}
                                    {!!item.detail.compare.offnet[0].tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.compare.offnet[0].tooltip} /> : null}
                                  </div>
                                  <div className="diff-item">
                                    {item.detail.compare.offnet[1].content}
                                    {!!item.detail.compare.offnet[1].tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.compare.offnet[1].tooltip} /> : null}
                                  </div>
                                </div> :
                                  <div>
                                    <div dangerouslySetInnerHTML={{ __html: !item.detail.offnet.content || item.detail.offnet.content === '' ? '-' : item.detail.offnet.content }} />
                                    {item.detail.offnet.tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.offnet.tooltip} /> : null}
                                  </div>
                              }
                            </div>
                            <div
                              onMouseOut={e => this.setHover(-1)}
                              onMouseOver={e => this.setHover(5)}
                              className={`fui-plan-table-item ${this.state.currentHover === 5 ? 'is-hover' : ''}`}
                            >
                              {
                                item.detail.compare ? <div className='diff'>
                                  <div className="diff-item">
                                    {item.detail.compare.limitedGift[0].content}
                                    {!!item.detail.compare.limitedGift[0].tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.compare.limitedGift[0].tooltip} /> : null}
                                  </div>
                                  <div className="diff-item">
                                    {item.detail.compare.limitedGift[1].content}
                                    {!!item.detail.compare.limitedGift[1].tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.compare.limitedGift[1].tooltip} /> : null}
                                  </div>
                                </div> :
                                  <div>
                                    <div dangerouslySetInnerHTML={{ __html: !item.detail.limitedGift.content || item.detail.limitedGift.content === '' ? '-' : item.detail.limitedGift.content }} />
                                    {item.detail.limitedGift.tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.limitedGift.tooltip} /> : null}
                                  </div>
                              }
                            </div>
                            {!!item.detail.geolocation ? <div
                              onMouseOut={e => this.setHover(-1)}
                              onMouseOver={e => this.setHover(5)}
                              className={`fui-plan-table-item ${this.state.currentHover === 5 ? 'is-hover' : ''}`}
                            >
                              {
                                item.detail.compare ? <div className='diff'>
                                  <div className="diff-item">
                                    {item.detail.compare.geolocation[0].content}
                                    {!!item.detail.compare.geolocation[0].tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.compare.geolocation[0].tooltip} /> : null}
                                  </div>
                                  <div className="diff-item">
                                    {item.detail.compare.geolocation[1].content}
                                    {!!item.detail.compare.geolocation[1].tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.compare.geolocation[1].tooltip} /> : null}
                                  </div>
                                </div> :
                                  <div>
                                    <div dangerouslySetInnerHTML={{ __html: !item.detail.geolocation.content || item.detail.geolocation.content === '' ? '-' : item.detail.geolocation.content }} />
                                    {item.detail.geolocation.tooltip ? <Tooltip
                                      parentNode={null}
                                      content={<i className='icon-information'></i>}
                                      trigger="click"
                                      tooltip={item.detail.geolocation.tooltip} /> : null}
                                  </div>
                              }
                            </div> : null}
                          </div>
                        ))
                      }
                    </Slider>
                  </div>
                </div>
                {this.state.filterModal ? <Modal
                  // onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
                  // onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
                  isOpen={this.state.filterModal}
                  contentLabel='Example Modal'
                  className='fui-prompt-modal device-filter'>
                  <div className='fui-prompt-modal-body p-4'>
                    <Formsy onValid={() => { this.setState({ formCanSubmit: true }) }} onInvalid={() => { this.setState({ formCanSubmit: false }) }}>
                      <p onClick={() => { this.clear() }} className="m-0 clean">清除全部</p>
                      <CheckboxButtons
                        ref={this.modalForm}
                        label={`篩選方案 (${this.state.form.filter.value.length !== 0 ? this.state.form.filter.value.split(',').length : 0}/${this.props.tabs.list[this.state.currentTab].filterArr.length})`}
                        name='filter'
                        checkKey={this.state.checkKey}
                        // default={this.state.form.filter.value}
                        default={this.state.defaultChecked}
                        options={this.props.tabs.list[this.state.currentTab].filterArr}
                        onChange={this.onChange}
                      />
                      <div className="page-action">
                        <Button onClick={() => { this.setState({ filterModal: false }, this.props.filtering({ filter: this.state.form.filter, tabContent: this.props.tabContent[this.state.currentTab], index: this.state.currentTab, arr: this.props.tabs.list[this.state.currentTab].filterArr })) }} link="#" className={`mb-lg-0 mb-2 ${this.state.formCanSubmit ? '' : 'disabled'}`} btnStyle='primary'>確認篩選</Button>
                        <Button onClick={() => this.setState({ filterModal: false })} link="#" className='mb-0' btnStyle="secondary">取消</Button>
                      </div>
                    </Formsy>
                  </div>
                </Modal> : null}
              </TabPane>
            ))
          }
        </div>

      </section>
    )
  }
}

DevicePlanTable.propTypes = {
  title: PropTypes.string,
  filterTitle: PropTypes.string,
  className: PropTypes.string,
  tabs: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        labe: PropTypes.string,
      }),
    ),
    scroll: PropTypes.bool,
  }),
  tabContent: PropTypes.array,
  filterArr: PropTypes.array,
  programList: PropTypes.array,
  selected: PropTypes.object,
  onChange: PropTypes.func,
  filtering: PropTypes.func,
  resetFilterContent: PropTypes.func,
}

export default DevicePlanTable;