import React from 'react';
import Link from '../../Link';
import PropTypes from 'prop-types';

class GroupExtraLinkCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEn: false,
    };
  }

  componentDidUpdate() {
    if ((typeof window !== 'undefined' && window.$isEn) !== this.state.isEn) {
      this.setState({
        isEn: typeof window !== 'undefined' && window.$isEn,
      });
    }
  }

  openFbLink = (link) => {
    window.open(
      `//www.facebook.com/sharer.php?u=${encodeURIComponent(
        typeof window !== 'undefined' ? window.location.origin : '' + link
      )}`
    );
  };

  openLineLink = (link, type) => {
    window.open(
      `//social-plugins.line.me/lineit/share?url=${encodeURIComponent(
        typeof window !== 'undefined' ? window.location.origin : '' + link
      )}`
    );
  };

  render() {
    return (
      <div className='group-extra-link-card'>
        {this.props.article.map((article, index) => {
          return (
            <div className='fui-card single-extra-link-card' key={article.title + index}>
              <Link
                to={article.link}
                target={article.target}
                className='article-img'
                style={{ backgroundImage: `url(${article.img})` }}>
                {/* <img src={article.img} srcSet={article.retinaImg || article.img} alt={article.alt} /> */}
              </Link>
              {article.ribbon ? <div className='fui-card-ribbon'>{article.ribbon}</div> : null}
              <div className='article-content'>
                <Link to={article.link} target={article.target}>
                  {!!article.period ? <small className='periodical'>第{article.period}期</small> : null}
                  {!!article.articleType ? <small>{article.articleType}</small> : null}
                  {
                    // TODO: 暫時隱藏
                    /* {!!article.date ? <small className='date'>{article.date}</small> : null} */
                  }
                  <h4>{article.title}</h4>
                  <p>{article.brief}</p>
                </Link>
                <div className='fui-card-extra'>
                  <Link to={article.link} target={article.target} className='fui-button is-arrow mb-0'>
                    {this.state.isEn ? 'More' : '看更多'}
                  </Link>
                  <div className='social'>
                    <i onClick={() => this.openFbLink(article.link)} className={`icon-facebook-sm`}></i>
                    <i onClick={() => this.openLineLink(article.link)} className={`icon-line-sm`}></i>
                    {/* <i className={`icon-facebook-sm`}></i>
                    <i className={`icon-line-sm`}></i> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

GroupExtraLinkCard.propTypes = {
  article: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string,
      retinaImg: PropTypes.string,
      period: PropTypes.string,
      date: PropTypes.string,
      title: PropTypes.string,
      brief: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};
export default GroupExtraLinkCard;
