import React, { Component } from 'react'
import Button from '../../components/Button';
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import CollapsePaper from '../../components/collapse/CollapsePaper';
import Link from '../../components/Link';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import { Grid } from '@material-ui/core';

class Recommend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasRedommand: false,
      monthList: [
        {
          text: '2020/04月份',
          value: '2020/04月份'
        },
      ],
      selectMonth: '2020/04月份',
    }
  }
  selectMonth = (val) => {
    console.log(`val`, val);
    this.setState({
      selectMonth: val
    })
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="推薦費率查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section className="recommend">
          {this.state.hasRecommend ? <div className="fui-container">
            <h4 className="is-text-darkgray50 mb-7">
              根據您近期的通話/上網使用情況，專屬推薦最節省的費率方案。
              </h4>

            <h2 className="mb-3">綜合費率</h2>
            <div className="fui-segments">
              <div className="paper mb-0">
                <div className="d-flex justify-between flex-align-center mt-md-4 mt-md-2 mt-0 mb-md-2 mb-3">
                  <h4 className='m-0'>費率狀況：已使用最適費率</h4>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <div className="rate-card">
                      <img src="/resources/cbu/img/ic-rate-plan-1399@2x.png" alt="ic-rate-plan-1399" />
                      <h5 className='mt-3 mb-2'>4G新絕配1399</h5>
                      <Button link="#" className='disabled mb-0' btnStyle='primary' size='medium'>目前費率</Button>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="rate-card">
                      <div className="tag tag--red">
                        推薦費率
                      </div>
                      <img src="/resources/cbu/img/ic-rate-plan-1599@2x.png" alt="ic-rate-plan-1599" />
                      <h5 className='mt-3 mb-2'>4G新絕配1599</h5>
                      <Button link="#" className='mb-0' btnStyle='primary' size='medium'>變更費率</Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Grid className='mt-md-7 mt-3 mb-3' container spacing={2}>
              <Grid item xs={12} md={8}>
                <h2 className="m-0">單月費率</h2>
              </Grid>
              <Grid item xs={12} md={4}>
                <Dropdown
                  className="is-button ml-0"
                  list={this.state.monthList}
                  label={this.state.selectMonth}
                  arrow={true}
                  hasCheck={false}
                  onChange={(item) => this.selectMonth(item)}
                />
              </Grid>
            </Grid>
            <div className="fui-segments">
              <div className="paper mb-0">
                <div className="d-flex justify-between flex-align-center mt-md-4 mt-md-2 mt-0 mb-md-2 mb-3">
                  <h4 className='m-0'>通話/上網使用情況</h4>
                </div>
                <table className="text-wrap odd table-fixed">
                  <tbody>
                    <tr >
                      <td className="align-center is-bg-white is-text-medium">語音使用量明細</td>
                      <td className="align-left  pl-md-5 pl-2">
                        - 網內通話 78 分鐘<br />
                        - 網外通話 26 分鐘<br />
                        - 市話通話 12 分鐘
                      </td>
                    </tr>
                    <tr>
                      <td className="align-center is-bg-white is-text-medium">上網使用量明細</td>
                      <td className="align-left  pl-md-5 pl-2">
                        實際上網使用 44,426MB
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="d-flex justify-between flex-align-center mt-md-7 mt-md-2 mt-4 mb-md-4 mb-3">
                  <h4 className='m-0'>費率狀況：建議選擇推薦費率</h4>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <div className="rate-card">
                      <img src="/resources/cbu/img/ic-rate-plan-1399@2x.png" alt="ic-rate-plan-1399" />
                      <h5 className='mt-3 mb-2'>4G新絕配1399</h5>
                      <Button link="#" className='disabled mb-0' btnStyle='primary' size='medium'>目前費率</Button>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="rate-card">
                      <div className="tag tag--red">
                        推薦費率
                      </div>
                      <img src="/resources/cbu/img/ic-rate-plan-2699@2x.png" alt="ic-rate-plan-2699" />
                      <h5 className='mt-3 mb-2'>4G新絕配1599</h5>
                      <Button link="#" className='mb-0' btnStyle='primary' size='medium'>變更費率</Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div> : <div className="fui-container"><div className='paper'>
            <p className="body m-0 is-text-darkgray50">親愛的用戶，您的門號目前尚未有推薦費率可查詢，謝謝。</p>
          </div></div>}
        </section>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            1. 本服務係依據您最近六個月之實際用量進行試算後，提供的費率推薦。實際用量資料若少於六個月，則無資料之月份將顯示「無資料」。<br/>
            2. 上述推薦費率的試算係以牌價為基礎，未包含您專屬的「合約贈送」優惠及「月租費內含」的內容，並不考慮國際電話及國際漫遊的相關費用，試算週期為推薦月份的上一個月1日至月底，而非以帳單週期計算，如您近日已有變更費率方案或變更通話使用行為，推薦的資費將可能改變。此資訊僅供參考。<br/>
            3. 如您欲依據推薦資費進行變更時，仍有合約及優惠限制，將無法立即進行變更。
          `}
        />
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ hasRecommend: false })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.3.2.2_合約費率_推薦費率查詢_空值</Button>
              <Button onClick={() => this.setState({ hasRecommend: true })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.3.2.1_合約費率_推薦費率查詢_主頁</Button>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Recommend;