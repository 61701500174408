import React from 'react';
import NavTab from '../../components/partials/NavTab';
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import AnchorDetect from '../../components/AnchorDetect';
import BannerPromotionBasic from '../../components/partials/banner/BannerPromotionBasic';
import DevicePlanTable from '../../components/partials/DevicePlanTable';
import CardFeature from '../../components/partials/card/CardFeature';
import CardRecommend from '../../components/partials/CardRecommend';
import QuoteBanner from '../../components/partials/QuoteBanner';
import CardProduct from '../../components/partials/CardProduct';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import OnVisible, { setDefaultProps } from 'react-on-visible';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import SectionAd3 from '../../components/partials/SectionAd3';
import axios from 'axios';
import * as Mock from '../../mock/Exclusive';
let PlanMock = null
class PromoteDevice extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.form = React.createRef();
    this.state = {
      promotion: {
        className: 'instagram mt-3 mt-md-0',
        title: '#遠傳樂享1加1',
        socialMedia: {
          line: {
            link: "#",
            target: "_blank"
          },
          facebook: {
            link: "#",
            target: "_blank"
          },
          title: "分享遠傳優惠"
        },
        cards: [
          {
            image: '/resources/cbu/img/cbu-promotion-post-1@2x.jpg',
            title: 'syuyikai',
            description: '挑戰✨遠傳iPhone11 樂享1+1 ✨ Kobe曾經在場上相Jordan討教投籃竅，...',
            link: "#",
          },
          {
            image: '/resources/cbu/img/cbu-promotion-post-2@2x.jpg',
            title: 'yylo11',
            description: '❤️遠傳iPhone11 樂享1+1❤️ 搶到跟自己打架的優惠在這兒💨',
            link: '#',
          },
          {
            image: '/resources/cbu/img/cbu-promotion-post-3@2x.jpg',
            title: 'uj85_',
            description: '遠傳iPhone11 樂享1+1 跟大家分享遠傳的優惠購機方案 🔥🔥',
            link: '#',
          },
          {
            image: '/resources/cbu/img/cbu-promotion-post-3@2x.jpg',
            title: 'ponponboxbox!',
            description: '遠傳iPhone11 樂享1+1 有人跟我一樣被iPhone11燒到嗎⁉️ 升級…',
            link: '#',
          },
        ]
      },
      productInfo: [{
        title: '現在，給自己一個寵i的機會',
        hotTag: '熱門商品',
        tag: ['5G手機'],
        name: 'iPhone SE 紅色',
        list: [
          { value: '0', text: '128 GB' },
          { value: '1', text: '256 GB' },
        ],
        brand: 'APPLE',
        image: [
          '/resources/cbu/img/estore-product-thumb-01@2x.jpg',
          '/resources/cbu/img/estore-product-thumb-01@2x.jpg',
          '/resources/cbu/img/estore-product-thumb-01@2x.jpg',
          '/resources/cbu/img/estore-product-thumb-01@2x.jpg'
        ]
      }],
      selectedPlan: {
        banner: {
          image: { md: '', sm: '' }, title: '', description: '', actions: [{ text: '', link: '' }]
        },
        promotionCard: { title: '', cards: [] },
        parentPromotion: { image: { md: '', sm: '' }, title: '', description: '', content: '', actions: { ios: { image: { md: '', sm: '' } }, android: { image: { md: '', sm: '' } } } }
      },
      program: [
        {
          icon: '/resources/cbu/img/cbu-promotion-plan-1@2x.png',
          iconAlt: 'program1',
          name: 'iPhoneSE',
          title: '０元拿手機',
          list: [
            '吃到飽不降速',
            '每月網外送200分鐘'
          ],
          tag: {
            text: '最經濟',
            color: 'red'
          },
          button: [
            {
              title: '了解更多',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          icon: '/resources/cbu/img/cbu-promotion-plan-2@2x.png',
          iconAlt: 'program1',
          name: 'iPhone11 ',
          title: '好禮1+1 輕鬆帶走AirPods',
          list: [
            '搭配無限充電盒',
            '會員續約再折$5,000'
          ],
          tag: {
            text: '最佳續約',
            color: 'red'
          },
          button: [
            {
              title: '了解更多',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          icon: '/resources/cbu/img/cbu-promotion-plan-3@2x.png',
          iconAlt: 'program1',
          name: 'iPhoneSE ',
          title: '最省荷包拿大容量',
          list: [
            '專案價只貼$8,400',
            '送$500配件購物金'
          ],
          tag: {
            text: '最熱門',
            color: 'red'
          },
          button: [
            {
              title: '了解更多',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          icon: '/resources/cbu/img/cbu-promotion-plan-3@2x.png',
          iconAlt: 'program1',
          name: 'iPhoneSE ',
          title: '輕量版',
          list: [
            '加量不加價，升級4GB',
            '流量可自動無限展延'
          ],
          tag: {
            text: '最熱門',
            color: 'red'
          },
          button: [
            {
              title: '了解更多',
              link: '#',
              target: '_self'
            }
          ]
        }
      ],
    }
    this.getExclusiveData().then(result => {
      PlanMock = result;
      this.setExclusiveData();
    });
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  getExclusiveData = (val) => {
    return axios('/json/exclusive.json').then(result => {
      return JSON.parse(JSON.stringify(result.data));
    })
  }

  setExclusiveData = () => {
    let planTab = [];
    let activePlan = 0;
    let activeTab = 0;
    let activeNum = 0;
    let activeNav = this.props.match.params.plan ? PlanMock.navTab.find((tab, i) => {
      if (this.props.location.pathname === tab.name) {
        activeNum = i
        return tab;
      }
    }) : PlanMock.navTab[0];

    PlanMock.plan.forEach((plan, i) => {
      if (this.props.match.params.plan === plan.title) {
        activePlan = i
      }
      // BannerTab
      // PlanTab
      PlanMock.planTabs.tabs.forEach((tab, k) => {
        if (!planTab[k]) planTab[k] = [];
        tab.child.forEach(ch => {
          if (activeNav.name === ch.name) {
            activeTab = k
          }
          if (ch.name === plan.name) {
            planTab[k].push(plan)
          }
        })
      });
    })
    this.setState({
      query: this.props.match.params.plan || '',
      defaultTab: activeNum,
      navTabs: PlanMock.navTab,
      selectedPlan: PlanMock.plan[activePlan],
      planTabs: {
        title: PlanMock.planTabs.title,
        tableTitle: PlanMock.planTabs.tableTitle,
        tabs: {
          scroll: true,
          list: PlanMock.planTabs.tabs.map(tab => {
            return {
              label: tab.name,
              filterArr: tab.filterArr,
              filterName: tab.filterName,
              pickPlan: tab.pickPlan,
            }
          }),
          default: activeTab,
        },
        tabContent: planTab
      },
    })

    this.forceUpdate()
    // console.log("updated");
  }

  spec = (item, idx, e) => {
    console.log({ item, idx, e });
  }
  // 根據DevicePlanTable的選擇來打API //
  tableOnChange = (val) => {
    this.getExclusiveData(val).then(result => {
      PlanMock = result;
      this.setExclusiveData();
    });
  }
  filtering = (val) => {
    if (!!val.filter.value) {
      let filterArr = []
      val.filter.value.split(",").map((key) => {
        filterArr.push(val.arr[key - 1].label)
      })
      let active = []
      let second = []
      let final = []
      val.tabContent.forEach((plan) => {
        filterArr.map((filter) => {
          // console.log(plan);
          if (plan.name.indexOf(filter) > -1) {
            active.push(plan)
          } else {
            second.push(plan)
          }
        })
      })
      // console.log({ active, second });

      final = [...active, ...second]

      var result = new Set();
      var repeat = new Set();
      final.forEach(item => {
        result.has(item) ? repeat.add(item) : result.add(item);
      })
      final = [...result]
      // console.log(final);


      let planTab = [];
      let activePlan = 0;
      let activeTab = 0;
      let activeNum = 0;
      let activeNav = this.props.match.params.plan ? PlanMock.navTab.find((tab, i) => {
        if (this.props.location.pathname === tab.name) {
          activeNum = i
          return tab;
        }
      }) : PlanMock.navTab[0];

      // console.log(`this.props.location.pathname: `, this.props.location.pathname);
      // console.log(`activeNav: `, activeNav);

      PlanMock.plan.forEach((plan, i) => {
        if (this.props.match.params.plan === plan.title) {
          activePlan = i
        }
        // BannerTab
        // PlanTab
        PlanMock.planTabs.tabs.forEach((tab, k) => {
          if (!planTab[k]) planTab[k] = [];
          tab.child.forEach(ch => {
            if (activeNav.name === ch.name) {
              activeTab = k
            }
            if (ch.name === plan.name) {
              planTab[k].push(plan)
            }
          })
        });
      })
      planTab[val.index] = final

      this.setState({
        query: this.props.match.params.plan || '',
        defaultTab: activeNum,
        navTabs: PlanMock.navTab,
        selectedPlan: PlanMock.plan[activePlan],
        planTabs: {
          title: PlanMock.planTabs.title,
          tableTitle: PlanMock.planTabs.tableTitle,
          tabs: {
            scroll: true,
            list: PlanMock.planTabs.tabs.map((tab, i) => {
              if (i === val.index) {
                return {
                  label: tab.name,
                  filterArr: tab.filterArr,
                  filterName: tab.filterName,
                  pickPlan: filterArr,
                }
              } else {
                return {
                  label: tab.name,
                  filterArr: tab.filterArr,
                  filterName: tab.filterName,
                  pickPlan: tab.pickPlan,
                }
              }
            }),
            default: activeTab,
          },
          tabContent: planTab
        },
      })
      this.forceUpdate()
    } else {
      this.resetFilterContent();
    }
  }
  onChange(name, value) {
    // console.log({ name, value });
    let form = Object.assign(this.state.form);
    form[name].value = value;
    this.setState({
      form: form,
    });
  }
  disableButton(model, resetForm, invalidateForm) {
    // debugger
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        if (window.isIE) {
          let elem = window || document.documentElement
          elem.scroll(0, document.getElementsByClassName('is-invalid')[0].offsetTop);
        } else
          window.scrollTo(0, document.getElementsByClassName('is-invalid')[0].offsetTop);
      }, 100);
    }
    this.setState({ canSubmit: false });
  }

  enableButton(model, resetForm, invalidateForm) {
    // debugger
    this.setState({ canSubmit: true });
  }

  filtering = (val) => {
    if (!!val.filter.value) {
      let filterArr = []
      val.filter.value.split(",").map((key) => {
        filterArr.push(val.arr[key - 1].label)
      })
      let active = []
      let second = []
      let final = []
      val.tabContent.forEach((plan) => {
        filterArr.map((filter) => {
          // console.log(plan);
          if (plan.name.indexOf(filter) > -1) {
            active.push(plan)
          } else {
            second.push(plan)
          }
        })
      })
      // console.log({ active, second });

      final = [...active, ...second]

      var result = new Set();
      var repeat = new Set();
      final.forEach(item => {
        result.has(item) ? repeat.add(item) : result.add(item);
      })
      final = [...result]
      // console.log(final);


      let planTab = [];
      let activePlan = 0;
      let activeTab = 0;
      let activeNum = 0;
      let activeNav = this.props.match.params.plan ? PlanMock.navTab.find((tab, i) => {
        if (this.props.location.pathname === tab.name) {
          activeNum = i
          return tab;
        }
      }) : PlanMock.navTab[0];

      // console.log(`this.props.location.pathname: `, this.props.location.pathname);
      // console.log(`activeNav: `, activeNav);

      PlanMock.plan.forEach((plan, i) => {
        if (this.props.match.params.plan === plan.title) {
          activePlan = i
        }
        // BannerTab
        // PlanTab
        PlanMock.planTabs.tabs.forEach((tab, k) => {
          if (!planTab[k]) planTab[k] = [];
          tab.child.forEach(ch => {
            if (activeNav.name === ch.name) {
              activeTab = k
            }
            if (ch.name === plan.name) {
              planTab[k].push(plan)
            }
          })
        });
      })
      planTab[val.index] = final

      this.setState({
        query: this.props.match.params.plan || '',
        defaultTab: activeNum,
        navTabs: PlanMock.navTab,
        selectedPlan: PlanMock.plan[activePlan],
        planTabs: {
          title: PlanMock.planTabs.title,
          tableTitle: PlanMock.planTabs.tableTitle,
          tabs: {
            scroll: true,
            list: PlanMock.planTabs.tabs.map((tab, i) => {
              if (i === val.index) {
                return {
                  label: tab.name,
                  filterArr: tab.filterArr,
                  filterName: tab.filterName,
                  pickPlan: filterArr,
                }
              } else {
                return {
                  label: tab.name,
                  filterArr: tab.filterArr,
                  filterName: tab.filterName,
                  pickPlan: tab.pickPlan,
                }
              }
            }),
            default: activeTab,
          },
          tabContent: planTab
        },
      })
      this.forceUpdate()
    } else {
      this.resetFilterContent();
    }
  }
  resetFilterContent = () => {
    this.setExclusiveData();
    this.forceUpdate()
  }
  render() {
    return (
      <main className='life-circle article'>
        <NavTab onChange={this.changeTab} {...{
          pageTitle: '寵i獻禮',
          tabs: {
            name: 'help-center',
            list: [
              { name: 'tab-1', label: 'iPhone SE', link: '#iPhone_SE' },
              { name: 'tab-2', label: 'iPhone11', link: '#iPhone11' },
              { name: 'tab-3', label: 'iPhoneXS/XR', link: '#iPhoneXS' },
              { name: 'tab-4', label: 'iPhoneXS/XR', link: '#iPhoneXR' },
              { name: 'tab-5', label: 'iPhone7', link: '#iPhone7' },
            ],
          },
          buttons: [
            {
              text: '新申辦',
              btnStyle: 'primary',
              link: '/'
            },
            {
              text: '推薦續約',
              btnStyle: 'secondary',
              link: '/'
            }
          ],
          default: 0
        }} />
        <AnchorDetect className="vertical-anchor-nav" items={['anchor0', 'anchor1', 'anchor2', 'anchor3', 'anchor4']} activeClass="active" offsetTop={145}>
          <li><span>遠傳獨享</span></li>
          <li><span>寵i機會</span></li>
          <li><span>超值方案</span></li>
          <li><span>隱藏菜單</span></li>
          <li><span>1加1</span></li>
        </AnchorDetect>
        <BannerPromotionBasic
          bgColor="#fff"
          slides={[
            {
              image: {
                md: '/resources/cbu/img/cbu-promotion-device-1920x500.jpg',
                sm: '/resources/cbu/img/cbu-promotion-students-750x1000.jpg'
              },
              tag: "網路限定",
              title: '買 iPhone SE 到遠傳，最經濟',
              description: '享受不用等，iPhone SE現貨開賣',
              actions: [
                {
                  btnStyle: 'primary',
                  target: '_blank',
                  type: 'link',
                  text: '申辦4.５G方案',
                  link: '#'
                },
                {
                  btnStyle: 'secondary',
                  target: '_blank',
                  type: 'link',
                  text: '拿好禮1+1',
                  link: '#'
                }
              ]
            }
          ]} openModal={this.openModal} />
        <OnVisible id="anchor0">
          <CardFeature {...{
            "title": "在遠傳買，你能獨享...",
            "cards": [
              {
                "type": "card01",
                "image": "/resources/cbu/img/cbu-promotion-feature-movie@2x.jpg",
                "title": "用Retina HD螢幕，享受免費的高畫質電影",
                "list": [
                  {
                    "text": "friDay影音90天免費看"
                  },
                  {
                    "text": "加購friDay影音娛樂包再折$1,100"
                  }
                ]
              },
              {
                "type": "card01",
                "image": "/resources/cbu/img/cbu-promotion-feature-broken@2x.jpg",
                "title": "新手機，讓遠傳幫你顧",
                "list": [
                  {
                    "text": "申辦保險享App Store購物金300"
                  },
                  {
                    "text": "全省遠傳門市送修服務"
                  }
                ]
              },
              {
                "type": "promotion",
                "title": "好禮1+1帶走AirPods享38折，貼補你 iPhone SE 的配件費換手機一次到位",
                "bg": "/resources/cbu/exclusive/cbu-feature-card-red-390x638.jpg",
                "action": {
                  "btnStyle": "primary",
                  "text": "馬上申辦",
                  "link": "#",
                  "target": "_self"
                }
              },
              {
                "type": "card01",
                "image": "/resources/cbu/img/cbu-promotion-feature-movie@2x.jpg",
                "title": "續約好康 1+1",
                "list": [
                  {
                    "text": "申辦保險享App Store購物金300"
                  },
                  {
                    "text": "全省遠傳門市送修服務"
                  }
                ]
              }
            ]
          }} />
        </OnVisible>
        <QuoteBanner
          className="mt-md-0 mt-5"
          topBg={{
            md: '/resources/cbu/img/cbu-feature-text-green-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-feature-text-green-750x708.jpg'
          }}
          content={`<h2 class='with-comma is-text-white m-0'>
              若你是攜碼，或我們的老客戶續約...<br />
                謝謝你選擇我們，遠傳再幫你 <span class="border-bottom is-blue30">省下萬元預繳金</span>
            </h2>`}
        />
        <OnVisible id="anchor1">
          <CardProduct
            title='CardProduct'
            list={this.state.productInfo}
            onChange={(e) => this.onChange(e)}
          />
        </OnVisible>
        <OnVisible id="anchor2">
          {!PlanMock ? null : <DevicePlanTable
            filterTitle="規格"
            {...this.state.planTabs}
            filterArr={this.state.filterArr}
            resetFilterContent={this.resetFilterContent}
            programList={
              [
                { value: '32 GB', text: '32 GB', label: '32 GB' },
                { value: '64 GB', text: '64 GB', label: '64 GB' },
                { value: '128 GB', text: '128 GB', label: '128 GB' },
                { value: '256 GB', text: '256 GB', label: '256 GB' },
              ]
            }
            selected={this.state.selectedPlan}
            filtering={(val) => { this.filtering(val) }}
            onChange={(val) => this.tableOnChange(val)}
          />}
        </OnVisible>
        <OnVisible id="anchor3">
          <CardRecommend
            program={this.state.program}
            title="看看網路版隱藏菜單"
          />
          <SectionCollapseInfo
            title="貼心小叮嚀"
            content={`
                <ol>
                  <li>此功能不需帳號及密碼，只要輸入身分資料完成登入檢核就可進行繳費。</li>
                  <li>快速登入後僅能進行繳費功能，若需使用其他功能，請先進行登入。</li>
                  <li>繳納費用非一般性消費支出，請洽各發卡銀行確認是否有刷卡紅利或現金回饋。</li>
                </ol>
              `}
          />
        </OnVisible>
        <OnVisible id="anchor4">
          <SectionCarousel1 {...this.state.promotion} />
        </OnVisible>
        <SectionAd3
          {...{
            patternColor: 'green',
            title: `在遠傳買iPhone手機最有保障，手機問題就找全省遠傳門市和德誼維修中心。`,
            action: [{
              text: '了解更多',
              line: '#',
              target: '_blank',
            }]
          }
          }
        />
        <div className="is-bg-lightgray70">
          <div id="faq" className="fui-container pt-4">
            <SectionFaq {...{
              title: '常見問題',
              collapseList: [
                {
                  title: '已於網路門市成功的門號是否仍可換號？',
                  content: `已於網路門市成功的門號是否仍可換號？`,
                },
                {
                  title: '拿到SIM卡後，門號何時開通？',
                  content: `拿到SIM卡後，門號何時開通？`,
                },
                {
                  title: '為什麼有門號數量購買的限制？',
                  content: `為什麼有門號數量購買的限制？`,
                },
                {
                  title: '網路門市中可申辦的門號是否會定期更新？',
                  content: `網路門市中可申辦的門號是否會定期更新？`,
                },
                {
                  title: '宅配取貨需準備哪些證件？',
                  content: `宅配取貨需準備哪些證件？`,
                },
                {
                  title: '網路門市申辦攜碼移入生效時間與實體門市之差異？',
                  content: `網路門市申辦攜碼移入生效時間與實體門市之差異？`,
                },
                {
                  title: '訂單成立後要如何更換手機款式？',
                  content: `訂單成立後要如何更換手機款式？`,
                },
                {
                  title: '如何查詢申辦進度？',
                  content: `如何查詢申辦進度？`,
                },
                {
                  title: '我是否可以幫他人在網路門市申辦門號？',
                  content: `我是否可以幫他人在網路門市申辦門號？`,
                },
                {
                  title: '為什麼找不到我想買的廠牌/款式？',
                  content: `為什麼找不到我想買的廠牌/款式？`,
                },
              ],
              container: false,
              to: '#',
              target: '_blank'
            }} />
          </div>
        </div>
      </main >
    )
  }
}

export default PromoteDevice;