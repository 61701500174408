import React, { Component } from 'react'
import Slider from 'react-slick';
import PropTypes from 'prop-types';

class BrandCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.settings = {
      dots: true,
      autoPlay: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: true,
      arrows: false
    }
  }
  render() {
    return (
      <section className="partner" id={this.props.id}>
        <h5 className="w-100 align-center is-text-medium">合作夥伴</h5>
        <Slider {...this.settings} className="horizontal-cards dark-dot">
          {this.props.partner.map(page => {
            return (
              <div className="fui-container" key={page.id}>
                <div className="brands-wrapper">
                  {page.content.map((brand, i) => {
                    return (
                      <div key={brand.alt + i}>
                        <img src={brand.url} alt={brand.alt} />
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </Slider>
      </section>
    );
  }
}

BrandCarousel.propTypes = {
  id: PropTypes.string,
  partner: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      content: PropTypes.array
    })
  )
}

export default BrandCarousel;