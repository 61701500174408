import React, { Component } from 'react';
import Link from './Link';
import PropTypes from 'prop-types';

class SocialMedia extends Component {
  constructor(props) {
    super(props);
  }

  linkFormat(link) {
    let linkString =
      link.indexOf('//') > -1 ? link : typeof window !== 'undefined' ? window.location.origin + link : link;

    return encodeURIComponent(linkString);
  }

  render() {
    return (
      <div className={`social-media d-flex flex-align-center ${this.props.theme ? 'is-' + this.props.theme : ''}`}>
        <div
          className='d-inline-block mr-2'
          dangerouslySetInnerHTML={{
            __html: this.props.displayContent,
          }}></div>
        {!!this.props.lineLink ? (
          <Link
            to={`//social-plugins.line.me/lineit/share?url=${this.linkFormat(this.props.lineLink)}`}
            target='_blank'>
            <i style={!!this.props.size ? { fontSize: this.props.size } : null} className={`icon-line-sm`}></i>
          </Link>
        ) : null}
        {!!this.props.fbLink ? (
          <Link to={`//www.facebook.com/sharer.php?u=${this.linkFormat(this.props.fbLink)}`} target='_blank'>
            <i style={!!this.props.size ? { fontSize: this.props.size } : null} className={`icon-facebook-sm`}></i>
          </Link>
        ) : null}
      </div>
    );
  }
}

SocialMedia.propTypes = {
  fbLink: PropTypes.string,
  lineLink: PropTypes.string,
  displayContent: PropTypes.string,
  size: PropTypes.number,
  theme: PropTypes.string,
};

export default SocialMedia;
