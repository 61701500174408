import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const CustomizedTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgb(104,119,139)",
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(16),
    border: "1px solid #dadde9",
    padding: "20px 15px",
  },
}))(Tooltip);

export default function TitleTooltip(props) {
  return (
    <CustomizedTooltip
      arrow //箭頭
      enterTouchDelay={0} //手機版長按秒數
      leaveTouchDelay={2500} //手機版顯示秒數
      title={<React.Fragment>{props.title}</React.Fragment>}
    >
      <div className="fui-tooltip-container">
        <button className="fui-button is-text">
          <i className="icon-information"></i>
        </button>
      </div>
    </CustomizedTooltip>
  );
}
