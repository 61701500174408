import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

import Link from '../../components/Link';

import SectionBanner2 from '../../components/partials/banner/SectionBanner2';

import * as Mock from '../../mock/OriginNumberRoaming';

class BuyLocalCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      bgImg: {
        md: '/resources/cbu/roaming/cbu-roaminghome1-banner-1920x470.jpg',
        sm: '/resources/cbu/roaming/cbu-roaminghome1-banner-1920x470.jpg',
      },
      keyword: '',
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        this.setState({
          isMobile: window.innerWidth < 768,
        });
      });
  }


  render() {
    return (
      <main className="">
        <NavAnchor
          pageTitle='<a href="#">出國上網卡 - 遠遊卡</a>'
          button={{
            link: '#',
            text: '回遠遊卡首頁',
          }}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '出國上網卡 - 遠遊卡', link: '/' },
            { text: '遠遊卡購買管道', link: '/' }
          ]}
          color='white'
        />

        <SectionBanner2
          className='is-360 only-title'
          image={{
            md: '/resources/cbu/roaming/cbu-Roamingshop-banner-1920x360.jpg',
            sm: '/resources/cbu/roaming/cbu-roamingshop-750x400.jpg',
          }}
          title='遠遊卡購買管道'
        />

        <section className="origin-number-roaming buy-local-card pb-0">
          <div className="fui-container">

            <section className="pt-0 pt-lg-4 pb-4 pb-lg-6">
              <h2 className="">線上購物網站</h2>
              <SectionBanner2
                className='is-text-white is-295 mb-2 mb-lg-4'
                image={{
                  md: '/resources/cbu/roaming/img-onlinestore-web@2x.jpg',
                  sm: '/resources/cbu/roaming/img-onlinestore-mobile@2x.jpg',
                }}
                title='遠傳網路門市'
                description='免運宅配到府，到遠傳網路門市買遠遊卡就對了！'
                action={{
                  text: '立即選購'
                }}
              />
              

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Link to="#" className='paper-link external'>
                    <div className="title">遠遊卡好康 APP</div>
                    <div className="desc">買卡拿優惠！</div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Link to="#" className='paper-link external'>
                    <div className="title">friDay購物</div>
                    <div className="desc">f幣回饋 買多賺多！</div>
                  </Link>
                </Grid>
              </Grid>
            </section>


            <section className="pt-0">
              <h4 className="mb-2">其他線上購買管道</h4>
              <div className="channel-list">
                <Link to="#" className='mr-lg-4 mb-2'>PChome 購物</Link>
                <Link to="#" className='mr-lg-4 mb-2'>燦坤線上購物</Link>
                <Link to="#" className='mr-lg-4 mb-2'>五福旅遊</Link>
                <Link to="#" className='mr-lg-4 mb-2'>KLOOK客路</Link>
              </div>
            </section>


            <section className="pt-0 pb-6">
              <h2 className="">實體門市</h2>
              <SectionBanner2
                className='is-text-white is-295 mb-2 mb-lg-4'
                image={{
                  md: '/resources/cbu/roaming/img-store-web@2x.jpg',
                  sm: '/resources/cbu/roaming/img-store-mobile@2x.jpg',
                }}
                title='遠傳門市'
                description='現在就搜尋離你最近的遠傳門市，買張遠遊卡吧！'
                action={{
                  text: '查詢遠傳門市 '
                }}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Link to="#" className='paper-link external'>
                    <div className="title">全虹通訊</div>
                    <div className="desc">提供實體卡現貨</div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link to="#" className='paper-link external'>
                    <div className="title">德誼數位</div>
                    <div className="desc">提供實體卡現貨</div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link to="#" className='paper-link external'>
                    <div className="title">燦坤 3C</div>
                    <div className="desc">提供實體卡現貨</div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link to="#" className='paper-link external'>
                    <div className="title">統一超商</div>
                    <div className="desc">ibon 機台預購</div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link to="#" className='paper-link external'>
                    <div className="title">全家超商</div>
                    <div className="desc">FamiPort 機台預購</div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Link to="#" className='paper-link external'>
                    <div className="title">OK超商</div>
                    <div className="desc">提供實體卡現貨</div>
                  </Link>
                </Grid>
              </Grid>
            </section>

            <section className="pt-0">
              <h4 className="mb-2">超商機台預購說明</h4>
              <ul class="green-dot-list is-text-black100">
                <li class="">統一超商 ibon 機台：點選「門市預購」>「目錄」>「遠遊卡」選購產品。於當日 11:30 前預購，最快次日取貨。</li>
                <li>全家超商 FamiPort 機台：點選「預購」>「悠遊卡/旅遊上網卡」>「國家分類」選購遠遊卡產品。</li>
              </ul>
            </section>


          </div>
        </section>
      </main>
    );
  }
}

export default BuyLocalCard;
