import React, { Component } from 'react';
import VideoModal from './VideoModal';
import PropTypes from 'prop-types';
class SingleVideo extends Component {
  constructor(props) {
    super(props);
    this.VideoSlideSetting = {
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 1,
      draggable: true,
    };
    this.state = {
      currentVideo: '',
      modalOpen: false,
    };
  }
  openVideoModal = (data) => {
    this.setState({
      modalOpen: true,
      alterVideo: data.alterVideo ? data.alterVideo : null,
      currentVideo: data.videoId,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      alterVideo: '',
      currentVideo: '',
    });
  };
  render() {
    return (
      <section className='fui-horzonal-cards video-carousel is-narrow p-0'>
        <h2 className='section-title m-0 mb-3 p-0'>{this.props.title}</h2>
        <div
          style={{ zIndex: 1 }}
          className='video-card'
          data-fet-key={this.props.fetKey || ''}
          onClick={() => this.openVideoModal(this.props)}>
          <div className='video-img d-none d-sm-block' style={{ backgroundImage: `url(${this.props.imgLarge})` }} />
          <div className='video-img d-block d-sm-none' style={{ backgroundImage: `url(${this.props.imgSmall})` }} />
          <div className='mask'></div>
        </div>
        <VideoModal
          open={this.state.modalOpen}
          alterVideo={this.state.alterVideo}
          videoUrl={this.state.currentVideo}
          onClose={this.closeModal}
        />
      </section>
    );
  }
}

SingleVideo.propTypes = {
  title: PropTypes.string,
  videoId: PropTypes.string,
  imgLarge: PropTypes.string,
  imgSmall: PropTypes.string,
  videoLink: PropTypes.string,
  alterVideo: PropTypes.string,
};
export default SingleVideo;
