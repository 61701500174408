import React from 'react';
import Slider from 'react-slick';
import Button from '../../Button';
import Modal from 'react-modal';

import PropTypes from 'prop-types';

class BannerPromotionBasic extends React.Component {
  constructor(props) {
    super(props);
    this.slide = React.createRef();
    this.slideSetting = {
      dots: typeof this.props.dots !== 'undefined' ? this.props.dots : true,
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      autoplay: this.props.autoplay ? this.props.autoplay : false,
      autoplaySpeed: this.props.autoplaySpeed ? this.props.autoplaySpeed : 5000,
      draggable: typeof this.props.draggable === 'undefined' ? true : this.props.draggable,
      adaptiveHeight: true,
      afterChange: (current) => this.setState({ activeSlide: current }),
    };
    this.state = {
      modalOpen: false,
      activeSlide: !!this.props.activeSlide ? this.props.activeSlide : 0,
      currentSlide: 0,
    };
  }
  componentDidUpdate() {
    if (this.props.activeSlide !== this.state.activeSlide) {
      this.slide.current.slickGoTo(this.props.activeSlide);
    }
  }

  setModalContent = (content) => {
    this.props.openModal({
      type: 'exclusive-promotion',
      ...content,
    });
  };
  getThemeColor = () => {
    let current = this.state.activeSlide;
    return this.props.slides[current].theme === 'dark' ? 'dark-theme' : '';
  };
  render() {
    return (
      <div
        className={`fui-banner is-exclusive is-promotion ${this.getThemeColor()} ${
          this.props.className ? this.props.className : ''
        }`}>
        <Slider {...this.slideSetting} ref={this.slide}>
          {this.props.slides.map((slide, i) => (
            <div
              className={`estore-banner-item ${slide.theme === 'dark' ? 'dark-theme' : ''}`}
              key={`estore-banner-${i}`}>
              <div className='bg' style={slide.bgColor ? { backgroundColor: slide.bgColor } : { backgroundColor: "#fff" }}>
                <img src={slide.image.md} alt={slide.title} className='d-none d-sm-block' />
                <img src={slide.image.sm} alt={slide.title} className='d-block d-sm-none' />
              </div>
              <div className='caption'>
                <div className='fui-container'>
                  <div className='caption-wrapper'>
                    {!!slide.tag ? (
                      <div className='tag'>
                        <div className='tag-bg'></div>
                        {slide.tag}
                      </div>
                    ) : null}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: slide.title,
                      }}
                    />
                    <div
                      className='description'
                      dangerouslySetInnerHTML={{
                        __html: slide.description,
                      }}
                    />
                    <div className='action p-0'>
                      {slide.actions.map((act, j) =>
                        act.type === 'link' ? (
                          <Button
                            link={act.link}
                            target={this.props.target || act.target}
                            btnStyle={act.btnStyle}
                            key={`estore-banner-${i}-btn-${j}`}
                            size='large'
                            data-fet-key={act.fetKey}>
                            {act.text}
                          </Button>
                        ) : (
                          <Button
                            onClick={(e) => this.setModalContent(act.modalContent)}
                            btnStyle={act.btnStyle}
                            key={`estore-banner-${i}-btn-${j}`}
                            size='large'
                            data-fet-key={act.fetKey}>
                            {act.text}
                          </Button>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='fui-arrow d-block d-sm-none'>
                <i className='icon-chevron-down'></i>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

BannerPromotionBasic.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      theme: PropTypes.string,
      tag: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          link: PropTypes.string,
          target: PropTypes.string,
        })
      ),
    }).isRequired
  ),
  className: PropTypes.string,
  bgColor: PropTypes.string,
  openModal: PropTypes.func,
};

export default BannerPromotionBasic;
