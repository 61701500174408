import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';

import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import numberFormatter from '../../utils/numberFormatter';

let bill1 = {
  detailLink: '#123',
  date: '2020年04月',
  period: '2020/03/20 至 2020/04/19',
  usage: {
    totalCall: '00:11:28',
    internet: '2.08 GB',
    payBill: '無需繳費'
  },
  fee: [
    {
      title: '電信費用',
      fee: 999,
      isRed: false
    },
    {
      title: '小額代收',
      fee: 120,
      isRed: false
    },
    {
      title: '本期新增金額合計',
      fee: 1119,
      isRed: true
    },
    {
      title: '累計應繳金額',
      fee: 0,
      isRed: true
    },
  ],
  payment: '#',
  resendPayment: '#',
  usageDetail: {
    inter: 9,
    outer: 3,
    local: 1,
    special: 0,
    internet: 0
  }
}
let bill2 = {
  detailLink: '#123',
  date: '2020年05月',
  period: '2020/04/20 至 2020/05/19',
  usage: {
    totalCall: '00:11:28',
    internet: '2.08 GB',
    payBill: '無需繳費'
  },
  fee: [
    {
      title: '電信費用',
      fee: 999,
      isRed: false
    },
    {
      title: '小額代收',
      fee: 120,
      isRed: false
    },
    {
      title: '本期新增金額合計',
      fee: 1119,
      isRed: true
    }
  ],
  payment: null,
  resendPayment: '#',
  usageDetail: {
    inter: 9,
    outer: 3,
    local: 1,
    special: 0,
    internet: 0
  }
}
let bill3 = {
  detailLink: '#123',
  date: '2020年05月',
  period: '2020/04/20 至 2020/05/19',
  usage: {
    totalCall: '00:11:28',
    internet: '2.08 GB',
    payBill: '無需繳費'
  },
  fee: [
    {
      title: '電信費用',
      fee: 999,
      isRed: false
    },
    {
      title: '小額代收',
      fee: 120,
      isRed: false
    },
    {
      title: '本期新增金額合計',
      fee: 1119,
      isRed: true
    },
    {
      title: '累計應繳金額（含未繳及溢繳金額）',
      fee: 0,
      isRed: true
    },
  ],
  payment: null,
  resendPayment: '#',
  usageDetail: null
}
class QueryIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      form: {
        id: { value: '', required: true },
      },
      selectPeriod: { value: '1', text: '2020/04月份' },
      periodOption: [
        { value: '1', text: '2020/04月份' },
        { value: '2', text: '2020/05月份' },
        { value: '3', text: '2020/06月份' },
      ],
      currentBill: bill1,
    }
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }

  selectPeriod = (val) => {
    this.setState({
      selectPeriod: val
    })
    if (val.value === '1') {
      this.setState({
        currentBill: bill1
      })
    } else if (val.value === '2') {
      this.setState({
        currentBill: bill2
      })
    } else {
      this.setState({
        currentBill: bill3
      })
    }
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={[
            {
              text: '立即續約',
              link: '#',
            },
            {
              text: '回個人專區',
              link: '/',
            },
          ]}
        />

        <FormBanner
          title="帳單查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="query-index pb-0">
          <div className="fui-container">
            <Dropdown
              className="is-button d-block d-md-none"
              list={[
                { value: '', text: '2020/04月份' },
                { value: '', text: '2020/05月份' },
              ]}
              label={this.state.selectPeriod.text}
              arrow={true}
              hasCheck={false}
              onChange={(item) => this.selectPeriod(item)}
            />
            <h2 className="mb-0">{this.state.currentBill.date}帳單</h2>
            <div className="d-flex justify-between flex-align-center">
              <div className="body is-text-darkgray50">
                費用計算期間：{this.state.currentBill.period}
              </div>
              <Dropdown
                className="is-button d-none d-md-block"
                list={this.state.periodOption}
                label={this.state.selectPeriod.text}
                arrow={true}
                hasCheck={false}
                onChange={(item) => this.selectPeriod(item)}
              />

            </div>
            <div className="paper mt-4 mb-2">
              <h4 className="mt-md-4 mt-0 mb-md-3 mb-2">
                本期使用總量
                </h4>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      通話時數
                      </h5>
                    <h2 className='mb-0'>{this.state.currentBill.usage.totalCall}</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0 d-sm-block d-flex justify-between">
                      上網用量
                        <Tooltip
                        parentNode={null}
                        content={<i className='icon-information is-text-darkgray100 ml-1 d-flex'></i>}
                        trigger="click"
                        tooltip={'此處上網用量是指您該月份帳單內所有門號的上網用量加總，各門號使用明細請至「帳單明細」查詢。'}
                      />
                    </h5>
                    <h2 className='mb-0'>{this.state.currentBill.usage.internet}</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      繳費期限
                      </h5>
                    <h2 className='mb-0'>{this.state.currentBill.usage.payBill}</h2>
                  </div>
                </Grid>
              </Grid>
              <div className="d-flex justify-between flex-align-center mt-md-2 pt-md-4 mt-4">
                <h4 className='m-0'>費用明細</h4>
                <Link className='fui-button is-secondary is-small mr-0' to={this.state.currentBill.detailLink}>
                  <span className='text'>查詢帳單明細</span>
                </Link>
              </div>
              <div className="frammer">
                {this.state.currentBill.fee.map((item, index) => {
                  return (
                    <div className="frammer--list" key={index}>
                      <h5>{item.title}</h5>
                      {item.isRed ? <h2 className='is-text-error'>{item.fee.toLocaleString()}元</h2> : <h4>{item.fee}元</h4>}
                    </div>
                  )
                })}
              </div>
              <div className='d-flex fui-buttons two-buttons'>
                {this.state.currentBill.payment ? <Button link={this.state.currentBill.payment} btnStyle='primary' size='large'>我要繳費</Button> : null}
                <Button link={this.state.currentBill.resendPayment} btnStyle="secondary" size='large'>補寄帳單</Button>
              </div>
            </div>
          </div>
        </section>
        <section className='usage-rate no-bg pb-4'>
          <div className="fui-container">
            <div className="paper">
              <div className='rate-group'>
                <h4 className="mb-2 mt-md-3 mt-0">本月通話/使用明細</h4>
                {this.state.currentBill.usageDetail ?
                  <div>
                    <p className="mt-0">此分析圖提供您門號在該月份撥打國內通話的分鐘數。</p>
                    <div className="item-wrapper">
                      <div className='rate-item'>
                        <div className='number'>
                          <h1>{this.state.currentBill.usageDetail.inter}</h1>
                          <h6 className='usage'>分鐘</h6>
                        </div>
                        <div>網內通話</div>
                      </div>
                      <div className='rate-item'>
                        <div className='number'>
                          <h1>{this.state.currentBill.usageDetail.outer}</h1>
                          <h6 className='usage'>分鐘</h6>
                        </div>
                        <div>網外通話</div>
                      </div>
                      <div className='rate-item'>
                        <div className='number'>
                          <h1>{this.state.currentBill.usageDetail.local}</h1>
                          <h6 className='usage'>分鐘</h6>
                        </div>
                        <div>市話</div>
                      </div>
                      <div className='rate-item'>
                        <div className='number'>
                          <h1>{this.state.currentBill.usageDetail.special}</h1>
                          <h6 className='usage'>分鐘</h6>
                        </div>
                        <div>特殊撥號</div>
                      </div>
                      <div className='rate-item'>
                        <div className='number'>
                          <h1>{this.state.currentBill.usageDetail.internet}</h1>
                          <h6 className='usage'>分鐘</h6>
                        </div>
                        <div>智慧網路</div>
                      </div>
                    </div>
                  </div> : <p className='is-text-darkgray50 mb-0 mt-4 body'>本月無使用記錄，謝謝。</p>}

              </div>
            </div>
          </div>
        </section>
      </main >
    )
  }
}

export default QueryIndex;