import React from 'react';
import Slider from 'react-slick';
import Button from '../../Button';
import PropTypes from 'prop-types';
class CarouselBanner3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
    };
  }

  render() {
    const settings = {
      dots: true,
      accessibility: false,
      adaptiveHeight: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      speed: 500,
      arrows: false,
      autoplaySpeed: 5000,
      cssEase: 'linear',
      customPaging: (i) => <button key={i}></button>,
    };

    return (
      <div className='carousel-banner-3' id={this.props.id}>
        <Slider {...settings}>
          {this.props.slider.map((slide) => {
            return (
              <div key={slide.title} className='slide'>
                <div style={slide.bgColor ? { backgroundColor: slide.bgColor } : null}>
                  {typeof slide.img === 'string' ? (
                    <div
                      className='bg-img'
                      style={{ backgroundImage: `url(${process.env.PUBLIC_URL + slide.img})` }}></div>
                  ) : (
                    <React.Fragment>
                      <div
                        className='bg-img d-none d-sm-block'
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + slide.img.md})` }}></div>
                      <div
                        className='bg-img d-block d-sm-none'
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + slide.img.sm})` }}></div>
                    </React.Fragment>
                  )}
                  {/* <img className="bg-img" src={slide.img} alt="bg-img" /> */}
                  <div className='fui-container'>
                  <div className='content-wrapper'>
                    <div className='content'>
                      <p className='subtitle is-text-white' dangerouslySetInnerHTML={{ __html: slide.type }}></p>
                      <div className='is-text-white' dangerouslySetInnerHTML={{ __html: slide.title }}></div>
                      <Button
                        link={slide.action.link}
                        btnStyle='secondary'
                        className='is-large is-reverse'
                        target={slide.action.target}>
                        {slide.action.text}
                      </Button>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

CarouselBanner3.propTypes = {
  slider: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.shape({
        md: PropTypes.string,
        sm: PropTypes.string,
      }).isRequired,
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      action: PropTypes.shape({
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }),
    })
  ),
};

export default CarouselBanner3;
