import React from 'react';
import Link from '../Link';
import PropTypes from 'prop-types';
class ProdPromoCard extends React.Component {
  render() {
    return (
      <div className='fui-card is-prod-promo'>
        <Link to={this.props.link} target={this.props.target} className="fui-card-action">
          {this.props.ribbon ? <div className='ribbon'>{this.props.ribbon}</div> : null}
          <div className='fui-card-image'>
            <img src={this.props.image} alt={this.props.title} />
          </div>
          <div className='fui-card-caption'>
            <h4 className='fui-card-title'>{this.props.title}</h4>
          </div>
        </Link>
      </div>
    )
  }
}
ProdPromoCard.propTypes = {
  total: PropTypes.number,
  list: PropTypes.array,
  current: PropTypes.number,
  perPage: PropTypes.number,
  pages: PropTypes.number
}
export default ProdPromoCard;

