import React, { Component } from 'react';
import MultiRadioCard from '../../components/card/MultiRadioCard';
import ToolsItemCarousel from '../../components/partials/carousel/ToolsItemCarousel';
import AppPromotion2 from '../../components/partials/AppPromotion2';
import BannerPromotionBasic from '../../components/partials/banner/BannerPromotionBasic';
import AppPromotion3 from '../../components/partials/AppPromotion3';
import SectionProductInfo from '../../components/partials/SectionProductInfo';
import Formsy from 'formsy-react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import RadioButtonsWithPrice from '../../components/form/RadioButtonsWithPrice';
import ServiceEntry from '../../components/card/ServiceEntry';
import CardCollapse1 from '../../components/card/CardCollapse1';
import CardCollapse2 from '../../components/card/CardCollapse2';
import Slider from 'react-slick';
import SectionCard2 from '../../components/card/SectionCard2';
import SectionCooperation from '../../components/partials/SectionCooperation';
import * as formatNumber from '../../utils/numberFormatter';
import NavAnchor from '../../components/partials/NavAnchor';
import FeatureBanner from '../../components/partials/FeatureBanner';
class Module2 extends Component {
  constructor(props) {
    super(props);
    this.card = React.createRef();
    this.state = {
      formCanSubmit: false,
      list: [
        {
          title: '一二三四五六七八九十',
        },
        {
          title: 'KKBOX',
        },
      ],
      collapseData2: [
        {
          title: '親職教養專家 張旭鎧 (阿鎧老師)：',
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          content:
            '<div class="body">面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。</div>',
        },
        {
          title: '3C 部落客 babyfans 杜小威：',
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          content:
            '<div class="body">面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。</div>',
        },
        {
          title: '朱朱育兒日記 朱兒：',
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          content:
            '<div class="body">面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。</div>',
        },
        {
          title: '朱朱育兒日記 朱兒：</h5>',
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          content:
            '<div class="body">面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。</div>',
        },
        {
          title: '<h5>朱朱育兒日記 朱兒：</h5>',
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          content:
            '<div class="body">面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。</div>',
        },
      ],
      collapseData: [
        {
          title: '親職教養專家 張旭鎧 (阿鎧老師)：',
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          quote:
            '易於使用操作便利的智慧手錶，數位教養最佳工具！只要透過手機APP，就能立即了解孩子位置，隨時與父母連絡，進行互動。',
          content:
            '面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。',
          link: '#',
        },
        {
          title: '3C 部落客 babyfans 杜小威：',
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          quote: '過年外出一度走散了，馬上開定位和打電話立刻尋找他。很推薦給孩子小的家長，可以買給小孩外出用。',
          content:
            '面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。',
          link: '#',
        },
        {
          title: '朱朱育兒日記 朱兒：',
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          quote:
            '孩子可以隨時打電話給我，我也可以確認他的安全，有急事發生孩子也可按SOS通知所有家庭成員。真的是讓我輕鬆許多。',
          content:
            '面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。',
          link: '#',
        },
        {
          title: '朱朱育兒日記 朱兒：',
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          quote:
            '孩子可以隨時打電話給我，我也可以確認他的安全，有急事發生孩子也可按SOS通知所有家庭成員。真的是讓我輕鬆許多。',
          content:
            '面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。',
          link: '#',
        },
        {
          title: '朱朱育兒日記 朱兒：',
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          quote:
            '孩子可以隨時打電話給我，我也可以確認他的安全，有急事發生孩子也可按SOS通知所有家庭成員。真的是讓我輕鬆許多。',
          content:
            '面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。',
          link: '#',
        },
      ],
      form: {
        options1: { value: '', required: false },
        options2: { value: '', required: false },
      },
    };
  }
  onChange = (name, value) => {
    // console.log(name, value);
    let form = Object.assign(this.state.form);
    form[name].value = value;
    this.setState({
      form: form,
    });
  };
  render() {
    return (
      <main className='life-circle article'>
        <NavAnchor
          pageTitle='騎樂無窮'
          button={[
            {
              text: '新申辦',
              link: '#',
            },
            {
              text: '推薦續約',
              link: '#',
            },
          ]}
        />
        <BannerPromotionBasic
          bgColor='#fff'
          autoplay={false}
          className='mt-0'
          // autoplaySpeed={5000}
          slides={[
            {
              image: {
                md: '/resources/cbu/img/ebu-aws-banner-1440-x-460@2x copy.jpg',
                sm: '/resources/cbu/img/ebu-aws-banner-550-x-460@2x.png',
              },
              // tag: "網路限定",
              title: '遠傳電信帳單代收',
              description:
                '<div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>快速、便利、安全</h5></div><div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>免信用卡、免手續費</h5></div>',
              actions: [
                {
                  btnStyle: 'primary',
                  target: '_blank',
                  type: 'link',
                  text: '馬上設定',
                  link: '#',
                },
              ],
            },
          ]}
          openModal={this.openModal}
        />
        <ToolsItemCarousel
          {...{
            title: '不再為了帳單而擔心',
            className: 'pb-0 mt-4',
            item: [
              {
                image: '/resources/cbu/life-circle-slashie/images/flight-video.svg',
                title: 'Google Play 代收',
                link: '#',
                // description: 'friDay影音',
              },
              {
                image: '/resources/cbu/life-circle-slashie/images/smart-speaker.svg',
                title: 'iTines 代收',
                link: '#',
                // description: '愛講語音助理',
              },
              {
                image: '/resources/cbu/life-circle-slashie/images/bobee-parking.svg',
                title: 'Microsoft 代收',
                link: '#',
                // description: '愛車守護寶',
              },
              {
                image: '/resources/cbu/life-circle-slashie/images/friday-shopping-2.svg',
                title: '小額付費',
                link: '#',
                // description: 'friDay購物',
              },
              {
                image: '/resources/cbu/life-circle-slashie/images/laundry.svg',
                title: '停車代收',
                link: '#',
                // description: '潔衣家洗衣服務',
              },
              {
                image: '/resources/cbu/life-circle-slashie/images/friday-57.svg',
                title: '代收設定查詢',
                link: '#',
                // description: 'friDay 57',
              },
              {
                image: '/resources/cbu/life-circle-slashie/images/smart-speaker.svg',
                title: '行動代收',
                link: '#',
                // description: '數位萬事通',
              },
            ],
          }}
        />
        <SectionProductInfo
          title='愛講x禾聯智慧家電'
          list={[
            {
              hotTag: '熱門商品',
              // tag: ['5G手機'],
              name: '禾聯智能掃地機器人',
              content: {
                price: `<del class="">$7,990</del>
                <h4 class="is-text-error m-0">$0起</h4>`,
                checklist: `<h6 class='d-flex flex-align-center m-0 is-text-darkgray50'>
                <img src="/resources/cbu/prepaid/images/checklist.svg" />陀螺儀路徑規劃精準導航
              </h6>
              <h6 class='d-flex flex-align-center m-0 is-text-darkgray50'>
                <img src="/resources/cbu/prepaid/images/checklist.svg" />預約排程清潔功能
              </h6>
              <h6 class='d-flex flex-align-center m-0 is-text-darkgray50'>
                <img src="/resources/cbu/prepaid/images/checklist.svg" />100ml 水箱拖地功能
              </h6>`,
                tips: [
                  {
                    block: '聲控排程Tips',
                    content: '明天早上10點開啟掃地機',
                  },
                  {
                    block: '聲控排程Tips',
                    content: '1小時候請關閉掃地',
                  },
                  {
                    block: '暫停Tips',
                    content: '現在暫停掃地機',
                  },
                ],
              },
              brand: '愛講搭配專案',
              image: [
                '/resources/cbu/img/estore-product-thumb-02@2x.jpg',
                '/resources/cbu/img/estore-product-thumb-02@2x.jpg',
                '/resources/cbu/img/estore-product-thumb-02@2x.jpg',
                '/resources/cbu/img/estore-product-thumb-02@2x.jpg',
              ],
              link: {
                url: '#',
                text: '前往購買',
                target: '_blank',
              },
            },
          ]}
          // onChange={(e) => this.onChange(e)}
        />
        <FeatureBanner
          tabs={[
            { name: '01', label: '娛樂', text: '娛樂', value: '娛樂', index: 0 },
            { name: '02', label: '遊戲', text: '遊戲', value: '遊戲', index: 1 },
            { name: '03', label: '購物', text: '購物', value: '購物', index: 2 },
            { name: '04', label: '生活應用1', text: '生活應用1', value: '生活應用1', index: 3 },
            { name: '05', label: '生活應用2', text: '生活應用2', value: '生活應用2', index: 4 },
            { name: '06', label: '生活應用3', text: '生活應用3', value: '生活應用3', index: 5 },
          ]}
          slides={[
            {
              image: {
                md: '/resources/cbu/img/img-store-web.jpg',
                sm: '/resources/cbu/img/cbu-promotion-feature-banner-01-690x720.jpg',
              },
              title: '<h3 class="mt-4 mb-2 is-text-white">哪裡可以使用小額付費代收？</h3>',
              description: '<p class="is-text-white">遊戲、購物、訂票、生活繳費，下列場域皆能適用</p>',
              actions: [
                {
                  btnStyle: 'primary',
                  target: '_blank',
                  type: 'link',
                  text: '線上開通',
                  link: '#',
                },
              ],
            },
            {
              image: {
                md: '/resources/cbu/img/img-store-web@2x.jpg',
                sm: '/resources/cbu/img/img-onlinestore-mobile@2x.jpg',
              },
              title: '<h3 class="mt-4 mb-2 is-text-white">遠遊卡 2.0 升級登場</h3>',
              description: '<p class="is-text-white">一卡在手 ‧ 旅遊上網 ‧ 暢遊全球 gogo</p>',
              actions: [
                {
                  btnStyle: 'primary',
                  target: '_blank',
                  type: 'link',
                  text: '開通小額付費',
                  link: '#',
                },
              ],
            },
            {
              image: {
                md: '/resources/cbu/img/img-store-web@2x.jpg',
                sm: '/resources/cbu/img/img-onlinestore-mobile@2x.jpg',
              },
              title: '<h3 class="mt-4 mb-2 is-text-white">遠遊卡 2.0 升級登場</h3>',
              description: '<p class="is-text-white">一卡在手 ‧ 旅遊上網 ‧ 暢遊全球 gogo</p>',
              actions: [
                {
                  btnStyle: 'primary',
                  target: '_blank',
                  type: 'link',
                  text: '開通小額付費',
                  link: '#',
                },
              ],
            },
            {
              image: {
                md: '/resources/cbu/img/img-store-web@2x.jpg',
                sm: '/resources/cbu/img/img-onlinestore-mobile@2x.jpg',
              },
              title: '<h3 class="mt-4 mb-2 is-text-white">遠遊卡 2.0 升級登場</h3>',
              description: '<p class="is-text-white">一卡在手 ‧ 旅遊上網 ‧ 暢遊全球 gogo</p>',
              actions: [
                {
                  btnStyle: 'primary',
                  target: '_blank',
                  type: 'link',
                  text: '開通小額付費',
                  link: '#',
                },
              ],
            },
            {
              image: {
                md: '/resources/cbu/img/img-store-web@2x.jpg',
                sm: '/resources/cbu/img/img-onlinestore-mobile@2x.jpg',
              },
              title: '<h3 class="mt-4 mb-2 is-text-white">遠遊卡 2.0 升級登場</h3>',
              description: '<p class="is-text-white">一卡在手 ‧ 旅遊上網 ‧ 暢遊全球 gogo</p>',
              actions: [
                {
                  btnStyle: 'primary',
                  target: '_blank',
                  type: 'link',
                  text: '開通小額付費',
                  link: '#',
                },
              ],
            },
            {
              image: {
                md: '/resources/cbu/img/img-store-web@2x.jpg',
                sm: '/resources/cbu/img/img-onlinestore-mobile@2x.jpg',
              },
              title: '<h3 class="mt-4 mb-2 is-text-white">遠遊卡 2.0 升級登場</h3>',
              description: '<p class="is-text-white">一卡在手 ‧ 旅遊上網 ‧ 暢遊全球 gogo</p>',
              actions: [
                {
                  btnStyle: 'primary',
                  target: '_blank',
                  type: 'link',
                  text: '開通小額付費',
                  link: '#',
                },
              ],
            },
          ]}
          list={[
            [
              {
                title: '富爾特軟體',
                link: '#',
                target: '_blank',
              },
              {
                title: '商業周刊',
                link: '#',
                target: '_blank',
              },
              {
                title: '羅賽塔石牌',
                link: '#',
                target: '_blank',
              },
              {
                title: 'ParkPay',
                link: '#',
                target: '_blank',
              },
            ],
            [
              {
                title: '智冠科技',
                link: '#',
                target: '_blank',
              },
              {
                title: 'GASH Point',
                link: '#',
                target: '_blank',
              },
              {
                title: '台灣碩網',
                link: '#',
                target: '_blank',
              },
              {
                title: '競舞娛樂',
                link: '#',
                target: '_blank',
              },
              {
                title: '愛情公寓',
                link: '#',
                target: '_blank',
              },
              {
                title: 'Sweetring',
                link: '#',
                target: '_blank',
              },
              {
                title: '戲谷',
                link: '#',
                target: '_blank',
              },
              {
                title: '隨身遊戲',
                link: '#',
                target: '_blank',
              },
              {
                title: 'Opoint',
                link: '#',
                target: '_blank',
              },
            ],
            [
              {
                title: '富爾特軟體',
                link: '#',
                target: '_blank',
              },
              {
                title: '商業周刊',
                link: '#',
                target: '_blank',
              },
              {
                title: '羅賽塔石牌',
                link: '#',
                target: '_blank',
              },
              {
                title: 'ParkPay',
                link: '#',
                target: '_blank',
              },
            ],
            [
              {
                title: '富爾特軟體',
                link: '#',
                target: '_blank',
              },
              {
                title: '商業周刊',
                link: '#',
                target: '_blank',
              },
              {
                title: '羅賽塔石牌',
                link: '#',
                target: '_blank',
              },
              {
                title: 'ParkPay',
                link: '#',
                target: '_blank',
              },
            ],
            [
              {
                title: '富爾特軟體',
                link: '#',
                target: '_blank',
              },
              {
                title: '商業周刊',
                link: '#',
                target: '_blank',
              },
              {
                title: '羅賽塔石牌',
                link: '#',
                target: '_blank',
              },
              {
                title: 'ParkPay',
                link: '#',
                target: '_blank',
              },
            ],
            [
              {
                title: '富爾特軟體',
                link: '#',
                target: '_blank',
              },
              {
                title: '商業周刊',
                link: '#',
                target: '_blank',
              },
              {
                title: '羅賽塔石牌',
                link: '#',
                target: '_blank',
              },
              {
                title: 'ParkPay',
                link: '#',
                target: '_blank',
              },
            ],
          ]}
        />

        <SectionCard2
          title='<h2 class="mt-0">更多貼心服務</h2>'
          tabs={[
            { name: '01', label: '音樂、有聲書', text: '音樂、有聲書', value: '音樂、有聲書', index: 0 },
            { name: '02', label: '新聞廣播', text: '新聞廣播', value: '新聞廣播', index: 1 },
            { name: '03', label: '資訊快查', text: '資訊快查', value: '資訊快查', index: 2 },
            { name: '04', label: '生活幫手1', text: '生活幫手1', value: '生活幫手1', index: 3 },
            { name: '05', label: '生活幫手2', text: '生活幫手2', value: '生活幫手2', index: 4 },
            { name: '06', label: '生活幫手3', text: '生活幫手3', value: '生活幫手3', index: 5 },
          ]}
          list={[
            [
              {
                meta: '一二三四五六七八九十一二三四五六七八九',
                title: '一二三四五六七八九十一二',
                content:
                  '一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一',
                tips: '一二三四五六七八九十一二三四五六七八九十一二',
                link: { url: '#', target: '_blank' },
              },
              {
                meta: '音樂雙平台',
                title: 'KKBOX音樂',
                content: '聽音樂的好朋友，在愛講、電腦、手機等隨處都能享受千萬首正版好音樂。',
                tips: '聲控 Tips：來首蘇打綠的歌、我想聽周杰倫',
                link: { url: '#', target: '_blank' },
              },
              {
                meta: '有聲書',
                title: '樂學有聲書',
                content: '含近千則故事及兒歌，提升孩子想像力及創造力，培養藝術及閱讀素養。',
                tips: '聲控 Tips：我想聽狐狸請客的故事',
                link: { url: '#', target: '_blank' },
              },
              {
                meta: '有聲書',
                title: '愛播聽書FM',
                content: '童書、娛樂、人文、百科等自由選聽，囊括最多台灣名家的有聲知識內容。',
                tips: '聲控 Tips：我要聽愛播(兒童故事/老人與海)',
                link: { url: '#', target: '_blank' },
              },
              {
                meta: '英語學習',
                title: '空中英語教室 每日一句',
                content: '一天一句，輕鬆學英文，週一~週五每天給您不一樣的內容，週末隨選週間內容重播複習。',
                tips: '聲控 Tips：我想聽空英每日一句',
                link: { url: '#', target: '_blank' },
              },
              {
                meta: '音樂雙平台',
                title: 'KKBOX音樂',
                content: '聽音樂的好朋友，在愛講、電腦、手機等隨處都能享受千萬首正版好音樂。',
                tips: '聲控 Tips：來首蘇打綠的歌、我想聽周杰倫',
                link: { url: '#', target: '_blank' },
              },
            ],
            [
              {
                meta: '音樂雙平台',
                title: 'KKBOX音樂',
                content: '聽音樂的好朋友，在愛講、電腦、手機等隨處都能享受千萬首正版好音樂。',
                tips: '聲控 Tips：來首蘇打綠的歌、我想聽周杰倫',
                link: { url: '#', target: '_blank' },
              },
              {
                meta: '音樂雙平台',
                title: 'KKBOX音樂',
                content: '聽音樂的好朋友，在愛講、電腦、手機等隨處都能享受千萬首正版好音樂。',
                tips: '聲控 Tips：來首蘇打綠的歌、我想聽周杰倫',
                link: { url: '#', target: '_blank' },
              },
            ],
            [
              {
                meta: '音樂雙平台',
                title: 'KKBOX音樂',
                content: '聽音樂的好朋友，在愛講、電腦、手機等隨處都能享受千萬首正版好音樂。',
                tips: '聲控 Tips：來首蘇打綠的歌、我想聽周杰倫',
                link: { url: '#', target: '_blank' },
              },
              {
                meta: '音樂雙平台',
                title: 'KKBOX音樂',
                content: '聽音樂的好朋友，在愛講、電腦、手機等隨處都能享受千萬首正版好音樂。',
                tips: '聲控 Tips：來首蘇打綠的歌、我想聽周杰倫',
                link: { url: '#', target: '_blank' },
              },
            ],
          ]}
        />
        <SectionCooperation
          list={[
            {
              title: '技術合作',
              img: {
                url: '/resources/cbu/img/coop-01.png',
                alt: 'coop-1',
              },
            },
            {
              title: '專案廠商',
              img: {
                url: '/resources/cbu/img/coop-01.png',
                alt: 'coop-2',
              },
            },
          ]}
        />
        <AppPromotion3
          bgImage='/resources/cbu/help-center/images/bg-all.png'
          title='加入遠傳生活圈，滿載好康'
          subtitle='一二三四五六七八九十一二三四五六七八九十一二'
          description={`
            <p>
              以遠傳門號快速登入, 上網流量即時看，查帳單、繳帳單，一鍵完成！
              <br />
              還有許多用戶獨享驚喜優惠
            </p>`}
          icon='/resources/cbu/help-center/images/img-logo-fet.png'
          android={{
            qrCode: '/resources/cbu/help-center/images/qr-android.jpg',
            icon: '/resources/cbu/help-center/images/google-play.png',
            link: '/',
            title: '支援 Android 7 以上版本',
          }}
          ios={{
            qrCode: '/resources/cbu/help-center/images/qr-ios.png',
            icon: '/resources/cbu/help-center/images/app-store.png',
            link: '/',
            title: '支援 iOS 10 以上版本',
          }}
        />
        <AppPromotion2
          bgImage='/resources/cbu/help-center/images/bg-all.png'
          title='加入遠傳生活圈，滿載好康'
          subtitle='立即下載App，享好康回饋！'
          description={`
            <div class='body'><img src="/resources/cbu/prepaid/images/checklist.svg" />4G吃到飽不降速</div>
            <div class='body'><img src="/resources/cbu/prepaid/images/checklist.svg" />買就送通話金</div>
            <div class='body'><img src="/resources/cbu/prepaid/images/checklist.svg" />插入SIM卡即可使用</div>  
          `}
          icon='/resources/cbu/help-center/images/img-logo-fet.png'
          android={{
            qrCode: '/resources/cbu/help-center/images/qr-android.jpg',
            icon: '/resources/cbu/help-center/images/google-play.png',
            link: '/',
            title: '支援 Android 7 以上版本',
          }}
          ios={{
            qrCode: '/resources/cbu/help-center/images/qr-ios.png',
            icon: '/resources/cbu/help-center/images/app-store.png',
            link: '/',
            title: '支援 iOS 10 以上版本',
          }}
        />
        <CardCollapse2 list={this.state.collapseData2} title='<h2>用戶真實回饋</h2>' />
        <CardCollapse1 list={this.state.collapseData} />
        <section>
          <div className='fui-container'>
            <h1 className='m-0'>多選方案牌卡</h1>
            <h2>MultiRadioCard</h2>
            <p>
              多選方案牌卡內容分為2部分：
              <br />
              • 標題：小於30字元
              <br />
              • 牌卡：此牌卡是為了比較兩種方案，因次建議只能有2張牌卡，方便小網比較。若有其他資訊解釋需求，請參考 12.0
              行銷模組 UX spec
              <br />
              -標題：小於20字元
              <br />
              -方案選擇：建議至多4個方案選擇，方案名稱小於24字元
              <br />
              -btn：點擊後，依據選擇的方案轉至頁面。
            </p>
          </div>
        </section>
        <MultiRadioCard
          title='付費應用程式'
          option1={[
            { value: '0', label: '一二三四五六七八九十一二', price: 149, link: '/promotion-module' },
            { value: '1', label: '90天-愛講專案加贈 7 天', price: 447, link: '#12' },
            { value: '2', label: '180天-愛講專案加贈 15 天', price: 894, link: '#13' },
            { value: '3', label: '365天-愛講專案加贈 45 天', price: 1788, link: '#14' },
          ]}
          option2={[
            { value: '0', label: '月租無限聽_愛講專案優惠', price: 149, link: '#21' },
            { value: '1', label: '90天-愛講專案加贈 7 天', price: 447, link: '#22' },
            { value: '2', label: '180天-愛講專案加贈 15 天', price: 894, link: '#23' },
            { value: '3', label: '365天-愛講專案加贈 45 天', price: 1788, link: '#24' },
          ]}
          list={[
            {
              btnText: '購買',
              icon: '/resources/cbu/img/fridaymusic@3x.png',
              alt: 'App',
              title: '一二三四五六七八九十',
            },
            {
              btnText: '購買',
              icon: '/resources/cbu/img/kkbox@3x.png',
              alt: '圖說',
              title: 'KKBOX',
            },
          ]}
          form={{
            options1: { value: '1', required: false },
            options2: { value: '1', required: false },
          }}
        />
        <ServiceEntry
          title='更多服務'
          list={[
            {
              title: '查詢洗衣序號',
              // phone: '(02)2915-3639 (09:00-21:00)',
              link: '#',
            },
            {
              title: '潔衣家客服中心',
              phone: '(02)2915-3639 (09:00-21:00)',
              link: '#',
            },
            {
              title: '查詢洗衣進度',
              // phone: '(02)2915-3639 (09:00-21:00)',
              link: '#',
            },
            {
              title: '洗衣定型化契約',
              // phone: '(02)2915-3639 (09:00-21:00)',
              link: '#',
            },
          ]}
        />
      </main>
    );
  }
}

export default Module2;
