import React, { Component } from 'react'
import LoadMore from '../LoadMore'
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import Tooltip from '../../components/Tooltip';
import * as formatNumber from '../../utils/numberFormatter';
class CardPlanBasic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: this.props.list.slice(0, 4)
    }
    this.card = React.createRef()
  }
  getColumns = () => {
    let length = this.state.list.length;
    // console.log(length);
    if (length < 5) {
      return length
    } else {
      return 4
    }
  }
  render() {
    return (
      <section className='promotion-card pb-0' style={!!this.props.topBg ? { backgroundImage: `url(${this.props.topBg})` } : null}>
        <div className='fui-container promotion-card-title mt-4'>
          <h2 className="mt-0 ">{this.props.title}</h2>
          <Slider {...{
            dots: false,
            infinite: false,
            arrows: true,
            slidesToShow: this.getColumns(),
            draggable: true,
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  arrows: true,
                  slidesToShow: 4,
                  variableWidth: true,
                },
              },
              {
                breakpoint: 960,
                settings: {
                  arrows: true,
                  slidesToShow: 2,
                  variableWidth: true,
                },
              },
              {
                breakpoint: 640,
                settings: {
                  arrows: true,
                  slidesToShow: 1,
                  variableWidth: true
                }
              }
            ],
          }}
            className='horizontal-cards is-program pb-md-9 mb-0'>
            {this.state.list.map((card, idx) => (
              <div key={idx} className='fui-card is-card-program'>
                {card.tooltip ? <div className="tooltip-wrapper">
                  <Tooltip
                    parentNode={null}
                    content={<i className='icon-information'></i>}
                    trigger="click"
                    tooltip={card.tooltip} />
                </div> : null}
                {card.tag ? <div className={`tag tag-${card.tag.color}`}>{card.tag.text}</div> : null}
                <h3 className="mt-2 mb-0" ref={this.card}>
                  {card.title}
                </h3>
                <h1 className='mt-2 mb-1'>
                  <small className='mr-1'>{card.unit}</small>
                  <span className="price is-text-error" style={{ fontSize: this.props.list.length === 2 ? '56px' : null }}>
                    <span>$</span>{formatNumber.default(card.price)}
                  </span>
                </h1>
                <ul>
                  {card.list.map(item => (
                    <li className='' key={item}>
                      <i className="icon-check is-text-accent" />
                      <div className='subtitle is-text-darkgray50'>{item}</div>
                    </li>
                  ))}
                </ul>
                {card.button.length > 1 ? <div className="form-group two-buttons">
                  <Button btnStyle='primary' size='medium' link={card.button[0].link}>{card.button[0].title}</Button>
                  <Button btnStyle='secondary' size='medium' link={card.button[1].link}>{card.button[1].title}</Button>
                </div> : <div className="form-group m-0">
                    <Button btnStyle='primary' size='medium' className='mb-0' link={card.button[0].link}>{card.button[0].title}</Button>
                  </div>}
              </div>
            ))}
          </Slider>
        </div>
        <div
          className='bottom-bg'
          style={this.props.bottomBg ? { backgroundImage: `url(${this.props.bottomBg})` } : null}
        />
      </section>
    )
  }
}

CardPlanBasic.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string,
      tooltip: PropTypes.string,
    })
  ),
  bottomBg: PropTypes.string,
  topBg: PropTypes.string,
}
export default CardPlanBasic;