import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import Header from '../../components/partials/header/Default';
import CheckGroup from '../../components/form/CheckGroup';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Modal from 'react-modal';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

class MergeBill extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.form = React.createRef();
    this.state = {
      isMember: false,
      step: 1,
      mergable: false,
      canSubmit: false,
      modalOpen: false,
      authCode: '',
      phoneNumber: '0926-899-868',
      account: '859909700',
      form: {
        account: { value: '', required: false },
      },
      mergeNumber: [
        { value: '0', label: '0916-000-995' },
        { value: '1', label: '0928-789-789' },
        { value: '2', label: '0912-477-477' },
        { value: '3', label: '0988-654-654' },
        { value: '4', label: '0988–092-000' },
        { value: '5', label: '0936-282-525' },
        { value: '6', label: '0936-282-525' },
        { value: '7', label: '03-1234567' }
      ],
      linkNumber: [
        {
          type: 'cellphone',
          number: '0936-123-995'
        },
        {
          type: 'cellphone',
          number: '0931-456-995'
        },
        {
          type: 'landline',
          number: '02-1234567'
        },
        {
          type: 'landline',
          number: '02-8887777'
        },
      ]
    }
  }

  onChange = (name, value) => {
    // console.log(`name, value: `, name, value);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }

  handleChange = () => {
    this.setState({
      authCode: this.input.current.value,
    });
  }

  submitAuthCode = () => {
    // console.log(`this.authCode: `, this.input.current.value);
    this.setState({
      modalOpen: false,
      step: 2
    })
  }
  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    this.setState({ canSubmit: false });
  }
  submit = model => {
    // console.log('submitted');
  };
  render() {
    return (
      <main>
        <Header />
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="「合併帳單」申請"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        {this.state.step == 1 ? <section className="query-index">
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mb-5 pb-md-2 pb-1">提供您進行名下帳單合併為同一份帳單的服務，方便您有效管理帳單。</h4>
            <div className="paper mt-md-4 mt-2 mb-2">
              <div className="d-flex justify-between flex-align-center mt-md-4 mt-md-2 mt-0 mb-2">
                <h4 className='m-0'>欲申請帳戶資料</h4>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      門號
                      </h5>
                    <h2 className='mb-0'>{this.state.phoneNumber}</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      帳號
                    </h5>
                    <h2 className='mb-0'>{this.state.account}</h2>
                  </div>
                </Grid>
              </Grid>
            </div>
            {!this.state.isMember ? <div>
              <h4 className='mt-5 pt-2'>為保護您個人資料的安全，此服務需進行本人門號認證，將發送認證碼簡訊至您的門號 {this.state.phoneNumber}</h4>
              <div className='d-flex fui-buttons two-buttons mt-md-5 mt-0 pt-md-2 pt-4'>
                <Button onClick={() => this.setState({ modalOpen: true })} link="#" className='mb-lg-0 mb-2' btnStyle='primary' size='large'>確認</Button>
              </div>
            </div> : <div className='member-login'>
                <h4 className='mt-5 pt-2'>為保護您個人資料的安全，請輸入遠傳帳戶密碼進行確認</h4>
                <h6 className='mt-4 mb-2'>帳戶密碼(遠傳通關密碼)</h6>
                <div className="d-flex flex-column text-input mb-1">
                  <input
                    onBlur={this.handleChange}
                    // id={`input-${this.props.name}`}
                    type='text'
                    ref={this.input}
                    onChange={this.handleChange}
                    onKeyUp={this.handleChange}
                    placeholder='請輸入密碼'
                    value={this.state.authCode}
                    maxLength={8}
                  />
                </div>
                <p className='is-text-darkgray50 d-flex flex-row mt-2'>
                  帳戶密碼說明：<br />
                    1.帳戶密碼為您在遠傳所設置的帳戶授權密碼，亦稱通關密碼。<br />
                    2.如您欲變更密碼或查詢密碼，請洽客服中心或立即使用客服即時通。
                  </p>
                <div className='d-flex fui-buttons two-buttons mt-md-5 mt-0 pt-md-1 pt-4'>
                  <Button onClick={() => this.setState({ step: 2 })} link="#" className={`mb-lg-0 mb-2 ${!!this.state.authCode ? '' : 'disabled'}`} btnStyle='primary' size='large'>確認</Button>
                </div>
              </div>}

          </div>
        </section> : null}
        {this.state.step == 2 ? <section className="query-index">
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mb-5 pb-2">提供您進行名下帳單合併為同一份帳單的服務，方便您有效管理帳單。</h4>
            <div className="paper mt-md-4 mt-2 mb-2">
              <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-2">
                <h4 className='m-0'>目前登入門號</h4>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      帳號
                      </h5>
                    <h2 className='mb-0'>{this.state.phoneNumber}</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      帳號
                    </h5>
                    <h2 className='mb-0'>{this.state.account}</h2>
                  </div>
                </Grid>
              </Grid>
              <h4 className='mt-md-5 mb-md-3 mb-2 pt-md-2 pt-0 mt-3'>此帳單其他門號</h4>
              <Grid container spacing={2}>
                {this.state.linkNumber.map((item, i) => {
                  return (
                    <Grid item xs={12} sm={3} key={item.number + i}>
                      <div className="border d-flex p-md-2 px-2 py-1 flex-align-center">
                        <img className='mr-1' src={'/resources/common/images/' + (item.type == 'cellphone' ? '' : 'contact-') + 'phone.svg'} alt={item.type} />
                        {item.number}
                      </div>
                    </Grid>
                  )
                })}

              </Grid>
            </div>

            {!!this.state.mergable ? <div>
              <div className="paper pt-md-4 pt-3 mt-2 mb-md-2">
                {/* <div className="d-flex justify-between flex-align-center mt-md-4 mt-2 mb-2">
                <h4 className='m-0'>請選擇欲合併帳單之號碼</h4>
              </div> */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Formsy
                      className=''
                      onValidSubmit={this.submit}
                      onValid={this.enableButton}
                      onInvalidSubmit={this.disableButton}
                      noValidate
                      ref={this.form}>
                      <CheckGroup
                        onChange={this.onChange}
                        validationErrors={{ isDefaultRequiredValue: '請選擇欲合併帳單之號碼' }}
                        label='請選擇欲合併帳單之號碼'
                        name='account'
                        className="is-merge-bill mt-md-4 mt-0 mb-0 pb-0"
                        required={this.state.form.account.required}
                        default={this.state.form.account.value}
                        options={this.state.mergeNumber}
                      />
                    </Formsy>
                  </Grid>
                </Grid>
              </div>
              <div className='d-flex fui-buttons two-buttons mt-md-5 mt-0 pt-md-2 pt-2'>
                <Button onClick={() => console.log('hi')} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='primary' size='large'>合併至此份帳單</Button>
              </div>
            </div> : <div className="paper mb-0 pb-4">
                <p className="body is-text-darkgray50 m-0">親愛的用戶，目前您名下尚無符合「合併帳單」之門號，謝謝！</p>
              </div>}
          </div>
        </section> : null}

        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ isMember: false })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.12.1_帳單與用量_「合併帳單」申請_step1</Button>
              <Button onClick={() => this.setState({ isMember: true })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.12.6_帳單與用量_「合併帳單」申請_有設定遠傳密碼</Button>
              <Button onClick={() => this.setState({ mergable: !this.state.mergable })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.12.7_帳單與用量_「合併帳單」申請_無可合併門號</Button>
            </div>
          </div>
        </div>


        <SectionCollapseInfo
          title="注意事項"
          content={`
            1. 此服務申請完成後，於次日生效。<br/>
            2. 合併生效後，若您原帳單結算週期未滿一個月者，月租費及折扣將依實際結算週期以比例分攤計算，並分別列在新舊帳單中。<br/>
            3. 合併帳單生效後，付款方式及帳單結帳日將以此登入的帳戶設定為主。
          `}
        />
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5>門號認證</h5>
            <p className='mt-4 mb-5 align-left'>為保護您資料的安全，此服務需進行本人門號認證，目前已發送驗證碼至您的門號 {this.state.phoneNumber}</p>

            <div className="d-flex flex-column text-input">
              <input
                onBlur={this.handleChange}
                // id={`input-${this.props.name}`}
                type='text'
                ref={this.input}
                onChange={this.handleChange}
                onKeyUp={this.handleChange}
                placeholder="請輸入認證碼"
                value={this.state.authCode}
                maxLength={8}
              />
              <small className='d-flex flex-row mt-2 is-text-medium'>
                沒有收到認證碼？ <a className="is-text-accent">重新發送</a>
              </small>
            </div>
            <Button onClick={() => { this.submitAuthCode() }} link="#" className={`mb-lg-0 mb-2 ${!!this.state.authCode ? '' : 'disabled'}`} btnStyle='primary'>確定</Button>
            <Button onClick={() => this.setState({ modalOpen: false })} link="#" className='mb-0' btnStyle="secondary">取消</Button>
          </div>
        </Modal>
      </main >
    )
  }
}

export default MergeBill;