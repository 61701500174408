import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import TextInput from '../../components/form/TextInput';
import RadioGroup from '../../components/form/RadioGroup';
import Checkbox from '../../components/form/Checkbox';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import Select from '../../components/form/Select';
import numberFormatter from '../../utils/numberFormatter';
import Tooltip from '../../components/Tooltip';

class fillBillCreditcardPayDataPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.validCode = React.createRef();
    this.creditGroup = React.createRef();
    this.state = {
      step: 1,
      canSubmit: false,
      modalOpen: false,
      form: {
        credit_number_1: { value: '', required: true },
        credit_number_2: { value: '', required: true },
        credit_number_3: { value: '', required: true },
        credit_number_4: { value: '', required: true },
        credit_year: { value: null, required: true },
        credit_month: { value: null, required: true },
        credit_code: { value: '', required: true },
        agree: { value: false, required: true },
      },
    };
  }

  componentDidMount() {
    let creditInputs = Array.from(this.creditGroup.current.getElementsByTagName('input'));
    creditInputs.map((input, i) => {
      this.setInputFilter(input, function (value) {
        return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
      });

      return input.addEventListener('keyup', e => {
        let index = creditInputs.indexOf(e.target);
        // console.log('input:' + index)
        if (e.currentTarget.value.length === 4 && index < 3) {
          creditInputs[index + 1].focus();
        }
      })
    })

    this.setInputFilter(this.validCode.current.getElementsByTagName('input')[0], function (value) {
      return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
    });
  }

  setInputFilter = (textbox, inputFilter) => {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  onChange = (n, v) => {
    let form = Object.assign(this.state.form);
    form[n].value = v;

    this.setState({
      form: form,
    });
  };

  nextStep = () => {
    this.setState({
      step: 2,
    });
  };

  submit = (autopay) => {
    let form = this.state.form;
  };

  render() {
    const { form, step } = this.state;

    return (
      <main>

        <NavAnchor
          pageTitle='信用卡繳費'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title='信用卡繳費'
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <section>
          <div className='fui-container'>
            <h4 className='is-text-darkgray70 my-0'>請確認應繳費項，並填寫付款資訊。</h4>
            <div className='is-text-accent text-sm'>
              <span className='required-dot'></span>必填
            </div>
            <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
              <div className='form-group mt-5'>
                <label className="mt-2">應繳金額</label>
                <div>
                  <h2 className='is-text-error'>{numberFormatter(1401)} 元</h2>
                </div>
              </div>

              <div className="pb-5">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div className="form-group">
                      <label className="is-required">信用卡卡號</label>
                      <div className="credit-input" ref={this.creditGroup}>
                        <input
                          type='tel'
                          required={true}
                          name='credit_number[]'
                          maxLength='4'
                          value={form.credit_number_1.value}
                          onChange={e => this.onChange('credit_number_1', e.currentTarget.value)} />
                        <span>-</span>
                        <input
                          type='tel'
                          required={true}
                          name='credit_number[]'
                          maxLength='4'
                          value={form.credit_number_2.value}
                          onChange={e => this.onChange('credit_number_2', e.currentTarget.value)} />
                        <span>-</span>
                        <input
                          type='tel'
                          required={true}
                          name='credit_number[]'
                          maxLength='4'
                          value={form.credit_number_3.value}
                          onChange={e => this.onChange('credit_number_3', e.currentTarget.value)} />
                        <span>-</span>
                        <input
                          type='tel'
                          required={true}
                          name='credit_number[]'
                          maxLength='4'
                          value={form.credit_number_4.value}
                          onChange={e => this.onChange('credit_number_4', e.currentTarget.value)} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <div className="form-group mb-2">
                      <label className="is-required">信用卡有效日期</label>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Select
                            name="credit_month"
                            required={true}
                            label={"月"}
                            value={form.credit_month.value}
                            options={[
                              { text: '01', value: '01' },
                              { text: '02', value: '02' },
                              { text: '03', value: '03' },
                              { text: '04', value: '04' },
                              { text: '05', value: '05' },
                              { text: '06', value: '06' },
                              { text: '07', value: '07' },
                              { text: '08', value: '08' },
                              { text: '09', value: '09' },
                              { text: '10', value: '10' },
                              { text: '11', value: '11' },
                              { text: '12', value: '12' }
                            ]}
                            onChange={this.onChange} />
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                            name="credit_year"
                            label={"年"}
                            required={true}
                            value={form.credit_year.value}
                            options={[
                              { text: '20', value: '20' },
                              { text: '21', value: '21' },
                              { text: '22', value: '22' },
                              { text: '23', value: '23' },
                              { text: '24', value: '24' },
                              { text: '25', value: '25' },
                              { text: '26', value: '26' },
                              { text: '27', value: '27' },
                              { text: '28', value: '28' },
                              { text: '29', value: '29' },
                              { text: '30', value: '30' }
                            ]}
                            onChange={this.onChange} />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <div className="form-group mb-2">
                      <label className="is-required">
                        檢查碼
                        <Tooltip
                          content={<i className='icon-information i-24 is-text-business100'></i>}
                          trigger="click"
                          tooltip={`信用卡背面三碼數字`}
                        />
                      </label>
                      <div className='credit-input' ref={this.validCode}>
                        <TextInput
                          type='tel'
                          name='credit_code'
                          required={true}
                          maxLength="3"
                          minLength="3"
                          validationError={"請輸入檢查碼"}
                          value={form.credit_code.value}
                          onChange={val => this.onChange('credit_code', val)}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <img
                  src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured.png'}
                  srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured@2x.png'}
                  alt='' height="32" className="mr-1" />
                <img
                  src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb.png'}
                  srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb@2x.png'}
                  alt='' height="32" className="mr-1" />
                <img
                  src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard.png'}
                  srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard@2x.png'}
                  alt='' height="32" className="mr-1" />
                <img
                  src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa.png'}
                  srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa@2x.png'}
                  alt='' height="32" className="mr-1" />

                <div className='is-text-darkgray50 mt-1'>
                  <small>本付款機制採用SSL 256 bites安全加密機制</small>
                </div>
              </div>

              <CheckboxCollapse
                name='agree'
                label='本人已詳閱及了解此注意事項並同意遵守'
                validationError='請詳閱及了解此注意事項'
                validations={{
                  isChecked: form => form.agree
                }}
                checked={form.agree.value}
                maxLength='4'
                value={form.agree.value}
                getCheckbox={(e) => this.onChange('agree', e)}
                collapseContent={`
                <p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。</p>
                <p>使用「全國繳費平台」繳納遠傳電信服務費用注意事項：</p>
                <p>
                1.限以門號申請本人活存帳戶使用此服務。<br/>
                2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。
                </p>
                `}
              />

              <div className='page-action'>
                <Button btnStyle='primary' size='large' onClick={this.submit} disabled={!this.state.canSubmit}>
                  結帳
                </Button>
                <Button btnStyle='secondary' size='large' onClick={(e) => this.setState({ step: 1 })}>
                  上一步
                </Button>
              </div>
            </Formsy>
          </div>
        </section>
      </main>
    );
  }
}

export default fillBillCreditcardPayDataPage;
