import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../components/Link';
import Breadcrumb from '../components/Breadcrumb';
import SearchBanner from '../components/partials/banner/SearchBanner';
import Card from '../components/card/Card';
import Tab from '../components/tab/Tab';
import TabPane from '../components/tab/TabPane';
import CarouselCards from '../components/partials/card/CarouselCards';
import Paginate from '../components/paginate';
import ExtraPromotion from '../components/partials/card/ExtraPromotion';
import ProductCardWithMultiAction from '../components/card/ProductCardWithMultiAction';

import * as Mock from '../mock/SearchResult';

class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.keywords = React.createRef();
    this.state = {
      isEBU: this.props.location.pathname.indexOf('ebu') > -1,
      currentTab: 0,
      keyword: query.get('keyword'),
      page: Number(query.get('page')) || 1,
      resultList1: Mock.resultList1,
      resultList2: Mock.resultList2,
    };

    this.pageChange = this.pageChange.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  changeTab(value) {
    this.setState({ currentTab: value });
  }

  highlightKeyword(word) {
    if (!this.state.keyword || typeof this.state.keyword !== 'string') return word;

    let value = this.state.keyword.toLowerCase();
    let index = word.toLowerCase().indexOf(value);

    if (index === -1 || value.value === '') {
      return word;
    } else {
      return (
        word.substring(0, index) +
        "<span class='highlight'>" +
        word.substring(index, index + value.length) +
        '</span>' +
        word.substring(index + value.length)
      );
    }
  }

  pageChange(result) {
    this.props.history.push(
      `${this.props.location.pathname}?keyword=${this.state.keyword}&page=${result.selected + 1}`
    );
  }

  render() {
    return (
      <main>
        <Breadcrumb
          breadcrumb={[
            { link: '/ebu', text: '商務' },
            { link: '/search', text: '搜尋結果' },
          ]}
        />
        <SearchBanner
          bgImg={{
            md: '/resources/common/images/ebu-banner-search.png',
            sm: '/resources/common/images/ebu-banner-search.png',
          }}
          keyword={{
            defaultKeyword: [
              'Gogoro',
              'Gogoro 699',
              'google',
              'GPS',
              'GPS Google',
              'Gogo',
              'GoPro',
              'Geo',
              'SEO',
              'OPPO',
            ],
            path: '/search',
            placeholder: '找企業用戶常見問題',
          }}
          hot={{
            path: '/search',
            label: '熱搜',
            hotword: [
              '遠傳物聯網',
              '雲端運算',
              '主機代管',
              '大寬頻企業光纖',
              'Office 365',
              '007 國際電話',
              '世界電話國碼',
              '世界電話國碼',
              '世界電話國碼',
              '世界電話國碼',
            ],
          }}
        />

        {/* 第二階段會加上分類 filter */}
        <div className='fui-search-filter'>
          <div className='fui-container'>
            <Grid container justify='center'>
              <Grid item xs={12} sm={12} md={8}>
                <Tab {...Mock.resultFilterTab} scroll={true} onChange={this.changeTab} />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="search-result-tabpane">
          <TabPane active={this.state.currentTab === 0}>
            <section className='result-container'>
              <div className='fui-container'>
                <Grid container justify='center'>
                  <Grid item xs={12} sm={12} md={10}>
                    <div className='result-list'>
                      <div className='result-count is-text-darkgray50'>有 {this.state.resultList1.length} 項搜尋結果</div>
                      {this.state.resultList1.map((item, i) => (
                        <div key={`result-item-${i}`} className='result-item'>
                          <Link to={item.link} className='result-content'>
                            <h6 className='result-header'>
                              {item.type === 'mobile' ? <i className='icon-phone'></i> : <i className='icon-pc'></i>}
                              <span dangerouslySetInnerHTML={{ __html: this.highlightKeyword(item.title) }}></span>
                            </h6>
                            <p
                              className='result-description'
                              dangerouslySetInnerHTML={{ __html: this.highlightKeyword(item.description) }}></p>
                          </Link>
                          {item.extra ? <ExtraPromotion cards={item.extra} /> : null}
                        </div>
                      ))}
                    </div>

                    <div className='product-card-sections'>
                      <CarouselCards
                        title='網路門市'
                        type='product'
                        more={{
                          text: '前往網路門市',
                          link: '#',
                        }}
                        cards={Mock.productCarousel}
                      />

                      <CarouselCards
                        title='影片'
                        type='movie'
                        more={{
                          text: '前往影城',
                          link: '#',
                        }}
                        cards={Mock.movieCarousel}
                      />

                      <CarouselCards
                        title='音樂-900來電答鈴'
                        type='ring'
                        more={{
                          text: '前往900來電答鈴',
                          link: '#',
                        }}
                        cards={Mock.ringCarousel}
                      />

                      <CarouselCards
                        title='音樂-friDay音樂'
                        type='music'
                        more={{
                          text: '前往friDay音樂',
                          link: '#',
                        }}
                        cards={Mock.musicCarousel}
                      />
                    </div>
                    <div className='related-keyword'>
                      <p className='text is-text-medium'>「{this.state.keyword}」 的相關搜尋： </p>
                      {Mock.relateKeyword.map((word, i) => (
                        <Link
                          role='button'
                          key={`relate-keyword-${i}`}
                          to={`${this.state.isEBU ? '/ebu' : ''}/search?keyword=${word}`}
                          className='fui-button is-tag'>
                          {word}
                        </Link>
                      ))}
                    </div>
                    <Paginate
                      initialPage={this.state.page - 1}
                      pageCount={100}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      containerClassName='fui-pagination'
                      previousLabel={<i className='icon-chevron-left'></i>}
                      nextLabel={<i className='icon-chevron-right'></i>}
                      firstLabel={<i className='icon-first'></i>}
                      lastLabel={<i className='icon-last'></i>}
                      onPageChange={this.pageChange}
                    />
                  </Grid>
                </Grid>

              </div>
            </section>
          </TabPane>
          <TabPane active={this.state.currentTab === 1}>
            <section className='result-container'>
              <div className='fui-container'>
                <Grid container justify='center'>
                  <Grid item xs={12} sm={12} md={10}>
                    <div className='result-list'>
                      <div className='result-count is-text-darkgray50'>有 {this.state.resultList2.length} 項搜尋結果</div>
                      {this.state.resultList2.map((item, i) => (
                        <div key={`result-item-${i}`} className='result-item'>
                          <Link to={item.link} className='result-content'>
                            <h6 className='result-header'>
                              {item.type === 'mobile' ? <i className='icon-phone'></i> : <i className='icon-pc'></i>}
                              <span dangerouslySetInnerHTML={{ __html: this.highlightKeyword(item.title) }}></span>
                            </h6>
                            <p
                              className='result-description'
                              dangerouslySetInnerHTML={{ __html: this.highlightKeyword(item.description) }}></p>
                          </Link>
                          {item.extra ? <ExtraPromotion cards={item.extra} /> : null}
                        </div>
                      ))}
                    </div>

                    <div className='related-keyword'>
                      <p className='text is-text-medium'>「{this.state.keyword}」 的相關搜尋： </p>
                      {Mock.relateKeyword.map((word, i) => (
                        <Link
                          role='button'
                          key={`relate-keyword-${i}`}
                          to={`${this.state.isEBU ? '/ebu' : ''}/search?keyword=${word}`}
                          className='fui-button is-tag'>
                          {word}
                        </Link>
                      ))}
                    </div>
                    <Paginate
                      initialPage={this.state.page - 1}
                      pageCount={100}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      containerClassName='fui-pagination'
                      previousLabel={<i className='icon-chevron-left'></i>}
                      nextLabel={<i className='icon-chevron-right'></i>}
                      firstLabel={<i className='icon-first'></i>}
                      lastLabel={<i className='icon-last'></i>}
                      onPageChange={this.pageChange}
                    />
                  </Grid>
                </Grid>

              </div>
            </section>
          </TabPane>
          <TabPane active={this.state.currentTab === 2}>
            <section className='result-container is-product'>
              <div className='fui-container'>
                <div className='result-count is-text-darkgray50'>有 {this.state.resultList2.length} 項搜尋結果</div>

                <div className='fui-cards no-scrollbar is-product-result'>
                  {Mock.productResult.map((prod, i) => (
                    <ProductCardWithMultiAction key={`product-result-${i}`} type='product' {...prod} />
                  ))}
                </div>

                <Grid container justify='center'>
                  <Grid item xs={12} sm={12} md={10}>
                    <ExtraPromotion cards={Mock.ResultExtra} />

                    <div className='related-keyword'>
                      <p className='text is-text-medium'>「{this.state.keyword}」 的相關搜尋： </p>
                      {Mock.relateKeyword.map((word, i) => (
                        <Link
                          role='button'
                          key={`relate-keyword-${i}`}
                          to={`${this.state.isEBU ? '/ebu' : ''}/search?keyword=${word}`}
                          className='fui-button is-tag'>
                          {word}
                        </Link>
                      ))}
                    </div>
                    <Paginate
                      initialPage={this.state.page - 1}
                      pageCount={100}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      containerClassName='fui-pagination'
                      previousLabel={<i className='icon-chevron-left'></i>}
                      nextLabel={<i className='icon-chevron-right'></i>}
                      firstLabel={<i className='icon-first'></i>}
                      lastLabel={<i className='icon-last'></i>}
                      onPageChange={this.pageChange}
                    />
                  </Grid>
                </Grid>

              </div>
            </section>
          </TabPane>
          <TabPane active={this.state.currentTab === 3}>
            <section className='result-container'>
              <div className='fui-container'>
                <Grid container justify='center'>
                  <Grid item xs={12} sm={12} md={10}>
                    <div className='result-list'>
                      <div className='result-count is-text-darkgray50'>有 {this.state.resultList2.length} 項搜尋結果</div>
                      {this.state.resultList2.map((item, i) => (
                        <div key={`result-item-${i}`} className='result-item'>
                          <Link to={item.link} className='result-content'>
                            <h6 className='result-header'>
                              {item.type === 'mobile' ? <i className='icon-phone'></i> : <i className='icon-pc'></i>}
                              <span dangerouslySetInnerHTML={{ __html: this.highlightKeyword(item.title) }}></span>
                            </h6>
                            <p
                              className='result-description'
                              dangerouslySetInnerHTML={{ __html: this.highlightKeyword(item.description) }}></p>
                          </Link>
                          {item.extra ? (
                            <div className='result-extra'>
                              <div className='fui-cards three-card'>
                                {item.extra.map((card, j) => (
                                  <Card key={`result-item-${i}-extra-${j}`} {...card} />
                                ))}
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      ))}
                    </div>

                    <div className='related-keyword'>
                      <p className='text is-text-medium'>「{this.state.keyword}」 的相關搜尋： </p>
                      {Mock.relateKeyword.map((word, i) => (
                        <Link
                          role='button'
                          key={`relate-keyword-${i}`}
                          to={`${this.state.isEBU ? '/ebu' : ''}/search?keyword=${word}`}
                          className='fui-button is-tag'>
                          {word}
                        </Link>
                      ))}
                    </div>

                    <Paginate
                      initialPage={this.state.page - 1}
                      pageCount={100}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      containerClassName='fui-pagination'
                      previousLabel={<i className='icon-chevron-left'></i>}
                      nextLabel={<i className='icon-chevron-right'></i>}
                      firstLabel={<i className='icon-first'></i>}
                      lastLabel={<i className='icon-last'></i>}
                      onPageChange={this.pageChange}
                    />
                  </Grid>
                </Grid>

              </div>
            </section>
          </TabPane>
          <TabPane active={this.state.currentTab === 4}>
          <section className='result-container'>
            <div className='fui-container'>
              <Grid container justify='center'>
                <Grid item xs={12} sm={12} md={10}>
                  <div className='result-list'>
                    <div className='result-count is-text-darkgray50'>有 {this.state.resultList2.length} 項搜尋結果</div>
                    {this.state.resultList2.map((item, i) => (
                      <div key={`result-item-${i}`} className='result-item'>
                        <Link to={item.link} className='result-content'>
                          <h6 className='result-header'>
                            {item.type === 'mobile' ? <i className='icon-phone'></i> : <i className='icon-pc'></i>}
                            <span dangerouslySetInnerHTML={{ __html: this.highlightKeyword(item.title) }}></span>
                          </h6>
                          <p
                            className='result-description'
                            dangerouslySetInnerHTML={{ __html: this.highlightKeyword(item.description) }}></p>
                        </Link>
                        {item.extra ? (
                          <div className='result-extra'>
                            <div className='fui-cards three-card'>
                              {item.extra.map((card, j) => (
                                <Card key={`result-item-${i}-extra-${j}`} {...card} />
                              ))}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ))}
                  </div>

                  <div className='related-keyword'>
                    <p className='text is-text-medium'>「{this.state.keyword}」 的相關搜尋： </p>
                    {Mock.relateKeyword.map((word, i) => (
                      <Link
                        role='button'
                        key={`relate-keyword-${i}`}
                        to={`${this.state.isEBU ? '/ebu' : ''}/search?keyword=${word}`}
                        className='fui-button is-tag'>
                        {word}
                      </Link>
                    ))}
                  </div>

                  <Paginate
                    initialPage={this.state.page - 1}
                    pageCount={100}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    containerClassName='fui-pagination'
                    previousLabel={<i className='icon-chevron-left'></i>}
                    nextLabel={<i className='icon-chevron-right'></i>}
                    firstLabel={<i className='icon-first'></i>}
                    lastLabel={<i className='icon-last'></i>}
                    onPageChange={this.pageChange}
                  />
                </Grid>
              </Grid>

            </div>
          </section>
        </TabPane>
        </div>
      </main>
    );
  }
}

export default SearchResult;
