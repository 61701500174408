import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';
import CardFeature from '../../components/partials/card/CardFeature';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import QuoteBanner from '../../components/partials/QuoteBanner';
import AnchorDetect from '../../components/AnchorDetect';
import OnVisible, { setDefaultProps } from 'react-on-visible';
import Slider from 'react-slick';
import BannerPromotionBasic from '../../components/partials/banner/BannerPromotionBasic';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import SocialMedia from '../../components/SocialMedia';
import CardMultiSteps from '../../components/card/CardMultiSteps';
import EventInfo from '../../components/partials/EventInfo';
import PromotionCard from '../../components/partials/PromotionCard';
import CardPlanBasic from '../../components/card/CardPlanBasic';
import * as formatNumber from '../../utils/numberFormatter';
import axios from 'axios';
import * as Mock from '../../mock/Exclusive';
let PlanMock = null
class PromotionCoop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      currentModal: null,
      credit: "/resources/cbu/img/cbu-goshare-bg-yellow3.png",
      cityRoad: "/resources/cbu/img/cbu-goshare-bg-blue2.png",
      coopBg: "/resources/cbu/img/cbu-goshare-bg-yellow1.png",
      program: [
        {
          title: '好禮二選一，AirPods或3C任你折',
          list: [
            '上網輕速飽 (限速最高21Mbps)',
            '網內每通前5分鐘免費',
            '網外25分鐘',
            '送總額$500騎乘金，或AirPods或3C夯品折$500'
          ],
          unit: '月租',
          price: '498',
          tag: {
            text: '熱門方案',
            color: 'red'
          },
          button: [
            {
              title: '申辦方案',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          title: '不限速吃到飽，城市旅行輕鬆跑',
          unit: '月租',
          price: '699',
          list: [
            '上網吃到飽不限速',
            '網內免費',
            '網外200分鐘',
            '送總額$4800GoShare騎乘金'
          ],
          button: [
            {
              title: '申辦方案',
              link: '#',
              target: '_self'
            }
          ]
        }
      ],
      cityRoadProgram: [
        {
          title: '1+1好禮獎不完，AirPods輕鬆購',
          list: [
            '上網吃到飽不限速',
            '網內免費，網外950分鐘',
            '送總額$4800GoShare騎乘金',
            'AirPods $1,990或AirPods Pro $4,690或其他優惠'
          ],
          tooltip: '此處上網用量是指您該月份帳單內所有門號的上網用量加總，各門號使用明細請至「帳單明細」查詢。',
          unit: '月租',
          price: '999',
          tag: {
            text: '熱門方案',
            color: 'red'
          },
          button: [
            {
              title: '申辦方案',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          title: '1+1好禮獎不完，AirPods輕鬆購',
          list: [
            '上網吃到飽不限速',
            '網內免費，網外950分鐘',
            '送總額$4800GoShare騎乘金',
            'AirPods $1,990或AirPods Pro $4,690或其他優惠'
          ],
          unit: '月租',
          price: '999',
          tag: {
            text: '熱門方案',
            color: 'red'
          },
          button: [
            {
              title: '申辦方案',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          title: 'AirPods Pro只要0元',
          unit: '月租',
          price: '1399',
          list: [
            '上網吃到飽不限速',
            '網內免費，網外1300分鐘</',
            '送總額$4800GoShare騎乘金',
            'AirPods Pro $0或其他3C夯品優惠'
          ],
          button: [
            {
              title: '申辦方案',
              link: '#',
              target: '_self'
            }
          ]
        }
      ],
      rental: [
        {
          title: '宅在家，微上網',
          img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
          imgAlt: '',
          unit: '專案價',
          price: '0',
          brand: 'SAMSUNG',
          name: 'SAMSUNG Galaxy A20',
          hottag: {
            text: '0元拿手機',
            color: 'red'
          },
          tag: ['福利A品'],
        },
        {
          title: '吃到飽，小寒暄',
          img: '/resources/cbu/img/cbu-promotion-product-1@2x.jpg',
          imgAlt: '',
          unit: '專案價',
          price: '0',
          brand: 'Xiaomi',
          name: '紅米 NOTE 8T 64GB',
          hottag: {
            text: '0元拿手機',
            color: 'red'
          },
          tag: [],
        },
        {
          title: '吃到飽，暢快聊',
          img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
          imgAlt: '',
          unit: '專案價',
          price: '9500',
          brand: 'APPLE',
          name: 'iPhone SE 64G',
          hottag: {
            text: '熱門方案',
            color: 'red'
          },
          tag: [],
        },
        {
          title: '吃到飽，暢快聊',
          img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
          imgAlt: '',
          unit: '專案價',
          price: '11500',
          brand: 'SAMSUNG',
          name: 'SAMSUNG Galaxy A20',
          hottag: {
            text: '熱門方案',
            color: 'red'
          },
          tag: [],
        },
      ],
      selectedPlan: {
        banner: {
          image: { md: '', sm: '' }, title: '', description: '', actions: [{ text: '', link: '' }]
        },
        promotionCard: { title: '', cards: [] },
        parentPromotion: { image: { md: '', sm: '' }, title: '', description: '', content: '', actions: { ios: { image: { md: '', sm: '' } }, android: { image: { md: '', sm: '' } } } }
      },
      steps: {
        title: '騎乘金發放3步驟',
        hasMore: true,
        list: [
          {
            name: '申辦方案',
            content: '申辦遠傳 X GoShare 騎樂無窮方案'
          },
          {
            name: '註冊GoShare會員',
            content: '於方案申辦完成後，請立即以方案門號完成註冊GoShare APP會員。 (如方案門號之前已註冊過GoShare會員，可略過此步驟)'
          },
          {
            name: '發放騎乘金',
            content: '方案門號生效後次月起，GoShare將依各方案適用之騎乘金優惠，於每月10日自動提供騎乘金優惠券至方案門號連結的GoShare帳戶中，無法提供給其他GoShare帳戶使用。(如遇周末/例假日將提前或延後至前/次一工作日發放)'
          },
          {
            name: '發放騎乘金',
            content: '方案門號生效後次月起，GoShare將依各方案適用之騎乘金優惠，於每月10日自動提供騎乘金優惠券至方案門號連結的GoShare帳戶中，無法提供給其他GoShare帳戶使用。(如遇周末/例假日將提前或延後至前/次一工作日發放)'
          }],
      },
      stepsLoadData: [
        {
          name: '申辦方案',
          content: '申辦遠傳 X GoShare 騎樂無窮方案'
        },
        {
          name: '申辦方案',
          content: '申辦遠傳 X GoShare 騎樂無窮方案'
        },
        {
          name: '申辦方案',
          content: '申辦遠傳 X GoShare 騎樂無窮方案'
        },
        {
          name: '申辦方案',
          content: '申辦遠傳 X GoShare 騎樂無窮方案'
        },
      ]
    }
    this.getExclusiveData().then(result => {
      PlanMock = result;
      this.setExclusiveData();
    });
  }
  getExclusiveData = () => {
    return axios('/json/people-plan.json').then(result => {
      // console.log(`result.data`, result.data);
      return JSON.parse(JSON.stringify(result.data));
    })
  }

  setExclusiveData = () => {
    let planTab = [];
    let activePlan = 0;
    let activeTab = 0;
    let activeNum = 0;
    let activeNav = this.props.match.params.plan ? PlanMock.navTab.find((tab, i) => {
      if (this.props.location.pathname === tab.link) {
        activeNum = i
        return tab;
      }
    }) : PlanMock.navTab[0];

    PlanMock.plan.forEach((plan, i) => {
      if (this.props.match.params.plan === plan.title) {
        activePlan = i
      }
      // BannerTab
      // PlanTab
      PlanMock.planTabs.tabs.forEach((tab, k) => {
        if (!planTab[k]) planTab[k] = [];
        tab.child.forEach(ch => {
          if (activeNav.name === ch.name) {
            activeTab = k
          }
          if (ch.name === plan.name) {
            planTab[k].push(plan)
          }
        })
      });
    })

    this.setState({
      query: this.props.match.params.plan || '',
      defaultTab: activeNum,
      navTabs: PlanMock.navTab,
      selectedPlan: PlanMock.plan[activePlan],
      planTabs: {
        title: PlanMock.planTabs.title,
        tableTitle: PlanMock.planTabs.tableTitle,
        tabs: {
          scroll: true,
          list: PlanMock.planTabs.tabs.map(tab => { return { label: tab.name } }),
          default: activeTab
        },
        tabContent: planTab
      },
      filterArr: PlanMock.filterArr
    })

    this.forceUpdate()
  }
  onChange(name, value) {
    let form = Object.assign(this.state.form);
    form[name].value = value;
    this.setState({
      form: form,
    });
  }
  // call API to get steps' data
  loadMore = () => {
    let data = Object.assign(this.state.steps);
    data['list'] = [...this.state.steps.list, ...this.state.stepsLoadData];
    data['hasMore'] = false;
    this.setState({
      list: data
    });
  }
  openModal = (content) => {
    this.setState({
      currentModal: content,
      modalOpen: true
    })
  }
  render() {
    return !PlanMock ? null : (
      <main className='life-circle article'>
        <NavAnchor
          pageTitle='騎樂無窮'
          button={[
            {
              text: '新申辦',
              link: '#'
            },
            {
              text: '推薦續約',
              link: '#'
            }
          ]}
        />
        <AnchorDetect className="vertical-anchor-nav" items={['anchor0', 'anchor1', 'anchor2', 'anchor3', 'anchor4']} activeClass="active" offsetTop={145}>
          <li><span>遠傳獨享</span></li>
          <li><span>騎乘輕體驗</span></li>
          <li><span>上下班通勤</span></li>
          <li><span>瞎趴上路</span></li>
          <li><span>活動說明</span></li>
        </AnchorDetect>
        <OnVisible id="anchor0">
          <BannerPromotionBasic
            className="is-promotion"
            slides={[
              {
                image: {
                  md: '/resources/cbu/img/cbu-promotion-goshare-1920x500.jpg',
                  sm: '/resources/cbu/img/cbu-banner-goshare-750x1000.jpg'
                },
                tag: null,
                title: '<div class="d-inline mt-7"><img style="width: 88px" src="/resources/cbu/img/img-go-share@2x.png" /></div><div class="">騎樂無窮，遠傳帶你輕鬆啟程</div>',
                description: '搭配指定專案，就送$4,800騎乘金、AirPods',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '立即申辦',
                    link: '#'
                  },
                  // {
                  //   "btnStyle": "secondary",
                  //   "text": "刮開每月折100",
                  //   "link": "#",
                  //   "type": "modal",
                  //   "content": ""
                  // }
                ]
              }
            ]}
            openModal={this.openModal}
          />
        </OnVisible>
        <CardFeature
          titleColor="white"
          topBg="/resources/cbu/img/cbu-goshare-bg-blue-1@2x.jpg"
          bottomBg={null}
          {...{
            "title": "5月31前，遠傳獨家搶辦",
            "cards": [
              {
                "type": "card01",
                "image": "/resources/cbu/img/promotion-easy@2x.jpg",
                "title": "上網＋網內雙飽，輕鬆配",
                "list": [
                  {
                    "text": "月付＄699，上網＋網內雙飽"
                  },
                  {
                    "text": "限時加碼，網外最高享1300分鐘"
                  }
                ]
              },
              {
                "type": "card01",
                "image": "/resources/cbu/img/promotion-coupon@2x.jpg",
                "title": "每月高額騎乘金，自由騎行",
                "list": [
                  {
                    "text": "搭配方案享$4,800騎乘金輕鬆GO"
                  },
                  {
                    "text": "小額資費也能享騎乘金"
                  }
                ]
              },
              {
                "type": "card01",
                "image": "/resources/cbu/img/promotion-airpods@2x.jpg",
                "title": "最新3C配件免費送，讓你成為穿梭街道的最美風景",
                "list": [
                  {
                    "text": "最瞎趴AirPods Pro只要$0"
                  },
                  {
                    "text": "Giant都會通勤小折，免費騎"
                  }
                ]
              },
              {
                "type": "card01",
                "image": "/resources/cbu/img/promotion-airpods@2x.jpg",
                "title": "最新3C配件免費送，讓你成為穿梭街道的最美風景",
                "list": [
                  {
                    "text": "最瞎趴AirPods Pro只要$0"
                  },
                  {
                    "text": "Giant都會通勤小折，免費騎"
                  }
                ]
              }
            ]
          }} />
        <QuoteBanner
          className="is-white"
          topBg={null}
          bottomBg={null}
          bgColor="#f6fb6b"
          content={`<h2 class='with-comma m-0 is-text-black100'>
              <span class="border-bottom is-green30">順暢網路及租借體驗</span>，讓我自由穿梭大街小巷
            </h2>`}
        />
        <OnVisible id="anchor1">
          <SectionBanner2
            position='center top'
            className="is-500 is-prmotion-banner top-0"
            image={{
              md: '/resources/cbu/img/cbu-feature-scenario-goshareA-1920x500.jpg',
              sm: '/resources/cbu/img/cbu-feature-scenario-goshareA-750x984.jpg',
            }}
            title='
          <div class="mb-2">
            <h3 class="">
              城市探險好夥伴
            </h3>
            <p class="body mb-md-5 mb-4">絕佳性能與續航力，城市探險的最佳選擇</p>
            <h3 class="">
              隨借隨還
            </h3>
            <p class="body mb-md-5 mb-4">想去哪就去哪，營運範圍內的合法路側停車格</p>
            <h3 class="">
              獨一無二的騎乘體驗
            </h3>
            <p class="body mb-md-5 mb-4">Gogoro 智慧雙輪，提供使用者絕佳的騎乘體驗</p>
          </div>'
            action={null}
          />
        </OnVisible>
        <OnVisible id="anchor2">
          <PromotionCard
            topBg={null}
            bottomBg={this.state.coopBg}
            program={this.state.program}
          />
        </OnVisible>
        <QuoteBanner
          className="is-white"
          bgImage=''
          bgColor="#f6fb6b"
          content={`<h2 class='with-comma m-0 is-text-black100'>
          <span class="border-bottom is-green30">上下班通勤</span>小幫手，<span class="border-bottom is-green30">假日郊遊不能沒有它</span>
            </h2>`}
        />
        <OnVisible id="anchor3">
          <SectionBanner2
            position='center'
            className="is-500 is-prmotion-banner mb-0 top-0 content-right"
            image={{
              md: '/resources/cbu/img/cbu-feature-scenario-goshareB-1920x500.jpg',
              sm: '/resources/cbu/img/cbu-feature-scenario-goshareB-750x984.jpg',
            }}
            title='
          <div class="mb-2">
            <h3 class="">
            滿足日常移動需求
            </h3>
            <p class="body mb-md-5 mb-4">方便快速租借，輕鬆上路</p>
            <h3 class="">
              通勤族新選擇
            </h3>
            <p class="body mb-md-5 mb-4">輕巧自在騎乘，短途通勤輕鬆有型</p>
            <h3 class="">
            幫你打氣充滿電
            </h3>
            <p class="body mb-md-5 mb-4">搭配無線充電手機架，滿電出發</p>
          </div>'
            action={null}
          />
        </OnVisible>
        <OnVisible id="anchor4">
          <CardPlanBasic
            title="不用支付太多，共享騎乘輕體驗"
            list={this.state.cityRoadProgram}
            bottomBg={this.state.cityRoad}
          />
        </OnVisible>
        <EventInfo
          topBg={'/resources/cbu/img/cbu-goshare-bg-blue3.png'}
          bottomBg={'/resources/cbu/img/cbu-goshare-bg-yellow2.png'}
        >
          <h4 className="mt-md-4 mb-2">
            活動說明
              </h4>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <div className="is-bg-lightgray70 px-3 py-3">
                <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                  活動日期
                  </h5>
                <h2 className='mb-0'>即日至<div className="border-bottom">2020/05/31</div></h2>
              </div>
            </Grid>
          </Grid>
          <h4 className="mt-md-7 mt-3 mb-2">GoShare騎乘金說明：</h4>
          <p className="mt-md-4 mt-2">請於完成方案申辦後，<div className='border-bottom'>自行以方案門號完成註冊GoShare APP會員</div>，如方案門號申辦後次月5日前，未完成註冊GoShare APP會員，視同放棄當月GoShare騎乘金優惠券，恕無法補發。GoShare詳細營運地區及騎乘金使用規範，請參考 GoShare App。</p>
          <h4 className="mt-md-7 mt-4">GoShare營運範圍：</h4>
          <ul className="green-dot-list mb-md-6 mb-4">
            <li>台北市、桃園市<span className="border-bottom">24小時隨借隨還</span></li>
            <li>新北青春山海線<span className="border-bottom">24小時隨借隨還</span></li>
          </ul>
          <div className="page-action mb-1">
            <Button btnStyle='secondary' size='large' link="#">下載GoShare APP</Button>
            <Button btnStyle='secondary' size='large' link="#">服務內容</Button>
          </div>
        </EventInfo>
        <OnVisible id="anchor5">
          <CardMultiSteps
            bg={this.state.credit}
            {...this.state.steps}
            loadMore={this.loadMore}
          />
          <SectionCollapseInfo
            title="貼心小叮嚀"
            content={`
                <ol>
                  <li>此功能不需帳號及密碼，只要輸入身分資料完成登入檢核就可進行繳費。</li>
                  <li>快速登入後僅能進行繳費功能，若需使用其他功能，請先進行登入。</li>
                  <li>繳納費用非一般性消費支出，請洽各發卡銀行確認是否有刷卡紅利或現金回饋。</li>
                </ol>
              `}
          />
        </OnVisible>
        <div className="is-bg-lightgray50 pb-md-10 pb-6 pt-1">
          <div className="fui-container">
            <SocialMedia
              theme="light"
              fbLink="#"
              lineLink="#"
              size={48}
              displayContent="分享遠傳優惠"
            />
          </div>
        </div>
        <Modal
          onAfterOpen={e => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={e => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel="Example Modal"
          className="fui-app-trial-modal">
          {this.state.currentModal ? (
            this.state.currentModal.type === 'app-trial' ? (
              <div className="fui-app-trial-body">
                <img src={this.state.currentModal.md} className='d-none d-md-block' alt="" />
                <img src={this.state.currentModal.sm} className='d-block d-md-none' alt="" />
                <div className="fui-buttons two-button">
                  <Button btnStyle='primary' link={this.state.currentModal.link} target={this.state.currentModal.target}>詳細內容</Button>
                  <Button btnStyle='secondary' onClick={e => this.setState({ modalOpen: false })}>關閉</Button>
                </div>
              </div>
            ) : (
                <div className="gamer_center">
                  <div className="content" dangerouslySetInnerHTML={{ __html: this.state.currentModal.content }}>
                  </div>
                  <div className="fui-buttons two-button">
                    <Button btnStyle='secondary' onClick={e => this.setState({ modalOpen: false })}>關閉</Button>
                  </div>
                </div>
              )) : null
          }
        </Modal>
      </main>
    );
  }
}

export default PromotionCoop;