import React from 'react';
import Button from '../../components/Button';
import PropTypes from 'prop-types';

class Panel extends React.Component {
  render() {
    return (
      <div className="fui-container">
        <div
          className={`fui-panel-container ${this.props.className ? this.props.className : ''} ${this.props.initial ? 'no-shadow no-bg' : ''}`}
          style={this.props.bgColor ? ({ backgroundColor: this.props.bgColor }) : null}
        >
          <div />
          <div dangerouslySetInnerHTML={{
            __html: this.props.content || '',
          }} />
          <div className="page-action mb-1">
            {this.props.btnList.map((item, i) => {
              return (
                <Button
                  key={i}
                  btnStyle='secondary'
                  size='large'
                  link={item.link}
                >
                  {item.text}
                </Button>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
  bgColor: PropTypes.string,
  initial: PropTypes.bool,
  btnList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string
    })
  ),
}

export default Panel;