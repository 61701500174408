import React, { Component } from 'react';
import Link from '../Link';
import PropTypes from 'prop-types';

class NextArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section className='next-article'>
        <div className='fui-container fui-container-article'>
          {this.props.prev.title ? (
            <Link to={this.props.prev.link.url} target={this.props.prev.link.target}>
              <div className='next-article--prev'>
                <img src='/resources/cbu/e-service/images/right.svg' alt='prev' />
                <div className='d-none d-md-block'>
                  <small className='is-text-coral100 mb-1'>上一篇</small>
                  <div className='body'>{this.props.prev.title}</div>
                </div>
                <div className='d-block d-md-none'>上一篇</div>
              </div>
            </Link>
          ) : (
            <div className='next-article--prev'>
              <span style={{ minWidth: '42px', marginRight: '12px' }}></span>
              <div className='d-none d-md-block'>
                <small className='mb-1' style={{ color: '#ccc' }}>
                  上一篇
                </small>
                <div className='body' style={{ color: '#ccc', fontWeight: 'normal' }}>
                  無更多頁面
                </div>
              </div>
              <div className='d-block d-md-none' style={{ color: '#ccc', fontWeight: 'normal' }}>
                無更多頁面
              </div>
            </div>
          )}
          {this.props.next.title ? (
            <Link to={this.props.next.link.url} target={this.props.next.link.target}>
              <div className='next-article--next'>
                <div className='d-none d-md-block align-right'>
                  <small className='is-text-coral100 mb-1'>下一篇</small>
                  <div className='body'>{this.props.next.title}</div>
                </div>
                <div className='d-block d-md-none'>下一篇</div>
                <img src='/resources/cbu/e-service/images/right.svg' alt='next' />
              </div>
            </Link>
          ) : (
            <div className='next-article--next'>
              <div className='d-none d-md-block align-right'>
                <small className='mb-1' style={{ color: '#ccc' }}>
                  下一篇
                </small>
                <div className='body' style={{ color: '#ccc', fontWeight: 'normal' }}>
                  無更多頁面
                </div>
              </div>
              <div className='d-block d-md-none' style={{ color: '#ccc', fontWeight: 'normal' }}>
                無更多頁面
              </div>
              <span style={{ minWidth: '42px', marginLeft: '12px' }}></span>
            </div>
          )}
        </div>
      </section>
    );
  }
}
NextArticle.propTypes = {
  // title: PropTypes.string,
  // article: PropTypes.array,
};
export default NextArticle;
