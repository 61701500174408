import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import NavContentTab2 from '../../components/partials/NavContentTab2';
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import Panel from '../../components/panel/Panel';
import PanelTab from '../../components/panelContent/PanelTab';
import PanelFigure1 from '../../components/panelContent/PanelFigure1';
import TabPane from '../../components/tab/TabPane';
import SectionCarousel3 from '../../components/partials/card/SectionCarousel3';
import PanelInformation from '../../components/panelContent/PanelInformation';
import BannerWithLink from '../../components/partials/banner/BannerWithLink';
import { Grid } from '@material-ui/core';
import ServiceTag from '../../components/partials/ServiceTag';
class Wifi extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  changeMainTab = value => {
    this.setState({
      currentMainTab: value,
    });
    // return value === 0 ? this.props.history.push('/cbu/5g') : null
  };
  render() {
    return (
      <main className="prepaid">
        <Header />
        <NavAnchor
          pageTitle='<a href="#">Wi-Fi 上網</a>'
          button={{
            link: '#',
            text: '聯絡我們',
          }}
          tabs={[
            { label: '自動連網說明', link: '#autoConnection' },
            { label: '如何設定', link: '#howTo' },
            { label: '熱點查詢', link: '#hotspot' },
            { label: '更多優惠', link: '#promotion' },
            { label: '常見問題', link: '#faq' },
          ]}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: 'Wi-Fi 上網', link: '/' }
          ]}
          color='white'
        />
        <SectionBanner2
          image={{
            md: '/resources/cbu/otherService/cbu-wifitab1-banner-1920x470.jpg',
            sm: '/resources/cbu/otherService/cbu-wifitab1-750x720.jpg',
          }}
          title='Wi-Fi 自動連網'
          description='
            <h6>
              <img src="/resources/cbu/prepaid/images/checklist.svg"/>更方便 自動切換 3G/4G 到 Wi-Fi
            </h6>
            <h6>
              <img src="/resources/cbu/prepaid/images/checklist.svg"/>更安全 自動登入，免輸入帳號、密碼
            </h6>'
          action={null} />
        <NavContentTab2 tabs={{
          name: 'add-value-tab',
          icon: false,
          title: true,
          list: [
            {
              name: 'add-value',
              title: 'Wi-Fi 自動連網',
              label: '',
              link: '/otherService/Wifi',
              target: '_self',
            },
            {
              name: 'application',
              title: 'Wi-Fi 手動設定上網',
              label: '',
              link: '/otherService/WifiManual',
              target: '_self',
            },
          ]
        }} onChange={e => this.changeMainTab(e)} />
        <div className="ribbon-bg">
          <section className='p-0'>
            <div className='tab-content'>
              {
                <TabPane active={true}>
                  <div className='fui-container'>
                    <section id="autoConnection" className="p-0">
                      <h2 className="">FET Wi-Fi 自動連網</h2>
                      <div className="paper mt-0">
                        <h4 className="mt-4">免帳號密碼 自動上網</h4>
                        <p>「Wi-Fi自動連網服務」是以遠傳電信 USIM 作為認證方式，只要您的行動裝置插有遠傳 SIM 卡，並已完成「Wi-Fi自動連網服務」的設定，則在所有 FET Wi-Fi 熱點的區域都能夠免輸入帳號、密碼就自動連上遠傳 Wi-Fi 網路喔！</p>
                        <h4 className="mt-4">適用對象</h4>
                        <ul className="green-dot-list">
                          <li>手機方案：4G $599費率以上</li>
                          <li>平板方案：4G $499費率以上</li>
                        </ul>
                      </div>
                    </section>
                    <section id="howTo" className="">
                      <h2 className="">如何設定自動連網</h2>
                      <div className="paper">
                        <PanelTab
                          tabs={{
                            name: 'panel-tab',
                            list: [
                              { name: 'panel-tab-1', label: 'iOS 系統' },
                              { name: 'panel-tab-2', label: 'Android 系統' }
                            ],
                          }}>
                          <div className="mt-5 pt-2">
                            <p>請依下列步驟完成自動上網的設定（ 僅支援 iOS 8.3 以上版本）：</p>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={4}>
                                <PanelFigure1 inline={false} image='/resources/cbu/otherService/01.png' caption='Step 1：請選取「設定」中的「Wi-Fi」功能' />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4}>
                                <PanelFigure1 inline={false} image='/resources/cbu/otherService/02.png' caption='Step 2：向右滑，開啟「Wi-Fi」功能，自動搜尋熱點' />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4}>
                                <PanelFigure1 inline={false} image='/resources/cbu/otherService/03.png' caption='Step 3：選取「 FET Wi-Fi Auto 」成功連線後，即可開始上網' />
                              </Grid>
                            </Grid>
                          </div>
                          <div className="mt-5 pt-2">
                            <p>請依下列步驟完成自動上網的設定</p>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={12} md={4}>
                                <PanelFigure1 inline={false} image='/resources/cbu/otherService/android-01.png' caption='Step 1：請選取「設定」中的「Wi-Fi」功能' />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4}>
                                <PanelFigure1 inline={false} image='/resources/cbu/otherService/android-02.png' caption='Step 2：手機自動搜尋「FET Wi-Fi Auto」, 進行認證後即自動登入' />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4}>
                                <PanelFigure1 inline={false} image='/resources/cbu/otherService/android-03.png' caption='Step 3：成功登入後，顯示「已連線」即可開始上網' />
                              </Grid>
                            </Grid>
                          </div>
                        </PanelTab>
                      </div>
                    </section>
                    <BannerWithLink
                      id="hotspot"
                      title='熱點查詢'
                      image={{
                        md: '/resources/cbu/otherService/img-wifi-hotspot-lg.jpg',
                        sm: '/resources/cbu/otherService/img-wifihotspot-mobile.jpg',
                      }}
                      bannerTitle='遍佈全台的 Wi-Fi 熱點'
                      bannerDescription='<p>FET Wi-Fi 在全國擁有超過 10,000 個熱點，現在就開始搜尋離你最近的 Wi-Fi 熱點吧！</p>'
                      bannerAction={{
                        text: '查詢遠傳門市',
                        link: '#',
                        target: '_self'
                      }}
                      link={[
                        {
                          title: '查詢全虹門市',
                          link: "#",
                          target: '_blank'
                        },
                        {
                          title: '查詢全家便利商店門市',
                          link: "#"
                        },
                        {
                          title: '查詢萊爾富便利商店門市',
                          link: "#"
                        }
                      ]}
                    />
                  </div>
                  <SectionCarousel3 {...{
                    title: '更多優惠',
                    id: "promotion",
                    cards: [
                      {
                        image: '/resources/cbu/otherService/img-01.png',
                        title: '打 $1 送 $1 帳單折抵',
                        description: '遠傳月租型獨享優惠！不分時段，不分國家，每打1元就送1元回饋金！',
                        link: '#',
                      },
                      {
                        image: '/resources/cbu/otherService/img-02.png',
                        title: '市話撥打 007 享優惠',
                        description: '免收月租費，撥打到大陸、香港、日本於一般時段及減價時段皆各享有優惠...',
                        link: '#',
                      },
                      {
                        image: '/resources/cbu/otherService/img-03.png',
                        title: '市話 007 晶鑽專案',
                        description: '申請晶鑽方案，撥打中國大陸、香港、加拿大、美國，不分時段皆享優惠價！',
                        link: '#',
                      },
                    ],
                    more: { text: '看更多', link: '#' },
                  }} />
                  <div id="" className="is-bg-gray30 mt-5">
                    <div className="fui-container">
                      <PanelInformation title={'備註說明'} content={`
                        <ol>
                          <li>使用「遠傳Wi-Fi Auto服務」需特定資費或方案的用戶才可使用</li>
                          <li>使用此服務需搭配iOS3.1.X以上版本或特定Android行動裝置才能使用</li>
                          <li>不需另外申請，只要行動裝置及搭配的數據資費或方案符合使用條件，自行設定後即可開始使用</li>
                          <li>因經營成本及其他相關因素之變動，遠傳保留修改或終止本優惠費率與優惠活動之權利，適用期間及活動內容以遠傳網站公告為主。</li>
                        </ol>
                      `} />
                    </div>
                  </div>
                </TabPane>
              }
            </div>
          </section>
        </div>
        <Panel className="is-bg-lightgray70 mb-0">
          <div id="faq" className="fui-container">
            <h3 className="is-text-darkgray50 is-text-medium m-0">常見問題</h3>
            <SectionFaq {...{
              collapseList: [
                {
                  title: '我的 iPhone 手機版本無法支援自動連網時，可以怎麼辦?',
                  content: `語音通話的購買通路有哪些？`,
                },
                {
                  title: '遠傳 Wi-Fi 自動連網服務是否有特定行動上網裝置才能使用？',
                  content: `超值版上網的上網傳輸量沒用完怎麼辦？`,
                },
                {
                  title: '手動設定上網時，忘記密碼怎麼辦?',
                  content: `每個儲值方式都可享有優惠嗎？  `,
                },
              ],
              container: false,
              to: '#',
              target: '_blank'
            }} />
          </div>
        </Panel>
        <ServiceTag {...{
          title: '看看大家都搜什麽',
          tags: [
            {
              name: '國際漫遊',
              link: '#',
            },
            {
              name: '外國旅客上網卡',
              link: '#',
            },
            {
              name: '獨家資費',
              link: '#',
            }
          ]
        }} />
      </main>
    );
  }
}
export default Wifi;