import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';

import LabelInput from '../../components/form/LabelInput';
import RadioGroup from '../../components/form/RadioGroup';
import Select from '../../components/form/Select';
import Button from '../../components/Button';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';


class bankDepositPage extends React.Component {
  constructor(props) {
    super(props);
    this.tooltipContent = React.createRef();
    this.bankForm = React.createRef();
    this.creditForm = React.createRef();
    this.state = {
      step: 1,
      canSubmit1: false,
      canSubmit2: false,
      form_1: {
        payment: { value: '1', required: true },
        happygo: { value: '500', required: true },
      },
      form_2: {
        id_number: { value: '', required: true },
        payment: { value: '1' },
        msisdn: { value: '', required: true },
        credit_number_1: { value: "", required: true },
        credit_number_2: { value: "", required: true },
        credit_number_3: { value: "", required: true },
        credit_number_4: { value: "", required: true },
        credit_year: { value: null, required: true },
        credit_month: { value: null, required: true },
        credit_code: { value: '', required: true },
        bank_code: { value: '', required: true },
        bank_account: { value: '', required: true },
        receipt: { value: '1', required: true },
        receipt_extra_2: { value: '', required: true },
        receipt_extra_3: { value: '', required: true },
        agree: { value: false, required: true }
      }
    }
  }

  changeTab = (value) => {
    this.setState({
      currentTab: value
    })
  }

  enableButton = () => {
    if (this.state.step === 1)
      this.setState({
        canSubmit1: true
      })
    else
      this.setState({
        canSubmit2: true
      })
  }

  disableButton = () => {
    if (this.state.step === 1)
      this.setState({
        canSubmit1: false
      })
    else
      this.setState({
        canSubmit2: false
      })
  }

  onChange1 = (n, v) => {
    let form = Object.assign(this.state.form_1);
    form[n].value = v;

    this.setState({
      form_1: form
    })
  }

  onChange2 = (n, v) => {
    let form = Object.assign(this.state.form_2);
    form[n].value = v;

    this.setState({
      form_2: form
    })
  }

  nextStep = () => {
    this.setState({
      step: 2
    })
  }

  submit = () => {
    this.props.history.push('/billCreditcardPayController/billCreditcardPayPage')
  }

  render() {
    const { step, form_1, form_2 } = this.state;

    return (
      <main>
        {/* 標題需再確認後更新 */}
        <NavAnchor
          pageTitle='銀行帳號繳費'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="銀行帳號繳費"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <div>
          <section className='pt-2 pt-md-5'>
            <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
              <div className="fui-container">
                <div className='paper pb-2'>
                  <h4 className="my-0">請選擇應繳帳單</h4>
                  <RadioGroup
                    label=''
                    name="payment"
                    options={[
                      { label: '上期應繳金額', value: '1' },
                      { label: '全部應繳金額', value: '2' }
                    ]}
                    default={form_1.payment.value}
                    onChange={this.onChange1}
                  />
                </div>
                <div className="paper">
                  <h4>帳單明細</h4>
                  <table className="text-wrap">
                    <tbody>
                      <tr>
                        <th>手機門號</th>
                        <td className="align-left">0936-000-123</td>
                      </tr>
                      <tr>
                        <th>繳費期限</th>
                        {
                          form_1.payment.value === '1' ? (
                            <td className="align-left is-text-error">已逾期</td>
                          ) : (
                              <td className="align-left">2019/01/08</td>
                            )
                        }
                      </tr>
                      <tr>
                        <th>本次繳費金額</th>
                        <td className="align-left">1,986元</td>
                      </tr>
                    </tbody>
                  </table>
                  {/* 沒有上期應繳金額 和 全部應繳金額 時顯示說明*/}
                  <div className="is-text-gray100 mt-1">
                    <small>本期應繳金額少於600元(含)可累計下期帳單合併繳費</small>
                  </div>

                  <h4 className='mt-4'>應繳費項</h4>
                  <table className="payment-table is-text-black50">
                    <tbody>
                      <tr>
                        <th className='align-left p-2'>
                          <Grid container>
                            <Grid item xs={6}>
                              <span className='is-text-black50'>電信費用</span>
                            </Grid>
                            <Grid item xs={6}>
                              <span className="float-right is-text-black50">1,401元</span>
                            </Grid>
                          </Grid>
                          <Grid container className='happygo'>
                            <Grid item xs={6}>
                              <span className='is-text-black50'>HAPPY GO 點數折抵</span>
                            </Grid>
                            <Grid item xs={6}>
                              <div className="float-right">
                                <div className='d-inline-block my-2'>
                                  <Select
                                    name='happygo'
                                    options={[
                                      { text: '500 元', value: '500' },
                                      { text: '1000 元', value: '1000' },
                                      { text: '1500 元', value: '1500' }
                                    ]}
                                    value={form_1.happygo.value}
                                    onChange={this.onChange1}
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} className="note">
                              <span className="is-text-gray100">
                                尚有 1819 點 (截至 2014 / 09 / 29 )<br />
                                2019/12/31 到期點數：0點
                              </span>
                            </Grid>
                          </Grid>
                        </th>
                      </tr>
                      <tr>
                        <th className='align-left p-2'>
                          <Grid container>
                            <Grid item xs={6}>
                              <span className='is-text-black50'>帳單總額</span>
                            </Grid>
                            <Grid item xs={6}>
                              <h2 className="is-text-error my-0 float-right">1,401元</h2>
                            </Grid>
                          </Grid>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                  <table className="payment-table is-text-black50">
                    <tbody>
                      <tr>
                        <th className='align-left p-2'>
                          <Grid container>
                            <Grid item xs={6}>
                              <span className='is-text-black50'>電信費用</span>
                            </Grid>
                            <Grid item xs={6}>
                              <span className="float-right is-text-black50">1,401元</span>
                            </Grid>
                          </Grid>
                          <Grid container className="happygo">
                            <Grid item xs={6}>
                              <span className='is-text-black50'>HAPPY GO 點數折抵</span>
                            </Grid>
                            <Grid item xs={6}>
                              <div className="float-right">
                                <div className='d-inline-block my-2'>
                                  <Select
                                    name='happygo'
                                    options={[
                                      { text: '500 元', value: '500' },
                                      { text: '1000 元', value: '1000' },
                                      { text: '1000 元', value: '1000' }
                                    ]}
                                    value={form_1.happygo.value}
                                    onChange={this.onChange1}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} className="note">
                              <span className="is-text-gray100">點數不足，或尚未申請 HAPPY GO，未能使用 HAPPY GO 紅利點數</span>
                            </Grid>
                          </Grid>
                        </th>
                      </tr>
                      <tr>
                        <th className='align-left p-2'>
                          <Grid container>
                            <Grid item xs={6}>
                              <span className='is-text-black50'>帳單總額</span>
                            </Grid>
                            <Grid item xs={6}>
                              <h2 className="is-text-error my-0 float-right">1,401元</h2>
                            </Grid>
                          </Grid>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="page-action">
                  <Button btnStyle='primary' size='large' onClick={this.nextStep}>填寫付款資料</Button>
                  <Button btnStyle='secondary' size='large' link="/payController/payPage">取消</Button>
                </div>
              </div>
            </Formsy>
          </section>
        </div>

        <SectionCollapseInfo
          title="注意事項"
          content={`
            1. 此功能不需帳號及密碼，只要輸入身分資料完成登入檢核就可進行繳費。<br/>
            2. 快速登入後僅能進行繳費功能，若需使用其他功能，請先進行登入。<br/>
            3. i繳納費用非一般性消費支出，請洽各發卡銀行確認是否有刷卡紅利或現金回饋。
          `}
        />
      </main>
    )
  }
}

export default bankDepositPage;