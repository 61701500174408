import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import * as Validation from '../../utils/validation';

import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
class QueryLogin extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef()
    this.state = {
      form: {
        id_number: { value: '', required: true },
      },
      canSubmit: false
    }
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    // console.log('onChange', name, value)
    this.setState({
      form: newForm,
    });
  };
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={[
            {
              text: '立即續約',
              link: '#',
            },
            {
              text: '回個人專區',
              link: '/',
            },
          ]}
        />

        <FormBanner
          title="帳單查詢"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="query-index pb-md-0 pb-5">
          <div className="fui-container">
            <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼進行確認</h4>
                  <LabelInput
                    className="mb-2"
                    name='id_number'
                    required={this.state.form.id_number.required}
                    label='證照號碼'
                    validations={{
                      idNumberValid: Validation.idNumberValid
                    }}
                    validationErrors={{
                      isDefaultRequiredValue: '請輸入證照號碼'
                    }}
                    placeholder='請輸入證照號碼'
                    value={this.state.form.id_number.value}
                    onChange={this.onChange}
                  />
                  <p className="body-1 is-text-darkgray50 mt-0 mb-3">
                    身分說明：<br />
                    個人戶：身分證字號<br />
                    公司戶：統一編號或稅籍編號<br />
                    外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼<br />
                    ※當您使用此服務時，視為您已詳細閱讀並同意"<a className="info-link" to="#">遠傳官網會員條款</a>"所有內容 。
                  </p>
                  <Button link="#" className={this.state.canSubmit ? '' : 'disabled'} btnStyle='primary' size='large'>確認</Button>
                </Grid>
              </Grid>
            </Formsy>
          </div>
        </section>

      </main >
    )
  }
}

export default QueryLogin;