import React from 'react';

import PropTypes from 'prop-types';

class Panel extends React.Component {
    render() {
        return (
            <div
                className={`fui-panel ${this.props.className ? this.props.className : ''} ${this.props.initial ? 'no-shadow no-bg' : ''}`}
                style={this.props.bgColor ? ({ backgroundColor: this.props.bgColor }) : null}
            >
                {this.props.children}
            </div>
        )
    }
}

Panel.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string
}

export default Panel;