import React from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class LabelTextarea extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      isFocus: false,
      submitted: false,
      isInvalid: false,
      required: this.props.required || false,
      placeholder: this.props.placeholder || '您可以在此輸入需求...',
      maxLength: this.props.maxLength || 450,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  setFocus = event => {
    this.setState({
      isFocus: true,
    });
  };

  setBlur = event => {
    this.props.setValue(this.input.current.value || '');

    this.setState({
      submitted: this.props.isFormSubmitted(),
      isFocus: false,
      isInvalid: this.props.showRequired() || this.props.showError()
    });
  };

  componentDidUpdate(prevProps) {
    // console.group(prevProps.value, 
    if (prevProps.getValue() !== this.props.getValue()) {
      this.input.current.value = this.props.getValue()
    }
    if (this.props.isFormSubmitted() !== this.state.submitted) {
      // console.log(this.props.value)
      this.setState({
        submitted: this.props.isFormSubmitted(),
        isInvalid: this.props.showRequired() || this.props.showError()
      })
    }
  }

  handleChange(event) {
    let val = this.input.current.value;

    if (val.length > this.state.maxLength) {
      return false;
    }
    this.props.setValue(val);
    this.input.current.value = val;

    this.setState({
      isInvalid: this.props.showRequired() || this.props.showError()
    })

    this.props.onChange(this.props.name, val);
  }

  render() {
    const errorMessage = this.props.getErrorMessage();

    return (
      <div className={`form-group ${this.state.isInvalid ? 'is-invalid' : ''} ${this.props.className ? this.props.className : ''}`}>
        <label htmlFor={`textarea-${this.props.name}`} className={this.state.required ? 'is-required' : ''}>
          {this.props.label}
        </label>
        <div className={`fui-textarea ${this.state.isFocus ? 'is-focus' : ''}`}>
          <textarea
            id={`textarea-${this.props.name}`}
            name={this.props.name}
            ref={this.input}
            placeholder={this.props.placeholder}
            value={this.props.getValue() || ''}
            onBlur={this.setBlur}
            onFocus={this.setFocus}
            onKeyPress={this.handleChange}
            onChange={this.handleChange}></textarea>
          <div className='max-length-counter'>
            {(this.props.getValue() || '').length} / {this.state.maxLength}
          </div>
        </div>
        {this.state.isInvalid ? <span className='error-message'>{errorMessage}</span> : ''}
      </div>
    );
  }
}

LabelTextarea.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

export default withFormsy(LabelTextarea);
