import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

import LifeCirclePromoCarousel from '../../components/partials/card/LifeCirclePromoCarousel';
import LifeCircleCarousel from '../../components/partials/card/LifeCircleCarousel';
import LifeCircleBanner from '../../components/partials/banner/LifeCircleBanner';

import Tab from '../../components/tab/Tab';
import TabPane from '../../components/tab/TabPane';

import ToolsItem from '../../components/item/ToolsItem';

import Button from '../../components/Button';
// import AnchorDetect from 'react-anchor-scroll-detect';
import AnchorDetect from '../../components/AnchorDetect';
// import * as Scroll from 'react-scroll';
// import { Link, div, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import BanCard from '../../components/card/BanCard';
import OnVisible, { setDefaultProps } from 'react-on-visible';

import * as Mock from '../../mock/LifeCircle';
setDefaultProps({
  bounce: true,
  percent: 20
});

class slashie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0
    }
  }

  changeTab = (value) => {
    this.setState({
      currentTab: value
    })
  }
  
  render() {
    const { currentTab } = this.state;
    return (
      <main className="life-circle article">
        <NavAnchor
          pageTitle='遠傳生活圈'
          button={{
            text: '回生活圈',
            link: '/',
          }}
        />


        <AnchorDetect className="vertical-anchor-nav" items={['anchor0', 'anchor1', 'anchor2', 'anchor3', 'anchor4']} activeClass="active" offsetTop={145} >
          <li><span>智能家居</span></li>
          <li><span>智能家居</span></li>
          <li><span>智能家居</span></li>
          <li><span>智能家居</span></li>
          <li><span>智能家居</span></li>
        </AnchorDetect>
        
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '遠傳生活圈', link: '/' },
            { text: '斜槓老爸', link: '' },
          ]}
          color='black'
        />

        <OnVisible id="anchor0">
          <LifeCircleBanner {...Mock.slashieBanner0} />
        </OnVisible>

        <section>
          <div className="fui-container">
            <Tab
              name=''
              list={[
                { label: '鬧鐘喚醒' },
                { label: 'APP開燈開窗' },
                { label: '啟動咖啡機' },
              ]}
              onChange={this.changeTab} 
            />
            <TabPane active={currentTab === 0}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <img src={process.env.PUBLIC_URL + '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-01.jpg'} alt='' />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="w-100 wording">
                    <p className="my-0 is-life-circle-gray">遠傳全能行動管家</p>
                    <h3 className="is-text-regular">智慧插頭連結家裡的窗簾，用全能行動管家APP輕鬆開窗又開燈</h3>
                    <Button btnStyle='secondary' size='' className="is-sm-block">打造智能家居</Button>
                  </div>
                </Grid>
              </Grid>
            </TabPane>
            <TabPane active={currentTab === 1}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-01.jpg'} alt='' />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="w-100 wording">
                    <p className="my-0 is-life-circle-gray">遠傳全能行動管家</p>
                    <h3 className="is-text-regular">智慧插頭連結家裡的窗簾，用全能行動管家APP輕鬆開窗又開燈</h3>
                    <Button btnStyle='secondary' size='' className="is-sm-block">打造智能家居</Button>
                  </div>
                </Grid>
              </Grid>
            </TabPane>
            <TabPane active={currentTab === 2}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-01.jpg'} alt='' />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="w-100 wording">
                    <p className="my-0 is-life-circle-gray">遠傳全能行動管家</p>
                    <h3 className="is-text-regular">智慧插頭連結家裡的窗簾，用全能行動管家APP輕鬆開窗又開燈</h3>
                    <Button btnStyle='secondary' size='' className="is-sm-block">打造智能家居</Button>
                  </div>
                </Grid>
              </Grid>
            </TabPane>
          </div>
        </section>
        
        <OnVisible id="anchor1">
          <LifeCircleBanner {...Mock.slashieBanner1} />
        </OnVisible>

        <BanCard {...Mock.slashieBancard1}/>

        <OnVisible id="anchor2">
          <LifeCircleBanner {...Mock.slashieBanner2} />
        </OnVisible>

        <BanCard {...Mock.slashieBancard2} />

        <OnVisible id="anchor3">
          <LifeCircleBanner {...Mock.slashieBanner3} />
        </OnVisible>

        <section className="">
          <div className="fui-container">
            <Tab
              name=''
              list={[
                { label: '即時定位' },
                { label: '安全守護區' },
                { label: '聲控撥號' },
              ]}
              onChange={this.changeTab} 
            />
            <TabPane active={currentTab === 0}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-04.jpg'} alt='' />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="w-100 wording">
                    <p className="my-0 is-life-circle-gray">遠傳愛講智慧手錶</p>
                    <h3 className="is-text-regular">當孩子進入或離開安全守護區時，立即通知家長。</h3>
                    <Button btnStyle='secondary' size='' className="is-sm-block">了解手錶規格</Button>
                  </div>
                </Grid>
              </Grid>
            </TabPane>
            <TabPane active={currentTab === 1}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-04.jpg'} alt='' />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="w-100 wording">
                    <p className="my-0 is-life-circle-gray">遠傳愛講智慧手錶</p>
                    <h3 className="is-text-regular">當孩子進入或離開安全守護區時，立即通知家長。</h3>
                    <Button btnStyle='secondary' size='' className="is-sm-block">了解手錶規格</Button>
                  </div>
                </Grid>
              </Grid>
            </TabPane>
            <TabPane active={currentTab === 2}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-04.jpg'} alt='' />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="w-100 wording">
                    <p className="my-0 is-life-circle-gray">遠傳愛講智慧手錶</p>
                    <h3 className="is-text-regular">當孩子進入或離開安全守護區時，立即通知家長。</h3>
                    <Button btnStyle='secondary' size='' className="is-sm-block">了解手錶規格</Button>
                  </div>
                </Grid>
              </Grid>
            </TabPane>
          </div>
        </section>

        <OnVisible id="anchor4">
          <LifeCircleBanner {...Mock.slashieBanner4} />
        </OnVisible>

        <section className="">
          <div className="fui-container">
            <Tab
              name=''
              list={[
                { label: '整合帳單' },
                { label: '集中會員卡' },
                { label: '發票自動對獎' },
                { label: 'icash、悠遊卡支付' },
              ]}
              onChange={this.changeTab} 
            />
            <TabPane active={currentTab === 0}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-05.jpg'} alt='' />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="w-100 wording">
                    <p className="my-0 is-life-circle-gray">friDay57 APP</p>
                    <h3 className="is-text-regular">卡費、電話費、水費、停車費，開車還有eTag，一堆煩人的生活費用，用friDay57一次搞定。</h3>
                    <Button btnStyle='secondary' size='' className="is-sm-block">下載friDay57 APP</Button>
                  </div>
                </Grid>
              </Grid>
            </TabPane>
            <TabPane active={currentTab === 1}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-05.jpg'} alt='' />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="w-100 wording">
                    <p className="my-0 is-life-circle-gray">friDay57 APP</p>
                    <h3 className="is-text-regular">卡費、電話費、水費、停車費，開車還有eTag，一堆煩人的生活費用，用friDay57一次搞定。</h3>
                    <Button btnStyle='secondary' size='' className="is-sm-block">下載friDay57 APP</Button>
                  </div>
                </Grid>
              </Grid>
            </TabPane>
            <TabPane active={currentTab === 2}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-05.jpg'} alt='' />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="w-100 wording">
                    <p className="my-0 is-life-circle-gray">friDay57 APP</p>
                    <h3 className="is-text-regular">卡費、電話費、水費、停車費，開車還有eTag，一堆煩人的生活費用，用friDay57一次搞定。</h3>
                    <Button btnStyle='secondary' size='' className="is-sm-block">下載friDay57 APP</Button>
                  </div>
                </Grid>
              </Grid>
            </TabPane>
            <TabPane active={currentTab === 3}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-05.jpg'} alt='' />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="w-100 wording">
                    <p className="my-0 is-life-circle-gray">friDay57 APP</p>
                    <h3 className="is-text-regular">卡費、電話費、水費、停車費，開車還有eTag，一堆煩人的生活費用，用friDay57一次搞定。</h3>
                    <Button btnStyle='secondary' size='' className="is-sm-block">下載friDay57 APP</Button>
                  </div>
                </Grid>
              </Grid>
            </TabPane>
          </div>
        </section>
        
        <ToolsItem {...Mock.slashieToolsItem} />
 
        <LifeCirclePromoCarousel {...Mock.slashiePromoCard} />
  

        <LifeCircleCarousel {...Mock.slashieLifeCircleCard} />
      </main>
    );
  }
}

export default slashie;
