import React from 'react';
import { Grid } from '@material-ui/core';
import PanelFigure from '../../components/panelContent/PanelFigure1'
import PropTypes from 'prop-types';

class Panel extends React.Component {
  render() {
    return (
      <div className="fui-container">
        <div
          className={`fui-panel-container ${this.props.className ? this.props.className : ''} ${this.props.initial ? 'no-shadow no-bg' : ''}`}
          style={this.props.bgColor ? ({ backgroundColor: this.props.bgColor }) : null}
        >
          {this.props.list.map((item, i) => {
            return (
              <PanelFigure
                key={i}
                image={item.image}
                caption={item.caption}
                inline={item.inline}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
}

export default Panel;