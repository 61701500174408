import React from 'react';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import RadioGroup from '../../components/form/RadioGroup';
import LabelInput from '../../components/form/LabelInput';
import * as Validation from '../../utils/validation';

class VoiceBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      canSubmit: false,
      form: {
        current_setting: { value: '0', required: true },
        unanwsered: { value: '0', required: true },
        greeting: { value: '1', required: true },
        voice_note: { value: '1', required: true },
        id_number: { value: '', required: true }
      }
    }
  }

  nextStep = () => {
    this.setState({
      step: this.state.step + 1
    })
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm,
    });
  }

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };

  submit = () => {
    this.props.history.push('/resultController/ResultPage', {
      type: 'success',
      pageName: '漫遊與門號服務',
      title: '設定完成',
      description: `您的語音信箱設定已開啟，將於 <span class='is-text-error'>10分鐘</span> 後生效，謝謝`,
      actions: [
        {
          text: '返回語音信箱設定',
          link: '/roamingConfigController/VoiceBox',
          btnStyle: 'primary'
        },
        {
          text: '返回個人專區',
          link: '#',
          btnStyle: 'secondary'
        }
      ]
    })
  }

  render() {
    const { step, form } = this.state;
    return (
      <main>
        <NavAnchor
          pageTitle="漫遊與門號服務"
          button={{ text: '回個人專區', link: '/' }}
        />
        <FormBanner
          title="語音信箱設定"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="form-section">
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
            <div className="fui-container">
              <h4 className='is-text-darkgray50'>語音信箱為自動開啟的基本服務，您若不想使用此服務，可以在此關閉或重新開啟語音信箱功能。</h4>
              <div className="fui-segments mb-7">
                <div className="fui-segment">
                  <RadioGroup
                    className='mb-0'
                    label='目前設定'
                    name='current_setting'
                    options={[
                      { label: '開啟', value: '1' },
                      { label: '關閉', value: '0' },
                    ]}
                    default={form.current_setting.value}
                    onChange={this.onChange}
                  />
                </div>
                {
                  step >= 2 && form.current_setting.value === '1' ? (
                    <div className="fui-segment">
                      <RadioGroup
                        label='未接通狀態宣告'
                        name='unanwsered'
                        options={[
                          { label: '開啟', value: '1' },
                          { label: '關閉', value: '0' },
                        ]}
                        default={form.unanwsered.value}
                        onChange={this.onChange}
                      />
                      <RadioGroup
                        label='標準歡迎詞版本'
                        name='greeting'
                        options={[
                          { label: '開啟', value: '1' },
                          { label: '關閉', value: '0' },
                        ]}
                        default={form.greeting.value}
                        onChange={this.onChange}
                      />
                      <RadioGroup
                        className='mb-0'
                        label='來電留言語音提醒'
                        name='voice_note'
                        options={[
                          { label: '開啟', value: '1' },
                          { label: '關閉', value: '0' },
                        ]}
                        default={form.voice_note.value}
                        onChange={this.onChange}
                      />
                    </div>
                  ) : null
                }
              </div>
              {
                step === 3 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                      <LabelInput
                        className="mb-2 hide-required"
                        name='id_number'
                        required={this.state.form.id_number.required}
                        label='證照號碼'
                        validations={{
                          idNumberValid: Validation.idNumberValid
                        }}
                        validationErrors={{
                          isDefaultRequiredValue: '請輸入證照號碼'
                        }}
                        placeholder='請輸入證照號碼'
                        value={this.state.form.id_number.value}
                        onChange={this.onChange}
                      />
                      <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                        身分說明：<br />
                          個人戶：身分證字號<br />
                          公司戶：統一編號或稅籍編號<br />
                          外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                      </p>
                    </Grid>
                  </Grid>
                ) : null
              }
              {
                step === 3 ? (
                  <div className="page-action"><Button btnStyle='primary' size='large' className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} onClick={this.submit}>確認</Button></div>
                ) : (
                    <div className="page-action"><Button btnStyle='primary' size='large' onClick={this.nextStep}>下一步</Button></div>
                  )
              }
            </div>
          </Formsy>
        </section>

        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            <ol>
              <li>若您關閉語音信箱再重新申請，則原信箱中之留言，將會全部被刪除。</li>
              <li>如您有安裝指定轉接功能，語音信箱將會自動失效；待您取消指定轉接功能，語音信箱將會自動恢復。</li>
            </ol>
          `}
        />
      </main>
    )
  }
}

export default VoiceBox;