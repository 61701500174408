import React from 'react';
import Link from '../Link';
import PropTypes from 'prop-types';

const FooterAd = (props) => {
  const [isMd, setIsMd] = React.useState(typeof window !== 'undefined' && window.innerWidth >= 768);

  const handleClick = (e) => {
    //_alTrackClick
    if (props.onClick) props.onClick(e);
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onresize = () => {
        setIsMd(window.innerWidth >= 768);
      };
    }
  });

  return (
    <div
      className='fui-footer-promo'
      style={{
        backgroundImage: `url(${isMd ? props.image.md : props.image.sm})`,
      }}>
      <Link to={props.action.link} target={props.action.target} onClick={handleClick}
        data-fet-key={`MC_Home_網路門市限定_${props.action.text}`}>
        <div className='promotion-container'>
          <div className='content'>
            <h4 className='fui-section-promo-title' dangerouslySetInnerHTML={{ __html: props.title }}></h4>
          </div>
          <div className='action'>
            <div className='promotion-button'>{props.action.text}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

FooterAd.propTypes = {
  image: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  title: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    target: PropTypes.string,
    btnStyle: PropTypes.string, // only 'primary' or 'secondary
  }),
};

export default FooterAd;
