import React from 'react';
import PropTypes from 'prop-types';

class ArticleAuthorPanel extends React.Component {
    render() {
        return (
            <div className={`fui-panel article-author-panel article-panel ${this.props.className ? this.props.className : ''}`}>
                <div className='fui-container fui-container-article'>
                    <div class='img'>
                        <img src={this.props.img} alt={this.props.alt} />
                    </div>
                    <div class='wording'>
                        <h6 className='mt-0 mb-2'>{this.props.title}</h6>
                        <div style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.props.desc }}></div>
                    </div>
                </div>
            </div>
        )
    }
}

ArticleAuthorPanel.propTypes = {
    img: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    className: PropTypes.string,
}

export default ArticleAuthorPanel;