import React from 'react';
import Button from '../Button';
import VideoModal from '../VideoModal';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

class SplashPromotion extends React.Component {
  constructor(props) {
    super(props);
    this.splash = React.createRef();
    this.state = {
      isMobile: window.innerWidth <= 768 || (window.innerWidth <= 1024 && window.innerWidth < window.innerHeight),
      close: false,
      isPlay: true,
      currentHeight: 0,
      defaultHeight: 0,
      lastScroll: 0,
      modalOpen: false,
      alterVideo: null,
      currentVideo: null,
      delay: this.props.delay,
    };

    this.storageKey = 'SplashPromotion';
    this.$html = document.getElementsByTagName('html')[0];
  }

  componentDidMount() {
    this.$html.classList.add('promotion-open');
    document.getElementsByClassName('fui-banner')[0].classList.remove('fade-in');

    window.addEventListener('resize', (e) => {
      this.setState({
        isMobile: window.innerWidth <= 768 || (window.innerWidth <= 1024 && window.innerWidth < window.innerHeight),
      });
      this.countHeight();
    });
    this.saveOpenState();
    if (this.state.delay > 0) {
      setTimeout(() => {
        this.collapsePromotion();
      }, this.state.delay * 1000);
    }
  }

  countHeight = (e) => {
    const $header = document.getElementsByTagName('header')[0];
    let $main = document.getElementsByTagName('main')[1] || document.getElementsByTagName('main')[0];
    let ht = window.innerHeight - $header.clientHeight;
    // $main.style.marginTop = `${ht}px`;

    if (this.state.close) {
      $main.style.marginTop = `${window.innerWidth <= 960 ? 50 : 100}px`;
    } else {
      this.setState({
        currentHeight: this.splash.current.clientHeight,
        defaultHeight: ht,
      });
      // debugger
      this.splash.current.style.height = `${ht}px`;
      this.splash.current.style.top = `${$header.clientHeight}px`;
    }
  };

  scrollToClose = (e) => {
    if (this.state.close) return;

    this.setState({ close: true, isPlay: false });
    this.collapsePromotion();

    if (window.isIE) {
      let elem = window || document.documentElement;
      elem.scroll(0, 0);
    } else {
      window.scrollTo({ left: 0, top: 0 });
    }
  };

  openVideoModal = (data) => {
    this.setState({
      modalOpen: true,
      alterVideo: data.alterVideo,
      currentVideo: data.videoUrl,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      alterVideo: '',
      currentVideo: '',
    });
  };

  saveOpenState = () => {
    const splashPromotions = !!Cookies.get(this.storageKey) ? JSON.parse(Cookies.get(this.storageKey)) : {};
    Cookies.set(
      this.storageKey,
      {
        open: parseInt(splashPromotions.open || 0) + 1,
        click: splashPromotions.click || 0,
      },
      { expires: 7 }
    );
  };

  saveClickState = (event, actionType) => {
    const splashPromotions = !!Cookies.get(this.storageKey) ? JSON.parse(Cookies.get(this.storageKey)) : {};
    Cookies.set(
      this.storageKey,
      {
        open: splashPromotions.open || 0,
        click: parseInt(splashPromotions.click || 0) + 1,
      },
      { expires: 7 }
    );

    //_alTrackClick
    this.props.onClick && this.props.onClick(event, actionType);
  };

  collapsePromotion = () => {
    this.setState({
      isPlay: false,
      close: true,
    });
    let $main = document.getElementsByTagName('main')[1] || document.getElementsByTagName('main')[0];
    let $header = document.getElementsByTagName('header')[0];

    this.splash.current.classList.add('is-collapse');
    this.splash.current.style.height = '0px';

    setTimeout(() => {
      this.$html.classList.remove('promotion-open');
      this.setState({
        modalOpen: false,
        alterVideo: '',
        currentVideo: '',
      });
    }, 1000);
    this.countHeight();

    $main.style.marginTop = `${window.innerWidth <= 960 ? 50 : 100}px`;
    document.getElementsByClassName('fui-cbu')[0].classList.add('fade-in');
  };

  render() {
    return (
      <section className='fui-splash-promotion' ref={this.splash} id='Splash_AD'>
        {this.props.image ? (
          <div
            className='image'
            data-bg={this.state.isMobile ? this.props.image.sm : this.props.image.md}
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + (this.state.isMobile ? this.props.image.sm : this.props.image.md)
              })`,
            }}></div>
        ) : null}
        {this.props.video && this.state.isPlay ? (
          <div
            role='button'
            className='video'
            data-aa-tracking-id={`影片_${this.props.title}`}
            onClick={(event) => {
              // [AA Tracking]
              if (window && typeof window.ctrf === 'function') window.ctrf(event || {}, `影片_${this.props.title}`);
              this.saveClickState(event, 1);
              this.openVideoModal(this.props.video);
            }}
            data-bg={this.state.isMobile ? this.props.video.cover.sm : this.props.video.cover.md}
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + (this.state.isMobile ? this.props.video.cover.sm : this.props.video.cover.md)
              })`,
            }}></div>
        ) : null}
        <div className='caption'>
          <div className='fui-container'>
            <div className='content'>
              <h3 className='my-0'>{this.props.title}</h3>
            </div>
            <div className='action'>
              <Button
                btnStyle='primary'
                target={this.props.action.target}
                link={this.props.action.link}
                data-aa-tracking-id={`${this.props.title}_${this.props.action.text}`}
                onClick={(event) => {
                  this.saveClickState(event, 1);
                }}>
                {this.props.action.text}
              </Button>
              <Button
                btnStyle='secondary'
                data-aa-tracking-id={`Tab_略過_${this.props.title}`}
                onClick={(event) => {
                  this.saveClickState(event, 0);
                  this.collapsePromotion();
                  // [AA Tracking]
                  if (window && typeof window.ctrf === 'function')
                    window.ctrf(event || {}, `Tab_略過_${this.props.title}`);
                }}>
                略過
              </Button>
            </div>
          </div>
        </div>

        <VideoModal
          open={this.state.modalOpen}
          alterVideo={this.state.alterVideo}
          videoUrl={this.state.currentVideo}
          onClose={this.closeModal}
        />
      </section>
    );
  }
}

SplashPromotion.propTypes = {
  image: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  video: PropTypes.shape({
    alterVideo: PropTypes.string,
    videoUrl: PropTypes.string,
    cover: PropTypes.shape({
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
};

export default SplashPromotion;
