import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

class Panel extends React.Component {
  render() {
    return (
      <div className="fui-container">
        <div
          className={`fui-panel-container ${this.props.className ? this.props.className : ''} ${this.props.initial ? 'no-shadow no-bg' : ''}`}
          style={this.props.bgColor ? ({ backgroundColor: this.props.bgColor }) : null}
        >
          <h4 className="mt-md-4 mb-2 is-font-bold" dangerouslySetInnerHTML={{
            __html: this.props.title || '',
          }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <div dangerouslySetInnerHTML={{
                __html: this.props.content || '',
              }} />
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
}

export default Panel;