import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CardProductSingle from './CardProductSingle';
import CardProductMultiple from './CardProductMultiple';
class CardProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div>
                {this.props.list.length === 1 ? <CardProductSingle
                    title={this.props.title}
                    list={this.props.list}
                    onChange={(e) => this.props.onChange(e)}
                /> :
                    <CardProductMultiple
                        title={this.props.title}
                        list={this.props.list}
                        onChange={(e) => this.props.onChange(e)}
                    />}
            </div>
        );
    }
}
CardProduct.propTypes = {
    title: PropTypes.string,
    onChange: PropTypes.func,
    list: PropTypes.array,
}
export default CardProduct;