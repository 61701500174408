import React from 'react';
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types';

class PanelLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            minHeight: 225,
            collapseHeight: 0,
            isMobile: typeof window !== 'undefined' && window.innerWidth < 960,
        }
        this.contentBody = React.createRef();
        this.toggleCollapse = this.toggleCollapse.bind(this)
        this.setContentHeight = this.setContentHeight.bind(this)
    }

    componentDidMount() {
        this.setContentHeight()
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', e => {
                this.setState({
                    isMobile: window.innerWidth < 960
                })
            })
        }
    }

    setContentHeight() {
        this.setState({
            collapseHeight: this.contentBody.current.children[0].clientHeight
        })
    }

    toggleCollapse() {
        this.setState({
            open: !this.state.open
        })
        setTimeout(() => {
            this.setContentHeight()
        }, 50)
    }
    getOpen() {
        let open = this.state.open
        if (!open) {
            if (typeof window !== 'undefined' && !window.$isEn) {
                return '看更多'
            } else {
                return 'More'
            }
        } else {
            if (typeof window !== 'undefined' && !window.$isEn) {
                return '收合'
            } else {
                return 'Less'
            }
        }
    }

    render() {
        return (
            <Grid className="panel-layout" container spacing={5} direction={this.props.contentPosition && this.props.contentPosition === 'right' ? 'row-reverse' : 'row'}>
                <Grid item md={6} style={{ order: this.state.isMobile ? 2 : 0 }}>
                    {
                        this.props.title ? (
                            <h3 className="fui-panel-title">{this.props.title}</h3>
                        ) : ''
                    }
                    <div className={`fui-collapse-body ${this.state.open ? 'is-open' : ''}`}
                        ref={this.contentBody}
                        style={{ height: this.state.open ? this.state.collapseHeight : this.state.minHeight }}>
                        <article dangerouslySetInnerHTML={{ __html: this.props.content }}>
                        </article>
                        {this.props.children}
                    </div>
                    {
                        this.state.collapseHeight > this.state.minHeight ? (
                            <div className="fui-collapse-action">
                                <button className="fui-button" onClick={this.toggleCollapse}>
                                    <span className="text">
                                        {this.getOpen()}
                                    </span>
                                    <i className={`${this.state.open ? 'icon-minus' : 'icon-plus'}`}></i>
                                </button>
                            </div>
                        ) : null
                    }
                </Grid>
                <Grid item md={6}>
                    <img src={process.env.PUBLIC_URL + this.props.image} srcSet={process.env.PUBLIC_URL + (this.props.retinaImage || this.props.image)} alt={this.props.alt ? this.props.alt : this.props.title} width={this.props.imageWidth || 'auto'} />
                </Grid>
            </Grid>
        )
    }
}

PanelLayout.propTypes = {
    contentPosition: PropTypes.string, // left | right
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    retinaImage: PropTypes.string,
    image: PropTypes.string.isRequired,
    imageWidth: PropTypes.string,
}

export default PanelLayout;