import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

import TabPane from '../../components/tab/TabPane';
import NavContentTab2 from '../../components/partials/NavContentTab2';

import BannerPromotionBasic from '../../components/partials/banner/BannerPromotionBasic';
import CardRoamingMulti from '../../components/partials/CardRoamingMulti';
import ESectionFeature from '../../components/partials/ESectionFeature';
import SectionAd3 from '../../components/partials/SectionAd3';
import FooterAd from '../../components/ad/FooterAd';
import SectionFaqTab from '../../components/partials/collapse/SectionFaqTab';
import AppPromotion from '../../components/partials/AppPromotion';

import * as Mock from '../../mock/OriginNumberRoaming';

class Prepaid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bgImg: {
        md: '/resources/cbu/roaming/cbu-roaminghome1-banner-1920x470.jpg',
        sm: '/resources/cbu/roaming/cbu-roaminghome1-banner-1920x470.jpg',
      },
      keyword: '',
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        this.setState({
          isMobile: window.innerWidth < 768,
        });
      });
  }

  changeMainTab = value => {
    this.setState({
      currentMainTab: value,
    });
  };

  render() {
    return (
      <main className="">
        <NavAnchor
          pageTitle='<a href="#">原號漫遊</a>'
          button={{
            link: '#',
            text: '聯絡我們',
          }}
        // tabs={[
        //   { label: '主題活動', link: '#anchor1' },
        //   { label: '達人帶你玩', link: '#anchor2' },
        //   { label: '漫遊優惠', link: '#anchor3' },
        //   { label: '其他漫遊', link: '#anchor4' },
        // ]}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '原號漫遊', link: '/' }
          ]}
          color='black'
        />


        <BannerPromotionBasic
          className="is-origin-number-roaming-prepaid-banner"
          bgColor="#fff"
          slides={
            [
              {
                image: {
                  md: '/resources/cbu/roaming/cbu-roaminghk-banner-1920x470.jpg',
                  sm: '/resources/cbu/roaming/cbu-roaminghk-750x720.jpg'
                },
                tag: "",
                title: '你的專屬優惠',
                description: '漫遊上網吃到飽每天只要 $299',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '了解更多',
                    link: '#'
                  },

                ]
              },
              {
                image: {
                  md: '/resources/cbu/roaming/cbu-roaminghk-banner-1920x470.jpg',
                  sm: '/resources/cbu/roaming/cbu-roaminghk-750x720.jpg'
                },
                tag: "",
                title: '你的專屬優惠',
                description: '漫遊上網吃到飽每天只要 $299',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '了解更多',
                    link: '#'
                  },

                ]
              },
            ]
          }
        />


        <NavContentTab2
          tabs={{
            name: 'origin-number-roaming-tab',
            icon: false,
            title: true,
            list: [
              {
                name: 'monthly',
                title: '月租型用戶',
                label: '',
                link: '/originNumberRoaming/Index',
                target: '_self',
              },
              {
                name: 'prepaid',
                title: '預付卡用戶',
                label: '',
                link: '/originNumberRoaming/Prepaid',
                target: '_self',
              },
            ]
          }} onChange={e => this.changeMainTab(e)} />

        <section className="origin-number-roaming prepaid with-bg pb-0 pt-7">
          <div className='tab-content'>
            {
              <TabPane active={true}>

                <CardRoamingMulti {...Mock.roamingCardMulti} />


                <ESectionFeature {...Mock.sectionFeature} />


                <section id="" className="apply-steps" style={{ backgroundImage: `url(${this.state.isMobile ? '/resources/cbu/roaming/cbu_roaming_prepay_750x492.jpg' : '/resources/cbu/roaming/cbu_roaming_pre_1920x470.jpg'})` }}>
                  <div className="fui-container">
                    <h2>預付漫遊，簡單又貼心</h2>
                    <div className="subtitle">遠傳預付卡用戶獨享的超優惠服務！輕鬆申請，開心上網！</div>
                    <ul>
                      <li>
                        <div className="circle">1</div>
                        <div className="body">至遠傳官網、線上客服（#123）或遠傳實體門市申購</div>
                      </li>
                      <li>
                        <div className="circle">2</div>
                        <div className="body">抵達國外當地後，手機開啟數據漫遊即可開始上網</div>
                      </li>
                      <li>
                        <div className="circle">3</div>
                        <div className="body">上網流量達 70% 及 95% 時，皆有貼心簡訊通知</div>
                      </li>
                    </ul>
                    <p className="note">還沒有遠傳預付卡？歡迎攜帶個人證件至附近的 <a href='#' target="_blank" className="is-text-black50 ">遠傳門市</a> 辦理！</p>
                  </div>
                </section>


                <SectionAd3
                  patternColor='green'
                  title={`不知道原號漫遊怎麼用？別擔心，漫遊小叮嚀讓你一次就上手！`}
                  action={[
                    {
                      text: '了解更多',
                      line: '#',
                    },
                  ]}
                />


                <SectionFaqTab {...Mock.faqTab} />

                <FooterAd {...Mock.eventPromoData2} />

              </TabPane>
            }
          </div>
        </section>
      </main>
    );
  }
}

export default Prepaid;
