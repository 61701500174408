import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import BannerPromotionBasic from '../../components/partials/banner/BannerPromotionBasic';
import Tab from '../tab/Tab';
import HasMoreTab from '../tab/HasMoreTab';
import TabPane from '../tab/TabPane';
import PropTypes from 'prop-types';
import Link from '../../components/Link';
class FeatureBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      tabs: [],
      dropdown: [],
      link: [
        {
          title: '查詢全虹門市',
          link: '#',
          target: '_blank',
        },
        {
          title: '查詢全家便利商店門市',
          link: '#',
        },
        {
          title: '查詢萊爾富便利商店門市',
          link: '#',
        },
        {
          title: '查詢全虹門市',
          link: '#',
          target: '_blank',
        },
        {
          title: '查詢全家便利商店門市',
          link: '#',
        },
        {
          title: '查詢萊爾富便利商店門市',
          link: '#',
        },
      ],
    };
  }
  tabChange = (index) => {
    this.setState({
      currentTab: index,
    });
  };
  HasMoreTabsChange = (val) => {
    this.setState({
      currentTab: val.index,
    });
  };
  componentDidMount() {
    let tabs = this.props.tabs;
    let newtabs = [];
    let dropdown = [];
    if (tabs.length > 5) {
      for (let i = 0; i < 5; i++) {
        newtabs.push(tabs[i]);
      }
      for (let j = 5; j < tabs.length; j++) {
        dropdown.push(tabs[j]);
      }
      this.setState({
        tabs: newtabs,
        dropdown: dropdown,
      });
    } else {
      this.setState({
        tabs: this.props.tabs,
      });
    }
  }
  render() {
    return (
      <section className='feature-banner'>
        <div className='fui-container'>
          {this.props.slides.length > 0 ? (
            <BannerPromotionBasic
              className='is-origin-number-roaming-localcard-banner mb-2'
              bgColor='#fff'
              activeSlide={this.state.currentTab}
              dots={false}
              draggable={false}
              target={this.props.target}
              slides={this.props.slides}
            />
          ) : null}
          {!!!this.state.dropdown ? (
            <Tab name='fui-faq-collapse-tab' list={this.props.tabs} onChange={this.tabChange} />
          ) : (
            <HasMoreTab
              tabs={this.state.tabs}
              dropdown={this.state.dropdown}
              onChange={this.HasMoreTabsChange}
              defaultSelected={this.state.tabs[0]}
            />
          )}
          {this.props.list.map((list, i) => (
            <TabPane active={this.state.currentTab === i} key={`collapseList-${i}`}>
              <Grid container spacing={2} className=''>
                {list.map((item, idx) => (
                  <Grid item xs={12} sm={12} md={(list.length & 1) === 0 ? 6 : 4} key={item.title + idx}>
                    <Link
                      target={this.props.target || item.target}
                      to={item.link}
                      className='paper'
                      data-fet-key={item.fetKey}>
                      {item.title}
                      <img src='/resources/cbu/prepaid/images/external.svg' alt='external' />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </TabPane>
          ))}
        </div>
      </section>
    );
  }
}

export default FeatureBanner;
