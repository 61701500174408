import React, { Component } from 'react'
import Button from '../../components/Button';
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import PanelTab from '../../components/panelContent/PanelTab';
import TabPane from '../../components/tab/TabPane';
import PanelFigure from '../../components/panelContent/ArticlePanelFigure';
import { Grid } from '@material-ui/core';
class EBillBenefit extends Component {
  constructor(props) {
    super(props);
    this.panel = React.createRef();
    this.state = {
      currentTab: 0,

    }
  }
  changeTab = (val) => {
    this.setState({
      currentTab: val
    })
  }
  render() {
    return (
      <main className="prepaid">
        <Header />
        <NavAnchor
          pageTitle='<a href="#">帳單與用量</a>'
          button={{
            link: '#',
            text: '回個人專區',
          }}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '電子帳單五大好處', link: '/' }
          ]}
          color='white'
        />
        <SectionBanner2
          position='right'
          className="is-360"
          image={{
            md: '/resources/cbu/otherService/cbu-eservice-ebill-benefit-1920x360.jpg',
            sm: '/resources/cbu/otherService/cbu-eservice-ebill-benefit-750x400.jpg',
          }}
          title='<div class="mb-2 is-text-white">電子帳單五大好處</div>'
          action={{
            text: '電子帳單申請',
            link: '#',
          }}
        />
        <div className="ribbon-bg pt-md-7 pt-4">
          <section className='p-0'>
            <div className='tab-content'>
              {
                <TabPane active={true}>
                  <div className='fui-container'>
                    <h4 className="is-text-darkgray50 mt-0 mb-7">
                      遠傳電子帳單讓您繳費安全又便利，從五大好處，更了解電子帳單的優點，立即體驗用越久享受越多的優質帳單新體驗！
                    </h4>
                    <PanelTab
                      ref={this.panel}
                      tabs={{
                        name: 'panel-tab',
                        list: [
                          { name: 'panel-tab-1', label: '繳費方便' },
                          { name: 'panel-tab-2', label: '樂活生活' },
                          { name: 'panel-tab-3', label: '費用清楚' },
                          { name: 'panel-tab-4', label: '即時掌握' },
                          { name: 'panel-tab-5', label: '申請好禮' },
                        ],
                      }}>
                      <div className="paper mt-1 pt-md-7 pt-3">
                        <h5 className=" mt-0 mb-3 is-text-black50 is-text-medium">六大繳費管道</h5>
                        <ul className="green-dot-list is-text-black100 mt-0">
                          <li className="">便利商店手機條碼繳費</li>
                          <li>門市/手機條碼繳費</li>
                          <li>行動客服APP線上繳費</li>
                          <li>線上銀行帳號繳費</li>
                          <li>信用卡繳費</li>
                          <li>自動扣繳</li>
                        </ul>
                        <h4 className="is-text-black100 mb-3">e化帳單的手機條碼 輕鬆繳費</h4>
                        <ul className="step-list mt-md-3 mt-0">
                          <li>
                            <div className="circle">1</div>
                            <h6>點選簡訊通知中的網址</h6>
                          </li>
                          <li>
                            <div className="circle">2</div>
                            <h6>持著手機網頁的繳費條碼</h6>
                          </li>
                          <li className="mb-4">
                            <div className="circle">3</div>
                            <h6>前往直營門市和超商進行繳費</h6>
                          </li>
                        </ul>
                      </div>
                      <div className="paper mt-1 pt-md-7 pt-3">
                        <h5 className=" mt-0 mb-3 is-text-black50 is-text-medium">減少使用紙張，環保、減碳、愛地球</h5>
                        <p className="is-text-black100 mb-4">小小動作即可改善環境，愛地球可以很簡單</p>
                        <div className="main-img mb-3">
                          <img src="/resources/cbu/img/img-eservice-bill-lohas@2x.jpg" alt="img-eservice-bill-lohas" />
                        </div>
                      </div>
                      <div className="paper mt-1 pt-md-7 pt-3">
                        <h5 className=" mt-0 mb-3 is-text-black50 is-text-medium">費用一目了然，帳務清楚又完整</h5>
                        <p className="is-text-black100 mb-4">提供重點是簡單的帳單資訊，一秒就能看懂帳單。</p>
                        <PanelFigure
                          inline={true}
                          image='/resources/cbu/img/img-ebill-printscreen@2x.jpg'
                          caption='隨時隨地都能在手機瀏覽帳單資訊'
                        />
                        <PanelFigure
                          inline={true}
                          image='/resources/cbu/img/img-paperbill-printscreen@2x.jpg'
                          caption='如果還是想要紙本帳單？別擔心！仍然可以選擇每月以 Email 加送紙本帳單電子檔給您'
                        />
                      </div>
                      <div className="paper mt-1 pt-md-7 pt-3">
                        <h5 className="mb-md-7  mt-0 mb-3 is-text-black50 is-text-medium">準時收到帳單，隨時查看不怕丟</h5>
                        <ul className='promotion-pattern'>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <li className='mb-md-0 mb-4'>
                                <img src={process.env.PUBLIC_URL + "/resources/cbu/img/ontime-bill@2x.png"} alt="feature" />
                                <div className="info">
                                  <h4>準時收到帳單</h4>
                                  <ul>
                                    <li>
                                      <i className="icon-check is-text-accent" />
                                    每月準時寄發
                                  </li>
                                    <li>
                                      <i className="icon-check is-text-accent" />
                                    更換地址不需擔心
                                  </li>
                                  </ul>
                                </div>
                              </li>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <li>
                                <img src={process.env.PUBLIC_URL + "/resources/cbu/img/double-notification@2x.png"} alt="feature" />
                                <div className="info">
                                  <h4>簡訊+Email雙重通知</h4>
                                  <ul>
                                    <li>
                                      <i className="icon-check is-text-accent" />
                                      雙重提醒不漏繳
                                    </li>
                                    <li>
                                      <i className="icon-check is-text-accent" />
                                      隨時查看帳單
                                    </li>
                                    <li>
                                      <i className="icon-check is-text-accent" />
                                      保存容易、方便
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </Grid>
                          </Grid>
                        </ul>
                      </div>
                      <div className="paper mt-1 pt-md-7 pt-3">
                        <h5 className="mt-0 mb-4 is-text-black50 is-text-medium">首辦電子帳單，享精美好禮</h5>
                        <div className="inframe-banner">
                          <div className="left">
                            <img src="/resources/cbu/img/img-panel-ad-apply-gifts@3x.jpg" alt="e-bill" />
                          </div>
                          <div className="right">
                            <h3 className='is-text-white'>遠傳電子帳單</h3>
                            <p className="body is-text-white">
                              e起在家賺購物金，抽$1,000 F幣
                            </p>
                            <Button btnStyle='secondary' size='medium' className="is-reverse is-text-accent" link="#">了解更多</Button>
                          </div>
                        </div>
                      </div>
                    </PanelTab>
                  </div>
                </TabPane>
              }
            </div>
          </section>
        </div>
      </main>
    );
  }
}
export default EBillBenefit;