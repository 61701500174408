import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import AdaptHeightBanner from '../../components/partials/banner/AdaptHeightBanner';
import CollapsePaper from '../../components/collapse/CollapsePaper';
import Pagination from '../../components/paginate/Pagination';
import Breadcrumb from '../../components/Breadcrumb';
import { Grid } from '@material-ui/core';
import NavAnchor from '../../components/partials/NavAnchor';
let data = [
  {
    date: '2020/07/01',
    title: `<h6 class='m-0'>3G/4G站台維護公告</h6>`,
    content: `<h6 class='is-text-regular m-0'>為使服務更完善，高雄市前鎮區、旗津區部份地區3G/4G站台預計於2020/7/8 02:00~07:00進行系統升級維護，維護期間暫時無法使用3G/4G網路。(包括語音與GPRS服務)，造成不便敬請見諒，謝謝。 </h6>`
  },
  {
    date: '2020/07/01',
    title: `<h6 class='m-0'>手機預付型直撥017 109年1月至12月優惠活動</h6>`,
    content: `<h6 class='is-text-regular mt-0 mb-3'>自4/15起受理因疫情影響、有繳費困難的客戶，申請電信帳單延期繳費，申請條件如下：</h6>
    <ol class="order-list">
      <li><div class="decimal">1</div>月租型有效門號，申請當下無逾期帳款，且近半年繳費記錄良好者</li>
      <li><div class="decimal">2</div>因受疫情影響並取得中央部會(如經濟部、財政部、勞動部..)核准之「疫情紓困證明文件」者</li>
      <li><div class="decimal">3</div>同一證號下有多個門號者以「一張帳單」為限</li>
      <li><div class="decimal">4</div>基於營運考量，相關申請資格及緩費金額，本公司具有審核及准駁權利，若超過本公司可承受之範圍即停止受理申請</li>
      <li><div class="decimal">5</div>申請管道：本公司各直營/加盟門市</li>
    </ol>`
  },
  {
    date: '2020/07/01',
    title: `<h6 class='m-0'>手機預付型直撥017 109年1月至12月優惠活動</h6>`,
    content: `<h6 class='is-text-regular m-0'>新世紀資通股份有限公司<br/>
    手機預付型直撥017 109年01月至109年12月份優惠活動 (國際網路優惠方案)</h6>
    <h6 class='is-text-regular mb-4 mt-4'>優惠期間：</h6>
    <ul class="green-dot-list mb-3">
    <li>自中華民國109年01月01日至中華民國109年12月31日止。</li>
    <li>馬來西亞/菲律賓∕泰國∕印尼/越南優惠時段為週一至週日00:00~24:00</li>
    </ul>
    <h6 class='is-text-regular mb-4 mt-4'>優惠內容：</h6>
    <ul class="green-dot-list mb-3">
      <li>上述優惠期間之ㄧ般時段促銷費率為：</li>
    </ul>
    <table class="text-wrap bill-table table-fixed m-0 table-gray-head"">
      <tbody>
        <tr>
        <th class="">國家</th>
          <th class="">每分鐘/元</th>
        </tr>
        <tr>
          <td class="">馬來西亞</td>
          <td class="">5元</td>
        </tr>
        <tr>
          <td class="">菲律賓</td>
          <td class="">5.2元</td>
        </tr>
        <tr>
          <td class="">泰國</td>
          <td class="">3.5元</td>
        </tr>
        <tr>
          <td class="">印尼</td>
          <td class="">3.5元</td>
        </tr>
        <tr>
          <td class="">越南</td>
          <td class="">5.5元</td>
        </tr>
      </tbody>
    </table>
    <p class='is-text-darkgray50 is-text-regular mb-4 mt-2'>週一至週五08:00至23:00、週六08:00至12:00</p>
    <ul class="green-dot-list mb-3">
      <li>上述優惠期間之減價時段促銷費率為</li>
    </ul>
    <table class="text-wrap bill-table table-fixed m-0 table-gray-head">
      <tbody>
        <tr>
        <th class="">國家</th>
          <th class="">每分鐘/元</th>
        </tr>
        <tr>
          <td class="">馬來西亞</td>
          <td class="">5元</td>
        </tr>
        <tr>
          <td class="">菲律賓</td>
          <td class="">5.2元</td>
        </tr>
        <tr>
          <td class="">泰國</td>
          <td class="">3.5元</td>
        </tr>
        <tr>
          <td class="">印尼</td>
          <td class="">3.5元</td>
        </tr>
        <tr>
          <td class="">越南</td>
          <td class="">5.5元</td>
        </tr>
      </tbody>
    </table>
    <p class='is-text-darkgray50 is-text-regular mb-4 mt-2'>週一至週五23:00至翌日08:00；週六12:00至週一08:00；含週日及國定假日全天</h6>
    <ul class="green-dot-list mb-3">
      <li>促銷異動對照表</li>
    </ul>
    <div class="compareTable content-16 clearfix stick-to-right">
      <div class="tableLeft">
          <div class="tableHead">
              <table>
                  <tbody>
                      <tr style="height: 52px;">
                          <th>項目</th>
                      </tr>
                      <tr style="height: 168px;">
                          <th>優惠時段</th>
                      </tr>
                      <tr style="height: 52px;">
                          <th>馬來西亞</th>
                      </tr>
                      <tr style="height: 52px;">
                          <th>菲律賓</th>
                      </tr>
                      <tr style="height: 52px;">
                          <th>泰國</th>
                      </tr>
                      <tr style="height: 52px;">
                          <th>印尼</th>
                      </tr>
                      <tr style="height: 52px;">
                          <th>越南</th>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
      <div class="tableRight">
          <div class="tableBody">
              <div class="spaceBox">
                  <table class="compareList table-gray-head text-wrap bill-table table-fixed">
                      <tbody>
                          <tr style="height: 52px;">
                          <td colspan="2">原牌價</td>
                          <td style="min-width: 288px">本優惠方案</td>
                          </tr>
                          <tr style="height: 60px;">
                          <td style="min-width: 188px">一般時段</td>
                          <td style="min-width: 188px">減價時段</td>
                          <td>109年01月01日至109年12月31日止</td>
                          </tr>
                          <tr style="height: 108px;">
                          <td>週一至週五08:00至23:00、週六08:00至12:00</td>
                          <td>週一至週五23:00至翌日08:00；週六12:00至週一08:00；含週日及國定假日全天</td>
                          <td>週一至週日00:00~24:00</td>
                          </tr>
                          <tr style="height: 52px;">
                          <td>5元</td>
                          <td>5元</td>
                          <td>5元</td>
                          </tr>
                          <tr style="height: 52px;">
                          <td>5.2元</td>
                          <td>5.2元</td>
                          <td>5.2元</td>
                          </tr>
                          <tr style="height: 52px;">
                          <td>3.5元</td>
                          <td>3.5元</td>
                          <td>3.5元</td>
                          </tr>
                          <tr style="height: 52px;">
                          <td>3.5元</td>
                          <td>3.5元</td>
                          <td>3.5元</td>
                          </tr>
                          <tr style="height: 52px;">
                          <td>5.5元</td>
                          <td>5.5元</td>
                          <td>5.5元</td>
                          </tr>
                      </tbody>
                  </table> 
              </div>
          </div>
      </div>
  </div>
    <h6 class='is-text-regular mb-4 mt-4'>限制條件：</h6>
    <ol class="order-list">
      <li><div class="decimal">1</div>本項優惠不適用於本公司市內網路用戶與撥號選接用戶。</li>
      <li><div class="decimal">2</div>本項優惠不得與其他優惠活動合併使用。</li>
      <li><div class="decimal">3</div>本項優惠費率適用於行動電話預付型之用戶並由與本公司簽訂代收代付合約之行動業者代收本項優惠資費。</li>
      <li><div class="decimal">4</div>本項優惠費率非與本公司簽訂代收代付合約之行動業者預付型用戶即可依本公司長途及國際網路業務規章及契約規定辦理註冊登記之後，由本公司直接出帳收費。</li>
    </ol>`
  },
  {
    date: '2020/07/01',
    title: `<h6 class='m-0'>108年度行動寬頻業務服務品質自我評核</h6>`,
    content: `<img class="w-100" src="/resources/cbu/img/img-from-client@2x.jpg" alt="demo img"/>`
  },
  {
    date: '2020/07/01',
    title: `<h6 class='m-0'>國家通訊傳播委員會 通訊申訴監理報告</h6>`,
    content: `
    <div class="d-block pt-1">
      <a href="#" class='fui-button is-external-link hover-underline-link-accent is-text pr-0 mb-0'>
        <span>通訊申訴監理報告</span>
        <img src="/resources/cbu/prepaid/images/external.svg" alt="external link" />
      </a>
    </div>`
  },
  {
    date: '2020/07/01',
    title: `<h6 class='m-0'>國家通訊傳播委員會行動通信業務客戶統計數</h6>`,
    content: `
    <div class="d-block pt-1">
      <a href="#" class='fui-button is-external-link hover-underline-link-accent is-text pr-0 mb-0'>
        <span>國家通訊傳播委員會行動通信業務客戶統計數</span>
        <img src="/resources/cbu/prepaid/images/external.svg" alt="external link" />
      </a>
    </div>`
  },
  {
    date: '2020/07/01',
    title: `<h6 class='m-0'>災防告警服務專區</h6>`,
    content: `
    <h6 class='is-text-regular mb-3'>遠傳配合主管機關及政府災害防救科技中心規劃建置「災防告警系統」，以即時簡訊提供用戶災防告警訊息，掌握應變時機。本系統持續進行測試及調整階段，相關訊息請參考</h6>
    <div class="d-block pt-1">
      <a href="#" class='fui-button is-external-link hover-underline-link-accent is-text pr-0 mb-0'>
        <span>政府公告</span>
        <img src="/resources/cbu/prepaid/images/external.svg" alt="external link" />
      </a>
    </div>
    <div class="d-block pt-1">
      <a href="#" class='fui-button is-external-link hover-underline-link-accent is-text pr-0 mb-0'>
        <span>災防告警訊息PWS－手機OTA軟體更新教學影片</span>
        <img src="/resources/cbu/prepaid/images/external.svg" alt="external link" />
      </a>
    </div>
    <div class="d-block pt-1">
      <a href="#" class='fui-button is-external-link hover-underline-link-accent is-text pr-0 mb-0'>
        <span>災防告警訊息PWS頻道專區</span>
        <img src="/resources/cbu/prepaid/images/external.svg" alt="external link" />
      </a>
    </div>
    `
  },
  {
    date: '2020/07/01',
    title: `<h6 class='m-0'>Title</h6>`,
    content: `
    <h6 class='is-text-regular m-0'>Title</h6>
    `
  },
  {
    date: '2020/07/01',
    title: `<h6 class='m-0'>Title</h6>`,
    content: `
    <h6 class='is-text-regular m-0'>Title</h6>
    `
  },
  {
    date: '2020/07/01',
    title: `<h6 class='m-0'>Title</h6>`,
    content: `
    <h6 class='is-text-regular m-0'>Title</h6>
    `
  },
]
class Bulletin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data
    }
  }
  onChangePage(pageOfItems, selectedPage) {
    // this.props.history.push(`${this.props.location.pathname}?keyword=${this.state.keyword}&page=${selectedPage}`);

    // update page of items
    // this.updatePageOfItems(pageOfItems);
    // console.log('on change');
  }
  render() {
    return (
      <main>
        <Header />
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回個人專區',
            link: '#',
          }}
        />
        <Breadcrumb
          color='white'
          breadcrumb={[
            { text: '幫助中心', link: '/' },
            { text: '服務公告', link: '/#/' }
          ]}
        />
        <AdaptHeightBanner
          height={{
            desktop: 360,
            mobile: 180
          }}
          bgImg={{
            md: '/resources/cbu/img/cbu-eservice-service-announcement-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-eservice-service-announcement-750x400.jpg',
          }}
          title='服務公告'
        />
        <section className="ribbon-bg pb-md-8 pb-7 pt-md-7 pt-4">
          <div className='fui-container'>
            {this.state.data ? this.state.data.map((item, i) => {
              return (
                <CollapsePaper
                  key={item.title + i}
                  title={item.title}
                  date={item.date}
                  className='mb-2'
                >
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </CollapsePaper>
              )
            }) : null}
            <Pagination
              items={['1', '2', '3', '4']}
              onChangePage={this.onChangePage}
              initialPage={1}
              pageSize={2} />
          </div>
        </section>
      </main>
    );
  }
}

export default Bulletin;