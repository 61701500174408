import React from 'react';
import Link from '../components/Link';
import PropTypes from 'prop-types';

class SelectedArticle extends React.Component {
  render() {
    return (
      <div className='selected-article'>
        <div className='head'>
          <h6>{this.props.title}</h6>
        </div>
        <ul>
          {this.props.article.map(article => {
            return (
              <li key={article.id}>
                <Link to={article.link}>
                  {article.genre ? (
                    <div className='top'>
                      <small>{article.genre}</small>
                      <small>
                        {article.date.year}/{article.date.month}/{article.date.day}
                      </small>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='body'>
                    <p>{article.content}</p>
                    <i className='icon-chevron-right'></i>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

SelectedArticle.propTypes = {
  title: PropTypes.string,
  article: PropTypes.array,
};
export default SelectedArticle;
