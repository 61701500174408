import React, { Component } from 'react';
import VideoModal from './VideoModal'
import Slider from 'react-slick';
import PropTypes from 'prop-types';
class VideoCarousel extends Component {
  constructor(props) {
    super(props);
    this.VideoSlideSetting = {
      dots: false,
      infinite: false,
      arrows: true,
      slidesToShow: 1,
      draggable: true,
    };
    this.state = {
      currentVideo: '',
      modalOpen: false
    }
  }
  openVideoModal = data => {
    console.log(`data:`, data);
    this.setState({
      modalOpen: true,
      alterVideo: data.alterVideo ? data.alterVideo : null,
      currentVideo: data.videoId,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      alterVideo: '',
      currentVideo: '',
    });
  };
  render() {
    return (
      <section className={`fui-horzonal-cards video-carousel is-narrow ${!!this.props.className ? this.props.className : ''}`} id='video-slider'>
        <div className='fui-container'>
          <h2 className='section-title m-0 p-0'>{this.props.title}</h2>
          <Slider {...this.VideoSlideSetting} className={`horizontal-cards mb-0 ${this.props.videos.length === 1 ? 'single-video' : ''}`}>
            {this.props.videos.map((card, idx) => (
              <div key={idx} className='video-card' onClick={() => this.openVideoModal(card)}>
                <div className='video-card-text'>
                  <div className='subtitle'>{card.title}</div>
                  <small>{card.content}</small>
                </div>
                <div className='video-img d-none d-sm-block' style={{ backgroundImage: `url(${card.imgLarge})` }} />
                <div className='video-img d-block d-sm-none' style={{ backgroundImage: `url(${card.imgSmall})` }} />
                <div className="mask"></div>
              </div>
            ))}
          </Slider>
        </div>
        <VideoModal
          open={this.state.modalOpen}
          alterVideo={this.state.alterVideo}
          videoUrl={this.state.currentVideo}
          onClose={this.closeModal}
        />
      </section>
    );
  }
}

VideoCarousel.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      videoId: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      imgSmall: PropTypes.string,
      imgLarge: PropTypes.string,
      alterVideo: PropTypes.string
    })
  ).isRequired
}
export default VideoCarousel;