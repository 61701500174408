import React from 'react';
import Link from '../Link';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

class AppPromotion3 extends React.Component {
  render() {
    return (
      <section
        className='fui-app-promotion app-promotion-3'
        id={this.props.id}
        style={{
          backgroundImage: `url(${this.props.bgImage})`,
        }}>
        <div className='d-none d-sm-block fui-container'>
          <Grid container spacing={4}>
            <Grid item sm={6} md={6} lg={5} xl={5}>
              <div className='app-info'>
                <img src={process.env.PUBLIC_URL + this.props.icon} height='96' alt='' />
                <div className='d-block'>
                  <h2 className='mb-1'>{this.props.title}</h2>
                  <h4>{this.props.subtitle}</h4>
                </div>
              </div>
            </Grid>
            <Grid item sm={6} md={6} lg={4} xl={4}>
              <Grid container className='download' spacing={4}>
                <Grid item xs={6} sm={6} md={6} className='align-center'>
                  <Link to={this.props.android.link}>
                    <img src={process.env.PUBLIC_URL + this.props.android.qrCode} height='144' alt='' />
                    <br />
                    <img src={process.env.PUBLIC_URL + this.props.android.icon} height='43' alt='' />
                    <br />
                    <div className='text-mini'>{this.props.android.title}</div>
                  </Link>
                </Grid>
                <Grid item xs={6} sm={6} md={6} className='align-center'>
                  <Link to={this.props.ios.link}>
                    <img src={process.env.PUBLIC_URL + this.props.ios.qrCode} height='144' alt='' />
                    <br />
                    <img src={process.env.PUBLIC_URL + this.props.ios.icon} height='43' alt='' />
                    <br />
                    <div className='text-mini'>{this.props.ios.title}</div>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className='d-block d-sm-none fui-container'>
          <div className='top-area'>
            <div className='app-info'>
              <img src={process.env.PUBLIC_URL + this.props.icon} height='66' alt='' />
              <div className='d-block'>
                <h2 className='mb-1'>{this.props.title}</h2>
                <h4>{this.props.subtitle}</h4>
              </div>
            </div>
          </div>
          <Grid container align='center' spacing={3}>
            <Grid item xs={6} sm={6} className='align-center'>
              <Link to={this.props.android.link}>
                <img src={process.env.PUBLIC_URL + this.props.android.icon} height='43' alt='' />
                <div className='text-mini'>{this.props.android.title}</div>
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} className='align-center'>
              <Link to={this.props.ios.link}>
                <img src={process.env.PUBLIC_URL + this.props.ios.icon} height='43' alt='' />
                <div className='text-mini'>{this.props.ios.title}</div>
              </Link>
            </Grid>
          </Grid>
        </div>
      </section>
    );
  }
}

AppPromotion3.propTypes = {
  id: PropTypes.string,
  bgImage: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  android: PropTypes.shape({
    link: PropTypes.string,
    qrCode: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
  }),
  ios: PropTypes.shape({
    link: PropTypes.string,
    qrCode: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
  }),
};

export default AppPromotion3;
