import React, { Component } from 'react'
import { Grid } from '@material-ui/core';

import Breadcrumb from '../../components/Breadcrumb';
import Panel from '../../components/panel/Panel';
import PanelInformation from '../../components/panelContent/PanelInformation';
import Paginate from '../../components/paginate';
import Link from '../../components/Link';
class Notice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            searchResult: [],
            faqItems: [],
            filterItems: [],
            pageOfItems: [],
            pageSetting: {
                pageSize: 5,
            },
            url: '',
        }
        this.onChangePage = this.onChangePage.bind(this);
    }
    onChangePage(pageOfItems, selectedPage) {
        console.log({ pageOfItems, selectedPage });
        // const query = new URLSearchParams(this.props.location.search);
        // query.set('page', selectedPage);
        // const searchParam = query.toString();
        // this.props.history.push(`${this.props.location.pathname}?${searchParam}`);

        // // update page of items
        // this.updatePageOfItems(pageOfItems);
    }
    render() {
        return (
            <main className="notice">

                <Breadcrumb
                    breadcrumb={[
                        { text: '首頁', link: '/' },
                        { text: '產品 ‧ 資費', link: '/' },
                        { text: '月租型', link: '/' },
                        { text: '資費方案', link: '/' },
                        { text: '手機與平板費率', link: '/' },
                        { text: '費率異動公告', link: '/' },
                    ]}
                    color='black'
                />
                <div className="fui-container">
                    <Grid container>
                        <Panel className="is-bg-white d-flex fui-table">
                            <Grid item xs={12} sm={12} md={9} className='pr-md-4'>
                                <h2 className="is-text-medium mt-0">費率異動公告</h2>
                                <PanelInformation
                                    title='<h5 class="m-0 is-text-black50 is-text-medium">限定通路限時優惠方案</h5>'
                                    date='<p class="is-text-gray100 m-0 is-text-medium">2020/03/10</p>'
                                    content={`
                                    <div class="schinner editBox">
                                        <table style="height: 2258px; width: 576px;" border="0" width="576">
                                        <tbody>
                                            <tr>
                                                <td class="null" width="115"><strong>遠傳電信</strong></td>
                                                <td class="null" colspan="2" width="261"><strong>資費方案</strong></td>
                                                <td class="null" width="237">199方案</td>
                                                <td width="199">299方案</td>
                                                <td width="249">399方案</td>
                                                <td width="237">999方案</td>
                                                <td width="237">1199方案</td>
                                                <td width="237">1399方案</td>
                                                <td width="237">1799方案</td>
                                                <td width="219">2699方案</td>
                                            </tr>
                                            <tr>
                                                <td class="null" colspan="3" width="376"><strong>原始月租方案(牌告價)(元)</strong></td>
                                                <td>199</td>
                                                <td>399</td>
                                                <td>399</td>
                                                <td>999</td>
                                                <td>1199</td>
                                                <td>1399</td>
                                                <td>1799</td>
                                                <td>2699</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="2" width="115"><strong>月租費</strong></td>
                                                <td colspan="2" width="261"><strong>元</strong></td>
                                                <td>199</td>
                                                <td>299</td>
                                                <td>399</td>
                                                <td>999</td>
                                                <td>1199</td>
                                                <td>1399</td>
                                                <td>1799</td>
                                                <td>2699</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" width="261"><strong>可抵通信費(可/不可)</strong></td>
                                                <td>不可</td>
                                                <td>不可</td>
                                                <td>不可</td>
                                                <td>不可</td>
                                                <td>不可</td>
                                                <td>不可</td>
                                                <td>不可</td>
                                                <td>不可</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" width="376"><strong>合約期間(月)</strong></td>
                                                <td>24/30</td>
                                                <td>30</td>
                                                <td>24/30</td>
                                                <td>24/30/36</td>
                                                <td>24/30/36</td>
                                                <td>24/30/36</td>
                                                <td>24/30/36</td>
                                                <td>24/30/36</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" width="376"><strong>優惠方案內容</strong></td>
                                                <td width="237">1.網內語音免費分鐘數50分鐘(原資費0分鐘)<br>2. 網外語音免費分鐘數20分鐘(原資費0分鐘)<br>3. 國內行動上網1GB(原資費200MB)</td>
                                                <td width="199">1. 國內行動上網1.3GB(原資費300MB)</td>
                                                <td width="249">1. 國內行動上網6GB(原資費300MB)<br>2.綁約24/30個月再享前3個月上網不限傳輸量之優惠<br>3.網內語音免費不限分鐘數(原資費單通前3分鐘免費)</td>
                                                <td width="237">1.網內語音免費不限分鐘數(原資費單通前10分鐘免費)<br>2.網外語音免費950分鐘(原資費50分鐘)<br>3. 國內行動上網不限傳輸量(原資費3GB)<br>4.網內免費簡訊250則(原資費200則)</td>
                                                <td width="237">1.網外語音免費970分鐘(原資費60分鐘)<br>2. 國內行動上網不限傳輸量(原資費4GB)<br>3.網內免費簡訊350則(原資費300則)</td>
                                                <td width="237">1.網外語音免費1300分鐘(原資費80分鐘)<br>2. 國內行動上網不限傳輸量(原資費6GB)<br>3.網內免費簡訊不限則數(原資費為400則)</td>
                                                <td width="237">1.網外語音免費1400分鐘(原資費130分鐘)<br>2. 國內行動上網不限傳輸量(原資費11GB)<br>3.網內免費簡訊不限則數(原資費為500則)</td>
                                                <td width="219">1.網外語音免費1420分鐘(原資費220分鐘)<br>2. 國內行動上網不限傳輸量(原資費22GB)<br>3.網內免費簡訊不限則數(原資費為600則)</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="29" width="115"><strong>通信費</strong></td>
                                                <td colspan="2" width="261"><strong>贈送通信費</strong></td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="3" width="92"><strong>網內通話</strong></td>
                                                <td width="169"><strong>一般(元/秒)</strong></td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>減價(元/秒)</strong></td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                                <td>0.08</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>免費分鐘數</strong></td>
                                                <td>50</td>
                                                <td>單通前3分鐘免費</td>
                                                <td>網內免費</td>
                                                <td>網內免費</td>
                                                <td>網內免費</td>
                                                <td>網內免費</td>
                                                <td>網內免費</td>
                                                <td>網內免費</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="3" width="92"><strong>網外通話</strong></td>
                                                <td width="169"><strong>一般(元/秒)</strong></td>
                                                <td>0.12</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>減價(元/秒)</strong></td>
                                                <td>0.12</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                                <td>0.11</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>免費分鐘數</strong></td>
                                                <td>20</td>
                                                <td>20</td>
                                                <td>20</td>
                                                <td>950</td>
                                                <td>970</td>
                                                <td>1300</td>
                                                <td>1400</td>
                                                <td>1420</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="3" width="92"><strong>市話</strong></td>
                                                <td width="169"><strong>一般(元/秒)</strong></td>
                                                <td>0.12</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>減價(元/秒)</strong></td>
                                                <td>0.12</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                                <td>0.1</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>免費分鐘數</strong></td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td class="null">-</td>
                                                <td class="null">-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td width="92"><strong>網外+市話</strong></td>
                                                <td width="169"><strong>免費分鐘數</strong></td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td width="92"><strong>網內+網外</strong></td>
                                                <td width="169"><strong>免費分鐘數</strong></td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="3" width="92"><strong>影像電話網內通話</strong></td>
                                                <td width="169"><strong>一般(元/秒)</strong></td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>減價(元/秒)</strong></td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                                <td>0.16</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>免費分鐘數</strong></td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="3" width="92"><strong>影像電話網外通話</strong></td>
                                                <td width="169"><strong>一般(元/秒)</strong></td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>減價(元/秒)</strong></td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                                <td>0.25</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>免費分鐘數</strong></td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="4" width="92"><strong>簡訊</strong></td>
                                                <td width="169"><strong>網內(元/則)</strong></td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>網外(元/則)</strong></td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                                <td>2.6127</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>免費網內則數</strong></td>
                                                <td>-</td>
                                                <td>50</td>
                                                <td>50</td>
                                                <td>250</td>
                                                <td>350</td>
                                                <td>無限制</td>
                                                <td>無限制</td>
                                                <td>無限制</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>免費網外則數</strong></td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="7" width="92"><strong>數據傳輸</strong></td>
                                                <td width="169"><strong>無限上網速率</strong></td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>不限速</td>
                                                <td>不限速</td>
                                                <td>不限速</td>
                                                <td>不限速</td>
                                                <td>不限速</td>
                                                <td>不限速</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>部分無限上網之期間</strong></td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>前3個月上網不限傳輸量(24/30個月)</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>有限上網速率</strong></td>
                                                <td>不限速</td>
                                                <td>不限速</td>
                                                <td>不限速</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>國內免費數據傳輸量</strong></td>
                                                <td>1GB</td>
                                                <td>1.3GB</td>
                                                <td>6GB</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>超量後速率</strong></td>
                                                <td>最高128Kbps</td>
                                                <td>最高128Kbps</td>
                                                <td>最高128Kbps</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>加購費率(計量)</strong></td>
                                                <td colspan="3" width="685">200MB/50元、1GB/150元、3GB/350元(促銷價，至2020/12/31)</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td width="169"><strong>加購費率(計時)</strong></td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" width="376"><strong>電信優惠補貼款(元)</strong></td>
                                                <td>-</td>
                                                <td>250元/月</td>
                                                <td>250元/月</td>
                                                <td>250元/月</td>
                                                <td>250元/月</td>
                                                <td>250元/月</td>
                                                <td>250元/月</td>
                                                <td>250元/月</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" width="376"><strong>終端設備補貼款(單門號無)(元)</strong></td>
                                                <td width="237">3000元(24個月)<br>4000元(30個月)</td>
                                                <td width="199">5000元(30個月)</td>
                                                <td width="249">3600-5000元(24個月)<br>3000-6000元(30個月)</td>
                                                <td width="237">7600-13600元(24個月)<br>9600-15600元(30個月)<br>11600-17600元(36個月)</td>
                                                <td width="237">9100-15100元(24個月)<br>11100-17100元(30個月)<br>13100-19600元(36個月)</td>
                                                <td width="237">12600-20600元(24個月)<br>14600-22600元(30個月)<br>16600-25600元(36個月)</td>
                                                <td width="237">16600-24600元(24個月)<br>20100-28100元(30個月)<br>23600-32100元(36個月)</td>
                                                <td width="219">25600-33600元(24個月)<br>30100-38100元(30個月)<br>34600-44100元(36個月)</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" width="376"><strong>計算方式</strong></td>
                                                <td colspan="8" width="1852">詳見註1、註2</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" width="376"><strong>促銷案實施日期</strong></td>
                                                <td colspan="8">2020/6/1</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" width="376"><strong>促銷案截止日期</strong></td>
                                                <td colspan="8">2020/6/30</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" width="376"><strong>適用對象</strong></td>
                                                <td colspan="8">新啟用/攜碼/續約</td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <p>註1、電信終端設備及其他契約搭配有價商品補貼款，將依約定總額為基準，以〝日〞為單位計算。【補貼款總額×(合約未到期日數÷合約約定日數)】<br>註2、電信費用補貼款，將依實際補貼款為基準，以〝日〞為單位計算。【實際補貼款總額×(合約未使用日數÷合約約定日數)】</p>
                                        <div class="descbox editBox">
                                        <div class="mainTitle">注意事項：</div>
                                        <ol>
                                            <li>本方案需搭配指定費率購機或其他有價商品，綁約24/30/36個月，合約期間內享方案優惠，適用之機款/型號/價格以門市現場銷售為主。申辦1399以上方案需先繳納定額帳單金額，攜碼免預繳需檢附他業者近3個月內任1期千元以上帳單正本方可辦理，符合資格之老客戶續約可免預繳。</li>
                                            <li>每月優惠僅限當月使用不得遞延，若於合約期間變更資費，視同放棄方案優惠；合約期滿恢復資費原價計收。</li>
                                            <li>本方案所提供上網優惠僅限國內使用，且限抵扣於APN為Internet之上網傳輸量。若於國外使用將另依當地國際漫遊收費標準計費。內含傳輸量使用完畢，將予以限速至最高128Kbps，用戶可按其需求加購上網計量包。</li>
                                            <li>申辦指定資費搭配指定機款加碼贈送5G體驗，贈送內容以簡訊通知為準，體驗期間優惠內容及收費同4G合約及另享5G網速體驗。申辦1399以上資費方案，用戶將於5G開台升級至對應5G方案。遠傳5G開台時間依遠傳公告為主。</li>
                                            <li>免費語音分鐘數不含影像/國際電話、國際漫遊、語音信箱、加值語音/特碼服務及其他免費電話。網外語音免費分鐘數不含市話。</li>
                                            <li>行動通信品質隨地形/氣候/建物遮蔽/使用人數/地點等因素影響，如無法支援LTE網路將轉為UMTS網路。</li>
                                            <li>本特別業務是為一般用戶正常使用所提供之通信費率方案，如經發現商業使用、不正常使用或其他權利濫用之行為，本公司得不經通知逕行終止本方案優惠或終止服務契約。</li>
                                            <li>申辦通路：遠傳直營、加盟、網路門市及電銷服務中心。</li>
                                            <li>299方案不定期開放指定通路申辦。</li>
                                        </ol>
                                        </div>
                                    </div>
                  <div class="compareTable content-16 clearfix">
                  <div class="tableLeft">
                      <div class="tableHead">
                          <table>
                              <tbody>
                                  <tr style="height: 52px;">
                                      <th>州別</th>
                                  </tr>
                                  <tr style="height: 260px;">
                                      <th>亞洲</th>
                                  </tr>
                                  <tr style="height: 52px;">
                                      <th>美洲</th>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div class="tableRight">
                      <div class="tableBody">
                          <div class="spaceBox">
                              <table class="compareList">
                                  <tbody>
                                      <tr style="height: 52px;">
                                          <td>國家</td>
                                          <td>國碼</td>
                                          <td>每分鐘通話費</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>香港</td>
                                          <td>852</td>
                                          <td>NT $6.7</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>日本/南韓</td>
                                          <td>81/82</td>
                                          <td>NT $9.1</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>中國</td>
                                          <td>86</td>
                                          <td>NT $8.4</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>新加坡</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>馬来西亞</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>美國/加拿大</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                  </tbody>
                              </table> 
                          </div>
                      </div>
                  </div>
              </div>
              <div class="descbox line editBox">
                <div class="mainTitle">注意事項：
                <div class="unfold">
                  <ol>
                    <li>限遠傳4G新絕配及4G好機零客戶申辦。</li>
                    <li>任意網內價費率方案僅適用於以上所列之資費。</li>
                    <li>申裝、退租及變更本服務隔日生效。申裝、退租及異動時，本服務當期月租費依門號計費週期按實際使用天數比例計算。</li>
                    <li>申裝生效前以及退租生效後，撥打網外語音產生之語音通信費，均依客戶所屬4G資費單價計收。</li>
                    <li>用戶可至遠傳電信直營門市、遠傳電信特約服務中心、或透過客戶服務中心申辦即可。</li>
                    <li>任意網僅享有所申辦費率之網內優惠價格，不包含國際電話、大眾電信、市內電話等。</li>
                  </ol>
                </div>
              </div>
                  `}
                                />
                                <PanelInformation
                                    title='<h5 class="m-0 is-text-black50 is-text-medium">限定通路限時優惠方案</h5>'
                                    date='<p class="is-text-gray100 m-0 is-text-medium">2020/03/10</p>'
                                    content={`
                  <div class="compareTable content-16 clearfix">
                  <div class="tableLeft">
                      <div class="tableHead">
                          <table>
                              <tbody>
                                  <tr style="height: 52px;">
                                      <th>州別</th>
                                  </tr>
                                  <tr style="height: 260px;">
                                      <th>亞洲</th>
                                  </tr>
                                  <tr style="height: 52px;">
                                      <th>美洲</th>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div class="tableRight">
                      <div class="tableBody">
                          <div class="spaceBox">
                              <table class="compareList">
                                  <tbody>
                                      <tr style="height: 52px;">
                                          <td>國家</td>
                                          <td>國碼</td>
                                          <td>每分鐘通話費</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>香港</td>
                                          <td>852</td>
                                          <td>NT $6.7</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>日本/南韓</td>
                                          <td>81/82</td>
                                          <td>NT $9.1</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>中國</td>
                                          <td>86</td>
                                          <td>NT $8.4</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>新加坡</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>馬来西亞</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>美國/加拿大</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                  </tbody>
                              </table> 
                          </div>
                      </div>
                  </div>
              </div>
                  `}
                                />
                                <PanelInformation
                                    title='<h5 class="m-0 is-text-black50 is-text-medium">限定通路限時優惠方案</h5>'
                                    date='<p class="is-text-gray100 m-0 is-text-medium">2020/03/10</p>'
                                    content={`
                  <div class="compareTable content-16 clearfix">
                  <div class="tableLeft">
                      <div class="tableHead">
                          <table>
                              <tbody>
                                  <tr style="height: 52px;">
                                      <th>州別</th>
                                  </tr>
                                  <tr style="height: 260px;">
                                      <th>亞洲</th>
                                  </tr>
                                  <tr style="height: 52px;">
                                      <th>美洲</th>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div class="tableRight">
                      <div class="tableBody">
                          <div class="spaceBox">
                              <table class="compareList">
                                  <tbody>
                                      <tr style="height: 52px;">
                                          <td>國家</td>
                                          <td>國碼</td>
                                          <td>每分鐘通話費</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>香港</td>
                                          <td>852</td>
                                          <td>NT $6.7</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>日本/南韓</td>
                                          <td>81/82</td>
                                          <td>NT $9.1</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>中國</td>
                                          <td>86</td>
                                          <td>NT $8.4</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>新加坡</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>馬来西亞</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>美國/加拿大</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                  </tbody>
                              </table> 
                          </div>
                      </div>
                  </div>
              </div>
                  `}
                                />
                                <PanelInformation
                                    title='<h5 class="m-0 is-text-black50 is-text-medium">限定通路限時優惠方案</h5>'
                                    date='<p class="is-text-gray100 m-0 is-text-medium">2020/03/10</p>'
                                    content={`
                  <div class="compareTable content-16 clearfix">
                  <div class="tableLeft">
                      <div class="tableHead">
                          <table>
                              <tbody>
                                  <tr style="height: 52px;">
                                      <th>州別</th>
                                  </tr>
                                  <tr style="height: 260px;">
                                      <th>亞洲</th>
                                  </tr>
                                  <tr style="height: 52px;">
                                      <th>美洲</th>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div class="tableRight">
                      <div class="tableBody">
                          <div class="spaceBox">
                              <table class="compareList">
                                  <tbody>
                                      <tr style="height: 52px;">
                                          <td>國家</td>
                                          <td>國碼</td>
                                          <td>每分鐘通話費</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>香港</td>
                                          <td>852</td>
                                          <td>NT $6.7</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>日本/南韓</td>
                                          <td>81/82</td>
                                          <td>NT $9.1</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>中國</td>
                                          <td>86</td>
                                          <td>NT $8.4</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>新加坡</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>馬来西亞</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                      <tr style="height: 52px;">
                                          <td>美國/加拿大</td>
                                          <td>65</td>
                                          <td>NT $7.3</td>
                                      </tr>
                                  </tbody>
                              </table> 
                          </div>
                      </div>
                  </div>
              </div>
                  `}
                                />
                                <Paginate
                                    initialPage={1}
                                    pageCount={8}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    containerClassName='fui-pagination'
                                    previousLabel={<i className='icon-chevron-left'></i>}
                                    nextLabel={<i className='icon-chevron-right'></i>}
                                    firstLabel={<i className='icon-first'></i>}
                                    lastLabel={<i className='icon-last'></i>}
                                    onPageChange={this.pageChange}
                                />
                                <Link to={'#'} className='fui-button is-text pr-0'>
                                    <i className='icon-chevron-left mr-1'></i>
                                    <span>返回資費總覽</span>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <div className="sidebar">
                                    <div className="sidebarBox">
                                        <div className="box max boxShadow">
                                            <div className="mainTitle">
                                                資費試算
                      </div>
                                            <p>那種資費最符合你的預算 讓FETnet幫你精打細算</p>
                                            <a href="/postpaid/rateplan/rateplan-simulation.html" target="_self" className="button fui-button">
                                                立即試算
                        </a>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Panel>
                    </Grid>
                </div>
            </main>
        );
    }
}

export default Notice;