import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import CheckGroup from '../../components/form/CheckGroup';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import ZipcodeBanner from '../../components/partials/banner/ZipcodeBanner';
import Breadcrumb from '../../components/Breadcrumb';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

class CallWating extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.form = React.createRef();
    this.state = {
      isMember: false,
      step: 1,
      mergable: false,
      canSubmit: false,
      modalOpen: false,
      authCode: '',
      phoneNumber: '0926-899-868',
      account: '859909700',
      form: {
        account: { value: '', required: false },
      },
      mergeNumber: [
        { value: '0', label: '0916-000-995' },
        { value: '1', label: '0928-789-789' },
        { value: '2', label: '0912-477-477' },
        { value: '3', label: '0988-654-654' },
        { value: '4', label: '0988–092-000' },
        { value: '5', label: '0936-282-525' },
        { value: '6', label: '0936-282-525' },
        { value: '7', label: '03-1234567' }
      ],
      linkNumber: [
        {
          type: 'cellphone',
          number: '0936-123-995'
        },
        {
          type: 'cellphone',
          number: '0931-456-995'
        },
        {
          type: 'landline',
          number: '02-1234567'
        },
        {
          type: 'landline',
          number: '02-8887777'
        },
      ]
    }
  }

  onChange = (name, value) => {
    // console.log(`name, value: `, name, value);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }

  handleChange = () => {
    this.setState({
      authCode: this.input.current.value,
    });
  }

  submitAuthCode = () => {
    // console.log(`this.authCode: `, this.input.current.value);
    this.setState({
      modalOpen: false,
      step: 2
    })
  }
  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    this.setState({ canSubmit: false });
  }
  submit = model => {
    // console.log('submitted');
  };
  render() {
    return (
      <main className='prepaid'>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <Breadcrumb
          color="white"
          breadcrumb={[
            { link: '/ebu', text: '個人首頁' },
            { link: '', text: '個人專區' },
            { link: '', text: '話中插接說明' },
          ]}
        />
        <SectionBanner2
          position='right'
          className="is-360"
          image={{
            md: '/resources/cbu/img/cbu-eservice-call-waiting-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-eservice-call-waiting-750x400.jpg',
          }}
          title='<div class="mt-5 is-text-white">話中插接說明</div>'
          action={null}
        />
        <section className="query-index ribbon-bg">
          <div className="fui-container">
            <h4 className="is-text-darkgray50 mb-4 pb-md-2 pb-0">當您正在通話中而有其他來電時，話中插接功能會以兩聲"嗶"聲告知您，您可以立即接聽，或由語音信箱為您代接。</h4>
            <div className="paper mt-md-4 mt-2 mb-2">
              <div className="d-flex justify-between flex-align-center mt-md-4 mt-md-2 mt-0 mb-2">
                <h4 className='m-0'>話中插接須知</h4>
              </div>
              <ul className="green-dot-list">
                <li>費用：免月租費，當用戶使用話中插接功能，接聽第三方電話，發話方雖為通話保留狀態，系統仍持續計費。</li>
              </ul>
              <div className="d-flex justify-between flex-align-center mt-md-4 mt-md-2 mt-0 mb-2">
                <h4 className='m-0'>設定及取消方式</h4>
              </div>
              <ul className="green-dot-list">
                <li>遠傳所有客戶，皆免費享有此功能，毋需另行啟用，並可透過下面方式輸入數字與符號後，於最後按出撥出鍵，來設定手機取消及重開此功能。</li>
              </ul>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <h4 className='mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular'>取消話中插接</h4>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-regular mb-1 mt-0">請按</h5>
                    <h2 className='mb-0'>#43#</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className='mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular'>重開話中插接</h4>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-regular mb-1 mt-0">請按</h5>
                    <h2 className='mb-0'>*43#</h2>
                  </div>
                </Grid>
              </Grid>
              <div className="d-flex justify-between flex-align-center mt-md-4 mt-2 mb-2">
                <h4 className='m-0'>操作方法</h4>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <figure className="fui-figure">
                    <img style={{ maxWidth: '220px' }} src="/resources/cbu/img/keep-and-waitng-call@2x.png" alt="keep-and-waitng-call" />
                    <figcaption className='mb-md-4 mb-0'>保留原通電話並接插撥，請按 2 再按撥出鍵</figcaption>
                  </figure>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <figure className="fui-figure">
                    <img style={{ maxWidth: '220px' }} src="/resources/cbu/img/change-and-waitng-call@2x.png" alt="change-and-waitng-call" />
                    <figcaption className='mb-md-4 mb-0'>在兩通電話中切換，請按 2 再按撥出鍵。</figcaption>
                  </figure>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <figure className="fui-figure">
                    <img style={{ maxWidth: '220px' }} src="/resources/cbu/img/cut-off-and-waiting-call@2x.png" alt="cut-off-and-waiting-call" />
                    <figcaption className='mb-md-4 mb-0'>結束一方通話，並和另一方繼續通話，請按 1 再按撥出鍵。</figcaption>
                  </figure>
                </Grid>
              </Grid>
              <div className="d-flex justify-between flex-align-center mt-md-4 mt-md-2 mt-4 mb-2">
                <h4 className='m-0'>計費說明</h4>
              </div>
              <ul className="green-dot-list">
                <li>自己打給朋友A，被朋友B插撥，則「於插撥狀態自己仍需支付撥打給朋友A的費用」。</li>
                <li>朋友B插撥進來，則「插撥自己，朋友B需支付撥打給自己之費用」。</li>
              </ul>
              <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                <img className='w-100' src="/resources/cbu/img/img-eservice-call-waiting-info@2x.jpg" alt="img-eservice-call-waiting-info" />
              </div>
            </div>
          </div>
        </section>


        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
          <ol>
            <li>此服務供遠傳月租型、預付型用戶使用。</li>
            <li>未被接起之插撥電話將直接被轉至語音信箱。</li>
          </ol>
          `}
        />
      </main >
    )
  }
}

export default CallWating;