import { withFormsy } from 'formsy-react';
import React from 'react';
import PropTypes from 'prop-types';

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.changeValue = this.changeValue.bind(this);
    this.state = {
      submitted: false,
      isInvalid: false,
      required: this.props.required || false,
      placeholder: this.props.placeholder || '',
      maxLength: this.props.maxLength || 200,
      minLength: this.props.minLength || 200,
    };
  }

  componentDidUpdate(prevProps) {
    // console.group(prevProps.value,
    if (prevProps.getValue() !== this.props.getValue()) {
      this.input.current.value = this.props.getValue();
    }
    if (this.props.isFormSubmitted() !== this.state.submitted) {
      // console.log(this.props.value)
      this.setState({
        submitted: this.props.isFormSubmitted(),
        isInvalid: this.props.showRequired() || this.props.showError(),
      });
    }
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.value);
    this.props.setValue(this.input.current.value);
    this.setState({
      submitted: this.props.isFormSubmitted(),
      isInvalid: this.props.showRequired() || this.props.showError(),
    });

    if(this.props.onChange)
      this.props.onChange(event.currentTarget.value)
      
  }

  resetInput = () => {
    this.props.setValue(null);
    this.currentValue = null;
  };

  render() {
    const errorMessage = this.props.getErrorMessage();
    return (
      <div className='text-input'>
        <input
          className={this.state.isInvalid ? 'invalid' : ''}
          ref={this.input}
          onChange={this.changeValue}
          onKeyUp={this.changeValue}
          onBlur={this.changeValue}
          placeholder={this.state.placeholder}
          value={this.props.getValue() || ''}
          maxLength={this.state.maxLength}
          minLength={this.state.minLength}
        />
        {this.currentValue ? (
          <div className='reset' onClick={this.resetInput}>
            <i className='icon-close'></i>
          </div>
        ) : (
          ''
        )}
        {this.state.isInvalid ? <span className='error-message'>{errorMessage}</span> : ''}
      </div>
    );
  }
}

TextInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  changeValue: PropTypes.bool,
  onChange: PropTypes.func
};

export default withFormsy(TextInput);
