import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';
import LabelInput from '../form/LabelInput';
import { withRouter } from 'react-router-dom';
import Button from '../Button';
import PropTypes from 'prop-types';

export class PhoneSubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      done: false,
      cantSubmit: false,
      showEmptyError: false,
      form: {
        phone: { value: '', required: true },
      },
    };
  }

  changeValue = (n, v) => {
    let form = Object.assign(this.state.form);
    form[n].value = v;
    this.setState({
      form: form,
    });
    this.forceUpdate();
  };

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };
  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };
  subscribe = e => {
    if (this.state.canSubmit) {
      // this.props.setLoading(true);

      const pathname = this.props.url.endsWith('.html') ? this.props.url : this.props.url + '.html';
      console.log('subscribed');

      // this.props.history.push({
      //   pathname,
      //   search: `?phone=${this.state.form.phone.value}`,
      // });
    } else {
      this.setState({
        showEmptyError: true,
      });
    }
  };

  render() {
    return (
      <section className='fui-section-promo subscribe-wrapper is-edm' style={!!this.props.bg ? { backgroundImage: `url(${process.env.PUBLIC_URL + this.props.bg})` } : null}>
        <Grid container justify='center'>
          <Grid item xs={12} sm={12} md={6} align='center'>
            <Formsy className='w-100' onValid={() => this.enableButton()} onInvalid={() => this.disableButton()}>
              <h4 className=''>{this.props.title}</h4>
              <div className='subscription fui-input-group no-shadow'>
                <div className='fui-input'>
                  <LabelInput
                    name='phone'
                    validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                    required
                    label=''
                    placeholder='輸入手機門號'
                    value={this.state.form.phone.value}
                    validationErrors={{
                      matchRegexp: '請輸入正確的手機門號',
                    }}
                    onChange={this.changeValue}
                  />
                  {this.state.showEmptyError && !this.state.canSubmit ? (
                    <span className='error-message'>請輸入正確的手機門號</span>
                  ) : null}
                </div>
                <div className='fui-action'>
                  <Button
                    onClick={() => this.subscribe()}
                    className={`m-0 ${!this.state.canSubmit ? 'disabled' : ''}`}
                    btnStyle='primary'>
                    {this.props.sending}
                  </Button>
                </div>
                {this.state.done ? (
                  <div className='subscribe'>
                    <button className='fui-button is-primary m-0 email-pending'>
                      <span className='text'>傳送中...</span>
                    </button>
                    <div className='is-primary m-0 email-done'>
                      <span className='text'>您已成功訂閱</span>
                    </div>
                  </div>
                ) : (
                    ''
                  )}
              </div>
            </Formsy>
          </Grid>
        </Grid>
      </ section>
    );
  }
}

PhoneSubscribe.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  sending: PropTypes.string,
};

export default withRouter(PhoneSubscribe);
