import React from 'react';
import Header from '../../components/partials/header/Default';
import { Grid } from '@material-ui/core';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import RadioGroup from '../../components/form/RadioGroup';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import CheckboxButtons from '../../components/form/CheckboxButtons';
import RadioButtons from '../../components/form/RadioButtons';
import Select from '../../components/form/Select';
import CheckGroup from '../../components/form/CheckGroup';
import LabelInput from '../../components/form/LabelInput';
import LabelTextarea from '../../components/form/LabelTextarea';
import Formsy from 'formsy-react';
import scriptLoader from 'react-async-script-loader';

class PromotionForm extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.map = null;
    this.state = {
      canSubmit: true,
      form: {
        name: { value: '', required: true },
        email: { value: '', required: true },
        mobile: { value: '', required: true },
        interesting01: { value: '0', required: true },
        interesting02: { value: '0', required: true },
        comment: { value: '', required: false },
        company_name: { value: '', required: true },
        company_phone: { value: '', required: false },
        contact_time: { value: '', required: true },
        resources: { value: '0', required: false },
        tax_number: { value: '', required: false },
        company_location: { value: '0', required: true },
        company_type: { value: '0', required: true },
        event_no: { value: '0', required: true },
        event_person: { value: '', required: true },
        agree: { value: '', required: true },
        address: { value: '', required: true },
        industry: { value: '', required: true },
        register: { value: '', required: true },
      },
    };
    this.onChange = this.onChange.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.submit = this.submit.bind(this);
  }
  componentDidMount() {
    // this.props.setFooter('blank');
  }
  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (typeof window === 'undefined') return;

    if (isScriptLoaded && !this.props.isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        const lat = 25.025833;
        const lng = 121.538167;
        this.map = new window.google.maps.Map(this.refs.map, {
          center: { lat: lat, lng: lng },
          // mapTypeControl: true,
          zoom: 16,
          icon: {
            origin: new window.google.maps.Point(130, 130),
          },
        });

        const marker = new window.google.maps.Marker({
          position: { lat: lat, lng: lng },
          map: this.map,
          // label: {
          //     text: '106台北市大安區建國南路二段231號',
          //     color: "#eb3a44",
          //     fontSize: "14px",
          // },
        });
      } else this.props.onError();
    }
  }

  onChange(name, value) {
    let form = Object.assign(this.state.form);
    form[name].value = value;
    this.setState({
      form: form,
    });
  }

  disableButton(model, resetForm, invalidateForm) {
    // debugger
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        if (window.isIE) {
          let elem = window || document.documentElement
          elem.scroll(0, document.getElementsByClassName('is-invalid')[0].offsetTop);
        } else
          window.scrollTo(0, document.getElementsByClassName('is-invalid')[0].offsetTop);
      }, 100);
    }
    this.setState({ canSubmit: false });
  }

  enableButton(model, resetForm, invalidateForm) {
    // debugger
    this.setState({ canSubmit: true });
  }

  submit(model) {
    console.log('submitted');
    this.props.setLoading(true);
    console.log(this.props);
    setTimeout(() => {
      this.props.setLoading(false);
    }, 5000);
  }

  render() {
    return (
      <main className=''>
        <Header />
        <NavAnchor
          pageTitle='學生青春無價'
          button={{
            text: '回活動頁',
            link: '#'
          }} />
        <FormBanner
          title="選方案，領7-11禮券"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />
        <Formsy
          className=''
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalidSubmit={this.disableButton}
          noValidate
          ref={this.form}>
          <div className='fui-container pt-md-7 pt-4'>
            <div className='form-group'>
              <h4 className='form-description is-text-darkgray50'>
                填寫您的需求，專家將提供您專業的建議與解決方案。
              </h4>
              <div className='is-text-accent text-sm'>
                <span className='required-dot'></span>必填
              </div>
            </div>

            <CheckGroup
              onChange={this.onChange}
              validationErrors={{ isDefaultRequiredValue: '請選擇階段' }}
              label='您有興趣了解'
              name='interesting01'
              required={this.state.form.interesting01.required}
              default={this.state.form.interesting01.value}
              options={[
                { value: '0', label: '雲端服務建議' },
                { value: '1', label: '行動通訊服務' },
                { value: '2', label: '強化資訊安全' },
                { value: '3', label: '大數據分析' },
                { value: '4', label: 'VPN 數據服務' },
                { value: '5', label: 'IDC 代客服務' }
              ]}
            />
            <RadioGroup
              validationErrors='請選擇項目'
              onChange={this.onChange}
              label='您有興趣了解'
              name='interesting02'
              className="mb-md-5 mb-2"
              required={this.state.form.interesting02.required}
              default={this.state.form.interesting02.value}
              options={[
                { value: '0', label: '雲端服務建議' },
                { value: '1', label: '行動通訊服務' },
                { value: '2', label: '強化資訊安全' },
                { value: '3', label: '大數據分析' },
                { value: '4', label: 'VPN 數據服務' },
                { value: '5', label: 'IDC 代客服務' }
              ]}
            />
            <LabelTextarea
              name='comment'
              required={this.state.form.comment.required}
              value={this.state.form.comment.value}
              label='還有其他需求想讓我們知道嗎？'
              placeholder='您可以在此輸入需求...'
              maxLength={200}
              onChange={this.onChange}
            />

            <Grid container spacing={2} className='input-row'>
              <Grid item xs={12} sm={12} md={6}>
                <LabelInput
                  validationErrors={{
                    isDefaultRequiredValue: '請輸入您的姓名',
                  }}
                  name='name'
                  required={this.state.form.name.required}
                  value={this.state.form.name.value}
                  label='姓名'
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <LabelInput
                  validations={{
                    minLength: 10,
                    isMobile: function (values, value) {
                      return !value === false && value.length === 10 && /^09\d{8}$/.test(value)
                        ? true
                        : '請輸入正確的行動電話';
                    },
                  }}
                  validationErrors={{
                    isDefaultRequiredValue: '請輸入行動電話',
                  }}
                  name='mobile'
                  required={this.state.form.mobile.required}
                  value={this.state.form.mobile.value}
                  label='行動電話'
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <LabelInput
                  validations='isEmail'
                  validationErrors={{
                    isEmail: '請輸入正確的 Email',
                    isDefaultRequiredValue: '請輸入 Email',
                  }}
                  name='email'
                  required={this.state.form.email.required}
                  value={this.state.form.email.value}
                  label='Email'
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <LabelInput
                  validationErrors={{
                    isDefaultRequiredValue: '請輸入公司名稱',
                  }}
                  name='company_name'
                  required={this.state.form.company_name.required}
                  value={this.state.form.company_name.value}
                  label='公司名稱'
                  onChange={this.onChange}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <LabelInput
                  validationErrors={{
                    isDefaultRequiredValue: '請輸入公司電話/分機',
                  }}
                  name='company_phone'
                  required={this.state.form.company_phone.required}
                  value={this.state.form.company_phone.value}
                  label='公司電話/分機'
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <LabelInput
                  validationErrors={{
                    isDefaultRequiredValue: '請輸入統編',
                  }}
                  name='tax_number'
                  required={this.state.form.tax_number.required}
                  value={this.state.form.tax_number.value}
                  label='統編'
                  onChange={this.onChange}
                />
              </Grid>


              <Grid item xs={12} sm={12} md={6}>
                <div className='form-group'>
                  <label className='is-required'>公司地點</label>
                  <Select
                    validationErrors={{
                      isDefaultRequiredValue: '請輸入公司地點',
                    }}
                    name='address'
                    required={this.state.form.address.required}
                    options={[
                      { text: 'Option 1', value: '1' },
                      { text: 'Option 2', value: '2' },
                    ]}
                    value={this.state.form.address.value}
                    label={!!this.state.form.address.value ? this.state.form.address.value : '請輸入公司地點'}
                    onChange={this.onChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='form-group'>
                  <label className='is-required'>產業別</label>
                  <Select
                    validationErrors={{
                      isDefaultRequiredValue: '請輸入產業別',
                    }}
                    name='industry'
                    required={this.state.form.industry.required}
                    options={[
                      { text: 'Option 1', value: '1' },
                      { text: 'Option 2', value: '2' },
                    ]}
                    value={this.state.form.industry.value}
                    label={!!this.state.form.industry.value ? this.state.form.industry.value : '請輸入產業別'}
                    onChange={this.onChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <CheckboxButtons
                  label='您在哪些地方看過此資訊？(可複選)'
                  name='resources'
                  options={[
                    { value: '1', label: '公開課程' },
                    { value: '2', label: 'FB廣告' },
                    { value: '3', label: '網路廣告' },
                    { value: '4', label: '網路搜尋' },
                    { value: '5', label: '雜誌廣告' },
                    { value: '6', label: '親友推薦' },
                    { value: '7', label: '計程車廣告' },
                    { value: '8', label: '其他' },
                  ]}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item md={12}>
                <RadioButtons
                  label='方便聯繫您的時間'
                  required
                  validationErrors={{ isDefaultRequiredValue: '請選擇方便聯繫您的時間' }}
                  name='contact_time'
                  options={[
                    { value: '3', label: '17:00-19:00' },
                    { value: '1', label: '10:00-12:00' },
                    { value: '2', label: '14:00-17:00' },
                    { value: '0', label: '不指定時間' },
                  ]}
                  selected={'3'}
                  getSelected={e => this.onChange('contact_time', e)}
                />
              </Grid>
            </Grid>
          </div>
          <div className='google-map'>
            <div className='fui-container'>
              <div className='google-map-info'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <h4 className=''>課程資訊</h4>
                    <div className='info-list'>
                      <p className='text is-text-medium'>課程地點</p>
                      <p className='text'>106台北市大安區建國南路二段231號</p>
                    </div>
                    <div className='info-list'>
                      <p className='text is-text-medium'>課程名額</p>
                      <p className='text'>40 位</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <h4 className=''>交通資訊</h4>
                    <div className='info-list'>
                      <p className='text is-text-medium'>搭捷運</p>
                      <p className='text'>科技大樓站下站，步行9分鐘。</p>
                    </div>
                    <div className='info-list'>
                      <p className='text is-text-medium'>自行開車</p>
                      <p className='text'>
                        大夏館停車場 106台北市大安區建國南路二段229號
                        <br />
                        ※此為收費停車場，每小時 20 元
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className='google-map-display mb-7' ref='map'></div>
            {!this.map && <div className='center-md'>Loading...</div>}
          </div>
          <div className='fui-container mb-120'>
            <div className='input-group'>
              <div className='recaptcha'>
                <img src='https://cldup.com/p-jwfiXPY0-3000x3000.png' alt='recaptcha' />
              </div>
              <CheckboxCollapse
                name='agree'
                required
                validationErrors={{ isDefaultRequiredValue: '請同意個人資料保護法第8條所為之告知事情' }}
                collapseContent={`<p>您已知悉並同意遠傳電信股份有限公司(以下稱本公司)將您所留下的個人資料(包括姓名、電子郵件地址、電話號碼及其他您同意填寫的資料)，進行蒐集、利用與處理。本公司不會將您的個人資料傳輸予第三方，您的個人資料僅利用來與您為後續業務之連繫使用。於業務連繫期間，您有權隨時向本公司申請補充、更正、查閱或提供您個人資料之複本，您亦有權要求本公司停止蒐集、處理、利用或刪除您的個人資料(提醒您注意，於此同時，本公司將無法繼續提供您完善的服務)。您欲行使前述各項權利時，本公司的企業客服窗口能受理您的請求。您可以在本公司企業服務官網(www.fetnet.net/enterprise)找到企業客服電話，謝謝！</p>`}
                label='我已知悉並了解遠傳依個人資料保護法第8條所為之告知事情'
                getCheckbox={e => this.onChange('agree', e)}
                checked={false}
              />
            </div>

            <div className="page-action">
              <button onClick={this.submit} className={`is-large fui-button is-primary m-0`} disabled={!this.state.canSubmit}>
                <span className='text'>{this.state.isEn ? 'Submit' : '確認送出'}</span>
              </button>
            </div>
          </div>
        </Formsy>
      </main>
    );
  }
}

export default scriptLoader(['https://maps.googleapis.com/maps/api/js?key=AIzaSyAEtGDgMxN3WjDZchzkMCNz3l2PWdPtKxM'])(PromotionForm);
