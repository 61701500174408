import React from 'react';
import Tab from '../tab/Tab';
import TabPane from '../tab/TabPane';
import Slider from 'react-slick';
import Button from '../Button';
import PropTypes from 'prop-types';

class PlanTable extends React.Component {
  constructor(props) {
    super(props);

    this.planTable = React.createRef();
    this.sliderRef = [];

    this.state = {
      currentHover: -1,
      currentTab: this.props.tabs.default || 0
    }
    this.slideSetting = {
      dots: false,
      infinite: false,
      arrows: true,
      draggable: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToShow: 2,
            variableWidth: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: true,
            slidesToShow: 1,
            variableWidth: true
          }
        }
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tabs.default !== this.props.tabs.default) {
      this.setState({ currentTab: this.props.tabs.default || 0 })
    }

    if (this.props.selected.name !== prevProps.selected.name) {
      setTimeout(() => {
        this.sliderRef[this.state.currentTab].slickGoTo(this.hasSelect(this.props.tabContent[this.state.currentTab]))
      }, 300)
    }
  }

  setHover = (index) => {
    this.setState({
      currentHover: index,
    })
  }

  tabChange = (index) => {
    this.setState({
      currentTab: index
    })
  }

  setSlider = (ref) => {
    this.sliderRef.push(ref)
  }

  hasSelect = (tab) => {
    let index = 0
    tab.forEach((item, i) => {
      if (this.props.selected.name === item.name) {
        index = i;
      }
    })

    return index;
  }

  render() {
    return (
      <section className='fui-plan-table-container'>
        <div className="fui-container">
          <h2>{this.props.title}</h2>
          <Tab {...this.props.tabs} name='fui-plan-table-tab' default={this.state.currentTab} onChange={this.tabChange} />
          {
            this.props.tabContent.map((tab, i) => (
              <TabPane active={i === this.state.currentTab} key={`fui-plan-table-${i}`}>
                <div className='fui-plan-table'>
                  <div className='fui-plan-table-header'>
                    <div className='fui-plan-table-item'>
                      <div className='fui-plan-table-label'>約期</div>
                    </div>
                    <div className='fui-plan-table-item more-height'>
                      <div className='fui-plan-table-label'>數據<br />傳輸量</div>
                    </div>
                    <div className='fui-plan-table-item'>
                      <div className='fui-plan-table-label'>網內通話</div>
                    </div>
                    <div className='fui-plan-table-item'>
                      <div className='fui-plan-table-label'>網外通話</div>
                    </div>
                    <div className='fui-plan-table-item'>
                      <div className='fui-plan-table-label'>市話</div>
                    </div>
                    <div className='fui-plan-table-item'>
                      <div className='fui-plan-table-label'>網路限定禮</div>
                    </div>
                  </div>
                  <div className='fui-plan-table-body' ref={this.planTable}>
                    <Slider {...this.slideSetting} initialSlide={this.hasSelect(tab)} slidesToShow={tab.length >= 4 ? 4 : tab.length} ref={this.setSlider}>
                      {
                        tab.map((item, k) => (
                          <div className={`fui-plan-table-segment ${this.props.selected.name === item.name ? 'is-select' : ''}`} key={`fui-plan-table-${i}-segment-${k}`}>
                            <div className='fui-plan-table-title'>
                              <h1>
                                <small>{item.unit}</small>
                                <span className="price is-text-error">
                                  <small>$</small>{item.title}
                                </span>
                              </h1>
                              <Button
                                target={item.action.target || '_self'}
                                link={item.action.link}
                                btnStyle={this.props.selected.name === item.name ? 'primary' : 'secondary'}
                              >
                                {item.action.text}
                              </Button>
                            </div>
                            <div
                              onMouseOut={e => this.setHover(-1)}
                              onMouseOver={e => this.setHover(0)}
                              className={`fui-plan-table-item ${this.state.currentHover === 0 ? 'is-hover' : ''}`}>
                              {
                                item.detail.duration.map((du, idx) => (
                                  <div key={`fui-plan-table-${i}-segment-${k}-duration-${idx}`}>{du}</div>
                                ))
                              }
                            </div>
                            <div
                              onMouseOut={e => this.setHover(-1)}
                              onMouseOver={e => this.setHover(1)}
                              className={`fui-plan-table-item ${this.state.currentHover === 1 ? 'is-hover' : ''} more-height`}
                              dangerouslySetInnerHTML={{ __html: item.detail.throughput }}
                            >
                            </div>
                            <div
                              onMouseOut={e => this.setHover(-1)}
                              onMouseOver={e => this.setHover(2)}
                              className={`fui-plan-table-item ${this.state.currentHover === 2 ? 'is-hover' : ''}`}
                              dangerouslySetInnerHTML={{ __html: item.detail.intranet }}
                            >
                            </div>
                            <div
                              onMouseOut={e => this.setHover(-1)}
                              onMouseOver={e => this.setHover(3)}
                              className={`fui-plan-table-item ${this.state.currentHover === 3 ? 'is-hover' : ''}`}
                              dangerouslySetInnerHTML={{ __html: !item.detail.offnet || item.detail.offnet === '' ? '-' : item.detail.offnet }}
                            >
                            </div>
                            <div
                              onMouseOut={e => this.setHover(-1)}
                              onMouseOver={e => this.setHover(4)}
                              className={`fui-plan-table-item ${this.state.currentHover === 4 ? 'is-hover' : ''}`}
                              dangerouslySetInnerHTML={{ __html: !item.detail.cityTalk || item.detail.cityTalk === '' ? '-' : item.detail.cityTalk }}
                            >
                            </div>
                            <div
                              onMouseOut={e => this.setHover(-1)}
                              onMouseOver={e => this.setHover(5)}
                              className={`fui-plan-table-item ${this.state.currentHover === 5 ? 'is-hover' : ''}`}
                              dangerouslySetInnerHTML={{ __html: !item.detail.limitedGift || item.detail.limitedGift === '' ? '-' : item.detail.limitedGift }}
                            >
                            </div>
                          </div>
                        ))
                      }
                    </Slider>
                  </div>
                </div>
              </TabPane>
            ))
          }
        </div>
      </section >
    )
  }
}

PlanTable.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        labe: PropTypes.string,
      }),
    ),
    scroll: PropTypes.bool,
  }),
  tabContent: PropTypes.array,
  selected: PropTypes.object
}

export default PlanTable;