import React, { Component } from 'react'
import Faq from './Faq';
import { withRouter } from 'react-router-dom'


class HelpCenterContent extends Component {
  render() {
    return (
      <section className='fui-section-faq'>
        <div className='fui-container'>
          <Faq {...this.props}></Faq>
        </div>
      </section>
    )
  }
}

export default withRouter(HelpCenterContent)