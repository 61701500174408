import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Breadcrumb from '../../components/Breadcrumb';
class AppList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick(item) {
    window.open(item.link.descriptionLink, item.link.target || "_self");
  }
  render() {
    return (
      <section className='list-wrapper m-0'>
        <div className='fui-container'>
          <Grid container spacing={2}>
            {this.props.list.map((item) => {
              return (
                <Grid key={item.appName} item xs={12} sm={12} md={6}>
                  <div className='paper'>
                    <div className='d-flex' onClick={(e) => item.link.descriptionLink ? this.handleClick(item) : null} style={item.link.descriptionLink ? { "cursor": "pointer" } : {}}>
                      <img src={item.icon.img} alt={item.icon.alt} />
                      <div className='flex-column d-lfex'>
                        <div className='body'>{item.appName}</div>
                        <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex'>
                      {item.link.ios ? (
                        <a href={item.link.ios} target={item.link.target}>
                          <img src='/resources/cbu/help-center/images/app-store.png' />
                        </a>
                      ) : null}
                      {item.link.android ? (
                        <a href={item.link.android} target={item.link.target}>
                          <img src='/resources/cbu/help-center/images/google-play.png' />
                        </a>
                      ) : null}
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </section>
    );
  }
}
Breadcrumb.propTypes = {
  list: PropTypes.array,
};
export default AppList;
