export const hotLocation = {
  title: '熱門目的地',
  cards: [
    {
      image: '/resources/cbu/roaming/cbu-roaming-china-thumbnail-01.jpg',
      title: '中國大陸',
      description: 'China',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/cbu-roaming-hongkong-thumbnail-03.jpg',
      title: '香港',
      description: 'Hong Kong',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/cbu-roaming-Macau-thumbnail-02.jpg',
      title: '澳門',
      description: 'Macau',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/cbu-roaming-japen-thumbnail-04.jpg',
      title: '日本',
      description: 'Japan',
      link: '#',
    },
  ],
};

export const sectionFeature = {
  title: '為什麽選擇原號漫遊？',
  color: 'accent',
  column: 3,
  list: [
    {
      title: '挑戰通路超低價',
      image: '/resources/cbu/roaming/roaming-feature-1@2x.png',
      list: [{ text: '重要來電不漏接' }, { text: '24 小時線上客服' }, { text: '提醒簡訊上網金額' }],
    },
    {
      title: '輕鬆買安心退',
      image: '/resources/cbu/roaming/roaming-feature-2@2x.png',
      list: [{ text: '線上即可隨開隨用' }, { text: '免換卡、免租分享器' }, { text: '可進行熱點分享' }],
    },
    {
      title: '售後服務最到位',
      image: '/resources/cbu/roaming/roaming-feature-3@2x.png',
      list: [{ text: '多元旅遊好康送給你' }, { text: '加購機上漫遊好方便' }, { text: '加購機上漫遊好方便' }],
    },
  ],
};

export const topicCard = {
  title: '主題活動',
  // more: {
  //   link: '#',
  //   text: '更多話題',
  // },
  cards: [
    {
      image: '/resources/cbu/roaming/roaming-card-img-1@2x.jpg',
      subtitle: '亞洲',
      title: '旅日省錢大作戰',
      description: '誰說出國要花大錢？教你小資遊日本的最佳祕技！',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/roaming-card-img-2@2x.jpg',
      subtitle: '亞洲',
      title: '青春亞洲遊',
      description: '旅遊達人帶你玩遍亞洲，完成你的夢想清單。',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/roaming-card-img-3@2x.jpg',
      subtitle: '亞洲',
      title: '賞櫻私房景點',
      description: '賞櫻就是要優雅又從容，不要再去人擠人的觀光景點了！ ',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/roaming-card-img-3@2x.jpg',
      subtitle: '亞洲',
      title: '賞櫻私房景點',
      description: '賞櫻就是要優雅又從容，不要再去人擠人的觀光景點了！ ',
      link: '#',
    },
  ],
};

export const eventPromoData = {
  image: {
    md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
    sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
  },
  title: '不知道原號漫遊怎麼用？別擔心，漫遊小叮嚀讓你一次就上手！',
  description: '',
  action: {
    text: '了解更多',
    link: '#',
  },
};

export const eventPromoData2 = {
  image: {
    md: '/resources/cbu/cbu-index/promo-img-web.jpg',
    sm: '/resources/cbu/cbu-index/promo-img-mobile.jpg',
  },
  title: 'Apple Watch Series 5 LTE 11/8上市 現正開放預購',
  description: '',
  action: {
    text: '了解輕享方案',
    link: '#',
  },
};

export const discountCard = {
  title: '漫遊優惠',
  more: {
    link: '#',
    text: '看更多',
  },
  cards: [
    {
      image: '/resources/cbu/roaming/roaming-card-img-4@2x.jpg',
      subtitle: '',
      title: '遠傳用戶獨享接機優惠價 $799',
      description: '疫情結束全面啟動旅遊模式，機場接送優惠價，陪你輕鬆出遊安心回家。',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/roaming-card-img-5@2x.jpg',
      subtitle: '',
      title: '亞洲輕量包 漫遊一天只要 $20',
      description: '自助最佳旅伴全新登場！一天 1G 只要 $20 超划算！',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/roaming-card-img-4@2x.jpg',
      subtitle: '',
      title: '訂房送帳單折抵金 $1,050',
      description: '於 Booking.com 訂房享有遠傳電信帳單折抵，要搶要快！ ',
      link: '#',
    },
  ],
};

export const otherCard = {
  title: '<h2>其他漫遊</h2>',
  // more: {
  //   link: '#',
  //   text: '更多話題',
  // },
  cards: [
    {
      image: '/resources/cbu/roaming/airplane.svg',
      subtitle: '',
      title: '機上漫遊',
      description:
        '搭飛機也能上網滑手機！上網、追劇、遠端辦公、聊 Line，在二萬英呎高空上一點也不無聊！輕鬆體驗在高空飆網的樂趣！',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/wifi.svg',
      subtitle: '',
      title: 'Wifi優惠費率',
      description:
        '搭飛機也能上網滑手機！上網、追劇、遠端辦公、聊 Line，在二萬英呎高空上一點也不無聊！輕鬆體驗在高空飆網的樂趣...',
      link: '#',
    },
  ],
};

export const faqTab = {
  title: '常見問題',
  tabs: [{ label: '漫遊上網促銷' }, { label: '漫遊語音/上網費' }, { label: '漫遊通話/上網設定' }],
  collapseList: [
    [
      {
        title: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？',
        content: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？',
      },
      {
        title: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？',
        content: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？',
      },
      {
        title: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？',
        content: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？',
      },
    ],
    [
      {
        title: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？',
        content: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？',
      },
      {
        title: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？',
        content: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？',
      },
      {
        title: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？',
        content: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？',
      },
    ],
    [
      {
        title: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？',
        content: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？',
      },
      {
        title: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？',
        content: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？',
      },
      {
        title: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？',
        content: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？',
      },
    ],
  ],
};

export const hot = {
  path: '/roamingPlan/Apply',
  label: '依地區搜尋',
  // hotword: [
  //   '亞洲',
  //   '美洲',
  //   '歐洲',
  //   '大洋洲',
  //   '中東',
  //   '非洲',
  // ],
  // active: [
  //   true,
  //   false,
  //   false,
  //   false,
  //   false
  // ],
  hotword: [
    { text: '亞洲', active: true },
    { text: '美洲', active: false },
    { text: '歐洲', active: false },
    { text: '大洋洲', active: false },
    { text: '中東', active: false },
    { text: '非洲', active: false },
  ],
};

export const keyword = {
  defaultKeyword: [
    '巴西 Brasil',
    '巴拉圭 Paraguay',
    '巴貝多 Barbados',
    '巴哈馬 Bahamas',
    '巴拿馬 Panama',
    '巴勒斯坦 Palestine',
  ],
  path: '/roamingPlan/Apply',
  placeholder: '請選擇或輸入目的地',
};

export const menu = {
  tabs: [{ label: '亞洲' }, { label: '美洲' }, { label: '歐洲' }, { label: '非洲' }, { label: '大洋洲 / 其他' }],
  content: [
    {
      title: '亞洲',
      all: [
        '日本 Japan',
        // '韓國 Korea',
        // '中國 China',
        // '泰國 Thailand',
        // '菲律賓 Philippines',
        // '越南 Vietnam',
        // '柬埔寨 Cambodia',
        // '緬甸 Myanmar',
        // '印尼 Country',
        // '印度 Country',
        // '香港 Country',
        // '澳門 Country',
      ],
    },
    {
      title: '美洲',
      all: [
        '美國 U.S.A',
        '加拿大 Canada',
        '墨西哥 Mexico',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
      ],
    },
    {
      title: '歐洲',
      all: [
        '法國 France',
        '德國 Deutschland',
        '英國 ChiBritainna',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
      ],
    },
    {
      title: '非洲',
      all: [
        '南非 South Africa',
        '埃及 Egypt',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
      ],
    },
    {
      title: '大洋洲 / 其他',
      all: [
        '澳洲 Australia',
        '紐西蘭 New Zealand',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
        '國家 Country',
      ],
    },
  ],
};

export const roamingCardMulti = {
  title: '熱門方案',
  desc: '',
  cards: [
    {
      image: '/resources/cbu/roaming/roaming_HongKong_712x400.jpg',
      imgAlt: '',
      title: '香港/澳門漫遊上網 5日 3GB',
      desc: '每日平均只要 60 元，漫遊上網無負擔！需於申購安裝後 30 日內啟用，效期到期前出國啟用即可',
      target: '適用對象：遠傳電信預付卡用戶',
      country: '適用國家：香港、澳門',
      tips: '',
      amount: '',
      price: '$299',
      modalText: '看詳細說明',
      tag: {
        text: '網路限定優惠',
        color: 'red',
      },
      button: {
        text: '立即申請',
        target: '_blank',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/roaming/roaming_Singapore_710x400.jpg',
      imgAlt: '',
      title: '新加坡漫遊上網 5日 3GB',
      desc: '每日平均只要 110 元，漫遊上網無負擔！需於申購安裝後 30 日內啟用，效期到期前出國啟用即可',
      target: '適用對象：遠傳電信預付卡用戶',
      country: '適用國家：新加坡',
      tips: '',
      amount: '',
      price: '$549',
      modalText: '看詳細說明',
      tag: {
        text: '',
        color: '',
      },
      button: {
        text: '立即申請',
        target: '_blank',
        link: '#',
      },
    },
  ],
};

export const roamingCardMultiCountry = {
  title: '熱門漫遊方案',
  desc: '無論是追星、吃美食、買保養品，人在韓國一樣可以漫遊上網與親友即時分享',
  cards: [
    {
      image: '/resources/cbu/roaming/roaming_HongKong_712x400.jpg',
      imgAlt: '',
      title: '韓國漫遊吃到飽',
      desc: '適合於出國需要看影片、打手機遊戲、進行直播，網路用量較多的你',
      target: '遠傳電信月租型用戶',
      country: '韓國',
      tips: '',
      amount: '日付',
      price: '99',
      modalText: '看詳細說明',
      modal: {
        title: '方案詳細說明',
        content: `
          <p class='align-left mt-0'>上網費率</p>
          <div class="fui-table-response mb-2 mb-lg-4">
            <table class="modal-roaming-country-table">
              <thead>
                <tr>
                  <th>用量</th>
                  <th>上網費率</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>不限流量</td>
                  <td>$299 / 天</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="align-left mb-3 notice">
            <p class='is-text-medium mb-1'>申請管道</p>
            <ul class="green-dot-list">
              <li>線上申請：登入遠傳電信會員，透過<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳官網</a>申請</li>
              <li>APP 申請：登入遠傳電信會員，透過<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳行動 APP</a>申請</li>
              <li>電話申請：國內手機直撥 <a href='#' target="_blank" class="is-text-error hover-underline-link">123</a>/<a href='#' target="_blank" class="is-text-error hover-underline-link">888</a>；國外手機直撥<a href='#' target="_blank" class="is-text-error hover-underline-link">+886936010888</a></li>
              <li>門市申請：親自至<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳門市</a>申請</li>
            </ul>
          </div>


          <div class="align-left mb-3 notice">
            <p class='is-text-medium mb-1'>注意事項</p>
            <p class=''>1.本優惠費率以到達當地開始上網起，連續24小時為一日計價。<br/>
            2.適用對象: 至國外漫遊登錄在KT/SKT之遠傳月租型客戶。出國前，請致電客服中心開啟/確認國際數據漫遊服務。手機直撥123/888或 MVPN用戶99888 (免費)；免付費客服專線0800-058-885。市話撥449-5888(市內計費)，在國外時請電+886936010888。</p>
          </div>
        `,
      },
      tag: {
        text: '網路限定優惠',
        color: 'red',
      },
      button: {
        text: '立即申請',
        target: '_blank',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/roaming/roaming_HongKong_712x400.jpg',
      imgAlt: '',
      title: '韓國漫遊計日型',
      desc: '適合於出國需要看影片、打手機遊戲、進行直播，網路用量較多的你',
      target: '遠傳電信月租型用戶',
      country: '韓國',
      tips: '',
      amount: '日付',
      price: '299',
      modalText: '看詳細說明',
      modal: {
        title: '方案詳細說明',
        content: `
          <p class='align-left mt-0'>上網費率</p>
          <div class="fui-table-response mb-2 mb-lg-4">
            <table class="modal-roaming-country-table">
              <thead>
                <tr>
                  <th>用量</th>
                  <th>上網費率</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>不限流量</td>
                  <td>$299 / 天</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="align-left mb-3 notice">
            <p class='is-text-medium mb-1'>申請管道</p>
            <ul class="green-dot-list">
              <li>線上申請：登入遠傳電信會員，透過<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳官網</a>申請</li>
              <li>APP 申請：登入遠傳電信會員，透過<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳行動 APP</a>申請</li>
              <li>電話申請：國內手機直撥 <a href='#' target="_blank" class="is-text-error hover-underline-link">123</a>/<a href='#' target="_blank" class="is-text-error hover-underline-link">888</a>；國外手機直撥<a href='#' target="_blank" class="is-text-error hover-underline-link">+886936010888</a></li>
              <li>門市申請：親自至<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳門市</a>申請</li>
            </ul>
          </div>


          <div class="align-left mb-3 notice">
            <p class='is-text-medium mb-1'>注意事項</p>
            <p class=''>1.本優惠費率以到達當地開始上網起，連續24小時為一日計價。<br/>
            2.適用對象: 至國外漫遊登錄在KT/SKT之遠傳月租型客戶。出國前，請致電客服中心開啟/確認國際數據漫遊服務。手機直撥123/888或 MVPN用戶99888 (免費)；免付費客服專線0800-058-885。市話撥449-5888(市內計費)，在國外時請電+886936010888。</p>
          </div>
        `,
      },
      tag: {
        text: '',
        color: '',
      },
      button: {
        text: '立即申請',
        target: '_blank',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/roaming/roaming_HongKong_712x400.jpg',
      imgAlt: '',
      title: '亞洲輕量包暢用型 2GB',
      desc: '適合於出國僅需瀏覽網頁、地圖搜索、使用Line訊息/通話，網路用量較少的你',
      target: '遠傳電信月租型用戶',
      country: '新加坡、韓國、香港、澳門、馬來西亞、泰國等 13 國',
      tips: '新加坡、韓國、香港、澳門、馬來西亞、泰國等 13 國 ',
      amount: '每2GB',
      price: '298',
      modalText: '看詳細說明',
      modal: {
        title: '方案詳細說明',
        content: `
          <p class='align-left mt-0'>上網費率</p>
          <div class="fui-table-response mb-2 mb-lg-4">
            <table class="modal-roaming-country-table">
              <thead>
                <tr>
                  <th>用量</th>
                  <th>上網費率</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>不限流量</td>
                  <td>$299 / 天</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="align-left mb-3 notice">
            <p class='is-text-medium mb-1'>申請管道</p>
            <ul class="green-dot-list">
              <li>線上申請：登入遠傳電信會員，透過<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳官網</a>申請</li>
              <li>APP 申請：登入遠傳電信會員，透過<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳行動 APP</a>申請</li>
              <li>電話申請：國內手機直撥 <a href='#' target="_blank" class="is-text-error hover-underline-link">123</a>/<a href='#' target="_blank" class="is-text-error hover-underline-link">888</a>；國外手機直撥<a href='#' target="_blank" class="is-text-error hover-underline-link">+886936010888</a></li>
              <li>門市申請：親自至<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳門市</a>申請</li>
            </ul>
          </div>


          <div class="align-left mb-3 notice">
            <p class='is-text-medium mb-1'>注意事項</p>
            <p class=''>1.本優惠費率以到達當地開始上網起，連續24小時為一日計價。<br/>
            2.適用對象: 至國外漫遊登錄在KT/SKT之遠傳月租型客戶。出國前，請致電客服中心開啟/確認國際數據漫遊服務。手機直撥123/888或 MVPN用戶99888 (免費)；免付費客服專線0800-058-885。市話撥449-5888(市內計費)，在國外時請電+886936010888。</p>
          </div>
        `,
      },
      tag: {
        text: '',
        color: '',
      },
      button: {
        text: '立即申請',
        target: '_blank',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/roaming/roaming_HongKong_712x400.jpg',
      imgAlt: '',
      title: '計量7日型數據漫遊優惠費率',
      desc: '適合出國只有偶爾需要聯繫、上網查資訊的你',
      target: '遠傳電信月租型用戶',
      country: '祕魯、巴西、肯亞 、阿拉伯聯合大公國等 12 國',
      tips: '新加坡、韓國、香港、澳門、馬來西亞、泰國等 13 國 ',
      amount: '日付',
      price: '299',
      modalText: '看詳細說明',
      modal: {
        title: '方案詳細說明',
        content: `
          <p class='align-left mt-0'>上網費率</p>
          <div class="fui-table-response mb-2 mb-lg-4">
            <table class="modal-roaming-country-table">
              <thead>
                <tr>
                  <th>用量</th>
                  <th>上網費率</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>不限流量</td>
                  <td>$299 / 天</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="align-left mb-3 notice">
            <p class='is-text-medium mb-1'>申請管道</p>
            <ul class="green-dot-list">
              <li>線上申請：登入遠傳電信會員，透過<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳官網</a>申請</li>
              <li>APP 申請：登入遠傳電信會員，透過<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳行動 APP</a>申請</li>
              <li>電話申請：國內手機直撥 <a href='#' target="_blank" class="is-text-error hover-underline-link">123</a>/<a href='#' target="_blank" class="is-text-error hover-underline-link">888</a>；國外手機直撥<a href='#' target="_blank" class="is-text-error hover-underline-link">+886936010888</a></li>
              <li>門市申請：親自至<a href='#' target="_blank" class="is-text-error hover-underline-link">遠傳門市</a>申請</li>
            </ul>
          </div>


          <div class="align-left mb-3 notice">
            <p class='is-text-medium mb-1'>注意事項</p>
            <p class=''>1.本優惠費率以到達當地開始上網起，連續24小時為一日計價。<br/>
            2.適用對象: 至國外漫遊登錄在KT/SKT之遠傳月租型客戶。出國前，請致電客服中心開啟/確認國際數據漫遊服務。手機直撥123/888或 MVPN用戶99888 (免費)；免付費客服專線0800-058-885。市話撥449-5888(市內計費)，在國外時請電+886936010888。</p>
          </div>
        `,
      },
      tag: {
        text: '',
        color: '',
      },
      button: {
        text: '立即申請',
        target: '_blank',
        link: '#',
      },
    },
  ],
};

export const promotionCard = {
  title: '你的專屬優惠',
  // more: {
  //   link: '#',
  //   text: '更多話題',
  // },
  cards: [
    {
      image: '/resources/cbu/roaming/roaming-card-img-4.jpg',
      title: '遠傳用戶獨享接機優惠價 $799',
      description: '遠傳用戶獨享接機優惠價 $799',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/roaming-card-img-5.jpg',
      title: '遠傳用戶獨享接機優惠價 $799',
      description: '遠傳用戶獨享接機優惠價 $799',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/roaming-card-img-4.jpg',
      title: '遠傳用戶獨享接機優惠價 $799',
      description: '遠傳用戶獨享接機優惠價 $799',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/roaming-card-img-5.jpg',
      title: '遠傳用戶獨享接機優惠價 $799',
      description: '遠傳用戶獨享接機優惠價 $799',
      link: '#',
    },
  ],
};

export const roamingCardAircraft = {
  title: '漫遊方案',
  desc: '在二萬英呎高空上不用擔心無聊沒事做！',
  cards: [
    {
      image: '/resources/cbu/roaming/roaming_aircraft-_710x400.jpg',
      imgAlt: '',
      title: '機上漫遊吃到飽',
      desc: '在機上就像在家一樣，一點都不無聊！上網、追劇、LINE、玩手遊都可以！',
      target: '適用對象：遠傳電信月租型用戶',
      country: '適用航班：請至 <a href="#" class="underline" target="_blank">AeroMobile</a> 官網查詢',
      tips: '',
      amount: '日付',
      price: '$299',
      modalText: '看詳細說明',
      tag: {
        text: '網路限定優惠',
        color: 'red',
      },
      button: {
        text: '立即申請',
        target: '_blank',
        link: '#',
      },
    },
  ],
};

export const sectionProduct = {
  title: '',
  cards: [
    {
      tag: {
        color: 'red',
        text: '網路限定優惠',
      },
      image: '/resources/cbu/roaming/card-sim-7-days-499@2x.jpg',
      title: '日本 7日5GB高速上網',
      lists: [
        { text: '每日不到 $100 不降速吃到飽' },
        { text: '可連續使用 168 小時' },
      ],
      action: {
        text: '前往購買',
        link: '/',
      },
    },
    {
      tag: {
        color: '',
        text: '',
      },
      title: '韓國 5日上網吃到飽',
      image: '/resources/cbu/roaming/card-sim-5-days-599@2x.jpg',
      lists: [
        { text: '每日不到 $100 不降速吃到飽' },
        { text: '可連續使用 168 小時' },
        { text: '使用韓國 KDDI (AU) 網路' },
        { text: '使用韓國 KDDI (AU) 網路' },
      ],
      action: {
        text: '前往購買',
        link: '#',
      },
    },
    {
      tag: {
        color: '',
        text: '',
      },
      title: '日本 7日5GB高速上網',
      image: '/resources/cbu/roaming/card-sim-7-days-759@2x.jpg',
      lists: [
        { text: '每日不到 $100 不降速吃到飽' },
        { text: '可連續使用 168 小時' },
        { text: '使用日本 KDDI (AU) 網路' },
      ],
      action: {
        text: '前往購買',
        link: '/',
      },
    },
    {
      tag: {
        color: '',
        text: '',
      },
      title: '日本 7日5GB高速上網',
      image: '/resources/cbu/roaming/card-sim-5-days-599@2x.jpg',
      lists: [
        { text: '每日不到 $100 不降速吃到飽' },
        { text: '可連續使用 168 小時' },
        { text: '使用日本 KDDI (AU) 網路' },
      ],
      action: {
        text: '前往購買',
        link: '/',
      },
    },
  ],
};

export const sectionFeatureLocalCard = {
  title: '為什麽選擇遠遊卡？',
  color: 'accent',
  column: 3,
  list: [
    {
      title: '最穩定有保障',
      image: '/resources/cbu/roaming/roamingcardfeature-1@2x.png',
      list: [{ text: '使用當地優質網路' }, { text: '產品涵蓋近百國' }],
    },
    {
      title: '隨插即用免設定',
      image: '/resources/cbu/roaming/roamingcardfeature-2@2x.png',
      list: [{ text: '免預約免設定' }, { text: '可分享熱點' }],
    },
    {
      title: '最貼心真人客服',
      image: '/resources/cbu/roaming/roamingcardfeature-3@2x.png',
      list: [{ text: 'LINE@ 真人一對一回覆' }, { text: '全年無休24小時客服' }],
    },
  ],
};

export const travelCard = {
  title: '旅遊專題',
  // more: {
  //   link: '#',
  //   text: '更多話題',
  // },
  cards: [
    {
      image: '/resources/cbu/roaming/roaming-card-img-1@2x.jpg',
      subtitle: '海島度假趣',
      title: '【帛琉】原來離台灣不遠的地方就有天空之鏡！',
      description:
        '出發前我其實完全不知道帛琉的日出這麼美，這根本就是「帛琉版的天空之鏡」了。整片超絕美風景只有我們獨享，超…',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/roaming-card-img-2@2x.jpg',
      subtitle: '夢幻賞櫻季',
      title: '【日本】關西浪漫櫻花秘境，一起賞夜櫻吧！',
      description:
        '出發前我其實完全不知道帛琉的日出這麼美，這根本就是「帛琉版的天空之鏡」了。整片超絕美風景只有我們獨享，超…',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/roaming-card-img-3@2x.jpg',
      subtitle: '小資輕旅行',
      title: '【泰國】五天玩泰國不到 $20,000！教你超實用省...',
      description:
        '出發前我其實完全不知道帛琉的日出這麼美，這根本就是「帛琉版的天空之鏡」了。整片超絕美風景只有我們獨享，超… ',
      link: '#',
    },
    {
      image: '/resources/cbu/roaming/roaming-card-img-3@2x.jpg',
      subtitle: '小資輕旅行',
      title: '【泰國】五天玩泰國不到 $20,000！教你超實用省...',
      description:
        '出發前我其實完全不知道帛琉的日出這麼美，這根本就是「帛琉版的天空之鏡」了。整片超絕美風景只有我們獨享，超… ',
      link: '#',
    },
  ],
};

export const faqNoTab = {
  title: '常見問題',
  collapseList: [
    {
      title: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？',
      content: '目前與遠傳漫遊的國家有哪些？ 通話費如何計算？',
    },
    {
      title: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？',
      content: '促銷方案中，國外數據漫遊日付$99元吃到飽是什麼優惠？和遠傳目前的數據漫遊優惠有何不同？',
    },
    {
      title: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？',
      content: '漫遊上網計日型(指定國家上網吃到飽資費)，需要事先申請嗎 ？',
    },
  ],
};
