import React, { Component } from 'react'
import Button from '../../components/Button';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
class CardRecommend extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div className={`fui-container mt-md-0 mt-4 ${this.props.className ? this.props.className : ''}`}>
        <h2 className="mt-0">{this.props.title}</h2>
        <Slider {...{
          dots: false,
          infinite: false,
          arrows: true,
          slidesToShow: 3,
          draggable: true,
          responsive: [
            {
              breakpoint: 960,
              settings: {
                arrows: true,
                slidesToShow: 1,
                variableWidth: true,
              },
            },

            {
              breakpoint: 768,
              settings: {
                arrows: true,
                slidesToShow: 2,
                variableWidth: true
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: true,
                slidesToShow: 1,
                variableWidth: true
              }
            }
          ],
        }} className='horizontal-cards is-program pb-9'>
          {this.props.program.map((card, idx) => (
            <div key={idx} className='fui-card is-card-program'>
              {card.tag ? <div className={`tag tag-${card.tag.color}`}>{card.tag.text}</div> : null}
              <img src={process.env.PUBLIC_URL + card.icon} alt={card.iconAlt} />
              <h5 className="m-0">{card.name}</h5>
              <h3 className="m-0">{card.title}</h3>
              <ul>
                {card.list.map(item => (
                  <li key={item}>
                    <i className="icon-check is-text-accent" />
                    <div className='subtitle is-text-darkgray50'>{item}</div>
                  </li>
                ))}
              </ul>
              {card.button.length > 1 ? <div className="form-group two-buttons">
                <Button btnStyle='primary' size='medium' link={card.button[0].link}>{card.button[0].title}</Button>
                <Button btnStyle='secondary' size='medium' link={card.button[1].link}>{card.button[1].title}</Button>
              </div> : <div className="form-group m-0">
                  <Button btnStyle='primary' size='medium' className='mb-0' link={card.button[0].link}>{card.button[0].title}</Button>
                </div>}
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
CardRecommend.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  program: PropTypes.array
}
export default CardRecommend;