import React from 'react';
import Item from '../item/Item';
import { Grid } from '@material-ui/core';
import ResponseCollapse from '../collapse/ResponseCollapse';
import PropTypes from 'prop-types';

class ProductMap extends React.Component {
  constructor(props) {
    super(props);
    this.collapse = React.createRef();
    this.state = {
      isEn: false,
      collapseHeight: 256,
      bodyHeight: 0,
      collapse: this.props.isCollapse === true,
    };
  }

  componentDidMount() {
    this.setState({
      bodyHeight: this.collapse.current.children[0].clientHeight,
    });
  }

  componentDidUpdate() {
    if ((typeof window !== 'undefined' && window.$isEn) !== this.state.isEn) {
      this.setState({
        isEn: typeof window !== 'undefined' && window.$isEn,
      });
    }
  }

  toggleCollapse = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  renderItem = (menu) =>
    menu.list.map((item, idx) => (
      <Item key={`prod-map-${idx}`} className='paded' target={'_blank'} icon={'chevron-right'} link={item.link}>
        {item.name}
      </Item>
    ));

  renderColumn = (len) => {
    if (len <= 2) return 6;
    if (len === 3) return 4;
    return 3;
  };

  render() {
    return (
      <section className='product-map' id={this.props.id}>
        <div className='fui-container'>
          <h4 className='align-center'>{this.props.title}</h4>
          <div className={`fui-collapse`}>
            <div
              className='collapse-body'
              ref={this.collapse}
              style={{
                height: this.state.collapse ? this.state.bodyHeight : this.state.collapseHeight,
              }}>
              <Grid container justify={this.props.data.length >= 4 ? 'flex-start' : 'center'}>
                {this.props.data.map((menu, i) => (
                  <Grid item key={i} xs={12} sm={12} md={3} className='p-md-3'>
                    <ResponseCollapse
                      title={menu.title}
                      list={menu.list}
                      open={i === 0 ? true : false}
                      id={`lookforArea-${i + 1}`}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
            {this.state.bodyHeight > 286 ? (
              <div className='collapse-trigger align-center' id={`lookforArea-0`}>
                <button
                  className='fui-button is-text'
                  data-aa-tracking-id={`${!this.state.collapse ? 'Tab_展開' : 'Tab_收合'}`}
                  onClick={(event) => {
                    this.toggleCollapse();
                    // [AA Tracking]
                    if (window && typeof window.ctrf === 'function')
                      window.ctrf(event || {}, `${!this.state.collapse ? 'Tab_展開' : 'Tab_收合'}`);
                  }}>
                  {!this.state.collapse ? (
                    <div>
                      <span className='text'>
                        {this.props.more ? this.props.more.text : this.state.isEn ? 'More' : '展開看更多'}
                      </span>
                      <i className='icon-plus'></i>
                    </div>
                  ) : (
                    <div>
                      <span className='text'>
                        {this.props.less ? this.props.less.text : this.state.isEn ? 'Less' : '展開完成請收合'}
                      </span>
                      <i className='icon-minus'></i>
                    </div>
                  )}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}

ProductMap.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      list: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          link: PropTypes.string,
          target: PropTypes.string,
          icon: PropTypes.string,
        })
      ),
    })
  ),
  more: PropTypes.shape({
    text: PropTypes.string,
    isCollapse: PropTypes.bool,
  }),
};

export default ProductMap;
