import React from 'react';
import Sldier from 'react-slick';
import Button from '../../Button';
import Link from '../../Link';
import PropTypes from 'prop-types';

class LifeCirclePromotion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      sliderSetting: {
        arrows: true,
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
      },
    };
  }

  componentDidMount() {
    let arrowBtns = document.querySelectorAll('.fui-lifecircle-promotion .slick-arrow');
    if (arrowBtns.length > 0 && arrowBtns[0] && arrowBtns[1]) {
      arrowBtns[0].setAttribute('id', 'lifecircle-0');
      arrowBtns[0].setAttribute('data-aa-tracking-id', 'Tab_左');
      arrowBtns[1].setAttribute('id', 'lifecircle-0');
      arrowBtns[1].setAttribute('data-aa-tracking-id', 'Tab_右');

      arrowBtns[0].addEventListener('click', (event) => {
        // [AA Tracking]
        if (window && typeof window.ctrf === 'function') window.ctrf(event || {}, `Tab_左`);
      });
      arrowBtns[1].addEventListener('click', (event) => {
        // [AA Tracking]
        if (window && typeof window.ctrf === 'function') window.ctrf(event || {}, `Tab_右`);
      });
    }
  }

  updateSlider = (oldIndex, newIndex) => {
    this.setState({
      currentSlide: newIndex,
    });
  };

  render() {
    return (
      <section className='fui-lifecircle-promotion'>
        <div className='fui-container' id={`lifecircle-0`}>
          <h2>{this.props.title}</h2>
          <Sldier {...this.state.sliderSetting} beforeChange={this.updateSlider}>
            {this.props.slider.map((slide, i) => (
              <div id={`lifecircle-${i + 1}-1`} key={`lifecircle-${i + 1}-1`}>
                <Link
                  to={slide.action.link}
                  target={slide.action.target}
                  key={`fui-lifecircle-promotion-${i}`}
                  data-aa-tracking-id={`${slide.title}`}>
                  <div className='bg'>
                    <img data-src={slide.image.md} className='d-none d-sm-block lazyload' alt={slide.alt} />
                    <img data-src={slide.image.sm} className='d-block d-sm-none lazyload' alt={slide.alt} />
                  </div>
                  <div className='caption'>
                    <h2>{slide.title}</h2>
                    <h6>{slide.description}</h6>
                    <Button reverse={true} btnStyle='secondary' size='large' data-aa-tracking-id={`${slide.title}`}>
                      {slide.action.text}
                    </Button>
                  </div>
                </Link>
              </div>
            ))}
          </Sldier>
          <div className='fui-lifecycle-icons' style={{ height: 'auto' }}>
            {this.props.slider.map((slide, i) => (
              <div
                key={`lifecircle-slide-${i + 1}-1`}
                style={{
                  display: this.state.currentSlide === i ? 'block' : 'none',
                  height: slide.icons.length === 0 ? '0px' : '150px',
                }}>
                <div
                  className={`lifecycle-icon-container ${this.state.currentSlide === i ? 'fade-in' : ''}`}
                  key={`lifecycle-icon-${i}`}
                  id={`lifecircle-${i + 1}-2`}>
                  {slide.icons.map((item, j) => (
                    <Link
                      to={item.link}
                      target={item.target}
                      key={`lifecycle-icon-${i}-${j}`}
                      className='lifecycle-icon'
                      data-aa-tracking-id={`數位加值服務`}>
                      <div className='icon'>
                        <img className='lazyload' data-src={item.img} alt={item.alt} />
                      </div>
                      <div className='content'>
                        <h6>{item.text}</h6>
                        <div className='body2 is-text-gray100'>{item.description}</div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

LifeCirclePromotion.propTypes = {
  title: PropTypes.string,
  slider: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      alt: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      action: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
        target: PropTypes.string,
      }),
      icons: PropTypes.arrayOf(
        PropTypes.shape({
          img: PropTypes.string,
          alt: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          target: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    })
  ),
};

export default LifeCirclePromotion;
