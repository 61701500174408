import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../components/Button';
import Card from '../../components/card/RoamingCard';

class CardRoamingMulti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      currentModal: {
        title: '',
        content: '',
      },
    };
  }

  openModal = (content) => {
    this.setState({
      modalOpen: true,
      currentModal: content,
    });
  };

  render() {
    const { currentModal } = this.state;
    return (
      <div className='fui-container'>
        {this.props.desc ? (
          <div>
            <h2 className='mt-0 mb-1'>{this.props.title}</h2>
            <h5 className='mt-0 mb-2 is-text-light'>{this.props.desc}</h5>
          </div>
        ) : (
          <h2 className='mt-0'>{this.props.title}</h2>
        )}
        <section className='fui-cards card-roaming-single card-roaming-multi'>
          {this.props.cards.map((card, j) => (
            <Card
              key={`card-roaming-multi-${j}`}
              openModal={this.openModal}
              {...card}
              defaultImg={this.props.defaultImg || ""}
              isAircraft={this.props.isAircraft || false}
            />
          ))}
        </section>
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5 className='align-center'>{currentModal.title}</h5>
            <div dangerouslySetInnerHTML={{__html: currentModal.content}}></div>

            <div className='fui-buttons justify-center'>
              <Button
                btnStyle='primary'
                className=''
                onClick={() => {
                  this.setState({ modalOpen: false });
                }}>
                我瞭解了
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
CardRoamingMulti.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  cards: PropTypes.arrayOf(Card),
};
export default CardRoamingMulti;
