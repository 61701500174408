import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Tab from '@ui/tab/Tab';
import TabPane from '@ui/tab/TabPane';
import Card from '@ui/card/Card';
class SectionTabBannerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSubTab: 0,
      default: this.props.defaultTab || 0
    }
  }
  changeSubTab = value => {
    this.setState({
      currentSubTab: value,
    });
  };
  renderColumn = len => {
    switch (len) {
      case 1:
      case 2:
        return 'tow-card';
      case 3:
        return 'three-card';

      default:
        return 'four-card';
    }
  };
  render() {
    return (
      <section className='five-g' id={this.props.id}>
        <div className='tab-content tab-ebu'>
          <div className='fui-container'>
            <div dangerouslySetInnerHTML={{ __html: this.props.title || "" }}></div>
            <Tab {...this.props.tabs} default={this.state.default} onChange={e => this.changeSubTab(e)} />
            {this.props.tabContent && this.props.tabContent.length ? this.props.tabContent.map((child, j) => (
              < TabPane
                active={this.state.currentSubTab === j}
                key={`${this.props.tabs.name}-${j}-cards`}>
                {
                  <div
                    className={`fui-cards no-scrollbar ${this.renderColumn(child.cards.length)}`}
                    style={{ backgroundImage: `url(${child.background})` }}>
                    <div className='tab-content-wrapper'>
                      <h2 className='is-text-white'>{child.slideTitle || ""}</h2>
                      <p className='is-text-white'>{child.slideContent || ""}</p>
                    </div>
                    <div className={`five-g-card ${child.cards.length == 1 ? 'single-card' : ''}`}>
                      {child.cards && child.cards.length ? child.cards.map((card, idx) => (
                        <Card
                          key={`${this.props.tabs}-${j}-card-${idx}`}
                          {...card}
                          style={{ width: 270 }}
                        />
                      )) : null}
                    </div>
                  </div>
                }
              </TabPane>
            )) : null}
          </div>
        </div>
      </section>
    );
  }
}
SectionTabBannerCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  tabs: PropTypes.shape({
    name: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        title: PropTypes.string,
        label: PropTypes.string
      })
    ),
  }),
  tabContent: PropTypes.arrayOf(
    PropTypes.shape({
      background: PropTypes.string,
      slideTitle: PropTypes.string,
      slideContent: PropTypes.string,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          meta: PropTypes.string,
          description: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    })
  )
}
export default SectionTabBannerCard;