import React, { Component } from 'react'
import NavAnchor from '../../components/partials/NavAnchor';
import DevicePlanTable from '../../components/partials/DevicePlanTable';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import QuoteBanner from '../../components/partials/QuoteBanner';
import AnchorDetect from '../../components/AnchorDetect';
import CardPlanBasic from '../../components/card/CardPlanBasic';
import CardPhonePlan from '../../components/card/CardPhonePlan';
import OnVisible, { setDefaultProps } from 'react-on-visible';
import BannerPromotionBasic from '../../components/partials/banner/BannerPromotionBasic';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import SocialMedia from '../../components/SocialMedia';
import CardRecommend from '../../components/partials/CardRecommend';
import * as formatNumber from '../../utils/numberFormatter';
import axios from 'axios';
import * as Mock from '../../mock/Exclusive';
let PlanMock = null
class PromotionPeolpe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      program: [
        {
          title: '宅在家，微上網',
          list: [
            '上網4GB',
            '網內30分鐘',
            '網外30分鐘',
            '市話40分鐘'
          ],
          unit: '月租',
          price: '999',
          tag: {
            text: '熱門方案',
            color: 'red'
          },
          button: [
            {
              title: '申辦方案',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          title: '吃到飽，小寒暄',
          unit: '月租',
          price: '999',
          list: [
            '上網吃到飽不限速',
            '網內40分鐘',
            '網外40分鐘',
            '市話60分鐘'
          ],
          button: [
            {
              title: '申辦方案',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          title: '吃到飽，暢快聊',
          unit: '月租',
          price: '999',
          list: [
            '上網吃到飽不限速',
            '送$網內免費',
            '網外80分鐘',
            '市話100分鐘'
          ],
          button: [
            {
              title: '申辦方案',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          title: '吃到飽，暢快聊',
          unit: '月租',
          price: '999',
          list: [
            '上網吃到飽不限速',
            '送$網內免費',
            '網外80分鐘',
            '市話100分鐘'
          ],
          button: [
            {
              title: '申辦方案',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          title: '吃到飽，暢快聊',
          unit: '月租',
          price: '999',
          list: [
            '上網吃到飽不限速',
            '送$網內免費',
            '網外80分鐘',
            '市話100分鐘'
          ],
          button: [
            {
              title: '申辦方案',
              link: '#',
              target: '_self'
            }
          ]
        },
      ],
      hiddenProgram: [
        {
          icon: '/resources/cbu/img/cbu-promotion-plan-1@2x.jpg',
          iconAlt: 'program1',
          name: 'Apple AirPods',
          title: '０元拿耳機',
          list: [
            '吃到飽不降速',
            '每月網外送200分鐘'
          ],
          tag: {
            text: '最佳續約',
            color: 'blue'
          },
          button: [
            {
              title: '了解更多',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          icon: '/resources/cbu/img/cbu-promotion-plan-2@2x.jpg',
          iconAlt: 'program1',
          name: 'Apple AirPods Pro',
          title: '新耳機',
          list: [
            '吃到飽不降速',
            '每月網外送200分鐘'
          ],
          tag: {
            text: '最佳續約',
            color: 'blue'
          },
          button: [
            {
              title: '了解更多',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          icon: '/resources/cbu/img/cbu-promotion-plan-3@2x.png',
          iconAlt: 'program1',
          name: 'iPhoneSE 64GB ',
          title: '學生iPhoneSE方案',
          list: [
            '吃到飽不降速',
            '每月網外送200分鐘'
          ],
          tag: {
            text: '最熱門',
            color: 'red'
          },
          button: [
            {
              title: '了解更多',
              link: '#',
              target: '_self'
            }
          ]
        },
        {
          icon: '/resources/cbu/img/cbu-promotion-plan-3@2x.png',
          iconAlt: 'program1',
          name: 'iPhone11 ',
          title: '好禮1+1 輕鬆帶走AirPods',
          list: [
            '搭配無限充電盒',
            '會員續約再折$5,000'
          ],
          tag: {
            text: '最熱門',
            color: 'red'
          },
          button: [
            {
              title: '了解更多',
              link: '#',
              target: '_self'
            }
          ]
        },
      ],
      rental: [
        {
          title: '宅在家，微上網',
          img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
          imgAlt: '',
          price: '月付<span class="">$1,399</span><br/>專案價<span class="is-text-error">$0</span>',
          brand: 'SAMSUNG',
          name: 'SAMSUNG Galaxy A20',
          hottag: {
            text: '0元拿手機',
            color: 'red'
          },
          tag: ['福利A品'],
        },
        {
          title: '吃到飽，小寒暄',
          img: '/resources/cbu/img/cbu-promotion-product-1@2x.jpg',
          imgAlt: '',
          price: '專案價 <span class="is-text-error">$0</span>',
          brand: 'Xiaomi',
          name: '紅米 NOTE 8T 64GB',
          hottag: {
            text: '0元拿手機',
            color: 'red'
          },
          tag: [],
        },
        {
          title: '吃到飽，暢快聊',
          img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
          imgAlt: '',
          unit: '專案價',
          price: '<span>專案價</span><div class="price is-text-error">$9,500</div>',
          brand: 'APPLE',
          name: 'iPhone SE 64G',
          hottag: {
            text: '熱門方案',
            color: 'red'
          },
          tag: [],
        },
        {
          title: '吃到飽，暢快聊',
          img: '/resources/cbu/img/pms-1569413376-36787289@2x.jpg',
          imgAlt: '',
          unit: '專案價',
          price: '<span class="label">商品價</span><del>$5,600</del><b class="price is-text-error">$10,500</b></div>',
          brand: 'SAMSUNG',
          name: 'SAMSUNG Galaxy A20',
          hottag: {
            text: '熱門方案',
            color: 'red'
          },
          tag: [],
        },
      ],
      selectedPlan: {
        banner: {
          image: { md: '', sm: '' }, title: '', description: '', actions: [{ text: '', link: '' }]
        },
        promotionCard: { title: '', cards: [] },
        parentPromotion: { image: { md: '', sm: '' }, title: '', description: '', content: '', actions: { ios: { image: { md: '', sm: '' } }, android: { image: { md: '', sm: '' } } } }
      },
    }
    this.getExclusiveData().then(result => {
      PlanMock = result;
      this.setExclusiveData();
    });
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  getExclusiveData = (val) => {
    return axios('/json/people-plan.json').then(result => {
      return JSON.parse(JSON.stringify(result.data));
    })
  }
  onChange(name, value) {
    // console.log({ name, value });
    let form = Object.assign(this.state.form);
    form[name].value = value;
    this.setState({
      form: form,
    });
  }
  disableButton(model, resetForm, invalidateForm) {
    // debugger
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        if (window.isIE) {
          let elem = window || document.documentElement
          elem.scroll(0, document.getElementsByClassName('is-invalid')[0].offsetTop);
        } else
          window.scrollTo(0, document.getElementsByClassName('is-invalid')[0].offsetTop);
      }, 100);
    }
    this.setState({ canSubmit: false });
  }

  enableButton(model, resetForm, invalidateForm) {
    // debugger
    this.setState({ canSubmit: true });
  }
  // 根據DevicePlanTable的選擇來打API //
  tableOnChange = (val) => {
    this.getExclusiveData(val).then(result => {
      PlanMock = result;
      this.setExclusiveData();
    });
  }
  filtering = (val) => {
    if (!!val.filter.value) {
      let filterArr = []
      val.filter.value.split(",").map((key) => {
        filterArr.push(val.arr[key - 1].label)
      })
      let active = []
      let second = []
      let final = []
      val.tabContent.forEach((plan) => {
        filterArr.map((filter) => {
          // console.log(plan);
          if (plan.name.indexOf(filter) > -1) {
            active.push(plan)
          } else {
            second.push(plan)
          }
        })
      })
      // console.log({ active, second });

      final = [...active, ...second]

      var result = new Set();
      var repeat = new Set();
      final.forEach(item => {
        result.has(item) ? repeat.add(item) : result.add(item);
      })
      final = [...result]
      // console.log(final);


      let planTab = [];
      let activePlan = 0;
      let activeTab = 0;
      let activeNum = 0;
      let activeNav = this.props.match.params.plan ? PlanMock.navTab.find((tab, i) => {
        if (this.props.location.pathname === tab.name) {
          activeNum = i
          return tab;
        }
      }) : PlanMock.navTab[0];

      // console.log(`this.props.location.pathname: `, this.props.location.pathname);
      // console.log(`activeNav: `, activeNav);

      PlanMock.plan.forEach((plan, i) => {
        if (this.props.match.params.plan === plan.title) {
          activePlan = i
        }
        // BannerTab
        // PlanTab
        PlanMock.planTabs.tabs.forEach((tab, k) => {
          if (!planTab[k]) planTab[k] = [];
          tab.child.forEach(ch => {
            if (activeNav.name === ch.name) {
              activeTab = k
            }
            if (ch.name === plan.name) {
              planTab[k].push(plan)
            }
          })
        });
      })
      planTab[val.index] = final

      this.setState({
        query: this.props.match.params.plan || '',
        defaultTab: activeNum,
        navTabs: PlanMock.navTab,
        selectedPlan: PlanMock.plan[activePlan],
        planTabs: {
          title: PlanMock.planTabs.title,
          tableTitle: PlanMock.planTabs.tableTitle,
          tabs: {
            scroll: true,
            list: PlanMock.planTabs.tabs.map((tab, i) => {
              if (i === val.index) {
                return {
                  label: tab.name,
                  filterArr: tab.filterArr,
                  filterName: tab.filterName,
                  pickPlan: filterArr,
                }
              } else {
                return {
                  label: tab.name,
                  filterArr: tab.filterArr,
                  filterName: tab.filterName,
                  pickPlan: tab.pickPlan,
                }
              }
            }),
            default: activeTab,
          },
          tabContent: planTab
        },
      })
      this.forceUpdate()
    } else {
      this.resetFilterContent();
    }
  }

  setExclusiveData = () => {
    let planTab = [];
    let activePlan = 0;
    let activeTab = 0;
    let activeNum = 0;
    let activeNav = this.props.match.params.plan ? PlanMock.navTab.find((tab, i) => {
      if (this.props.location.pathname === tab.name) {
        activeNum = i
        return tab;
      }
    }) : PlanMock.navTab[0];

    PlanMock.plan.forEach((plan, i) => {
      if (this.props.match.params.plan === plan.title) {
        activePlan = i
      }
      // BannerTab
      // PlanTab
      PlanMock.planTabs.tabs.forEach((tab, k) => {
        if (!planTab[k]) planTab[k] = [];
        tab.child.forEach(ch => {
          if (activeNav.name === ch.name) {
            activeTab = k
          }
          if (ch.name === plan.name) {
            planTab[k].push(plan)
          }
        })
      });
    })
    this.setState({
      query: this.props.match.params.plan || '',
      defaultTab: activeNum,
      navTabs: PlanMock.navTab,
      selectedPlan: PlanMock.plan[activePlan],
      planTabs: {
        title: PlanMock.planTabs.title,
        tableTitle: PlanMock.planTabs.tableTitle,
        tabs: {
          scroll: true,
          list: PlanMock.planTabs.tabs.map(tab => {
            return {
              label: tab.name,
              filterArr: tab.filterArr,
              filterName: tab.filterName,
              pickPlan: tab.pickPlan,
            }
          }),
          default: activeTab,
        },
        tabContent: planTab
      },
    })

    this.forceUpdate()
    // console.log("updated");
  }
  spec = (item, idx, e) => {
    console.log({ item, idx, e });
  }

  resetFilterContent = () => {
    this.setExclusiveData();
    this.forceUpdate()
  }
  render() {
    return (
      <main className='life-circle article'>
        <NavAnchor
          pageTitle='學生青春無價'
        />
        <AnchorDetect className="vertical-anchor-nav" items={['anchor0', 'anchor1', 'anchor2', 'anchor3', 'anchor4']} activeClass="active" offsetTop={145}>
          <li><span>效率換娛樂</span></li>
          <li><span>寵i機會</span></li>
          <li><span>超值方案</span></li>
          <li><span>隱藏菜單</span></li>
          <li><span>1加1</span></li>
        </AnchorDetect>
        <OnVisible id="anchor0">
          <BannerPromotionBasic
            className="is-promotion"
            slides={[
              {
                image: {
                  md: '/resources/cbu/img/cbu-promotion-students-1920x500.jpg',
                  sm: '/resources/cbu/img/cbu-promotion-students-750x1000.png'
                },
                tag: null,
                title: '<div class="mt-7">學生聰明享受過，一刻都不錯過</div>',
                description: '盡情暢聊、飆網、拿夯機，享受極致青春',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '立即申辦',
                    link: '#'
                  }
                ]
              }
            ]}
            openModal={this.openModal}
          />
        </OnVisible>
        <QuoteBanner
          className="is-white"
          bgImage=''
          bgColor="#fff"
          content={`<h2 class='with-comma m-0 is-text-black100'>
              <span class="border-bottom">遠傳好品質網路</span>，讓我擁有更多娛樂時間
            </h2>`}
        />
        <SectionBanner2
          position='center'
          className="is-500 is-prmotion-banner top-0 content-right"
          image={{
            md: '/resources/cbu/img/cbu-feature-scenario-left-1920x500.jpg',
            sm: '/resources/cbu/img/cbu-feature-scenario-left-750x984.jpg',
          }}
          title='
          <div class="mb-2">
            <h3 class="">
              準時搶課、交作業
            </h3>
            <p class="body mb-md-5 mb-1">快又穩定的網速，幫你爭取時間</p>
            <h3 class="">
              電子帳單五大好處
            </h3>
            <p class="body mb-md-5 mb-1">分享給筆電，在外討論、唸書真方便</p>
            <h3 class="">
              順暢的遠端作業討論
            </h3>
            <p class="body mb-md-5 mb-1">在家唸書或上課，都能好好學習</p>
          </div>'
          action={null}
        />

        <OnVisible id="anchor1">
          <CardPlanBasic
            title="還是學生的你，不用支付太多"
            className="pt-5"
            list={this.state.program}
          />
        </OnVisible>
        <QuoteBanner
          className="mt-md-0 pt-0 is-white"
          bgImage=''
          bgColor="#fff"
          content={`<h2 class='with-comma m-0 is-text-black100'>
          用<span class="border-bottom">10杯珍奶價錢</span>的月租費，就能拿到喜歡的新手機
            </h2>`}
        />
        <OnVisible id="anchor2">
          <SectionBanner2
            position='center top'
            className="is-500 is-prmotion-banner top-0 mb-1"
            image={{
              md: '/resources/cbu/img/cbu-feature-scenario-right-1920x500.jpg',
              sm: '/resources/cbu/img/cbu-feature-scenario-right-750x984.jpg',
            }}
            title='
          <div class="mb-2">
            <h3 class="">
              低資費也能換手機
            </h3>
            <p class="body mb-md-5 mb-1">各家品牌供你任選</p>
            <h3 class="">
              買手機安心保固
            </h3>
            <p class="body mb-md-5 mb-1">全省遠傳門市都可送修</p>
            <h3 class="">
              資費+$100送3C
            </h3>
            <p class="body mb-md-5 mb-1">資費+$100送3C</p>
          </div>'
            action={null}
          />
        </OnVisible>
        <OnVisible id="anchor3">
          <CardPhonePlan
            className="mb-1"
            title="688月租費搭手機"
            list={this.state.rental}
          />
        </OnVisible>
        <OnVisible id="anchor4">
          {!PlanMock ? null : <DevicePlanTable
            filterTitle="月租費"
            {...this.state.planTabs}
            filterArr={this.state.filterArr}
            resetFilterContent={this.resetFilterContent}
            programList={
              [
                { value: '388', text: '388', label: '388' },
                { value: '488', text: '488', label: '488' },
                { value: '599', text: '599', label: '599' },
                { value: '699', text: '699', label: '699' },
              ]
            }
            selected={this.state.selectedPlan}
            filtering={(val) => { this.filtering(val) }}
            onChange={(val) => this.tableOnChange(val)}
          />}
        </OnVisible>
        <OnVisible id="anchor5">
          <CardRecommend
            program={this.state.hiddenProgram}
            title="看看網路版隱藏菜單"
          />
        </OnVisible>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
              <ol>
                <li>此功能不需帳號及密碼，只要輸入身分資料完成登入檢核就可進行繳費。</li>
                <li>快速登入後僅能進行繳費功能，若需使用其他功能，請先進行登入。</li>
                <li>繳納費用非一般性消費支出，請洽各發卡銀行確認是否有刷卡紅利或現金回饋。</li>
              </ol>
            `}
        />
        <div className="is-bg-lightgray50 pb-md-10 pb-6 pt-1">
          <div className="fui-container">
            <SocialMedia fbLink="#" lineLink="#" size={48} displayContent="分享遠傳優惠" />
          </div>
        </div>
      </main>
    );
  }
}

export default PromotionPeolpe;