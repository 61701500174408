import React from 'react';
import PropTypes from 'prop-types';

const Switch = (props) => {
  const [checked, setChecked] = React.useState(props.checked ? props.checked : false);

  React.useEffect(() => {
    setChecked(props.checked ? props.checked : false)
  }, [props.checked]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.onChange(event, event.target.checked);
  };

  return (
    <label className='fui-switch' id={props.id}>
      <input type='checkbox' name={props.name} value='1' checked={checked} onChange={handleChange} />
      <div className='content' 
          data-aa-tracking-id={`個人/商務`}
          onClick={(event) => {
            if (window && typeof window.ctrf === "function")
              window.ctrf(event || {}, `個人/商務`);
          }}>
        <span className='off'>{props.off}</span>
        <span className='on'>{props.on}</span>
      </div>
    </label>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  on: PropTypes.string.isRequired,
  off: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default Switch;
