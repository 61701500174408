import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link'

class ArticleAuthorSimplePanel extends React.Component {

    render() {
        // console.log(this.props);
        return (
            <div className={`fui-panel article-author-tags-panel article-author-simple-panel ${this.props.className}`}>
                <div className='fui-container fui-container-article'>
                    <div className='border-author'>
                        {this.props.borderAuthor1}
                        <br />
                        {this.props.borderAuthor2}
                    </div>
                    {/* <div class='tags'>
                        {
                            this.props.tags.map((item, i) => (
                                <Link to={item.url} key={i} class='item'>{item.title}</Link>
                            ))
                        }
                    </div> */}
                </div>
            </div>
        )
    }
}

ArticleAuthorSimplePanel.propTypes = {
    borderAuthor1: PropTypes.string,
    borderAuthor2: PropTypes.string,
    // tags: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         title: PropTypes.string,
    //         url: PropTypes.string
    //     })
    // )
}

export default ArticleAuthorSimplePanel;