import React, { Component } from 'react'
import Button from '../../components/Button';
import PropTypes from 'prop-types';
class CardRoamingSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <section className='card-roaming-single'>
        <div className='fui-container'>
          <h2 className="mt-0">{this.props.title}</h2>
          <div className='fui-card'>
            {this.props.hottag ? <div className={`fui-tag fui-tag-${this.props.hottag.color}`}>{this.props.hottag.text}</div> : null}
            <div className='fui-card-image'>
              <img className='lazyload' data-src={process.env.PUBLIC_URL + this.props.image} src={process.env.PUBLIC_URL + this.props.image} alt={this.props.imgAlt} />
            </div>
            <div className='fui-card-caption'>
              <div className="fui-card-meta is-text-darkgray50">{this.props.meta}</div>
              <h3 className="">
                {this.props.name}
              </h3>
              <div className='fui-card-price' dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
            </div>
            <div className="fui-card-action">
              <div className="d-block align-right">
                <small className="d-inline-block">{this.props.coupon.name}</small>
                <h1 className="d-inline-block is-text-error">{this.props.coupon.code}</h1>
              </div>
              <Button btnStyle='primary' className='m-0 w-100' link={this.props.button.link} target={this.props.button.target}>{this.props.button.name}</Button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
CardRoamingSingle.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  imgAlt: PropTypes.string,
  price: PropTypes.string,
  meta: PropTypes.string,
  coupon: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  button: PropTypes.PropTypes.shape({
    name: PropTypes.string,
    target: PropTypes.string,
    link: PropTypes.string,
  }),
  hottag: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
  }),
}
export default CardRoamingSingle;