import React from 'react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import RadioGroup from '../../components/form/RadioGroup';
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import * as Validation from '../../utils/validation';


class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: 'id',
      step: 1,
      step2Validation: false,
      ismail: [
        {
          value: '0',
          label: '是',
        },
        {
          value: '1',
          label: '否',
        },
      ],
      form: {
        setting: { value: 'false', required: false },
        status: { value: 'true', required: false },
        id_number: { value: '', required: false },
        fetnetAccount: { value: '', required: true },
      },
      canSubmit: false
    }
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm,
    });
  };
  defaultFormOnChange = (name, value) => {
    let result = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    console.log(result);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = result;
    this.setState({
      form: newForm,
    });
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="VoLTE設定"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="usage-rate pt-md-7 pt-4 no-bg">
          <div className="fui-container">
            <div className="fui-segments no-shadow is-bg-initial">
              {this.state.step == 1 ? <div>
                <h4 className="is-text-darkgray50 mb-md-8 mb-4">
                  透過遠傳4.5G傳送語音服務，給您〝聲〞歷其境的極致享受
                </h4>
                <div className="paper mt-md-4 mt-2 mb-2 pb-md-7 pb-4">
                  <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-2">
                    <h4 className='m-0 is-text-medium'>設定須知</h4>
                  </div>
                  <ol className="is-text-medium mb-0 order-list">
                    <li><span className="decimal">1</span>遠傳VoLTE月租費30元，通話費計算方式同用戶現行資費及促案方案，同享資費及促案本身通話優惠，通話產生的傳輸量亦不計費。</li>
                    <li><span className="decimal">2</span>以下為遠傳VoLTE通話條件，若缺一條件則以一般音質通話。VoLTE音質亦受發話雙方4G收訊強弱影響。<br />
                      - 受發話雙方皆成功開通遠傳VoLTE服務。<br />
                      - 受發話雙方皆使用支援遠傳VoLTE的手機並已升級至支援版本，且手機設定開啟VoLTE功能。<br />
                      - 受發話雙方皆在遠傳4G網路環境下。
                    </li>
                    <li><span className="decimal">3</span>目前僅限網內且符合VoLTE通話所有條件下可享VoLTE音質，撥打2G、3G網內/網外/市話將以一般音質進行，尚無法提供遠傳VoLTE服務。</li>
                  </ol>
                </div>
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                  <div className="paper pt-md-7 pt-4 pb-md-6 pb-2">
                    <RadioGroup
                      label='目前設定'
                      name="setting"
                      className='mb-0'
                      options={[
                        { label: '開啟', value: 'true' },
                        { label: '關閉', value: 'false' }
                      ]}
                      default={this.state.form.setting.value}
                      onChange={this.onChange}
                    />
                  </div>
                </Formsy>
                {!this.state.step2Validation ? <div className='page-action'><Button
                  onClick={() => this.setState({ step2Validation: true })}
                  link="#"
                  className='mb-lg-0 mb-md-2 mb-0'
                  btnStyle='primary'
                  size='large'>
                  下一步
                  </Button></div> : null}

                {this.state.step2Validation ? <div className="mt-7">
                  <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                    {this.state.accountType == 'id' ? <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <h4 className='mb-3'>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <LabelInput
                          className="mb-2"
                          name='id_number'
                          required={this.state.form.id_number.required}
                          label='證照號碼'
                          validations={{
                            idNumberValid: Validation.idNumberValid
                          }}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入證照號碼'
                          }}
                          placeholder='請輸入證照號碼'
                          value={this.state.form.id_number.value}
                          onChange={this.onChange}
                        />
                        <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                          身分說明：<br />
                          個人戶：身分證字號<br />
                          公司戶：統一編號或稅籍編號<br />
                          外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                        </p>
                        <div className='page-action'><Button onClick={() => { this.props.history.push('/VoLTE/success') }} link="#" className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>確認</Button></div>
                      </Grid>
                    </Grid> : <Grid container spacing={2}><Grid item xs={12} md={12}>
                      <h4 className='mb-3'>為保護您個人資料的安全，請輸入遠傳帳戶密碼進行確認</h4>
                    </Grid>
                        <Grid item xs={12} md={6}>
                          <LabelInput
                            className="mb-2"
                            name='fetnetAccount'
                            required={this.state.form.fetnetAccount.required}
                            label='帳戶密碼(遠傳通關密碼)'
                            validations="isLength:8"
                            validationErrors='請輸入密碼'
                            placeholder='請輸入密碼'
                            value={this.state.form.fetnetAccount.value}
                            onChange={this.onChange}
                          />
                          <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                            帳戶密碼說明：<br />
                            1.帳戶密碼為您在遠傳所設置的帳戶授權密碼，亦稱通關密碼。<br />
                            2.如您欲變更密碼或查詢密碼，請洽客服中心或立即使用客服即時通。
                          </p>
                          <div className='page-action'><Button onClick={() => { this.props.history.push('/VoLTE/success') }} link="#" className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>確認</Button></div>
                        </Grid>
                      </Grid>}
                  </Formsy>
                </div> : null}
              </div> : null}
            </div>
          </div>
        </section>
        <SectionCollapseInfo
          className=""
          title="注意事項"
          content={`
                <ol>
                  <li>4G資費月租費在1,199元以上的用戶，提供減免VoLTE月租費之優惠，若將4G資費月租費降轉至1,199以下，優惠即終止。<li/>
                  <li>遠傳VoLTE服務目前無法與900來電答鈴、上網免費體驗方案同時使用及提供企業MPVN客戶申請(GMVPN客戶除外)，亦暫不支援影像電話服務。</li>
                  <li>已申請VoLTE語音服務之客戶，將無法透過遠傳服務人員或是手機自行關閉「話中插接與保留」功能。</li>
                  <li>成功開通或關閉遠傳VoLTE，系統將自動發送簡訊確認。</li>
                  <li>遠傳保留活動修改、終止或暫停優惠之權利，更多詳情請參考遠傳官方網站公告。</li>
                </ol>
              `}
        />
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ accountType: 'id' })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.4.8.3_漫遊與門號服務_行動上網_輸入身分證號</Button>
              <Button onClick={() => this.setState({ accountType: 'fetnet' })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.4.8.2_漫遊與門號服務_行動上網_輸入遠傳帳密</Button>
            </div>
          </div>
        </div>
      </main >
    )
  }
}

export default Setting;