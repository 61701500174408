import React from 'react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import RadioGroup from '../../components/form/RadioGroup';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import * as Validation from '../../utils/validation';


class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: 'id',
      step: 1,
      step2Validation: false,
      ismail: [
        {
          value: '0',
          label: '是',
        },
        {
          value: '1',
          label: '否',
        },
      ],
      form: {
        setting: { value: 'false', required: false },
        ringPlan: { value: 'true', required: false },
        id_number: { value: '', required: false },
        fetnetAccount: { value: '', required: true },
      },
      canSubmit: false
    }
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm,
    });
  };
  defaultFormOnChange = (name, value) => {
    let result = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    console.log(result);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = result;
    this.setState({
      form: newForm,
    });
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="來電答鈴設定"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="usage-rate pt-md-7 pt-4 no-bg">
          <div className="fui-container">
            <div className="fui-segments no-shadow is-bg-initial">
              {this.state.step == 1 ? <div>
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                  <div className="paper pt-md-7 pt-4 pb-md-6 pb-2 mb-0 border--bottom">
                    <RadioGroup
                      label='目前設定'
                      name="setting"
                      className='mb-0'
                      options={[
                        {
                          label: '開啟',
                          value: 'true'
                        },
                        {
                          label: '關閉',
                          value: 'false'
                        }
                      ]}
                      default={this.state.form.setting.value}
                      onChange={this.onChange}
                    />
                    {this.state.form.setting.value == 'true' ? <RadioGroup
                      label='請選擇來電答鈴方案'
                      name="ringPlan"
                      className='mb-0 is-two mt-7'
                      options={[
                        {
                          label: `<h5 class="align-center is-text-black100 mt-3 mb-0">900來電答鈴30月租費</h5>
                                  <p class="body mt-1 mb-md-4 mb-0">開通後，將提供三首為您精心挑選鈴聲做為系統預設鈴聲。於每月帳單內收取30元服務費用。</p>`,
                          value: 'true',
                          icon: {
                            default: "/resources/cbu/img/ic-plan-30-unselected@2x.png",
                            selected: "/resources/cbu/img/ic-plan-30-selected@2x.png",
                          }
                        },
                        {
                          label: `<h5 class="align-center is-text-black100 mt-3 mb-0">900來電答鈴69超值包</h5>
                                  <p class="body mt-1 mb-md-4 mb-0">享有來電答鈴豪華型月租費30元；精選音樂盒50元，超值優惠價69元(原價80元)，每月自動更換強打新歌。於每月帳單內收取69元服務費用。</p>`,
                          value: 'false',
                          icon: {
                            default: "/resources/cbu/img/ic-plan-69-unselected@2x.png",
                            selected: "/resources/cbu/img/ic-plan-69-selected@2x.png",
                          }
                        }
                      ]}
                      default={this.state.form.ringPlan.value}
                      onChange={this.onChange}
                    /> : null}
                  </div>

                </Formsy>

                {!this.state.step2Validation ? <div className='page-action'><Button
                  onClick={() => this.setState({ step2Validation: true })}
                  link="#"
                  className='mb-lg-0 mb-md-2 mb-0'
                  btnStyle='primary'
                  size='large'>
                  下一步
                  </Button></div> : null}

                {this.state.step2Validation ? <div className="mt-7">
                  <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                    {this.state.accountType == 'id' ? <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <h4 className='mb-3'>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <LabelInput
                          className="mb-2"
                          name='id_number'
                          required={this.state.form.id_number.required}
                          label='證照號碼'
                          validations={{
                            idNumberValid: Validation.idNumberValid
                          }}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入證照號碼'
                          }}
                          placeholder='請輸入證照號碼'
                          value={this.state.form.id_number.value}
                          onChange={this.onChange}
                        />
                        <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                          身分說明：<br />
                          個人戶：身分證字號<br />
                          公司戶：統一編號或稅籍編號<br />
                          外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                        </p>
                        <div className='page-action'><Button onClick={() => { this.props.history.push('/VoLTE/success') }} link="#" className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>確認</Button></div>
                      </Grid>
                    </Grid> : <Grid container spacing={2}><Grid item xs={12} md={12}>
                      <h4 className='mb-3'>為保護您個人資料的安全，請輸入遠傳帳戶密碼進行確認</h4>
                    </Grid>
                        <Grid item xs={12} md={6}>
                          <LabelInput
                            className="mb-2"
                            name='fetnetAccount'
                            required={this.state.form.fetnetAccount.required}
                            label='帳戶密碼(遠傳通關密碼)'
                            validations="isLength:8"
                            validationErrors='請輸入密碼'
                            placeholder='請輸入密碼'
                            value={this.state.form.fetnetAccount.value}
                            onChange={this.onChange}
                          />
                          <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                            帳戶密碼說明：<br />
                            1.帳戶密碼為您在遠傳所設置的帳戶授權密碼，亦稱通關密碼。<br />
                            2.如您欲變更密碼或查詢密碼，請洽客服中心或立即使用客服即時通。
                          </p>
                          <div className='page-action'><Button onClick={() => { this.props.history.push('/VoLTE/success') }} link="#" className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>確認</Button></div>
                        </Grid>
                      </Grid>}
                  </Formsy>
                </div> : null}
              </div> : null}
            </div>
          </div>
        </section>
        <SectionCollapseInfo
          className=""
          title="注意事項"
          content={`
                <ol>
                  <li>月租費依照所申裝之方案類型不同(30元及69元)，使用不足一個月按比例計算。</li>
                  <li>900來電答鈴用戶申裝超值包服務，系統會更換為用戶選擇的音樂盒。</li>
                  <li>答鈴下載每首15元。</li>
                  <li>更改回系統預設鈴聲每次30元。</li>
                </ol>
                <div class="body-1 is-text-medium mt-6 mb-3">貼心小叮嚀</div>
                <ol>
                  <li>申裝900來電答鈴服務成功後，即可以享有0元任您下載免費專區的歌曲。</li>
                  <li>69超值包若仍有合約，無法透過網頁取消，請洽客服人員。</li>
                  <li>來電答鈴69超值包再送免費好禮優惠活動需簽約12個月，贈品以實物為主，詳細活動內容及辦法，請上900.fetnet.net，遠傳保有隨時修改或終止本活動之權利。</li>
                </ol>
              `}
        />
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ accountType: 'id' })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.4.6.4_漫遊與門號服務_來電答鈴_輸入驗證碼_身份証</Button>
              <Button onClick={() => this.setState({ accountType: 'fetnet' })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.4.6.3_漫遊與門號服務_來電答鈴_輸入驗證碼_帳戶密碼</Button>
            </div>
          </div>
        </div>
      </main >
    )
  }
}

export default Setting;