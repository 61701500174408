import React from 'react';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';

import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import numberFormatter from '../../utils/numberFormatter';

let bill1 = {
  detailLink: '#123',
  date: '2020年04月',
  period: '2020/03/20 至 2020/04/19',
  info: {
    number: '0916-814-747',
    endDate: '無需繳費',
  },
  fee: [
    {
      title: '電信費用',
      fee: 999,
      isRed: false
    },
    {
      title: '小額代收',
      fee: 120,
      isRed: false
    },
    {
      title: '本期新增金額合計',
      fee: 1119,
      isRed: true
    },
    {
      title: '累計應繳金額',
      fee: 0,
      isRed: true
    },
  ],
  payment: null,
  resendPayment: '#',
  usageDetail: {
    inter: 9,
    outer: 3,
    local: 1,
    special: 0,
    internet: 0
  }
}

class QueryPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      form: {
        id: { value: '', required: true },
      },
      selectPeriod: { value: '1', text: '2020/04月份' },
      periodOption: [
        { value: '1', text: '2020/04月份' },
        { value: '2', text: '2020/05月份' },
        { value: '3', text: '2020/06月份' },
      ],
      currentBill: bill1,
    }
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <FormBanner
          title="本期應繳金額"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="query-index">
          <div className="fui-container">
            <div className="paper mb-2">
              <h4 className="mt-md-4 mb-md-3 mb-2">
                帳務資訊
                </h4>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                      門號
                      </h5>
                    <h2 className='mb-0'>{this.state.currentBill.info.number}</h2>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="is-bg-lightgray70 px-3 py-3">
                    <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0 d-sm-block d-flex justify-between">
                      繳費截止日
                    </h5>
                    <h2 className='mb-0'>{this.state.currentBill.info.endDate}</h2>
                  </div>
                </Grid>
              </Grid>
              <div className="d-flex justify-between flex-align-center mt-md-2 pt-md-4 mt-2">
                <h4 className='m-0'>費用明細</h4>
              </div>
              <div className="frammer mb-md-4 mb-0">
                {this.state.currentBill.fee.map((item, index) => {
                  return (
                    <div className="frammer--list" key={index}>
                      <h5>{item.title}</h5>
                      {item.isRed ? <h2 className='is-text-error'>{item.fee.toLocaleString()}元</h2> : <h4>{item.fee}元</h4>}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='mt-md-5 mt-4 pt-md-3 d-flex fui-buttons two-buttons'>
              {this.state.currentBill.payment ? <Button link={this.state.currentBill.payment} className='mb-0' btnStyle='primary' size='large'>我要繳費</Button> : null}
              <Button link={this.state.currentBill.resendPayment} className='mb-0' btnStyle="secondary" size='large'>帳單明細</Button>
            </div>
          </div>
        </section>
      </main >
    )
  }
}

export default QueryPay;