import React, { Component } from 'react';
import Header from '../../components/partials/header/Default';
import Breadcrumb from '../../components/Breadcrumb';
import NavAnchor from '../../components/partials/NavAnchor';
import AppListWrapper from '../../components/partials/AppList'
class AppList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [
                {
                    appName: '遠傳行動客服',
                    icon: {
                        img: '/resources/cbu/help-center/images/app@2x.png',
                        alt: 'app'
                    },
                    content: `<p>
                        提供即時隨身服務，無論是要查詢帳單金額、管理上網用量、繳交電話費、更改費率、手機促銷情報、搜尋/預約門市服務、免費課程與客服即時通，還有最熱門的優惠券下載全...
                    </p>`,
                    link: {
                        ios: '#',
                        android: '#'
                    }
                },
                {
                    appName: '遠傳愛講定位手錶',
                    icon: {
                        img: '/resources/cbu/help-center/images/invalid-name@2x.png',
                        alt: 'app'
                    },
                    content: `<p>搭配遠傳愛講定位手錶，可於APP內查看手錶即時定位、設定安全守護區範圍，並可於APP內輕鬆管理手錶所有設定如通訊錄、上課禁用...等功能。</p>`,
                    link: {
                        ios: '#',
                        android: '#'
                    }
                },
                {
                    appName: 'friDay 購物',
                    icon: {
                        img: '/resources/cbu/help-center/images/friday-app@2x.png',
                        alt: 'app'
                    },
                    content: `<ul>
                    <li>最懂商品的達人駐站</li>
                    <li>踏遍台灣買不到的好商品</li>
                    <li>永遠意想不到的好價格</li>
                    </ul>`,
                    link: {
                        ios: '#',
                        android: '#'
                    }
                },
                {
                    appName: 'friDay 影音',
                    icon: {
                        img: '/resources/cbu/help-center/images/friday.png',
                        alt: 'app'
                    },
                    content: `<p>提供合法優質的新聞，電影，戲劇內容，即時新聞直撥免費。</p>`,
                    link: {
                        ios: '#',
                        android: '#'
                    }
                },
                {
                    appName: '遠傳鈴聲館',
                    icon: {
                        img: '/resources/cbu/help-center/images/app-ring.png',
                        alt: 'app'
                    },
                    content: `<ul>
                    <li>萬首高品質鈴聲/全曲下載</li>
                    <li>週週更新，搶先擁有新歌</li>
                    </ul>`,
                    link: {
                        android: '#'
                    }
                },
                {
                    appName: 'friDay 音樂',
                    icon: {
                        img: '/resources/cbu/help-center/images/fri-day-app@2x.png',
                        alt: 'app'
                    },
                    content: `<ul>
                        <li>全球音樂寶庫聽到飽</li>
                        <li>音樂辨識歌曲不錯過</li>
                        <li>全新藝人影音看個夠</li>
                    </ul>`,
                    link: {
                        ios: '#',
                        android: '#'
                    }
                },
                {
                    appName: '遠傳e券商',
                    icon: {
                        img: '/resources/cbu/help-center/images/e@2x.png',
                        alt: 'app'
                    },
                    content: `<ul>
                        <li>多種金融商品即時報價資訊</li>
                        <li>到價簡訊通知</li>
                        <li>支援數十家券商下單</li>
                    </ul>`,
                    link: {
                        ios: '#',
                        android: '#'
                    }
                },
                {
                    appName: '行動電視',
                    icon: {
                        img: '/resources/cbu/help-center/images/app@2x (1).png',
                        alt: 'app'
                    },
                    content: `<p>Live頻道掌握新聞時事、最新財經消息，國內外強檔戲劇隨選隨看，追劇好Easy!</p>`,
                    link: {
                        ios: '#',
                        android: '#'
                    }
                },
            ]
        }
    }
    render() {
        return (
            <main className="app-list-page">
                <Header />
                <NavAnchor
                    pageTitle='幫助中心'
                    button={{
                        text: '回首頁',
                        link: '/',
                    }}
                />
                <Breadcrumb
                    breadcrumb={[
                        { link: '/ebu', text: '幫助中心' },
                        { link: '', text: 'APP總覽' },
                    ]}
                />
                <AppListWrapper list={this.state.list} />
                {/* <section className="list-wrapper">
                    <div className="fui-container">
                        <Grid container spacing={2}>
                            {this.state.list.map((item) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className="paper">
                                            <div className="d-flex">
                                                <img src={item.icon.img} alt={item.icon.alt} />
                                                <div className="flex-column d-lfex">
                                                    <div className="body">
                                                        {item.appName}
                                                    </div>
                                                    <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="d-flex">
                                                {item.link.ios ? <a href={item.link.ios}><img src="/resources/cbu/help-center/images/app-store.png" /></a> : null}
                                                {item.link.android ? <a href={item.link.android}><img src="/resources/cbu/help-center/images/google-play.png" /></a> : null}
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>
                </section> */}
            </main>
        );
    }
}

export default AppList;