import React, { Component } from 'react';
import LoadMore from '../../components/LoadMore';
import PropTypes from 'prop-types';
class CardCollapse2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentArticleLoadMore: this.props.list.length > 3,
      threeData: [],
      showMore: false,
    };
  }
  loadData = () => {
    this.setState({
      showMore: true,
      currentArticleLoadMore: false,
      threeData: this.props.list,
    });
    this.forceUpdate();
  };
  componentDidMount() {
    let result = [];
    this.props.list.map((item, i) => {
      return i < 3 ? result.push(item) : null;
    });
    this.setState({
      threeData: result,
    });
  }
  render() {
    return (
      <section className='card-collapse-1'>
        <div className='fui-container'>
          <div dangerouslySetInnerHTML={{ __html: this.props.title }}></div>
          {this.state.threeData.map((item, i) => {
            return (
              <div key={item.title + i} style={{ marginBottom: 12 }}>
                <div className='fui-collapse paper'>
                  <div className='collapse-body'>
                    <div className='collapse-wrapper'>
                      <div className='head-img'>
                        <img src={item.img.url} alt='img-01' />
                      </div>
                      <div className='collapse-content'>
                        <h5>
                          <img className='d-inline-block d-md-none' src={item.img.url} alt={item.img.alt} />
                          <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                        </h5>
                        <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {this.props.list.length > 3 ? (
            <LoadMore
              moreLabel='展開看更多'
              noMoreLabel={null}
              click={(e) => this.loadData()}
              load={this.state.currentArticleLoadMore}
            />
          ) : null}
        </div>
      </section>
    );
  }
}
CardCollapse2.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      img: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      content: PropTypes.string,
    })
  ),
};
export default CardCollapse2;
