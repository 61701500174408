import React from 'react';
import Link from '../../Link';
import PropTypes from 'prop-types';

class ArticleCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEn: false,
    };
  }

  componentDidUpdate() {
    if ((typeof window !== 'undefined' && window.$isEn) !== this.state.isEn) {
      this.setState({
        isEn: typeof window !== 'undefined' && window.$isEn,
      });
    }
  }
  openFbLink = (link) => {
    window.open(
      `//www.facebook.com/sharer.php?u=${encodeURIComponent(
        typeof window !== 'undefined' ? window.location.origin : '' + link
      )}`
    );
  };

  openLineLink = (link, type) => {
    window.open(
      `//social-plugins.line.me/lineit/share?url=${encodeURIComponent(
        typeof window !== 'undefined' ? window.location.origin : '' + link
      )}`
    );
  };
  render() {
    return (
      <div id={this.props.id}>
        <div dangerouslySetInnerHTML={{ __html: this.props.title }}></div>
        <div className='group-extra-link-card article-card' id={this.props.id}>
          {this.props.article.map((article, index) => {
            return (
              <div className='fui-card single-extra-link-card' key={article.title + index}>
                <Link
                  to={article.link}
                  className='article-img'
                  style={{ backgroundImage: `url(${process.env.PUBLIC_URL + article.img})` }}></Link>
                <div className='article-content'>
                  <Link to={article.link}>
                    {!!article.period ? <small className='periodical is-text-error'>{article.period}</small> : null}
                    {/* {!!article.showDate ? <small className='date'>{article.date}</small> : null} */}
                    <h4>{article.title}</h4>
                    <p>{article.discription}</p>
                  </Link>
                  <div className='fui-card-extra'>
                    <Link to={article.link} className='fui-button is-arrow mb-0'>
                      {this.state.isEn ? 'More' : '看更多'}
                    </Link>
                    <div className='social'>
                      <i onClick={() => this.openFbLink(article.link)} className={`icon-facebook-sm`}></i>
                      <i onClick={() => this.openLineLink(article.link)} className={`icon-line-sm`}></i>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      // </section>
    );
  }
}

ArticleCards.propTypes = {
  id: PropTypes.string,
  article: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string,
      retinaImg: PropTypes.string,
      period: PropTypes.string,
      date: PropTypes.string,
      title: PropTypes.string,
      brief: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};
export default ArticleCards;
