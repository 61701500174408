import React from 'react';
import Menu from './Menu';
import Item from './item/LinkItem';
import PropTypes from 'prop-types';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.trigger = React.createRef();
    this.state = {
      anchorEl: null,
      isClose: false,
      selectedItem: this.props.label,
      isHandleClick: false,
      selected: null,
    };

    this.getSelected = this.getSelected.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectedItem: this.props.label,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.label !== this.state.selectedItem ||
      prevProps.list.length !== this.props.list.length ||
      (prevProps.list.length &&
        prevProps.list[0].text !== this.props.list[0].text &&
        prevProps.list[0].value !== this.props.list[0].value)
    ) {
      this.setState({
        selectedItem: this.props.label,
      });
    }
  }

  closeMenu = (event) => {
    if (this.props.close) this.props.close();

    setTimeout(() => {
      this.setState({
        anchorEl: null,
        isClose: false,
      });
      // console.log('closeMenu close')
      this.trigger.current.blur();
    }, 120);
  };

  handleClick = (e) => {
    e.preventDefault();
    if (this.state.isClose === true) {
      // [AA Tracking]
      if (this.props.trackingClick && window && typeof window.ctrf === "function") window.ctrf(e || {}, `Tab_收合`);
      return;
    }

    this.setState({ isHandleClick: true });

    if (Boolean(this.state.anchorEl)) {
      // console.log('handleClick close')
      this.setState({
        anchorEl: null,
        isHandleClick: false,
      }, () => {
        // [AA Tracking]
        if (this.props.trackingClick && window && typeof window.ctrf === "function") window.ctrf(e || {}, `Tab_收合`);
      });
    } else {
      // console.log('open')
      this.setState({
        anchorEl: e.currentTarget,
        isHandleClick: false,
      }, () => {
        // [AA Tracking]
        if (this.props.trackingClick && window && typeof window.ctrf === "function") window.ctrf(e || {}, `Tab_展開`);
      });
      if (this.props.onActive) this.props.onActive();
    }
  };

  selectItem = (item, idx, e) => {
    if (typeof window !== 'undefined') {
      if (window.event.preventDefault) window.event.preventDefault();
      else window.event.returnValue = false;
    }

    // console.log(`item`, item);
    this.setState({
      selectedItem: item.text,
      selected: item.text,
    });

    if (this.props.onChange) this.props.onChange(item, idx, e);
    this.closeMenu();
  };

  getSelected = (item) => {
    return this.state.selected === item.label ? 'active' : '';
  };

  readyToClose = (item) => {
    if (this.state.isHandleClick === true) return;
    this.setState({
      isClose: true,
    });
    this.forceUpdate();

    setTimeout(() => {
      this.closeMenu();
    }, 50);
  };

  render() {
    return (
      <div
        className={`
          fui-dropdown 
          ${this.props.arrow ? 'with-arrow' : ''} 
          ${this.props.className ? this.props.className : ''}
          ${Boolean(this.state.anchorEl) ? 'is-show' : ''} 
        `}>
        <button
          ref={this.trigger}
          aria-haspopup='true'
          aria-expanded={Boolean(this.state.anchorEl)}
          aria-label={this.props.label}
          data-aa-tracking-id={this.props.trackingClick && `${Boolean(this.state.anchorEl) ? "Tab_收合" : "Tab_展開"}`}
          onClick={this.handleClick}
          className='fui-dropdown-item'>
          <span
            dangerouslySetInnerHTML={{
              __html: this.props.selected ? this.props.label : this.state.selectedItem,
            }}></span>
          {this.props.arrow ? <i className='icon-chevron-down'></i> : ''}
        </button>
        <Menu
          id={this.props.id}
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.readyToClose}>
          <div className='menu-wrapper'>
            {this.props.list.map((item, idx) =>
              Array.isArray(item) ? (
                item.length > 0 && (
                  <div className='fui-menu-group' key={'menu-item-group-' + idx + this.props.id}>
                    {item.map((it, i) => (
                      <Item
                        {...it}
                        className={`${
                          (this.props.selected && this.props.selected === it.text) ||
                            (!this.props.selected &&
                              (this.state.selectedItem === it.text ||
                                (this.props.value && this.props.value === it.value)))
                            ? 'active'
                            : ''
                          } ${this.props.hasCheck ? 'check-icon' : ''}`}
                        key={'menu-item' + idx + this.props.id + i}
                        onClick={(e) => this.selectItem(it, idx, e)}>
                        {it.text}
                      </Item>
                    ))}
                  </div>
                )
              ) : (
                  <Item
                    {...item}
                    className={`${
                      (this.props.selected && this.props.selected === item.text) ||
                        (!this.props.selected &&
                          (this.state.selectedItem === item.text || (this.props.value && this.props.value === item.value)))
                        ? 'active'
                        : ''
                      } ${this.props.hasCheck ? 'check-icon' : ''} ${this.getSelected(item)}`}
                    key={'menu-item' + this.props.id + idx}
                    onClick={(e) => this.selectItem(item, idx, e)}>
                    {item.text}
                  </Item>
                )
            )}
          </div>
        </Menu>
      </div>
    );
  }
}

Dropdown.defaultProps = {
  hasCheck: false,
  onActive: null,
  trackingClick: true
};
Dropdown.propTypes = {
  id: PropTypes.string,
  list: PropTypes.arrayOf(Item),
  arrow: PropTypes.bool, // Boolean
  className: PropTypes.string,
  selected: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func, // callback
  onActive: PropTypes.func,
  hasCheck: PropTypes.bool,
  trackingClick: PropTypes.bool
};

export default Dropdown;
