import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
class SectionFeatureIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getColumn = () => {
    let length = this.props.list.length;
    // console.log(`length: `, this.props.list.length);
    switch (length) {
      case 1:
        return 12;
      case 2:
        return 4;
      case 3:
        return 4;
      case 4:
        return 6;
      case 5:
        return 4;
      default:
        return 4;
    }
  };
  render() {
    return (
      <section
        className='section-feature-icon'
        style={this.props.bg ? { backgroundImage: `url(${this.props.bg})` } : null}>
        <div className='fui-container'>
          <h2
            className='align-center w-100'
            dangerouslySetInnerHTML={{
              __html: this.props.title,
            }}
          />
          <Grid container justify='center'>
            {this.props.list.map((item, i) => {
              return (
                <Grid key={item.title + i} item xs={12} sm={12} md={this.getColumn()}>
                  <div className='icon-wrapper mb-5' style={{ alignItems: 'flex-start' }}>
                    <img src={item.icon.img} alt={item.icon.alt} />
                    <div className='d-flex flex-column ml-4'>
                      <h5
                        className='mt-0 mb-md-1 mb-0'
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                      />
                      <div
                        className='body-1'
                        dangerouslySetInnerHTML={{
                          __html: item.content,
                        }}></div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </section>
    );
  }
}
SectionFeatureIcon.propTypes = {
  loopData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ),
  bg: PropTypes.string,
  title: PropTypes.string,
};
export default SectionFeatureIcon;
