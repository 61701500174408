import React from 'react';
import Collapse from '../../collapse/Collapse';

import PropTypes from 'prop-types'

class SectionCollapseInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 0
        }
    }

    render() {
        return (
            <section
                id={this.props.id}
                style={this.props.bgColor ? { backgroundColor: this.props.bgColor } : null}
                className={`${this.props.className ? this.props.className : ''} fui-section-collapse ${this.props.theme ? ('is-' + this.props.theme) : ''}`}
            >
                <div className={this.props.container == false ? null : 'fui-container'}>
                    <div className={this.props.container == false ? 'px-md-4 px-2' : ''}>
                        <Collapse
                            title={this.props.title}
                            content={this.props.content} />
                    </div>
                </div >
            </section >
        )
    }
}

SectionCollapseInfo.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    content: PropTypes.string,
    bgColor: PropTypes.string,
    theme: PropTypes.string,
}

export default SectionCollapseInfo;