import React, { Component } from 'react'

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import NavContentTab2 from '../../components/partials/NavContentTab2';
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import Panel from '../../components/panel/Panel';
import PanelTab from '../../components/panelContent/PanelTab';
import AddValueSection from '../../components/partials/AddValueSection'
import * as Mock from '../../mock/AddValue.js';
class AddValue extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  changeMainTab = value => {
    this.setState({
      currentMainTab: value,
    });
    // return value === 0 ? this.props.history.push('/cbu/5g') : null
  };
  render() {
    return (
      <main className="prepaid">

        <NavAnchor
          pageTitle='<a href="#">預付卡</a>'
          button={{
            link: '#',
            text: '立即儲值',
          }}
          tabs={[
            { label: '儲值方案', link: '#addValue' },
            { label: '儲值方式', link: '#wayOfAddValue' },
            { label: '更多優惠', link: '#promotion' },
            { label: '常見問題', link: '#help' }
          ]}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '首頁', link: '/' },
            { text: '預付卡', link: '/' }
          ]}
          color='white'
        />
        <SectionBanner2
          image={{
            md: '/resources/cbu/prepaid/images/ebu-aws-banner-1440-x-460@2x.jpg',
            sm: '/resources/cbu/prepaid/images/ebu-aws-banner-750-x-660@2x.jpg',
          }}
          title='預付卡與你甜甜圈在一起'
          // description='白色情人節上網趣'
          description='<div class="list"><img class="mr-2" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5></div> <div class="list"><img class="mr-2" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5></div><div class="list"><img class="mr-2" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5></div>'
          action={{
            text: '了解更多',
            link: '#',
            target: '_self'
          }}
        />
        <NavContentTab2 tabs={{
          name: 'add-value-tab',
          icon: true,
          title: false,
          list: [
            {
              name: 'add-value',
              label: '預付卡儲值',
              link: '/prepaid/add-value',
              icon: '/resources/cbu/prepaid/images/prepaid-add-value.svg',
              focusIcon: '/resources/cbu/prepaid/images/prepaid-add-value-focus.svg',
              target: '_blank',
            },
            {
              name: 'application',
              label: '預付卡申辦',
              link: '/prepaid/application',
              icon: '/resources/cbu/prepaid/images/prepaid.svg',
              focusIcon: '/resources/cbu/prepaid/images/prepaid-focus.svg',
              target: '_self',
            },
          ],
        }} onChange={e => this.changeMainTab(e)} />
        <AddValueSection
          id="addValue"
          h1="你想找什麼樣的儲值方案呢？"
          tab={Mock.tabContent[0].tabs}
          tabContent={Mock.tabContent[0].tabContent}
          ad={{
            id: 'wayOfAddValue',
            patternColor: 'green',
            title: `除了遠傳官網，還可以透過APP、月租帳單、門市等多種方式儲值`,
            action: [{
              text: '查看儲值方式',
              line: '#',
              target: '_blank',
            }]
          }}
          carousel={{
            title: '更多優惠',
            id: "promotion",
            cards: [
              {
                image: '/resources/cbu/prepaid/images/cover.png',
                title: '儲值語音送上網',
                description: '至指定通路儲值語音通話，立即送上網流量，儲值越多送越多！',
                link: '#',
                target: '_self'
              },
              {
                image: '/resources/cbu/prepaid/images/cover.png',
                title: '儲值語音送上網',
                description: '至指定通路儲值語音通話，立即送上網流量，儲值越多送越多！',
                link: '#',
                target: '_self'
              },
              {
                image: '/resources/cbu/prepaid/images/cover.png',
                title: '儲值語音送上網',
                description: '至指定通路儲值語音通話，立即送上網流量，儲值越多送越多！',
                link: '#',
                target: '_self'
              },
            ],
            more: { text: '看更多', link: '#', target: '_blank' },
          }}
        />
        <Panel className="is-bg-lightgray70 px-0">
          <div className="fui-container">
            <h3 id="help" className="is-text-darkgray50 is-text-medium m-0">常見問題</h3>
            <PanelTab
              tabs={{
                name: 'panel-tab',
                list: [
                  { name: 'panel-tab-1', label: '預付卡儲值' },
                  { name: 'panel-tab-2', label: '預付卡申辦' },
                ],
              }}>
              <SectionFaq {...{
                collapseList: [
                  {
                    title: '語音通話的購買通路有哪些？',
                    content: `語音通話的購買通路有哪些？`,
                  },
                  {
                    title: '超值版上網的上網傳輸量沒用完怎麼辦？',
                    content: `超值版上網的上網傳輸量沒用完怎麼辦？`,
                  },
                  {
                    title: '每個儲值方式都可享有優惠嗎？  ',
                    content: `每個儲值方式都可享有優惠嗎？  `,
                  },
                ],
                container: false,
                to: '#',
                target: '_blank'
              }} />
              <SectionFaq {...{
                collapseList: [
                  {
                    title: '語音通話的購買通路有哪些？',
                    content: `語音通話的購買通路有哪些？`,
                  },
                  {
                    title: '超值版上網的上網傳輸量沒用完怎麼辦？',
                    content: `超值版上網的上網傳輸量沒用完怎麼辦？`,
                  },
                  {
                    title: '每個儲值方式都可享有優惠嗎？  ',
                    content: `每個儲值方式都可享有優惠嗎？  `,
                  },
                ],
                to: '#',
                target: '_blank',
                container: false
              }} />
            </PanelTab>
          </div>
        </Panel>
      </main>
    );
  }
}
export default AddValue;