export const indexMainCarousel = {
  slider: [
    {
      theme: 'dark',
      image: '/resources/cbu/life-circle-index/images/cbu-lifecircle-article-kv01.jpg',
      imageMb: '/resources/cbu/life-circle-index/images/img-soyummy-480-x-240@2x.jpg',
      title: '居家必備的吸塵器1',
      description: '以CP值破表的稱號，熱銷全台超過4,000台、集資金額超過3,600萬台幣，而引起熱議...',
      isLast: false,
      action: {
        text: '體驗故事',
        link: '#',
      },
    },
    {
      // theme: 'dark',
      image: '/resources/cbu/life-circle-index/images/cbu-lifecircle-article-kv01.jpg',
      title: '居家必備的吸塵器2',
      description: '以CP值破表的稱號，熱銷全台超過4,000台、集資金額超過3,600萬台幣，而引起熱議...',
      isLast: false,
      action: {
        text: '體驗故事',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-index/images/cbu-lifecircle-article-kv01.jpg',
      title: '居家必備的吸塵器3',
      description: '以CP值破表的稱號，熱銷全台超過4,000台、集資金額超過3,600萬台幣，而引起熱議...',
      action: {
        text: '體驗故事',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-index/images/cbu-lifecircle-article-kv01.jpg',
      title: '居家必備的吸塵器4',
      description: '以CP值破表的稱號，熱銷全台超過4,000台、集資金額超過3,600萬台幣，而引起熱議...',
      isLast: false,
      action: {
        text: '體驗故事',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-index/images/cbu-lifecircle-article-kv01.jpg',
      title: '居家必備的吸塵器5',
      description: '以CP值破表的稱號，熱銷全台超過4,000台、集資金額超過3,600萬台幣，而引起熱議...',
      isLast: false,
      action: {
        text: '體驗故事',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-index/images/cbu-lifecircle-article-kv01.jpg',
      title: '居家必備的吸塵器6',
      description: '以CP值破表的稱號，熱銷全台超過4,000台、集資金額超過3,600萬台幣，而引起熱議...',
      isLast: false,
      action: {
        text: '體驗故事',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-index/images/cbu-lifecircle-article-kv01.jpg',
      title: '居家必備的吸塵器7',
      description: '以CP值破表的稱號，熱銷全台超過4,000台、集資金額超過3,600萬台幣，而引起熱議...',
      action: {
        text: '體驗故事',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-index/images/cbu-lifecircle-article-kv01.jpg',
      title: '居家必備的吸塵器8',
      description: '以CP值破表的稱號，熱銷全台超過4,000台、集資金額超過3,600萬台幣，而引起熱議...',
      isLast: false,
      action: {
        text: '體驗故事',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-index/images/cbu-lifecircle-article-kv01.jpg',
      title: '居家必備的吸塵器9',
      description: '以CP值破表的稱號，熱銷全台超過4,000台、集資金額超過3,600萬台幣，而引起熱議...',
      isLast: false,
      action: {
        text: '體驗故事',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-index/images/cbu-lifecircle-article-kv01.jpg',
      title: '更多策展',
      description: '',
      isLast: true,
      action: {
        text: '更多策展',
        link: '#',
      },
    },
  ],
};

export const indexToolsItem = {
  title: '今天想做什麼，都在生活圈完成！',
  item: [
    {
      image: '/resources/cbu/life-circle-slashie/images/flight-video.svg',
      title: '直播新聞',
      description: 'friDay影音',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/smart-speaker.svg',
      title: '問台積電股市',
      description: '愛講語音助理',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/bobee-parking.svg',
      title: '車停在哪',
      description: '愛車守護寶',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/friday-shopping-2.svg',
      title: '解憂餐券',
      description: 'friDay購物',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/laundry.svg',
      title: '幫你洗衣服',
      description: '潔衣家洗衣服務',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/friday-57.svg',
      title: '小細節都省',
      description: 'friDay 57',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/3-c-doctor.svg',
      title: '3C解惑達人',
      description: '數位萬事通',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/friday-57.svg',
      title: '小細節都省',
      description: 'friDay 57',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/3-c-doctor.svg',
      title: '3C解惑達人',
      description: '數位萬事通',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/friday-57.svg',
      title: '小細節都省',
      description: 'friDay 57',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/3-c-doctor.svg',
      title: '3C解惑達人',
      description: '數位萬事通',
    },
  ],
};
export const indexAdBannerCard = {
  slider: [
    {
      image: '/resources/cbu/life-circle-index/images/img-soyummy-480-x-240.jpg',
      title: 'SoYummy 手壓蜜',
      price: '200 + 50元',
      action: {
        text: '立即兌換',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-index/images/img-soyummy-480-x-240.jpg',
      title: 'SoYummy 手壓蜜',
      price: '200 + 50元',
      action: {
        text: '立即兌換',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-index/images/img-soyummy-480-x-240.jpg',
      title: 'SoYummy 手壓蜜',
      price: '200 + 50元',
      action: {
        text: '立即兌換',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-index/images/img-soyummy-480-x-240.jpg',
      title: 'SoYummy 手壓蜜',
      price: '200 + 50元',
      action: {
        text: '立即兌換',
        link: '#',
      },
    },
  ],
};

export const indexfCoinChangeCard = {
  title: '用遠傳幣兌換更有品質的生活',
  // more: {
  //   link: '#',
  //   text: '更多話題',
  // },
  cards: [
    {
      // subtitle: 'Costco會員',
      image: '/resources/cbu/life-circle-index/images/img-fcoin-1-x-1-1.jpg',
      title: 'Costco會員',
      price: '200 + 50元',
      link: '#',
    },
    {
      // subtitle: '威秀影城電影票X2威秀影城電影票X2',
      image: '/resources/cbu/life-circle-index/images/img-fcoin-1-x-1-1.jpg',
      title: '威秀影城電影票X2 威秀影城電影票X2',
      price: '150',
      link: '#',
    },
    {
      // subtitle: '韓流夯話題',
      image: '/resources/cbu/life-circle-index/images/img-fcoin-1-x-1-1.jpg',
      title: '愛奇藝七日會員',
      price: '150',
      link: '#',
    },
    {
      // subtitle: '韓流夯話題',
      image: '/resources/cbu/life-circle-index/images/img-fcoin-1-x-1-1.jpg',
      title: '星巴克卡布奇諾',
      price: '120',
      link: '#',
    },
    {
      // subtitle: 'Costco會員',
      image: '/resources/cbu/life-circle-index/images/img-fcoin-1-x-1-1.jpg',
      title: 'Costco會員',
      price: '50元',
      link: '#',
    },
  ],
};
export const indexCouponCard = {
  title: '折價券領好領滿，現金存撲滿',
  // more: {
  //   link: '#',
  //   text: '更多話題',
  // },
  cards: [
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/img-fcoin-1-x-1-1.jpg',
      title: 'EZtable 10% 折扣',
      description: '從遠傳生活圈訂位享9折',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/img-fcoin-1-x-1-1.jpg',
      title: 'Foodomo',
      description: '從遠傳生活圈訂位享9折',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/img-fcoin-1-x-1-1.jpg',
      title: '親子天下 85折優惠',
      description: '從遠傳生活圈購買 享85折，並贈送一份好禮',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/img-fcoin-1-x-1-1.jpg',
      title: 'EZtable 10% 折扣',
      description: '從遠傳生活圈訂位享9折',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/img-fcoin-1-x-1-1.jpg',
      title: 'Foodomo',
      description: '從遠傳生活圈訂位享9折',
      link: '#',
    },
  ],
};

export const indexGoodsCard = {
  title: 'friDay購物精選',
  // more: {
  //   link: '#',
  //   text: '更多精選好物',
  // },
  cards: [
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: '韓國EUNYUL面膜-玻尿酸22ml韓國EUNYUL面膜-玻尿酸22ml',
      description: '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
      tag: {
        color: 'green',
        text: '最後倒數23小時',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: "KIEHL's 契爾氏 激光極淨白玻尿酸精華面膜(升級...",
      description: '追劇、保養兩兼',
      price: '$500',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: '韓國EUNYUL',
      description:
        '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！追劇、保養兩兼顧，輕鬆擁有女主臉蛋！追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: '正官庄 高麗蔘精EVERYTIME 10ml 30包',
      description: '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: 'Yodo優朵超細柔抽取式花紋家用紙150抽x72包/箱',
      description: '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: '紅牛能量飲料250ml*4入/組',
      description: '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
    },
  ],
};

export const indexNewsCard = {
  title: '圈出新聞焦點',
  // more: {
  //   link: '#',
  //   text: '更多話題',
  // },
  cards: [
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/img-news-1-x-1-1.jpg',
      title: '無疫情沒人戴口罩，而有口罩之亂的瑞士',
      description:
        '根據新蘇黎世報2月9日最新的全球疫情分析(德)，從疫情爆發至今，瑞士目前尚無確診病例。參與法國撤僑回到歐洲的瑞士人，現...',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/img-news-1-x-1-1.jpg',
      title: '口罩戴久悶熱，不小心就變成敏弱肌',
      description:
        '身邊開始有朋友說，本來沒有過敏體質，但不知什麼原因，原本換季也都還好，怎麼這次為了防新冠病毒(武漢病毒)，在辦公室或...',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/img-news-1-x-1-1.jpg',
      title: '遊戲人生 | 動物森友會與日常生活的對比',
      description:
        '這款遊戲近期非常的火紅，除了強打與現實時間同步之外，也可能可以在COVID-19（武漢肺炎）的防疫狀態，給玩家重溫日常生...',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/img-news-1-x-1-1.jpg',
      title: '《無人知曉》瞬間最高收視衝破11%大關',
      description:
        '《SYK 天空之城》紅遍大街小巷的「金珠英老師」金瑞亨回歸小螢幕新戲《無人知曉》首播瞬間最高收視率衝破11%大關開紅盤。...',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/img-news-1-x-1-1.jpg',
      title: '《無人知曉》瞬間最高收視衝破11%大關',
      description:
        '《SYK 天空之城》紅遍大街小巷的「金珠英老師」金瑞亨回歸小螢幕新戲《無人知曉》首播瞬間最高收視率衝破11%大關開紅盤。...',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/img-news-1-x-1-1.jpg',
      title: '《無人知曉》瞬間最高收視衝破11%大關',
      description:
        '《SYK 天空之城》紅遍大街小巷的「金珠英老師」金瑞亨回歸小螢幕新戲《無人知曉》首播瞬間最高收視率衝破11%大關開紅盤。...',
      link: '#',
    },
  ],
};

export const indexEntertainmentCard = {
  title: '再忙也要娛樂',
  // more: {
  //   link: '#',
  //   text: '更多話題',
  // },
  cards: [
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/thumbnail-placeholder-01.jpg',
      title: '17 Again',
      description: '從遠傳生活圈購買享9折，並贈送一份好禮。',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/thumbnail-placeholder-01.jpg',
      title: 'Toosie Slide',
      description: 'Drake',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/thumbnail-placeholder-01.jpg',
      title: '17 Again',
      description: '從遠傳生活圈購買享9折，並贈送一份好禮。',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/thumbnail-placeholder-01.jpg',
      title: 'Toosie Slide',
      description: 'Drake',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/thumbnail-placeholder-01.jpg',
      title: 'Toosie Slide',
      description: 'Drake',
      link: '#',
    },
    {
      subtitle: '',
      image: '/resources/cbu/life-circle-index/images/thumbnail-placeholder-01.jpg',
      title: 'Toosie Slide',
      description: 'Drake',
      link: '#',
    },
  ],
};
export const indexEventPromoData = {
  image: {
    md: '/resources/cbu/life-circle-index/images/cbu-promotion-banner-01-1920x156.jpg',
    sm: '/resources/cbu/cbu-index/cbu-promotion-banner-01-750x360.jpg',
  },
  title: '網路門市限定 週三驚爆閃購日，AirPods、藍牙耳機週週1折起',
  description: '',
  action: {
    text: '立即領折扣序號',
    link: '#',
  },
};

export const dramaBanner0 = {
  mainBanner: true,
  slogan: {
    md: '/resources/cbu/life-circle-drama/images/life-story-slogan-01.png',
    sm: '/resources/cbu/life-circle-drama/images/life-story-slogan-01-m.png',
  },
  image: {
    md: '/resources/cbu/life-circle-drama/images/cbu-lifestory-banner-01-1920x470.jpg',
    sm: '/resources/cbu/life-circle-drama/images/cbu-lifestory-banner-01-1920x470.jpg',
  },
  link: '#',
  title: '起床一睜開眼，就等不及要追最新的一集嗎？',
  alt: 'alt text',
  icon: '/resources/cbu/life-circle-drama/images/sunrise.svg',
  subtitle: '8:00AM',
  description: '',
  type: 'img-right',
};

export const dramaBanner1 = {
  image: {
    md: '/resources/cbu/life-circle-drama/images/cbu-lifestory-banner-02-1920x470.jpg',
    sm: '/resources/cbu/life-circle-drama/images/cbu-lifestory-banner-02-1920x470.jpg',
  },
  link: '#',
  title: '通勤片刻，也要追劇的大小事',
  alt: 'alt text',
  icon: '/resources/cbu/life-circle-drama/images/commuter.svg',
  subtitle: '9:00AM',
  description: '',
  type: 'img-left',
};
export const dramaBanner2 = {
  image: {
    md: '/resources/cbu/life-circle-drama/images/cbu-lifestory-banner-03-1920x470.jpg',
    sm: '/resources/cbu/life-circle-drama/images/cbu-lifestory-banner-03-1920x470.jpg',
  },
  link: '#',
  title: '吃點下午茶配原聲帶，延續粉紅泡泡振奮工作精神！',
  alt: 'alt text',
  icon: '/resources/cbu/life-circle-drama/images/tea-time.svg',
  subtitle: '4:30PM',
  description: '',
  type: 'img-right',
};
export const dramaBanner3 = {
  image: {
    md: '/resources/cbu/life-circle-drama/images/cbu-lifestory-banner-04-1920x470.jpg',
    sm: '/resources/cbu/life-circle-drama/images/cbu-lifestory-banner-04-1920x470.jpg',
  },
  link: '#',
  title: '追到累了嗎？補充能量再衝一集',
  alt: 'alt text',
  icon: '/resources/cbu/life-circle-drama/images/couch.svg',
  subtitle: '8:00PM',
  description: '',
  type: 'img-left',
};
export const dramaBanner4 = {
  image: {
    md: '/resources/cbu/life-circle-drama/images/cbu-lifestory-banner-05-1920x470.jpg',
    sm: '/resources/cbu/life-circle-drama/images/cbu-lifestory-banner-05-1920x470.jpg',
  },
  link: '#',
  title: '睡前下載喜歡的歌，明天歐爸用歌聲喚你起來',
  alt: 'alt text',
  icon: '/resources/cbu/life-circle-drama/images/night.svg',
  subtitle: '11:00PM',
  description: '',
  type: 'img-right',
};

export const dramaCard = {
  title: '熱騰騰人氣新劇',
  more: {
    link: '#',
    text: '更多優質好劇',
  },
  cards: [
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '怪咖！文主廚',
      description: '同步跟播至第2集',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '無人知曉',
      description: '同步跟播至第12集',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '戀愛可以持續到天長地久',
      description: '全20集',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '一半的一半',
      description: '同步跟播至第6集',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '怪咖！文主廚',
      description: '同步跟播至第2集',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '無人知曉',
      description: '同步跟播至第12集',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '戀愛可以持續到天長地久',
      description: '全20集',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '一半的一半',
      description: '同步跟播至第6集',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '怪咖！文主廚',
      description: '同步跟播至第2集',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '無人知曉',
      description: '同步跟播至第12集',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '戀愛可以持續到天長地久',
      description: '全20集',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-thumbnail-04.jpg',
      title: '一半的一半',
      description: '同步跟播至第6集',
      link: '#',
    },
  ],
};

export const newsCard = {
  title: '嗑影劇，夯話題',
  more: {
    link: '#',
    text: '更多話題',
  },
  cards: [
    {
      subtitle: '韓流夯話題',
      image: '/resources/cbu/life-circle-drama/images/thumbnail-placeholder-01.jpg',
      title: 'Eric與高媛熙吻戲還不夠看！導演再爆料!',
      description:
        '神話成員Eric時隔四年終於以《怪咖！文主廚》回歸小螢幕，身懷精湛廚藝的他被粉絲敲碗要求觀賞玉手，讓他邊秀邊笑說「禁...',
      link: '#',
    },
    {
      subtitle: '韓流夯話題',
      image: '/resources/cbu/life-circle-drama/images/thumbnail-placeholder-01.jpg',
      title: '丁海寅暖男笑容電力超強！蔡秀彬內心激動',
      description:
        '韓國暖男丁海寅和蔡秀彬主演的新劇《一半的一半》第一集開播就掀起收視熱潮，「一半的一半 蔡秀彬」更登上韓國入口網站測試長度測試長度測試長度測試長度',
      link: '#',
    },
    {
      subtitle: '韓流夯話題',
      image: '/resources/cbu/life-circle-drama/images/thumbnail-placeholder-01.jpg',
      title: '姐弟戀掰掰！丁海寅新劇和妹妹談感情',
      description:
        '韓國「國民初戀」丁海寅自2018年與姐姐孫藝真合作之後人氣急速上升，如今在《一半的一半》中雖是和妹妹談感情，卻依然直...',
      link: '#',
    },
    {
      subtitle: '韓流夯話題',
      image: '/resources/cbu/life-circle-drama/images/thumbnail-placeholder-01.jpg',
      title: '《無人知曉》瞬間最高收視衝破11%大關',
      description:
        '《SYK 天空之城》紅遍大街小巷的「金珠英老師」金瑞亨回歸小螢幕新戲《無人知曉》首播瞬間最高收視率衝破11%大關開紅盤。...',
      link: '#',
    },
  ],
};

export const koreaSongsCard = {
  title: '點聽韓語榜',
  cards: [
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: '這份愛 (This Love)',
      album: '太陽的後裔 韓劇原聲帶',
      signer: 'DAVICHI ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Our Story',
      album: '十八歲的瞬間 韓劇原聲帶',
      signer: '邕聖祐 ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Love',
      album: '你也是人類嗎？ 韓劇原聲帶',
      signer: 'LYn ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'You’re My Graden',
      album: '大力女子都奉順 韓劇原聲帶',
      signer: '鄭恩地 ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: '這份愛 (This Love)',
      album: '太陽的後裔 韓劇原聲帶',
      signer: 'DAVICHI ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Our Story',
      album: '十八歲的瞬間 韓劇原聲帶',
      signer: '邕聖祐 ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Love',
      album: '你也是人類嗎？ 韓劇原聲帶',
      signer: 'LYn ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'You’re My Graden',
      album: '大力女子都奉順 韓劇原聲帶',
      signer: '鄭恩地 ',
      action: '試聽',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: '這份愛 (This Love)',
      album: '太陽的後裔 韓劇原聲帶',
      signer: 'DAVICHI ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Our Story',
      album: '十八歲的瞬間 韓劇原聲帶',
      signer: '邕聖祐 ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Love',
      album: '你也是人類嗎？ 韓劇原聲帶',
      signer: 'LYn ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'You’re My Graden',
      album: '大力女子都奉順 韓劇原聲帶',
      signer: '鄭恩地 ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
  ],
};

export const japanSongsCard = {
  title: '點聽日語榜',
  more: {
    link: '#',
    text: '更多榜單',
  },
  cards: [
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: '這份愛 (This Love)',
      album: '太陽的後裔 韓劇原聲帶',
      signer: 'DAVICHI ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Our Story',
      album: '十八歲的瞬間 韓劇原聲帶',
      signer: '邕聖祐 ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Love',
      album: '你也是人類嗎？ 韓劇原聲帶',
      signer: 'LYn ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'You’re My Graden',
      album: '大力女子都奉順 韓劇原聲帶',
      signer: '鄭恩地 ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: '這份愛 (This Love)',
      album: '太陽的後裔 韓劇原聲帶',
      signer: 'DAVICHI ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Our Story',
      album: '十八歲的瞬間 韓劇原聲帶',
      signer: '邕聖祐 ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Love',
      album: '你也是人類嗎？ 韓劇原聲帶',
      signer: 'LYn ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'You’re My Graden',
      album: '大力女子都奉順 韓劇原聲帶',
      signer: '鄭恩地 ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: '這份愛 (This Love)',
      album: '太陽的後裔 韓劇原聲帶',
      signer: 'DAVICHI ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Our Story',
      album: '十八歲的瞬間 韓劇原聲帶',
      signer: '邕聖祐 ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Love',
      album: '你也是人類嗎？ 韓劇原聲帶',
      signer: 'LYn ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'You’re My Graden',
      album: '大力女子都奉順 韓劇原聲帶',
      signer: '鄭恩地 ',
      action: {
        text: '試聽',
        link: '#',
      },
    },
  ],
};

export const dramaGoodsCard = {
  title: '追劇精選好物',
  more: {
    link: '#',
    text: '更多精選好物',
  },
  cards: [
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: '韓國EUNYUL面膜-玻尿酸22ml',
      description: '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: "KIEHL's 契爾氏 激光極淨白玻尿酸精華面膜(升級...",
      description: '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: '韓國EUNYUL面膜-玻尿酸22ml',
      description: '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: '正官庄 高麗蔘精EVERYTIME 10ml 30包',
      description: '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: 'Yodo優朵超細柔抽取式花紋家用紙150抽x72包/箱',
      description: '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: '紅牛能量飲料250ml*4入/組',
      description: '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
    },
  ],
};

export const classicSongsCard = {
  title: '收藏動人的經典歌曲',
  more: {
    link: '#',
    text: '更多鈴聲',
  },
  cards: [
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: '這份愛 (This Love)',
      album: '太陽的後裔 韓劇原聲帶',
      signer: 'DAVICHI ',
      action: {
        text: '下載',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Our Story',
      album: '十八歲的瞬間 韓劇原聲帶',
      signer: '邕聖祐 ',
      action: {
        text: '下載',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Love',
      album: '你也是人類嗎？ 韓劇原聲帶',
      signer: 'LYn ',
      action: {
        text: '下載',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'You’re My Graden',
      album: '大力女子都奉順 韓劇原聲帶',
      signer: '鄭恩地 ',
      action: {
        text: '下載',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: '這份愛 (This Love)',
      album: '太陽的後裔 韓劇原聲帶',
      signer: 'DAVICHI ',
      action: {
        text: '下載',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Our Story',
      album: '十八歲的瞬間 韓劇原聲帶',
      signer: '邕聖祐 ',
      action: {
        text: '下載',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Love',
      album: '你也是人類嗎？ 韓劇原聲帶',
      signer: 'LYn ',
      action: {
        text: '下載',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'You’re My Graden',
      album: '大力女子都奉順 韓劇原聲帶',
      signer: '鄭恩地 ',
      action: {
        text: '下載',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: '這份愛 (This Love)',
      album: '太陽的後裔 韓劇原聲帶',
      signer: 'DAVICHI ',
      action: {
        text: '下載',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Our Story',
      album: '十八歲的瞬間 韓劇原聲帶',
      signer: '邕聖祐 ',
      action: {
        text: '下載',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'Love',
      album: '你也是人類嗎？ 韓劇原聲帶',
      signer: 'LYn ',
      action: {
        text: '下載',
        link: '#',
      },
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music-thumb-01.jpg',
      title: 'You’re My Graden',
      album: '大力女子都奉順 韓劇原聲帶',
      signer: '鄭恩地 ',
      action: {
        text: '下載',
        link: '#',
      },
    },
  ],
};

export const dramaToolsItem = {
  title: '追劇讓我暫時脫離現實，體驗 1% 極致的美好人生。',
  withComma: true,
  subtitle1: '遠傳生活圈為',
  subtitleHighlight: '追劇族',
  subtitle2: '打理日常的大小事',
  item: [
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shopping-1.svg',
      title: '金泰熙著裝',
      description: 'friDay購物',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-57.svg',
      title: '懶人記帳本',
      description: 'friDay 57',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-music.svg',
      title: '必聽的梨泰院',
      description: 'friDay音樂',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-photo.svg',
      title: '保存甜蜜回憶',
      description: 'friDay相片書',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/laundry.svg',
      title: '幫你洗衣服',
      description: '潔衣家洗衣服務',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/flight.svg',
      title: '出國上網',
      description: '遠遊卡',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/flight-video.svg',
      title: '直播新聞',
      description: 'friDay影音',
    },
  ],
};

export const dramaPromoCard = {
  title: '專屬你的追劇方案',
  cards: [
    {
      image: '/resources/cbu/life-circle-drama/images/plan-thumbnail-01.jpg',
      title: '499吃到飽，追劇穩定不中斷',
      description: '送$500禮券，加$99再送小米手環4。',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/plan-thumbnail-01.jpg',
      title: '4.5G方案配平板，享受用大螢幕看劇！',
      description: '送$500禮券，加$99再送小米手環4。',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/plan-thumbnail-01.jpg',
      title: '陪你宅在家，輕鬆換新機，再送網購金',
      description: '送$500禮券，加$99再送小米手環4。',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/plan-thumbnail-01.jpg',
      title: '499吃到飽，追劇穩定不中斷',
      description: '送$500禮券，加$99再送小米手環4。',
      link: '#',
    },
  ],
};

export const eventPromoData = {
  image: {
    md: '/resources/cbu/life-circle-drama/images/cbu_event_banner_1920x195.jpg',
    sm: '/resources/cbu/life-circle-drama/images/cbu_event_banner_700x496.jpg',
  },
  title: '08/08邕聖祐、金香起、申承浩來台',
  description: '18歲的瞬間 Happy Together 遠傳 FriDay影音會員同樂會！',
  action: {
    text: '免費報名',
    link: '#',
  },
};

export const dramaLifeCircleCard = {
  title: '探索更多生活圈',
  cards: [
    {
      image: '/resources/cbu/life-circle-drama/images/life-story-thumbnail-01.png',
      title: '玩家聯盟時刻',
      description: '',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/life-story-thumbnail-02.png',
      title: '週末自煮烘焙日',
      description: '',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/life-story-thumbnail-03.png',
      title: '跑山環島騎到飽',
      description: '',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/life-story-thumbnail-01.png',
      title: '斜槓老爸',
      description: '',
      link: '#',
    },
  ],
};

export const slashieBanner0 = {
  mainBanner: true,
  slogan: {
    md: '/resources/cbu/life-circle-slashie/images/life-story-slogan-02.png',
    sm: '/resources/cbu/life-circle-slashie/images/life-story-slogan-02-m.png',
  },
  image: {
    md: '/resources/cbu/life-circle-slashie/images/cbu-lifestory-banner-06-1920x470.jpg',
    sm: '/resources/cbu/life-circle-slashie/images/cbu-lifestory-banner-06-1920x470.jpg',
  },
  link: '#',
  title: '智慧生活替我解決日常瑣事',
  alt: 'alt text',
  icon: '/resources/cbu/life-circle-slashie/images/tea-time.svg',
  subtitle: '太晚睡，需要咖啡醒腦',
  description: '',
  type: 'img-right',
};

export const slashieBanner1 = {
  image: {
    md: '/resources/cbu/life-circle-slashie/images/cbu-lifestory-banner-07-1920x470.jpg',
    sm: '/resources/cbu/life-circle-slashie/images/cbu-lifestory-banner-07-1920x470.jpg',
  },
  link: '#',
  title: '家人的叮嚀，有愛講幫忙傳達，出門東西不健忘！',
  alt: 'alt text',
  icon: '/resources/cbu/life-circle-slashie/images/commuter.svg',
  subtitle: '糟糕！忘記要帶什麼了?',
  description: '',
  type: 'img-left',
};
export const slashieBanner2 = {
  image: {
    md: '/resources/cbu/life-circle-slashie/images/cbu-lifestory-banner-08-1920x470.jpg',
    sm: '/resources/cbu/life-circle-slashie/images/cbu-lifestory-banner-08-1920x470.jpg',
  },
  link: '#',
  title: '長輩的3C問題，通通轉介數位專家。',
  alt: 'alt text',
  icon: '/resources/cbu/life-circle-slashie/images/questions.svg',
  subtitle: '奶奶又打來問問題！',
  description: '',
  type: 'img-right',
};
export const slashieBanner3 = {
  image: {
    md: '/resources/cbu/life-circle-slashie/images/cbu-lifestory-banner-09-1920x470.jpg',
    sm: '/resources/cbu/life-circle-slashie/images/cbu-lifestory-banner-09-1920x470.jpg',
  },
  link: '#',
  title: '用智慧手錶定位、通話，在安全便利的地方接小孩。',
  alt: 'alt text',
  icon: '/resources/cbu/life-circle-slashie/images/family.svg',
  subtitle: '放學接待區好多人！',
  description: '',
  type: 'img-left',
};
export const slashieBanner4 = {
  image: {
    md: '/resources/cbu/life-circle-slashie/images/cbu-lifestory-banner-10-1920x470.jpg',
    sm: '/resources/cbu/life-circle-slashie/images/cbu-lifestory-banner-10-1920x470.jpg',
  },
  link: '#',
  title: '白天忙公事，晚上做家事，瑣事真的都幫我處理好了。',
  alt: 'alt text',
  icon: '/resources/cbu/life-circle-slashie/images/night.svg',
  subtitle: '收到銀行繳費提醒',
  description: '',
  type: 'img-right',
};

export const slashieToolsItem = {
  title: '不用當超人，當個懂得生活的斜槓老爸。',
  withComma: true,
  subtitle1: '遠傳生活圈為',
  subtitleHighlight: '斜槓老爸',
  subtitle2: '打理日常的大小事',
  item: [
    {
      image: '/resources/cbu/life-circle-slashie/images/flight-video.svg',
      title: '直播新聞',
      description: 'friDay影音',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/smart-speaker.svg',
      title: '問台積電股市',
      description: '愛講語音助理',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/bobee-parking.svg',
      title: '車停在哪',
      description: '愛車守護寶',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/friday-shopping-2.svg',
      title: '解憂餐券',
      description: 'friDay購物',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/laundry.svg',
      title: '幫你洗衣服',
      description: '潔衣家洗衣服務',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/friday-57.svg',
      title: '小細節都省',
      description: 'friDay 57',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/3-c-doctor.svg',
      title: '3C解惑達人',
      description: '數位萬事通',
    },
  ],
};

export const slashiePromoCard = {
  title: '顧老顧少的省心方案',
  cards: [
    {
      image: '/resources/cbu/life-circle-slashie/images/plan-thumbnail-04.png',
      title: '199夠用最實在',
      description: '單門號或家電任你選！',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/plan-thumbnail-04.png',
      title: '陪孩子一起探索世界',
      description: '學生憑證件可享指定專案再折$1,000優惠',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/plan-thumbnail-04.png',
      title: '超省$1.8親子輕鬆講易付卡',
      description: '網內、網外或市話，每分鐘只要$1.8元，怎麼打都划算！',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/plan-thumbnail-04.png',
      title: '199夠用最實在',
      description: '單門號或家電任你選！',
      link: '#',
    },
  ],
};

export const slashieLifeCircleCard = {
  title: '探索更多生活圈',
  cards: [
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-05.png',
      title: '追劇人生',
      description: '',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-01.png',
      title: '玩家聯盟時刻',
      description: '',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-02.png',
      title: '週末自煮烘焙日',
      description: '',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-03.png',
      title: '跑山環島騎到飽',
      description: '',
      link: '#',
    },
  ],
};

export const slashieBancard1 = {
  image: '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-02.jpg',
  title: '最方便的傳聲筒，不必找紙筆，對著愛講說話即可完成音箱留言。',
  subtitle: '遠傳愛講語音助理',
  type: 'img-left',
  description: '',
  checkList: false,
  checkListArrList: '',
  action: {
    text: '探索更多愛講功能',
    link: '#',
  },
};
export const slashieBancard2 = {
  image: '/resources/cbu/life-circle-slashie/images/cbu-smarthome-thumb-03.jpg',
  subtitle: '遠傳數位萬事通',
  title: '專業團隊24小時待命，快速解決3C大小事。',
  description: '',
  checkList: true,
  checkListArrList: [
    '專家團隊24小時不限次數服務',
    '遠端診斷排除問題，給予貼心支援',
    '專家即時教學使用方式，提升用戶體驗。',
  ],
  type: 'img-left',
  action: {
    text: '探索更多愛講功能',
    link: '#',
  },
};

export const articleGoodsCard = {
  title: '開學必備清單',
  more: {
    link: '#',
    text: '更多商品優惠',
  },
  cards: [
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: 'LAMINA 城市假期 天絲四件式兩用被套床包組(雙人)',
      description: '單人/雙人/加大均一價，校園團購首選。',
      price: '$2180',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: 'KEYWAY收納系列',
      description: '學生宿舍收納良品，多樣大小組合，限時買1送1。',
      price: '$379',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: 'Acer翻轉觸控新機福利機',
      description: '天天可領購物券，現領現折',
      price: '$9,900',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: 'adidas 休閒鞋 Superstar 低筒 女鞋 DB3346',
      description: '開學缺一雙鞋？看看運動鞋聯合特賣',
      price: '$2490',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: 'Panasonic國際 輕巧型速乾吹風機EH-ND11-A',
      description: '輕巧好攜帶，迅速吹乾頭髮',
      price: '$2990',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-drama/images/friday-shop-thumb-01.jpg',
      title: '紅牛能量飲料250ml*4入/組',
      description: '追劇、保養兩兼顧，輕鬆擁有女主臉蛋！',
      price: '$500',
      link: '#',
    },
  ],
};
export const articleLifeCircleCard = {
  title: '<h2 class="section-title">探索更多生活圈</h2>',
  cards: [
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-05.png',
      title: '追劇人生',
      description: '',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-01.png',
      title: '玩家聯盟時刻',
      description: '玩家聯盟時刻',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-02.png',
      title: '週末自煮烘焙日',
      description: '',
      link: '#',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-03.png',
      title: '跑山環島騎到飽',
      description: '',
      link: '#',
    },
  ],
};
export const articleLifeCircleCard1 = {
  title: '<h2 class="section-title">探索更多生活圈</h2>',
  cards: [
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-05.png',
      content: '<div class="text">追劇人生追劇人生追劇人生追劇人生追劇人生</div>',
      link: '#',
      target: '',
      fetKey: '',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-01.png',
      content: '<div class="text">玩家聯盟時刻</div>',
      link: '#',
      target: '',
      fetKey: '',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-02.png',
      content: '<div class="text">週末自煮烘焙日</div>',
      link: '#',
      target: '',
      fetKey: '',
    },
    {
      image: '/resources/cbu/life-circle-slashie/images/life-story-thumbnail-03.png',
      content: '<div class="text">跑山環島騎到飽</div>',
      link: '#',
      target: '',
      fetKey: '',
    },
  ],
};

// curation
export const curationTabs = {
  tabs: {
    name: 'tab-micro',
    list: [
      {
        name: 'tab-1',
        label: '開箱智慧生活',
        value: '開箱智慧生活',
        text: '開箱智慧生活',
        index: 0,
      },
      {
        name: 'tab-2',
        label: '影劇話題',
        value: '影劇話題',
        text: '影劇話題',
        index: 1,
      },
      {
        name: 'tab-3',
        label: '大師戀愛論',
        value: '大師戀愛論',
        text: '大師戀愛論',
        index: 2,
      },
      {
        name: 'tab-4',
        label: '防疫小確幸',
        value: '防疫小確幸',
        text: '防疫小確幸',
        index: 3,
      },
      {
        name: 'tab-5',
        label: '健康食代',
        value: '健康食代',
        text: '健康食代',
        index: 4,
      },
      {
        name: 'tab-6',
        label: '車聯網',
        value: '車聯網',
        text: '車聯網',
        index: 5,
      },
      {
        name: 'tab-7',
        label: '智慧城市',
        value: '智慧城市',
        text: '智慧城市',
        index: 6,
      },
    ],
  },
};

export const curationContent = {
  mainContent: [
    {
      id: 'topic-01',
      period: '開箱智慧生活',
      date: '2019.12.12',
      title: '2020居家必備的一組吸塵器！無線、高續航、配件齊全萬元初',
      imgVertical: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-01.jpg',
      imgHorizontal: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-01.jpg',
      brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
      link: '#',
      fbLink: '#fb',
      lineLink: '#line',
    },
    {
      id: 'topic-02',
      period: '開箱智慧生活',
      date: '2019.12.12',
      title: '簡單生活之居家空間三要素，下一個居家IG網紅就是你',
      imgVertical: '',
      imgHorizontal: '',
      brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
      link: '#',
      fbLink: '#',
      lineLink: '#',
    },
    {
      id: 'topic-03',
      period: '開箱智慧生活',
      date: '2019.12.12',
      title: '[開箱] 夏天這麼長，風扇更不能隨便 – 用 Stadler Form 完整你對夏天...',
      imgVertical: '',
      imgHorizontal: '',
      brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
      link: '#',
      fbLink: '#',
      lineLink: '#',
    },
  ],
  sideContent: [
    [
      {
        id: 'topic-01',
        period: '開箱智慧生活',
        date: '2020.07.20',
        title: '簡單生活之居家空間三要素，下一個居家IG網就...',
        img: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-02.jpg',
        imgVertical: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-02.jpg',
        imgHorizontal: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-02.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#fb30',
        lineLink: '#',
      },
      {
        id: 'topic-02',
        period: '開箱智慧生活',
        date: '2019.12.12',
        title: '美版 Google Nest mini 微開箱與控...',
        img: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-02.jpg',
        imgVertical: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-02.jpg',
        imgHorizontal: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-02.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
      {
        id: 'topic-03',
        period: '開箱智慧生活',
        date: '2019.12.12',
        title: 'Aloeair 空氣清淨機 PA600F 簡潔高...',
        img: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-02.jpg',
        imgVertical: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-02.jpg',
        imgHorizontal: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-02.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
    ],
    [
      {
        id: 'topic-01',
        period: '大師戀愛論',
        date: '2019.12.12',
        title: '【練習愛情】所謂穩定的愛情狀態｜Leyes',
        img: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-03.jpg',
        imgVertical: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-03.jpg',
        imgHorizontal: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-03.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#fb40',
        lineLink: '#',
      },
      {
        id: 'topic-02',
        period: '大師戀愛論',
        date: '2019.12.12',
        title: '開店熟客經營｜一個老客戶勝過開發...',
        img: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-03.jpg',
        imgVertical: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-03.jpg',
        imgHorizontal: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-03.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
      {
        id: 'topic-03',
        period: '大師戀愛論',
        date: '2019.12.12',
        title: '開餐廳必備｜提高淨利小心機-翻桌...',
        img: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-03.jpg',
        imgVertical: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-03.jpg',
        imgHorizontal: '/resources/cbu/life-circle-curation/images/lifecircle-article-thumb-03.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
    ],
  ],
};

export const curationArticle = [
  {
    title: '開箱智慧生活',
    loadMore: true,
    list: [
      {
        id: 'topic-01-1',
        period: '大師戀愛論',
        date: '2019.12.12',
        title: '開箱智慧生活',
        img: '/resources/cbu/life-circle-curation/images/ebu-medium-article-banner-crop.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
      {
        id: 'topic-01-2',
        period: '開箱智慧生活',
        date: '2019.12.12',
        title: '智慧城市 Level Up 電信商營運大進化',
        img: '/resources/cbu/life-circle-curation/images/ebu-medium-article-banner-crop.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
    ],
  },
  {
    title: '影劇話題',
    loadMore: false,
    list: [
      {
        id: 'topic-02-1',
        period: '影劇話題',
        date: '2019.12.12',
        title: '醫療商機',
        img: '/resources/cbu/life-circle-curation/images/ebu-medium-article-banner-crop.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
      {
        id: 'topic-02-2',
        period: '影劇話題',
        date: '2019.12.12',
        title: '智慧城市 Level Up 電信商營運大進化',
        img: '/resources/cbu/life-circle-curation/images/ebu-medium-article-banner-crop.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
    ],
  },
  {
    title: '大師戀愛論',
    loadMore: false,
    list: [
      {
        id: 'topic-03-1',
        period: '大師戀愛論',
        date: '2019.12.12',
        title: 'AIOT',
        img: '/resources/cbu/life-circle-curation/images/ebu-medium-article-banner-crop.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
    ],
  },
  {
    title: '防疫小確幸',
    loadMore: false,
    list: [
      {
        id: 'topic-04-1',
        period: '防疫小確幸',
        date: '2019.12.12',
        title: '全部期刊',
        img: '/resources/cbu/life-circle-curation/images/ebu-medium-article-banner-crop.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
    ],
  },
  {
    title: '健康食代',
    loadMore: false,
    list: [
      {
        id: 'topic-05-1',
        period: '健康食代',
        date: '2019.12.12',
        title: '5G',
        img: '/resources/cbu/life-circle-curation/images/ebu-medium-article-banner-crop.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
    ],
  },
  {
    title: '車聯網',
    loadMore: false,
    list: [
      {
        id: 'topic-06-1',
        period: '車聯網',
        date: '2019.12.12',
        title: '車聯網 電信商營運大進化',
        img: '/resources/cbu/life-circle-curation/images/ebu-medium-article-banner-crop.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
    ],
  },
  {
    title: '智慧城市',
    loadMore: false,
    list: [
      {
        id: 'topic-07-1',
        period: '智慧城市',
        date: '2019.12.12',
        title: '智慧城市 Level Up 電信商營運大進化',
        img: '/resources/cbu/life-circle-curation/images/ebu-medium-article-banner-crop.jpg',
        brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
        link: '#',
        fbLink: '#',
        lineLink: '#',
      },
    ],
  },
];

export const curationLoadMoreData = [
  {
    id: 'topic-03',
    period: '25',
    date: '2019.12.12',
    title: '智慧城市 Level Up 電信商營運大進化',
    img: '/resources/cbu/life-circle-curation/images/ebu-medium-article-banner-crop.jpg',
    brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
    link: '#',
  },
  {
    id: 'topic-04',
    period: '65',
    date: '2019.12.12',
    title: '智慧城市 Level Up 電信商營運大進化',
    img: '/resources/cbu/life-circle-curation/images/ebu-medium-article-banner-crop.jpg',
    brief: '靈活運用大(大數據)、人(人工智慧)、物(物聯網)技術者，將是個人、企業與國家永續成長的決勝關鍵。',
    link: '#',
  },
];
export const selectedArticle = {
  title: '人氣文章',
  content: [
    {
      id: 'link_01',
      content: 'Dyson TP06智慧空氣清淨機 消除甲醛 為家中長輩、小孩帶來室內清新好空氣',
      link: '#',
    },
    {
      id: 'link_02',
      content: '今天起就讓LG WiFi濕拖清潔機器人幫你做家事！ LG CordZero...',
      link: '#',
    },
    {
      id: 'link_03',
      content: '簡單生活之居家空間三要素，下一個居家IG網紅就是你',
      link: '#',
    },
    {
      id: 'link_04',
      content: '恆壓電控水箱的石頭 S5 Max',
      link: '#',
    },
    {
      id: 'link_05',
      content: '麗克特微笑鬆餅機 | Recolte Smile Baker RSM-1 在家自製鬆餅，好吃又...',
      link: '#',
    },
    {
      id: 'link_06',
      content: '[開箱] 夏天這麼長，風扇更不能隨便！Stadler Form 完整你對夏天的涼爽想像',
      link: '#',
    },
  ],
};

export const curationPromotion = {
  image: {
    md: '/resources/cbu/life-circle-curation/images/sidebar-ad.jpg',
    sm: '/resources/cbu/life-circle-curation/images/cbu-promotion-sidebar-750x540.jpg',
  },
  title: '遠傳遠遊卡 friDay聯名卡 卡友專區',
  content: '於friDay購物結帳頁購買遠遊卡並輸入優惠券折抵序號(ftcard)享九折優...',
  action: {
    link: '#',
    text: '了解更多',
  },
};

export const milestone = [
  {
    year: '2020',
    list: [
      '於臺北市府跨年晚會成為全臺第一個運用5G技術完成多現場、低遲延的娛樂表演，領先同業打造全臺最大的5G戶外場域。',
    ],
  },
  {
    year: '2019',
    list: [
      '於「台北5G國際高峰會」現場啟動5G訊號結合AI檢驗技術，引領產業加速邁向工業4.0。',
      '遠傳5G走出實驗室，領先全臺完成LTE與5G NR現網雙連接測試。',
      '跨國結盟JTBC電視台，全臺首發5G結合17 Media現場直播節目。',
      '攜手北市府打造全臺雙第一，率先啟動「5G開放試驗場域」暨「商業實驗研發電信網路」。',
      '結盟聯發科技打造5G晶片測試環境。',
      '遠傳5G進駐全家便利商店「科技概念2號店，立下臺灣零售業創舉。',
      '攜手三大醫學中心，啟動國內第一個5G遠距診療前瞻計畫。',
    ],
  },
  {
    year: '2018',
    list: [
      '率先加入國際5G汽車通訊技術聯盟，為全臺電信業之首，並成立遠傳5G先鋒隊，引領全臺車聯網產業鏈。',
      '加入「DOCOMO亞洲物聯網計畫」，亞洲首創行動通訊物聯網生態圈啟動，加速發展跨國聯網服務。',
      '遠傳5G先鋒隊正式啟航， 領先全臺 ，建構5G車聯網產業鏈創新基地。',
    ],
  },
  {
    year: '2017',
    list: [
      '遠傳5G實驗室在臺灣首次5G核示網路展示NFV及Network Slicing關鍵技術。',
      '獨步全臺，率先展示愛立信合作的LAA，飆出1Gbps極速。',
    ],
  },
];

export const fiveGNewscards = {
  title: "<h2 class='section-title'><span style='color:#D3060F'>心5G</span>新鮮事</h2>",
  more: { text: '看更多', link: '#' },
  cards: [
    {
      public_at: '2020/01/16',
      meta: '最新消息',
      title: '遠傳取得發展5G所需頻譜　全面啟動5G業務',
      link:
        'https://www.fetnet.net/corporate/NewsReleases-content.html?id=202001171153&_ga=2.188014888.162253882.1584591174-1256035182.1578309344',
    },
    {
      public_at: '2020/01/16',
      meta: '最新消息',
      title: '遠傳取得發展5G所需頻譜　全面啟動5G業務',
      link:
        'https://www.fetnet.net/corporate/NewsReleases-content.html?id=202001171153&_ga=2.188014888.162253882.1584591174-1256035182.1578309344',
    },
    {
      public_at: '2020/01/01',
      meta: '最新消息',
      title: '遠傳助攻2020跨年盛典，百萬收視喜迎5G元年',
      link: 'https://www.fetnet.net/corporate/NewsReleases-content.html?id=202001061449',
    },
    {
      public_at: '2019/12/31',
      meta: '最新消息',
      title: '遠傳4G、5G 雙網齊飆，打造全台最大5G娛樂場域',
      link: 'https://www.fetnet.net/corporate/NewsReleases-content.html?year=2019&id=202001021520',
    },
  ],
};
