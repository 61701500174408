import React, { Component } from 'react'
import PanelFigure1 from '../../components/panelContent/PanelFigure1';
import { Grid } from '@material-ui/core';
import LoadMore from '../LoadMore'
import PropTypes from 'prop-types';
class PanelMultiSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  loadMore = () => {
    // call API 取得更多影片，透過 props 傳回新的 card
    if (this.props.loadMore) this.props.loadMore(this.state.currentPage);
  };
  render() {
    return (
      <section className='panel-multi-step pb-md-10 pb-6 pt-1' style={!!this.props.bg ? { backgroundImage: `url(${this.props.bg})` } : null}>
        <div className='fui-container mt-md-4 mt-0 pt-2 mb-1'>
          <h2 className="mt-0 mb-8">{this.props.title}</h2>
          <div className="paper mt-4 mb-0 pb-md-7 pb-4">
            <Grid container spacing={2}>
              {this.props.list.map((item, i) => {
                return (
                  <Grid className="position-relative pt-4" item xs={12} sm={12} md={4} key={item.name + i}>
                    <div className="circle">{i + 1}</div>
                    <PanelFigure1 inline={false} image={item.img} caption={item.name} />
                  </Grid>
                )
              })}
            </Grid>
            <LoadMore
              moreLabel="展開看更多"
              className="mb-0"
              noMoreLabel="已經沒有更多內容囉！"
              click={() => this.props.loadMore()}
              load={this.props.hasMore}
            />
          </div>
        </div>
      </section>
    )
  }
}

PanelMultiSteps.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string,
    })
  ),
  bg: PropTypes.string,
  loadMore: PropTypes.func
}
export default PanelMultiSteps;