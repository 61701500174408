import React from 'react';
import Button from '../Button';
import Modal from 'react-modal';

import PropTypes from 'prop-types';

class AppTrail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth < 768,
      currentModal: {
        md: '',
        sm: '',
      }
    }
  }

  componentDidMount = () => {
    if(window === 'undefined') return;

    window.addEventListener('resize', e => {
      this.setState({
        isMobile: window.innerWidth < 768
      });
    })
  };

  openModal = (type) => {
    this.props.openModal({
      type: 'app-trial',
      ...this.props.actions[type]
    })
  }
  
  render () {
    return (
      <section className="fui-app-trial" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + (this.state.isMobile ? this.props.image.sm : this.props.image.md)})`
      }}>
        <div className="fui-container">
          <h2>{this.props.title}</h2>
          <div className="description">{this.props.description}</div>
          <div className="content" dangerouslySetInnerHTML={{__html: this.props.content}}></div>
          <div className='fui-action'>
            <Button btnStyle='secondary' onClick={e => this.openModal('android')}>Android 教學</Button>
            <Button btnStyle='secondary' onClick={e => this.openModal('ios')}>iOS 教學</Button>
          </div>
        </div>

        
      </section>
    )
  }
}

AppTrail.propTypes = {
  image: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  openModal: PropTypes.func,
  actions: PropTypes.shape({
    android: PropTypes.shape({
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
    ios: PropTypes.shape({
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
  }),
}

export default AppTrail