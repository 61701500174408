import React from 'react';
import Link from './Link';


class SearchBoxList extends React.Component {
  constructor (props) {
    super(props);
    
    this.state = {
      category: decodeURIComponent(props.match.params.category)
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(prevProps.match.params.category !== this.props.match.params.category) {
      this.setState({
        category: decodeURIComponent(this.props.match.params.category)
      })
    }
  }

  gotoCategory = (selected) => {
    if(this.state.category !== selected.text) {
      this.props.history.push(`${this.props.tab.link}/` + encodeURIComponent(selected.link))
    } else {
      this.props.history.push(`${this.props.tab.link}`);
      this.setState({
        category: ''
      })
    }
  }

  render () {
    return (
      <div className="fui-container">
        <div className="fui-search-tag-list">
          {
            this.props.searchList.map((item, i) => (
              <div 
              role="button"
              key={`find-prod-search-${i}`} 
              className={`fui-search-tag-item ${this.state.category === item.text ? 'is-active' : ''}`} 
              onClick={e => this.gotoCategory(item)}>
                {item.text}
                <i className="icon-check"></i>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}

export default SearchBoxList;