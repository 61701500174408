import React from 'react';
import Formsy from 'formsy-react';
import NavTab from '../../components/partials/NavTab';
import Button from '../../components/Button';
import Breadcrumb from '../../components/Breadcrumb';
import FindItemBanner from '../../components/partials/banner/FindItemBanner';
import SearchBoxList from '../../components/SearchBoxList';
import SectionCarousel4 from '../../components/partials/card/SectionCarousel4';
import SectionFaqTab from '../../components/partials/collapse/SectionFaqTab';
import Switch from '../../components/Switch';
import Dropdown from '../../components/Dropdown';
import LabelInput from '../../components/form/LabelInput';
import ProductCard from '../../components/card/ProductCard';
import PromotionCard from '../../components/card/PromotionCard';
import ProdPromoCard from '../../components/card/ProdPromoCard';
import LoadMore from '../../components/LoadMore';

import * as Mock from '../../mock/FindProduct';

class FindProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canSubmit: false,
      showFilter: false,
      isMobile: window.innerWidth < 768,
      category: props.match.params.category || '全部',
      tab: 0,
      currentTab: {label: '', link: ''},
      showMore: false,
      banner: Mock.banner[props.match.params.tab || 'phone'],
      searchList: Mock.searchList[props.match.params.tab || 'phone'],
      pageLoading: false,
      product: {
        total: 0,
        list: [],
        current: 0,
        perPage: 12,
        pages: 0
      },
      form: {
        type: {value: false},
        brand: {value: '全部品牌'},
        recommand: {value: '熱門推薦'},
        keyword: {value: ''},
      }
    }
  }

  componentDidMount = () => {
    window.addEventListener('resize', e => {
      this.setState({
       isMobile: window.innerWidth < 768
      })
    })
    this.getTabIndex();
    this.forceUpdate();
    this.getMorePage();
  }

  componentDidUpdate = (prevProps) => {
    
    if(prevProps.match.params.tab !== this.props.match.params.tab || prevProps.match.params.category !== this.props.match.params.category) {
      this.getTabIndex();

      this.setState({
        category: this.props.match.params.category || '全部',
        banner: Mock.banner[this.props.match.params.tab],
        searchList: Mock.searchList[this.props.match.params.tab],
        product: {
          total: 0,
          list: [],
          current: 0,
          perPage: 12,
          pages: 0
        },
      })

      setTimeout(() => {
        this.getMorePage();
        this.forceUpdate();
      }, 300)
    }
  }

  getTabIndex = () => {
    let tabIndex = 0;
    if(this.props.match.params.tab) {
      Mock.navTab.tabs.list.forEach((item, i) => {
        if(item.link.indexOf(this.props.match.params.tab) > -1) {
          tabIndex = i 
        }
      })
    } 

    this.setState({
      tab: tabIndex,
      currentTab: Mock.navTab.tabs.list[tabIndex]
    })
  }

  getMorePage = () => {
    const {product} = this.state;
    let list = Object.assign(Mock.products[this.props.match.params.tab || 'phone']);
    let pages = Math.ceil(list.length / product.perPage);

    if(pages < product.current + 1) return;

    this.setState({
      product: {
        perPage: product.perPage,
        current: product.current + 1,
        total: list.length,
        list: [...product.list, ...list.slice(product.current * product.perPage, (product.current + 1) * product.perPage)],
        pages: pages
      }
    })

    this.forceUpdate();
  }

  inputChange = (name, value) => {
    let form = Object.assign(this.state.form)

    form[name].value = value 
    this.setState({
      form: form
    })

    this.forceUpdate();
  }

  resetSearch = () => {
    this.setState({
      form: {
        type: {value: false},
        brand: {value: '全部品牌'},
        recommand: {value: '熱門推薦'},
        keyword: {value: ''},
      }
    })
  }

  searchProduct = () => {

  }

  enableButton = () => {

  }

  disableButton = () => {

  }

  render () {
    const {form, product} = this.state;
    return (
      <main className={this.state.pageLoading ? 'is-api-loading' : ''}>
        <NavTab {...Mock.navTab} default={this.state.tab} />
        <Breadcrumb breadcrumb={[
          {text: '首頁', link: '/'},
          {text: '找商品', link: '/estore/find-product'},
          {text: this.state.currentTab.label, link: this.state.currentTab.link},
        ]} color="white" />
        <FindItemBanner {...this.state.banner}/>
        <SearchBoxList 
          match={this.props.match}
          tab={this.state.currentTab}
          location={this.props.location}
          history={this.props.history}
          searchList={this.state.searchList} 
        />
        <div className='search-bar'>
          <div className='fui-container'>
            <Formsy 
              onValid={this.enableButton} 
              onInvalid={this.disableButton}
              className={this.state.showFilter ? 'with-shadow' : ''}
            >
              <div className='search-head'>
                { this.state.tab !== 3 ? (
                    <div className="form-group">
                      <Switch on='單機' off="搭門號" name='type' checked={form.type.value} onChange={(e, checked) => this.inputChange('type', checked)} />
                    </div>
                  ) : null
                }
                <div role="button" 
                className='fui-button is-text m-0 d-block d-sm-none' 
                onClick={e => this.setState({showFilter: !this.state.showFilter})}>
                  {
                    this.state.showFilter ? (
                      <i className="icon-close m-0 mr-1"></i>
                    ) : (
                      <img src={'/resources/common/images/filter.svg'} alt='篩選' width="24" className='mr-1' />
                    )
                  }
                  <span className='text'>篩選</span>
                </div>
              </div>
              <div className={`search-body ${this.state.showFilter ? 'd-flex' : 'd-none'} d-sm-flex`}>
                <div className="search-groups">
                  { this.state.tab !== 3 ? (
                    <div className="form-group">
                      {
                        this.state.isMobile ? (
                          <label>品牌</label>
                        ) : null
                      }
                      <Dropdown 
                        label={form.brand.value}  
                        className={this.state.isMobile ? "is-button" : ''}
                        arrow={true}
                        value={form.brand.value} 
                        list={[
                          {text: '全部品牌', value: '全部品牌'},
                          {text: 'APPLE', value: 'APPLE'},
                          {text: 'SAMSUNG', value: 'SAMSUNG'},
                          {text: 'LG', value: 'LG'}
                        ]}
                        placeholder='全部品牌'
                        onChange={val => this.inputChange('brand', val)} 
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      {
                        this.state.isMobile ? (
                          <label>排序</label>
                        ) : null
                      }
                      <Dropdown 
                        label={form.recommand.value}  
                        className={this.state.isMobile ? "is-button" : ''}
                        arrow={true}
                        value={form.recommand.value} 
                        list={[
                          {text: '熱門推薦', value: '熱門推薦'},
                          {text: '最新上架', value: '最新上架'},
                          {text: '價格低到高', value: '價格低到高'},
                          {text: '價格高到低', value: '價格高到低'}
                        ]}
                        placeholder='熱門推薦'
                        onChange={val => this.inputChange('recommand', val)} 
                      />
                    </div>
                    )
                  }
                  <div className="form-group search-group">
                    <LabelInput 
                      label={this.state.isMobile ? '搜尋' : ''}
                      placeholder='輸入商品名稱'
                      name='keyword'
                      value={form.keyword.value}
                      onChange={this.inputChange}
                    />
                    <button className='fui-button is-text m-0 d-none d-md-block' onClick={this.searchProduct}>
                      <i className="icon-search"></i>
                    </button>
                  </div>
                </div>
                <div className="d-block d-md-none fui-buttons two-buttons">
                  <Button btnStyle='primary' onClick={this.searchProduct}>確認</Button>
                  <Button btnStyle='secondary' onClick={this.resetSearch}>清除</Button>
                </div>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="ribbon-bg">
          <section>
            <div className="fui-container">
              <h1>
                {decodeURIComponent(this.state.category)}
                <small className="ml-2 is-text-gray100">{product.total} 項商品</small>
              </h1>
              <div className="fui-cards four-card is-product no-scrollbar py-0 mb-1">
                {
                  product.list.map((prod, i) => (
                    (prod.type === 'product' || prod.type === 'wireless') ? (
                      <ProductCard {...prod} key={`find-prod-${i}`} />
                    ) : (
                      prod.type === 'promo-product' ? (
                        <ProdPromoCard {...prod} key={`find-prod-${i}`} />
                      ) : prod.type === 'promotion' ? (
                        <PromotionCard {...prod} key={`find-prod-${i}`} />
                      ) : null
                    )
                  ))
                }
              </div>
              <LoadMore 
              className='mb-0'
              moreLabel="看更多"
              noMoreLabel={'沒有更多商品'} 
              load={product.current < product.pages}
              click={this.getMorePage} />
            </div>
          </section>
          <SectionCarousel4 {...Mock.moreCards} />
        </div>
        <SectionFaqTab {...Mock.faq} />
      </main>
    )
  }
}

export default FindProduct;