import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import CollapseMenu from '../partials/collapse/CollapseMenu';
import Panel from '../panel/Panel';
import FaqCollapse from '../collapse/FaqCollapse';
export default class Faq extends Component {
    static propTypes = {
        payload: PropTypes.object,
        url: PropTypes.object,
        menuData: PropTypes.object
    };

    static defaultProps = {
        payload: null,
        url: null,
        menuData: {
            id: '123',
            name: '條款分類',
            content: [
                {
                    id: '1',
                    name: '隱私權條款',
                    link: '/help-center/terms-of-service/privacy'
                },
                {
                    id: '2',
                    name: '著作權條款',
                    link: '/help-center/terms-of-service/copyright'
                },
                {
                    id: '3',
                    name: '遠傳生活圈會員條款',
                    link: '/help-center/terms-of-service/lifecircle'
                },
            ]
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            pageOfItems: this.props.payload.faq[0].content,
            subtitle: this.props.payload.faq[0].subTitle,
        }
    }


    findTitleName(category, menu) {
        if (menu && menu.content && menu.content.length) {
            if (!category) return menu.content[0].name;

            return menu.content.find((item) => {
                return (item.id === category)
            }).name
        }
    }
    changeMenu = (val) => {
        console.log(`val`, val);
    }

    render() {
        const faq = this.props.payload.faqCard;
        const { category, pageOfItems, pageSetting } = this.state;
        const { url, menuData: menu } = this.props;

        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={4} className='pr-md-4 side-menu'>
                    {
                        menu && <CollapseMenu usage="help-center" menu={menu} selectMenu={this.changeMenu} />
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={8} className='faq-container'>
                    <Panel>
                        {
                            <div>
                                <h3>{this.findTitleName(category, menu)}</h3>
                                <div dangerouslySetInnerHTML={{ __html: this.state.subtitle }} ></div>
                            </div>
                        }
                        {
                            pageOfItems.length && pageOfItems.map((faq, j) => (
                                <div key={j}>
                                    <FaqCollapse
                                        key={`faq-collapse-${j}`}
                                        title={decodeURIComponent(faq.title)}
                                        content={decodeURIComponent(faq.content)}
                                        detailLink={url ? (url.faqDetailUrl + "?uuid=" + faq.uuid) : `/help-center/faq/detail/${faq.uuid}`}
                                        open={j === 0}
                                        feedback={e => console.log(e)}
                                        hasFeedback={false}
                                    />
                                </div>
                            ))
                        }
                    </Panel>
                </Grid>
            </Grid>
        )
    }
}
