import React, { useEffect, createRef } from "react";
import Slider from "react-slick";
import Button from "../../Button";
import ArrowLeftWhite from "../../animateArrow/ArrowLeftWhite";
import ArrowRightWhite from "../../animateArrow/ArrowRightWhite";
import ProductBgArrow from "../../animateArrow/ProductBgArrow";
import Card from "../../card/Card";
import { setArrowBtns } from "../../../utils/slider-tracking";
import PropTypes from "prop-types";
import YoutubeStyleLoader from "@ui/contentLoader/youtube";
import TitleTooltip from "@ui/card/TitleTooltip";

const LifeCircleNewsCarousel = (props) => {
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  const renderBg = () => {
    switch (props.bgStyle) {
      case "arrow":
        return (
          <div className="section-bg">
            <ProductBgArrow />
          </div>
        );
      default:
        return (
          <div className="section-bg">
            <ArrowLeftWhite />
            <ArrowRightWhite />
          </div>
        );
    }
  };

  const ref = createRef();

  useEffect(() => {
    if (!props["tracking-prefix"]) return;
    if (!ref || !ref.current) return;

    setArrowBtns(
      ref.current,
      `${props["tracking-prefix"]}${props.title || ""}${props.position || ""}`,
      { prev: "LastPage", next: "NextPage" }
    );
  }, []);

  return !props.ready ? (
    <div className="fui-container">
      <YoutubeStyleLoader width={1366} />
    </div>
  ) : typeof props.cards === "undefined" ? (
    ""
  ) : (
    <section ref={ref} className="fui-horzonal-cards coupon-list" id={props.id}>
      {renderBg()}
      <div className="fui-container">
        <h2 className="section-title title-with-tooltip">{props.title}</h2>
        {props.tooltip && <TitleTooltip title={props.tooltip}></TitleTooltip>}
        <Slider {...settings} className="horizontal-cards">
          {props.cards.map((card, idx) => (
            <Card
              key={idx}
              {...card}
              fetKey={
                props["tracking-prefix"]
                  ? `${props["tracking-prefix"]}${props.title || ""}${
                      props.position || ""
                    }_${card.title || ""}${idx + 1}`
                  : ""
              }
              data-recommend-tracking={card.recommendData}
            />
          ))}
        </Slider>
        {props.more ? (
          <div className="align-center more">
            <Button
              btnStyle="secondary"
              link="{props.more.link}"
              size=""
              className=""
            >
              {props.more.text}
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

LifeCircleNewsCarousel.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.objectOf(Card)),
  bgStyle: PropTypes.string,
  more: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
  "tracking-prefix": PropTypes.string,
  position: PropTypes.number,
};

export default LifeCircleNewsCarousel;
