import React, { Component } from 'react'
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Slider from 'react-slick';
import Dropdown from "../../components/Dropdown";
import Button from '../../components/Button';
import PropTypes from 'prop-types';
class SectionProductInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sldierSetting: {
        dots: true,
        infinite: false,
        arrows: true,
        slidesToShow: 1,
        draggable: true,
        adaptiveHeight: false,
      }
    }
  }
  render() {
    return (
      <section className='section-product-info fui-product-section pb-0 pt-7 mb-4'>
        <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
          <div className="fui-container">
            <h2 className="mb-6">{this.props.title}</h2>
            <div className='fui-panel position-relative main-shadow'>
              <div className="tag tag-red">{this.props.list[0].hotTag}</div>
              <Grid container className='pt-md-2 pb-md-0 pb-1'>
                <Grid item xs={12} sm={12} md={6}>
                  {/* 產品圖 */}
                  <Slider {...this.state.sldierSetting} className='fui-product-slider'>
                    {this.props.list[0].image.map((item, i) => (
                      <img src={item.url} alt={item.alt} key={`fui-product-slider-${i}`} />
                    ))}
                  </Slider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className='px-lg-0'>
                  {/* 產品內容 */}
                  <h6 className="is-text-darkgray50 is-text-regular m-0">
                    {this.props.list[0].brand}
                  </h6>
                  <h2>
                    {this.props.list[0].tag ? this.props.list[0].tag.map((tag, i) => (
                      <span className='inline-tag' key={`product-tag-${i}`}>
                        {tag}
                      </span>
                    )) : null}
                    {this.props.list[0].name}
                  </h2>
                  <p className='mb-1 mt-0'>專案價</p>
                  <div className="d-inline-flex price" dangerouslySetInnerHTML={{ __html: this.props.list[0].content.price }} />
                  <div className="d-inline" dangerouslySetInnerHTML={{ __html: this.props.list[0].content.checklist }}>
                  </div>
                  <div className="info-block">
                    {this.props.list[0].content.tips.map((item, index) => {
                      return (
                        <div className="list" key={index + item.content}>
                          <div className="list-tag">{item.block}</div>
                          <small>{item.content}</small>
                        </div>
                      )
                    })}
                  </div>
                  <Button data-fet-key={this.props.list[0].link.fetKey} link={this.props.list[0].link.url} target={this.props.list[0].link.target} btnStyle='primary' size="large" className='mt-4 fui-button is-primary'>{this.props.list[0].link.text}</Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Formsy>
      </section>
    );
  }
}
SectionProductInfo.propTypes = {
  title: PropTypes.string,
  hotTag: PropTypes.string,
  tag: PropTypes.array,
  name: PropTypes.string,
  list: PropTypes.array,
  brand: PropTypes.string,
  image: PropTypes.array,
}
export default SectionProductInfo;