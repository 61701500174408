import React, { Component } from 'react'
import NavTab from '../../components/partials/NavTab';
import { Grid } from '@material-ui/core';
import Panel from '../../components/panel/Panel';
import SectionCooperation from '../../components/partials/SectionCooperation'
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import CardCollapse1 from '../../components/card/CardCollapse1';
import BannerPromotionBasic from '../../components/partials/banner/BannerPromotionBasic';
import PanelContainer from '../../components/panel/PanelContainer';
import VideoCarousel from '../../components/VideoCarousel';
import PanelContainer3 from '../../components/panel/PanelContainer3';
import QuoteBanner from '../../components/partials/QuoteBanner';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import SectionFeatureIcon from '../../components/partials/SectionFeatureIcon';
import DevicePlanTable from '../../components/partials/DevicePlanTable';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import axios from 'axios';
let PlanMock = null
class WatchIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 768 || (window.innerWidth <= 1024 && window.innerWidth < window.innerHeight),
      collapseData: [
        {
          title: "親職教養專家 張旭鎧 (阿鎧老師)：",
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          quote: "易於使用操作便利的智慧手錶，數位教養最佳工具！只要透過手機APP，就能立即了解孩子位置，隨時與父母連絡，進行互動。",
          content: "面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。",
          link: "#",
        },
        {
          title: "3C 部落客 babyfans 杜小威：",
          img: { url: '/resources/cbu/img/promotion-watch-babyfans@2x.png', alt: 'img-02' },
          quote: "過年外出一度走散了，馬上開定位和打電話立刻尋找他。很推薦給孩子小的家長，可以買給小孩外出用。",
          content: "面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。",
          link: "#",
        },
        {
          title: "朱朱育兒日記 朱兒：",
          img: { url: '/resources/cbu/img/promotion-watch-chu@2x.png', alt: 'img-03' },
          quote: "孩子可以隨時打電話給我，我也可以確認他的安全，有急事發生孩子也可按SOS通知所有家庭成員。真的是讓我輕鬆許多。",
          content: "面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。",
          link: "#",
        },
        {
          title: "朱朱育兒日記 朱兒：",
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          quote: "孩子可以隨時打電話給我，我也可以確認他的安全，有急事發生孩子也可按SOS通知所有家庭成員。真的是讓我輕鬆許多。",
          content: "面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。",
          link: "#",
        },
        {
          title: "朱朱育兒日記 朱兒：",
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          quote: "孩子可以隨時打電話給我，我也可以確認他的安全，有急事發生孩子也可按SOS通知所有家庭成員。真的是讓我輕鬆許多。",
          content: "面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。",
          link: "#",
        },
      ],
    }
    this.getExclusiveData().then(result => {
      PlanMock = result;
      this.setExclusiveData();
    });
  }
  changeTab = () => {
    // console.log('changed tab');

  }

  getExclusiveData = (val) => {
    return axios('/json/watch.json').then(result => {
      return JSON.parse(JSON.stringify(result.data));
    })
  }

  setExclusiveData = () => {
    let planTab = [];
    let activePlan = 0;
    let activeTab = 0;
    let activeNum = 0;
    let activeNav = this.props.match.params.plan ? PlanMock.navTab.find((tab, i) => {
      if (this.props.location.pathname === tab.name) {
        activeNum = i
        return tab;
      }
    }) : PlanMock.navTab[0];

    PlanMock.plan.forEach((plan, i) => {
      if (this.props.match.params.plan === plan.title) {
        activePlan = i
      }
      // BannerTab
      // PlanTab
      PlanMock.planTabs.tabs.forEach((tab, k) => {
        if (!planTab[k]) planTab[k] = [];
        tab.child.forEach(ch => {
          if (activeNav.name === ch.name) {
            activeTab = k
          }
          if (ch.name === plan.name) {
            planTab[k].push(plan)
          }
        })
      });
    })
    this.setState({
      query: this.props.match.params.plan || '',
      defaultTab: activeNum,
      navTabs: PlanMock.navTab,
      selectedPlan: PlanMock.plan[activePlan],
      planTabs: {
        title: PlanMock.planTabs.title,
        tableTitle: PlanMock.planTabs.tableTitle,
        tabs: {
          scroll: true,
          list: PlanMock.planTabs.tabs.map(tab => {
            return {
              label: tab.name,
              filterArr: tab.filterArr,
              filterName: tab.filterName,
              pickPlan: tab.pickPlan,
            }
          }),
          default: activeTab,
        },
        tabContent: planTab
      },
    })

    this.forceUpdate()
    // console.log("updated");
  }

  spec = (item, idx, e) => {
    // console.log({ item, idx, e });
  }
  // 根據DevicePlanTable的選擇來打API //
  tableOnChange = (val) => {
    this.getExclusiveData(val).then(result => {
      PlanMock = result;
      this.setExclusiveData();
    });
  }
  filtering = (val) => {
    if (!!val.filter.value) {
      let filterArr = []
      val.filter.value.split(",").map((key) => {
        filterArr.push(val.arr[key - 1].label)
      })
      let active = []
      let second = []
      let final = []
      val.tabContent.forEach((plan) => {
        filterArr.map((filter) => {
          // console.log(plan);
          if (plan.name.indexOf(filter) > -1) {
            active.push(plan)
          } else {
            second.push(plan)
          }
        })
      })
      // console.log({ active, second });

      final = [...active, ...second]

      var result = new Set();
      var repeat = new Set();
      final.forEach(item => {
        result.has(item) ? repeat.add(item) : result.add(item);
      })
      final = [...result]
      // console.log(final);


      let planTab = [];
      let activePlan = 0;
      let activeTab = 0;
      let activeNum = 0;
      let activeNav = this.props.match.params.plan ? PlanMock.navTab.find((tab, i) => {
        if (this.props.location.pathname === tab.name) {
          activeNum = i
          return tab;
        }
      }) : PlanMock.navTab[0];

      // console.log(`this.props.location.pathname: `, this.props.location.pathname);
      // console.log(`activeNav: `, activeNav);

      PlanMock.plan.forEach((plan, i) => {
        if (this.props.match.params.plan === plan.title) {
          activePlan = i
        }
        // BannerTab
        // PlanTab
        PlanMock.planTabs.tabs.forEach((tab, k) => {
          if (!planTab[k]) planTab[k] = [];
          tab.child.forEach(ch => {
            if (activeNav.name === ch.name) {
              activeTab = k
            }
            if (ch.name === plan.name) {
              planTab[k].push(plan)
            }
          })
        });
      })
      planTab[val.index] = final

      this.setState({
        query: this.props.match.params.plan || '',
        defaultTab: activeNum,
        navTabs: PlanMock.navTab,
        selectedPlan: PlanMock.plan[activePlan],
        planTabs: {
          title: PlanMock.planTabs.title,
          tableTitle: PlanMock.planTabs.tableTitle,
          tabs: {
            scroll: true,
            list: PlanMock.planTabs.tabs.map((tab, i) => {
              if (i === val.index) {
                return {
                  label: tab.name,
                  filterArr: tab.filterArr,
                  filterName: tab.filterName,
                  pickPlan: filterArr,
                }
              } else {
                return {
                  label: tab.name,
                  filterArr: tab.filterArr,
                  filterName: tab.filterName,
                  pickPlan: tab.pickPlan,
                }
              }
            }),
            default: activeTab,
          },
          tabContent: planTab
        },
      })
      this.forceUpdate()
    } else {
      this.resetFilterContent();
    }
  }
  resetFilterContent = () => {
    this.setExclusiveData();
    this.forceUpdate()
  }
  render() {
    return (
      <main className='life-circle article'>
        <NavTab onChange={this.changeTab} {...{
          pageTitle: '愛講定位手錶',
          tabs: {
            name: 'help-center',
            list: [
              { name: 'tab-1', label: '產品介紹', link: '#' },
              { name: 'tab-2', label: '最新優惠', link: './promote' },
            ],
          },
          button: null,
          default: 0
        }} />
        <BannerPromotionBasic
          bgColor="#fff"
          autoplay={false}
          autoplaySpeed={5000}
          slides={
            [
              {
                image: {
                  md: '/resources/cbu/img/cbu-promotion-watch-1920x500.jpg',
                  sm: '/resources/cbu/img/cbu-promotion-watch-750x1000.jpg'
                },
                // tag: "網路限定",
                title: '<div class="mt-7">愛講定位手錶是家人的守護者</div>',
                description: '<div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>申辦愛講定位手錶$990起</h5></div><div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>加贈手錶專用保護套</h5></div>',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '線上買',
                    link: '#'
                  },
                  {
                    btnStyle: 'secondary',
                    target: '_blank',
                    type: 'link',
                    text: '門市買',
                    link: '#'
                  }
                ]
              },
              {
                image: {
                  md: '/resources/cbu/img/ebu-aws-banner-1440-x-460@2x.jpg',
                  sm: '/resources/cbu/img/ebu-aws-banner-375-x-500@2x.jpg'
                },
                theme: 'dark',
                tag: "網路限定",
                title: '<div class="mt-md-7">買 iPhone SE 到遠傳，最經濟</div>',
                description: '<div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5></div>',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '申辦4.５G方案',
                    link: '#'
                  },
                  {
                    btnStyle: 'secondary',
                    target: '_blank',
                    type: 'link',
                    text: '拿好禮1+1',
                    link: '#'
                  }
                ]
              },
              {
                image: {
                  md: '/resources/cbu/img/ebu-aws-banner-1440-x-460@2x copy.jpg',
                  sm: '/resources/cbu/img/ebu-aws-banner-550-x-460@2x.png'
                },
                tag: "網路限定",
                title: '買 iPhone SE 到遠傳，最經濟',
                description: '<div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5></div>',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '申辦4.５G方案',
                    link: '#'
                  },
                  {
                    btnStyle: 'secondary',
                    target: '_blank',
                    type: 'link',
                    text: '拿好禮1+1',
                    link: '#'
                  }
                ]
              },
              {
                image: {
                  md: '/resources/cbu/img/ebu-aws-banner-1440-x-460@2x copy.jpg',
                  sm: '/resources/cbu/img/ebu-aws-banner-550-x-460@2x.png'
                },
                tag: "網路限定",
                title: '買 iPhone SE 到遠傳，最經濟',
                description: '<div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5></div>',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '申辦4.５G方案',
                    link: '#'
                  },
                  {
                    btnStyle: 'secondary',
                    target: '_blank',
                    type: 'link',
                    text: '拿好禮1+1',
                    link: '#'
                  }
                ]
              },
            ]
          }
        />
        <VideoCarousel
          title="佩戴身邊，即時守護"
          videos={[
            {
              videoId: 'oqCt6yq_xoc',
              title: '遠傳愛講定位手錶',
              content: '功能特色篇',
              imgSmall: '/resources/cbu/img/img-promotion-watch-video-m@2x.jpg',
              imgLarge: '/resources/cbu/img/img-promotion-watch-video@3x.png',
              videoLink: 'oqCt6yq_xoc',
              alterVideo: null,
            }
          ]}
        />
        <div className="fui-container">
          <h2>用愛講定位手錶照顧全家</h2>
        </div>
        <PanelContainer3
          // initial={true}
          // bgColor="#111"
          className='mt-0 narrow-padding'
          data={[
            {
              title: '省去日常瑣事的麻煩提醒',
              contentPosition: 'right',
              content: '<p>出門前只要問：「今天天氣如何？要帶雨傘嗎？」、「空氣品質好嗎？」，愛講定位手錶就能幫忙確認今天外出要攜帶的物品，適合穿著的衣物。另外，也能透過它傳遞語音訊息：「爺爺、奶奶早餐吃完要記得吃保健品喔！」</p><div class="fui-list"><div class="fui-item"><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">定時鬧鐘</span></div><div class="fui-item"><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">查天氣預報、空氣品質</span></div><div class="fui-item"><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">備忘語音提醒</span></div></div></div></div>',
              img: {
                width: '600px',
                url: '/resources/cbu/img/promotion-watch-01@2x.jpg',
                alt: 'img'
              }
            },
            {
              title: '解決老老少少的疑難雜症',
              contentPosition: 'left',
              content: '<p>具備不耗能的觸碰螢幕，陪著孩子一起複習英文課、數學課，唸有聲書，也能即時回應家中老少的各種需求。</p><div class="fui-list mb-0"><div class="fui-item  "><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">即時翻譯英文與日文</span></div><div class="fui-item  "><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">數學計算機</span></div><div class="fui-item  "><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">語音助理智慧問答</span></div></div><div class="fui-list mb-0"><div class="fui-item  "><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">精選兒童有聲書</span></div></div>',
              img: {
                width: '600px',
                url: '/resources/cbu/img/promotion-watch-02@2x.jpg',
                alt: 'img'
              }
            },
            {
              title: '在遠也能陪伴家人，好好傳達關愛',
              contentPosition: 'right',
              content: '<p>用手錶也能做家人間的交換日記，拍張照片發送到家族群聊，一起分享趣事。App還能替你回報今日佩戴者的運動量有沒有足夠，作為一個體貼的手錶能顧到全家人的健康。</p><div class="fui-list"><div class="fui-item"><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">上課禁用，流量監控</span></div><div class="fui-item"><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">200萬畫素，84.1度大廣角</span></div><div class="fui-item"><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">健康計步</span></div><div class="fui-item"><span class="prefix"><i class="is-text-accent icon-check"></i></span><span class="content">家族群聊</span></div></div></div></div>',
              img: {
                width: '600px',
                url: '/resources/cbu/img/promotion-watch-03@2x.jpg',
                alt: 'img'
              }
            }
          ]}
        />
        <QuoteBanner
          className="mt-md-10 mt-8"
          topBg={{
            md: '/resources/cbu/img/cbu-feature-text-red-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-feature-text-red-750x708.jpg'
          }}
          content={`<div class='m-0 px-md-9 is-text-white'>
              突發狀況不慌張，愛講定位手錶是<br/>孩子與長輩的<span class="border-bottom is-business50">貼身保鑣</span>
            </div>
            `}
        />
        <SectionBanner2
          position='center'
          className="is-500 is-prmotion-banner mb-0 top-0 content-right"
          image={{
            md: '/resources/cbu/img/cbu-feature-scenario-left-map-1920x500.jpg',
            sm: '/resources/cbu/img/cbu-feature-scenario-left-map-750x984.jpg',
          }}
          title='
          <div class="mb-2">
            <h3 class="">
            天天安全護送家人
            </h3>
            <p class="body mb-md-5 mb-4">家人離開或進入安全區，都主動回報</p>
            <h3 class="">
            軌跡追蹤、尋人導航
            </h3>
            <p class="body mb-md-5 mb-4">了解家人足跡動向，導航最佳路線</p>
            <h3 class="">
            完善的自動警報系統
            </h3>
            <p class="body mb-md-5 mb-4">聲控撥號傳訊，主動聯繫家長，15秒內接聽</p>
          </div>'
          action={null}
        />
        <SectionFeatureIcon
          title="在遠傳買，給你更多安全感"
          bg="/resources/cbu/img/cbu-feature-icon-green-1920x440.jpg"
          list={[
            {
              title: '24HR電話客服',
              content: '全台遠傳門市售後服務',
              icon: {
                img: '/resources/cbu/img/24-hr.svg',
                alt: 'img'
              }
            },
            {
              title: '電信資安規範',
              content: '個資隱私有保障',
              icon: {
                img: '/resources/cbu/img/rule.svg',
                alt: 'img'
              }
            },
            {
              title: '安全關懷無國界',
              content: '國內吃到飽，國外漫遊支援',
              icon: {
                img: '/resources/cbu/img/international.svg',
                alt: 'img'
              }
            }
          ]}
        />
        <QuoteBanner
          className="mt-0 is-white is-bg-white mb-md-0 mb-4"
          content={`<div class='m-0 is-text-black50'>
          <span class="border-bottom is-red30">網路吃到飽只要</span>$399，手錶不到一千元<br/>
          立刻帶回家！
            </div>
            `}
        />
        {
          !PlanMock || !this.state.planTabs ? null : <DevicePlanTable
            filterTitle="規格"
            {...this.state.planTabs}
            filterArr={this.state.filterArr}
            resetFilterContent={this.resetFilterContent}
            programList={
              [
                { value: '32 GB', text: '32 GB', label: '32 GB' },
              ]
            }
            selected={this.state.selectedPlan}
            filtering={(val) => { this.filtering(val) }}
            onChange={(val) => this.tableOnChange(val)}
          />
        }
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            1. 上網吃到飽優惠僅限於將本專案SIM卡放置於本專案指定設備使用，若SIM卡取出放置於其他上網裝置使用，每月傳輸量為3.5GB。<br/>
            2. 月付$249包含遠傳高速飆網199方案月租+愛講定位手錶服務費$50。月付$399包含遠傳高速飆網499方案享月租折價$150+愛講定位手錶服務費$50。<br/>
            3. 於遠傳網路門市申辦愛講定位手錶商品案，需同意開通定位服務，始能使用愛講定位手錶完整功能。定位服務於手錶門號開通後次個工作日開通，約期與商品案相同，約期內不得單獨解除定位服務。本定位服務需月付$50，合併於手錶門號帳單收取。<br/>
            4. 以上價格限搭配遠傳愛講定位手錶豪華方案，月付$399簽約36個月，若調降費率或提前退租視同放棄本專案優惠且須繳交專案補貼款。<br/>
            5. 方案內容如有調整依門市最新公告為準。
          `}
        />
        <SectionBanner2
          position='center top'
          className="is-500 is-prmotion-banner mb-0 top-0 content-left"
          image={{
            md: '/resources/cbu/img/cbu-feature-scenario-right-waterproof-1920x500.jpg',
            sm: '/resources/cbu/img/cbu-feature-scenario-right-waterproof-750x984.jpg',
          }}
          title='
          <div class="mb-2">
            <h3 class="">
            上山下海都防水
            </h3>
            <p class="body mb-md-5 mb-4">IP68生活防水</p>
            <h3 class="">
            耐用高通晶片
            </h3>
            <p class="body mb-md-5 mb-4">Qualcomm晶片獨家技術</p>
            <h3 class="">
            食品級矽膠錶帶
            </h3>
            <p class="body mb-md-5 mb-4">無毒親膚、高耐磨安心品質</p>
          </div>'
          action={null}
        />
        <div className="fui-container">
          <h2 className='mt-7'>產品規格</h2>
        </div>
        <section className='fui-login-section pt-0 pb-md-4 pb-0'>
          <div className="fui-container">
            <div className="fui-segments">
              <div className="fui-segment">
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={6}>
                    <table className="text-wrap odd p-lg-0 p-3">
                      <tbody className='d-table'>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>項目</th>
                          <td className='align-left pl-md-4 pl-2'>規格</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>尺寸</th>
                          <td className='align-left pl-md-4 pl-2'>49.3 X 42.0 mm</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>重量</th>
                          <td className='align-left pl-md-4 pl-2'>50.5g</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>處理器</th>
                          <td className='align-left pl-md-4 pl-2'>高通Snapdragon Wear 2500</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>記憶體</th>
                          <td className='align-left pl-md-4 pl-2'>512MB RAM；4GB ROM</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>藍芽</th>
                          <td className='align-left pl-md-4 pl-2'>Bluetooth V5.0/BLE</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>定位</th>
                          <td className='align-left pl-md-4 pl-2'>GPS/Glonass/BDS/Galileo</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>無線網路</th>
                          <td className='align-left pl-md-4 pl-2'>802.11 b/g/n</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>顯示器	</th>
                          <td className='align-left pl-md-4 pl-2'>1.4 英寸 320*320</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>攝像頭</th>
                          <td className='align-left pl-md-4 pl-2'>寬視角 200萬像素</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>感應器</th>
                          <td className='align-left pl-md-4 pl-2'>加速度計</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>電池容量	</th>
                          <td className='align-left pl-md-4 pl-2'>660mAh</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>防水等級</th>
                          <td className='align-left pl-md-4 pl-2'>IP68</td>
                        </tr>
                        <tr>
                          <th className='align-left pl-md-4 pl-2'>藍芽</th>
                          <td className='align-left pl-md-4 pl-2'>4G: -LTE FDD: Band1 , Band3<br />3G: -WCDMA: Band1</td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className='promotion' style={{ height: "100%", display: "flex", zIndex: 1 }}>
                      <img style={{ objectFit: "cover" }} className='w-100' src="/resources/cbu/img/promotion-watch-qualcomm.jpg" alt="qualcomm" />
                    </div>
                    <img className='d-none d-md-block' style={{ position: "absolute", right: 15, bottom: 0, width: 500, height: 400, zIndex: 0 }} src="/resources/cbu/img/gradient-triangle-red.svg" alt="triangle" />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </section>
        <CardCollapse1
          list={this.state.collapseData}
        />
        <Panel className="is-bg-lightgray70 mb-0 p-0">
          <div id="faq-007" className="fui-container pb-md-4">
            <h3 className="is-text-darkgray50 is-text-medium m-0">常見問題</h3>
            <SectionFaq {...{
              collapseList: [
                {
                  title: ' 非遠傳電信用戶也可以購買愛講定位手錶嗎?需要搭配門號或續約嗎？可以在那裡買得到？',
                  content: ` 非遠傳電信用戶也可以購買愛講定位手錶嗎?需要搭配門號或續約嗎？可以在那裡買得到？`,
                },
                {
                  title: '遠傳愛講定位手錶所用的SIM卡有限制嗎? 插入非遠傳卡片或不是使用這個方案的SIM卡，手錶還能使用嗎？',
                  content: `遠傳愛講定位手錶所用的SIM卡有限制嗎? 插入非遠傳卡片或不是使用這個方案的SIM卡，手錶還能使用嗎？`,
                },
                {
                  title: '遠傳愛講定位手錶是否支援3G、4G的網路？',
                  content: `遠傳愛講定位手錶是否支援3G、4G的網路？`,
                },
                {
                  title: '遠傳愛講定位手錶能到國外使用嗎？',
                  content: `遠傳愛講定位手錶能到國外使用嗎？`,
                },
                {
                  title: '手錶充飽電需要多久?充飽後能使用多久？',
                  content: `手錶充飽電需要多久?充飽後能使用多久？`,
                },
                {
                  title: '一支手錶可以綁定幾台手機？',
                  content: `一支手錶可以綁定幾台手機？`,
                },
                {
                  title: '更換手機時，需要重新與手錶進行綁定嗎？',
                  content: `更換手機時，需要重新與手錶進行綁定嗎？`,
                },
                {
                  title: '手錶損壞維修方式為何？',
                  content: `手錶損壞維修方式為何？`,
                },
                {
                  title: '產品客服專線、服務時間？',
                  content: `產品客服專線、服務時間？`,
                },
                {
                  title: '如何下載遠傳愛講定位手錶APP？',
                  content: `如何下載遠傳愛講定位手錶APP？`,
                },
              ],
              container: false,
              to: '#',
              target: '_blank'
            }} />
          </div>
        </Panel>
        <SectionCooperation
          list={[
            {
              title: '技術合作',
              img: {
                url: '/resources/cbu/img/coop-01.png',
                alt: 'coop-1'
              }
            },
            {
              title: '專案廠商',
              img: {
                url: '/resources/cbu/img/coop-01.png',
                alt: 'coop-2'
              }
            },
          ]}
        />
      </main>
    );
  }
}

export default WatchIntro;