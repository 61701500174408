import React from 'react';
import RedArrow from '../../animateArrow/RedArrow';
import Card from '../../card/Card';

import PropTypes from 'prop-types';

const ServiceEntry = (props) => {
    return (
        <section className="service-entry">
            <RedArrow/>
            <div className="fui-container">
            <div className="section-title" dangerouslySetInnerHTML={{ __html: props.title }}></div>
                <div className="fui-cards four-card py-0 mt-2">
                    {
                        props.cards.map((item, key) => (
                            <Card className='service-entry' {...item} key={key} />
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

ServiceEntry.propTypes = {
    title: PropTypes.string,
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.string
        })
    )
}

export default ServiceEntry;