import React from 'react';
import Collapse from '../../collapse/Collapse';

import PropTypes from 'prop-types'

class CollapsePaper extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 0
        }
    }

    render() {
        return (
            <div className={this.props.container == false ? null : 'fui-container'}>
                <section style={this.props.bgColor ? { backgroundColor: this.props.bgColor } : null} className={`fui-collapse-paper ${this.props.className ? this.props.className : ''} ${this.props.theme ? ('is-' + this.props.theme) : ''} ${ this.props.bgColor ? 'has-bgColor' : null}`}>
                    <Collapse
                        open={this.props.open}
                        title={this.props.title}
                        content={this.props.content} 
                    />
                </section>
            </div>
        )
    }
}

CollapsePaper.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    content: PropTypes.string,
    bgColor: PropTypes.string,
    theme: PropTypes.string,
    container: PropTypes.bool,
    open: PropTypes.bool,
}

export default CollapsePaper;