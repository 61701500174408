import React, { useEffect, createRef } from 'react';
import Slider from 'react-slick';
import { Grid } from '@material-ui/core';
import Button from '../../Button';
import PropTypes from 'prop-types';
import { setArrowBtns, setDotBtns } from '../../../utils/slider-tracking';

const LifeCircleIndexMainCarousel = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplay: false,
    fade: false,
    draggable: true,
    customPaging: (i) => <button key={i}></button>,
    // customPaging: function (i) {
    //   for (let j = 0; j < props.slider.length; j++) {
    //     if (i === j) {
    //       return <a>
    //         <img src={props.list[i].circleImage} alt={props.list[i].alt} />
    //         <h6>{props.list[i].shortname}</h6>
    //         <div className="bar"></div>
    //         <div className="bar-active" style={{ backgroundColor: props.list[i].color }}>
    //           <div className="triangle" style={{ borderBottom: `10px solid ${props.list[i].color}` }}></div>
    //         </div>
    //       </a>
    //     }
    //   }
    // },
  };
  const currentItem = React.useRef();

  const [currentTheme, setCurrentTheme] = React.useState(
    props.slider && props.slider.length ? props.slider[0].theme || 'light' : 'light'
  );
  const prevSlider = React.useRef(null);
  const nextSlider = React.useRef(null);

  const updateSlider = (oldIndex, newIndex) => {
    setCurrentTheme(props.slider[newIndex].theme);
    prevSlider.current.slickGoTo(newIndex);
    nextSlider.current.slickGoTo(newIndex);
  };

  const setPrevSlide = () => {
    let prevData = [...props.slider];
    let last = prevData.pop();
    prevData = [last].concat(prevData);
    return prevData;
  };

  const setNextSlide = () => {
    let nextData = [...props.slider];
    let first = nextData.shift();
    nextData.push(first);
    return nextData;
  };

  const prevData = setPrevSlide();
  const nextData = setNextSlide();

  const ref = createRef();
  const trackingPrefix = `${props.trackingPrefix}${props.position || ''}`;

  useEffect(() => {
    if (!ref || !ref.current) return;

    setArrowBtns(ref.current, `${trackingPrefix}`, { prev: 'LastPage', next: 'NextPage' });

    setDotBtns(ref.current, `${trackingPrefix}-`, `tab`);
  }, [ref, trackingPrefix]);

  return (
    <div ref={ref} className='main-carousel' id={props.id}>
      <Slider
        className={`is-prev slick-arrow-prev-wording ${currentTheme === 'dark' ? 'dark-theme' : ''}`}
        ref={prevSlider}
        {...settings}
        arrows={false}
        fade={true}>
        {prevData.map((item, idx) => (
          <p key={item + idx}>{item.title} </p>
        ))}
      </Slider>

      <Slider beforeChange={updateSlider} {...settings} className={currentTheme === 'dark' ? 'dark-theme' : ''}>
        {props.slider.map((slide) => {
          return (
            <div key={slide.title} className='slide'>
              {typeof slide.image === 'string' ? (
                <div className='img'>
                  <img className='d-none d-sm-block' src={slide.image} alt={slide.alt} />
                  <img
                    className='d-block d-sm-none w-100'
                    src={!!slide.imageMb ? slide.imageMb : slide.image}
                    alt={slide.alt}
                  />
                </div>
              ) : (
                  ''
                )}

              {/* <img className="bg-img" src={slide.img} alt="bg-img" /> */}
              <div className={`wording`}>
                <div className='fui-container'>
                  <Grid container>
                    <Grid item xs={12}>
                      {slide.isLast === true ? (
                        <div className='is-last'>
                          <Button
                            link={slide.action.link}
                            target={slide.action.target}
                            btnStyle='secondary'
                            className={`is-large ${slide.theme === 'dark' ? 'is-reverse' : ''}`}
                            data-fet-key={`${trackingPrefix}_${slide.title}`}>
                            {slide.action.text}
                          </Button>
                        </div>
                      ) : (
                          <div>
                            <h1 className={`'mt-0 title with-comma ${slide.theme === 'dark' ? 'dark-theme' : ''}`}>
                              {slide.title}
                            </h1>
                            <p className={`mt-0 subtitle ${slide.theme === 'dark' ? 'dark-theme' : ''}`}>
                              {slide.description}
                            </p>
                            {slide.action.text !== '' ? (
                              <Button
                                link={slide.action.link}
                                target={slide.action.target}
                                btnStyle='secondary'
                                className={`is-large ${slide.theme === 'dark' ? 'is-reverse' : ''}`}
                                data-fet-key={`${trackingPrefix}_${slide.title}`}>
                                {slide.action.text}
                              </Button>
                            ) : null}
                          </div>
                        )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <Slider
        className={`is-next slick-arrow-next-wording ${currentTheme === 'dark' ? 'dark-theme' : ''}`}
        ref={nextSlider}
        {...settings}
        arrows={false}
        fade={true}>
        {nextData.map((item, idx) => (
          <p>{item.title} </p>
        ))}
      </Slider>
    </div>
  );
};

LifeCircleIndexMainCarousel.propTypes = {
  slider: PropTypes.arrayOf(
    PropTypes.shape({
      theme: PropTypes.string, // light or dark
      image: PropTypes.string.isRequired,
      alt: PropTypes.string,
      title: PropTypes.string.isRequired,
      isLast: PropTypes.bool,
      action: PropTypes.shape({
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        target: PropTypes.string,
      }),
      trackingPrefix: PropTypes.string,
      position: PropTypes.number,
    })
  ),
};

export default LifeCircleIndexMainCarousel;
