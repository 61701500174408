import React from 'react';

import { Grid } from '@material-ui/core';
import AppPromotion from '../../components/partials/AppPromotion';
import ProductMap from '../../components/partials/ProductMap';
import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';

import * as Mock from '../../mock/EServiceIndex';

class MailboxSubmitFail extends React.Component {
    render() {
        return (
            <main>

                <NavAnchor
                    pageTitle='幫助中心'
                    button={{
                        text: '回個人專區',
                        link: '/',
                    }}
                />
                <section className="e-service-result">
                    <div className="fui-container">
                        <Grid>
                            <Grid xs={12}>
                                <img src={process.env.PUBLIC_URL + '/resources/cbu/img/cbu-illustration-empty-error-3@2x.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/img/cbu-illustration-empty-error-3@2x.png'} alt='' />
                                <div className="content">
                                    <h2 className="is-text-darkgray50 mb-0">驗證前端資料不通過</h2>
                                    <p className='subtitle is-text-darkgray100'>您的資料輸入有誤，請再試一次</p>
                                    <div className='action'>
                                        <Button btnStyle='primary'>返回個人專區</Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </section>
            </main>
        );
    }
}

export default MailboxSubmitFail;
