import React, { Component } from 'react'
import PropTypes from 'prop-types';
class SectionCooperation extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <section className="section-cooperation">
        <div className="fui-container">
          <div className="d-flex justify-center">
            {this.props.list.map((item, i) => {
              return (
                <div key={item.title + i} className="d-flex flex-align-center px-4">
                  <small className="is-text-white mr-4">
                    {item.title}
                  </small>
                  <img src={item.img.url} alt={item.img.alt} />
                </div>
              )
            })}
          </div>
        </div>
      </section>
    );
  }
}
SectionCooperation.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      img: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      })
    })
  ),
}
export default SectionCooperation;