import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import OrderPaper from '../../components/partials/card/OrderPaper'
class FridayOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: null,
      currentOpen: false,
      order: []
    }
  }
  tabChange = (i) => {
    this.setState({
      current: i
    })
    if (i == 1) {
      this.props.history.push('/order/order-friday-empty')
    } else {
      this.props.history.push('/order/order-history-empty')
    }
  }
  getOrderList = () => {
    if (!!this.props.list) {
      return (
        this.state.order.map((list) => {
          return (
            <OrderPaper
              list={list}
              key={list.id}
            />
          )
        })
      )
    } else {
      return (
        <div className="paper py-4">
          <div className="is-text-darkgray50 body">
            查無訂單記錄
          </div>
        </div>
      )
    }
  }
  render() {
    return (
      <main className="my-order">
        <FormBanner
          title="我的訂單"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />
        <NavContentTab1
          default={1}
          tabs={{
            name: 'page-tab',
            list: [
              { label: '網路門市' },
              { label: 'friDay購物' },
            ]
          }}
          onChange={this.tabChange}
        />
        <section className="order-status">
          <div className="fui-container">
            {this.getOrderList()}
          </div>
        </section>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
                <ol>
                  <li>我的訂單貼心小叮嚀</li>
                </ol>
              `}
        />
      </main>
    );
  }
}

export default FridayOrder;