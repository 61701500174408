import React, { Component } from 'react';
import LoadMore from '../../components/LoadMore'
import PropTypes from 'prop-types';
import CardCollapaseSingle from './CardCollapseSingle';
class CardCollapse1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentArticleLoadMore: this.props.list.length > 3,
      threeData: [],
      showMore: false,
    }
  }
  loadData = () => {
    this.setState({
      showMore: true,
      currentArticleLoadMore: false,
      threeData: this.props.list
    })
    // this.props.load()
    this.forceUpdate()
  }
  componentDidMount() {
    let result = []
    this.props.list.map((item, i) => {
      return (i < 3 ? result.push(item) : null)
    })
    // console.log(`result: `, result);
    this.setState({
      threeData: result
    })
  }
  render() {
    // console.log(this.props.list);
    return (
      <section className={`card-collapse-1 ${this.props.className ? this.props.className : ''}`}>
        <div className="fui-container">
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.title,
            }}>
          </div>
          {this.state.threeData.map((item, i) => {
            return (
              <CardCollapaseSingle
                key={i}
                title={item.title}
                img={item.img}
                quote={item.quote}
                content={item.content}
                linkText={item.linkText}
                link={item.link}
                target={this.props.target}
              />
            )
          })}
          {this.props.list.length > 3 ? (
            <LoadMore
              moreLabel="展開看更多"
              noMoreLabel={null}
              click={e => this.loadData()}
              load={this.state.currentArticleLoadMore}
            />
          ) : (
              null
            )}

        </div>
      </section>
    );
  }
}

CardCollapse1.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      img: PropTypes.string,
      quote: PropTypes.string,
      content: PropTypes.string,
      link: PropTypes.string,
      img: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    })
  ),
}
export default CardCollapse1;