import React from 'react';

import PropTypes from 'prop-types';

class FormBanner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            bg: this.props.image.md
        }
    }
    componentDidMount() {
        if (typeof window !== 'undefined') {
            this.setState({
                bg: (window.innerWidth < 640) ? this.props.image.sm : this.props.image.md
            })

            window.addEventListener('resize', e => {
                this.setState({
                    bg: (window.innerWidth < 640) ? this.props.image.sm : this.props.image.md
                })
            })
        }
    }
    render() {
        return (
            <section className={`fui-banner is-form ${this.props.className}`} style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + this.state.bg})`
            }}>
                <div className="fui-container">
                    <h1>{this.props.title}</h1>
                </div>
            </section>
        )
    }
}

FormBanner.propTypes = {
    image: PropTypes.shape({
        sm: PropTypes.string.isRequired,
        md: PropTypes.string.isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string
}
export default FormBanner;