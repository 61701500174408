const setArrowBtns = (ref, trackingPrefix, btnText) => {
    try {
        if (!ref) return;
        
        let arrowBtns = ref.querySelectorAll(".slick-arrow");
    
        if (arrowBtns.length > 0 && arrowBtns[0] && arrowBtns[1]) {
            arrowBtns[0].setAttribute('data-fet-key', `${trackingPrefix}_${btnText ? btnText.prev : ''}` || 'Tab_左');
            arrowBtns[1].setAttribute('data-fet-key', `${trackingPrefix}_${btnText ? btnText.next: ''}` || 'Tab_右');
        }
    } catch (error) {
        console.log("slider-tracking.js: ", error);
    }
}

const setDotBtns = (ref, trackingPrefix, btnText) => {
    try {
        if (!ref) return;
        
        let dotBtns = ref.querySelectorAll(".slick-slider:not(.is-prev):not(.is-next) .slick-dots button");

        dotBtns.forEach((item, idx) => {
            item.setAttribute('data-fet-key', `${trackingPrefix}${idx + 1}_${btnText}` || '');
        })
    } catch (error) {
        console.log("slider-tracking.js: ", error);
    }
}

export { setArrowBtns, setDotBtns };
