import React, { Component } from 'react'
import PropTypes from 'prop-types';
class QuoteBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768
    }
  }
  render() {
    return (
      <div
        className={`quote-banner position-relative ${!!this.props.className ? this.props.className : ''}`}
        id={this.props.id}
        style={{ backgroundImage: this.props.topBg ? `url(${this.state.isMobile ? this.props.topBg.sm : this.props.topBg.md})` : null, backgroundColor: this.props.bgColor ? this.props.bgColor : null }}
      >
        <section className='tools-list h-100 d-flex flex-align-center justify-center py-0 px-2'>
          <div className='align-center px-4 py-8' style={{ zIndex: 1 }}>
            <div className='with-comma m-0' dangerouslySetInnerHTML={{
              __html: this.props.content,
            }} />
          </div>
        </section>
        <div
          className='bottom-bg'
          style={this.props.bottomBg ? { backgroundImage: `url(${this.props.bottomBg})` } : null}
        />
      </ div >
    );
  }
}
QuoteBanner.propTypes = {
  topBg: PropTypes.object,
  bgColor: PropTypes.string,
  bottomBg: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
}
export default QuoteBanner;