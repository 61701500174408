import React from 'react'
import ContentLoader from 'react-content-loader'

class YoutubeStyleLoader extends React.Component {

    render() {
        const padding = 12
        const borderRadius = 4
        let { width, row, column, videoHeight, textHeight } = this.props;
        const list = []

        if (!row) {
            row = 1
        }

        if (!videoHeight) {
            videoHeight = 285
        }

        if (!textHeight) {
            textHeight = 20
        }

        if (!column) {
            column = 5
        }
        let height

        for (let i = 1; i <= row; i++) {
            for (let j = 0; j < column; j++) {
                const itemWidth = (width - padding * (column + 1)) / column
                const x = padding + j * (itemWidth + padding)
                const videoHeight = 285
                const height3 = 20
                const space = 10
                const y1 = padding + space * (i - 1)
                const y2 = y1 + padding + videoHeight
                const y3 = y2 + padding / 2 + textHeight
                list.push(
                    <React.Fragment>
                        <rect
                            x={x}
                            y={y1}
                            rx={borderRadius}
                            ry={borderRadius}
                            width={itemWidth}
                            height={videoHeight}
                        />
                        <rect x={x} y={y2} rx={0} ry={0} width={itemWidth} height={textHeight} />
                        <rect
                            x={x}
                            y={y3}
                            rx={0}
                            ry={0}
                            width={itemWidth * 0.7}
                            height={textHeight}
                        />
                    </React.Fragment>
                )

                if (i === row) {
                    height = y3 + height3
                }
            }
        }

        return (
            <ContentLoader
                viewBox={`0 0 ${width} ${height}`}
                width={width}
                height={height}
            >
                {list}
            </ContentLoader>
        )
    }
}
export default YoutubeStyleLoader