import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

class ArticleBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        this.setState({
          isMobile: window.innerWidth < 768,
        });
      });
  }
  render() {
    return (
      <section className='article-banner'>
        <div className='img'>
          <img src={this.state.isMobile ? this.props.sm : this.props.md} alt={this.props.alt} />
        </div>
        <div className='wording'>
          <div className='fui-container'>
            <Grid container>
              <Grid item xs={12} md={7}>
                <h6
                  className='mb-1'
                  style={{
                    color: this.props.titleColor ? this.props.titleColor : '#f05c38',
                  }}>
                  {this.props.title}
                </h6>
                <h1
                  style={{
                    color: this.props.descColor ? this.props.descColor : '',
                  }}>
                  {this.props.desc}
                </h1>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    );
  }
}

ArticleBanner.defaultProps = {
  md: '/resources/cbu/life-circle-article/images/cbu-lifecircle-kv-default-1920x360.jpg',
  sm: '/resources/cbu/life-circle-article/images/cbu-lifecircle-kv-default-1920x360.jpg',
}
ArticleBanner.propTypes = {
  sm: PropTypes.string,
  md: PropTypes.string,
  alt: PropTypes.string,
  titleColor: PropTypes.string,
  descColor: PropTypes.string,
  titleColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};
export default ArticleBanner;
