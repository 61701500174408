import React, { Component } from 'react'
import ProductCard from '../../components/card/ProductCard';
import LoadMore from '../../components/LoadMore';
import Button from '../../components/Button';
import PropTypes from 'prop-types';
class SectionProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <section className='section-product-card'>
        <div className="fui-container">
          <h2>
            {this.props.title}
          </h2>
          <div className="fui-cards four-card is-product no-scrollbar py-0 mb-1">
            {
              this.props.list.map((prod, i) => {
                return (
                  <div key={prod.meta + i} className="fui-card is-product">
                    {prod.prodhottag ? <div className={`tag tag-${prod.prodhottag.color}`}>{prod.prodhottag.text}</div> : null}
                    <div className='fui-card-image'>
                      <img className='lazyload' data-src={process.env.PUBLIC_URL + prod.image} src={process.env.PUBLIC_URL + prod.image} alt={prod.title} />
                    </div>
                    <div className='fui-card-caption'>

                      <div className="fui-card-meta">{prod.meta}</div>
                      <div className="body">
                        {prod.title}
                      </div>
                      <div className='fui-card-price' dangerouslySetInnerHTML={{ __html: prod.content }}></div>
                      <Button btnStyle='secondary' className='m-0 w-100' link={prod.link} target="_blank">立即購買</Button>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <LoadMore
            className='mb-0'
            moreLabel={this.props.loadMore.label}
            noMoreLabel={this.props.loadMore.noMore}
            load={this.props.hasMore}
            click={() => this.props.loadMoreFunc()}
          />
        </div>
      </section>
    );
  }
}
SectionProductCard.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.shape({
    label: PropTypes.string,
    noMore: PropTypes.string,
  }),
  loadMoreFunc: PropTypes.func,
}
export default SectionProductCard;