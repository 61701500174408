import React from 'react';
import {Grid} from '@material-ui/core';
import CbuBanner from '../components/partials/banner/CbuBanner'
import GreetingGuide from '../components/partials/GreetingGuide'
import ProductMap from '../components/partials/ProductMap'
import PromotionCard from '../components/partials/card/PromotionCard'
import CbuNews from '../components/partials/card/CbuNews'
import SplashPromotion from '../components/ad/SplashPromotion'
import LifeCirclePromotion from '../components/partials/carousel/LifeCirclePromotion'
import FooterAd from '../components/ad/FooterAd';

import * as Mock from '../mock/Index';

class CbuIndex extends React.Component {
  render() {
    return (
      <main>
        <SplashPromotion {...Mock.splashPromotion} />
        <CbuBanner {...Mock.bannerTab} />
        <div className='ribbon-bg'>
          <GreetingGuide {...Mock.greetings} />
          <PromotionCard {...Mock.promotionCard} />
          <LifeCirclePromotion {...Mock.lifeCirclePromotion} />
          <CbuNews {...Mock.cbuNews} />
        </div>
        <ProductMap {...Mock.productMap} />
        <FooterAd 
          image={{
            md: process.env.PUBLIC_URL + '/resources/cbu/cbu-index/promo-img-web.jpg',
            sm: process.env.PUBLIC_URL + '/resources/cbu/cbu-index/promo-img-mobile.jpg'
          }}
          title='Apple Watch Series 5 LTE 11/8上市 現正開放預購'
          action={{
            text: '更多詳情',
            link: '/',
            btnStyle: 'secondary'
          }}
        />
      </main>
    );
  }
}

export default CbuIndex;