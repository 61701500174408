import React from 'react';

import { Grid } from '@material-ui/core';
import AppPromotion from '../../components/partials/AppPromotion';
import ProductMap from '../../components/partials/ProductMap';
import NavAnchor from '../../components/partials/NavAnchor';
import Button from '../../components/Button';

import * as Mock from '../../mock/EServiceIndex';

class getPhoneBillPaySettingSmsValidateErrorPage extends React.Component {
  render() {
    return (
      <main>

        <NavAnchor
          pageTitle='帳單代收'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <section className="e-service-result">
          <div className="fui-container">
            <Grid>
              <Grid xs={12}>
                <img src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/illustration-540-px-not-applicable.png'} srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/illustration-540-px-not-applicable@2x.png'} alt='' />
                <div className="content">
                  <h2 className="is-text-darkgray50 mb-0">你的門號/帳號不適用此服務</h2>
                  <p className='subtitle is-text-darkgray100'>此功能需為「月租型門號」才可以使用。</p>
                  <div className='action'>
                    <Button btnStyle='primary'>返回個人專區</Button>
                    <Button btnStyle='secondary' >去申辦新門號</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        <AppPromotion
          bgImage={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/appdownload-1920x400.png'}
          title="遠傳行動客服 APP"
          subtitle="遠傳門號用戶專享"
          description={`
            <p>
              以遠傳門號快速登入, 上網流量即時看，查帳單、繳帳單，一鍵完成！
              <br />
              還有許多用戶獨享驚喜優惠
            </p>`
          }
          icon={process.env.PUBLIC_URL + '/resources/cbu/help-center/images/img-logo-fet.png'}
          android={{
            qrCode: process.env.PUBLIC_URL + '/resources/cbu/help-center/images/qr-android.jpg',
            icon: process.env.PUBLIC_URL + '/resources/cbu/help-center/images/google-play.png',
            link: '/',
            title: '支援 Android 7 以上版本'
          }}
          ios={{
            qrCode: process.env.PUBLIC_URL + '/resources/cbu/help-center/images/qr-android.jpg',
            icon: process.env.PUBLIC_URL + '/resources/cbu/help-center/images/google-play.png',
            link: '/',
            title: '支援 iOS 10 以上版本'
          }}
        />
        <ProductMap id='product-map' {...Mock.productMapData} />
      </main>
    );
  }
}

export default getPhoneBillPaySettingSmsValidateErrorPage;
