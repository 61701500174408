import React from 'react';
import LinkItem from '../../item/LinkItem';
import Collapse from '../../collapse/Collapse';

import PropTypes from 'prop-types';

class MenuDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentItem: 0,
    };
  }

  renderCollapseMenu = (menu, index) => (
    <Collapse title={menu.text} key={`menu-drawer-${index}`}>
      {menu.list.map((item, i) => (
        <LinkItem link={item.link} key={`menu-drawer-${index}-item-${i}`}
          data-aa-tracking-id={`${menu.text}_${item.text}`}
          onClick={(event) => {
            // [AA Tracking]
            if (window && typeof window.ctrf === "function")
              window.ctrf(event || {}, `${menu.text}_${item.text}`);
          }}
        >
          {item.text}
        </LinkItem>
      ))}
    </Collapse>
  );

  render() {
    const { list, title } = this.props;
    const { currentItem } = this.state;

    return (
      <div className='fui-menu-drawer'>
        <div className='fui-menu-drawer-head' id={`${this.props.id}-0`}>
          <div className='drawer-bar-left'>
            <div role='button' 
              data-aa-tracking-id={`Tab_返回`}
              onClick={(event) => {
                this.props.closeDrawer();
                // [AA Tracking]
                if (window && typeof window.ctrf === "function")
                  window.ctrf(event || {}, `Tab_返回`);
              }}>
              <i className='icon-chevron-left'></i>
            </div>
          </div>
          <div className='drawer-bar-center'>{title}</div>
          <div className='drawer-bar-right'>
            <div role='button' 
              data-aa-tracking-id={`Tab_關閉`}
              onClick={(event) => {
                this.props.closeMenu();
                // [AA Tracking]
                if (window && typeof window.ctrf === "function")
                  window.ctrf(event || {}, `Tab_關閉`);
              }}>
              <i className='icon-close'></i>
            </div>
          </div>
        </div>
        <div className='fui-menu-drawer-body'>
          <div className='fui-menu-drawer-sidemenu'>
            {list &&
              list !== null &&
              list.map((group, i) => (
                <div 
                  id={`${this.props.id}-${i + 1}`}
                  key={`fui-menu-drawe-${i}`}>
                  <div
                    className={`fui-drawer-item ${currentItem === i ? 'is-active' : ''}`}
                    data-aa-tracking-id={`Tab_${group.text}`}
                    onClick={(event) => { 
                      this.setState({ currentItem: i })
                      // [AA Tracking]
                      if (window && typeof window.ctrf === "function")
                        window.ctrf(event || {}, `Tab_${group.text}`);
                    }}>
                    <img src={group.iconActive} alt={group.text} className={'icon is-active '} />
                    <img src={group.icon} alt={group.text} className={'icon '} />
                    <div className='text'>{group.text}</div>
                  </div>
                </div>
              ))}
          </div>
          {list &&
            list !== null &&
            list.map((group, i) => (
              <div
                id={`${this.props.id}-${i + 1}`}
                key={`fui-menu-drawer-content-${i}`}
                className={`fui-menu-drawer-container ${currentItem === i ? 'is-active' : ''}`}>
                {group.list !== null &&
                  group.list.map((item, j) =>
                    item.list ? (
                      this.renderCollapseMenu(item, j)
                    ) : (
                      <LinkItem key={`menu-drawer-${j}`} link={item.link}>
                        {item.text}
                      </LinkItem>
                    )
                  )}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

MenuDrawer.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      child: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          link: PropTypes.string,
          child: PropTypes.arrayOf(
            PropTypes.shape({
              text: PropTypes.string,
              link: PropTypes.string,
            })
          ),
        })
      ),
    })
  ),
  closeDrawer: PropTypes.func,
  closeMenu: PropTypes.func,
};

export default MenuDrawer;
