import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import CheckGroup from '../../components/form/CheckGroup';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import Modal from 'react-modal';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';

import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';

class MergeBill extends React.Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.form = React.createRef();
        this.state = {
            detail: {
                email: {
                    title: '電子帳單接收Email',
                    content: 'xxxxxxx@xxxxxxx.xxx'
                },
                number: {
                    title: '簡訊接收門號',
                    content: '帳單中所有門號 <a class="info-link" href="#" target="_self">自行設定</a>'
                },
            },
            type: 'success',
            description: `<h4 class="mb-1">申請完成</h4><p class="mt-0 mb-4">您已完成電子帳單申請，自下一期起，帳單將以<span class="is-text-accent">「Email+簡訊」</span>方式通知您</p>`,
            promotion: {
                title: '你的專屬優惠',
                cards: [
                    {
                        image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
                        title: '本月影片免費看!',
                        description: '話題電影、日劇、韓劇等等應有盡有',
                        link: '#',
                    },
                    {
                        image: '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
                        title: '中午不知道吃什麼？',
                        description: 'Uber Eats 送遠傳新客優惠金200元',
                        link: '#',
                    },
                    {
                        image: '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
                        title: '連假打算出國？',
                        description: '遠遊卡吃到飽上網不再煩惱用量 ',
                        link: '#',
                    },
                    {
                        image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
                        title: '本月影片免費看!',
                        description: '話題電影、日劇、韓劇等等應有盡有',
                        link: '#',
                    },
                ]
            },
        }
    }


    render() {
        return (
            <main>
                <NavAnchor
                    pageTitle='帳單與用量'
                    button={{
                        text: '回個人專區',
                        link: '#'
                    }} />

                <section className="e-service-result is-success mt-4">
                    <div className="fui-container">
                        <Grid container>
                            <Grid item xs={12}>
                                {
                                    this.state.type !== 'fail' ? (
                                        <img src={'/resources/cbu/e-service/images/completed.png'} srcSet={'/resources/cbu/e-service/images/completed@2x.png'} alt='' />
                                    ) : (
                                            <img src={'/resources/cbu/e-service/images/failed.png'} srcSet={'/resources/cbu/e-service/images/failed@2x.png'} alt='' />
                                        )
                                }

                                <div className="content">
                                    <h4 className="mb-0 mt-3">{this.state.title || ''}</h4>
                                    <p className='body1' dangerouslySetInnerHTML={{
                                        __html: this.state.description || ''
                                    }}></p>
                                </div>
                            </Grid>
                        </Grid>
                        <div className="paper">
                            <h4 className='align-left'>設定詳情</h4>
                            <table className="text-wrap odd bill-table table-fixed">
                                <tbody>
                                    <tr>
                                        <td className="align-center is-bg-white is-text-medium">{this.state.detail.email.title}</td>
                                        <td className="align-left  pl-md-5 pl-2">{this.state.detail.email.content}</td>
                                    </tr>
                                    <tr>
                                        <td className="align-center is-bg-white is-text-medium">{this.state.detail.number.title}</td>
                                        <td className="align-left  pl-md-5 pl-2" dangerouslySetInnerHTML={{ __html: this.state.detail.number.content }}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='fui-buttons two-buttons mt-md-5 mt-2 pt-2'>
                            <Button className='d-block d-md-inline-block' link="#" btnStyle='primary' size='large'>返回個人專區</Button>
                        </div>


                    </div>
                </section>
                <SectionCarousel1 {...this.state.promotion} />
            </main >
        )
    }
}

export default MergeBill;