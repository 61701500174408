import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import NavContentTab2 from '../../components/partials/NavContentTab2';
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import Panel from '../../components/panel/Panel';
import PanelTab from '../../components/panelContent/PanelTab';
import PanelFigure1 from '../../components/panelContent/PanelFigure1';
import TabPane from '../../components/tab/TabPane';
import SectionCarousel3 from '../../components/partials/card/SectionCarousel3';
import PanelInformation from '../../components/panelContent/PanelInformation';
import BannerWithLink from '../../components/partials/banner/BannerWithLink';
import { Grid } from '@material-ui/core';
import ServiceTag from '../../components/partials/ServiceTag';
class NumberQuery extends Component {
  constructor(props) {
    super(props);
    this.panel = React.createRef();
    this.state = {
      currentTab: 0,
      tab1: {
        head: ['1XY', '110', '113', '117', '118', '119', '165', '166', '167', '168'],
        content: [
          ['敘述', '申請人'],
          ['報案專線', '內政部'],
          ['婦幼專線', '內政部'],
          ['報時專線', '經濟部'],
          ['海巡服務專線', '行政院海岸巡防署'],
          ['消防專線', '內政部'],
          ['防詐騙專線', '內政部'],
          ['國語氣象預報專線', '中央氣象局'],
          ['閩、客、英語氣象預報', '中央氣象局'],
          ['國道路況專線', '國道公路警察局']
        ]
      },
      tab2: {
        head: ['19XY', '1900', '1911', '1912', '1922', '1950', '1955', '1957', '1968'],
        content: [
          ['敘述', '申請人'],
          ['便民服務專線', '內政部'],
          ['申告緊急停電事故搶修及獲知停電訊息服務專線', '內政部'],
          ['緊急事故報修正油氣安全與油價諮詢	', '經濟部'],
          ['防疫專線', '行政院海岸巡防署'],
          ['全國性消費者服務專線	', '內政部'],
          ['外籍勞工24小時諮詢保護專線', '內政部'],
          ['1957福利諮詢專線', '中央氣象局'],
          ['智慧型國道路況語音查詢系統服務專線	', '中央氣象局']
        ]
      }
    }
  }
  changeTab = (val) => {
    this.setState({
      currentTab: val
    })
  }
  render() {
    return (
      <main className="">
        <Header />
        <NavAnchor
          pageTitle='<a href="#">帳單與用量</a>'
          button={{
            link: '#',
            text: '回個人專區',
          }}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '個人專區', link: '/' },
            { text: '電信特殊號碼查詢', link: '/' }
          ]}
          color='white'
        />
        <SectionBanner2
          position='right'
          className="is-360"
          image={{
            md: '/resources/cbu/img/cbu-eservice-special-call-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-eservice-special-call-750x400.jpg',
          }}
          title='<div class="mt-md-5 mt-4">電信特殊號碼查詢</div>'
          action={null}
        />
        <div className="ribbon-bg pt-md-7 pt-4 pb-md-4 pb-0">
          <section className='p-0'>
            <div className='tab-content'>
              <div className="fui-container">
                <h4 className="is-text-darkgray50 mb-md-8 mb-4">
                  此功能提供您進入國家通訊傳播委員會查詢「1XY」(例如：165 防詐騙專線、166 國語氣象預報專線...)及「19XY」(例如：1900 便民服務專線、1922 防疫專線...)之相關專線。
                </h4>
              </div>
              {
                <TabPane active={true}>
                  <div className='fui-container'>
                    <PanelTab
                      ref={this.panel}
                      onClick={(val) => this.changeTab(val)}
                      tabs={{
                        name: 'panel-tab',
                        list: [
                          { name: 'panel-tab-1', label: '1XY範例號碼' },
                          { name: 'panel-tab-2', label: '19XY範例號碼' }
                        ],
                      }}>
                      <div className="paper mt-1">
                        <h4 className='mt-4'>示意範例</h4>
                        <p className="body-1 mb-4">以下為電信特殊號碼示意範例，詳細內容請至國家通訊傳播委員會查詢。查看國家通訊傳播委員會 <a href="#" className="info-link">電信特殊號碼核配現況</a>。</p>
                        <div className="compareTable content-16 clearfix text-wrap bill-table mt-md-0 mt-3 mb-4">
                          <div className="tableLeft">
                            <div className="tableHead">
                              <table>
                                <tbody>
                                  {this.state.tab1.head.map((item, i) => {
                                    return (
                                      <tr className='is-bg-white' key={item}>
                                        <td className={`align-center is-text-bold`}>{item}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="tableRight">
                            <div className="tableBody">
                              <div className="spaceBox">
                                <table className="compareList odd">
                                  <tbody>
                                    {this.state.tab1.content.map((list, i) => (
                                      <tr key={list + i} className={i == 0 ? 'is-bg-lightgray70' : ''}>
                                        {list.map((item, j) => (
                                          <td className={i == 0 ? 'is-text-bold' : 'is-text-regular'} key={item + j}>
                                            {item}
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="paper mt-1">
                        <h4 className='mt-4'>示意範例</h4>
                        <p className="body-1 mb-4">以下為電信特殊號碼示意範例，詳細內容請至國家通訊傳播委員會查詢。查看國家通訊傳播委員會 <a href="#" className="info-link">電信特殊號碼核配現況</a>。</p>
                        <div className="compareTable content-16 clearfix text-wrap bill-table mt-md-0 mt-3 mb-4">
                          <div className="tableLeft">
                            <div className="tableHead">
                              <table>
                                <tbody>
                                  {this.state.tab2.head.map((item, i) => {
                                    return (
                                      <tr className='is-bg-white' key={item}>
                                        <td className={`align-center is-text-bold`}>{item}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="tableRight">
                            <div className="tableBody">
                              <div className="spaceBox">
                                <table className="compareList odd">
                                  <tbody>
                                    {this.state.tab2.content.map((list, i) => (
                                      <tr key={list + i} className={i == 0 ? 'is-bg-lightgray70' : ''}>
                                        {list.map((item, j) => (
                                          <td className={i == 0 ? 'is-text-bold' : 'is-text-regular'} key={item + j}>
                                            {item}
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PanelTab>
                  </div>
                </TabPane>
              }
            </div>
          </section>
        </div>
      </main>
    );
  }
}
export default NumberQuery;