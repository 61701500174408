import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Link from '../Link'
import Card from '../card/Card'

export default class MoreHelp extends Component {
    static propTypes = {
        cards: PropTypes.array,
        link: PropTypes.string
    }

    static defaultProps = {
        cards: [],
        link: ''
    };
    

    render() {
        const { cards, link } = this.props;

        return (
            <section>
                <h3 className='mb-3 mb-lg-0'>需要進一步的幫助? </h3>
                <div className='fui-cards no-scrollbar two-card pb-3'>
                    {
                        cards.length && cards.map((card, i) => (
                            <Card
                                key={`more-help-${i}`}
                                link={card.link}
                                icon={card.icon}
                                title={card.title}
                                description={card.description}
                            />
                        ))
                    }
                </div>
                <div className='align-right'>
                    <Link to={link} className='fui-button is-text pr-0'>
                        <span className='text'>查詢企業用戶常見問題</span>
                        <i className='icon-chevron-right'></i>
                    </Link>
                </div>
            </section>
        )
    }
}
