import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';
import Modal from 'react-modal';

import FormBanner from '../../components/partials/banner/FormBanner';
import LabelInput from '../../components/form/LabelInput';
import Select from '../../components/form/Select';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import ParkingCheckAreaSection from '../../components/form/ParkingCheckAreaSection';
import Checkbox from '../../components/form/Checkbox';
import Button from '../../components/Button';
import * as Validation from '../../utils/validation';


class BillSetting extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef()
    this.form2 = React.createRef()
    this.state = {
      step: 1,
      canSubmit: false,
      modalOpen: false,
      defaultFormChanged: false,
      formChanged: false,
      removeIndex: 0,
      selectedArea: {
        index: 0,
        value: ''
      },
      defaultForm: [
        {
          number: '0916814747',
          billAlert: [
            { value: false }
          ],
          endDateAlert: [
            { value: false }
          ],
        },
        {
          number: '0916814748',
          billAlert: [
            { value: false }
          ],
          endDateAlert: [
            { value: false }
          ],
        },
      ],
      form: {
        agree: { value: false },
        type: [
          { value: 'number' },
        ],
        number: [
          { value: '0916814740' },
        ],

        remove: [
          { value: false },
        ],
        billAlert: [
          { value: true },
        ],
        endDateAlert: [
          { value: true },
        ],
      },
      form2: {
        id_number: { value: '', required: false },
      },
      showArea: false
    };
  };

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  }

  onChange = (n, v, i) => {
    // console.log(n, v, i)
    let form = Object.assign(this.state.form);
    if (n == 'number') {
      v = v.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
    }
    if (!i && i !== 0) {
      form[n].value = v
    } else {
      form[n][i].value = v
    }
    this.state.form.number.forEach((item) => {
      if (!!item.value) {
        this.setState({
          form: form,
          formChanged: true
        })
      } else {
        this.setState({
          form: form,
          formChanged: false
        })
      }
    })
  }
  onChange2 = (name, value) => {
    let newForm = Object.assign(this.state.form2);
    newForm[name].value = value;
    // console.log('onChange', name, value)
    this.setState({
      form2: newForm,
    });
  };
  defaultFormOnChange = (n, v, i) => {
    // console.log(n, v, i)
    let form = Object.assign(this.state.defaultForm);
    if (n == 'number') {
      v = v.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
    }
    if (!i && i !== 0) {
      form[n].value = v
    } else {
      form[i][n].value = v
    }

    this.setState({
      defaultForm: form,
      defaultFormChanged: true
    })
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  conifrmRemoveCarInfo = () => {
    this.setState({
      modalOpen: true
    });
  }

  addCarInfo = () => {
    let form = Object.assign(this.state.form);
    form.type.push({ value: '' })
    form.number.push({ value: '' })
    form.billAlert.push({ value: true })
    form.endDateAlert.push({ value: true })
    form.remove.push({ value: null })

    this.state.form.number.forEach((item) => {
      if (!!item.value) {
        this.setState({
          form: form,
          formChanged: true
        })
      } else {
        this.setState({
          form: form,
          formChanged: false
        })
      }
    })
  }

  setArea = (index) => {
    this.setState({
      selectedArea: {
        index: 0,
        value: ''
      }
    })

    this.setState({
      showArea: true,
      selectedArea: {
        index: index,
        value: this.state.form.area[index].value
      }
    })

    this.forceUpdate()
  }

  closeArea = () => {
    this.setState({
      showArea: false,
      selectedArea: {
        index: 0,
        value: ''
      }
    })
  }

  resetArea = () => {
    let form = Object.assign(this.state.form);
    form.area[this.state.selectedArea.index].value = this.state.selectedArea.value;

    this.setState({
      form: form,
      showArea: false
    })
  }

  beforeSubmit = () => {
    if (this.state.form.remove.find(item => item.value === true)) {
      this.conifrmRemoveCarInfo();
    } else {
      this.submit();
    }
  }

  submit = () => {
    this.closeModal();
    console.info("SUBMIT")
  }

  render() {
    const { form } = this.state;
    const { defaultForm } = this.state;

    return (
      <main className='bill-notify-setting'>

        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="帳務及繳費通知設定"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />

        <section className={`page-body ${this.state.showArea ? 'd-none' : 'd-block'}`}>
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
            <div className="fui-container">
              <div className='paper mb-0'>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <h4 className="mb-2 mt-0">以下為您同份帳單門號，您可設定每個門號簡訊接收狀態</h4>
                  </Grid>
                </Grid>

                <div className="car-information-list">
                  {this.state.defaultForm.map((item, i) => {
                    return (
                      <div key={item.number} className="car-information-item">
                        <div className="car-information-header">
                          <div className="content">門號 {i + 1}</div>
                        </div>
                        <div className="car-information-body mb-md-4 mb-0">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              <div className="form-group">
                                <label>門號</label>
                                <div className="form-list">
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <div className="input-group">
                                        <input disabled type='text' name='number' defaultValue={defaultForm[i].number} />
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <Checkbox
                                        name='billAlert'
                                        className='checkbox mt-0 m-md-0 pb-1'
                                        label="帳單金額與繳費通知結果"
                                        default={defaultForm[i].billAlert.value}
                                        onChange={(n, v) => this.defaultFormOnChange(n, v, i)}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <Checkbox
                                        name='endDateAlert'
                                        className='m-0'
                                        label="繳費截止日提醒"
                                        default={defaultForm[i].endDateAlert.value}
                                        onChange={(n, v) => this.defaultFormOnChange(n, v, i)}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )
                  })}

                  <h4 className="mb-3 pt-5 mt-2">同證號下其他門號接收帳務簡訊</h4>

                  {
                    form.type.map((type, i) => (
                      <div className="car-information-item" key={`ar-information-item-${i}`}>
                        <div className="car-information-header">
                          <div className="content">門號 {i + 1}</div>
                          <div className='action'>
                            {form.remove[i].value !== null ? <Checkbox
                              name='remove'
                              label="移除"
                              default={form.remove[i].value}
                              onChange={(n, v) => this.onChange(n, v, i)}
                            /> : null}
                          </div>
                        </div>
                        <div className="car-information-body mb-md-4 mb-0">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              <div className="form-group">
                                <label>門號</label>
                                <div className="form-list">
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <div className="input-group">
                                        <input type='text' name='number[]' value={form.number[i].value} onChange={(e) => this.onChange('number', e.target.value, i)} />
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <Checkbox
                                        name='billAlert'
                                        className='checkbox m-0 pb-1'
                                        label="帳單金額與繳費通知結果"
                                        default={form.billAlert[i].value}
                                        checked={form.billAlert[i].value}
                                        onChange={(n, v) => this.onChange(n, v, i)}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <Checkbox
                                        name='endDateAlert'
                                        className='m-0'
                                        label="繳費截止日提醒"
                                        default={form.endDateAlert[i].value}
                                        checked={form.endDateAlert[i].value}
                                        onChange={(n, v) => this.onChange(n, v, i)}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            </Grid>

                          </Grid>
                        </div>
                      </div>
                    ))
                  }

                </div>
                <div className="car-information-action pb-2 pb-0">
                  <div role="button" className="fui-button d-block is-large" onClick={this.addCarInfo}>
                    <span className='text text-sm'>新增更多門號</span>
                    <i className='icon-plus heading-5 is-text-regular'></i>
                  </div>
                </div>
              </div>



              {this.state.step == 1 ? <div className="page-action mt-5 pt-2">
                <Button btnStyle="primary" size="large" disabled={!this.state.defaultFormChanged && !this.state.formChanged} onClick={() => this.setState({ step: 2 })}>下一步</Button>
              </div> : null}


            </div>
          </Formsy>

          {
            this.state.step === 2 ? (
              <section className={`pb-0 pt-4 validation`}>
                <div className="fui-container">
                  <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form2}>
                    <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          name='id_number'
                          className="mb-0"
                          required={this.state.form2.id_number.required}
                          label='證照號碼'
                          validations={{
                            idNumberValid: Validation.idNumberValid
                          }}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入證照號碼'
                          }}
                          placeholder='請輸入證照號碼'
                          value={this.state.form2.id_number.value}
                          onChange={this.onChange2}
                        />
                        <p className='body1 is-text-darkgray50 mb-5'>
                          身分說明：
                            <br />
                            個人戶：身分證字號
                            <br />
                            公司戶：統一編號或稅籍編號
                            <br />
                            外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                        </p>
                        <Button btnStyle="primary" size="large" disabled={!this.state.canSubmit} onClick={this.beforeSubmit}>確認</Button>
                      </Grid>
                    </Grid>
                  </Formsy>
                </div>
              </section>
            ) : null
          }
        </section>
      </main>
    )
  }
}

export default BillSetting;