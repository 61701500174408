import React from 'react';

import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import NavTab from '../../components/partials/NavTab';
import Card from '../../components/card/Card';
import Panel from '../../components/panel/Panel';
import PanelTitle1 from '../../components/panelContent/PanelTitle1';
import HelpCenterBanner from '../../components/partials/banner/HelpCenterBanner';

import * as Mock from '../../mock/HelpCenterMock';
import AppPromotion from '../../components/partials/AppPromotion';

class HelpCenter extends React.Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);

    this.state = {
      currentTab: query.get('tab') === 'ebu' ? 1 : query.get('tab') === 'contact' ? 2 : 1,
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    };
  }

  componentDidMount = () => {
    window.addEventListener('resize', e => {
      this.setState({
        isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
      })
    })
  }

  tabChange = index => {
    this.setState({
      currentTab: index
    })
  };

  render() {
    const { currentTab } = this.state;
    return (
      <main className="help-center">
        <NavTab
          pageTitle='幫助中心'
          onChange={this.tabChange}
          default={0}
          tabs={{
            name: 'help-center-tab',
            list: [
              { name: 'tab-1', label: '個人用戶', link: '#' },
              { name: 'tab-2', label: '商務用戶', link: 'http://fetnet-demo.aja.com.tw/help-center' },
              { name: 'tab-3', label: '聯絡我們', link: '/help-center/contact' },
              { name: 'tab-4', label: '服務條款', link: '/help-center/terms-of-service/privacy' },
            ],
          }}
          button={{
            text: '回首頁',
            link: '/help-center',
          }}
        />
        <HelpCenterBanner
          title={"需要什麼幫助?"}
          bgImg={{
            sm: "/resources/cbu/help-center/images/ebu-helpcenter-home-banner-sm.jpg",
            md: "/resources/cbu/help-center/images/ebu-helpcenter-home-banner.jpg",
          }}
          keyword={{
            defaultKeyword: [
              'Gogoro',
              'Gogoro 699',
              'google',
              'GPS',
              'GPS Google',
              'Gogo',
              'GoPro',
              'Geo',
              'SEO',
              'OPPO',
            ],
            path: '/help-center/search',
            placeholder: '找個人用戶常見問題'
          }}
          hot={{
            path: '/help-center',
            hotword: [
              '遠傳物聯網',
              '雲端運算',
              '主機代管',
              '大寬頻企業光纖',
              'Office 365',
              '007 國際電話',
              '世界電話國碼',
              '世界電話國碼',
              '世界電話國碼',
              '世界電話國碼',
            ]
          }}
        />
        <section className='fui-help-shortcut'>
          <div className='fui-container fui-shortcut-container'>
            {Mock.shortcut.map((item, i) => (
              <Link to={item.link} key={`help-center-shortcut-${i}`} className='fui-shortcut-item'>
                <img src={item.image} alt={item.text} />
                <div className='text'>{item.text}</div>
              </Link>
            ))}
          </div>
        </section>
        <section className='fui-horizontal-card help-center-search-card'>
          <div className='fui-container'>
            <div className='fui-section-header'>
              <h2>{Mock.searchCard.title}</h2>
              <Link to={Mock.searchCard.more.link} className='fui-button is-text pr-0'>
                <span className='text'>{Mock.searchCard.more.text}</span>
                <i className='icon-chevron-right mr-0'></i>
              </Link>
            </div>
            <div className='fui-cards four-card no-scrollbar'>
              {Mock.searchCard.cards.map((card, idx) => (
                <Card key={idx} {...card} link={this.state.isMobile ? card.linkMb : card.link} />
              ))}
            </div>
          </div>
        </section>
        <AppPromotion
          bgImage='/resources/cbu/help-center/images/bg-all.png'
          title="遠傳行動客服 APP"
          subtitle="貼心服務不用等"
          description={`
            <p>
              以遠傳門號快速登入, 上網流量即時看，查帳單、繳帳單，一鍵完成！
              <br />
              還有許多用戶獨享驚喜優惠
            </p>`
          }
          icon='/resources/cbu/help-center/images/img-logo-fet.png'
          android={{
            qrCode: '/resources/cbu/help-center/images/qr-android.jpg',
            icon: '/resources/cbu/help-center/images/google-play.png',
            link: '/',
            title: '支援 Android 7 以上版本'
          }}
          ios={{
            qrCode: '/resources/cbu/help-center/images/qr-ios.png',
            icon: '/resources/cbu/help-center/images/app-store.png',
            link: '/',
            title: '支援 iOS 10 以上版本'
          }}
        />

      </main>
    );
  }
}

export default HelpCenter;
