import React from 'react';
import { Grid } from '@material-ui/core';
import NavAnchor from '../components/partials/NavAnchor';
import Pagination from '../components/paginate/Pagination';
import Panel from '../components/panel/Panel';
import LabelTextarea from '../components/form/LabelTextarea';
import RadioButtons from '../components/form/RadioButtons';
import Formsy from 'formsy-react';
import PanelInformation from '../components/panelContent/PanelInformation';
import FaqCollapse from '../components/collapse/FaqCollapse';
import Breadcrumb from '../components/Breadcrumb';
import MoreHelp from '../components/helpCenterContent/MoreHelp';
import FaqBanner from '../components/partials/banner/FaqBanner';
import { CollapseMenu as Menu } from '../components/partials/collapse/CollapseMenuThirdLevel';
import GroupExtraLinkCard from '../components/partials/card/GroupExtraLinkCard';
import LoadMore from '../components/LoadMore';
import ProductMap from '../components/partials/ProductMap';
import FooterAd from '../components/ad/FooterAd';

import * as DiscountMock from '../mock/Discount';

class Discount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEn: false,
      data: null,
      article: DiscountMock.a,
      currentArticleLoadMore: true,
      feedbackForm: {
        feedbackOption: '',
        comment: '',
      },
    };
  }

  loadMore = () => {
    // console.log(DiscountMock.loadMoreData);
    this.setState({
      article: [...this.state.article, ...DiscountMock.loadMoreData],
      currentArticleLoadMore: false,
    });
  };

  // 切換menu頁面
  selectMenu = val => {
    // if (!!!val.content) {
    //   this.setState({
    //     article: DiscountMock[`${val.id}`],
    //   });
    // }
  };
  onChangePage(pageOfItems, selectedPage) {
    // let locationSearch = this.props.location.search;
    // let queryParams = new URLSearchParams(locationSearch);
    // let searchParam = '';
    // let searchParamsWithoutPage = '';

    // if (queryParams.has('page')) {
    //     queryParams.delete('page');
    //     searchParamsWithoutPage = queryParams.toString();
    //     searchParam = searchParamsWithoutPage ? (`?${searchParamsWithoutPage}&page=${selectedPage}`) : (`?page=${selectedPage}`);
    // } else {
    //     searchParam = locationSearch ?  (locationSearch + `&page=${selectedPage}`) : (`?page=${selectedPage}`)
    // }

    // // TODO: 由於回上一頁 issue，先以 location.href 重新整理換分頁，暫時註解待後續優化。
    // if (typeof window !== 'undefined') window.location.href = `${this.props.location.pathname + (searchParam || '')}`;
  }
  feedbackRender() {
    if (this.state.feedbackCompelete) {
      return (
        <div className='is-text-darkgray50'>
          <i className='icon-check'></i> 感謝您的回應！
        </div>
      );
    }
    if (this.state.editForm) {
      return (
        <Formsy
          className=''
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalidSubmit={this.disableButton}
          noValidate
          ref={this.form}>
          <div className='is-text-darkgray50'>為什麼沒有幫助？</div>

          <RadioButtons
            name='feedbackOption'
            options={[
              { value: '1', label: '這說明沒有解決我的問題' },
              { value: '2', label: '這些資訊不正確' },
              { value: '3', label: '我看不懂' },
              { value: '4', label: '其它' },
            ]}
            selected={this.state.feedbackForm.feedbackOption}
            getSelected={e => this.storeForm('feedbackOption', e)}
          />
          {this.state.commentShow ? (
            <LabelTextarea
              placeholder='若您對此解答有其他意見，請說明。幫助我們提供更佳服務，謝謝！'
              label=''
              name='comment'
              value={this.state.feedbackForm.comment}
              onChange={this.storeForm}
            />
          ) : null}

          <div className='input-group'>
            <button className='fui-button is-secondary' onClick={e => this.returnFeedback(-1)}>
              <span className='text'>送出</span>
            </button>
            <button className='fui-button is-secondary' onClick={e => this.resetFeedback()}>
              <span className='text'>取消</span>
            </button>
          </div>
        </Formsy>
      );
    }

    return (
      <div>
        <span className='text is-text-darkgray50'>答案有幫助嗎？</span>
        <button className='fui-button is-secondary' onClick={e => this.returnFeedback(1)}>
          <span className='text'>是</span>
        </button>
        <button className='fui-button is-secondary' onClick={e => this.returnFeedback(0)}>
          <span className='text'>否</span>
        </button>
      </div>
    );
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回首頁',
            link: '/help-center',
          }}
        />
        <Breadcrumb
          color='white'
          breadcrumb={[
            { link: '/help-center', text: '商務總覽' },
            { link: '/help-center/faq', text: '常見問題-Office365' },
          ]}
        />
        <FaqBanner
          bgImg={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png'
          }}
          keyword={{
            defaultKeyword: [
              'Gogoro',
              'Gogoro 699',
              'google',
              'GPS',
              'GPS Google',
              'Gogo',
              'GoPro',
              'Geo',
              'SEO',
              'OPPO',
            ],
            path: '/help-center/search',
            placeholder: '請輸入關鍵字'
          }}
          hot={{
            path: '/help-center/search',
            hotword: [
              '遠傳物聯網',
              '雲端運算',
              '主機代管',
              '大寬頻企業',
              'Office 365',
              '007 國際電話',
              '007 國際電話',
              '007 國際電話',
              '大寬頻企業',
            ]
          }}
        />

        <section>
          <div className='fui-container discount-layout'>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={4}>
                <Menu
                  selectMenu={this.selectMenu}
                  menu={{
                    name: '常見問題分類',
                    currentSelected: '',
                    content: [
                      // {
                      //   name: '熱門優惠',
                      //   id: 'a',
                      // },
                      {
                        name: '4.5G語音+上網',
                        id: 'b',
                        content: [
                          {
                            name: '單門號',
                            id: 'b01',
                            content: [
                              {
                                name: '單單單門號',
                                id: 'b011',
                              },
                              {
                                name: '單單單門號2',
                                id: 'b012',
                              },
                            ]
                          },
                          {
                            name: '上網微微飽',
                            id: 'b02',
                            content: [
                              {
                                name: '上網微微微飽',
                                id: 'b021',
                              },
                              {
                                name: '上網微微微飽2',
                                id: 'b022',
                              },
                            ]
                          },
                          {
                            name: '飆網吃到飽',
                            id: 'b03',
                            content: [
                              {
                                name: '飆網吃到飽飽',
                                id: 'b031',
                              },
                              {
                                name: '飆網吃到飽飽飽',
                                id: 'b032',
                              },
                            ]
                          },
                          {
                            name: '上網搭手機',
                            id: 'b04',
                          },
                          {
                            name: '上網搭家電',
                            id: 'b05',
                          },
                          {
                            name: '上網搭寬頻',
                            id: 'b06',
                          },
                          {
                            name: '上網搭生活娛樂',
                            id: 'b06',
                          },
                        ],
                      },
                      {
                        name: '推薦續約',
                        id: 'c',
                        content: [
                          {
                            name: '行銷01',
                            id: 'c01',
                            link: '/123'
                          },
                          {
                            name: '行銷02',
                            id: 'c02',

                            content: [
                              {
                                name: '行銷021',
                                id: 'c031',
                              },
                              {
                                name: '行銷022',
                                id: 'c032',
                              },
                            ]
                          },
                        ],
                      },
                      {
                        name: '網路門市限定',
                        id: 'd'
                      },
                      {
                        name: '家用上網',
                        id: 'e'
                      },
                      {
                        name: '遠傳易付卡',
                        id: 'f'
                      },
                      {
                        name: '出國上網',
                        id: 'g'
                      },
                      {
                        name: '用戶專屬優惠',
                        id: 'h'
                      },
                      {
                        name: 'friDay優惠',
                        id: 'i'
                      },
                    ],
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} className='faq-container'>
                <Panel>
                  <h3>熱門常見問題</h3>
                  <FaqCollapse
                    title="【網外】速博off-net+ADSL已下架，相關申請問題？"
                    content={`<p>速博off-net+ADSL已下架，不接受客戶新申請，若既有客戶異動或特殊原因仍需申請此項下架服務時，請撥打企業客服電話4499-365(手機撥打+02)索取申請書及詢問相關申請及開通問題;服務開通後，客戶需先連結至https://fixed-serv.fetnet.net/adsl/index.jsp此網頁成功後，才能連上其他網頁。</p><table class="compareList mb-6">
                    <tbody>
                        <tr style="height: 52px;">
                            <td>國家</td>
                            <td>國碼</td>
                            <td>每分鐘通話費</td>
                            <td>每分鐘通話費</td>
                            <td>每分鐘通話費</td>
                            <td>每分鐘通話費</td>
                            <td>每分鐘通話費</td>
                            <td>每分鐘通話費</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>香港</td>
                            <td>852</td>
                            <td>NT $6.7</td>
                            <td>NT $6.7</td>
                            <td>NT $6.7</td>
                            <td>NT $6.7</td>
                            <td>NT $6.7</td>
                            <td>NT $6.7</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>日本/南韓</td>
                            <td>81/82</td>
                            <td>NT $9.1</td>
                            <td>NT $9.1</td>
                            <td>NT $9.1</td>
                            <td>NT $9.1</td>
                            <td>NT $9.1</td>
                            <td>NT $9.1</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>中國</td>
                            <td>86</td>
                            <td>NT $8.4</td>
                            <td>NT $8.4</td>
                            <td>NT $8.4</td>
                            <td>NT $8.4</td>
                            <td>NT $8.4</td>
                            <td>NT $8.4</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>新加坡</td>
                            <td>65</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>馬来西亞</td>
                            <td>65</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>美國/加拿大</td>
                            <td>65</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                        </tr>
                    </tbody>
                </table> `}
                    detailLink="#"
                    open={true}
                    feedback={e => console.log(e)}
                    hasFeedback={true}
                  />
                  <FaqCollapse
                    title="【網外】速博off-net+ADSL已下架，相關申請問題？"
                    content={`<p>速博off-net+ADSL已下架，不接受客戶新申請，若既有客戶異動或特殊原因仍需申請此項下架服務時，請撥打企業客服電話4499-365(手機撥打+02)索取申請書及詢問相關申請及開通問題;服務開通後，客戶需先連結至https://fixed-serv.fetnet.net/adsl/index.jsp此網頁成功後，才能連上其他網頁。</p><table class="compareList mb-6">
                    <tbody>
                        <tr style="height: 52px;">
                            <td>國家</td>
                            <td>國碼</td>
                            <td>每分鐘通話費</td>
                            <td>每分鐘通話費</td>
                            <td>每分鐘通話費</td>
                            <td>每分鐘通話費</td>
                            <td>每分鐘通話費</td>
                            <td>每分鐘通話費</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>香港</td>
                            <td>852</td>
                            <td>NT $6.7</td>
                            <td>NT $6.7</td>
                            <td>NT $6.7</td>
                            <td>NT $6.7</td>
                            <td>NT $6.7</td>
                            <td>NT $6.7</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>日本/南韓</td>
                            <td>81/82</td>
                            <td>NT $9.1</td>
                            <td>NT $9.1</td>
                            <td>NT $9.1</td>
                            <td>NT $9.1</td>
                            <td>NT $9.1</td>
                            <td>NT $9.1</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>中國</td>
                            <td>86</td>
                            <td>NT $8.4</td>
                            <td>NT $8.4</td>
                            <td>NT $8.4</td>
                            <td>NT $8.4</td>
                            <td>NT $8.4</td>
                            <td>NT $8.4</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>新加坡</td>
                            <td>65</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>馬来西亞</td>
                            <td>65</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                        </tr>
                        <tr style="height: 52px;">
                            <td>美國/加拿大</td>
                            <td>65</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                            <td>NT $7.3</td>
                        </tr>
                    </tbody>
                </table> `}
                    // detailLink="#"
                    open={true}
                    feedback={e => console.log(e)}
                    hasFeedback={true}
                  />
                </Panel>

                <Pagination
                  items={['1', '2', '3', '4']}
                  onChangePage={this.onChangePage}
                  initialPage={1}
                  pageSize={2}
                />


                <MoreHelp
                  cards={
                    [
                      {
                        link: '/content/ebu/tw/form/online-faq-form.html',
                        icon: '/resources/cbu/help-center/images/business-response.svg',
                        title: '企業用戶問題反應',
                        description: '有任何問題嗎? 您可立即線上填寫資料，預約專人為您服務。',
                      },
                      {
                        link: '/content/ebu/tw/help-center/contact-us.html',
                        icon: '/resources/cbu/help-center/images/business-customer-center.svg',
                        title: '企業客服中心',
                        description: `服務專線 <a href='tel:024499365'>02-4499-365</a>`
                      }
                    ]
                  }
                  link={'/content/ebu/tw/help-center/popular-questions.html'}>
                </MoreHelp>
              </Grid>
            </Grid>
          </div>

        </section>
      </main>
    )
  }
}


export default Discount;