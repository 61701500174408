import React from 'react';
import DatePicker from "react-datepicker";
import RadioButtons from '../../components/form/RadioButtons';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import Select from '../../components/form/Select';
import LabelTextarea from '../../components/form/LabelTextarea';
import Link from '../../components/Link';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import NavAnchor from '../../components/partials/NavAnchor';
import RadioGroup from '../../components/form/RadioGroup';
import FormBanner from '../../components/partials/banner/FormBanner';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import * as Validation from '../../utils/validation';
class Mailbox extends React.Component {
  constructor(props) {
    super(props);
    this.ChangeForm = React.createRef();
    this.state = {
      emailCanSubmit: false,
      form: {
        id_number: { value: '', required: false },
        questionType: { value: '0', required: false },
        account: { value: '', required: false },
        accountBarcode: { value: '', required: false },
        emailAddress: { value: '', required: false },
        emailType: { value: '0', required: false },
        email: { value: '', required: false },
        willingContact: { value: '0', required: false },
        question1: { value: '', required: false },
        question2: { value: '', required: false },
        question3: { value: '', text: '帳單繳費相關', required: false },
        question4: { value: '', text: '帳單內容抵扣問題', required: false },
        comment: { value: '', required: true },
        orderNumber: { value: '', required: true },
        phoneNumber: { value: '', required: true },
        contactName: { value: '', required: true },
        contactPhone: { value: '', required: true },
        validCode: { value: '', required: true },
        questionPhone: { value: '', required: true },
        questionNumber: { value: '', required: true },
        questionDate: { value: '', required: true },
        question_hour: { value: '', required: true },
        question_min: { value: '', required: true },
        dateOfBirth: { value: '', required: true },
        billMonth: { value: '', required: true },
        receiptTime: { value: '', required: true },
        receivePhone: { value: '', required: true },
        activityName: { value: '', required: true },
        fixNumber: { value: '', required: true },
        otherPhone: { value: '', required: true },
        phoneBrand: { value: '', required: true },
        simCode: { value: '', required: true },
        showing: { value: '', required: true },
        billRate: { value: '', required: true },
        roamingCountry: { value: '', required: true },
        questionURL: { value: '', required: true },
        way: { value: '', required: true },
        suggestion: { value: '', required: true },
        city: { value: '', required: true },
        area: { value: '', required: true },
        village: { value: '', required: true },
        neighborhood: { value: '', required: true },
        road: { value: '', required: true },
        section: { value: '', required: true },
        lane: { value: '', required: true },
        number: { value: '', required: true },
        alley: { value: '', required: true },
        floor: { value: '', required: true },
        questionType01: { value: '0', required: true },
        questionType02: { value: 'true', required: true },
        questionType03: { value: 'true', required: true },
        questionType04: { value: '0', required: true },
        url: { value: '', required: true },
        isErrorCode: { value: '', required: true },
        errorCode: { value: '', required: true },
        enterErrorCode: { value: '', required: true },
        explaination: { value: '', required: true },
        explain: { value: '', required: true },
        defination: { value: '', required: true },
        recentContact: { value: 'true', required: true },
        agree: { value: false, required: true },
      },
      canSubmit: false,
      step: 1,
    }
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  onChange = (name, value) => {
    // console.log(this.emailForm);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    // console.log('onChange', name, value)
    this.setState({
      form: newForm,
    });
  };
  getBannerTitle = () => {
    // console.log(this.state.form.questionType.value);
    if (this.state.step === 1) {
      return '客服信箱'
    } else {
      switch (this.state.form.questionType.value) {
        case '0':
          return '電信門號相關問題'
        case '1':
          return '網路購物相關問題'
        case '2':
          return '上網/通話訊號相關問題'
        default:
          return '客服信箱'
      }
    }

  }
  submit = () => {
    if (!!this.emailForm.current) {
      this.setState({
        modalOpen: true
      })
    } else if (this.state.resentType === 'email') {
      this.setState({
        modalOpen: true
      })
    } else {
      console.log('submit!');
    }
  }
  getQuestionType = () => {
    switch (this.state.form.questionType.value) {
      case '0':
        return <div>
          <section className="pt-md-7 pt-4 no-bg">
            <div className="fui-container">
              <div className="no-shadow is-bg-initial">
                <Formsy onValid={() => { this.setState({ emailCanSubmit: true }) }} onInvalid={() => { this.setState({ emailCanSubmit: false }) }} ref={this.ChangeForm}>
                  <h4 className="is-text-darkgray50 mb-2">請提供以下詳細資料，以便瞭解待改善之處</h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <LabelInput
                        className="mb-2 hide-required w-100"
                        name='questionPhone'
                        required={this.state.form.questionPhone.required}
                        label="反應問題門號"
                        validationErrors={{ isRequired: '請輸入反應問題門號' }}
                        placeholder='請輸入反應問題門號'
                        value={this.state.form.questionPhone.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <div className="form-group mt-md-6 mt-4 mb-md-6">
                    <label>事件發生日期</label>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={5}>
                        <DatePicker
                          dateFormat="Y/MM/dd"
                          className="is-bg-white"
                          lang="zh-TW"
                          // minDate={new Date()}
                          selected={this.state.form.questionDate.value}
                          onChange={date => this.onChange('questionDate', date)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2}>
                        <Select
                          name="question_hour"
                          required={true}
                          label={"時"}
                          value={this.state.form.question_hour.value}
                          options={[
                            { text: '01時', value: '01' },
                            { text: '02時', value: '02' },
                            { text: '03時', value: '03' },
                            { text: '04時', value: '04' },
                            { text: '05時', value: '05' },
                            { text: '06時', value: '06' },
                            { text: '07時', value: '07' },
                            { text: '08時', value: '08' },
                            { text: '09時', value: '09' },
                            { text: '10時', value: '10' },
                            { text: '11時', value: '11' },
                            { text: '12時', value: '12' }
                          ]}
                          onChange={this.onChange} />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2}>
                        <Select
                          name="question_min"
                          required={true}
                          label={"分"}
                          value={this.state.form.question_min.value}
                          options={[
                            { text: '00分', value: '00' },
                            { text: '01分', value: '01' },
                            { text: '02分', value: '02' },
                            { text: '03分', value: '03' },
                            { text: '04分', value: '04' },
                            { text: '05分', value: '05' },
                            { text: '06分', value: '06' },
                            { text: '07分', value: '07' },
                            { text: '08分', value: '08' },
                            { text: '09分', value: '09' },
                            { text: '10分', value: '10' },
                            { text: '11分', value: '11' },
                            { text: '12分', value: '12' },
                            { text: '13分', value: '13' },
                            { text: '14分', value: '14' },
                            { text: '15分', value: '15' },
                            { text: '16分', value: '16' },
                            { text: '17分', value: '17' },
                            { text: '18分', value: '18' },
                            { text: '19分', value: '19' },
                            { text: '20分', value: '20' }
                          ]}
                          onChange={this.onChange} />
                      </Grid>
                    </Grid>

                  </div>
                  <div className="form-group mb-md-4 mb-2">
                    <label>事件發生地點(請輸入完整門牌地址)</label>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Select
                          required={true}
                          name='city'
                          label='縣市'
                          value={this.state.form.city.value}
                          options={[{ text: '台北市', value: '台北市' }]}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Select
                          required={true}
                          name='area'
                          label='鄉鎮市區'
                          value={this.state.form.area.value}
                          options={[{ text: '中正區', value: '中正區' }]}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className='mt-3'>
                      <Grid item xs={6} sm={6} md={3} className='d-flex'>
                        <LabelInput
                          className="mb-2 hide-required w-100"
                          name='village'
                          required={this.state.form.village.required}
                          validationErrors={{ isRequired: '請輸入反應問題門號' }}
                          placeholder='請輸入村里'
                          value={this.state.form.village.value}
                          onChange={this.onChange}
                        />
                        <p style={{ flex: `1 1 66px` }} className='pl-1 mb-2 mt-1'>村/里</p>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} className='d-flex'>
                        <LabelInput
                          className="mb-2 hide-required w-100"
                          name='neighborhood'
                          required={this.state.form.neighborhood.required}
                          validationErrors={{ isRequired: '請輸入鄰' }}
                          placeholder='請輸入鄰'
                          value={this.state.form.neighborhood.value}
                          onChange={this.onChange}
                        />
                        <p style={{ flex: `1 1 66px` }} className='pl-1 mb-2 mt-1'>鄰</p>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} className='d-flex'>
                        <LabelInput
                          className="mb-2 hide-required w-100"
                          name='road'
                          required={this.state.form.road.required}
                          validationErrors={{ isRequired: '請輸入反應問題門號' }}
                          placeholder='請輸入街/路'
                          value={this.state.form.road.value}
                          onChange={this.onChange}
                        />
                        <p style={{ flex: `1 1 66px` }} className='pl-1 mb-2 mt-1'>街/路</p>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} className='d-flex mb-2'>
                        <Select
                          className="w-100"
                          required={true}
                          name='section'
                          label='請選擇'
                          value={this.state.form.section.value}
                          options={[{ text: '一', value: '一' }]}
                          onChange={this.onChange}
                        />
                        <p style={{ flex: `1 1 66px` }} className='pl-1 mb-0 mt-0 d-flex flex-align-center'>段</p>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} className='d-flex'>
                        <LabelInput
                          className="mb-2 hide-required w-100"
                          name='lane'
                          required={this.state.form.lane.required}
                          validationErrors={{ isRequired: '請輸入巷' }}
                          placeholder='請輸入巷'
                          value={this.state.form.lane.value}
                          onChange={this.onChange}
                        />
                        <p style={{ flex: `1 1 66px` }} className='pl-1 mb-2 mt-1'>巷</p>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} className='d-flex'>
                        <LabelInput
                          className="mb-2 hide-required w-100"
                          name='alley'
                          required={this.state.form.alley.required}
                          validationErrors={{ isRequired: '請輸入弄' }}
                          placeholder='請輸入弄'
                          value={this.state.form.alley.value}
                          onChange={this.onChange}
                        />
                        <p style={{ flex: `1 1 66px` }} className='pl-1 mb-2 mt-1'>弄</p>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} className='d-flex'>
                        <LabelInput
                          className="mb-2 hide-required w-100"
                          name='number'
                          required={this.state.form.number.required}
                          validationErrors={{ isRequired: '請輸入號' }}
                          placeholder='請輸入號'
                          value={this.state.form.number.value}
                          onChange={this.onChange}
                        />
                        <p style={{ flex: `1 1 66px` }} className='pl-1 mb-2 mt-1'>號</p>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} className='d-flex'>
                        <LabelInput
                          className="mb-2 hide-required w-100"
                          name='floor'
                          required={this.state.form.floor.required}
                          validationErrors={{ isRequired: '請輸入樓' }}
                          placeholder='請輸入樓'
                          value={this.state.form.floor.value}
                          onChange={this.onChange}
                        />
                        <p style={{ flex: `1 1 66px` }} className='pl-1 mb-2 mt-1'>樓</p>
                      </Grid>
                    </Grid>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <RadioGroup
                        label='問題類型'
                        name="questionType02"
                        className='mb-md-5 mb-4'
                        options={[
                          { label: '語音', value: 'true' },
                          { label: '上網', value: 'false' }
                        ]}
                        default={this.state.form.questionType02.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <RadioGroup
                        label='發生地點'
                        name="questionType03"
                        options={[
                          { label: '室內', value: 'true' },
                          { label: '室外', value: 'false' }
                        ]}
                        default={this.state.form.questionType03.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <RadioGroup
                        label='發生狀況'
                        className='mb-md-4 mb-0'
                        name="questionType04"
                        options={[
                          { label: '無訊號涵蓋', value: '0' },
                          { label: '有訊號但無法連線/連線中斷', value: '1' },
                          { label: '有訊號但連線速度緩慢', value: '2' },
                          { label: '有訊號但無法開啟特定網頁', value: '3' },
                        ]}
                        default={this.state.form.questionType04.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <hr />
                  <h4 className="mt-md-7 mt-0 mb-md-3 mb-0 is-text-darkgray50">請提供以下相關資訊，以加快服務速度</h4>
                  <div className="form-group mb-0">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="hide-required mb-md-6 mb-5"
                          name='url'
                          required={true}
                          label="網頁開啟或下載所使用的URL"
                          validations="isUrl"
                          validationErrors={{ isRequired: '請輸入url' }}
                          placeholder='請輸入URL'
                          value={this.state.form.url.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="radio-with-input">
                          <RadioButtons
                            label='是否出現錯誤代碼'
                            className="hide-required"
                            required
                            name='isErrorCode'
                            options={[
                              { value: '0', label: '否' },
                              { value: '1', label: '是，錯誤代碼為' }
                            ]}
                            selected={'1'}
                            getSelected={e => this.onChange('isErrorCode', e)}
                          />
                          <div className="position-relative">
                            <div className='form-group'>
                              <input
                                // onBlur={this.handleChange}
                                // id={`input-${this.props.name}`}
                                type='text'
                                disabled={this.state.form.isErrorCode.value === '0' ? true : false}
                                placeholder="請輸入錯誤代碼"
                              // ref={this.input}
                              // onChange={this.handleChange}
                              // onKeyUp={this.handleChange}
                              // placeholder={!!this.props.placeholder ? this.props.placeholder : ''}
                              // value={this.props.getValue() || ''}
                              // maxLength={this.state.maxLength}
                              />
                            </div>
                            {/* <LabelInput
                              className={`hide-required ${this.state.form.isErrorCode.value === '0' ? 'disabled' : ''}`}
                              name='enterErrorCode'
                              required={false}
                              // label="網頁開啟或下載所使用的URL"
                              validationErrors={{ isRequired: '請輸入錯誤代碼' }}
                              placeholder='請輸入錯誤代碼'
                              value={this.state.form.enterErrorCode.value}
                              onChange={this.onChange}
                            /> */}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mt-md-0 mt-5 mt-md- mb-6 hide-required w-100"
                          name='questionPhone'
                          required={this.state.form.questionPhone.required}
                          label="第二支聯絡電話"
                          validationErrors={{ isRequired: '請輸入第二支聯絡電話' }}
                          placeholder='請輸入聯絡人姓名'
                          value={this.state.form.questionPhone.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelTextarea
                          name='explain'
                          className='hide-required mb-md-4 mb-0'
                          required={false}
                          value={this.state.form.explain.value}
                          label='補充說明(選填)'
                          placeholder='請輸入意見內容'
                          maxLength={200}
                          onChange={this.onChange}
                        />

                        <hr />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <h4 className="is-text-darkgray50 mb-md-2 mb-0">請詳細填寫以下聯絡資訊，我們將儘快為您服務！</h4>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-5 hide-required w-100"
                          name='questionPhone'
                          required={this.state.form.questionPhone.required}
                          label="聯絡人姓名"
                          validationErrors={{ isRequired: '請輸入聯絡人姓名' }}
                          placeholder='請輸入聯絡人姓名'
                          value={this.state.form.questionPhone.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-5 hide-required w-100"
                          name='questionPhone'
                          required={this.state.form.questionPhone.required}
                          label="聯絡電話"
                          validationErrors={{ isRequired: '請輸入聯絡電話' }}
                          placeholder='請輸入聯絡電話'
                          value={this.state.form.questionPhone.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-5 hide-required w-100"
                          name='email'
                          required={this.state.form.email.required}
                          label="Email"
                          validationErrors={{ isRequired: '請輸入Email' }}
                          placeholder='請輸入Email'
                          value={this.state.form.email.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <RadioGroup
                          validationErrors='請選擇項目'
                          onChange={this.onChange}
                          label='可否電話聯繫'
                          name='questionType01'
                          className="mb-0 hide-required"
                          required={this.state.form.questionType01.required}
                          default={this.state.form.questionType01.value}
                          options={[
                            { value: '0', label: '是' },
                            { value: '1', label: '否' }
                          ]}
                        />
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} className='mt-md-8 mt-3 pb-0'>
                          <div className="captcha">
                            <div className="form-group mb-0">
                              <input id="vertifyNum" placeholder="請輸入驗證碼" className="form-control" />
                              <div className="captcha-box">
                                <div className="captcha-number">
                                  <img src="https://cldup.com/gZ7MdGePW6-3000x3000.png" alt="驗證碼圖片" />
                                </div>
                                <button type="button" className="captcha-reload">
                                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcYiIiIeHh39/f4iIiIeHh4eHh4iIiIiIiIiIiIeHh4eHh4WFhYiIiImJiYiIiOpIBKYAAAAPdFJOUwDfIBCgfT+/75DPYFCvUPAcHYoAAAErSURBVHjatVKxSgNBFHzEeCKxOUHbTSpLt1Hbs7JUEOsNIraxsLALYiHYnHDgD1hoFwt7W2vF2m84uSMxiCNvdzWDKaycZt/Ozt7OvHvyb5i5v8XFS1sYyQkUwzviGq8IKEn7qPsbA6D+4WaB0ZUVOTBAJhEOKHzRMqgitwAsx3IFuAxVh77v8BGKbSzKN5oorfcDtCchDHbC4TtZ7uFMl1W8ETmHT10ekHFgVOGdLncmL3Xpwwph4Ld6RAgXMRSGmyI30jTHabokfL1hoMjE8EMd5Sq2FKXq22lalo41pqZl6bVPWwtLx9Y3fmRZ+iwh55FMoUcN7dIfLmI5X1MPyqBIBhN7zThFSR9kzwE43tvKeZh0MiIKMrAW2SdhtDYBnO/KL6wf7v8x7V/iaqjxLs+LqgAAAABJRU5ErkJggg==" alt="button" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className='input-group mt-3'>
                            <CheckboxCollapse
                              name='agree'
                              required
                              validationErrors={{ isDefaultRequiredValue: '請同意個人資料保護法第8條所為之告知事情' }}
                              collapseContent={`<p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。<br/>
                            使用「全國繳費平台」繳納遠傳電信服務費用注意事項：<br/>
                            1.限以門號申請本人活存帳戶使用此服務。<br/>
                            2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                            3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                            4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                            5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。<br/>
                            本人已詳閱及了解上述注意事項並同意遵守
                            </p>`}
                              label='您填寫以上內容即代表您已知悉遠傳電信依個人資料保護法第八條告知事項。請參閱：個人資料保護法告知內容'
                              getCheckbox={e => this.onChange('agree', e)}
                              checked={false}
                            />
                          </div>

                          <div className="page-action">
                            <button onClick={this.submit} className={`is-large fui-button is-primary m-0`} disabled={!this.state.canSubmit}>
                              <span className='text'>{this.state.isEn ? 'Submit' : '確認送出'}</span>
                            </button>
                          </div>
                        </Grid>

                      </Grid>
                    </Grid>
                  </div>
                </Formsy>
              </div>
            </div>
          </section>
        </div>
        break
      case '1':
        return <div>
          <section className="pt-md-7 pt-4 no-bg">
            <div className="fui-container">
              <div className="no-shadow is-bg-initial">
                <Formsy onValid={() => { this.setState({ emailCanSubmit: true }) }} onInvalid={() => { this.setState({ emailCanSubmit: false }) }} ref={this.ChangeForm}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className='form-group mb-md-4 mb-0'>
                        <label className=''>請選擇問題類別</label>
                        <Select
                          validationErrors={{
                            isDefaultRequiredValue: '請選擇問題類別',
                          }}
                          name='question1'
                          required={this.state.form.question1.required}
                          options={[
                            { text: '查詢訂單', value: '1' },
                            { text: '查詢出貨進度', value: '2' },
                          ]}
                          value={this.state.form.question1.value}
                          label={!!this.state.form.question1.value ? this.state.form.question1.value : '查詢出貨進度'}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className='form-group mb-md-4 mb-0'>
                        <label className=''></label>
                        <Select
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入查詢商品配送速度',
                          }}
                          name='question2'
                          required={this.state.form.question2.required}
                          options={[
                            { text: '查詢訂單編號', value: '1' },
                            { text: '查詢商品配送速度', value: '2' },
                          ]}
                          value={this.state.form.question2.value}
                          label={!!this.state.form.question2.value ? this.state.form.question2.value : '查詢商品配送速度'}
                          onChange={this.onChange}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <hr />
                  <h4 className="is-text-darkgray50 mt-md-7 mt-4">請提供以下相關資訊，以加快服務速度</h4>
                  <LabelTextarea
                    name='comment'
                    className="hide-required"
                    required={this.state.form.comment.required}
                    value={this.state.form.comment.value}
                    validationErrors={{
                      isDefaultRequiredValue: '請輸入狀況描述',
                    }}
                    label='意見內容'
                    placeholder='請輸入意見內容'
                    maxLength={200}
                    onChange={this.onChange}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <LabelInput
                        className="mb-2 hide-required w-100"
                        name='orderNumber'
                        required={this.state.form.orderNumber.required}
                        label="訂單編號"
                        validationErrors={{ isRequired: '請輸入訂單編號' }}
                        placeholder='請輸入訂單編號'
                        value={this.state.form.orderNumber.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <LabelInput
                        className="mb-2 hide-required w-100"
                        name='id_number'
                        required={false}
                        label="身份證字號(選填)"
                        value={this.state.form.id_number.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <LabelInput
                        className="mb-2 hide-required w-100"
                        name='phoneNumber'
                        required={this.state.form.phoneNumber.required}
                        label="收件人聯絡電話"
                        validationErrors={{ isRequired: '請輸入收件人聯絡電話' }}
                        placeholder='請輸入收件人聯絡電話'
                        value={this.state.form.phoneNumber.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <h4 className="is-text-darkgray50 mt-md-7 mt-0 mb-md-4 mb-0">請詳細填寫以下聯絡資訊，我們將儘快為您服務！</h4>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <LabelInput
                        className="mb-md-2 mb-4 hide-required w-100"
                        name='contactName'
                        required={this.state.form.contactName.required}
                        label="聯絡人姓名"
                        validationErrors={{ isRequired: '請輸入聯絡人姓名' }}
                        placeholder='請輸入聯絡人姓名'
                        value={this.state.form.contactName.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <LabelInput
                        className="mb-md-2 mb-4 hide-required w-100"
                        name='contactPhone'
                        required={false}
                        label="聯絡電話"
                        value={this.state.form.contactPhone.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <LabelInput
                        className="mb-md-2 mb-4 hide-required w-100"
                        name='email'
                        required={this.state.form.email.required}
                        label="Email"
                        validationErrors={{ isRequired: '請輸入Email' }}
                        placeholder='請輸入Email'
                        value={this.state.form.email.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} className='mt-md-8 mt-0 pb-0'>
                      <div className="captcha">
                        <div className="form-group mb-0">
                          <input id="vertifyNum" placeholder="請輸入驗證碼" className="form-control" />
                          <div className="captcha-box">
                            <div className="captcha-number">
                              <img src="https://cldup.com/gZ7MdGePW6-3000x3000.png" alt="驗證碼圖片" />
                            </div>
                            <button type="button" className="captcha-reload">
                              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcYiIiIeHh39/f4iIiIeHh4eHh4iIiIiIiIiIiIeHh4eHh4WFhYiIiImJiYiIiOpIBKYAAAAPdFJOUwDfIBCgfT+/75DPYFCvUPAcHYoAAAErSURBVHjatVKxSgNBFHzEeCKxOUHbTSpLt1Hbs7JUEOsNIraxsLALYiHYnHDgD1hoFwt7W2vF2m84uSMxiCNvdzWDKaycZt/Ozt7OvHvyb5i5v8XFS1sYyQkUwzviGq8IKEn7qPsbA6D+4WaB0ZUVOTBAJhEOKHzRMqgitwAsx3IFuAxVh77v8BGKbSzKN5oorfcDtCchDHbC4TtZ7uFMl1W8ETmHT10ekHFgVOGdLncmL3Xpwwph4Ld6RAgXMRSGmyI30jTHabokfL1hoMjE8EMd5Sq2FKXq22lalo41pqZl6bVPWwtLx9Y3fmRZ+iwh55FMoUcN7dIfLmI5X1MPyqBIBhN7zThFSR9kzwE43tvKeZh0MiIKMrAW2SdhtDYBnO/KL6wf7v8x7V/iaqjxLs+LqgAAAABJRU5ErkJggg==" alt="button" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className='input-group'>
                        <CheckboxCollapse
                          name='agree'
                          required
                          validationErrors={{ isDefaultRequiredValue: '請同意個人資料保護法第8條所為之告知事情' }}
                          collapseContent={`<p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。<br/>
                          使用「全國繳費平台」繳納遠傳電信服務費用注意事項：<br/>
                          1.限以門號申請本人活存帳戶使用此服務。<br/>
                          2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                          3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                          4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                          5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。<br/>
                          本人已詳閱及了解上述注意事項並同意遵守
                          </p>`}
                          label='您填寫以上內容即代表您已知悉遠傳電信依個人資料保護法第八條告知事項。請參閱：個人資料保護法告知內容'
                          getCheckbox={e => this.onChange('agree', e)}
                          checked={false}
                        />
                      </div>

                      <div className="page-action">
                        <button onClick={this.submit} className={`is-large fui-button is-primary m-0`} disabled={!this.state.canSubmit}>
                          <span className='text'>{this.state.isEn ? 'Submit' : '確認送出'}</span>
                        </button>
                      </div>
                    </Grid>

                  </Grid>
                </Formsy>
              </div>
            </div>
          </section>
        </div>
        break;
      case '2':
        return <div>
          <section className="pt-md-7 pt-4 no-bg">
            <div className="fui-container">
              <div className="no-shadow is-bg-initial">
                <Formsy onValid={() => { this.setState({ emailCanSubmit: true }) }} onInvalid={() => { this.setState({ emailCanSubmit: false }) }} ref={this.ChangeForm}>
                  <div className="form-group mb-md-4 mb-0">
                    <label>請選擇問題類別</label>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Select
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入查詢商品配送速度',
                          }}
                          name='question3'
                          required={this.state.form.question3.required}
                          label={this.state.form.question3.text}
                          options={[
                            { text: '帳單繳費相關', value: '1' },
                          ]}
                          value={this.state.form.question3.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Select
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入查詢商品配送速度',
                          }}
                          name='question4'
                          required={this.state.form.question4.required}
                          label={this.state.form.question4.text}
                          options={[
                            { text: '帳單內容抵扣問題', value: '1' },
                          ]}
                          value={this.state.form.question4.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <hr />
                  <div className="form-group mb-0 mt-md-7 ">
                    <h4 className="is-text-darkgray50 mb-3">請提供以下相關資訊，以加快服務速度</h4>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-md-2 mb-4 hide-required w-100"
                          name='questionNumber'
                          label="請輸入反應的遠傳門號"
                          required={this.state.form.questionNumber.required}
                          validationErrors={{ isRequired: '請輸入反應的遠傳門號' }}
                          placeholder='請輸入門號'
                          value={this.state.form.questionNumber.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-md-2 mb-0 hide-required w-100"
                          name='dateOfBirth'
                          label="門號登記者生日"
                          required={this.state.form.dateOfBirth.required}
                          validationErrors={{ isRequired: '請輸入門號登記者生日' }}
                          placeholder='請輸入生日'
                          value={this.state.form.dateOfBirth.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <label className="mt-4" htmlFor="">欲查詢的帳單月份</label>
                        <Select
                          name="billMonth"
                          required={true}
                          label={"1月"}
                          value={this.state.form.billMonth.value}
                          options={[
                            { text: '1月', value: '01' },
                            { text: '2月', value: '02' },
                            { text: '3月', value: '03' },
                            { text: '4月', value: '04' },
                            { text: '5月', value: '05' },
                            { text: '6月', value: '06' },
                            { text: '7月', value: '07' },
                            { text: '8月', value: '08' },
                            { text: '9月', value: '09' },
                            { text: '10月', value: '10' },
                            { text: '11月', value: '11' },
                            { text: '12月', value: '12' },
                          ]}
                          onChange={this.onChange} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-2 hide-required w-100 mt-md-7 mt-4"
                          name='receiptTime'
                          required={this.state.form.receiptTime.required}
                          label="收到日期及時間"
                          validationErrors={{ isRequired: '請輸入收到日期及時間' }}
                          placeholder='請輸入收到日期及時間'
                          value={this.state.form.receiptTime.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-2 hide-required w-100 mt-md-7 mt-4"
                          name='receivePhone'
                          required={this.state.form.receivePhone.required}
                          label="收到的簡訊發訊者號碼"
                          validationErrors={{ isRequired: '請輸入收到的簡訊發訊者號碼' }}
                          placeholder='請輸入收到的簡訊發訊者號碼'
                          value={this.state.form.receivePhone.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-2 hide-required w-100 mt-md-7 mt-4"
                          name='activityName'
                          required={this.state.form.activityName.required}
                          label="加值服務項目或活動名稱"
                          validationErrors={{ isRequired: '請輸入加值服務項目或活動名稱' }}
                          placeholder='請輸入加值服務項目或活動名稱'
                          value={this.state.form.activityName.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-2 hide-required w-100 mt-md-5 mt-4"
                          name='fixNumber'
                          required={this.state.form.fixNumber.required}
                          label="維修單號"
                          validationErrors={{ isRequired: '請輸入維修單號' }}
                          placeholder='請輸入維修單號'
                          value={this.state.form.fixNumber.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-2 hide-required w-100 mt-md-5 mt-4"
                          name='otherPhone'
                          required={this.state.form.otherPhone.required}
                          label="您的其他聯絡電話"
                          validationErrors={{ isRequired: '請輸入您的其他聯絡電話' }}
                          placeholder='請輸入您的其他聯絡電話'
                          value={this.state.form.otherPhone.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-2 hide-required w-100 mt-md-5 mt-4"
                          name='phoneBrand'
                          required={this.state.form.phoneBrand.required}
                          label="手機廠牌/型號"
                          validationErrors={{ isRequired: '請輸入手機廠牌/型號' }}
                          placeholder='請輸入手機廠牌/型號'
                          value={this.state.form.phoneBrand.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-2 hide-required w-100 mt-md-5 mt-4"
                          name='simCode'
                          required={this.state.form.simCode.required}
                          label="SIM晶片卡卡號末四碼"
                          validationErrors={{ isRequired: '請輸入SIM晶片卡卡號末四碼' }}
                          placeholder='請輸入SIM晶片卡卡號末四碼'
                          value={this.state.form.simCode.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-2 hide-required w-100 mt-md-5 mt-4"
                          name='showing'
                          required={this.state.form.showing.required}
                          label="手機螢幕鎖出現畫面"
                          validationErrors={{ isRequired: '請輸入手機螢幕鎖出現畫面' }}
                          placeholder='請輸入手機螢幕鎖出現畫面'
                          value={this.state.form.showing.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-2 hide-required w-100 mt-md-5 mt-4"
                          name='billRate'
                          required={this.state.form.billRate.required}
                          label="目前使用費率"
                          validationErrors={{ isRequired: '請輸入目前使用費率' }}
                          placeholder='請輸入目前使用費率'
                          value={this.state.form.billRate.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-2 hide-required w-100 mt-md-5 mt-4"
                          name='roamingCountry'
                          required={this.state.form.roamingCountry.required}
                          label="漫遊國家"
                          validationErrors={{ isRequired: '請輸入漫遊國家' }}
                          placeholder='請輸入漫遊國家'
                          value={this.state.form.roamingCountry.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-2 hide-required w-100 mt-md-5 mt-4"
                          name='questionURL'
                          required={this.state.form.questionURL.required}
                          label="問題頁面或網址"
                          validationErrors={{ isRequired: '請輸入問題頁面或網址' }}
                          placeholder='請輸入問題頁面或網址'
                          value={this.state.form.questionURL.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="mb-md-2 mb-4 hide-required w-100 mt-md-5 mt-4"
                          name='way'
                          required={this.state.form.way.required}
                          label="問題發生的管道"
                          validationErrors={{ isRequired: '請輸入問題發生的管道' }}
                          placeholder='請輸入問題發生的管道'
                          value={this.state.form.way.value}
                          onChange={this.onChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <LabelTextarea
                          name='suggestion'
                          className='hide-required mb-md-4 mb-2'
                          required={this.state.form.suggestion.required}
                          value={this.state.form.suggestion.value}
                          label='建議內容'
                          placeholder='請輸入內容'
                          maxLength={200}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelTextarea
                          name='explaination'
                          className='hide-required mb-md-4 mb-2'
                          required={this.state.form.explaination.required}
                          value={this.state.form.explaination.value}
                          label='收到的來源(簡訊/信函/專員...)或內容'
                          placeholder='請輸入內容'
                          maxLength={200}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelTextarea
                          name='defination'
                          className='hide-required mb-md-4 mb-2'
                          required={this.state.form.defination.required}
                          value={this.state.form.defination.value}
                          label='狀況描述'
                          placeholder='請輸入內容'
                          maxLength={200}
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <RadioGroup
                        label='最近一星期曾聯絡客服中心？'
                        className='mb-md-4 mb-3'
                        name="recentContact"
                        options={[
                          { label: '是', value: 'true' },
                          { label: '否', value: 'false' }
                        ]}
                        tooltip="最近一星期曾聯絡客服中心？"
                        default={this.state.form.recentContact.value}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="form-group mb-3 w-100">
                        <label htmlFor="">
                          注意事項-單純內容
                        </label>
                        <p className="mb-3 mt-1">繳費方式說明可參考此<a href="#" className="info-link">連結說明</a>。</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="form-group w-100 mb-md-4 mb-0">
                        <label htmlFor="">
                          注意事項-列點
                        </label>
                        <ol>
                          <li>若您欲申請遠傳會員，請點選網頁右上【 會員登入│註冊】，依網頁資料輸入即可。</li>
                          <li>登入帳號即為您的行動電話號碼，若忘記密碼，請輸入門號登記者身份證字號〈公司戶請輸入統一編號〉，系統會立即發送一通簡訊至您的手機（請注意英文有大小寫的分別），請重新開啟FET官方網頁登入。</li>
                          <li>若帳號鎖定，僅限本人致電客服中心解鎖。</li>
                          <li>客服專線：手機直撥123 市話撥449-5888／449-5123（市內計費），或提供本人方便聯絡時間及電話。我們的回電時間為09:00-18:00，謝謝您的配合。</li>
                        </ol>
                      </div>
                    </Grid>
                    <hr />
                  </Grid>
                  <hr />
                  <h4 className="is-text-darkgray50 mt-md-7 mt-3 mb-md-3 mb-2">請詳細填寫以下聯絡資訊，我們將儘快為您服務！</h4>
                  <div className="form-group mb-0">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="hide-required"
                          name='contactName'
                          required={true}
                          label="聯絡人姓名"
                          validationErrors={{ isRequired: '請輸入聯絡人姓名' }}
                          placeholder='請輸入聯絡人姓名'
                          value={this.state.form.contactName.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="hide-required"
                          name='contactPhone'
                          required={true}
                          label="聯絡電話"
                          validationErrors={{ isRequired: '請輸入聯絡電話' }}
                          placeholder='請輸入聯絡電話'
                          value={this.state.form.contactPhone.value}
                          onChange={this.onChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <LabelInput
                          className="hide-required"
                          name='email'
                          required={true}
                          validations="isEmail"
                          label="Email"
                          validationErrors={{ isRequired: '請輸入Email' }}
                          placeholder='請輸入Email'
                          value={this.state.form.email.value}
                          onChange={this.onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <RadioGroup
                          validationErrors='希望聯絡方式'
                          onChange={this.onChange}
                          label='希望聯絡方式'
                          name='willingContact'
                          className="m-0"
                          required={this.state.form.willingContact.required}
                          default={this.state.form.willingContact.value}
                          options={[
                            { value: '0', label: '電話' },
                            { value: '1', label: 'Email' },
                            { value: '2', label: '皆可' },
                          ]}
                        />
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} className='mt-md-8 mt-3 pb-0'>
                          <div className="captcha">
                            <div className="form-group mb-3">
                              <input id="vertifyNum" placeholder="請輸入驗證碼" className="form-control" />
                              <div className="captcha-box">
                                <div className="captcha-number">
                                  <img src="https://cldup.com/gZ7MdGePW6-3000x3000.png" alt="驗證碼圖片" />
                                </div>
                                <button type="button" className="captcha-reload">
                                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcYiIiIeHh39/f4iIiIeHh4eHh4iIiIiIiIiIiIeHh4eHh4WFhYiIiImJiYiIiOpIBKYAAAAPdFJOUwDfIBCgfT+/75DPYFCvUPAcHYoAAAErSURBVHjatVKxSgNBFHzEeCKxOUHbTSpLt1Hbs7JUEOsNIraxsLALYiHYnHDgD1hoFwt7W2vF2m84uSMxiCNvdzWDKaycZt/Ozt7OvHvyb5i5v8XFS1sYyQkUwzviGq8IKEn7qPsbA6D+4WaB0ZUVOTBAJhEOKHzRMqgitwAsx3IFuAxVh77v8BGKbSzKN5oorfcDtCchDHbC4TtZ7uFMl1W8ETmHT10ekHFgVOGdLncmL3Xpwwph4Ld6RAgXMRSGmyI30jTHabokfL1hoMjE8EMd5Sq2FKXq22lalo41pqZl6bVPWwtLx9Y3fmRZ+iwh55FMoUcN7dIfLmI5X1MPyqBIBhN7zThFSR9kzwE43tvKeZh0MiIKMrAW2SdhtDYBnO/KL6wf7v8x7V/iaqjxLs+LqgAAAABJRU5ErkJggg==" alt="button" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className='input-group'>
                            <CheckboxCollapse
                              name='agree'
                              required
                              validationErrors={{ isDefaultRequiredValue: '請同意個人資料保護法第8條所為之告知事情' }}
                              collapseContent={`<p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。<br/>
                            使用「全國繳費平台」繳納遠傳電信服務費用注意事項：<br/>
                            1.限以門號申請本人活存帳戶使用此服務。<br/>
                            2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                            3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                            4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                            5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。<br/>
                            本人已詳閱及了解上述注意事項並同意遵守
                            </p>`}
                              label='您填寫以上內容即代表您已知悉遠傳電信依個人資料保護法第八條告知事項。請參閱：個人資料保護法告知內容'
                              getCheckbox={e => this.onChange('agree', e)}
                              checked={false}
                            />
                          </div>

                          <div className="page-action">
                            <button onClick={this.submit} className={`is-large fui-button is-primary m-0`} disabled={!this.state.canSubmit}>
                              <span className='text'>{this.state.isEn ? 'Submit' : '確認送出'}</span>
                            </button>
                          </div>
                        </Grid>

                      </Grid>
                    </Grid>
                  </div>
                </Formsy>
              </div>
            </div>
          </section>
        </div>
        break;


      default:
        break;
    }
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title={this.getBannerTitle()}
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        {this.state.step == 1 ? <section className="pt-md-7 pb-md-7 pb-6 pt-4 no-bg">
          <div className="fui-container">
            <div className="fui-segments no-shadow is-bg-initial">
              <div className="paper mt-0 mb-0 pb-md-7 pb-2 pt-md-7 pt-2">
                <Formsy onValid={() => { this.setState({ emailCanSubmit: true }) }} onInvalid={() => { this.setState({ emailCanSubmit: false }) }} ref={this.ChangeForm}>
                  <h5 className='align-left mt-2 mb-2'>客服信箱說明</h5>
                  <ul className="green-dot-list">
                    <li>進一步瞭解遠傳的產品與服務，或對遠傳有任何問題與意見，都歡迎您與我們聯絡，我們將竭誠為您服務！</li>
                    <li>服務過程中若涉及個人資料安全與權益，客服人員將回電與您連繫，期待完整解決您的問題及建議，若有叨擾之處，懇請包涵！</li>
                    <li>為讓服務更即時有效率，歡迎您多加利用常見問題！</li>
                  </ul>
                  <RadioGroup
                    validationErrors='請選擇項目'
                    onChange={this.onChange}
                    label='請選擇問題類型'
                    name='questionType'
                    className="mb-0 mt-md-9"
                    required={this.state.form.questionType.required}
                    default={this.state.form.questionType.value}
                    options={[
                      { value: '0', label: '電信門號相關問題' },
                      { value: '1', label: '網路購物相關問題' },
                      { value: '2', label: '上網/通話訊號相關問題' }
                    ]}
                  />
                </Formsy>
              </div>
            </div>
            <div className="page-action mt-md-7">
              <Button size="large" onClick={() => { this.setState({ step: 2 }) }} link="#" className={`mb-lg-0 mb-2 ${this.state.emailCanSubmit ? '' : 'disabled'}`} btnStyle='primary'>下一步</Button>
            </div>
          </div>
        </section> : null}
        {this.state.step == 2 ?
          this.getQuestionType()
          : null}
      </main >
    )
  }
}

export default Mailbox;