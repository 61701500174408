import React from 'react';

import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import NavTab from '../../components/partials/NavTab';
import SectionAd3 from '../../components/partials/SectionAd3';
import Card from '../../components/card/Card';
import FormBanner from '../../components/partials/banner/FormBanner';
import ESectionContent from '../../components/partials/ESectionContent';
import Panel from '../../components/panel/Panel';
import PanelTitle1 from '../../components/panelContent/PanelTitle1';
import HelpCenterBanner from '../../components/partials/banner/HelpCenterBanner';

import * as Mock from '../../mock/HelpCenterMock';
import AppPromotion from '../../components/partials/AppPromotion';

class HelpCenter extends React.Component {
    constructor(props) {
        super(props);
        const query = new URLSearchParams(this.props.location.search);

        this.state = {
            currentTab: query.get('tab') === 'ebu' ? 1 : query.get('tab') === 'contact' ? 2 : 1,
            isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
        };
    }

    tabChange = index => {
        this.setState({
            currentTab: index
        })
        switch (index) {
            case 0:
                this.props.history.push('/help-center?tab=cbu');
                break;
            case 1:
                this.props.history.push('/help-center?tab=ebu');
                break;
            case 2:
                this.props.history.push('/help-center?tab=contact');
                break;
        }
    };

    render() {
        const { currentTab } = this.state;
        return (
            <main className="help-center">
                <NavTab
                    pageTitle='幫助中心'
                    onChange={this.tabChange}
                    default={2}
                    tabs={{
                        name: 'help-center-tab',
                        list: [
                            { name: 'tab-1', label: '個人用戶', link: '/help-center' },
                            { name: 'tab-2', label: '商務用戶', link: 'http://fetnet-demo.aja.com.tw/help-center' },
                            { name: 'tab-3', label: '聯絡我們', link: '#' },
                            { name: 'tab-4', label: '服務條款', link: '/help-center/terms-of-service/privacy' },
                        ],
                    }}
                    button={{
                        text: '回首頁',
                        link: '/help-center',
                    }}
                />

                <FormBanner
                    title='聯絡我們'
                    className="is-helpcenter"
                    image={{
                        sm: '/resources/cbu/help-center/images/ebu-survey-banner-sm.jpg',
                        md: '/resources/cbu/help-center/images/ebu-survey-banner.jpg',
                    }}
                />

                <ESectionContent title='需要更即時的幫助?'>
                    <h4 className='is-text-darkgray50'>遠傳24小時客服隨時提供協助</h4>
                    <Panel>
                        <PanelTitle1 title='行動通信客服' />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <h4>月租型用戶</h4>
                                <div className='fui-list contact-with-dot'>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            手機直撥
                        <Link to='tel:888' target='_blank'>
                                                888
                        </Link>
                        或
                        <Link to='tel:123' target='_blank'>
                                                123
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            MVPN用戶直撥
                        <Link to='tel:99888' target='_blank'>
                                                99888
                        </Link>
                        或
                        <Link to='tel:123' target='_blank'>
                                                123
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            市話手機直撥
                        <Link to='tel:0800058885' target='_blank'>
                                                0800-058-885
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            國外手機直撥
                        <Link to='tel:+886936010888' target='_blank'>
                                                +886-936-010-888
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            付費服務專線
                                            <Link to='tel:024495888' target='_blank'>
                                                02-449-5888
                                            </Link>
                                            (市話請直撥不加區碼，手機撥打請加 02)
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <h4>預付型用戶</h4>
                                <div className='fui-list contact-with-dot'>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            手機直撥
                        <Link to='tel:777' target='_blank'>
                                                777
                        </Link>
                        或
                        <Link to='tel:123' target='_blank'>
                                                123
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            付費服務專線
                        <Link to='tel:0936000777' target='_blank'>
                                                0936-000-777
                        </Link>
                        (預付型用戶免費)
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <hr />
                        <PanelTitle1 title='寬頻客服' />
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={4}>
                                <h4>新世紀資通用戶/Seednet 企業用戶</h4>
                                <div className='fui-list contact-with-dot'>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            市話手機直撥
                        <Link to='tel:0809080080' target='_blank'>
                                                0809-080-080
                        </Link>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <h4>Seednet 個人用戶</h4>
                                <div className='fui-list contact-with-dot'>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            付費服務專線
                        <Link to='tel:024495000' target='_blank'>
                                                02-449-5000
                        </Link>
                        (市話請直撥不加區碼，手機撥打請加 02)
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <hr />
                        <PanelTitle1 title='網路購物客服' />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={5}>
                                <h4>遠傳網路門市購物用戶</h4>
                                <div className='fui-list contact-with-dot'>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            付費服務專線
                        <Link to='tel:0277151688' target='_blank'>
                                                02-7715-1688
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            聯絡信箱
                        <Link to='mailto:estore@fareastone.com.tw' target='_blank'>
                                                estore@fareastone.com.tw
                        </Link>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <hr />
                        <PanelTitle1 title='傳真繳費收據聯絡信箱' />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='fui-list contact-with-dot'>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            聯絡信箱
                        <Link to='mailto:ebu-ccsqm@fareastone.com.tw' target='_blank'>
                                                ebu-ccsqm@fareastone.com.tw
                        </Link>
                        (服務時間 週一至週五 09:00-17:00,不含國定假日)
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <hr />
                        <PanelTitle1 title='客訴作業流程' />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={7}>
                                <h4>申訴管道</h4>
                                <div className='fui-list contact-with-dot'>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            手機直撥
                        <Link to='tel:123' target='_blank'>
                                                123
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            付費服務專線
                        <Link to='tel:+886277465214' target='_blank'>
                                                02-449-5123
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            信函寄至
                        <Link to='mailto:EBU-Info@fareastone.com.tw' target='_blank'>
                                                遠傳電信 客戶關係維繫組
                        </Link>
                        公司地址：
                        <Link to='#' target='_blank'>
                                                114 台北市內湖區瑞光路468號
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            電子信件
                        <Link to='mailto:EBU-Info@fareastone.com.tw' target='_blank'>
                                                客服信箱
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            行動客服APP
                        <Link to='＃' target='_blank'>
                                                立即下載
                        </Link>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <h4>申訴處理流程</h4>
                                <ul className="blue-dot-list with-circle">
                                    <li>
                                        <div className="circle">1</div>
                                                受理
                                            </li>
                                    <li>
                                        <div className="circle">2</div>
                                                三個工作天內完成初步回覆
                                            </li>
                                    <li>
                                        <div className="circle">3</div>
                                    結案
                                    </li>
                                </ul>

                            </Grid>
                        </Grid>
                        <hr />
                        <PanelTitle1 title='企業客服' />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={5}>
                                <h4>遠傳企業用戶</h4>
                                <div className='fui-list contact-with-dot'>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            付費市話專線
                        <Link to='tel:024499365' target='_blank'>
                                                02-4499-365
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            付費國外直撥
                        <Link to='tel:+886277465214' target='_blank'>
                                                886-2-7746-5214
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            傳真
                        <Link to='tel:0277151663' target='_blank'>
                                                02-7715-1663
                        </Link>
                                        </div>
                                    </div>
                                    <div className='fui-item'>
                                        <div className='prefix'>
                                            <div className='bulleted-dot'></div>
                                        </div>
                                        <div className='content'>
                                            客服信箱
                        <Link to='mailto:EBU-Info@fareastone.com.tw' target='_blank'>
                                                EBU-Info@fareastone.com.tw
                        </Link>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Panel>
                </ESectionContent>
                <AppPromotion
                    bgImage='/resources/cbu/help-center/images/bg-all.png'
                    title="遠傳行動客服 APP"
                    subtitle="貼心服務不用等"
                    description={`
            <p>
              以遠傳門號快速登入, 上網流量即時看，查帳單、繳帳單，一鍵完成！
              <br />
              還有許多用戶獨享驚喜優惠
            </p>`
                    }
                    icon='/resources/cbu/help-center/images/img-logo-fet.png'
                    android={{
                        qrCode: '/resources/cbu/help-center/images/qr-android.jpg',
                        icon: '/resources/cbu/help-center/images/google-play.png',
                        link: '/',
                        title: '支援 Android 7 以上版本'
                    }}
                    ios={{
                        qrCode: '/resources/cbu/help-center/images/qr-android.jpg',
                        icon: '/resources/cbu/help-center/images/google-play.png',
                        link: '/',
                        title: '支援 iOS 10 以上版本'
                    }}
                />
            </main>
        );
    }
}

export default HelpCenter;
