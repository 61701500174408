import React from 'react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import PanelList from '../../components/panelContent/PanelList';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import * as Validation from '../../utils/validation';
class ResumeCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: 'id',
      step: 1,
      step2Validation: false,
      ismail: [
        {
          value: '0',
          label: '是',
        },
        {
          value: '1',
          label: '否',
        },
      ],
      form: {
        setting: { value: 'false', required: false },
        status: { value: 'true', required: false },
        id_number: { value: '', required: false },
      },
      canSubmit: false
    }
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm,
    });
  };
  defaultFormOnChange = (name, value) => {
    let result = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    console.log(result);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = result;
    this.setState({
      form: newForm,
    });
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle="漫遊與門號服務"
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="恢復通話"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="form-section">
          <div className="fui-container">
            <h4 className='is-text-darkgray50'>此服務提供您於網站上申請恢復您的通話。</h4>
            <div className="paper mb-7">
              <h4 className='my-2'>恢復通話通知</h4>
              <PanelList
                prefix='bulleted'
                list={[
                  { text: '此門號因帳款預期無法外撥，申請恢復通話請輸入證號，復話後，請於三天內繳費，以免外撥電話功能再次限制。' }
                ]}
              />
            </div>

            {!this.state.step2Validation ? <div className='page-action'><Button
              onClick={() => this.setState({ step2Validation: true })}
              link="#"
              className='mb-lg-0 mb-md-2 mb-0'
              btnStyle='primary'
              size='large'>
              恢復通話
            </Button></div> : null}

            {this.state.step2Validation ? <div className="mt-7">
              <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <h4 className='mb-3'>為保護您個人資料的安全，請輸入門號登記者的證照號碼進行確認</h4>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LabelInput
                      className="mb-2"
                      name='id_number'
                      required={this.state.form.id_number.required}
                      label='證照號碼'
                      validations={{
                        idNumberValid: Validation.idNumberValid
                      }}
                      validationErrors={{
                        isDefaultRequiredValue: '請輸入證照號碼'
                      }}
                      placeholder='請輸入證照號碼'
                      value={this.state.form.id_number.value}
                      onChange={this.onChange}
                    />
                    <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                      身分說明：<br />
                  個人戶：身分證字號<br />
                  公司戶：統一編號或稅籍編號<br />
                  外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                </p>
                    <div className='page-action'>
                      <Button
                        onClick={() => { console.log('submit') }}
                        className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`}
                        btnStyle='primary'
                        size='large'>
                        確認
                  </Button>
                    </div>
                  </Grid>
                </Grid>
              </Formsy>
            </div> : null}
          </div>
        </section>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            1. 若使用原SM卡恢復通話,請於「新SIM卡號碼」處填入門號原SIM卡號碼即可。<br/>
            2. 透過ATM轉帳或攜帶帳單至超商、郵局繳費後,若需快速復話,可將繳費收據Email至ebu-ccsqm@fareastone.com.w。主旨註明“收據復話”,將於收到您寄發MAlL1小時內復話,18:00後寄送將於隔日11點前復話
          `}
        />
      </main>
    )
  }
}

export default ResumeCall;