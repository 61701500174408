import React from 'react';
import Item from '../item/Item';

import PropTypes from 'prop-types';

class ESectionContent extends React.Component {
  render() {
    return (
      <section className={`fui-content bg-arrow`} id={this.props.id} class='sitemap'>
        <div className='fui-container'>
          <h2>{this.props.title}</h2>
          {this.props.content ? (
            <div
              className='subtitle-2 mb-3 is-text-darkgray50'
              dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
          ) : (
            ''
          )}

          {this.props.checklist ? (
            <div className='fui-inline-items'>
              {this.props.checklist.map((item, i) => (
                <Item key={`content-list-${i}`} {...item} prefix={'check'}>
                  {item.text}
                </Item>
              ))}
            </div>
          ) : (
            ''
          )}

          {this.props.children}
        </div>
      </section>
    );
  }
}

ESectionContent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  checklist: PropTypes.arrayOf(PropTypes.objectOf(Item)),
  children: PropTypes.any,
};

export default ESectionContent;
