import React from 'react';
import Slider from 'react-slick';
import Button from '../../Button';

import PropTypes from 'prop-types';

class EStoreBanner extends React.Component {
  constructor (props) {
    super(props);

    this.slideSetting = {
      dots: true,
      infinite: false,
      arrows: false,
      slidesToShow: 1,
      draggable: true,
      adaptiveHeight: true,
    };
  }
  render () {
    return (
      <div className="fui-banner is-estore">
        <Slider {...this.slideSetting}>
        {
          this.props.slides.map((slide, i) => (
            <div className="estore-banner-item" key={`estore-banner-${i}`}>
              <div className='bg'>
                <img src={slide.image.md} alt={slide.title} className='d-none d-sm-block' />
                <img src={slide.image.sm} alt={slide.title} className='d-block d-sm-none' />
              </div>
              <div className='caption'>
                <div className='fui-container'>
                  <div className='tag'>
                    <div className='tag-bg'></div>
                    {slide.tag}
                  </div>
                  <h1>{slide.title}</h1>
                  <h5>{slide.description}</h5>
                  <div className='action'>
                    {
                      slide.actions.map((act, j) => (
                        <Button link={act.link} target={act.target} btnStyle={act.btnStyle} key={`estore-banner-${i}-btn-${j}`} size='large'>
                          {act.text}
                        </Button>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        </Slider>
      </div>
    )
  }
}

EStoreBanner.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      tag: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          link: PropTypes.string,
          target: PropTypes.string,
        })
      )
    })
  )
}

export default EStoreBanner;