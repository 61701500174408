import React from 'react';
import Button from '../Button';
import Link from '../Link';
import PropTypes from 'prop-types';
import { adClickTracking } from '../../utils/ad-tracking';

const Card = (props) => {
  const [isEn, setIsEn] = React.useState(false);

  React.useEffect(() => {
    setIsEn(typeof window !== 'undefined' && window.$isEn);
  }, []);

  return (
    <div className='fui-card is-product'>
      {props.link ? (
        <Link
          className='fui-card-action'
          to={props.link}
          data-aa-tracking-id={`${props.title}`}
          onClick={(event) => {
            props.adData && adClickTracking('contentAD', props.adData);
          }}>
          <div className={`tag tag-${props.tag.color}`}>{props.tag.text}</div>
          <div className='fui-card-image'>
            <img src={props.image} alt='' />
          </div>
          <div className='fui-card-caption'>
            <div className='fui-card-content'>
              <h5 className='fui-card-title'>
                <div className='text'>{props.title}</div>
              </h5>
              <div className='list'>
                {props.lists.map((list, i) => (
                  <div>
                    <img src='/resources/cbu/life-circle-slashie/images/check.svg' />
                    <span className='is-text-darkgray50'>{list.text}</span>
                  </div>
                ))}
              </div>
              {props.action ? (
                <div className='fui-card-extra'>
                  <Button btnStyle='primary' className='' link={props.action.link}>
                    {props.action.text}
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </Link>
      ) : (
        <div className='fui-card-action'>
          <div className={`tag tag-${props.tag.color}`}>{props.tag.text}</div>
          <div className='fui-card-image'>
            <img src={props.image} alt='' />
          </div>
          <div className='fui-card-caption'>
            <div className='fui-card-content'>
              <h5 className='fui-card-title'>
                <div className='text'>{props.title}</div>
              </h5>
              <div className='list'>
                {props.lists.map((list, i) => (
                  <div>
                    <img src='/resources/cbu/life-circle-slashie/images/check.svg' />
                    <span className='is-text-darkgray50'>{list.text}</span>
                  </div>
                ))}
              </div>
              {props.action ? (
                <div className='fui-card-extra'>
                  <Button btnStyle='primary' className='' link={props.action.link}>
                    {props.action.text}
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.shape({
    color: PropTypes.string,
    text: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  lists: PropTypes.shape({
    text: PropTypes.string,
  }),
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
};

export default Card;
