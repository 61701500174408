import React from 'react';
import { Grid } from '@material-ui/core';
import Modal from 'react-modal';
import Link from '../../components/Link';
import Formsy from 'formsy-react';
import RadioGroup from '../../components/form/RadioGroup';
import NavAnchor from '../../components/partials/NavAnchor';
import LabelInput from '../../components/form/LabelInput';

import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import Select from '../../components/form/Select';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import numberFormatter from '../../utils/numberFormatter';

class prepaidRoamingPayInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      modalOpen: false,
      isFail: false,
      form: {
        id: { value: '', required: true },
      }
    }
  }

  componentDidMount() {
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  submit = (val) => {
    this.setState({
      modalOpen: true,
      isFail: val
    })
    // this.props.history.push('/prepaidRoamingBalanceController/prepaidRoamingPayInfo');
  };

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />

        <FormBanner
          title="預付卡漫遊上網加購"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="prepaid-roaming">
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
            <div className="fui-container">
              <h4 className="is-text-darkgray70 my-0">請確認購買內容，並填寫付款資訊</h4>
              <div className='mb-5'>
                <h4 className="is-text-darkgray50 mt-5 mb-2">
                  購買內容
                </h4>
                <div className="fui-table-response">
                  <table>
                    <thead>
                      <tr>
                        <th>購買門號</th>
                        <th>方案名稱</th>
                        <th>金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0926-899-868</td>
                        <td>超4代易付180MB_60天_$180(1.2GB組合)</td>
                        <td>$180.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr className='my-5' />
                <h4 className="is-text-darkgray50 mb-2">
                  付款資料
                </h4>

                <Grid container>
                  <Grid item xs={12} md={12}>
                    <div className="fui-table-response mb-2">
                      <table>
                        <thead>
                          <tr>
                            <th>付款方式</th>
                            <th>餘額</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>餘額扣抵</td>
                            <td>$209.11</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <small className="form-note is-text-gray100">※提醒您，加購金額將由此門號的可用餘額中扣除。</small>
                  </Grid>
                </Grid>
                <Grid container className="with-note mt-5 pt-4">
                  <Grid item xs={12} md={12} >
                    <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LabelInput
                      label="證照號碼"
                      name="id"
                      placeholder="請輸入證照號碼"
                      value={this.state.form.id.value}
                      validationErrors={{
                        isDefaultRequiredValue: '請輸入證照號碼'
                      }}
                      onChange={this.onChange}
                      required={true}
                    />
                    <div className="text is-text-darkgray50 mt-2">
                      身分說明：<br />
                      個人戶：身分證字號<br />
                      公司戶：統一編號或稅籍編號<br />
                      外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，<br />
                      2019/01/01申辦-護照號碼
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="page-action">
                <Button btnStyle="primary" size="large" disabled={!this.state.canSubmit} onClick={() => this.submit(false)}>確認</Button>
                <Button btnStyle="primary" size="large" disabled={!this.state.canSubmit} onClick={() => this.submit(true)}>顯示失敗彈窗</Button>
              </div>
            </div>
          </Formsy>
          <Modal
            onAfterOpen={e => document.getElementsByTagName('html')[0].classList.add('modal-open')}
            onAfterClose={e => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
            isOpen={this.state.modalOpen}
            contentLabel="Example Modal"
            className="fui-prompt-modal">
            <div className="fui-prompt-modal-body">
              {this.state.isFail ? <div><h5>申請失敗</h5>
                <img className='success' src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/failed.png'} alt='' />
                <p className='mt-0 mb-4'>出現問題，請稍後再試</p></div> : <div><h5>申請成功</h5>
                  <img className='success' src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/completed.png'} alt='' />
                  <p className='mt-0 mb-4'>您的紙本發票已申請成功，將盡快寄送給您</p></div>}

              <div className="fui-buttons">
                <Button btnStyle='primary' onClick={() => this.setState({ modalOpen: false })}>確定</Button>
              </div>
            </div>
          </Modal>
        </section>
      </main >
    )
  }
}

export default prepaidRoamingPayInfo;