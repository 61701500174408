import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';

class SectionAd5 extends React.Component {
  render() {
    return (
      <div className='section-promotion-5' id={this.props.id}>
        <Link
          to={this.props.action.link}
          target={this.props.action.target}
          data-aa-tracking-id={`${this.props.title}`}
          onClick={(event) => {
            // [AA Tracking]
            //_alTrackClick
            this.props.onClick && this.props.onClick(event);
          }}>
          <div className='desktop' style={{ backgroundImage: `url(${this.props.image.md})` }}>
            <h3>{this.props.title}</h3>
            <h6>{this.props.content}</h6>
            {this.props.action.text && (
              <div className='promotion-button'>
                <span
                  className='text'
                  dangerouslySetInnerHTML={{
                    __html: this.props.action.text || '',
                  }}
                />
              </div>
            )}
          </div>
          <div className='mobile' style={{ backgroundImage: `url(${this.props.image.sm})` }}>
            <div className="d-flex left">
              <h3>{this.props.title}</h3>
              {this.props.content ? <h6>{this.props.content}</h6> : null}
            </div>
            <div className="d-flex right">
              {this.props.action.text && (
                <div className='promotion-button'>
                  <span
                    className='text'
                    dangerouslySetInnerHTML={{
                      __html: this.props.action.text || '',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

SectionAd5.propTypes = {
  image: PropTypes.shape({
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  title: PropTypes.string,
  content: PropTypes.string,
  action: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
};

export default SectionAd5;
