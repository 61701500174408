import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '../components/Button';
import Formsy from 'formsy-react';
import Checkbox from '../components/form/Checkbox';
import PropTypes from 'prop-types';
let frcForm = null;

class CheckModal extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
    this.myform = this.refs.myform;
    this.state = {
      open: false,
      content: '',
      value: false,
      form: {
        dayTime: {
          name: 'dayTime',
          label: '早鳥門市 ( 7:00 開始營業 )',
          value: false,
          required: false
        },
        nightTime: {
          name: 'nightTime',
          label: '夜貓門市 ( 營業至 00:00 )',
          value: false,
          required: false
        },
        fiveG: {
          name: 'fiveG',
          label: '5G體驗',
          value: false,
          required: false
        },
        special: {
          name: 'special',
          label: '特殊店點',
          value: false,
          required: false
        },
        diy: {
          name: 'diy',
          label: '自助服務牆',
          value: false,
          required: false
        },
        experience: {
          name: 'experience',
          label: '互動體驗區',
          value: false,
          required: false
        },
        coffee: {
          name: 'coffee',
          label: '咖啡機',
          value: false,
          required: false
        },
        borrow: {
          name: 'borrow',
          label: '行動電源/手機租借(預約制)',
          value: false,
          required: false
        },
        disabled: {
          name: 'disabled',
          label: '移動式坡道',
          value: false,
          required: false
        },
        serviceBell: {
          name: 'serviceBell',
          label: '服務鈴',
          value: false,
          required: false
        },
      }
    };
  }
  onChange = (target) => {
    let form = Object.assign(this.state.form);
    // console.log(`form`, form[target]);
    form[target].value = !form[target].value;
    this.setState({ form: form });
    this.forceUpdate();
  }
  resetForm = () => {
    this.setState({
      form: {
        dayTime: {
          name: 'dayTime',
          label: '早鳥門市 ( 7:00 開始營業 )',
          value: false,
          required: false
        },
        nightTime: {
          name: 'nightTime',
          label: '夜貓門市 ( 營業至 00:00 )',
          value: false,
          required: false
        },
        fiveG: {
          name: 'fiveG',
          label: '5G體驗',
          value: false,
          required: false
        },
        special: {
          name: 'special',
          label: '特殊店點',
          value: false,
          required: false
        },
        diy: {
          name: 'diy',
          label: '自助服務牆',
          value: false,
          required: false
        },
        experience: {
          name: 'experience',
          label: '互動體驗區',
          value: false,
          required: false
        },
        coffee: {
          name: 'coffee',
          label: '咖啡機',
          value: false,
          required: false
        },
        borrow: {
          name: 'borrow',
          label: '行動電源/手機租借(預約制)',
          value: false,
          required: false
        },
        disabled: {
          name: 'disabled',
          label: '移動式坡道',
          value: false,
          required: false
        },
        serviceBell: {
          name: 'serviceBell',
          label: '服務鈴',
          value: false,
          required: false
        },
      }
    });
    this.forceUpdate();
  }
  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      let $htmlTag = document.getElementsByTagName('html')[0];
      this.setState({
        open: this.props.open,
      });

      if (this.props.open) {
        $htmlTag.classList.add('modal-open');
        this.modal.current.classList.add('is-open');
      } else {
        this.modal.current.classList.add('is-closing');
        setTimeout(() => {
          this.modal.current.className = 'fui-modal';
          $htmlTag.classList.remove('modal-open');
        }, 500);
      }
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  submit = () => {
    this.props.submit(this.state.form);
  }

  render() {
    return (
      <div ref={this.modal} className={`fui-modal`}>
        <div className='fui-modal-content'>
          <div className='fui-modal-body'>
            <div className='check-modal'>
              <div className="d-flex flex-align-center justify-between">
                <h5 className="m-0">進階搜尋</h5>
                <div className="clean" onClick={() => { this.resetForm() }}>清除全部</div>
              </div>
              <Grid container className='list-container' spacing={2}>
                <Grid item xs={12} sm={6} md={12}>
                  <h6 className="">服務時間</h6>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <label className="checkbox">
                    <input type="checkbox" onChange={() => this.onChange('dayTime')} checked={this.state.form.dayTime.value} value={this.state.form.dayTime.value} />
                    <div className="content">{this.state.form.dayTime.label}</div>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <label className="checkbox">
                    <input type="checkbox" onChange={() => this.onChange('nightTime')} checked={this.state.form.nightTime.value} value={this.state.form.nightTime.value} />
                    <div className="content">{this.state.form.nightTime.label}</div>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <h6 className="">特殊服務/店點</h6>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <label className="checkbox">
                    <input type="checkbox" onChange={() => this.onChange('fiveG')} checked={this.state.form.fiveG.value} value={this.state.form.fiveG.value} />
                    <div className="content">{this.state.form.fiveG.label}</div>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <label className="checkbox">
                    <input type="checkbox" onChange={() => this.onChange('special')} checked={this.state.form.special.value} value={this.state.form.special.value} />
                    <div className="content">{this.state.form.special.label}</div>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <label className="checkbox">
                    <input type="checkbox" onChange={() => this.onChange('diy')} checked={this.state.form.diy.value} value={this.state.form.diy.value} />
                    <div className="content">{this.state.form.diy.label}</div>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <label className="checkbox">
                    <input type="checkbox" onChange={() => this.onChange('experience')} checked={this.state.form.experience.value} value={this.state.form.experience.value} />
                    <div className="content">{this.state.form.experience.label}</div>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <label className="checkbox">
                    <input type="checkbox" onChange={() => this.onChange('coffee')} checked={this.state.form.coffee.value} value={this.state.form.coffee.value} />
                    <div className="content">{this.state.form.coffee.label}</div>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <label className="checkbox">
                    <input type="checkbox" onChange={() => this.onChange('borrow')} checked={this.state.form.borrow.value} value={this.state.form.borrow.value} />
                    <div className="content">{this.state.form.borrow.label}</div>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <h6 className="">無障礙設施</h6>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <label className="checkbox">
                    <input type="checkbox" onChange={() => this.onChange('disabled')} checked={this.state.form.disabled.value} value={this.state.form.disabled.value} />
                    <div className="content">{this.state.form.disabled.label}</div>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <label className="checkbox">
                    <input type="checkbox" onChange={() => this.onChange('serviceBell')} checked={this.state.form.serviceBell.value} value={this.state.form.serviceBell.value} />
                    <div className="content">{this.state.form.serviceBell.label}</div>
                  </label>
                </Grid>
              </Grid>
              <div className="d-flex justify-center pt-5">
                <a onClick={this.submit} className='fui-button is-primary'>
                  <span className='text'>確認搜尋</span>
                </a>
                <a onClick={this.closeModal} className='fui-button is-secondary mr-0'>
                  <span className='text'>關閉</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CheckModal.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.object,
  onClose: PropTypes.func,
};
export default CheckModal;
