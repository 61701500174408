import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';

import Button from '../../components/Button';
import Link from '../../components/Link';
import FormBanner from '../../components/partials/banner/FormBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import LabelInput from '../../components/form/LabelInput';
import TextInput from '../../components/form/TextInput';
import RadioGroup from '../../components/form/RadioGroup';
import Select from '../../components/form/Select';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import numberFormatter from '../../utils/numberFormatter';
import { setInputFilter } from '../../utils/inputFilter';
import Tooltip from '../../components/Tooltip';

import * as PrepaidService from '../../service/PrepaidService'

class prepaidDepositPage extends React.Component {
  constructor(props) {
    super(props);

    this.validCode = React.createRef();
    this.creditGroup = React.createRef();
    this.bankAccountInput = React.createRef();
    this.msisdnInput = React.createRef();
    this.phoneNumberInput = React.createRef();
    this.identityInput = React.createRef();
    this.taxNumberInput = React.createRef();
    this.state = {
      step: 1,
      info: {},
      amountList: [],
      canSubmit1: false,
      canSubmit2: false,
      form_1: {
        cardpwd: { value: '', required: true },
        msisdn: { value: '', required: true },
        prepaid_option: { value: '100', required: true },
        payment: { value: '1', required: true }
      },
      form_2: {
        bank_code: { value: '', required: true },
        bank_account: { value: '', required: true },
        receipt: { value: '1', required: true },
        receipt_extra_2: { value: '', required: true },
        receipt_extra_3: { value: '', required: true },
        receipt_format: { value: '2', require: false },
        tax_id_numbers: { value: '', require: false },
        receipt_title: { value: '', require: false },
        receipt_send_way: { value: '1', require: false },
        receipt_send_code: { value: '', require: false },
        receipt_send_phone: { value: '', require: false },
        receipt_send_city: { value: '', require: false },
        receipt_send_area: { value: '', require: false },
        receipt_send_address: { value: '', require: false },
        receipt_send_receiver: { value: '', require: false },
        receipt_send_email: { value: '', require: false },
        agree: { value: false, required: true }
      }
    }

    PrepaidService.rechargeOption().then(data => {
      this.setState({
        info: data,
        amountList: data.amountList
      })
    })
  }

  componentDidMount() {
    setInputFilter(this.msisdnInput.current.getElementsByTagName('input')[0], function (value) {
      return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
    });
  }

  onChange1 = (name, value) => {
    let newForm = Object.assign(this.state.form_1);
    newForm[name].value = value;

    switch (newForm.payment.value) {
      case "1":
        this.setState({
          form_2: {
            bank_code: { value: '', required: true },
            bank_account: { value: '', required: true },
            receipt: { value: '1', required: true },
            receipt_extra_2: { value: '', required: true },
            receipt_extra_3: { value: '', required: true },
            receipt_format: { value: '2', require: false },
            receipt_send_way: { value: '1', require: false },
            tax_id_numbers: { value: '', require: false },
            receipt_title: { value: '', require: false },
            receipt_send_code: { value: '', require: false },
            receipt_send_phone: { value: '', require: false },
            receipt_send_city: { value: '', require: false },
            receipt_send_area: { value: '', require: false },
            receipt_send_address: { value: '', require: false },
            receipt_send_receiver: { value: '', require: false },
            receipt_send_email: { value: '', require: false },
            agree: { value: false, required: true }
          }
        })
        break;
      case "2":
        this.setState({
          form_2: {
            credit_number_1: { value: "", required: true },
            credit_number_2: { value: "", required: true },
            credit_number_3: { value: "", required: true },
            credit_number_4: { value: "", required: true },
            credit_year: { value: null, required: true },
            credit_month: { value: null, required: true },
            credit_code: { value: '', required: true },
            receipt: { value: '1', required: true },
            receipt_extra_2: { value: '', required: true },
            receipt_extra_3: { value: '', required: true },
            receipt_format: { value: '2', require: false },
            receipt_send_way: { value: '1', require: false },
            tax_id_numbers: { value: '', require: false },
            receipt_title: { value: '', require: false },
            receipt_send_code: { value: '', require: false },
            receipt_send_phone: { value: '', require: false },
            receipt_send_city: { value: '', require: false },
            receipt_send_area: { value: '', require: false },
            receipt_send_address: { value: '', require: false },
            receipt_send_receiver: { value: '', require: false },
            receipt_send_email: { value: '', require: false },
            agree: { value: false, required: true }
          }
        })
        break;
      case "3":
        this.setState({
          form_2: {
            id_number: { value: '', required: true },
            msisdn: { value: '', required: true },
            agree: { value: false, required: true }
          }
        })
        break;
    }

    this.setState({
      form_1: newForm
    })

  }

  onChange2 = (name, value) => {
    let newForm = Object.assign(this.state.form_2);
    newForm[name].value = value;

    this.setState({
      form_2: newForm
    })
  }

  disableButton1 = () => {
    this.setState({
      canSubmit1: false,
    });
  };

  enableButton1 = () => {
    this.setState({
      canSubmit1: true,
    });
  };

  disableButton2 = () => {
    this.setState({
      canSubmit2: false,
    });
  };

  enableButton2 = () => {
    this.setState({
      canSubmit2: true,
    });
  };

  stepSubmit = () => {
    if (this.state.form_1.payment.value === '4') {
      this.props.history.push('/prepaidDepositController/prepaidDepositFail');
    } else {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
      this.setState({ step: 2 })

      if (this.state.form_1.payment.value === '1') {
        setInputFilter(this.bankAccountInput.current.getElementsByTagName('input')[0], function (value) {
          return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
        });
      }

      if (this.state.form_1.payment.value === '1' || this.state.form_1.payment.value === '2') {
        setInputFilter(this.taxNumberInput.current.getElementsByTagName('input')[0], function (value) {
          return /^\w*$/.test(value); // Allow digits and '.' only, using a RegExp
        });
      }

      if (this.state.form_1.payment.value === '2') {
        let creditInputs = Array.from(this.creditGroup.current.getElementsByTagName('input'));
        creditInputs.map((input, i) => {
          setInputFilter(input, function (value) {
            return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
          });

          return input.addEventListener('keyup', e => {
            let index = creditInputs.indexOf(e.target);
            // console.log('input:' + index)
            if (e.currentTarget.value.length === 4 && index < 3) {
              creditInputs[index + 1].focus();
            }
          })
        })

        setInputFilter(this.validCode.current.getElementsByTagName('input')[0], function (value) {
          return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
        });

      }

      if (this.state.form_1.payment.value === '3') {
        setInputFilter(this.phoneNumberInput.current.getElementsByTagName('input')[0], function (value) {
          return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
        });
        setInputFilter(this.identityInput.current.getElementsByTagName('input')[0], function (value) {
          return /^\w*$/.test(value); // Allow digits and '.' only, using a RegExp
        });
      }

    }
  };

  submit = () => {
    // PrepaidService.submit(this.state.form_2).then(result => {

    // })
    this.props.history.push('/prepaidDepositController/prepaidDepositSuccess');
  };

  render() {
    const { step, form_1, form_2 } = this.state;
    return (
      <main>
        <NavAnchor
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="通話金儲值"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />
        <section className={`page-body ${step === 1 ? 'd-block' : 'd-none'}`}>
          <Formsy onValid={this.enableButton1} onInvalid={this.disableButton1}>
            <div className="fui-container">
              <div className="paper">
                <Grid container>
                  <Grid item xs={7} md={7}>
                    <h4 className="mb-2 mt-0">儲值門號</h4>
                  </Grid>
                  <Grid item xs={5} md={5} className='align-right'>
                    <Button btnStyle='secondary' size='small' className='is-text-sm'>查詢預付卡餘額</Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="rate-plan-box">
                      <h5>門號</h5>
                      <h2>0926-899-868</h2>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className='paper'>
                <Grid container>
                  <Grid item xs={12} md={7}>
                    <h4 className="mb-2 mt-0">請輸入欲儲值門號 </h4>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div ref={this.msisdnInput}>
                      <LabelInput
                        name="msisdn"
                        label="門號"
                        value={form_1.msisdn.value}
                        onChange={this.onChange1} />
                    </div>
                  </Grid>
                </Grid>

                <div>
                  <h4 className="mt-0 mb-2">請選擇通話金儲值金額</h4>
                  <div className="fui-table-response">
                    <table className="option-table">
                      <thead>
                        <tr>
                          <th width="50">選擇</th>
                          <th width="85">金額</th>
                          <th>特色</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.amountList.map((opt, i) => (
                            <tr key={`option-table-${i}`}>
                              <td className="align-center">
                                <label>
                                  <input
                                    id={`prepaid-${i}`}
                                    type="radio"
                                    name="prepaid_option"
                                    value={opt.amount}
                                    defaultChecked={Number(form_1.prepaid_option.value) === opt.amount}
                                    onChange={e => this.onChange1('prepaid_option', opt.amount)}
                                  />
                                  <div className="radio"></div>
                                </label>
                              </td>
                              <td className="align-center"><label htmlFor={`prepaid-${i}`}>$ {numberFormatter(opt.amount)}</label></td>
                              <td className="align-center"><label htmlFor={`prepaid-${i}`}>{opt.description}</label></td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className='mt-5'>
                  <RadioGroup
                    label='請選擇付款方式'
                    name="payment"
                    options={[
                      { label: '銀行帳號', value: '1' },
                      { label: '信用卡', value: '2' },
                      { label: '月租帳單', value: '3' }
                    ]}
                    default={form_1.payment.value}
                    onChange={this.onChange1}
                  />
                </div>
              </div>

              <div className="page-action">
                <Button btnStyle="primary" size='large' disabled={!this.state.canSubmit1} onClick={this.stepSubmit}>下一步</Button>
                <Button btnStyle="secondary" size='large'>查詢預付卡餘額</Button>
              </div>
            </div>
          </Formsy>
        </section>

        <section className={`page-body ${step === 2 ? 'd-block' : 'd-none'}`}>
          <Formsy onValid={this.enableButton2} onInvalid={this.disableButton2}>
            <div className="fui-container">
              <h4 className="is-text-darkgray70 my-0">請確認儲值內容，並填寫付款資訊</h4>
              <div className='is-text-accent text-sm'>
                <span className='required-dot'></span>必填
              </div>
              <div className='mb-5'>
                <h4 className="is-text-darkgray50 mt-5 mb-2">
                  儲值內容
                </h4>
                <div className="fui-table-response">
                  <table>
                    <thead>
                      <tr>
                        <th width="50%">儲值門號</th>
                        <th width="50%">金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0926-899-868</td>
                        <td>$100</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr className='my-5' />
                <h4 className="is-text-darkgray50 mb-2">
                  付款資料
                </h4>
                {
                  form_1.payment.value === '1' ? (
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <div className="form-group">
                          <label className="is-required">銀行代碼</label>
                          <Select
                            required={true}
                            name="bank_code"
                            label="請選擇"
                            options={[
                              { text: 'text', value: '1' }
                            ]}
                            value={form_2.bank_code.value}
                            onChange={this.onChange2}
                          />
                        </div>
                        <div ref={this.bankAccountInput}>
                          <LabelInput
                            required={true}
                            label="銀行帳號(限本人)"
                            name="bank_account"
                            value={form_2.bank_account.value}
                            onChange={this.onChange2}
                          />
                        </div>
                        <div>
                          <smalll className="form-note is-text-gray100">本付款機制採用SSL 256 bites安全加密機制</smalll>
                        </div>
                      </Grid>
                    </Grid>
                  ) : null
                }

                {
                  form_1.payment.value === '2' ? (
                    <div>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <div className="form-group">
                            <label className="is-required">信用卡卡號</label>
                            <div className="credit-input" ref={this.creditGroup}>
                              <input
                                type='tel'
                                name='credit_number[]'
                                required={true}
                                maxLength='4'
                                value={form_2.credit_number_1.value}
                                onChange={e => this.onChange2('credit_number_1', e.currentTarget.value)} />
                              <span>-</span>
                              <input
                                type='tel'
                                name='credit_number[]'
                                required={true}
                                maxLength='4'
                                value={form_2.credit_number_2.value}
                                onChange={e => this.onChange2('credit_number_2', e.currentTarget.value)} />
                              <span>-</span>
                              <input
                                type='tel'
                                name='credit_number[]'
                                required={true}
                                maxLength='4'
                                value={form_2.credit_number_3.value}
                                onChange={e => this.onChange2('credit_number_3', e.currentTarget.value)} />
                              <span>-</span>
                              <input
                                type='tel'
                                name='credit_number[]'
                                required={true}
                                maxLength='4'
                                value={form_2.credit_number_4.value}
                                onChange={e => this.onChange2('credit_number_4', e.currentTarget.value)} />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                          <div className="form-group mb-2">
                            <label className="is-required">信用卡有效日期</label>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Select
                                  name="credit_month"
                                  required={true}
                                  label={"月"}
                                  value={form_2.credit_month.value}
                                  options={[
                                    { text: '01', value: '01' },
                                    { text: '02', value: '02' },
                                    { text: '03', value: '03' },
                                    { text: '04', value: '04' },
                                    { text: '05', value: '05' },
                                    { text: '06', value: '06' },
                                    { text: '07', value: '07' },
                                    { text: '08', value: '08' },
                                    { text: '09', value: '09' },
                                    { text: '10', value: '10' },
                                    { text: '11', value: '11' },
                                    { text: '12', value: '12' }
                                  ]}
                                  onChange={this.onChange2} />
                              </Grid>
                              <Grid item xs={6}>
                                <Select
                                  name="credit_year"
                                  required={true}
                                  label={"年"}
                                  value={form_2.credit_year.value}
                                  options={[
                                    { text: '20', value: '20' },
                                    { text: '21', value: '21' },
                                    { text: '22', value: '22' },
                                    { text: '23', value: '23' },
                                    { text: '24', value: '24' },
                                    { text: '25', value: '25' },
                                    { text: '26', value: '26' },
                                    { text: '27', value: '27' },
                                    { text: '28', value: '28' },
                                    { text: '29', value: '29' },
                                    { text: '30', value: '30' }
                                  ]}
                                  onChange={this.onChange2} />
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <div className="form-group mb-2">
                            <label className="is-required">
                              檢查碼
                              <Tooltip
                                content={<i className='icon-information i-24 is-text-business100'></i>}
                                trigger="click"
                                tooltip={`信用卡背面三碼數字`}
                              />
                            </label>
                            <div className='credit-input' ref={this.validCode}>
                              <TextInput
                                type='tel'
                                name='credit_code'
                                required={true}
                                maxLength="3"
                                minLength='3'
                                validationErrors={{
                                  isDefaultRequiredValue: "請輸入檢查碼",
                                }}
                                value={form_2.credit_code.value}
                                onChange={val => this.onChange2('credit_code', val)}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured.png'}
                        srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured@2x.png'}
                        alt='' height="32" className="mr-1" />
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb.png'}
                        srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb@2x.png'}
                        alt='' height="32" className="mr-1" />
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard.png'}
                        srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard@2x.png'}
                        alt='' height="32" className="mr-1" />
                      <img
                        src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa.png'}
                        srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa@2x.png'}
                        alt='' height="32" className="mr-1" />
                      <div className="is-text-darkgray50 mt-1">
                        <small>本付款機制採用SSL 256 bites安全加密機制</small>
                      </div>
                    </div>
                  ) : null
                }

                {
                  form_1.payment.value === '3' ? (
                    <Grid container className='pb-5 mb-5 with-note'>
                      <Grid item xs={12} md={6}>
                        <div ref={this.phoneNumberInput}>
                          <LabelInput
                            label="月租門號(限本人)"
                            name="msisdn"
                            required={true}
                            value={form_2.msisdn.value}
                            onChange={this.onChange2}
                          />
                        </div>
                        <small className="form-note is-text-gray100">提醒您：使用「月租型門號帳單」加購，將於月租門號的下期帳單內進行收費</small>
                      </Grid>
                    </Grid>
                  ) : null
                }
                {
                  form_1.payment.value === '1' || form_1.payment.value === '2' ? (
                    <div>
                      <hr className="mb-5" />
                      <h4 className="is-text-darkgray50 mb-2">
                        發票明細
                      </h4>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <div className="form-group">
                            <label className="is-required">發票捐贈</label>
                            <div className="radio-buttons">
                              <div className="radio-group receipt-radio">
                                <div>
                                  <label className="radio-button">
                                    <p>不捐贈</p>
                                    <input
                                      type='radio'
                                      name="receipt"
                                      value='1'
                                      checked={form_2.receipt.value === '1'}
                                      onChange={e => this.onChange2('receipt', e.currentTarget.value)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                <div>
                                  <label className="radio-button">
                                    <p>
                                      捐贈，選擇單位
                                    </p>
                                    <input
                                      type='radio'
                                      name="receipt"
                                      value='2'
                                      checked={form_2.receipt.value === '2'}
                                      onChange={e => this.onChange2('receipt', e.currentTarget.value)} />
                                    <span className="checkmark"></span>
                                  </label>
                                  <Select
                                    name="receipt_extra_2"
                                    label="請選擇"
                                    disabled={form_2.receipt.value !== '2'}
                                    value={form_2.receipt_extra_2.value}
                                    onChange={this.onChange2}
                                    options={[
                                      { text: '111', value: '111' }
                                    ]}
                                  />
                                </div>
                                <div>
                                  <label className="radio-button">
                                    <p>
                                      捐贈，填寫愛心碼
                                    </p>
                                    <input
                                      type='radio'
                                      name="receipt"
                                      value='3'
                                      checked={form_2.receipt.value === '3'}
                                      onChange={e => this.onChange2('receipt', e.currentTarget.value)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <div>
                                    <Link to="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC603W/" target='_blank' className='search-code'>查詢愛心碼</Link>
                                    <input
                                      type="text"
                                      name="receipt_extra_3"
                                      disabled={form_2.receipt.value !== '3'}
                                      placeholder="請填寫"
                                      value={form_2.receipt_extra_3.value}
                                      onChange={e => this.onChange2('receipt_extra_3', e.currentTarget.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <small className="is-text-darkgray50">※捐贈發票後，不提供索取紙本發票及更改捐贈對象</small>
                          </div>
                        </Grid>
                      </Grid>
                      {
                        form_2.receipt.value === '1' ? (
                          <div>
                            <Grid container>
                              <Grid item xs={12}>
                                <RadioGroup
                                  label='發票格式'
                                  name="receipt_format"
                                  require={form_2.receipt.value === '1'}
                                  options={[
                                    { label: '二聯式發票', value: '1' },
                                    { label: '三聯式發票', value: '2' }
                                  ]}
                                  default={form_2.receipt_format.value}
                                  onChange={this.onChange2}
                                />
                              </Grid>
                            </Grid>

                            {
                              form_2.receipt_format.value === '2' ? (
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={5}>
                                    <div ref={this.taxNumberInput}>
                                      <LabelInput
                                        name='tax_id_numbers'
                                        label='統一編號'
                                        placeholder=' 請輸入統一編號'
                                        required={form_2.tax_id_numbers.value === '2'}
                                        onChange={this.onChange2}
                                        value={form_2.tax_id_numbers.value}
                                      />
                                    </div>
                                    <LabelInput
                                      name='receipt_title'
                                      label='發票抬頭'
                                      placeholder=' 請輸入發票抬頭'
                                      required={form_2.receipt_format.value === '2'}
                                      onChange={this.onChange2}
                                      value={form_2.receipt_title.value}
                                    />
                                  </Grid>
                                </Grid>
                              ) : null
                            }


                            <div className="form-group">
                              <label className='is-required'>發票寄送方式</label>
                              <div className="input-row">
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={5}>
                                    <Select
                                      required={form_2.receipt.value === '1'}
                                      name="receipt_send_way"
                                      label="請選擇"
                                      options={[
                                        { text: 'FETnet 會員', value: '1' },
                                        { text: '紙本郵寄', value: '2' },
                                        { text: '手機載具', value: '3' },
                                        { text: '自然人憑證', value: '4' }
                                      ]}
                                      value={form_2.receipt_send_way.value}
                                      onChange={this.onChange2}
                                    />
                                  </Grid>
                                </Grid>
                                {
                                  form_2.receipt_send_way.value === '3' ? (
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={5}>
                                        <TextInput
                                          type='tel'
                                          name='receipt_send_phone'
                                          required={form_2.receipt_send_way.value === '3'}
                                          placeholder='請輸入手機號碼'
                                          validationError={"請輸入手機號碼"}
                                          value={form_2.receipt_send_phone.value}
                                          onChange={val => this.onChange2('receipt_send_phone', val)}
                                        />
                                      </Grid>
                                    </Grid>
                                  ) : null
                                }
                                {
                                  form_2.receipt_send_way.value === '4' ? (
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={5}>
                                        <TextInput
                                          type='tel'
                                          name='receipt_send_code'
                                          required={form_2.receipt_send_way.value === '4'}
                                          placeholder='請輸入代碼'
                                          validationError={"請輸入代碼"}
                                          value={form_2.receipt_send_code.value}
                                          onChange={val => this.onChange2('receipt_send_code', val)}
                                        />
                                      </Grid>
                                    </Grid>
                                  ) : null
                                }
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={5}>
                                    <Select
                                      required={form_2.receipt.value === '1'}
                                      name="receipt_send_city"
                                      label="縣市"
                                      options={[
                                        { text: '台北市', value: '1' },
                                        { text: '新北市', value: '2' }
                                      ]}
                                      value={form_2.receipt_send_city.value}
                                      onChange={this.onChange2}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={5}>
                                    <Select
                                      required={form_2.receipt.value === '1'}
                                      name="receipt_send_area"
                                      label="鄉鎮市區"
                                      options={[
                                        { text: '台北市', value: '1' },
                                        { text: '新北市', value: '2' }
                                      ]}
                                      value={form_2.receipt_send_area.value}
                                      onChange={this.onChange2}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={5}>
                                    <TextInput
                                      type='tel'
                                      name='receipt_send_code'
                                      required={form_2.receipt.value === '1'}
                                      placeholder='請輸入中獎發票寄送地址'
                                      validationError={"請輸入中獎發票寄送地址"}
                                      value={form_2.receipt_send_address.value}
                                      onChange={val => this.onChange2('receipt_send_address', val)}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={5}>
                                    <TextInput
                                      type='tel'
                                      name='receipt_send_code'
                                      required={form_2.receipt.value === '1'}
                                      placeholder='請輸入收件人'
                                      validationError={"請輸入收件人"}
                                      value={form_2.receipt_send_receiver.value}
                                      onChange={val => this.onChange2('receipt_send_receiver', val)}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={5}>
                                    <TextInput
                                      type='tel'
                                      name='receipt_send_code'
                                      required={form_2.receipt.value === '1'}
                                      placeholder='請輸入電子郵件信箱'
                                      validationError={"請輸入電子郵件信箱"}
                                      value={form_2.receipt_send_email.value}
                                      onChange={val => this.onChange2('receipt_send_email', val)}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>
                        ) : null
                      }

                      <CheckboxCollapse
                        name="agree"
                        label="本人已詳閱及了解此注意事項並同意遵守"
                        validationError='請詳閱及了解此注意事項'
                        validations={{
                          isChecked: form => form.agree
                        }}
                        collapseContent={`
                          <p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。</p>
                          <p>使用「全國繳費平台」繳納遠傳電信服務費用注意事項：</p>
                          <p>
                          1.限以門號申請本人活存帳戶使用此服務。<br/>
                          2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                          3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                          4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                          5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。
                          </p>
                        `}
                        required
                        getCheckbox={e => this.onChange2('agree', e)}
                        value={form_2.agree.value}
                      />
                      <div className="page-action">
                        <Button btnStyle='primary' size="large" disabled={!this.state.canSubmit2} onClick={this.submit}>確認付款</Button>
                        <Button btnStyle='secondary' size="large" onClick={e => this.setState({ step: 1 })}>回上一步</Button>
                      </div>
                    </div>
                  ) : (
                      <div>
                        <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                        <Grid container>
                          <Grid item md={6}>
                            <div ref={this.identityInput}>
                              <LabelInput
                                name='id_number'
                                required={form_2.id_number.required}
                                label='證照號碼'
                                placeholder='請輸入證照號碼'
                                value={form_2.id_number.value}
                                onChange={this.onChange2}
                              />
                            </div>
                            <div className='body1 is-text-darkgray50'>
                              身分說明：
                            <br />
                            個人戶：身分證字號
                            <br />
                            公司戶：統一編號或稅籍編號
                            <br />
                            外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                          </div>
                          </Grid>
                        </Grid>
                        <div className="page-action">
                          <Button btnStyle='primary' size="large" disabled={!this.state.canSubmit2} onClick={this.submit}>確認</Button>
                          <Button btnStyle='secondary' size="large" onClick={e => this.setState({ step: 1 })}>回上一步</Button>
                        </div>
                      </div>
                    )
                }
              </div>
            </div>
          </Formsy>
        </section>
        {
          step === 1 ? (
            <SectionCollapseInfo
              title="貼心小叮嚀"
              content={`
                1. 選用月租帳單、銀行帳戶及信用卡支付易付卡門號儲值金額，其月租型門號或銀行帳戶及信用卡的所有人需與易付卡門號所有人為同一身分證號。<br/>
                2. 每張信用卡每月可支付易付卡額度為 $2,000 元，包含儲值及加購上網方案。<br/>
                3. 月租帳單每月累積消費總額依遠傳電信規範，若超過總額，將不得為易付卡支付儲值或加購上網方案。<br/>
                4. 非本國信用卡或非本國銀行帳戶，無法進行支付。<br/>
                5. 預付卡活動可參考「<a href="#">易付卡最新活動</a>」。<br/>
                6. 【門號效期到期通知】當用戶收到客戶名下所持易付卡門號，門號效期到期通知，且提醒須於3日內儲值之權益簡訊，務請客戶於期限內於遠傳電信各相關儲值通路，儘快儲值，以確保客戶門號效期之保留權益。 客戶透過門市、IVR、遠傳官網、行動客服APP及客服中心查詢門號到期日，係因系統執行與客戶權益簡訊仍有時間差距，僅可供部份參考；無法確保門號效期仍具持有效力。<br/>
                7. 每門易付卡餘額總額不得超過$5,000元。<br/>
                8. 易付卡任一活動所贈送的通信費(不含傳輸量)，除另有規範效期外，自每次完成贈送或儲值後6個月內有效，請於贈送效期內使用完畢，逾期失效，無法遞延、轉讓或折換現金<br/>
                9. 遠傳電信保有資費及服務異動修改之權利，最新資費服務內容以遠傳網站公告為主。
              `}
            />
          ) : null
        }
      </main>
    )
  }
}

export default prepaidDepositPage;