import React from 'react';
import { Link } from 'react-router-dom'
import RadioButtons from '../form/RadioButtons'
import LabelTextarea from '../form/LabelTextarea'
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';

class FaqCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.body = React.createRef();
    this.form = React.createRef();
    this.state = {
      contentHeight: 0,
      editForm: false,
      feedbackCompelete: false,
      commentShow: false,
      open: false,
      feedbackForm: {
        feedbackOption: '',
        comment: ''
      },
      options: [
        { value: '1', label: '這說明沒有解決我的問題' },
        { value: '2', label: '這些資訊不正確' },
        { value: '3', label: '我看不懂' },
        { value: '4', label: '其它' },
      ]
    }

    this.collapseOpen = this.collapseOpen.bind(this)
    this.storeForm = this.storeForm.bind(this)
    this.returnFeedback = this.returnFeedback.bind(this)
    this.setContentHeight = this.setContentHeight.bind(this)
    this.updateContentHeight = this.updateContentHeight.bind(this)
    this.disableButton = this.disableButton.bind(this)
    this.enableButton = this.enableButton.bind(this)
  }

  setContentHeight() {
    this.setState({
      open: this.props.open,
      contentHeight: this.body.current ? this.body.current.clientHeight : 0
    })
  }

  componentDidMount() {
    if (this.props.open) {
      this.setState({
        open: this.props.open,
      })
      this.collapseOpen()
    }
    if (typeof window !== 'undefined')
      window.addEventListener('resize', e => {
        setTimeout(() => {
          this.updateContentHeight();
        }, 500);
        this.forceUpdate();
      })
  }

  componentDidUpdate(nextProps) {
    if (this.props.onChange && (nextProps.open !== this.state.open || this.props.open !== this.state.open)) {
      this.setState({
        open: this.props.open
      })
    }
  }

  returnFeedback(val) {
    if (val === 0) {
      this.setState({
        editForm: true
      })
    } else {
      this.setState({
        feedbackCompelete: true
      })

      if (val === -1) {
        if (this.props.feedback) {
          this.props.feedback({
            help: 'no',
            form: this.state.feedbackForm
          })
        }
      }

      if (val === 1) {
        if (this.props.feedback) {
          this.props.feedback({
            help: 'yes'
          })
        }
      }

    }

    this.forceUpdate();
    this.updateContentHeight();
  }

  collapseOpen() {
    this.setContentHeight()
    if (this.props.onChange) {
      this.props.onChange(!this.state.open)
    } else {
      this.setState({
        open: !this.state.open
      })
    }

    this.forceUpdate();
  }

  storeForm(name, value) {
    let form = Object.assign(this.state.feedbackForm);
    form[name] = value;

    this.setState({
      feedbackForm: form,
      commentShow: ((name === 'feedbackOption' && value === '4') || name === 'comment')
    })

    this.forceUpdate();
    this.updateContentHeight();
  }

  updateContentHeight() {
    setTimeout(() => {
      this.setState({
        contentHeight: this.body.current ? this.body.current.clientHeight + (typeof window !== 'undefined' && window.isIE ? 100 : 0) : 0
      })
    }, 50)
  }

  resetFeedback = () => {
    this.setState({
      editForm: false,
      feedbackForm: {
        feedbackOption: '',
        comment: ''
      }
    })
    this.forceUpdate();
    this.updateContentHeight();
  }

  disableButton = (model, resetForm, invalidateForm) => {
    // debugger
    setTimeout(() => {
      if (typeof window !== 'undefined' && window.isIE) {
        let elem = window || document.documentElement
        elem.scroll(0, document.getElementsByClassName('is-invalid')[0].offsetTop);
      } else
        window.scrollTo(0, document.getElementsByClassName('is-invalid')[0].offsetTop);
    }, 100);
    this.setState({ canSubmit: false });
  }

  enableButton = (model, resetForm, invalidateForm) => {
    // debugger
    this.setState({ canSubmit: true });
  }

  submit = (model) => {
    console.log('submitted');
  }

  feedbackRender() {
    if (this.state.feedbackCompelete) {
      return (
        <div className='is-text-darkgray50'>
          <i className='icon-check'></i> 感謝您的回應！
        </div>
      )
    }
    if (this.state.editForm) {
      return (
        <Formsy className=""
          onValidSubmit={this.submit}
          onValid={this.enableButton}
          onInvalidSubmit={this.disableButton}
          noValidate
          ref={this.form}>
          <div className='is-text-darkgray50'>
            為什麼沒有幫助？
            </div>

          <RadioButtons
            name='feedbackOption'
            options={[
              { value: '1', label: '這說明沒有解決我的問題' },
              { value: '2', label: '這些資訊不正確' },
              { value: '3', label: '我看不懂' },
              { value: '4', label: '其它' },
            ]}
            selected={this.state.feedbackForm.feedbackOption.value}
            getSelected={e => this.storeForm('feedbackOption', e)} />
          {
            this.state.commentShow ? (
              <LabelTextarea
                placeholder='若您對此解答有其他意見，請說明。幫助我們提供更佳服務，謝謝！'
                label=''
                name='comment'
                value={this.state.feedbackForm.comment}
                onChange={this.storeForm} />
            ) : null
          }

          <div className="input-group">
            <button className='fui-button is-secondary' onClick={e => this.returnFeedback(-1)}>
              <span className='text'>送出</span>
            </button>
            <button className='fui-button is-secondary' onClick={e => this.resetFeedback()}>
              <span className='text'>取消</span>
            </button>
          </div>
        </Formsy>
      )
    }

    return (
      <div>
        <span className="text is-text-darkgray50">答案有幫助嗎？</span>
        <button className='fui-button is-secondary' onClick={e => this.returnFeedback(1)}>
          <span className='text'>是</span>
        </button>
        <button className='fui-button is-secondary' onClick={e => this.returnFeedback(0)}>
          <span className='text'>否</span>
        </button>
      </div>
    )
  }


  render() {
    return (
      <div className={`fui-collapse is-faq ${this.state.open ? 'is-open' : ''}`}>
        <div
          role="button"
          onClick={this.collapseOpen}
          className={`collapse-header`}
          dangerouslySetInnerHTML={{ __html: this.props.title }}>
        </div>
        <div
          className="collapse-body"
          style={{
            height: this.state.open ? this.state.contentHeight : 0
          }}>
          <div className={`${this.props.hasFeedback ? '' : 'p-0'}`} ref={this.body}>
            <article dangerouslySetInnerHTML={{ __html: this.props.content }}></article>
            {this.props.hasFeedback ? <div>
              {this.props.detailLink ? <div>
                <Link to={this.props.detailLink} className="fui-button is-text mb-0">
                  <span className="text">更多詳細說明</span>
                  <i className='icon-chevron-right'></i>
                </Link>
                <hr />
              </div> : null}
              <div className='feedback'>
                {
                  this.feedbackRender()
                }
              </div>
            </div> : null}

          </div>
        </div>
      </div>
    )
  }
}

FaqCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  detailLink: PropTypes.string,
  feedback: PropTypes.func,
  open: PropTypes.bool, // default false
  hasFeedback: PropTypes.bool
}

export default FaqCollapse