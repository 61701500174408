import React, { Component } from 'react'
import Button from '../../components/Button';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import * as formatNumber from '../../utils/numberFormatter';
class PromotionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideSetting: {
        dots: false,
        infinite: false,
        arrows: true,
        slidesToShow: 2,
        draggable: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              arrows: true,
              slidesToShow: 2,
              variableWidth: true,
            },
          },

          {
            breakpoint: 768,
            settings: {
              arrows: true,
              slidesToShow: 1,
              variableWidth: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: true,
              slidesToShow: 1,
              variableWidth: true
            }
          }
        ],
      },
    }
  }
  render() {
    return (
      <section className='promotion-card pb-0' style={!!this.props.topBg ? { backgroundImage: `url(${this.props.topBg})` } : null}>
        <div className='fui-container promotion-card-title mt-4'>
          <h2 className="mt-0 ">不用支付太多，共享騎乘輕體驗</h2>
          <Slider {...this.state.slideSetting}
            className='horizontal-cards is-program pb-md-9 mb-0'>
            {this.props.program.map((card, idx) => (
              <div key={idx} className='fui-card is-card-program'>
                {card.tag ? <div className={`tag tag-${card.tag.color}`}>{card.tag.text}</div> : null}
                <h3 className="mt-2 mb-0">{card.title}</h3>
                <h1 className='mt-2 mb-1'>
                  <small className='mr-1'>{card.unit}</small>
                  <span className="price is-text-error">
                    <span>$</span>{formatNumber.default(card.price)}
                  </span>
                </h1>
                <ul>
                  {card.list.map(item => (
                    <li className='' key={item}>
                      <i className="icon-check is-text-accent" />
                      <div className='subtitle is-text-darkgray50'>{item}</div>
                    </li>
                  ))}
                </ul>
                {card.button.length > 1 ? <div className="form-group two-buttons">
                  <Button btnStyle='primary' size='medium' link={card.button[0].link}>{card.button[0].title}</Button>
                  <Button btnStyle='secondary' size='medium' link={card.button[1].link}>{card.button[1].title}</Button>
                </div> : <div className="form-group m-0">
                    <Button btnStyle='primary' size='medium' className='mb-0' link={card.button[0].link}>{card.button[0].title}</Button>
                  </div>}
              </div>
            ))}
          </Slider>
        </div>
        <div
          className='bottom-bg'
          style={this.props.bottomBg ? { backgroundImage: `url(${this.props.bottomBg})` } : null}
        />
      </section>
    );
  }
}
PromotionCard.propTypes = {
  topBg: PropTypes.string,
  bottomBg: PropTypes.string,
  props: PropTypes.array,
};
export default PromotionCard;