import React from 'react';
import { Grid } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import Dropdown from "../Dropdown";
import CheckModal from '../CheckModal';
import Marker from '../Marker'
const refMap = React.createRef();
class StoreFinder extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      places: this.props.places,
      center: {},
      modalOpen: false,
      displayPlaces: [],
      prompt: true,
      currentStore: null,
      currentCity: null,
      currentLocation: null,
      cityResult: null,
      inputResult: null,
      locationResult: null,
      searchResult: null,
      currentZoom: 13,
      isMobile: window.innerWidth < 960,
      cityList: this.props.cityList
    }
  }
  static defaultProps = {
    center: { lat: 25.046618, lng: 121.517907 },
    zoom: 13
  };
  componentDidMount() {

    let startPos;
    let options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    let geoSuccess = (position) => {
      // console.log('success');
      startPos = position;
      this.setState({
        center: {
          lat: startPos.coords.latitude,
          lng: startPos.coords.longitude
        },
        prompt: false
      })
      this.getDisplayPlaces(startPos.coords.latitude, startPos.coords.longitude);
    };
    let error = () => {
      // console.log('blocked!');
      this.setState({
        center: {
          lat: StoreFinder.defaultProps.center.lat,
          lng: StoreFinder.defaultProps.center.lng,
        },
        prompt: true
      })
      // this.getDisplayPlaces(StoreFinder.defaultProps.center.lat, StoreFinder.defaultProps.center.lng);
    }
    let handlePermission = () => {
      let _this = this;
      if (this.msieversion()) {
        this.setState({
          center: {
            lat: StoreFinder.defaultProps.center.lat,
            lng: StoreFinder.defaultProps.center.lng,
          }
        })
        this.getDisplayPlaces(StoreFinder.defaultProps.center.lat, StoreFinder.defaultProps.center.lng);
      } else if (this.isSafari()) {
        // console.log('safari');
        this.setState({
          prompt: true
        })
        navigator.geolocation.getCurrentPosition(geoSuccess, error, options);
      } else {
        // debugger
        // navigator.geolocation.getCurrentPosition(geoSuccess, error, options)
        navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
          if (result.state == 'granted') {
            // console.log(result.state);
            navigator.geolocation.getCurrentPosition(geoSuccess);
          } else if (result.state == 'prompt') {
            // console.log(result.state);
            _this.setState({
              center: {
                lat: StoreFinder.defaultProps.center.lat,
                lng: StoreFinder.defaultProps.center.lng,
              },
              prompt: true
            })
            navigator.geolocation.getCurrentPosition(geoSuccess);
          } else if (result.state == 'denied') {
            // console.log(result.state);
            _this.setState({
              center: {
                lat: StoreFinder.defaultProps.center.lat,
                lng: StoreFinder.defaultProps.center.lng,
              },
              prompt: true
            })
            // _this.getDisplayPlaces(StoreFinder.defaultProps.center.lat, StoreFinder.defaultProps.center.lng);
          }
          result.onchange = function () {
            if (result.state == 'denied') {
              _this.setState({
                center: {
                  lat: StoreFinder.defaultProps.center.lat,
                  lng: StoreFinder.defaultProps.center.lng
                }
              })
            }
          }
        });
      }
    }
    handlePermission();
  }
  openInfo = (item) => {
    this.setState({
      currentStore: item,
      center: {
        lat: item.geometry.location.lat,
        lng: item.geometry.location.lng,
      },
      currentZoom: 13
    })

    if (this.state.isMobile) window.scrollTo({ top: 70, behavior: 'smooth' });
  }
  getMoreInfo = () => {
    if (this.state.currentStore !== null) {
      return (
        <div className="more-info">
          <h3 className="d-inline">{this.state.currentStore.name}</h3>
          <div className="close float-right" onClick={() => { this.setState({ currentStore: null }) }}>
            <i className='icon-close heading-3' />
          </div>
          <p className="subtitle is-text-medium">{this.state.currentStore.addr}</p>
          <div className="contact d-flex flex-align-center">
            <a href={`tel:${this.state.currentStore.phone}`} className="subtitle is-text-medium">{this.state.currentStore.phone}</a>
            <a href={this.state.currentStore.fb} target="_blank">
              <img src={process.env.PUBLIC_URL + "/resources/cbu/img/facebook-share.svg"} alt="fb" />
            </a>
            <a href={this.state.currentStore.getDirection} target="_blank">
              <img src={process.env.PUBLIC_URL + "/resources/cbu/img/map.svg"} alt="map" />
            </a>
          </div>
          <hr />
          <div className="d-flex wrapper time-table">
            <p className="is-text-medium">服務時間</p>
            <div className="d-flex flex-column">
              {this.state.currentStore.time.map((time) => {
                return (
                  <p key={time}>
                    {time}
                  </p>
                )
              })}
            </div>
          </div>
          <hr />
          <div className="d-flex wrapper services">
            <p className="is-text-medium">門市服務</p>
            <div className="services-list">
              {this.state.currentStore.services.map((service) => {
                return (
                  <div className="item" key={service}>
                    <img src={process.env.PUBLIC_URL + "/resources/cbu/life-circle-slashie/images/check.svg"} alt="check" />
                    <span>{service}</span>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="d-flex wrapper services">
            <p className="is-text-medium">無障礙設施</p>
            <div className="services-list">
              {this.state.currentStore.facilities.map((facility) => {
                return (
                  <div className="item" key={facility}>
                    <img src={process.env.PUBLIC_URL + "/resources/cbu/life-circle-slashie/images/check.svg"} alt="check" />
                    <span>{facility}</span>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="d-flex button-group">
            <a href={this.state.currentStore.getDirection} target="_blank" className='fui-button is-secondary'>
              <span className='text'>開啟地圖導航</span>
            </a>
            <a href={`tel:${this.state.currentStore.phone}`} className='fui-button is-secondary mr-0'>
              <span className='text'>門市預約</span>
            </a>
          </div>
        </div>
      )
    } else {
      // console.log("nothing");
    }

  }
  getDisplayPlaces = ((lat, lng) => {
    // console.log(`lat, lng`, lat, lng);
    const result = []
    this.state.places.map((item) => {
      // debugger
      if (Math.abs(item.geometry.location.lat - lat) < .05 && Math.abs(item.geometry.location.lng - lng) < .05) {
        result.push(item)
        // console.log(`item`, item);
      }
    })
    this.setState({
      displayPlaces: result
    })
  })

  onDrag = (map) => {
    this.setState({
      center: { lat: map.center.lat(), lng: map.center.lng() },
      currentCity: null,
      currentLocation: null,
      currentStore: null
    })
    this.getDisplayPlaces(map.center.lat(), map.center.lng());
  };
  cityActive = () => {
    // console.log("city active");
    this.setState({
      currentLocation: null
    })
  }
  cityChange = item => {
    // console.log(item);
    let result = [];
    this.state.places.map((place) => {
      // console.log(`place.addr `, place.addr);
      // console.log(`item `, item);
      if (place.addr.indexOf(item.text) > -1) {
        result.push(place)
      }
    })
    // console.log({ result });

    this.setState({
      currentCity: item,
      currentLocation: null,
      displayPlaces: result,
      cityResult: result,
      center: {
        lat: result[0].geometry.location.lat,
        lng: result[0].geometry.location.lng
      }
    })
  };
  locationChange = item => {
    // console.log(item);
    let result = [];
    this.state.cityResult.map((place) => {
      if (place.addr.indexOf(item.text) > -1) {
        result.push(place);
        this.setState({
          center: {
            lat: result[0].geometry.location.lat,
            lng: result[0].geometry.location.lng
          }
        })
      } else {
        this.setState({
          currentLocation: null,
          displayPlaces: this.state.cityResult
        })
      }
    })
    this.setState({
      currentLocation: item.text,
      displayPlaces: result,
      locationResult: result,
    })
  };
  inputFilter = event => {
    // console.log(this.input.current.value.length);
    let result = [];
    if (!!this.state.currentCity && !!this.state.currentLocation) {
      this.state.locationResult.map((place) => {
        if (place.addr.indexOf(this.input.current.value) > -1 || place.name.indexOf(this.input.current.value) > -1) {
          result.push(place);
          this.setState({
            center: {
              lat: result[0].geometry.location.lat,
              lng: result[0].geometry.location.lng
            }
          })
        }
      })
      if (this.input.current.value.length == 0) {
        this.setState({
          displayPlaces: this.state.places
        })
      } else {
        this.setState({
          displayPlaces: result
        })
      }
    } else {
      this.state.places.map((place) => {
        if (place.addr.indexOf(this.input.current.value) > -1 || place.name.indexOf(this.input.current.value) > -1) {
          result.push(place);
          this.setState({
            center: {
              lat: result[0].geometry.location.lat,
              lng: result[0].geometry.location.lng
            }
          })
        }
      })
      if (this.input.current.value.length === 0) {
        this.setState({
          displayPlaces: this.state.places
        })
      } else {
        this.setState({
          displayPlaces: result
        })
      }
    }
  }
  noPlaces = () => {
    if (this.state.prompt) {
      return (
        <div className="no-place">
          <img src={process.env.PUBLIC_URL + "/resources/cbu/img/pin.svg"} alt="pin" />
          <p className="is-text-grayblue heading-6 is-text-regular">你可以透過地址和關鍵字找門市，或允許瀏覽器存取位置資訊，搜尋離你最近的遠傳門市。</p>
        </div>
      )
    } else {
      return (
        <div className="no-place">
          <img src={process.env.PUBLIC_URL + "/resources/cbu/img/pin.svg"} alt="pin" />
          <h5 className="">很抱歉，找不到符合的門市。</h5>
          <p className="is-text-grayblue heading-6 is-text-regular">你可以從上方欄位再搜尋一次，或透過GPS定位功能查詢附近的遠傳門市。</p>
        </div>
      )
    }
  }
  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  // check is IE
  msieversion = () => {
    let ua = window.navigator.userAgent;
    let isIE = /MSIE|Trident/.test(ua);
    let isEdge = ua.indexOf('Edge') > 0;
    if (isIE || isEdge) {
      // console.log('is ie');
      return true;
    } else {
      return false;
    }
  }
  // check Safari
  isSafari = () => {
    let ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        return false// Chrome
      } else {
        return true// Safari
      }
    }
  }
  submit = (result) => {
    let filterResult = [];
    let filterArray = [];
    this.setState({
      searchResult: result
    })
    this.closeModal();
    // console.log(result);
    Object.keys(result).forEach(key => {
      if (result[key].value) {
        filterArray.push(result[key].name);
      }
    })
    // console.log(filterArray);

    if (Array.prototype.equals)
      console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
    // attach the .equals method to Array's prototype to call it on any array
    Array.prototype.equals = function (array) {
      // if the other array is a falsy value, return
      if (!array)
        return false;

      // compare lengths - can save a lot of time 
      if (this.length != array.length)
        return false;

      for (var i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
          // recurse into the nested arrays
          if (!this[i].equals(array[i]))
            return false;
        }
        else if (this[i] != array[i]) {
          // Warning - two different object instances will never be equal: {x:20} != {x:20}
          return false;
        }
      }
      return true;
    }
    // Hide method from for-in loops
    Object.defineProperty(Array.prototype, "equals", { enumerable: false });



    this.state.places.map((item) => {
      // console.log(item.filter.includes(filterArray.toString()));
      // console.log(`item.filter`, item.filter.toString());
      // console.log(`filterArray`, filterArray);

      if (item.filter.equals(filterArray) || this.msieversion() ? null : item.filter.includes(filterArray.toString())) {
        filterResult.push(item);
      }
    })
    //console.log(filterResult);
    this.setState({
      displayPlaces: filterResult,
      currentZoom: 8,
      center: { lat: 23.811303, lng: 120.979756 },
      currentStore: null
    })

    // 模糊搜尋版本
    // Object.keys(result).forEach(key => {
    //   console.log(result[key].value)
    //   if (result[key].value) {
    //     // loop勾選結果
    //     this.state.places.map((place) => {
    //       place.filter.map((item) => {
    //         if (item == result[key].name) {
    //           console.log(place);
    //           if (filterResult.indexOf(place) === -1) {
    //             filterResult.push(place);
    //           }
    //         }
    //       })
    //     })
    //     let uniq = [...new Set(filterResult)]
    //     console.log(`uniq`, uniq);
    //     this.setState({
    //       displayPlaces: filterResult
    //     })
    //   }
    // })
  }
  render() {
    return (
      <div className="store-finder">
        <div className="fui-container">
          <CheckModal open={this.state.modalOpen} closeModal={this.closeModal} submit={this.submit} />
          <h2>找門市</h2>
          <div className="store-wrapper">
            <Grid container spacing={0}>
              {this.state.isMobile ? this.getMoreInfo() : null}
              <Grid item xs={12} sm={12} md={12}>
                <div className="filter">
                  <Dropdown
                    className=""
                    list={this.state.cityList}
                    label={!!this.state.currentCity ? this.state.currentCity.text : '請選擇縣市'}
                    arrow={true}
                    hasCheck={false}
                    onChange={(item) => this.cityChange(item)}
                    onActive={(item) => this.cityActive()}
                  />
                  <Dropdown
                    className={this.state.currentCity ? null : 'disabled'}
                    list={this.state.currentCity !== null ? this.state.currentCity.location : [{ text: '請選擇地點', value: '請選擇地點' }]}
                    label={!!this.state.currentLocation ? this.state.currentLocation : '請選擇地點'}
                    arrow={true}
                    hasCheck={false}
                    onChange={(item) => { this.locationChange(item) }}
                  />
                  <div className="search-input">
                    <div className="input-wrapper">
                      <input
                        type='text'
                        ref={this.input}
                        placeholder="輸入店名、路名"
                        name="textFilter"
                        onBlur={this.inputFilter}
                        onChange={this.inputFilter}
                        onKeyUp={this.inputFilter}
                      />
                      <i className="icon-search" />
                    </div>
                    <button className='fui-button is-secondary filter-button' onClick={() => { this.setState({ modalOpen: true }) }}>
                      <span className='text'>進階搜尋</span>
                      <img src="/resources/cbu/img/filter.svg" alt="filter" />
                    </button>
                  </div>
                </div>
              </Grid>
              <Grid className="infobox" item xs={12} sm={12} md={5}>
                <div className={`searchbox ${this.state.currentStore ? 'open' : ''}`} >
                  {
                    this.state.displayPlaces !== null && this.state.displayPlaces.length > 0 ? this.state.displayPlaces.map((item) => {
                      return (
                        <div className="list" key={item.name} onClick={(e) => this.openInfo(item)}>
                          <h4>{item.name}</h4>
                          <div className="d-flex justify-between flex-align-center">
                            <p>{item.addr}</p>
                            <i className="icon-chevron-right" />
                          </div>
                          <div className="d-flex contact">
                            <p>{item.phone}</p>
                            <a href={item.fb} target="_blank">
                              <img src="/resources/cbu/img/facebook-share.svg" alt="fb" />
                            </a>
                            <a href={item.phone} target="_blank">
                              <img src="/resources/cbu/img/map.svg" alt="map" />
                            </a>
                          </div>
                        </div>
                      )
                    }) : this.noPlaces()
                  }
                </div>
                {this.state.isMobile ? null : this.getMoreInfo()}
              </Grid>
              {this.state.isMobile ? null : <Grid item xs={12} sm={12} md={7}>
                <div className="map">
                  <GoogleMapReact
                    ref={refMap}
                    defaultCenter={this.state.center}
                    center={this.state.center ? this.state.center : null}
                    defaultZoom={this.props.zoom}
                    zoom={this.state.currentZoom}
                    yesIWantToUseGoogleMapApiInternals
                    bootstrapURLKeys={{ key: 'AIzaSyAEtGDgMxN3WjDZchzkMCNz3l2PWdPtKxM' }}
                    onDragEnd={this.onDrag}
                  >
                    {!!this.state.displayPlaces && this.state.displayPlaces.map(place => (
                      <Marker
                        key={place.id}
                        text={place.name}
                        lat={place.geometry.location.lat}
                        lng={place.geometry.location.lng}
                        onClick={() => this.openInfo(place)}
                        className={place === this.state.currentStore ? 'active' : ''}
                      />
                    ))}
                  </GoogleMapReact>
                </div>
              </Grid>}

            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default StoreFinder;