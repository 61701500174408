import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Switch from '../../Switch';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip'

import Modal from 'react-modal';
import Button from '../../Button';
export class SwitchCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        type: { value: true },
      },
    };
  }

  inputChange = (name, value) => {
    let form = Object.assign(this.state.form);

    form[name].value = value;

    if (value === true) {
      this.setState({
        form: form,
        modalOpen: false
      });
    }
    else {
      this.setState({
        form: form,
        modalOpen: true
      });

    }
    
    this.forceUpdate();
  };

  setSwitch = (type) => {
    if(type) {
      this.inputChange('type', true)
    }

    this.setState({ modalOpen: false })
  }

  render() {
    const { form } = this.state;
    return (
      <div className={`pb-0 ${this.props.className ? this.props.className : ''}`}>
        <div className='fui-container'>
          <div className='fui-cards is-switch-card'>
            <div className='fui-card'>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <h4 className='mb-2'>
                    {this.props.title}
                    <Tooltip 
                      parentNode={null}
                      content={<img src={process.env.PUBLIC_URL + this.props.icon} className='align-middle ml-2' alt={this.props.icon} />}
                      trigger="click"
                      tooltip={this.props.tips} 
                    />
                  </h4>
                  <p>{this.props.description}</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className='search-bar'>
                    <div className='search-head'>
                      <div className='form-group'>
                        <Switch
                          on='開啟'
                          off='關閉'
                          name='type'
                          checked={form.type.value}
                          onChange={(e, checked) => this.inputChange('type', checked)}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5 className='align-center'>關閉漫遊通話設定？</h5>
            <p className='mt-0 align-center'>關閉漫遊通話設定後，將無法從國外撥打電話回台灣或其他國家，同時也會關閉國際直撥功能。你確定要關閉漫遊通話設定？</p>
            <div className="fui-buttons two-button justify-center">
              <Button btnStyle='primary' onClick={e => this.setSwitch(false)}>關閉設定</Button>
              <Button btnStyle='secondary' onClick={e => this.setSwitch(true)}>保持開啟</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

SwitchCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  switch: PropTypes.arrayOf(
    PropTypes.shape({
      on: PropTypes.string,
      off: PropTypes.string,
    })
  ),
};

export default SwitchCard;
