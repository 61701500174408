import React from 'react';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import NavAnchor from '../../components/partials/NavAnchor';

import DatePicker from "react-datepicker";
import TW from 'date-fns/locale/zh-TW';

import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Panel from '../../components/panel/Panel';
import PanelTab from '../../components/panelContent/PanelTab';

import Modal from 'react-modal';
import Link from '../../components/Link';
import FormBanner from '../../components/partials/banner/FormBanner';
// import LabelInput from '../../components/form/LabelInput';
import Select from '../../components/form/Select';
// import Select from 'react-select';
import Button from '../../components/Button';
import Checkbox from '../../components/form/Checkbox';

import LinkItem from '../../components/item/LinkItem';
import MenuAutocomplete from '../../components/partials/MenuAutocomplete';

import HotWord from '../../components/HotWordMulti';
import RoamingPlanRadio from '../../components/form/RoamingPlanRadio';

import * as Mock from '../../mock/RoamingPlan';

class Apply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      canSubmit: false,
      form: {
        select_country1: { value: '' },
        select_plan1: { value: '' },
        start_date1: { value: new Date() },
        start_hour1: { value: null },
        start_min1: { value: null },
        end_date1: { value: null },
        end_hour1: { value: null },
        end_min1: { value: null },

        select_area2: { value: '' },
        select_plan2: { value: '' },
        start_date2: { value: new Date() },
        start_hour2: { value: null },
        start_min2: { value: null },
        end_date2: { value: null },
        end_hour2: { value: null },
        end_min2: { value: null },

        select_plan3: { value: '' },
        start_date3: { value: new Date() },
        start_hour3: { value: null },
        start_min3: { value: null },
        end_date3: { value: null },
        end_hour3: { value: null },
        end_min3: { value: null },

        agree: { value: false, required: false },
      },

    }
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;

    this.setState({
      form: newForm
    })
  }

  openModal = () => {
    this.setState({
      modalOpen: true
    })
  }

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };

  submit = () => {

  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊方案申請/查詢'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="漫遊上網方案申請"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="form-section roaming-plan no-bg roaming-plan-apply">
          <div className="fui-container">
            <Formsy>
              <PanelTab
                tabs={{
                  name: 'panel-tab',
                  list: [
                    { name: 'panel-tab-1', label: '單國漫遊' },
                    { name: 'panel-tab-2', label: '多國漫遊' },
                    { name: 'panel-tab-3', label: '機上漫遊' },
                  ],
                }}>
                <Panel>
                  <h4 className="mt-0">請選擇目的地</h4>

                  <Grid container>
                    <Grid item xs={12} md={8}>
                      <MenuAutocomplete
                        {...Mock.menu}
                        {...Mock.keyword}
                      />
                    </Grid>
                  </Grid>

                  <div className="result-empty">
                    <div className="mt-3 is-text-gray100 text">
                      該國家目前無漫遊上網優惠，僅提供一般漫遊上網費率，如需申請或了解更多資訊，可撥空前往<Link to='#' className='hover-underline-link'>遠傳門市</Link>、撥打客服電話 (國內) <a href='tel:0800058885' className="hover-underline-link" target="_blank">0800-058-885</a> ; (國外) <a href='tel:+886936010888' className="hover-underline-link" target="_blank">+886936010888</a>、或也可透過<Link to='#' className='hover-underline-link'>客服即時通</Link>查詢唷！
                    </div>
                  </div>

                  <div className="result">
                    <h4 className="mt-5">請選擇漫遊上網方案</h4>
                    <RoamingPlanRadio {...Mock.plan}
                      name='select_plan'
                      value={this.state.form.select_plan1.value}
                      selected={this.state.form.select_plan1.value}
                      getSelected={e => this.onChange('select_plan1', e)}
                      openModal={this.openModal}
                    />

                    <h4 className="">請選擇時間</h4>
                    <div className="form-group">
                      <label className="required">生效日期（台灣時間）</label>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={5}>
                          <DatePicker
                            dateFormat="Y/MM/dd"
                            lang="zh-TW"
                            minDate={new Date()}
                            selected={this.state.form.start_date1.value}
                            onChange={date => this.onChange('start_date1', date)}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                          <Select
                            name="start_hour1"
                            required={true}
                            label={"時"}
                            value={this.state.form.start_hour1.value}
                            options={[
                              { text: '01時', value: '01' },
                              { text: '02時', value: '02' },
                              { text: '03時', value: '03' },
                              { text: '04時', value: '04' },
                              { text: '05時', value: '05' },
                              { text: '06時', value: '06' },
                              { text: '07時', value: '07' },
                              { text: '08時', value: '08' },
                              { text: '09時', value: '09' },
                              { text: '10時', value: '10' },
                              { text: '11時', value: '11' },
                              { text: '12時', value: '12' }
                            ]}
                            onChange={this.onChange} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                          <Select
                            name="start_min1"
                            required={true}
                            label={"分"}
                            value={this.state.form.start_min1.value}
                            options={[
                              { text: '00分', value: '00' },
                              { text: '01分', value: '01' },
                              { text: '02分', value: '02' },
                              { text: '03分', value: '03' },
                              { text: '04分', value: '04' },
                              { text: '05分', value: '05' },
                              { text: '06分', value: '06' },
                              { text: '07分', value: '07' },
                              { text: '08分', value: '08' },
                              { text: '09分', value: '09' },
                              { text: '10分', value: '10' },
                              { text: '11分', value: '11' },
                              { text: '12分', value: '12' },
                              { text: '13分', value: '13' },
                              { text: '14分', value: '14' },
                              { text: '15分', value: '15' },
                              { text: '16分', value: '16' },
                              { text: '17分', value: '17' },
                              { text: '18分', value: '18' },
                              { text: '19分', value: '19' },
                              { text: '20分', value: '20' }
                            ]}
                            onChange={this.onChange} />
                        </Grid>
                      </Grid>
                      <div className="mt-1 is-text-darkgray50 body-2">生效日期設定僅限 2 個月內</div>
                    </div>

                    <div className="form-group">
                      <label className="required">結束日期（台灣時間）</label>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={5}>
                          <DatePicker
                            dateFormat="Y/MM/dd"
                            lang="zh-TW"
                            minDate={new Date()}
                            placeholderText="請選擇日期"
                            disabled={true}
                            selected={this.state.form.end_date1.value}
                            onChange={date => this.onChange('end_date1', date)}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                          <Select
                            name="end_hour1"
                            required={true}
                            label={"時"}
                            value={this.state.form.end_hour1.value}
                            disabled={true}
                            options={[
                              { text: '01時', value: '01' },
                              { text: '02時', value: '02' },
                              { text: '03時', value: '03' },
                              { text: '04時', value: '04' },
                              { text: '05時', value: '05' },
                              { text: '06時', value: '06' },
                              { text: '07時', value: '07' },
                              { text: '08時', value: '08' },
                              { text: '09時', value: '09' },
                              { text: '10時', value: '10' },
                              { text: '11時', value: '11' },
                              { text: '12時', value: '12' }
                            ]}
                            onChange={this.onChange} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                          <Select
                            name="end_min1"
                            required={true}
                            label={"分"}
                            value={this.state.form.end_min1.value}
                            disabled={true}
                            options={[
                              { text: '00分', value: '00' },
                              { text: '01分', value: '01' },
                              { text: '02分', value: '02' },
                              { text: '03分', value: '03' },
                              { text: '04分', value: '04' },
                              { text: '05分', value: '05' },
                              { text: '06分', value: '06' },
                              { text: '07分', value: '07' },
                              { text: '08分', value: '08' },
                              { text: '09分', value: '09' },
                              { text: '10分', value: '10' },
                              { text: '11分', value: '11' },
                              { text: '12分', value: '12' },
                              { text: '13分', value: '13' },
                              { text: '14分', value: '14' },
                              { text: '15分', value: '15' },
                              { text: '16分', value: '16' },
                              { text: '17分', value: '17' },
                              { text: '18分', value: '18' },
                              { text: '19分', value: '19' },
                              { text: '20分', value: '20' }
                            ]}
                            onChange={this.onChange} />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Panel>

                <Panel>
                  <h4 className="mt-0 mb-4">請選擇目的地</h4>

                  <div className="mb-lg-2">依地區搜尋：</div>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <HotWord {...Mock.hot}
                        name="hotword"
                        // hotword={this.state.form.hotword}
                        // value={this.state.form.hotword}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>

                  <h4 className="">請選擇漫遊上網方案</h4>
                  <RoamingPlanRadio {...Mock.plan}
                    name='select_plan2'
                    value={this.state.form.select_plan2.value}
                    selected={this.state.form.select_plan2.value}
                    getSelected={e => this.onChange('select_plan2', e)}
                    openModal={this.openModal}
                  />

                  <h4 className="">請選擇時間</h4>
                  <div className="form-group">
                    <label className="required">生效日期（台灣時間）</label>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={5}>
                        <DatePicker
                          dateFormat="Y/MM/dd"
                          lang="zh-TW"
                          minDate={new Date()}
                          selected={this.state.form.start_date2.value}
                          onChange={date => this.onChange('start_date2', date)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2}>
                        <Select
                          name="start_hour2"
                          required={true}
                          label={"時"}
                          value={this.state.form.start_hour2.value}
                          options={[
                            { text: '01時', value: '01' },
                            { text: '02時', value: '02' },
                            { text: '03時', value: '03' },
                            { text: '04時', value: '04' },
                            { text: '05時', value: '05' },
                            { text: '06時', value: '06' },
                            { text: '07時', value: '07' },
                            { text: '08時', value: '08' },
                            { text: '09時', value: '09' },
                            { text: '10時', value: '10' },
                            { text: '11時', value: '11' },
                            { text: '12時', value: '12' }
                          ]}
                          onChange={this.onChange} />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2}>
                        <Select
                          name="start_min2"
                          required={true}
                          label={"分"}
                          value={this.state.form.start_min2.value}
                          options={[
                            { text: '00分', value: '00' },
                            { text: '01分', value: '01' },
                            { text: '02分', value: '02' },
                            { text: '03分', value: '03' },
                            { text: '04分', value: '04' },
                            { text: '05分', value: '05' },
                            { text: '06分', value: '06' },
                            { text: '07分', value: '07' },
                            { text: '08分', value: '08' },
                            { text: '09分', value: '09' },
                            { text: '10分', value: '10' },
                            { text: '11分', value: '11' },
                            { text: '12分', value: '12' },
                            { text: '13分', value: '13' },
                            { text: '14分', value: '14' },
                            { text: '15分', value: '15' },
                            { text: '16分', value: '16' },
                            { text: '17分', value: '17' },
                            { text: '18分', value: '18' },
                            { text: '19分', value: '19' },
                            { text: '20分', value: '20' }
                          ]}
                          onChange={this.onChange} />
                      </Grid>
                    </Grid>
                    <div className="mt-1 is-text-darkgray50 body-2">生效日期設定僅限 2 個月內</div>
                  </div>

                  <div className="form-group">
                    <label className="required">結束日期（台灣時間）</label>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={5}>
                        <DatePicker
                          dateFormat="Y/MM/dd"
                          lang="zh-TW"
                          minDate={new Date()}
                          placeholderText="請選擇日期"
                          disabled={true}
                          selected={this.state.form.end_date2.value}
                          onChange={date => this.onChange('end_date2', date)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2}>
                        <Select
                          name="end_hour2"
                          required={true}
                          label={"時"}
                          value={this.state.form.end_hour2.value}
                          disabled={true}
                          options={[
                            { text: '01時', value: '01' },
                            { text: '02時', value: '02' },
                            { text: '03時', value: '03' },
                            { text: '04時', value: '04' },
                            { text: '05時', value: '05' },
                            { text: '06時', value: '06' },
                            { text: '07時', value: '07' },
                            { text: '08時', value: '08' },
                            { text: '09時', value: '09' },
                            { text: '10時', value: '10' },
                            { text: '11時', value: '11' },
                            { text: '12時', value: '12' }
                          ]}
                          onChange={this.onChange} />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2}>
                        <Select
                          name="end_min2"
                          required={true}
                          label={"分"}
                          value={this.state.form.end_min2.value}
                          disabled={true}
                          options={[
                            { text: '00分', value: '00' },
                            { text: '01分', value: '01' },
                            { text: '02分', value: '02' },
                            { text: '03分', value: '03' },
                            { text: '04分', value: '04' },
                            { text: '05分', value: '05' },
                            { text: '06分', value: '06' },
                            { text: '07分', value: '07' },
                            { text: '08分', value: '08' },
                            { text: '09分', value: '09' },
                            { text: '10分', value: '10' },
                            { text: '11分', value: '11' },
                            { text: '12分', value: '12' },
                            { text: '13分', value: '13' },
                            { text: '14分', value: '14' },
                            { text: '15分', value: '15' },
                            { text: '16分', value: '16' },
                            { text: '17分', value: '17' },
                            { text: '18分', value: '18' },
                            { text: '19分', value: '19' },
                            { text: '20分', value: '20' }
                          ]}
                          onChange={this.onChange} />
                      </Grid>
                    </Grid>
                  </div>
                </Panel>

                <Panel>
                  <h4 className="mt-0">請選擇漫遊上網方案</h4>
                  <RoamingPlanRadio {...Mock.flightRoamingPlan}
                    name='select_plan'
                    value={this.state.form.select_plan3.value}
                    selected={this.state.form.select_plan3.value}
                    getSelected={e => this.onChange('select_plan3', e)}
                    openModal={this.openModal}
                  />

                  <h4 className="">請選擇時間</h4>
                  <div className="form-group">
                    <label className="required">生效日期（台灣時間）</label>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={5}>
                        <DatePicker
                          dateFormat="Y/MM/dd"
                          lang="zh-TW"
                          minDate={new Date()}
                          selected={this.state.form.start_date3.value}
                          onChange={date => this.onChange('start_date3', date)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2}>
                        <Select
                          name="start_hour3"
                          required={true}
                          label={"時"}
                          value={this.state.form.start_hour3.value}
                          options={[
                            { text: '01時', value: '01' },
                            { text: '02時', value: '02' },
                            { text: '03時', value: '03' },
                            { text: '04時', value: '04' },
                            { text: '05時', value: '05' },
                            { text: '06時', value: '06' },
                            { text: '07時', value: '07' },
                            { text: '08時', value: '08' },
                            { text: '09時', value: '09' },
                            { text: '10時', value: '10' },
                            { text: '11時', value: '11' },
                            { text: '12時', value: '12' }
                          ]}
                          onChange={this.onChange} />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2}>
                        <Select
                          name="start_min3"
                          required={true}
                          label={"分"}
                          value={this.state.form.start_min3.value}
                          options={[
                            { text: '00分', value: '00' },
                            { text: '01分', value: '01' },
                            { text: '02分', value: '02' },
                            { text: '03分', value: '03' },
                            { text: '04分', value: '04' },
                            { text: '05分', value: '05' },
                            { text: '06分', value: '06' },
                            { text: '07分', value: '07' },
                            { text: '08分', value: '08' },
                            { text: '09分', value: '09' },
                            { text: '10分', value: '10' },
                            { text: '11分', value: '11' },
                            { text: '12分', value: '12' },
                            { text: '13分', value: '13' },
                            { text: '14分', value: '14' },
                            { text: '15分', value: '15' },
                            { text: '16分', value: '16' },
                            { text: '17分', value: '17' },
                            { text: '18分', value: '18' },
                            { text: '19分', value: '19' },
                            { text: '20分', value: '20' }
                          ]}
                          onChange={this.onChange} />
                      </Grid>
                    </Grid>
                    <div className="mt-1 is-text-darkgray50 body-2">生效日期設定僅限 2 個月內</div>
                  </div>

                  <div className="form-group">
                    <label className="required">結束日期（台灣時間）</label>
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={5}>
                        <DatePicker
                          dateFormat="Y/MM/dd"
                          lang="zh-TW"
                          minDate={new Date()}
                          placeholderText="請選擇日期"
                          disabled={true}
                          selected={this.state.form.end_date3.value}
                          onChange={date => this.onChange('end_date3', date)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2}>
                        <Select
                          name="end_hour3"
                          required={true}
                          label={"時"}
                          value={this.state.form.end_hour3.value}
                          disabled={true}
                          options={[
                            { text: '01時', value: '01' },
                            { text: '02時', value: '02' },
                            { text: '03時', value: '03' },
                            { text: '04時', value: '04' },
                            { text: '05時', value: '05' },
                            { text: '06時', value: '06' },
                            { text: '07時', value: '07' },
                            { text: '08時', value: '08' },
                            { text: '09時', value: '09' },
                            { text: '10時', value: '10' },
                            { text: '11時', value: '11' },
                            { text: '12時', value: '12' }
                          ]}
                          onChange={this.onChange} />
                      </Grid>
                      <Grid item xs={6} sm={6} md={2}>
                        <Select
                          name="end_min3"
                          required={true}
                          label={"分"}
                          value={this.state.form.end_min3.value}
                          disabled={true}
                          options={[
                            { text: '00分', value: '00' },
                            { text: '01分', value: '01' },
                            { text: '02分', value: '02' },
                            { text: '03分', value: '03' },
                            { text: '04分', value: '04' },
                            { text: '05分', value: '05' },
                            { text: '06分', value: '06' },
                            { text: '07分', value: '07' },
                            { text: '08分', value: '08' },
                            { text: '09分', value: '09' },
                            { text: '10分', value: '10' },
                            { text: '11分', value: '11' },
                            { text: '12分', value: '12' },
                            { text: '13分', value: '13' },
                            { text: '14分', value: '14' },
                            { text: '15分', value: '15' },
                            { text: '16分', value: '16' },
                            { text: '17分', value: '17' },
                            { text: '18分', value: '18' },
                            { text: '19分', value: '19' },
                            { text: '20分', value: '20' }
                          ]}
                          onChange={this.onChange} />
                      </Grid>
                    </Grid>
                  </div>
                </Panel>
              </PanelTab>

              <div className="mt-3 is-text-gray100 text">※找不到想要的國家或方案嗎? 可撥空前往<Link to='#' className='hover-underline-link'>遠傳門市</Link>、撥打客服電話 (國內) <a href='tel:0800058885' className="hover-underline-link" target="_blank">0800-058-885</a> ; (國外) <a href='tel:+886936010888' className="hover-underline-link" target="_blank">+886936010888</a>、或也可透過<Link to='#' className='hover-underline-link'>客服即時通</Link>查詢唷！</div>


              <Checkbox
                name='agree'
                label='我已閱讀並了解欲申請方案的費率及詳細說明'
                value='1'
                checked={this.state.form.agree.value}
                onChange={this.onChange}
              />

              <div className="button-group">
                <div className="form-group two-buttons">
                  <Button btnStyle='primary' size='medium' disabled={!this.state.form.agree.value}>下一步</Button>
                  <Button btnStyle='secondary' size='medium'>取消</Button>
                </div>
              </div>
            </Formsy>
          </div>
        </section>

        <SectionCollapseInfo
          title="貼心小叮嚀"
          content={`
            若您的門號狀態為：欠費限話、暫停、損壞瑕疵補卡等情形，請至遠傳門市或來電客服中心處理。
          `}
        />


        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5 className='align-center'>方案詳細說明</h5>
            <div className="scrollable-modal-body is-bg-white">
              <p className='align-left mt-0'>上網費率</p>
              <div className="fui-table-response mb-2 mb-lg-4">
                <table className="modal-roaming-country-table">
                  <thead>
                    <tr>
                      <th>用量</th>
                      <th>上網費率</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>大於（含） 2MB</td>
                      <td>$99 / 天</td>
                    </tr>
                    <tr>
                      <td>小於 2MB</td>
                      <td>$99 / 天</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="align-left mb-3 notice">
                <p className='is-text-medium mb-1'>注意事項</p>
                <p className=''>1.本優惠費率以到達當地開始上網起，連續24小時為一日計價。<br />
              2.適用對象: 至國外漫遊登錄在KT/SKT之遠傳月租型客戶。出國前，請致電客服中心開啟/確認國際數據漫遊服務。手機直撥123/888或 MVPN用戶99888 (免費)；免付費客服專線0800-058-885。市話撥449-5888(市內計費)，在國外時請電+886936010888。</p>
              </div>

              <div className="fui-buttons justify-center">
                <Button btnStyle='primary' className='' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
              </div>



              <h5 className='align-center'>多國家上網吃到飽</h5>
              <p className='align-left mt-0'>適用國家及費率</p>
              <div className="fui-table-response mb-2 mb-lg-4">
                <table className="modal-roaming-country-table">
                  <thead>
                    <tr>
                      <th>國家</th>
                      <th>上網費率</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>中國、香港、澳門</td>
                      <td>$ 0 / 天</td>
                    </tr>
                    <tr>
                      <td>韓國、新加坡、泰國、印尼、菲律賓</td>
                      <td>$99 / 天</td>
                    </tr>
                    <tr>
                      <td>馬來西亞、日本、關島、塞班、天寧島、羅塔島、美國、加拿大、波多黎各</td>
                      <td>$299 / 天</td>
                    </tr>
                    <tr>
                      <td>柬埔寨、越南、澳洲、紐西蘭、法國、以色列、竂國</td>
                      <td>$399 / 天</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="align-left mb-3 notice">
                <p className='is-text-medium mb-1'>貼心提醒</p>
                <p className=''>1. 優惠費率以一網一日計價，用戶若於一日內漫遊到多個國家/地區的網路，會依網數進行一日計費 (例如：用戶若於一天內漫遊至第二個國家/地區的網路，將分別依二網用量各自一日計價，以此類推)。<br />
              2. 本優惠費率以到達當地開始上網起，連續24小時為一日計價。</p>
              </div>

              <div className="fui-buttons justify-center">
                <Button btnStyle='primary' className='' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
              </div>






              <h5 className='align-center'>全球定量包 1GB</h5>
              <p className='align-left is-text-medium mt-0'>適用國家</p>

              <div className="fui-table-response mb-2 mb-lg-4">
                <table className="modal-roaming-package-table">
                  <tbody>
                    <tr>
                      <th>歐洲（39 國）</th>
                      <td>阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國、阿爾巴尼亞、奧地利、亞賽拜然、比利時、保加利亞、克羅埃西亞、捷克、丹麥、愛沙尼亞、芬蘭、法國</td>
                    </tr>
                    <tr>
                      <th>美洲（8 國）</th>
                      <td>阿根廷、貝里斯、加拿大、智利、波多黎各、美屬維京群島（處女島）、美國、巴拿馬</td>
                    </tr>
                    <tr>
                      <th>大洋洲（2 國）</th>
                      <td>澳洲、紐西蘭</td>
                    </tr>
                    <tr>
                      <th>非洲（6 國）</th>
                      <td>剛果民主共和國、迦納、莫三比克、南非、坦尚尼亞、埃及</td>
                    </tr>
                    <tr>
                      <th>亞洲（5 國）</th>
                      <td>印度、哈蕯克、蒙古、斯里蘭卡、土耳其</td>
                    </tr>
                    <tr>
                      <th>中東（5 國）</th>
                      <td>巴基斯坦、以色列、科威特、卡達、沙烏地阿拉伯</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="fui-buttons justify-center">
                <Button btnStyle='primary' className='' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
              </div>






              <h5 className='align-center'>計量7日/15日型數據漫遊優惠費率</h5>
              <p className='align-left is-text-medium mt-0'>上網費率</p>

              <div className="fui-table-response mb-2 mb-lg-4">
                <table className="modal-roaming-country-table">
                  <thead>
                    <tr>
                      <th>用量</th>
                      <th>上網費率</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{'<'} 20MB</td>
                      <td>$299</td>
                    </tr>
                    <tr>
                      <td>20 ~ 50MB(含)</td>
                      <td>$699</td>
                    </tr>
                    <tr>
                      <td>50 ~ 80MB(含)</td>
                      <td>$1,099</td>
                    </tr>
                    <tr>
                      <td>> 80MB</td>
                      <td>$1,099+0.03/KB* (實際用量-80MB)</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className='align-left is-text-medium mb-1'>申請管道</p>
              <div className='fui-list align-left'>
                <div className='fui-item is-darkgray50'>
                  <div className='prefix'>
                    <div className='bulleted-dot'></div>
                  </div>
                  <div className='content'>線上申請：登入遠傳電信會員，透過<Link to='#' className='is-text-accent'>遠傳官網</Link>申請</div>
                </div>
                <div className='fui-item is-darkgray50'>
                  <div className='prefix'>
                    <div className='bulleted-dot'></div>
                  </div>
                  <div className='content'>APP 申請：登入遠傳電信會員，透過<Link to='#' className='is-text-accent'>遠傳行動 APP</Link>申請</div>
                </div>
                <div className='fui-item is-darkgray50'>
                  <div className='prefix'>
                    <div className='bulleted-dot'></div>
                  </div>
                  <div className='content'>電話申請：國內手機直撥 <Link to='tel:123' target="_blank" className='is-text-accent'>123/888</Link>；國外手機直撥<Link to='tel:+886936010888' target="_blank" className='is-text-accent'>+886936010888</Link></div>
                </div>
                <div className='fui-item is-darkgray50'>
                  <div className='prefix'>
                    <div className='bulleted-dot'></div>
                  </div>
                  <div className='content'>門市申請：親自至 <Link to='#' className='is-text-accent'>遠傳門市申請</Link></div>
                </div>
              </div>
              <div className="align-left mb-3 notice">
                <p className='is-text-medium mb-1'>注意事項</p>
                <p>1.『亞洲輕量包暢用型1GB』於用戶申辦並收到優惠生效簡訊後連續10日(連續240小時有效)享1GB優惠，若於到期日前上網優惠傳輸量已用完，將無法於該優惠適用國家繼續使用上網功能。<br />
              2. 加價購方式：當上網傳輸使用到達特定用量時，系統會主動發送簡訊通知，回傳簡訊即可加購上網用量。加購的用量會在原方案結束後自動生效，效期自生效後，重新算10天。<br />
              3. 10日到期後方案終止，未用完之傳輸量將歸零且不得要求遞延、折現或為其他主張。<br />
              4. 亞洲輕量包暢用型500MB為加價購產品，需購買亞洲輕量包暢用型1GB後才能進行加購。<br />
              5. 若用戶門號安裝「取消雙向簡訊加購傳輸量服務」，於國外使用亞洲輕量包暢用型，亦無法透過70% & 98%雙向簡訊進行用量加購。 </p>
              </div>
              <div className="fui-buttons justify-center">
                <Button btnStyle='primary' className='' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
              </div>




              <h5 className='align-center'>亞洲輕量包</h5>
              <p className='align-left is-text-medium mt-0'>上網費率</p>

              <div className="fui-table-response mb-2 mb-lg-4">
                <table className="modal-roaming-country-table">
                  <thead>
                    <tr>
                      <th>用量</th>
                      <th>上網費率</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1GB</td>
                      <td>$199</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className='align-left is-text-medium mb-1'>申請管道</p>
              <div className='fui-list align-left'>
                <div className='fui-item is-darkgray50'>
                  <div className='prefix'>
                    <div className='bulleted-dot'></div>
                  </div>
                  <div className='content'>線上申請：登入遠傳電信會員，透過<Link to='#' className='is-text-accent'>遠傳官網</Link>申請</div>
                </div>
                <div className='fui-item is-darkgray50'>
                  <div className='prefix'>
                    <div className='bulleted-dot'></div>
                  </div>
                  <div className='content'>APP 申請：登入遠傳電信會員，透過<Link to='#' className='is-text-accent'>遠傳行動 APP</Link>申請</div>
                </div>
                <div className='fui-item is-darkgray50'>
                  <div className='prefix'>
                    <div className='bulleted-dot'></div>
                  </div>
                  <div className='content'>電話申請：國內手機直撥 <Link to='tel:123' target="_blank" className='is-text-accent'>123/888</Link>；國外手機直撥<Link to='tel:+886936010888' target="_blank" className='is-text-accent'>+886936010888</Link></div>
                </div>
                <div className='fui-item is-darkgray50'>
                  <div className='prefix'>
                    <div className='bulleted-dot'></div>
                  </div>
                  <div className='content'>門市申請：親自至 <Link to='tel:123' className='is-text-accent'>遠傳門市申請</Link></div>
                </div>
              </div>
              <div className="align-left mb-3 notice">
                <p className='is-text-medium mb-1'>注意事項</p>
                <p>
                  1.『亞洲輕量包暢用型1GB』於用戶申辦並收到優惠生效簡訊後連續10日(連續240小時有效)享1GB優惠，若於到期日前上網優惠傳輸量已用完，將無法於該優惠適用國家繼續使用上網功能。<br />
              2. 加價購方式：當上網傳輸使用到達特定用量時，系統會主動發送簡訊通知，回傳簡訊即可加購上網用量。加購的用量會在原方案結束後自動生效，效期自生效後，重新算10天。<br />
              3. 10日到期後方案終止，未用完之傳輸量將歸零且不得要求遞延、折現或為其他主張。<br />
              4. 亞洲輕量包暢用型500MB為加價購產品，需購買亞洲輕量包暢用型1GB後才能進行加購。<br />
              5. 若用戶門號安裝「取消雙向簡訊加購傳輸量服務」，於國外使用亞洲輕量包暢用型，亦無法透過70% & 98%雙向簡訊進行用量加購。
              </p>
              </div>
              <div className="fui-buttons justify-center">
                <Button btnStyle='primary' className='' onClick={() => { this.setState({ modalOpen: false }) }}>我瞭解了</Button>
              </div>
            </div>
          </div>
        </Modal>

      </main>
    )
  }
}

export default Apply;