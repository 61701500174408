import React from 'react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';



class BillNotifyRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emptyTable: {},
      table: {
        head: ['日期', '2020/06/02 11:33:02', '2020/06/02 11:33:02', '2020/06/02 11:12:02'],
        content: [
          ['異動事項'], ['註冊電子Email帳單'], ['出帳簡訊通知設定'], ['取消電子Email帳單服務']
        ]
      },
      detail: [
        {
          title: '日期',
          content: '異動事項'
        },
        {
          title: '2020/06/02 11:33:02',
          content: '註冊電子Email帳單'
        },
        {
          title: '2020/06/02 11:33:02',
          content: '出帳簡訊通知設定'
        },
        {
          title: '2020/06/02 11:12:02',
          content: '取消電子Email帳單服務'
        }
      ],
      currentTable: {}
    }
  }

  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="近六個月異動記錄"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="usage-rate mt-7 no-bg">
          <div className="fui-container">
            <div className="fui-segments no-shadow is-bg-initial">
              <h4 className="is-text-darkgray50 mb-7">
                可查詢您最近6個月的繳費提醒的變更記錄
              </h4>
              <div className="paper mt-md-4 mt-2 mb-0 pb-4">
                {Object.keys(this.state.currentTable).length === 0 ? null : <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-4 mb-2">
                  <h4 className='m-0'>您最近六個月加購記錄如下</h4>
                </div>}
                {Object.keys(this.state.currentTable).length === 0 ? <div>
                  <div className="body is-text-darkgray50">
                    查無繳費提醒的變更記錄，謝謝。
                  </div>
                </div> : <table className="text-wrap odd bill-table table-fixed">
                    <tbody>
                      {this.state.detail.map((row, i) => {
                        if (i == 0) {
                          return (
                            <tr key={row.title + i}>
                              <th className="align-center is-text-medium">{row.title}</th>
                              <th className="align-center is-text-medium">{row.content}</th>
                            </tr>
                          )
                        } else return (
                          <tr key={row.title + i}>
                            <td className="align-center is-text-medium">{row.title}</td>
                            <td className="align-center">{row.content}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>}
              </div>
            </div>
          </div>
        </section>
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ currentTable: this.state.table })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.17.5_帳單與用量_電子帳單變更_有記錄</Button>
              <Button onClick={() => this.setState({ currentTable: this.state.emptyTable })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.1.17.4_帳單與用量_電子帳單變更_無異動記錄</Button>
            </div>
          </div>
        </div>
      </main >
    )
  }
}

export default BillNotifyRecord;