import React, { Component } from 'react'
import PropTypes from 'prop-types';
class EventInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <section
        className="event-info pt-md-4 pt-1 pb-3"
        style={this.props.topBg ? { backgroundImage: `url(${this.props.topBg})` } : null}
      >
        <div className="fui-container">
          <div className="paper mt-4 mb-0 pb-md-4 pb-1">
            {this.props.children}
          </div>
        </div>
        <div
          className='bottom-bg'
          style={this.props.bottomBg ? { backgroundImage: `url(${this.props.bottomBg})` } : null}
        />
      </section>
    );
  }
}
EventInfo.propTypes = {
  topBg: PropTypes.string,
  bottomBg: PropTypes.string,
};
export default EventInfo;