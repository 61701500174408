import React from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class CheckboxButtons extends React.Component {
  constructor(props) {
    super(props);
    // console.log(`props: `, this.props);
    this.state = {
      required: this.props.required || false,
      options: this.props.options,
      defaultChecked: this.props.default ? this.props.default.split(/,/g) : [],
      selected: this.props.default ? this.props.default.split(/,/g) : [],
      checkKey: this.props.checkKey
    };
  }

  componentDidMount() {
    if (this.props.default) {
      this.props.setValue(this.props.default);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.default !== this.props.default || prevProps.checkKey !== this.props.checkKey) {
      this.setState({
        defaultChecked: this.props.default ? this.props.default.split(/,/g) : [],
        selected: [],
      });
      this.forceUpdate();
    }
  }

  handleChange = event => {
    let selected = Object.assign(this.state.selected);
    let val = event.currentTarget.value;
    if (event.currentTarget.checked) {
      selected.push(val);
    } else {
      selected.splice(selected.indexOf(val), 1);
    }
    // debugger
    this.setState({ selected: selected });
    this.props.setValue(selected.length ? selected.toString() : null);
    this.props.onChange(this.props.name, selected.toString());
  };

  getDefaultCheck = (option) => {
    let isCheck = this.state.defaultChecked.indexOf(option.value) > -1;
    // console.log(this.state.defaultChecked, option, isCheck);
    return isCheck;
  }

  render() {
    const errorMessage = this.props.getErrorMessage();

    return (
      <div className='form-group'>
        <label className={this.state.required ? 'is-required' : ''}>{this.props.label}</label>
        <div className='checkbox-buttons'>
          {this.props.options.map((opt, i) => (
            <label className='checkbox' key={`checkbox-buttons-${this.props.name}-${i}-${this.props.checkKey}`}>
              <input
                type='checkbox'
                name={this.props.name}
                value={opt.value}
                onChange={e => this.handleChange(e)}
                defaultChecked={this.getDefaultCheck(opt)}
              />
              <div className='content'>{opt.label}</div>
            </label>
          ))}
        </div>
        <div className='error-message'>{errorMessage}</div>
      </div>
    );
  }
}

CheckboxButtons.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  default: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
};

export default withFormsy(CheckboxButtons);
