import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';
import Modal from 'react-modal';

import FormBanner from '../../components/partials/banner/FormBanner';
import LabelInput from '../../components/form/LabelInput';
import Select from '../../components/form/Select';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import ParkingCheckAreaSection from '../../components/form/ParkingCheckAreaSection';
import Checkbox from '../../components/form/Checkbox';
import Button from '../../components/Button';

const areaList = [
  {label: '台中市路邊停車', description: '', value: '台中市路邊停車', status: ''},
  {label: '新竹縣路邊停車', description: '', value: '新竹縣路邊停車', status: ''},
  {label: '彰化縣路邊停車', description: '', value: '彰化縣路邊停車', status: ''},
  {label: '台南市路邊停車', description: '', value: '台南市路邊停車', status: ''},
  {label: '高雄市路邊停車', description: '', value: '高雄市路邊停車', status: ''},
  {label: '嘉義市路邊停車', description: '申請代收日 2017/06/10', value: '嘉義市路邊停車', status: 'finish'},
  {label: '新北市路邊停車', description: '申請代收日 2017/06/10', value: '新北市路邊停車', status: 'progress'},
  {label: '宜蘭市路邊停車', description: '', value: '宜蘭市路邊停車', status: ''},
  {label: '台南市路邊停車', description: '申請代收日 2017/06/10', value: '台南市路邊停車', status: 'progress'},
  {label: '新竹市路邊停車', description: '申請代收日 2017/06/10', value: '新竹市路邊停車', status: 'progress'},
  {label: '高雄市公有停車場', description: '申請代收日 2017/06/10', value: '高雄市公有停車場', status: 'progress'},
  {label: '台北市公有停車場', description: '申請代收日 2017/06/10', value: '台北市公有停車場', status: 'progress'},
]

const newAreaList = [
  {label: '台中市路邊停車', description: '', value: '台中市路邊停車', status: ''},
  {label: '新竹縣路邊停車', description: '', value: '新竹縣路邊停車', status: ''},
  {label: '彰化縣路邊停車', description: '', value: '彰化縣路邊停車', status: ''},
  {label: '台南市路邊停車', description: '', value: '台南市路邊停車', status: ''},
  {label: '高雄市路邊停車', description: '', value: '高雄市路邊停車', status: ''},
  {label: '嘉義市路邊停車', description: '', value: '嘉義市路邊停車', status: ''},
  {label: '新北市路邊停車', description: '', value: '新北市路邊停車', status: ''},
  {label: '宜蘭市路邊停車', description: '', value: '宜蘭市路邊停車', status: ''},
  {label: '台南市路邊停車', description: '', value: '台南市路邊停車', status: ''},
  {label: '新竹市路邊停車', description: '', value: '新竹市路邊停車', status: ''},
  {label: '高雄市公有停車場', description: '', value: '高雄市公有停車場', status: ''},
  {label: '台北市公有停車場', description: '', value: '台北市公有停車場', status: ''},
]

class parkingfeeInitPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      modalOpen: false,
      removeIndex: 0,
      selectedArea: {
        index: 0,
        value: ''
      },
      form: {
        agree: { value: false },
        new: [
          {value: false}
        ],
        car_type: [
          {value: '汽車'}
        ],
        car_number_1: [
          {value: 'ABC'}
        ],
        car_number_2: [
          {value: '5678'}
        ],
        area: [
          {value: '嘉義市路邊停車'}
        ],
        remove: [
          {value: false}
        ]
      },
      showArea: false
    };
  };

  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  }

  onChange = (n, v, i) => {
    console.log(n, v, i)
    let form = Object.assign(this.state.form);
    if (!i && i!==0) {
      form[n].value = v
    } else {
      form[n][i].value = v
    }

    this.setState({
      form: form
    })
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  conifrmRemoveCarInfo = () => {
    this.setState({
      modalOpen: true
    });
  }

  addCarInfo = () => {
    let form = Object.assign(this.state.form);
    form.car_type.push({value: ''})
    form.car_number_1.push({value: ''})
    form.car_number_2.push({value: ''})
    form.area.push({value: ''})
    form.new.push({value: true})
    form.remove.push({value: false})

    this.setState({
      form: form
    })
  }

  setArea = (index) => {
    this.setState({
      selectedArea: {
        index: 0,
        value: ''
      }
    })

    this.setState({
      showArea: true,
      selectedArea: {
        index: index,
        value: this.state.form.area[index].value
      }
    })

    this.forceUpdate()
  }

  closeArea = () => {
    this.setState({
      showArea: false,
      selectedArea: {
        index: 0,
        value: ''
      }
    })
  }

  resetArea = () => {
    let form = Object.assign(this.state.form);
    form.area[this.state.selectedArea.index].value = this.state.selectedArea.value;
    
    this.setState({
      form: form,
      showArea: false
    })
  }

  beforeSubmit = () => {
    if(this.state.form.remove.find(item => item.value===true)) {
      this.conifrmRemoveCarInfo();
    } else {
      this.submit();
    }
  }

  submit = () => {
    this.closeModal();
    console.info("SUBMIT")
  }

  render() {
    const { form, selectedArea } = this.state;

    return (
      <main>
        
        <NavAnchor 
          pageTitle='交易/異動記錄'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="停車費代收設定"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />

        <section className={`page-body ${this.state.showArea ? 'd-none' : 'd-block'}`}>
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
            <div className="fui-container">
              <div className='paper'>
                <Grid container>
                  <Grid item xs={7} md={7}>
                    <h4 className="mb-2 mt-0">您目前的代收狀態</h4>
                  </Grid>
                  <Grid item xs={5} md={5} className='align-right'>
                    <Button btnStyle='secondary' size='small' className='is-text-sm'>代收記錄查詢</Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="rate-plan-box">
                      <h5>申請代收門號</h5>
                      <h2>0916814747</h2>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="rate-plan-box">
                      <h5>服務狀態</h5>
                      <h2>尚未申請</h2>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className='paper'>
                <Grid container>
                  <Grid item xs={7} md={7}>
                    <h4 className="mb-2 mt-0">輸入代收車輛資料</h4>
                  </Grid>
                  <Grid item xs={5} md={5} className='align-right'>
                    <Button btnStyle='secondary' size='small' className='is-text-sm'>異動記錄查詢</Button>
                  </Grid>
                </Grid>

                <div className="car-information-list">
                  {
                    form.car_type.map((type, i) => (
                      <div className="car-information-item" key={`ar-information-item-${i}`}>
                        <div className="car-information-header">
                          <div className="content">車輛 {i+1}</div>
                          <div className='action'>
                            <Checkbox
                              name='remove'
                              label="移除" 
                              default={form.remove[i].value}
                              onChange={(n,v) => this.onChange(n, v, i)}
                            />
                          </div>
                        </div>
                        <div className="car-information-body">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={3}>
                              <div className="form-group">
                                <label>車輛種類</label>
                                <div>
                                  <Select
                                    label={form.car_type[i].value}
                                    name='car_type[]'
                                    options={[
                                      {text: '汽車', value: '汽車'}
                                    ]} 
                                    value={form.car_type[i].value}
                                    onChange={(n, v) => {console.log(n, v);this.onChange('car_type',v,i)}} 
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <div className="form-group">
                                <label>車輛號碼</label>
                                <div className="input-group">
                                  <input type='text' name='car_number_1[]' value={form.car_number_1[i].value} onChange={(e) => this.onChange('car_number_1', e.target.value, i)}  />
                                  <span>-</span>
                                  <input type='text' name='car_number_2[]' value={form.car_number_2[i].value} onChange={(e) => this.onChange('car_number_2', e.target.value, i)}  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                              <div className="form-group">
                                <label>代收地區</label>
                                <div>
                                  <div 
                                    className='fui-button is-secondary btn-block'
                                    onClick={e => this.setArea(i)}
                                    disabled={(form.car_number_1[i].value==='' || form.car_number_2[i].value === '') && form.area[i].value === ''}
                                  >
                                    <span className='text'>{form.area[i].value === '' ? "設定" : "已設定"}</span>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    ))
                  }
                  
                </div>
                <div className="car-information-action">
                  <div role="button" className="fui-button d-block is-large" onClick={this.addCarInfo}>
                    <span className='text'>新增更多車輛</span>
                    <i className='icon-plus'></i>
                  </div>
                </div>
              </div>

              <CheckboxCollapse 
                name="agree" 
                label="我已知悉並了解遠傳停車費代收服務申請條款所為之告知事情" 
                validationError='請詳閱及了解此注意事項'
                validations={{
                  isChecked: form => form.agree
                }}
                checked={form.agree.value}
                value={form.agree.value}
                required={true}
                getCheckbox={e => this.onChange('agree', e, null)}
                collapseContent={`
                  <p>我已知悉並了解遠傳停車費代收服務申請條款所為之告知事情</p>
                `}
              />

              <div className="page-action">
                <Button btnStyle="primary" size="large" disabled={!this.state.canSubmit || !form.agree.value} onClick={this.beforeSubmit}>送出申請</Button>
              </div>
            </div>
          </Formsy>
        </section>

        {form.area.map((area, i) => (
          <ParkingCheckAreaSection 
            key={`ParkingCheckAreaSection-${i}`}
            className={this.state.showArea && selectedArea.index === i ? 'd-block' : 'd-none'} 
            name='area'
            options={
              form.new[i].value === true ? newAreaList : areaList
            }
            selected={selectedArea}
            onChange={(n, v) => this.onChange('area', v, i)} 
            close={this.closeArea}
            reset={this.resetArea}
          />
        ))}
        
        
        <Modal
          onAfterOpen={(e) => document.getElementsByTagName('html')[0].classList.add('modal-open')}
          onAfterClose={(e) => document.getElementsByTagName('html')[0].classList.remove('modal-open')}
          isOpen={this.state.modalOpen}
          contentLabel='Example Modal'
          className='fui-prompt-modal'>
          <div className='fui-prompt-modal-body'>
            <h5>移除停車費代收設定</h5>
            <p>你確定要移除車輛１的停車費代收設定？</p>

            <div className='fui-buttons two-button'>
              <Button btnStyle='primary' onClick={(e) => this.submit()}>
                確定
              </Button>
              <Button btnStyle='secondary' onClick={(e) => this.closeModal()}>
                取消
              </Button>
            </div>
          </div>
        </Modal>
      </main>
    )
  }
}

export default parkingfeeInitPage;