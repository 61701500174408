import React from 'react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import RadioGroup from '../../components/form/RadioGroup';
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import * as Validation from '../../utils/validation';


class AddressChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      step2Validation: false,
      ismail: [
        {
          value: '0',
          label: '是',
        },
        {
          value: '1',
          label: '否',
        },
      ],
      form: {
        homeNum: { value: '', required: false },
        officeNum: { value: '', required: false },
        mobile: { value: '', required: false },
        address: { value: '', required: false },
        id_number: { value: '', required: false },
        isMail: { value: '0', required: false },
      },
      canSubmit: false,
      selectCity: '基隆市',
      selectDist: '中正區',
      currentTable: [
        {
          title: '住家電話',
          content: '0935***020'
        },
        {
          title: '公司電話',
          content: '無'
        },
        {
          title: '行動電話',
          content: '0935***000'
        },
        {
          title: '帳單地址',
          content: '新北市永和區***'
        },
      ],
      cityList: [
        {
          text: '基隆市',
          value: '基隆市'
        },
        {
          text: '臺北市',
          value: '臺北市'
        },
        {
          text: '新北市',
          value: '新北市'
        },
        {
          text: '宜蘭縣',
          value: '宜蘭縣'
        },
        {
          text: '新竹市',
          value: '新竹市'
        },
        {
          text: '新竹縣',
          value: '新竹縣'
        },
        {
          text: '桃園市',
          value: '桃園市'
        },
        {
          text: '苗栗縣',
          value: '苗栗縣'
        },
        {
          text: '臺中市',
          value: '臺中市'
        },
        {
          text: '彰化縣',
          value: '彰化縣'
        },
        {
          text: '南投縣',
          value: '南投縣'
        },
        {
          text: '嘉義市',
          value: '嘉義市'
        },
        {
          text: '嘉義縣',
          value: '嘉義縣'
        },
        {
          text: '雲林縣',
          value: '雲林縣'
        },
        {
          text: '臺南市',
          value: '臺南市'
        },
        {
          text: '高雄市',
          value: '高雄市'
        },
        {
          text: '屏東縣',
          value: '屏東縣'
        },
        {
          text: '臺東縣',
          value: '臺東縣'
        },
        {
          text: '花蓮縣',
          value: '花蓮縣'
        },
        {
          text: '金門縣',
          value: '金門縣'
        },
        {
          text: '連江縣',
          value: '連江縣'
        },
        {
          text: '澎湖縣',
          value: '澎湖縣'
        },
      ],
      distList: [
        { value: "中正區", text: "中正區" },
        { value: "大同區", text: "大同區" },
        { value: "中山區", text: "中山區" },
        { value: "松山區", text: "松山區" },
        { value: "大安區", text: "大安區" },
        { value: "萬華區", text: "萬華區" },
        { value: "信義區", text: "信義區" },
        { value: "士林區", text: "士林區" },
        { value: "北投區", text: "北投區" },
        { value: "內湖區", text: "內湖區" },
        { value: "南港區", text: "南港區" },
        { value: "文山區", text: "文山區" },
      ]
    }
  }
  selectCity = (val) => {
    console.log(`val`, val);
    this.setState({
      selectCity: val
    })
  }
  selectDist = (val) => {
    console.log(`val`, val);
    this.setState({
      selectDist: val
    })
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm,
    });
  };
  defaultFormOnChange = (name, value) => {
    let result = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    console.log(result);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = result;
    this.setState({
      form: newForm,
    });
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  submit = () => {
  }
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='帳單與用量'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="帳單地址/電話變更"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="usage-rate pt-md-7 pt-4 no-bg">
          <div className="fui-container">
            <div className="fui-segments no-shadow is-bg-initial">
              {this.state.step == 10 ? <div>
                <div className="paper mt-md-4 mt-2 mb-0 pb-md-7 pb-4">
                  <p className='body mb-0 mt-4 is-text-darkgray50'>親愛的客戶，因您有申請明細帳單或合併帳單需求尚未完成，故目前無法變更地址。如有疑問，請洽客服中心或立即使用客服即時通，謝謝。</p>
                  <div className='page-action'>
                    <Button
                      onClick={() => { console.log('使用客服即時通') }}
                      link="#"
                      className='mt-4 mb-lg-0 mb-md-2 mb-0'
                      btnStyle='primary'
                      size='large'>
                      使用客服即時通
                  </Button>
                  </div>
                </div>
                <div className="paper mt-md-2 mt-2 mb-0 pb-md-7 pb-4">
                  <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-4 mb-2">
                    <h4 className='m-0'>目前登記的資料</h4>
                  </div>



                  {Object.keys(this.state.currentTable).length === 0 ? <div>
                    <div className="body is-text-darkgray50">
                      無加購上網用量記錄
                    </div>
                  </div> :
                    <table className="text-wrap odd table-fixed">
                      <tbody>
                        {this.state.currentTable.map((row, i) => {
                          return (
                            <tr key={row.title + i}>
                              <td className="align-center is-bg-white is-text-medium">{row.title}</td>
                              <td className="align-left  pl-md-5 pl-2">{row.content}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>}
                </div>
              </div> : null}

              {this.state.step == 1 ? <div>
                <h4 className="is-text-darkgray50 mb-8">
                  提供您線上變更帳單地址及連絡電話，申請成功後將於下一期帳單起寄送至您的新地址。
                </h4>
                <div className="paper mt-md-4 mt-2 mb-0 pb-md-7 pb-4">
                  <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-4 mb-2">
                    <h4 className='m-0'>目前登記的資料</h4>
                  </div>
                  {Object.keys(this.state.currentTable).length === 0 ? <div>
                    <div className="body is-text-darkgray50">
                      無加購上網用量記錄
                    </div>
                  </div> : <table className="text-wrap odd table-fixed">

                      <tbody>
                        {this.state.currentTable.map((row, i) => {
                          return (
                            <tr key={row.title + i}>
                              <td className="align-center is-bg-white is-text-medium">{row.title}</td>
                              <td className="align-left  pl-md-5 pl-2">{row.content}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>}
                </div>
                {!this.state.step2Validation ? <div className='page-action'><Button
                  onClick={() => this.setState({ step2Validation: true })}
                  link="#"
                  className='mt-3 mb-lg-0 mb-md-2 mb-0'
                  btnStyle='primary'
                  size='large'>
                  變更設定
                  </Button></div> : null}

                {this.state.step2Validation ? <div className="mt-7">
                  <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={8}>
                        <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                        <LabelInput
                          className="mb-2"
                          name='id_number'
                          required={this.state.form.id_number.required}
                          label='證照號碼'
                          validations={{
                            idNumberValid: Validation.idNumberValid
                          }}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入證照號碼'
                          }}
                          placeholder='請輸入證照號碼'
                          value={this.state.form.id_number.value}
                          onChange={this.onChange}
                        />
                        <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                          身分說明：<br />
                          個人戶：身分證字號<br />
                          公司戶：統一編號或稅籍編號<br />
                          外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                        </p>
                        <div className='page-action'><Button onClick={() => { this.setState({ step: 2 }) }} link="#" className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>送出申請</Button></div>
                        <div className='page-action'><Button onClick={() => { this.setState({ step: 10 }) }} link="#" className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>送出申請(前往無法更變頁面)</Button></div>
                      </Grid>
                    </Grid>
                  </Formsy>
                </div> : null}

              </div> : null}


              {this.state.step == 2 ? <div>
                <h4 className='is-text-darkgray50 mb-md-7 mb-4'>請輸入欲變更之聯絡資訊</h4>
                <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                  <div className="form-editor">
                    <div className="form-editor--list">
                      <div className="form-editor--list--left">
                        住家電話
                                            </div>
                      <div className="form-editor--list--right">
                        <div className="head">
                          0935***020
                                                </div>
                        <div className="content">
                          <LabelInput
                            className="mb-2"
                            name='homeNum'
                            required={this.state.form.homeNum.required}
                            label='更改之住家電話'
                            validations={{

                            }}
                            validationErrors={{

                            }}
                            placeholder='請輸入更改之住家電話'
                            value={this.state.form.homeNum.value}
                            onChange={this.onChange}
                          />
                          <small>範例：02-77235000</small>
                        </div>
                      </div>
                    </div>
                    <div className="form-editor--list">
                      <div className="form-editor--list--left">
                        公司電話
                                            </div>
                      <div className="form-editor--list--right">
                        <div className="head">
                          無
                                                </div>
                        <div className="content">
                          <LabelInput
                            className="mb-2"
                            name='officeNum'
                            required={this.state.form.officeNum.required}
                            label='更改之公司電話'
                            validations={{

                            }}
                            validationErrors={{

                            }}
                            placeholder='請輸入更改之公司電話'
                            value={this.state.form.officeNum.value}
                            onChange={this.onChange}
                          />
                          <small>範例：02-77235000#12</small>
                        </div>
                      </div>
                    </div>
                    <div className="form-editor--list">
                      <div className="form-editor--list--left">
                        行動電話
                                            </div>
                      <div className="form-editor--list--right">
                        <div className="head">
                          0935***020
                                                </div>
                        <div className="content">
                          <LabelInput
                            className="mb-2"
                            name='mobile'
                            required={this.state.form.mobile.required}
                            label='更改之行動電話'
                            validations={{ matchRegexp: /^09\d{2}-?\d{3}-?\d{3}$/ }}
                            validationErrors={{
                              matchRegexp: '請輸入正確的手機門號',
                            }}
                            placeholder='請輸入更改之行動電話'
                            value={this.state.form.mobile.value}
                            onChange={this.defaultFormOnChange}
                          />
                          <small>範例：0936000000</small>
                        </div>
                      </div>
                    </div>
                    <div className="form-editor--list">
                      <div className="form-editor--list--left">
                        帳單地址
                                            </div>
                      <div className="form-editor--list--right">
                        <div className="head">
                          新北市永和區******
                                                </div>
                        <div className="content">
                          <RadioGroup
                            validationErrors='是否為郵政信箱'
                            onChange={this.onChange}
                            label='是否為郵政信箱'
                            name='isMail'
                            className="is-two mb-md-5 mb-2"
                            required={this.state.form.isMail.required}
                            default={this.state.form.isMail.value}
                            options={this.state.ismail}
                          />
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <h6 className='m-0'>更改之帳單地址</h6>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Dropdown
                                className="is-button ml-0"
                                list={this.state.cityList}
                                label={this.state.selectCity}
                                arrow={true}
                                hasCheck={false}
                                onChange={(item) => this.selectCity(item)}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Dropdown
                                className="is-button ml-0"
                                list={this.state.distList}
                                label={this.state.selectDist}
                                arrow={true}
                                hasCheck={false}
                                onChange={(item) => this.selectDist(item)}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <small>郵遞區號：200</small>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <LabelInput
                                className="mt-1 mb-0"
                                name='address'
                                required={this.state.form.address.required}
                                label=''
                                validations={{

                                }}
                                validationErrors={{

                                }}
                                placeholder='請輸入更改之帳單地址'
                                value={this.state.form.address.value}
                                onChange={this.onChange}
                              />
                            </Grid>

                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </Formsy>
                <div className="button-group">
                  <div className='fui-buttons two-buttons mt-md-5 mt-0 pt-md-2 pt-4'>
                    <Button link="#" className='' btnStyle='primary' size='large'>確定變更</Button>
                    <Button link="#" className='' btnStyle="secondary" size='large'>取消</Button>
                  </div>
                </div>

              </div> : null}
            </div>
          </div>
        </section>
      </main >
    )
  }
}

export default AddressChange;