import React, { Component } from 'react'
import NavTab from '../../components/partials/NavTab';
import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import SectionAd3 from '../../components/partials/SectionAd3';
import { Grid } from '@material-ui/core';
import Panel from '../../components/panel/Panel';
import SectionCooperation from '../../components/partials/SectionCooperation'
import SectionFaq from '../../components/partials/collapse/SectionFaq';
import BannerPromotionBasic from '../../components/partials/banner/BannerPromotionBasic';
import PanelContainer from '../../components/panel/PanelContainer';
import QuoteBanner from '../../components/partials/QuoteBanner';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
class WatchIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 768 || (window.innerWidth <= 1024 && window.innerWidth < window.innerHeight),
      collapseData: [
        {
          title: "親職教養專家 張旭鎧 (阿鎧老師)：",
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          quote: "易於使用操作便利的智慧手錶，數位教養最佳工具！只要透過手機APP，就能立即了解孩子位置，隨時與父母連絡，進行互動。",
          content: "面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。",
          link: "#",
        },
        {
          title: "3C 部落客 babyfans 杜小威：",
          img: { url: '/resources/cbu/img/promotion-watch-babyfans@2x.png', alt: 'img-02' },
          quote: "過年外出一度走散了，馬上開定位和打電話立刻尋找他。很推薦給孩子小的家長，可以買給小孩外出用。",
          content: "面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。",
          link: "#",
        },
        {
          title: "朱朱育兒日記 朱兒：",
          img: { url: '/resources/cbu/img/promotion-watch-chu@2x.png', alt: 'img-03' },
          quote: "孩子可以隨時打電話給我，我也可以確認他的安全，有急事發生孩子也可按SOS通知所有家庭成員。真的是讓我輕鬆許多。",
          content: "面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。",
          link: "#",
        },
        {
          title: "朱朱育兒日記 朱兒：",
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          quote: "孩子可以隨時打電話給我，我也可以確認他的安全，有急事發生孩子也可按SOS通知所有家庭成員。真的是讓我輕鬆許多。",
          content: "面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。",
          link: "#",
        },
        {
          title: "朱朱育兒日記 朱兒：",
          img: { url: '/resources/cbu/img/CardCollapse1-img-01.jpg', alt: 'img-01' },
          quote: "孩子可以隨時打電話給我，我也可以確認他的安全，有急事發生孩子也可按SOS通知所有家庭成員。真的是讓我輕鬆許多。",
          content: "面對琳琅滿目的數位工具，該如何選擇呢？對此，阿鎧老師說，「父母易於使用、孩子操作便利的智慧手錶是個好選擇！」 「隨身佩戴的智慧手錶，能為孩子帶來安全感，加上手錶本身就帶有時間管理的觀念」他進一步以具備定位、通話與語音助理功能的《遠傳愛講定位手錶》為例，只要透過手機APP，家長就能輕鬆與孩子所佩戴的《遠傳愛講定位手錶》配對，了解孩子位置，孩子也能隨時與父母連絡，進行互動，手錶內建的鬧鐘、提醒、問天氣等功能，也能藉此養成孩子自主學習的習慣。",
          link: "#",
        },
      ],
    }
  }
  changeTab = () => {
    // console.log('changed tab');

  }


  render() {
    return (
      <main className='life-circle article'>
        <NavTab
          onChange={this.changeTab}
          {...{
            pageTitle: '愛講定位手錶',
            tabs: {
              name: 'help-center',
              list: [
                { name: 'tab-1', label: '產品介紹', link: './intro' },
                { name: 'tab-2', label: '最新優惠', link: '#' },
              ],
            },
            button: null,
            default: 1
          }} />
        <Breadcrumb
          breadcrumb={[
            { link: '', text: '個人首頁' },
            { link: '', text: '愛講定位手錶' },
          ]}
        />
        <BannerPromotionBasic
          bgColor="#fff"
          autoplay={false}
          autoplaySpeed={5000}
          slides={
            [
              {
                image: {
                  md: '/resources/cbu/img/cbu-promotion-necklace-1920x500.jpg',
                  sm: '/resources/cbu/img/cbu-promotion-necklace-750x1000.jpg'
                },
                // tag: "網路限定",
                title: '<div class="mt-7">愛講定位手錶是家人的守護者</div>',
                description: '<div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>申辦愛講定位手錶$990起</h5></div><div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>加贈手錶專用保護套</h5></div>',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '線上買',
                    link: '#'
                  },
                  {
                    btnStyle: 'secondary',
                    target: '_blank',
                    type: 'link',
                    text: '門市買',
                    link: '#'
                  }
                ]
              },
              {
                image: {
                  md: '/resources/cbu/img/ebu-aws-banner-1440-x-460@2x.jpg',
                  sm: '/resources/cbu/img/ebu-aws-banner-375-x-500@2x.jpg'
                },
                theme: 'dark',
                tag: "網路限定",
                title: '<div class="mt-md-7">買 iPhone SE 到遠傳，最經濟</div>',
                description: '<div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5></div>',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '申辦4.５G方案',
                    link: '#'
                  },
                  {
                    btnStyle: 'secondary',
                    target: '_blank',
                    type: 'link',
                    text: '拿好禮1+1',
                    link: '#'
                  }
                ]
              },
              {
                image: {
                  md: '/resources/cbu/img/ebu-aws-banner-1440-x-460@2x copy.jpg',
                  sm: '/resources/cbu/img/ebu-aws-banner-550-x-460@2x.png'
                },
                tag: "網路限定",
                title: '買 iPhone SE 到遠傳，最經濟',
                description: '<div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5></div>',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '申辦4.５G方案',
                    link: '#'
                  },
                  {
                    btnStyle: 'secondary',
                    target: '_blank',
                    type: 'link',
                    text: '拿好禮1+1',
                    link: '#'
                  }
                ]
              },
              {
                image: {
                  md: '/resources/cbu/img/ebu-aws-banner-1440-x-460@2x copy.jpg',
                  sm: '/resources/cbu/img/ebu-aws-banner-550-x-460@2x.png'
                },
                tag: "網路限定",
                title: '買 iPhone SE 到遠傳，最經濟',
                description: '<div class="list"><img class="" src="/resources/cbu/ratePlan/check-2.svg"/><h5>488上網飆到飽，+100把超夯手機、3C $0帶回家！</h5></div>',
                actions: [
                  {
                    btnStyle: 'primary',
                    target: '_blank',
                    type: 'link',
                    text: '申辦4.５G方案',
                    link: '#'
                  },
                  {
                    btnStyle: 'secondary',
                    target: '_blank',
                    type: 'link',
                    text: '拿好禮1+1',
                    link: '#'
                  }
                ]
              },
            ]
          }
        />
        <QuoteBanner
          className="mt-0"
          topBg={{
            md: '/resources/cbu/img/cbu-feature-text-red-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-feature-text-red-750x708.jpg'
          }}
          content={`<div class='m-0 px-md-9 is-text-white'>
              愛講的新外衣，替你心繫著最愛的家人
            </div>
            `}
        />
        <div className="fui-container">
          <h2 className='mb-md-7 mt-7 mb-4'>限量Bone愛講手錶保護套活動</h2>
        </div>
        <PanelContainer
          initial={false}
          className='mb-md-10 px-md-4 px-3'
        >
          <Grid container spacing={6} className='py-md-3 py-2'>
            <Grid item xs={12} md={6} className='py-0'>
              <img className='w-100' src="/resources/cbu/img/promotion-watch-04@2x.jpg" alt="watch" />
            </Grid>
            <Grid item xs={12} md={6} className='pb-0'>
              <h3 className='mt-0 mb-3'>滿足長輩小孩各種配戴需求</h3>
              <div className='checklist mb-4'>
                <div className='d-flex flex-align-center'>
                  <img
                    src={'/resources/cbu/life-circle-slashie/images/check.svg'}
                    alt=''
                  />
                  <span className='is-text-medium body-3 ml-1'>吊繩孔設計，附贈安全頸掛繩</span>
                </div>
                <div className='d-flex flex-align-center'>
                  <img
                    src={'/resources/cbu/life-circle-slashie/images/check.svg'}
                    alt=''
                  />
                  <span className='is-text-medium body-3 ml-1'>環保矽膠材質，防塵耐汙可水洗</span>
                </div>
                <div className='d-flex flex-align-center'>
                  <img
                    src={'/resources/cbu/life-circle-slashie/images/check.svg'}
                    alt=''
                  />
                  <span className='is-text-medium body-3 ml-1'>充電孔開口、充電免拆卸</span>
                </div>
                <div className='d-flex flex-align-center'>
                  <img
                    src={'/resources/cbu/life-circle-slashie/images/check.svg'}
                    alt=''
                  />
                  <span className='is-text-medium body-3 ml-1'>加大按鈕開關設計，方便使力</span>
                </div>
                <div className='d-flex flex-align-center'>
                  <img
                    src={'/resources/cbu/life-circle-slashie/images/check.svg'}
                    alt=''
                  />
                  <span className='is-text-medium body-3 ml-1'>台灣設計品牌bone，做工細緻、質感設計</span>
                </div>
              </div>
              <div className="page-action">
                <Button
                  btnStyle='primary'
                  target='_blank'
                  link="#"
                  size=''
                  className='mb-0'>
                  到門市申辦
                </Button>
              </div>
            </Grid>
          </Grid>
        </PanelContainer>
        <SectionCollapseInfo
          title="貼心小叮嚀"
          className='mt-md-0 mt-7 is-bg-gray30'
          content={`
            1. 上網吃到飽優惠僅限於將本專案SIM卡放置於本專案指定設備使用，若SIM卡取出放置於其他上網裝置使用，每月傳輸量為3.5GB。<br/>
            2. 月付$249包含遠傳高速飆網199方案月租+愛講定位手錶服務費$50。月付$399包含遠傳高速飆網499方案享月租折價$150+愛講定位手錶服務費$50。<br/>
            3. 於遠傳網路門市申辦愛講定位手錶商品案，需同意開通定位服務，始能使用愛講定位手錶完整功能。定位服務於手錶門號開通後次個工作日開通，約期與商品案相同，約期內不得單獨解除定位服務。本定位服務需月付$50，合併於手錶門號帳單收取。<br/>
            4. 以上價格限搭配遠傳愛講定位手錶豪華方案，月付$399簽約36個月，若調降費率或提前退租視同放棄本專案優惠且須繳交專案補貼款。<br/>
            5. 方案內容如有調整依門市最新公告為準。
          `}
        />

        <SectionAd3
          patternColor='green'
          title={`<h4 class="mb-0">提升孩子的數位競爭力</h4><p>與專業師資遊戲互動，開啟孩子自主學習！</p>`}
          action={[
            {
              text: '聯絡我們',
              line: '#',
            },
          ]}
        />
        <Panel className="is-bg-lightgray70 mb-0 pb-0">
          <div id="faq-007" className="fui-container pb-md-4">
            <h3 className="is-text-darkgray50 is-text-medium m-0">常見問題</h3>
            <SectionFaq {...{
              collapseList: [
                {
                  title: ' 非遠傳電信用戶也可以購買愛講定位手錶嗎?需要搭配門號或續約嗎？可以在那裡買得到？',
                  content: ` 非遠傳電信用戶也可以購買愛講定位手錶嗎?需要搭配門號或續約嗎？可以在那裡買得到？`,
                },
                {
                  title: '遠傳愛講定位手錶所用的SIM卡有限制嗎? 插入非遠傳卡片或不是使用這個方案的SIM卡，手錶還能使用嗎？',
                  content: `遠傳愛講定位手錶所用的SIM卡有限制嗎? 插入非遠傳卡片或不是使用這個方案的SIM卡，手錶還能使用嗎？`,
                },
                {
                  title: '遠傳愛講定位手錶是否支援3G、4G的網路？',
                  content: `遠傳愛講定位手錶是否支援3G、4G的網路？`,
                },
                {
                  title: '遠傳愛講定位手錶能到國外使用嗎？',
                  content: `遠傳愛講定位手錶能到國外使用嗎？`,
                },
                {
                  title: '手錶充飽電需要多久?充飽後能使用多久？',
                  content: `手錶充飽電需要多久?充飽後能使用多久？`,
                },
                {
                  title: '一支手錶可以綁定幾台手機？',
                  content: `一支手錶可以綁定幾台手機？`,
                },
                {
                  title: '更換手機時，需要重新與手錶進行綁定嗎？',
                  content: `更換手機時，需要重新與手錶進行綁定嗎？`,
                },
                {
                  title: '手錶損壞維修方式為何？',
                  content: `手錶損壞維修方式為何？`,
                },
                {
                  title: '產品客服專線、服務時間？',
                  content: `產品客服專線、服務時間？`,
                },
                {
                  title: '如何下載遠傳愛講定位手錶APP？',
                  content: `如何下載遠傳愛講定位手錶APP？`,
                },
              ],
              container: false,
              to: '#',
              target: '_blank'
            }} />
          </div>
        </Panel>
        <SectionCooperation
          list={[
            {
              title: '技術合作',
              img: {
                url: '/resources/cbu/img/coop-01.png',
                alt: 'coop-1'
              }
            },
            {
              title: '專案廠商',
              img: {
                url: '/resources/cbu/img/coop-01.png',
                alt: 'coop-2'
              }
            },
          ]}
        />
      </main>
    );
  }
}

export default WatchIntro;