import React from 'react';

import PropTypes from 'prop-types';

class Panel extends React.Component {
  render() {
    return (
      <div className="fui-container" style={{ ...this.props.style } || ""}>
        <div
          className={`fui-panel-container ${this.props.className ? this.props.className : ''} ${this.props.initial ? 'no-shadow no-bg' : ''}`}
          style={this.props.bgColor ? ({ backgroundColor: this.props.bgColor }) : null}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string
}

export default Panel;