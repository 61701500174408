export const tabContent = [
  {
    head: '遠傳心5G 未來無距離',
    content:
      '<p>遠傳5G以高頻寬、低延遲以及大規模物聯網的三大技術特性，透過提供AR/VR平台觀賞4K/8K影音串流服務，以及發展「大人物」（大數據、人工智慧、物聯網）相關創新應用，成功完成業界首發5G直播、並首創台灣運用5G技術完成多現場、低遲延、零時差感的戶外娛樂體驗，引領台灣大步邁向5G時代。因瞭解每位用戶對數位生活的期待，遠傳電信致力於並成功取得充足的5G頻譜，以4G與5G於低、中、高頻寬排列出最完美的搭配組合，提供用戶更全面、優質的網路體驗，讓未來理想生活沒有距離。</p>',
    paneTitle: '5G時代新躍進',
    banner: {
      image: {
        md: '/resources/ebu/images/ebu-solution.jpg',
        sm: '/resources/ebu/images/ebu-solution-sm.jpg',
      },
      caption: '透過 Smart WiFi 集客行銷，再遠的客人都能品嘗到我的獨家手藝。',
      action: {
        text: '我有興趣',
        link: '#',
      },
    },
    tabs: {
      name: 'solution',
      list: [
        { name: 'solution-1', title: '智慧產業', label: '智慧產業' },
        { name: 'solution-2', title: '智慧城市', label: '智慧城市' },
        { name: 'solution-3', title: '超高速時代', label: '超高速時代' },
        { name: 'solution-4', title: '企業專網', label: '企業專網' },
      ],
    },
    tabContent: [
      {
        paneBackground: '/resources/ebu/images/ebu-5g-banner-2-1440x620.jpg',
        slideTitle: '低延遲通信 拉近城鄉距離',
        slideContent:
          '遠傳5G服務將更貼近企業多元需求與消費者體驗，透過5G低延遲特性，可以達到即時、快速、傳遞高清影像，真正落實各領域的遠距應用，包含智慧醫療、智慧教育、智慧交通、智慧物流等領域。',
        cards: [
          {
            title: '5G晶片測試計畫',
            meta: '智慧產業',
            description: '遠傳結盟聯發科技 打造5G晶片測試環境',
            link: '#',
          },
          {
            title: '遠距診療計畫',
            meta: '智慧產業',
            description: '即時影像時差縮小10倍，畫面顯示更即時；使醫療服務行動化，讓醫療不再受限於固定場所。',
            link: '/content/ebu/tw/5g/form/5g-health-care-form.html',
          },
        ],
      },
      {
        head: '5G時代新躍進',
        content:
          '<p>面對5G服務新時代的到來，各國皆積極推動與5G發展相關的技術及應用，遠傳於2016年便成立5G實驗室，利用5G前導技術等高頻寬及低延遲的通訊網絡特性，進行各項創新技術的應用測試研究。</p><p>展望未來5G產業鏈的發展，同時也回應市場對5G新時代的期待，遠傳率先拋磚引玉，透過收集國際間5G相關產業的報告與應用並深入研究分析後，整理出「5G頻率政策與產業發展白皮書」，與各位先進一同分享5G相關趨勢與產業鏈發展，並期望透過不斷的累積，加速成長與創新變革，共享對未來5G時代的全新想像與期待，能夠成功引領未來的關鍵企業，攜手創造共贏局勢。</p>',
        paneTitle: '5G時代新躍進',
        button: {
          name: '下載白皮書',
          url: '#',
        },
        paneBackground: '/resources/ebu/images/ebu-5g-banner-3-1440x620.jpg',
        slideTitle: '聯網無所不在 智慧城市',
        slideContent:
          '透過萬物串聯以及5G大連結特性，展現智慧城市全新風貌，提供市民更健全、更方便的智慧車聯、智慧路燈、智慧停車、智慧大樓等物聯整合應用服務，提升多元智慧應用的可能性。',
        cards: [
          {
            title: '智慧路燈',
            meta: '智慧路燈',
            description: '路燈照明為城市基礎建設，透過控制器可讓路燈智慧化，連線平台即時遠端監控各盞路燈的聯網...',
            link: 'https://enterprise.fetnet.net/content/ebu/tw/product/IOT/IOT_L3/iot-light/iot-light-intro.html',
          },
          {
            title: '智慧智慧停車城市',
            meta: '智慧停車',
            description: '智慧停車解決方案可讓駕駛人即時查詢路邊停車格的空位，透過導航引領快速到位，省時又環...',
            link: 'https://enterprise.fetnet.net/content/ebu/tw/product/IOT/IOT_L3/iot-parking/iot-parking-intro.html',
          },
        ],
      },
      {
        head: '5G時代新躍進',
        content:
          '<p>面對5G服務新時代的到來，各國皆積極推動與5G發展相關的技術及應用，遠傳於2016年便成立5G實驗室，利用5G前導技術等高頻寬及低延遲的通訊網絡特性，進行各項創新技術的應用測試研究。</p><p>展望未來5G產業鏈的發展，同時也回應市場對5G新時代的期待，遠傳率先拋磚引玉，透過收集國際間5G相關產業的報告與應用並深入研究分析後，整理出「5G頻率政策與產業發展白皮書」，與各位先進一同分享5G相關趨勢與產業鏈發展，並期望透過不斷的累積，加速成長與創新變革，共享對未來5G時代的全新想像與期待，能夠成功引領未來的關鍵企業，攜手創造共贏局勢。</p>',
        paneTitle: '5G時代新躍進',
        button: {
          name: '下載白皮書',
          url: '#',
        },
        paneBackground: '/resources/ebu/images/ebu-5g-banner-4-1440x620.jpg',
        slideTitle: '超高速時代 搶先商用佈局',
        slideContent:
          '迎接5G加速時代，遠傳自2019即持續積極佈局5G商用化，打造各式的試驗場域，提供廠商進行測試，期望帶動台灣5G整體產業鏈，同時藉由5G場域的體驗，描繪未來5G商轉的多元應用。',
        cards: [
          {
            title: '5G內湖試驗計劃',
            meta: '5G內湖試驗計劃',
            description: '您的企業是否已經準備迎接5G時代爆發性成長商機？ 即刻前往內湖體驗最新5G技術！',
            link: '#',
          },
          {
            title: '全家「科技概念2號...',
            meta: '全家「科技概念2號...',
            description: '遠傳於全家「科技概念2號店」設置5G測試實驗站台，供消費者以行動裝置連網WiFi，透過...',
            link: 'https://enterprise.fetnet.net/content/ebu/tw/news-service/2019/news/news-20191022.html',
          },
        ],
      },
      {
        paneBackground: '/resources/ebu/images/ebu_5g_web_1440x620.jpg',
        slideTitle: '遠傳四大解決方案，部署5G企業專網',
        slideContent:
          '遠傳過去幾十年間己經累積了大量技術能量，不論網路規劃、建置與後續的維運都已具備相當之經驗，也建置專業的團隊可提供完整網路解決方案給企業用戶...',
        cards: [
          {
            title: '5G企業專網',
            meta: '企業專網',
            description: '遠傳四大解決方案，深入了解如何幫助企業部署5G專網',
            link: '/content/ebu/tw/5g/form/5g-private-network-form.html',
          },

        ],
      },
    ],
  },
  {
    head: '迎接5G服務新時代',
    content:
      '<p>面對5G服務新時代的到來，各國皆積極推動與5G發展相關的技術及應用，遠傳於2016年便成立5G實驗室，利用5G前導技術等高頻寬及低延遲的通訊網絡特性，進行各項創新技術的應用測試研究。</p><p>展望未來5G產業鏈的發展，同時也回應市場對5G新時代的期待，遠傳率先拋磚引玉，透過收集國際間5G相關產業的報告與應用並深入研究分析後，整理出「5G頻率政策與產業發展白皮書」，與各位先進一同分享5G相關趨勢與產業鏈發展，並期望透過不斷的累積，加速成長與創新變革，共享對未來5G時代的全新想像與期待，能夠成功引領未來的關鍵企業，攜手創造共贏局勢。</p>',
    paneTitle: '5G時代新躍進',
    button: {
      name: '下載白皮書',
      url: 'https://enterprise.fetnet.net/content/dam/fetnet/user_resource/ebu/documents/5G頻率政策與產業發展白皮書.pdf',
    },
    banner: {
      image: {
        md: '/resources/ebu/images/ebu-solution.jpg',
        sm: '/resources/ebu/images/ebu-solution-sm.jpg',
      },
      caption: '透過 Smart WiFi 集客行銷，再遠的客人都能品嘗到我的獨家手藝。',
      action: {
        text: '我有興趣',
        link: '#',
      },
    },
    tabs: {
      name: 'solution',
      list: [
        { name: 'solution-1', title: '智慧產業', label: '智慧產業' },
        { name: 'solution-2', title: '智慧城市', label: '智慧城市' },
        { name: 'solution-3', title: '超高速時代', label: '超高速時代' },
        { name: 'solution-4', title: '企業專網', label: '企業專網' },
      ],
    },
    tabContent: [
      {
        paneBackground: '/resources/ebu/images/ebu-5g-banner-2-1440x620.jpg',
        slideTitle: '低延遲通信 拉近城鄉距離',
        slideContent:
          '遠傳5G服務將更貼近企業多元需求與消費者體驗，透過5G低延遲特性，可以達到即時、快速、傳遞高清影像，真正落實各領域的遠距應用，包含智慧醫療、智慧教育、智慧交通、智慧物流等領域。',
        cards: [
          {
            title: '5G晶片測試計畫',
            meta: '智慧產業',
            description: '提供社區家庭完整的健康照護網，並結合藥局、村里辦公室、公共圖書館、關懷據點等，串聯...',
            link: '/ebu/product/5g-chip',
          },
          {
            title: '遠距診療計畫',
            meta: '智慧產業',
            description: '即時影像時差縮小10倍，畫面顯示更即時；使醫療服務行動化，讓醫療不再受限於固定場所。',
            link: '/ebu/product/5g-clinic',
          },
        ],
      },
      {
        head: '迎接5G服務新時代',
        content:
          '<p>面對5G服務新時代的到來，各國皆積極推動與5G發展相關的技術及應用，遠傳於2016年便成立5G實驗室，利用5G前導技術等高頻寬及低延遲的通訊網絡特性，進行各項創新技術的應用測試研究。</p><p>展望未來5G產業鏈的發展，同時也回應市場對5G新時代的期待，遠傳率先拋磚引玉，透過收集國際間5G相關產業的報告與應用並深入研究分析後，整理出「5G頻率政策與產業發展白皮書」，與各位先進一同分享5G相關趨勢與產業鏈發展，並期望透過不斷的累積，加速成長與創新變革，共享對未來5G時代的全新想像與期待，能夠成功引領未來的關鍵企業，攜手創造共贏局勢。</p>',
        paneTitle: '5G時代新躍進',
        button: {
          name: '下載白皮書',
          url: '#',
        },
        paneBackground: '/resources/ebu/images/ebu-5g-banner-3-1440x620.jpg',
        slideTitle: '聯網無所不在 智慧城市',
        slideContent:
          '透過萬物串聯以及5G大連結特性，展現智慧城市全新風貌，提供市民更健全、更方便的智慧車聯、智慧路燈、智慧停車、智慧大樓等物聯整合應用服務，提升多元智慧應用的可能性。',
        cards: [
          {
            title: '智慧路燈',
            meta: '智慧城市',
            description: '路燈照明為城市基礎建設，透過控制器可讓路燈智慧化，連線平台即時遠端監控各盞路燈的聯網狀態。',
            link: 'https://enterprise.fetnet.net/content/ebu/tw/product/IOT/IOT_L3/iot-light/iot-light-intro.html',
          },
          {
            title: '智慧停車',
            meta: '智慧城市',
            description: '智慧停車解決方案可讓駕駛人即時查詢路邊停車格的空位，透過導航引領快速到位，省時又環保。',
            link: 'https://enterprise.fetnet.net/content/ebu/tw/product/IOT/IOT_L3/iot-parking/iot-parking-intro.html',
          },
        ],
      },
      {
        head: '迎接5G服務新時代',
        content:
          '<p>面對5G服務新時代的到來，各國皆積極推動與5G發展相關的技術及應用，遠傳於2016年便成立5G實驗室，利用5G前導技術等高頻寬及低延遲的通訊網絡特性，進行各項創新技術的應用測試研究。</p><p>展望未來5G產業鏈的發展，同時也回應市場對5G新時代的期待，遠傳率先拋磚引玉，透過收集國際間5G相關產業的報告與應用並深入研究分析後，整理出「5G頻率政策與產業發展白皮書」，與各位先進一同分享5G相關趨勢與產業鏈發展，並期望透過不斷的累積，加速成長與創新變革，共享對未來5G時代的全新想像與期待，能夠成功引領未來的關鍵企業，攜手創造共贏局勢。</p>',
        paneTitle: '5G時代新躍進',
        button: {
          name: '下載白皮書',
          url: '#',
        },
        paneBackground: '/resources/ebu/images/ebu-5g-banner-4-1440x620.jpg',
        slideTitle: '超高速時代 搶先商用佈局',
        slideContent:
          '迎接5G加速時代，遠傳自2019即持續積極佈局5G商用化，打造各式的試驗場域，提供廠商進行測試，期望帶動台灣5G整體產業鏈，同時藉由5G場域的體驗，描繪未來5G商轉的多元應用。',
        cards: [
          {
            title: '5G內湖試驗計劃',
            meta: '超高速時代',
            description: '您的企業是否已經準備迎接5G時代爆發性成長商機？ 即刻前往內湖體驗最新5G技術！',
            link: '/ebu/form/5g-event',
          },
          {
            title: '全家「科技概念2號店」',
            meta: '超高速時代',
            description: '遠傳於全家「科技概念2號店」設置5G測試實驗站台，供消費者以行動裝置連網WiFi，透過FamiWifi搶先體驗5G科技。',
            link: 'https://enterprise.fetnet.net/content/ebu/tw/news-service/2019/news/news-20191022.html',
          },
        ],
      },
      {
        paneBackground: '/resources/ebu/images/ebu_5g_web_1440x620.jpg',
        slideTitle: '遠傳四大解決方案，部署5G企業專網',
        slideContent:
          '遠傳過去幾十年間己經累積了大量技術能量，不論網路規劃、建置與後續的維運都已具備相當之經驗，也建置專業的團隊可提供完整網路解決方案給企業用戶...',
        cards: [
          {
            title: '5G企業專網',
            meta: '企業專網',
            description: '遠傳四大解決方案，深入了解如何幫助企業部署5G專網',
            link: '/ebu/product/5g',
          },

        ],
      },
    ],
  },
];

export const tabs = {
  name: '5g-tab',
  icon: false,
  title: true,
  list: [
    {
      name: 'cbu',
      meta: '生活',
      label: '迎向未來生活',
    },
    {
      name: 'ebu',
      meta: '商務',
      label: '產業新進化',
    },
  ],
  default: 0
};

export const fiveGNewscards = [
  {
    public_at: '2020/01/16',
    meta: '最新消息',
    title: '遠傳取得發展5G所需頻譜　全面啟動5G業務',
    link: 'https://www.fetnet.net/corporate/NewsReleases-content.html?id=202001171153&_ga=2.188014888.162253882.1584591174-1256035182.1578309344',
  },
  {
    public_at: '2020/01/01',
    meta: '最新消息',
    title: '遠傳助攻2020跨年盛典，百萬收視喜迎5G元年',
    link: 'https://www.fetnet.net/corporate/NewsReleases-content.html?id=202001061449',
  },
  {
    public_at: '2019/12/31',
    meta: '最新消息',
    title: '遠傳4G、5G 雙網齊飆，打造全台最大5G娛樂場域',
    link: 'https://www.fetnet.net/corporate/NewsReleases-content.html?year=2019&id=202001021520',
  },
];

export const fiveGVideos = [
  {
    videoId: '9FaXQK51iM8',
    title: '遠傳x17 Media',
    content: '全台首發用5G訊號直播實境節目',
    imgSmall: '/resources/ebu/images/video-02.jpg',
    imgLarge: '/resources/ebu/images/video-02.jpg',
    videoLink: 'df-gMDkuC08',
  },
  {
    videoId: '9FaXQK51iM8',
    title: '2019台北跨年晚會',
    content: '幕後紀實影片',
    imgSmall: '/resources/ebu/images/video-03.jpg',
    imgLarge: '/resources/ebu/images/video-03.jpg',
    videoLink: 'https://youtu.be/u9YYwJKQ0aQ',
    alterVideo: null,
  },
  {
    videoId: '9FaXQK51iM8',
    title: '遠傳AI智慧驗布機',
    content: '',
    imgSmall: '/resources/ebu/images/video-01.jpg',
    imgLarge: '/resources/ebu/images/video-01.jpg',
    videoLink: 'https://youtu.be/CwfE29SgSZE',
    alterVideo: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
  }
];

export const milestone = [
  {
    year: '2020',
    list: [
      '於臺北市府跨年晚會成為全臺第一個運用5G技術完成多現場、低遲延的娛樂表演，領先同業打造全臺最大的5G戶外場域。',
    ],
  },
  {
    year: '2019',
    list: [
      '於「台北5G國際高峰會」現場啟動5G訊號結合AI檢驗技術，引領產業加速邁向工業4.0。',
      '遠傳5G走出實驗室，領先全臺完成LTE與5G NR現網雙連接測試。',
      '跨國結盟JTBC電視台，全臺首發5G結合17 Media現場直播節目。',
      '攜手北市府打造全臺雙第一，率先啟動「5G開放試驗場域」暨「商業實驗研發電信網路」。',
      '結盟聯發科技打造5G晶片測試環境。',
      '遠傳5G進駐全家便利商店「科技概念2號店，立下臺灣零售業創舉。',
      '攜手三大醫學中心，啟動國內第一個5G遠距診療前瞻計畫。',
    ],
  },
  {
    year: '2018',
    list: [
      '率先加入國際5G汽車通訊技術聯盟，為全臺電信業之首，並成立遠傳5G先鋒隊，引領全臺車聯網產業鏈。',
      '加入「DOCOMO亞洲物聯網計畫」，亞洲首創行動通訊物聯網生態圈啟動，加速發展跨國聯網服務。',
      '遠傳5G先鋒隊正式啟航， 領先全臺 ，建構5G車聯網產業鏈創新基地。',
    ],
  },
  {
    year: '2017',
    list: [
      '遠傳5G實驗室在臺灣首次5G核示網路展示NFV及Network Slicing關鍵技術。',
      '獨步全臺，率先展示愛立信合作的LAA，飆出1Gbps極速。',
    ],
  },
];

export const sectionTabBannerCard = {
  title: '<h2 className="pane-title">5G時代新躍進</h2>',
  tabs: {
    name: 'solution',
    list: [
      { name: 'solution-1', title: '智慧產業', label: '智慧產業' },
      { name: 'solution-2', title: '智慧城市', label: '智慧城市' },
      { name: 'solution-3', title: '超高速時代', label: '超高速時代' },
      { name: 'solution-4', title: '企業專網', label: '企業專網' },
    ],
  },
  tabContent: [
    {
      background: '/resources/ebu/images/ebu-5g-banner-2-1440x620.jpg',
      slideTitle: '低延遲通信 拉近城鄉距離',
      slideContent:
        '遠傳5G服務將更貼近企業多元需求與消費者體驗，透過5G低延遲特性，可以達到即時、快速、傳遞高清影像，真正落實各領域的遠距應用，包含智慧醫療、智慧教育、智慧交通、智慧物流等領域。',
      cards: [
        {
          title: '5G晶片測試計畫',
          meta: '智慧產業',
          description: '提供社區家庭完整的健康照護網，並結合藥局、村里辦公室、公共圖書館、關懷據點等，串聯...',
          link: '/ebu/product/5g-chip',
        },
        {
          title: '遠距診療計畫',
          meta: '智慧產業',
          description: '即時影像時差縮小10倍，畫面顯示更即時；使醫療服務行動化，讓醫療不再受限於固定場所。',
          link: '/ebu/product/5g-clinic',
        },
      ],
    },
    {
      background: '/resources/ebu/images/ebu-5g-banner-3-1440x620.jpg',
      slideTitle: '聯網無所不在 智慧城市',
      slideContent:
        '透過萬物串聯以及5G大連結特性，展現智慧城市全新風貌，提供市民更健全、更方便的智慧車聯、智慧路燈、智慧停車、智慧大樓等物聯整合應用服務，提升多元智慧應用的可能性。',
      cards: [
        {
          title: '智慧路燈',
          meta: '智慧城市',
          description: '路燈照明為城市基礎建設，透過控制器可讓路燈智慧化，連線平台即時遠端監控各盞路燈的聯網狀態。',
          link: 'https://enterprise.fetnet.net/content/ebu/tw/product/IOT/IOT_L3/iot-light/iot-light-intro.html',
        },
        {
          title: '智慧停車',
          meta: '智慧城市',
          description: '智慧停車解決方案可讓駕駛人即時查詢路邊停車格的空位，透過導航引領快速到位，省時又環保。',
          link: 'https://enterprise.fetnet.net/content/ebu/tw/product/IOT/IOT_L3/iot-parking/iot-parking-intro.html',
        },
      ],
    },
    {
      background: '/resources/ebu/images/ebu-5g-banner-4-1440x620.jpg',
      slideTitle: '超高速時代 搶先商用佈局',
      slideContent:
        '迎接5G加速時代，遠傳自2019即持續積極佈局5G商用化，打造各式的試驗場域，提供廠商進行測試，期望帶動台灣5G整體產業鏈，同時藉由5G場域的體驗，描繪未來5G商轉的多元應用。',
      cards: [
        {
          title: '5G內湖試驗計劃',
          meta: '超高速時代',
          description: '您的企業是否已經準備迎接5G時代爆發性成長商機？ 即刻前往內湖體驗最新5G技術！',
          link: '/ebu/form/5g-event',
        },
        {
          title: '全家「科技概念2號店」',
          meta: '超高速時代',
          description: '遠傳於全家「科技概念2號店」設置5G測試實驗站台，供消費者以行動裝置連網WiFi，透過FamiWifi搶先體驗5G科技。',
          link: 'https://enterprise.fetnet.net/content/ebu/tw/news-service/2019/news/news-20191022.html',
        },
      ],
    },
    {
      background: '/resources/ebu/images/ebu_5g_web_1440x620.jpg',
      slideTitle: '遠傳四大解決方案，部署5G企業專網',
      slideContent:
        '遠傳過去幾十年間己經累積了大量技術能量，不論網路規劃、建置與後續的維運都已具備相當之經驗，也建置專業的團隊可提供完整網路解決方案給企業用戶...',
      cards: [
        {
          title: '5G企業專網',
          meta: '企業專網',
          description: '遠傳四大解決方案，深入了解如何幫助企業部署5G專網',
          link: '/ebu/product/5g',
        },
      ],
    },
  ],
}