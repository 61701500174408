import React from 'react';
import Link from '../Link';
import PropTypes from 'prop-types';
class ProductCard extends React.Component {
  render() {
    return (
      <div className={`fui-card is-product ${this.props.type === 'wireless' ? 'is-wireless' : ''}`}>
        <Link to={this.props.link} target={this.props.target} className="fui-card-action">
          {this.props.ribbon ? <div className='ribbon'>{this.props.ribbon}</div> : null}
          <div className='fui-card-image'>
            <img className='lazyload' data-src={process.env.PUBLIC_URL + this.props.image} src={process.env.PUBLIC_URL + this.props.image} alt={this.props.title} />
          </div>
          <div className='fui-card-caption'>
            <div className="fui-card-meta">{this.props.meta}</div>
            <div className="body">
              {this.props.tag ? <span className='tag'>{this.props.tag}</span> : null}
              {this.props.title}
            </div>
            <div className="fui-card-price">
              {
                this.props.salePrice ? (
                  <div>
                    <span className='label'>優惠價</span>
                    {this.props.originPrice ? <del>{this.props.originPrice}</del> : null}
                    <b className='price'>{this.props.salePrice}</b>
                  </div>
                ) : null
              }
              {
                this.props.productPrice ? (
                  <div>
                    <span className='label'>商品價</span>
                    {this.props.originPrice ? <del>{this.props.originPrice}</del> : null}
                    <b className={`price ${this.props.originPrice ? 'is-text-error' : ''}`}>{this.props.productPrice}</b>
                  </div>
                ) : null
              }
              {this.props.projectPrice ? (
                <div>
                  <span className='label'>專案價</span>
                  <b className='price is-text-error'>{this.props.projectPrice} 起</b>
                </div>
              ) : null
              }
            </div>
          </div>
        </Link>
      </div>
    )
  }
}
ProductCard.propTypes = {
  image: PropTypes.string,
  ribbon: PropTypes.string,
  meta: PropTypes.string,
  title: PropTypes.string,
  tag: PropTypes.string,
  salePrice: PropTypes.string,
  projectPrice: PropTypes.string,
  originPrice: PropTypes.string
}
export default ProductCard;