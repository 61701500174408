import React from 'react';
import { Grid } from '@material-ui/core';

import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';

import Link from '../../components/Link';
import TabPane from '../../components/tab/TabPane';
import NavContentTab2 from '../../components/partials/NavContentTab2';

import MenuAutocomplete from '../../components/partials/MenuAutocomplete';
import LifeCircleCarousel from '../../components/partials/card/LifeCircleCarousel';
import SectionCarousel1 from '../../components/partials/card/SectionCarousel1';
import ArrowMoreTopRightCarousel from '../../components/partials/card/ArrowMoreTopRightCarousel';
import ESectionFeature from '../../components/partials/ESectionFeature'
import VideoCarousel from '../../components/VideoCarousel';
import WithIconCard from '../../components/partials/card/WithIconCard';

import SectionAd3 from '../../components/partials/SectionAd3';
import FooterAd from '../../components/ad/FooterAd';
import SectionFaqTab from '../../components/partials/collapse/SectionFaqTab';
import AppPromotion from '../../components/partials/AppPromotion';

import * as Mock from '../../mock/OriginNumberRoaming';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bgImg: {
        md: '/resources/cbu/roaming/cbu-roaminghome1-banner-1920x470.jpg',
        sm: '/resources/cbu/roaming/cbu-roaminghome1-banner-1920x470.jpg',
      },
      keyword: '',
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', (e) => {
        this.setState({
          isMobile: window.innerWidth < 768,
        });
      });
  }

  changeMainTab = value => {
    this.setState({
      currentMainTab: value,
    });
  };

  render() {
    return (
      <main className="roaming">
        <NavAnchor
          pageTitle='<a href="#">原號漫遊</a>'
          button={{
            link: '#',
            text: '立即申請',
          }}
          tabs={[
            { label: '主題活動', link: '#anchor1' },
            { label: '達人帶你玩', link: '#anchor2' },
            { label: '漫遊優惠', link: '#anchor3' },
            { label: '其他漫遊', link: '#anchor4' },
          ]}
        />
        <Breadcrumb
          breadcrumb={[
            { text: '個人首頁', link: '/' },
            { text: '原號漫遊', link: '/' }
          ]}
          color='white'
        />


        <section className={`fui-banner is-search-country ${this.state.className}`} style={{
          backgroundImage: `url(${this.state.isMobile ? this.state.bgImg.sm : this.state.bgImg.md})`,
        }}>

          <div className="fui-container">
            <div className="caption">
              <h1 className="mt-0 mb-4 is-text-white align-center">想要去哪裡旅遊？</h1>
            </div>
            <Grid container justify='center'>
              <Grid item xs={12} sm={12} md={8}>
                <MenuAutocomplete
                  {...Mock.menu}
                  {...Mock.keyword}
                />
                <div className="action">
                  <Link to='#'>
                    <div className="subtitle mt-2 is-text-white ">
                      漫遊上網吃到飽每天只要 $299
                      <img src="/resources/cbu/e-service/images/right-white.svg" className="align-middle" alt="" />
                    </div>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>


        <NavContentTab2
          tabs={{
            name: 'origin-number-roaming-tab',
            icon: false,
            title: true,
            list: [
              {
                name: 'monthly',
                title: '月租型用戶',
                label: '',
                link: '/originNumberRoaming/Index',
                target: '_self',
              },
              {
                name: 'prepaid',
                title: '預付卡用戶',
                label: '',
                link: '/originNumberRoaming/Prepaid',
                target: '_self',
              },
            ]
          }} onChange={e => this.changeMainTab(e)} />

        <section className="origin-number-roaming index with-bg pb-0 pt-7 pt-md-0">
          <div className='tab-content'>
            {
              <TabPane active={true}>

                <LifeCircleCarousel {...Mock.hotLocation} />

                <section id="anchor1" className="pt-0">
                  <ESectionFeature {...Mock.sectionFeature} />
                </section>

                <SectionAd3
                  patternColor='green'
                  title={`不知道原號漫遊怎麼用？別擔心，漫遊小叮嚀讓你一次就上手！`}
                  action={[
                    {
                      text: '了解更多',
                      line: '#',
                    },
                  ]}
                />


                <SectionCarousel1 {...Mock.topicCard}
                  className='pb-0'
                />


                <section id="anchor2" className="p-0">
                  <VideoCarousel
                    title="達人帶你玩"
                    videos={[
                      {
                        videoId: '9FaXQK51iM8',
                        title: '關西賞櫻超夯打卡景點',
                        content: '日本 5 天遠遊卡只要 $199',
                        imgSmall: '/resources/cbu/roaming/i-stock-970064046.jpg',
                        imgLarge: '/resources/cbu/roaming/i-stock-970064046.jpg',
                        videoLink: 'https://youtu.be/u9YYwJKQ0aQ',
                        alterVideo: null,
                      },
                      {
                        videoId: '9FaXQK51iM8',
                        title: '關西賞櫻超夯打卡景點22',
                        content: '日本 5 天遠遊卡只要 $199',
                        imgSmall: '/resources/cbu/roaming/i-stock-970064046.jpg',
                        imgLarge: '/resources/cbu/roaming/i-stock-970064046.jpg',
                        videoLink: 'https://youtu.be/u9YYwJKQ0aQ',
                        alterVideo: null,
                      },
                      {
                        videoId: '9FaXQK51iM8',
                        title: '關西賞櫻超夯打卡景點33',
                        content: '日本 5 天遠遊卡只要 $199',
                        imgSmall: '/resources/cbu/roaming/i-stock-970064046.jpg',
                        imgLarge: '/resources/cbu/roaming/i-stock-970064046.jpg',
                        videoLink: 'https://youtu.be/CwfE29SgSZE',
                        alterVideo: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
                      }
                    ]}
                  />
                </section>


                <section id="anchor3" className="p-0 overflow-hidden">
                  <ArrowMoreTopRightCarousel {...Mock.discountCard} />
                </section>


                <section id="anchor4" className="p-0">
                  <WithIconCard {...Mock.otherCard} />
                </section>


                <SectionFaqTab {...Mock.faqTab} />

                <FooterAd {...Mock.eventPromoData2} />

              </TabPane>
            }
          </div>
        </section>
      </main>
    );
  }
}

export default Index;
