import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import NavAnchor from '../../components/partials/NavAnchor';
import SectionCarousel3 from '../../components/partials/card/SectionCarousel3';
import StoreFinder from '../../components/partials/StoreFinder'
import PhoneSubscribe from '../../components/partials/PhoneSubscribe';
class Store extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: {},
      modalOpen: false,
      displayPlaces: [],
      currentStore: null,
      currentCity: null,
      currentLocation: null,
      cityResult: null,
      inputResult: null,
      locationResult: null,
      searchResult: null,
      currentZoom: 13,
      isMobile: window.innerWidth < 960,
      cityList: [
        {
          value: 'TPC',
          text: '台北市',
          location: [
            { value: '大同區', text: '大同區' },
            {
              value: '中正區',
              text: '中正區'
            },
            {
              value: '松山區',
              text: '松山區'
            },
            {
              value: '大安區',
              text: '大安區'
            }
          ]
        },
        {
          value: 'NTPC',
          text: '新北市',
          location: [
            {
              value: '板橋區',
              text: '板橋區'
            },
            {
              value: '三重區',
              text: '三重區'
            },
            {
              value: '中和區',
              text: '中和區'
            }
          ]
        },
      ],
      places: [
        {
          name: '台北車站直營店',
          id: '001',
          geometry: {
            location: {
              lat: 25.046618,
              lng: 121.517907
            }
          },
          fb: {},
          phone: '02-23719265',
          addr: '台北市中正區忠孝西路１段４９號Ｂ１',
          time: ['平日 07:00-23:00', '週六 07:00-23:00', '週日 07:00-23:00'],
          services: ['5G體驗', '互動體驗區', '咖啡機', '舊機回收', '包膜服務(預約制)', '手機免費充電/消毒', '在地服務', '複合門市', '自助繳費機', '手機維修收件', 'ETC繳費儲值', '行動電源/手機租借(預約制)'],
          facilities: ['移動式坡道', '服務鈴'],
          filter: ['dayTime', 'special'],
          book: '',
          getDirection: 'https://www.google.com.tw/maps/place/%E5%8F%B0%E5%8C%97%E5%B8%82%E4%B8%AD%E5%B1%B1%E5%8D%80%E5%8C%97%E5%AE%89%E8%B7%AF%EF%BC%95%EF%BC%94%EF%BC%92%E4%B9%8B%EF%BC%91%E8%99%9F'
        },
        {
          name: '台北館前直營店',
          id: '002',
          geometry: {
            location: {
              lat: 25.045560,
              lng: 121.515106
            }
          },
          fb: {},
          phone: '02-23719265',
          addr: '台北市中山區忠孝西路１段４９號Ｂ１',
          time: ['平日 07:00-23:00', '週六 07:00-23:00', '週日 07:00-23:00'],
          services: ['5G體驗', '互動體驗區', '咖啡機', '舊機回收', '包膜服務(預約制)', '手機免費充電/消毒', '在地服務', '複合門市', '自助繳費機', '手機維修收件', 'ETC繳費儲值', '行動電源/手機租借(預約制)'],
          facilities: ['移動式坡道', '服務鈴'],
          filter: ['dayTime', 'nightTime'],
          book: '',
          getDirection: 'https://www.google.com.tw/maps/place/%E5%8F%B0%E5%8C%97%E5%B8%82%E4%B8%AD%E5%B1%B1%E5%8D%80%E5%8C%97%E5%AE%89%E8%B7%AF%EF%BC%95%EF%BC%94%EF%BC%92%E4%B9%8B%EF%BC%91%E8%99%9F'
        },
        {
          name: '善導寺直營店',
          id: '0021',
          geometry: {
            location: {
              lat: 25.045660,
              lng: 121.512106
            }
          },
          fb: {},
          phone: '02-23719265',
          addr: '台北市中正區忠孝西路2段４９號Ｂ１',
          time: ['平日 07:00-23:00', '週六 07:00-23:00', '週日 07:00-23:00'],
          services: ['5G體驗', '互動體驗區', '咖啡機', '舊機回收', '包膜服務(預約制)', '手機免費充電/消毒', '在地服務', '複合門市', '自助繳費機', '手機維修收件', 'ETC繳費儲值', '行動電源/手機租借(預約制)'],
          facilities: ['移動式坡道', '服務鈴'],
          filter: ['nightTime'],
          book: '',
          getDirection: 'https://www.google.com.tw/maps/place/%E5%8F%B0%E5%8C%97%E5%B8%82%E4%B8%AD%E5%B1%B1%E5%8D%80%E5%8C%97%E5%AE%89%E8%B7%AF%EF%BC%95%EF%BC%94%EF%BC%92%E4%B9%8B%EF%BC%91%E8%99%9F'
        },
        {
          name: '善導寺直營店1',
          id: '0022',
          geometry: {
            location: {
              lat: 25.045660,
              lng: 121.512106
            }
          },
          fb: {},
          phone: '02-23719265',
          addr: '台北市中正區忠孝西路2段４９號Ｂ１',
          time: ['平日 07:00-23:00', '週六 07:00-23:00', '週日 07:00-23:00'],
          services: ['5G體驗', '互動體驗區', '咖啡機', '舊機回收', '包膜服務(預約制)', '手機免費充電/消毒', '在地服務', '複合門市', '自助繳費機', '手機維修收件', 'ETC繳費儲值', '行動電源/手機租借(預約制)'],
          facilities: ['移動式坡道', '服務鈴'],
          filter: ['nightTime'],
          book: '',
          getDirection: 'https://www.google.com.tw/maps/place/%E5%8F%B0%E5%8C%97%E5%B8%82%E4%B8%AD%E5%B1%B1%E5%8D%80%E5%8C%97%E5%AE%89%E8%B7%AF%EF%BC%95%EF%BC%94%EF%BC%92%E4%B9%8B%EF%BC%91%E8%99%9F'
        },
        {
          name: '善導寺直營店2',
          id: '0023',
          geometry: {
            location: {
              lat: 25.045660,
              lng: 121.512106
            }
          },
          fb: {},
          phone: '02-23719265',
          addr: '台北市中正區忠孝西路2段４９號Ｂ１',
          time: ['平日 07:00-23:00', '週六 07:00-23:00', '週日 07:00-23:00'],
          services: ['5G體驗', '互動體驗區', '咖啡機', '舊機回收', '包膜服務(預約制)', '手機免費充電/消毒', '在地服務', '複合門市', '自助繳費機', '手機維修收件', 'ETC繳費儲值', '行動電源/手機租借(預約制)'],
          facilities: ['移動式坡道', '服務鈴'],
          filter: ['nightTime'],
          book: '',
          getDirection: 'https://www.google.com.tw/maps/place/%E5%8F%B0%E5%8C%97%E5%B8%82%E4%B8%AD%E5%B1%B1%E5%8D%80%E5%8C%97%E5%AE%89%E8%B7%AF%EF%BC%95%EF%BC%94%EF%BC%92%E4%B9%8B%EF%BC%91%E8%99%9F'
        },
        {
          name: '善導寺直營店3',
          id: '0024',
          geometry: {
            location: {
              lat: 25.045660,
              lng: 121.512106
            }
          },
          fb: {},
          phone: '02-23719265',
          addr: '台北市中正區忠孝西路2段４９號Ｂ１',
          time: ['平日 07:00-23:00', '週六 07:00-23:00', '週日 07:00-23:00'],
          services: ['5G體驗', '互動體驗區', '咖啡機', '舊機回收', '包膜服務(預約制)', '手機免費充電/消毒', '在地服務', '複合門市', '自助繳費機', '手機維修收件', 'ETC繳費儲值', '行動電源/手機租借(預約制)'],
          facilities: ['移動式坡道', '服務鈴'],
          filter: ['nightTime'],
          book: '',
          getDirection: 'https://www.google.com.tw/maps/place/%E5%8F%B0%E5%8C%97%E5%B8%82%E4%B8%AD%E5%B1%B1%E5%8D%80%E5%8C%97%E5%AE%89%E8%B7%AF%EF%BC%95%EF%BC%94%EF%BC%92%E4%B9%8B%EF%BC%91%E8%99%9F'
        },
        {
          name: '三重直營店',
          id: '003',
          geometry: {
            location: {
              lat: 25.027939,
              lng: 121.431750
            }
          },
          fb: {},
          phone: '02-23719265',
          addr: '新北市三重區區忠孝西路１段４９號Ｂ１',
          time: ['平日 07:00-23:00', '週六 07:00-23:00', '週日 07:00-23:00'],
          services: ['5G體驗', '互動體驗區', '咖啡機', '舊機回收', '包膜服務(預約制)', '手機免費充電/消毒', '在地服務', '複合門市', '自助繳費機', '手機維修收件', 'ETC繳費儲值', '行動電源/手機租借(預約制)'],
          facilities: ['移動式坡道', '服務鈴'],
          filter: ['dayTime', 'nightTime'],
          book: '',
          getDirection: 'https://www.google.com.tw/maps/place/%E5%8F%B0%E5%8C%97%E5%B8%82%E4%B8%AD%E5%B1%B1%E5%8D%80%E5%8C%97%E5%AE%89%E8%B7%AF%EF%BC%95%EF%BC%94%EF%BC%92%E4%B9%8B%EF%BC%91%E8%99%9F'
        },
        {
          name: '南投彰南直營店',
          id: '004',
          geometry: {
            location: {
              lat: 23.880898,
              lng: 120.691864
            }
          },
          fb: {},
          phone: '02-23719265',
          addr: '南投縣中正區忠孝西路１段４９號Ｂ１',
          time: ['平日 07:00-23:00', '週六 07:00-23:00', '週日 07:00-23:00'],
          services: ['5G體驗', '互動體驗區', '咖啡機', '舊機回收', '包膜服務(預約制)', '手機免費充電/消毒', '在地服務', '複合門市', '自助繳費機', '手機維修收件', 'ETC繳費儲值', '行動電源/手機租借(預約制)'],
          facilities: ['移動式坡道', '服務鈴'],
          filter: [],
          book: '',
          getDirection: 'https://www.google.com.tw/maps/place/%E5%8F%B0%E5%8C%97%E5%B8%82%E4%B8%AD%E5%B1%B1%E5%8D%80%E5%8C%97%E5%AE%89%E8%B7%AF%EF%BC%95%EF%BC%94%EF%BC%92%E4%B9%8B%EF%BC%91%E8%99%9F'
        },
      ]
    }
  }
  render() {
    return (
      <main className="store">
        <NavAnchor
          pageTitle='幫助中心'
          button={{
            text: '回首頁',
            link: '/',
          }}
        />
        <Breadcrumb
          breadcrumb={[
            { link: '/ebu', text: '幫助中心' },
            { link: '/search', text: '門市服務' },
            { link: '/store-finder', text: '找門市' },
          ]}
        />
        <StoreFinder
          cityList={this.state.cityList}
          places={this.state.places}
        />
        <SectionCarousel3 {...{
          title: '門市大聲公',
          cards: [
            {
              image: '/resources/cbu/img/card-img-findstore-01@2x.jpg',
              subtitle: ' 門市教學',
              title: '3月門市教學課程',
              description: '巴斯克乳酪蛋糕氣炸鍋教學課程',
              link: '#',
            },
            {
              image: '/resources/cbu/img/card-img-findstore-02@2x.jpg',
              subtitle: '門市教學',
              title: '網路最熱門拍照技巧大公開',
              description: '免收月租費，撥打到大陸、香港、日本於一般時段及減價時段皆各享有優惠...',
              link: '#',
            },
            {
              image: '/resources/cbu/img/card-img-findstore-03@2x.jpg',
              subtitle: '門市情報誌',
              title: 'SAMSUNG Galaxy A51情報',
              description: '三星迷必讀情報，遠傳門市幫你整理好了！',
              link: '#',
            },
          ],
          more: { text: '看更多', link: '#' },
        }} />
        <SectionCarousel3 {...{
          title: '門市詢問破表的產品',
          className: "mt-5",
          cards: [
            {
              image: '/resources/cbu/img/card-img-findstore-04@2x.jpg',
              title: '免驚！當天可取漫遊卡',
              description: '上網預約，當天到門市輕鬆取卡。',
              link: '#',
            },
            {
              image: '/resources/cbu/img/card-img-findstore-05@2x.jpg',
              title: '愛講是孩子的好讀伴',
              description: '孩子的一百萬個為什麼？都讓愛講幫你回答！',
              link: '#',
            },
            {
              image: '/resources/cbu/img/card-img-findstore-06@2x.jpg',
              title: '樂齡享樂，輕鬆月付149起！',
              description: '月付149，享上網＋通話優惠。再加50元，家電手機0元帶回家！',
              link: '#',
            },
          ],
          more: { text: '看更多', link: '#' },
        }} />
        <PhoneSubscribe
          title='遠傳門市好康，每月報給你知'
          url='/ebu/product/done-manage-newsletter'
          bg='/resources/cbu/img/web-pattern-cbu-subscribe@2x.png'
          sending='送出'
        />
      </main >
    );
  }
}

export default Store;