
export const faqTab2 = {
  title: '常見問題',
  tabs: [
    { label: '會員查詢與管理' },
    { label: '遠傳生活圈' }
  ],
  collapseList: [
    [
      {
        title: '如何申請官網會員，且需要額外費用嗎？',
        content: '如何申請官網會員，且需要額外費用嗎？'
      },
      {
        title: '申請官網會員後，帳號何時生效？',
        content: '申請官網會員後，帳號何時生效？'
      },
      {
        title: '若不是遠傳電信用戶，也可以申請加入官網會員嗎？',
        content: '若不是遠傳電信用戶，也可以申請加入官網會員嗎？'
      },
    ],
    [
      {
        title: '如何申請官網會員，且需要額外費用嗎？',
        content: '如何申請官網會員，且需要額外費用嗎？'
      },
      {
        title: '申請官網會員後，帳號何時生效？',
        content: '申請官網會員後，帳號何時生效？'
      },
      {
        title: '若不是遠傳電信用戶，也可以申請加入官網會員嗎？',
        content: '若不是遠傳電信用戶，也可以申請加入官網會員嗎？'
      },
    ],
  ]
}
export const faqTab = {
  title: '常見問題',
  tabs: [
    { label: '電子帳單' },
    { label: '帳單/費用' },
    { label: '繳費服務' },
  ],
  collapseList: [
    [
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
    ],
    [
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
    ],
    [
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
      {
        title: '我的帳單想改成Email或簡訊寄送，該如何申請？',
        content: '我的帳單想改成Email或簡訊寄送，該如何申請？'
      },
    ]
  ]
}

export const shortcut1 = [
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-invoice-inquiry.svg',
    text: '帳單查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-invoice-detail.svg',
    text: '帳單明細',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-outstanding-account-network-traffic.svg',
    text: '未結帳金額/上網用量',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-pay-online.svg',
    text: '線上繳費',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-transaction-record.svg',
    text: '繳費/代收交易/發票查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-contract-inquiry.svg',
    text: '合約資訊查詢',
  }
]

export const shortcut2 = [
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-online-top-up.svg',
    text: '上網儲值',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-phone-top-up.svg',
    text: '通話金儲值',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-blance-online-expiry-date-search.svg',
    text: '餘額/上網/到期日查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-roaming-online-purchase.svg',
    text: '漫遊上網加購',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-invoice-search.svg',
    text: '儲值發票查詢',
  },
  {
    link: '#',
    image: '/resources/cbu/img/ic-80-cbu-help-center.svg',
    text: '常見問題',
  }
]

export const shortcut = [
  {
    link: '#',
    image: '/resources/cbu/img/friday-shopping-1.svg',
    text: 'friDay購物',
  },
  {
    link: '#',
    image: '/resources/cbu/img/flight-video.svg',
    text: 'friDay影音',
  },
  {
    link: '#',
    image: '/resources/cbu/img/friday-music.svg',
    text: 'friDay音樂',
  },
  {
    link: '#',
    image: '/resources/cbu/img/friday-57.svg',
    text: 'friDay 57',
  },
  {
    link: '#',
    image: '/resources/cbu/img/friday-photo.svg',
    text: 'friDay相片書',
  },
  {
    link: '#',
    image: '/resources/cbu/img/flight.svg',
    text: '遠遊卡',
  }
]

export const happygo = {
  title: 'HAPPYGO點數折抵',
  more: {
    link: '#',
    text: '看更多',
  },
  className: 'happy-go',
  cards: [
    {
      title: '折抵帳單金額50元',
      description: '<img src="/resources/cbu/img/ic-24-px-fcoin.svg" alt="fcoin" /> 200',
      link: '#',
    },
    {
      title: '折抵帳單金額100元',
      description: '<img src="/resources/cbu/img/ic-24-px-fcoin.svg" alt="fcoin" /> 200',
      link: '#',
    },
    {
      title: '折抵帳單金額300元',
      description: '<img src="/resources/cbu/img/ic-24-px-fcoin.svg" alt="fcoin" /> 200',
      link: '#',
    },
    {
      title: '折抵帳單金額500元',
      description: '<img src="/resources/cbu/img/ic-24-px-fcoin.svg" alt="fcoin" />200',
      link: '#',
    },
  ]
};

export const promotionCard = {
  title: '你的專屬優惠',
  cards: [
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
      title: '本月影片免費看!',
      description: '話題電影、日劇、韓劇等等應有盡有',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-eat-16x9.png',
      title: '中午不知道吃什麼？',
      description: 'Uber Eats 送遠傳新客優惠金200元',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-travel-16x9.png',
      title: '連假打算出國？',
      description: '遠遊卡吃到飽上網不再煩惱用量 ',
      link: '#',
    },
    {
      image: '/resources/cbu/e-service/images/image-cbu-discount-video-16x9.png',
      title: '本月影片免費看!',
      description: '話題電影、日劇、韓劇等等應有盡有',
      link: '#',
    },
  ]
};
