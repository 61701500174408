import React from 'react';
import { Grid } from '@material-ui/core';
import Formsy from 'formsy-react';

import NavAnchor from '../../components/partials/NavAnchor';
import LabelInput from '../../components/form/LabelInput';
import TextInput from '../../components/form/TextInput';
import Button from '../../components/Button';
import FormBanner from '../../components/partials/banner/FormBanner';
import Select from '../../components/form/Select';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';
import numberFormatter from '../../utils/numberFormatter';
import Tooltip from '../../components/Tooltip';

class fillPrepaidDepositDataPage extends React.Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);

    this.validCode = React.createRef();
    this.creditGroup = React.createRef();
    this.identityInput = React.createRef();
    this.state = {
      form: {
        id_number: { value: '', required: true },
        payment: { value: query.get('payment') || '1' },
        credit_number_1: { value: "", required: true },
        credit_number_2: { value: "", required: true },
        credit_number_3: { value: "", required: true },
        credit_number_4: { value: "", required: true },
        credit_year: { value: null, required: true },
        credit_month: { value: null, required: true },
        credit_code: { value: '', required: true },
        receipt: { value: '1', required: true },
        receipt_extra_2: { value: '', required: true },
        receipt_extra_3: { value: '', required: true },
        bank_code: { value: '', required: true },
        bank_account: { value: '', required: true },
      }
    }
  }

  componentDidMount() {
    let creditInputs = Array.from(this.creditGroup.current.getElementsByTagName('input'));
    creditInputs.map((input, i) => {
      this.setInputFilter(input, function (value) {
        return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
      });

      return input.addEventListener('keyup', e => {
        let index = creditInputs.indexOf(e.target);
        // console.log('input:' + index)
        if (e.currentTarget.value.length === 4 && index < 3) {
          creditInputs[index + 1].focus();
        }
      })
    })

    this.setInputFilter(this.validCode.current.getElementsByTagName('input')[0], function (value) {
      return /^\d*$/.test(value); // Allow digits and '.' only, using a RegExp
    });

    this.setInputFilter(this.identityInput.current.getElementsByTagName('input')[0], function (value) {
      return /^\w*$/.test(value); // Allow digits and '.' only, using a RegExp
    });
  }

  setInputFilter = (textbox, inputFilter) => {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;

    this.setState({
      form: newForm
    })
  }

  disableButton = () => {
    this.setState({
      canSubmit: false,
    });
  };

  enableButton = () => {
    this.setState({
      canSubmit: true,
    });
  };

  submit = () => {
    // this.props.history.push('/prepaidDepositController/fillPrepaidDepositDataPage');
  };

  render() {
    const { form } = this.state
    return (
      <main>

        <NavAnchor
          pageTitle='預付卡專區'
          button={{
            text: '回個人專區',
            link: '/',
          }}
        />
        <FormBanner
          title="上網儲值"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }} />
        <section>
          <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
            <div className="fui-container">
              <h4 className="is-text-darkgray70 my-0">請確認購買內容，並填寫付款資訊</h4>
              <div className='is-text-accent text-sm'>
                <span className='required-dot'></span>必填
              </div>
              <div className='mb-5'>
                <h4 className="is-text-darkgray50 mt-5 mb-2">
                  購買內容
                </h4>
                <div className="fui-table-response">
                  <table>
                    <thead>
                      <tr>
                        <th>儲值門號</th>
                        <th>方案名稱</th>
                        <th>金額</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0926-899-868</td>
                        <td>超4代易付180MB_60天_$180(1.2GB組合)</td>
                        <td>$180.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr className='my-5' />
                <h4 className="is-text-darkgray50 mb-2">
                  付款資料
                </h4>
                {
                  this.state.form.payment.value === '1' ? (
                    <div>
                      <div className="fui-table-response mb-5 pb-md-3">
                        <table>
                          <thead>
                            <tr>
                              <th>付款方式</th>
                              <th>餘額</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>預付卡可用餘額</td>
                              <td>${numberFormatter(209.11)}</td>
                            </tr>
                          </tbody>
                        </table>
                        <small className="is-text-gray100">※提醒您，加購金額將由此門號的可用餘額中扣除。</small>
                      </div>
                      <div className="mt-5">
                        <h4>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                        <Grid container>
                          <Grid item md={6}>
                            <div ref={this.identityInput}>
                              <LabelInput
                                label="證照號碼"
                                name='id_number'
                                placeholder="請輸入證照號碼"
                                required={this.state.form.id_number.required}
                                value={this.state.form.id_number.value}
                                onChange={this.onChange} />
                            </div>
                            <p className='is-text-darkgray50'>
                              身分說明：<br />
                              個人戶：身分證字號<br />
                              公司戶：統一編號或稅籍編號<br />
                              外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                            </p>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ) : null
                }

                <div className={this.state.form.payment.value === '2' ? 'd-block' : 'd-none'}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <div className="form-group">
                        <label className="is-required">信用卡卡號</label>
                        <div className="credit-input" ref={this.creditGroup}>
                          <input
                            type='tel'
                            name='credit_number[]'
                            required={true}
                            maxLength='4'
                            value={form.credit_number_1.value}
                            onChange={e => this.onChange('credit_number_1', e.currentTarget.value)} />
                          <span>-</span>
                          <input
                            type='tel'
                            name='credit_number[]'
                            required={true}
                            maxLength='4'
                            value={form.credit_number_2.value}
                            onChange={e => this.onChange('credit_number_2', e.currentTarget.value)} />
                          <span>-</span>
                          <input
                            type='tel'
                            name='credit_number[]'
                            required={true}
                            maxLength='4'
                            value={form.credit_number_3.value}
                            onChange={e => this.onChange('credit_number_3', e.currentTarget.value)} />
                          <span>-</span>
                          <input
                            type='tel'
                            name='credit_number[]'
                            required={true}
                            maxLength='4'
                            value={form.credit_number_4.value}
                            onChange={e => this.onChange('credit_number_4', e.currentTarget.value)} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <div className="form-group mb-2">
                        <label className="is-required">信用卡有效日期</label>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Select
                              name="credit_month"
                              required={true}
                              label={"月"}
                              value={form.credit_month.value}
                              options={[
                                { text: '01', value: '01' },
                                { text: '02', value: '02' },
                                { text: '03', value: '03' },
                                { text: '04', value: '04' },
                                { text: '05', value: '05' },
                                { text: '06', value: '06' },
                                { text: '07', value: '07' },
                                { text: '08', value: '08' },
                                { text: '09', value: '09' },
                                { text: '10', value: '10' },
                                { text: '11', value: '11' },
                                { text: '12', value: '12' }
                              ]}
                              onChange={this.onChange} />
                          </Grid>
                          <Grid item xs={6}>
                            <Select
                              name="credit_year"
                              required={true}
                              label={"年"}
                              value={form.credit_year.value}
                              options={[
                                { text: '20', value: '20' },
                                { text: '21', value: '21' },
                                { text: '22', value: '22' },
                                { text: '23', value: '23' },
                                { text: '24', value: '24' },
                                { text: '25', value: '25' },
                                { text: '26', value: '26' },
                                { text: '27', value: '27' },
                                { text: '28', value: '28' },
                                { text: '29', value: '29' },
                                { text: '30', value: '30' }
                              ]}
                              onChange={this.onChange} />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <div className="form-group mb-2">
                        <label className="is-required">
                          檢查碼
                        <Tooltip
                            content={<i className='icon-information i-24 is-text-business100'></i>}
                            trigger="click"
                            tooltip={`信用卡背面三碼數字`}
                          />
                        </label>
                        <div className='credit-input' ref={this.validCode}>
                          <TextInput
                            type='tel'
                            name='credit_code'
                            required={true}
                            maxLength="3"
                            minLength="3"
                            validationError={"請輸入檢查碼"}
                            value={form.credit_code.value}
                            onChange={val => this.onChange('credit_code', val)}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <img
                    src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured.png'}
                    srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-versignsecured@2x.png'}
                    alt='' height="32" className="mr-1" />
                  <img
                    src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb.png'}
                    srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-jcb@2x.png'}
                    alt='' height="32" className="mr-1" />
                  <img
                    src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard.png'}
                    srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-mastercard@2x.png'}
                    alt='' height="32" className="mr-1" />
                  <img
                    src={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa.png'}
                    srcSet={process.env.PUBLIC_URL + '/resources/cbu/e-service/images/img-credit-card-visa@2x.png'}
                    alt='' height="32" className="mr-1" />
                </div>

                {
                  this.state.form.payment.value === '3' ? (
                    <div>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <div className="form-group">
                            <label className="is-required">銀行代碼</label>
                            <Select
                              required={true}
                              name="bank_code"
                              label="請選擇"
                              options={[
                                { label: 'text', value: '1' }
                              ]}
                              value={this.state.form.bank_code.value}
                              onChange={this.onChange} />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container className="with-note">
                        <Grid item xs={12} md={6}>
                          <LabelInput
                            required={true}
                            label="銀行帳號(限本人)"
                            name="bank_account"
                            value={this.state.form.bank_account.value}
                            onChange={this.onChange}
                          />
                          <smalll className="form-note is-text-gray100">本付款機制採用SSL 256 bites安全加密機制</smalll>
                        </Grid>
                      </Grid>
                    </div>
                  ) : null
                }

                {
                  this.state.form.payment.value !== '1' ? (
                    <div>
                      <hr className="mb-5" />
                      <h4 className="is-text-darkgray50 mb-2">
                        發票明細
                      </h4>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <div className="form-group">
                            <label className="is-required">發票捐贈</label>
                            <div className="radio-buttons">
                              <div className="radio-group receipt-radio">
                                <div>
                                  <label className="radio-button">
                                    <p>不捐贈</p>
                                    <input
                                      type='radio'
                                      name="receipt"
                                      value='1'
                                      checked={this.state.form.receipt.value === '1'}
                                      onChange={e => this.onChange('receipt', e.currentTarget.value)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                <div>
                                  <label className="radio-button">
                                    <p>
                                      捐贈，選擇單位
                                    </p>
                                    <input
                                      type='radio'
                                      name="receipt"
                                      value='2'
                                      checked={this.state.form.receipt.value === '2'}
                                      onChange={e => this.onChange('receipt', e.currentTarget.value)} />
                                    <span className="checkmark"></span>
                                  </label>
                                  <Select
                                    name="receipt_extra_2"
                                    label="請選擇"
                                    disabled={this.state.form.receipt.value !== '2'}
                                    value={this.state.form.receipt_extra_2.value}
                                    onChange={this.onChange}
                                    options={[
                                      { text: '111', value: '111' }
                                    ]}
                                  />
                                </div>
                                <div>
                                  <label className="radio-button">
                                    <p>
                                      捐贈，填寫愛心碼
                                    </p>
                                    <input
                                      type='radio'
                                      name="receipt"
                                      value='3'
                                      checked={this.state.form.receipt.value === '3'}
                                      onChange={e => this.onChange('receipt', e.currentTarget.value)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      name="receipt_extra_3"
                                      disabled={this.state.form.receipt.value !== '3'}
                                      placeholder="請填寫"
                                      value={this.state.form.receipt_extra_3.value}
                                      onChange={e => this.onChange('receipt_extra_3', e.currentTarget.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <small className="is-text-darkgray50">※捐贈發票後，不提供索取紙本發票及更改捐贈對象</small>
                          </div>
                        </Grid>
                      </Grid>

                      <CheckboxCollapse
                        name="agree"
                        label="本人已詳閱及了解此注意事項並同意遵守"
                        validationError='請詳閱及了解此注意事項'
                        validations={{
                          isChecked: form => form.agree
                        }}
                        collapseContent={`
                          <p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。</p>
                          <p>使用「全國繳費平台」繳納遠傳電信服務費用注意事項：</p>
                          <p>
                          1.限以門號申請本人活存帳戶使用此服務。<br/>
                          2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                          3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                          4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                          5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。
                          </p>
                          <p>本人已詳閱及了解上述注意事項並同意遵守</p>
                        `}
                      />
                    </div>
                  ) : null
                }

              </div>

              <div className="page-action">
                <Button btnStyle="primary" size="large" onClick={this.submit}>確認</Button>
              </div>
            </div>

          </Formsy>
        </section>
      </main>
    )
  }
}

export default fillPrepaidDepositDataPage;