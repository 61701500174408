import React from 'react'
import ContentLoader from 'react-content-loader'

class DotsStyleLoader extends React.Component {

    render() {
        let { height } = this.props;
        const list = []
        let width = 400
        if (typeof window !== 'undefined') {
            width = window.innerWidth
        }

        if (!height) {
            height = 160
        }
        const dotSpace = 40
        for (let i = 0; i < 3; i++) {
            const dotX = width / 2 - dotSpace * i
            list.push(
                <circle cx={dotX} cy="86" r="8" />
            )
        }

        return (
            <ContentLoader
                viewBox={`0 0 ${width} ${height}`}
                height={height}
                width={width}
                backgroundColor="transparent"
            >
                {list}
            </ContentLoader>
        )
    }
}
export default DotsStyleLoader