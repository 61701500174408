import React from 'react';
import Button from '../../components/Button';
import RadioGroup from '../../components/form/RadioGroup';
import Formsy from 'formsy-react';
import NavAnchor from '../../components/partials/NavAnchor';
import FormBanner from '../../components/partials/banner/FormBanner';
import CheckboxCollapse from '../../components/form/CheckboxCollapse';

class EBill extends React.Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.form = React.createRef();
        this.state = {
            isMember: true,
            step: 1,
            canSubmit: false,
            form: {
                contact: { value: 'Email+簡訊通知', required: false },
                agree: { value: false, required: false },
            },
        }
    }

    onChange = (name, value) => {
        // console.log(`name, value: `, name, value);
        let newForm = Object.assign(this.state.form);
        newForm[name].value = value;
        this.setState({
            form: newForm
        })
    }
    beforeSubmit = () => {
        console.log('submit');

    }

    handleChange = () => {
        this.setState({
            authCode: this.input.current.value,
        });
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    }
    enableButton = () => {
        this.setState({ canSubmit: false });
    }
    render() {
        const { form, authCode } = this.state;
        return (
            <main>
                <NavAnchor
                    pageTitle='帳單與用量'
                    button={{
                        text: '回個人專區',
                        link: '#'
                    }} />
                <FormBanner
                    title="電子帳單申請"
                    image={{
                        md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
                        sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
                    }}
                />

                <section className="query-index">
                    {this.state.step == 1 ? <div className="fui-container">
                        <div className='member-login'>
                            <h4 className=''>為保護您個人資料的安全，請輸入遠傳帳戶密碼進行確認</h4>
                            <h6 className='mt-4 mb-2'>帳戶密碼(遠傳通關密碼)</h6>
                            <div className="text-input mb-1">
                                <input
                                    onBlur={this.handleChange}
                                    // id={`input-${this.props.name}`}
                                    type='text'
                                    ref={this.input}
                                    onChange={this.handleChange}
                                    onKeyUp={this.handleChange}
                                    placeholder='請輸入密碼'
                                    value={this.authCode}
                                    maxLength={8}
                                />
                            </div>
                            <p className='is-text-darkgray50 mt-1'>
                                帳戶密碼說明：<br />
                                1.帳戶密碼為您在遠傳所設置的帳戶授權密碼，亦稱通關密碼。<br />
                                2.如您欲變更密碼或查詢密碼，請洽客服中心或立即使用<a href="#" target="_blank" className="info-link">客服即時通</a>。
                            </p>
                            <div className='d-flex fui-buttons two-buttons mt-md-5 mt-0 pt-md-1 pt-4'>
                                <Button onClick={() => this.setState({ step: 2 })} link="#" className={`mb-lg-0 mb-2 ${!!this.state.authCode ? '' : 'disabled'}`} btnStyle='primary' size='large'>確認</Button>
                            </div>
                        </div>
                    </div> : <div className="fui-container">
                            <h4 className="is-text-darkgray50 mt-0 mb-7">
                                遠傳電子帳單讓您繳費安全又便利，從六大好處，更了解電子帳單的優點，立即體驗用越久享受越多的優質帳單新體驗。
                            </h4>
                            <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                                <RadioGroup
                                    label='請選擇接收方式'
                                    name="contact"
                                    options={[
                                        { label: 'Email+簡訊通知', value: 'Email+簡訊通知' },
                                        { label: '簡訊通知', value: '簡訊通知' }
                                    ]}
                                    default={this.state.form.contact.value}
                                    onChange={this.onChange}
                                />
                                <CheckboxCollapse
                                    name="agree"
                                    label="我已閱讀電子帳單服務申請條款"
                                    validationError='請詳閱及了解此注意事項'
                                    validations={{
                                        isChecked: form => form.agree
                                    }}
                                    checked={form.agree.value}
                                    value={form.agree.value}
                                    required={true}
                                    getCheckbox={e => this.onChange('agree', e, null)}
                                    collapseContent={`<p>為保障您的權益，請於使用本服務前，詳細閱讀下列注意事項，若您對本服務尚有不瞭解或不同意注意事項之內容者，請勿執行相關交易。<br/>
                                    使用「全國繳費平台」繳納遠傳電信服務費用注意事項：<br/>
                                    1.限以門號申請本人活存帳戶使用此服務。<br/>
                                    2.使用本服務可能繳納手續費(費用依全國繳費平台規定)，但於本公司使用此服務手續費0元。<br/>
                                    3.每筆單日繳費金額上限為新台幣10萬元，每月累計最高繳費金額為新台幣20萬元，如轉出金融機構之限額低於前述規定，則依轉出金融機構之規定辦理。<br/>
                                    4.您使用本服務所輸入之相關資料，將由遠傳電信、帳務代理、轉出、轉入金融機構及財金資訊服份有限公司在完成上述服務之特定目的內，蒐集、處理、利用及國際輸入您的個人資料。<br/>
                                    5.您使用本服務倘有錯誤或對款項之計算暨退補費等發生疑義，應自行洽扣款銀行、轉出金融機構或遠傳電信處理。<br/>
                                     本人已詳閱及了解上述注意事項並同意遵守
                                    </p>`}
                                />

                                <div className="page-action">
                                    <Button btnStyle="primary" size="large" disabled={!this.state.form.agree.value} onClick={this.beforeSubmit}>送出申請</Button>
                                </div>
                            </Formsy>
                        </div>}
                </section>
            </main >
        )
    }
}

export default EBill;