import React from 'react';
import Button from '../../components/Button';
import { Grid } from '@material-ui/core';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip'
import Dropdown from "../../components/Dropdown";
import RadioGroup from '../../components/form/RadioGroup';
import NavAnchor from '../../components/partials/NavAnchor';
import NavContentTab1 from '../../components/partials/NavContentTab1';
import FormBanner from '../../components/partials/banner/FormBanner';
import Pagination from '../../components/paginate/Pagination';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import * as Validation from '../../utils/validation';


class InternetSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: 'id',
      step: 1,
      step2Validation: false,
      ismail: [
        {
          value: '0',
          label: '是',
        },
        {
          value: '1',
          label: '否',
        },
      ],
      form: {
        setting: { value: 'true', required: false },
        agree: { value: 'false', required: false },
        id_number: { value: '', required: false },
        fetnetAccount: { value: '', required: true }
      },
      canSubmit: false
    }
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({
      form: newForm,
    });
  };
  defaultFormOnChange = (name, value) => {
    let result = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    console.log(result);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = result;
    this.setState({
      form: newForm,
    });
  }
  enableButton = () => {
    this.setState({
      canSubmit: true
    })
  };

  disableButton = () => {
    this.setState({
      canSubmit: false
    })
  };
  render() {
    return (
      <main>
        <NavAnchor
          pageTitle='漫遊與門號服務'
          button={{
            text: '回個人專區',
            link: '#'
          }} />
        <FormBanner
          title="行動上網設定"
          image={{
            md: '/resources/cbu/e-service/images/cbu-form-banner-1440-x-156.png',
            sm: '/resources/cbu/e-service/images/cbu-form-banner-375-x-147.png',
          }}
        />

        <section className="usage-rate pt-md-7 pt-4 no-bg">
          <div className="fui-container">
            <div className="fui-segments no-shadow is-bg-initial">
              {this.state.step == 1 ? <div>
                <h4 className="is-text-darkgray50 mb-8">
                  行動上網設定提供您開啟行動上網功能。
                </h4>
                <div className="paper mt-md-4 mt-2 mb-0 pb-md-7 pb-4">
                  <div className="d-flex justify-between flex-align-center mt-md-4 mt-0 mb-md-4 mb-2">
                    <h4 className='m-0'>目前的行動上網費率方案</h4>
                  </div>
                  <Grid className='mb-7' container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <div className="is-bg-lightgray70 px-3 py-3">
                        <h5 className="is-text-darkgray50 is-text-regular mb-1 mt-0">
                          方案
                        </h5>
                        <h2 className='mb-0'>遠傳上網分享包</h2>
                      </div>
                    </Grid>
                  </Grid>
                  <Formsy onValid={this.enableButton} onInvalid={this.disableButton}>
                    <RadioGroup
                      label='行動上網設定'
                      name="setting"
                      className='mb-0'
                      options={[
                        { label: '開啟', value: 'true' },
                        { label: '關閉', value: 'false' }
                      ]}
                      default={this.state.form.setting.value}
                      onChange={this.onChange}
                    />
                  </Formsy>

                </div>
                {!this.state.step2Validation ? <div className='page-action'><Button
                  onClick={() => this.setState({ step2Validation: true })}
                  link="#"
                  className='mt-3 mb-lg-0 mb-md-2 mb-0'
                  btnStyle='primary'
                  size='large'>
                  下一步
                  </Button></div> : null}

                {this.state.step2Validation ? <div className="mt-7">
                  <Formsy onValid={this.enableButton} onInvalid={this.disableButton} ref={this.form}>
                    {this.state.accountType === 'id' ? <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <h4 className='mb-3'>為保護您個人資料的安全，請輸入門號登記者的證照號碼以購買商品</h4>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <LabelInput
                          className="mb-2"
                          name='id_number'
                          required={this.state.form.id_number.required}
                          label='證照號碼'
                          validations={{
                            idNumberValid: Validation.idNumberValid
                          }}
                          validationErrors={{
                            isDefaultRequiredValue: '請輸入證照號碼'
                          }}
                          placeholder='請輸入證照號碼'
                          value={this.state.form.id_number.value}
                          onChange={this.onChange}
                        />
                        <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                          身分說明：<br />
                          個人戶：身分證字號<br />
                          公司戶：統一編號或稅籍編號<br />
                          外籍用戶：2018/12/31申辦-西元出生年月日+護照姓名前兩碼，2019/01/01申辦-護照號碼
                        </p>
                        <div className='page-action'><Button onClick={() => { console.log('submit!') }} link="#" className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>確認</Button></div>
                      </Grid>
                    </Grid> : <Grid container spacing={2}><Grid item xs={12} md={12}>
                      <h4 className='mb-3'>為保護您個人資料的安全，請輸入遠傳帳戶密碼進行確認</h4>
                    </Grid>
                        <Grid item xs={12} md={6}>
                          <LabelInput
                            className="mb-2"
                            name='fetnetAccount'
                            required={this.state.form.fetnetAccount.required}
                            label='帳戶密碼(遠傳通關密碼)'
                            validations="isLength:8"
                            validationErrors='請輸入密碼'
                            placeholder='請輸入密碼'
                            value={this.state.form.fetnetAccount.value}
                            onChange={this.onChange}
                          />
                          <p className="body-1 is-text-darkgray50 mt-0 mb-6">
                            帳戶密碼說明：<br />
                            1.帳戶密碼為您在遠傳所設置的帳戶授權密碼，亦稱通關密碼。<br />
                            2.如您欲變更密碼或查詢密碼，請洽客服中心或立即使用客服即時通。
                          </p>
                          <div className='page-action'><Button onClick={() => { console.log('submit!') }} link="#" className={`mb-0 ${this.state.canSubmit ? '' : 'disabled'}`} btnStyle='primary' size='large'>確認</Button></div>
                        </Grid>
                      </Grid>}
                  </Formsy>
                </div> : null}
              </div> : null}
            </div>
          </div>
        </section>
        {/* remove in production */}
        <div className="switcher py-5">
          <div className="fui-container">
            <div className="d-flex flex-column">
              <p>測試用資料切換</p>
              <Button onClick={() => this.setState({ accountType: 'id' })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.4.8.3_漫遊與門號服務_行動上網_輸入身分證號</Button>
              <Button onClick={() => this.setState({ accountType: 'fetnet' })} link="#" className='mb-lg-0 mb-md-2 mb-0' btnStyle='secondary' size='large'>7.4.8.2_漫遊與門號服務_行動上網_輸入遠傳帳密</Button>
            </div>
          </div>
        </div>
      </main >
    )
  }
}

export default InternetSetting;