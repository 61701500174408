import React from 'react';
import {Grid} from '@material-ui/core';
import CbuBanner from '../components/partials/banner/CbuBanner'
import GreetingGuide from '../components/partials/GreetingGuide'
import ProductMap from '../components/partials/ProductMap'
import PromotionCard from '../components/partials/card/PromotionCard'
import CbuNews from '../components/partials/card/CbuNews'
import SplashPromotion from '../components/ad/SplashPromotion'
import LifeCirclePromotion from '../components/partials/carousel/LifeCirclePromotion'

import * as Mock from '../mock/Index';

class CbuIndex extends React.Component {
  render() {
    return (
      <main>
        <SplashPromotion
          video={{
            cover: {
              md: '/resources/cbu/cbu-index/img-splash-promo@2x.jpg',
              sm: '/resources/cbu/cbu-index/mobile-spash-promotion.jpg',
            },
            alterVideo: 'https://youtu.be/_-P84QjN_G8'
          }}
          title='Google Pixel 4 打造完美手機體驗'
          description='遠傳獨家銷售，現在馬上來體驗'
          action={{
            link: '/',
            text: '立即購買'
          }}
        />
        <CbuBanner {...Mock.bannerTab} />
        <div className='ribbon-bg'>
          <GreetingGuide {...Mock.greetings} />
          <PromotionCard {...Mock.promotionCard} />
          <LifeCirclePromotion {...Mock.lifeCirclePromotion} />
          <CbuNews {...Mock.cbuNews} />
        </div>
        <ProductMap {...Mock.productMap} />
      </main>
    );
  }
}

export default CbuIndex;