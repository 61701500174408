import React, { Component } from 'react'
import Header from '../../components/partials/header/Default';
import NavAnchor from '../../components/partials/NavAnchor';
import Breadcrumb from '../../components/Breadcrumb';
import SectionBanner2 from '../../components/partials/banner/SectionBanner2';
import PanelInformation from '../../components/panelContent/PanelInformation';
import SectionCollapseInfo from '../../components/partials/collapse/SectionCollapseInfo';
import Panel from '../../components/panel/Panel';
class RestrictCall extends Component {
  constructor(props) {
    super(props);
    this.panel = React.createRef();
    this.state = {
    }
  }
  render() {
    return (
      <main className="">
        <Header />
        <NavAnchor
          pageTitle='<a href="#">漫遊與門號服務</a>'
          button={{
            link: '#',
            text: '漫遊與門號服務',
          }}
        />
        <Breadcrumb
          color="white"
          breadcrumb={[
            { link: '/ebu', text: '個人首頁' },
            { link: '', text: '個人專區' },
            { link: '', text: '指定轉接說明' },
          ]}
        />
        <SectionBanner2
          position='right'
          className='is-360'
          image={{
            md: '/resources/cbu/img/cbu-eservice-call-rule-1920x360.jpg',
            sm: '/resources/cbu/img/cbu-eservice-call-rule-750x400.jpg',
          }}
          title='<div class="mt-5 is-text-white">指定轉接說明</div>'
          action={null}
        />
        <div className="ribbon-bg pt-md-7 pt-4 pb-md-4">
          <div className='fui-container'>
            <div className="paper">
              <PanelInformation
                className="py-0"
                title={`<h5 class="m-0 is-text-black50 is-text-medium">撥打限制須知</h5>`}
                content={`
                  <h6 class="m-0 is-text-regular">設定指定轉接後，所有來話一經轉接到您設定的電話號碼時，轉接費用必須由您支付。</h6>
                  <ol class="order-list mb-0">
                      <li>
                          <span class="decimal">1</span>
                          設定通話限制功能時，則指定轉接功能將無法設定（指定轉接視同是由客戶手機撥出電話，若已設定禁撥電話的通話限制，則客戶無法撥出任何電話，因此無法設定指定轉接）。
                      </li>
                      <li>
                          <span class="decimal">2</span>
                          若設定禁撥及禁接來話，簡訊服務將一併限制。
                      </li>
                      <li>
                          <span class="decimal">3</span>
                          此功能免費，使用前請洽客服中心開啟功能。
                      </li>
                  </ol>`}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">改變密碼</h5>'
                content={`
                  <h6 class="mb-4 mt-0 is-text-regular is-text-black50">可選擇四位數字作為密碼，以啟用或取消收發話限制功能(預設密碼1111)。輸入完設定數字及符號後，請按撥出鍵設定。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">**03*330* + 舊密碼* + 新密碼* + 新密碼#</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  `}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">禁接來話</h5>'
                content={`
                  <h6 class="mb-4 mt-0 is-text-regular is-text-black50">可選擇四位數字作為密碼，以啟用或取消收發話限制功能。輸入完設定數字及符號後，請按撥出鍵設定。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">*35* + 密碼號碼#</h2>
                        </div>
                      </div>
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">取消</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">#35* + 密碼號碼#</h2>
                        </div>
                      </div>
                    </div>
                    </div>
                  `}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">禁撥電話</h5>'
                content={`
                  <h6 class="mb-4 mt-0 is-text-regular is-text-black50">可選擇四位數字作為密碼，以啟用或取消收發話限制功能。輸入完設定數字及符號後，請按撥出鍵設定。 </h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">*33* + 密碼號碼#</h2>
                        </div>
                      </div>
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">取消</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">#33* + 密碼號碼#</h2>
                        </div>
                      </div>
                    </div>
                    </div>
                  `}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">禁播國際電話</h5>'
                content={`
                  <h6 class="mb-4 mt-0 is-text-regular is-text-black50">可選擇四位數字作為密碼，以啟用或取消收發話限制功能。輸入完設定數字及符號後，請按撥出鍵設定。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">*331* + 密碼號碼#</h2>
                        </div>
                      </div>
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">取消</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">#331* + 密碼號碼#</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  `}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">漫遊時禁打本國/漫遊區外</h5>'
                content={`
                  <h6 class="mb-4 mt-0 is-text-regular is-text-black50">可選擇四位數字作為密碼，以啟用或取消收發話限制功能。輸入完設定數字及符號後，請按撥出鍵設定。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">*332* + 密碼號碼#</h2>
                        </div>
                      </div>
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">取消</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">#332* + 密碼號碼#</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  `}
              />
            </div>
            <div className="paper mt-1">
              <PanelInformation
                className="py-0"
                title='<h5 class="m-0 is-text-black50 is-text-medium">漫遊時拒接所有來電</h5>'
                content={`
                  <h6 class="mb-4 mt-0 is-text-regular is-text-black50">可選擇四位數字作為密碼，以啟用或取消收發話限制功能。輸入完設定數字及符號後，請按撥出鍵設定。</h6>
                  <div class="mb-md-2">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2">
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">設定</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">*351* + 密碼號碼#</h2>
                        </div>
                      </div>
                      <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
                        <h4 class="mt-0 mb-md-3 mb-2 is-text-darkgray50 is-text-regular">取消</h4>
                        <div class="is-bg-lightgray70 px-3 py-3">
                          <h5 class="is-text-regular mb-1 mt-0">請按</h5>
                          <h2 class="m-0">#351* + 密碼號碼#</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  `}
              />
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default RestrictCall;