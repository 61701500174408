import React from 'react';
import { withFormsy } from 'formsy-react';
import Dropdown from '../Dropdown';
import PropTypes from 'prop-types';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.selector = React.createRef();
    this.state = {
      submitted: false,
      isInvalid: false,
      label: this.props.getValue() && this.props.getValue() !== '' ? this.getSelectedItem(this.props.getValue()) : this.props.label
    };
  }

  componentDidMount() {
    this.selector.current.value = this.props.getValue();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isFormSubmitted() !== this.state.submitted
      ||
      (this.props.getValue() && this.getSelectedItem(this.props.getValue()) !== this.state.label)
    ) {
      // console.log('componentDidUpdate', this.props.getValue(), this.getSelectedItem(this.props.getValue()))
      this.setState({
        label: this.props.getValue() && this.props.getValue() !== '' ? this.getSelectedItem(this.props.getValue()) : this.props.label,
        submitted: this.props.isFormSubmitted(),
        isInvalid: this.props.showRequired() || this.props.showError(),
      });
    }
  }

  doClose = () => {
    this.setState({
      isInvalid: this.props.showRequired() || this.props.showError(),
    });
  };

  changeValue = select => {
    this.selector.current.value = select;
    this.props.setValue(select)

    this.setState({
      label: (this.getSelectedItem(select.value)),
      isInvalid: this.props.showRequired() || this.props.showError(),
    });

    this.forceUpdate()

    if (this.props.onChange) this.props.onChange(this.props.name, select.value);

  };

  getSelectedItem(value) {
    if (value === '') {
      return '';
    } else {
      let opt = this.props.options.filter(opt => opt.value === value)
      return opt.length ? opt[0].text : ''
    }
  }

  render() {
    const errorMessage = this.props.getErrorMessage();

    return (
      <div className={`fui-select ${this.props.className ? this.props.className : ''}`}>
        <select name={this.props.name} ref={this.selector} disabled={this.props.disabled} defaultValue={this.props.getValue() || ''}>
          {this.props.options.map((opt, i) => (
            <option key={`fui-select-${i}`} value={opt.value}>
              {opt.text}
            </option>
          ))}
        </select>
        <Dropdown
          id={this.props.name}
          className="is-button"
          label={this.state.label}
          list={this.props.options}
          onChange={this.changeValue}
          arrow={true}
          close={this.doClose}
        />
        {this.state.isInvalid ? <span className='error-message'>{errorMessage}</span> : ''}
      </div>
    );
  }
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default withFormsy(Select);
