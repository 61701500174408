import { isMobile } from './device-utils';

const adTracking = (type, result) => {
  let arg1 = type || 'na';
  let arg2 = result.scpID || 'na';
  let arg3 = result.activityName || 'na';
  let arg4 = result.alt || result.image.alt || 'na';
  let arg5 = isMobile() ? 'isMobile' : 'isWeb';
  if (typeof window._alTrackImpressions !== 'undefined' && typeof window._alTrackImpressions === 'function') {
    window._alTrackImpressions(arg1, arg2, arg3, arg4, arg5);
  } else {
    console.log(arg1);
    console.log(arg2);
    console.log(arg3);
    console.log(arg4);
    console.log(arg5);
    return;
  }
};

const adClickTracking = (type, result) => {
  let arg1 = type || 'na';
  let arg2 = result.scpID || 'na';
  let arg3 = result.activityName || 'na';
  let arg4 = result.alt || result.image.alt || 'na';
  let arg5 = isMobile() ? 'isMobile' : 'isWeb';
  if (typeof window._alTrackClick !== 'undefined' && typeof window._alTrackClick === 'function') {
    window._alTrackClick(arg1, arg2, arg3, arg4, arg5);
  } else {
    console.log(arg1);
    console.log(arg2);
    console.log(arg3);
    console.log(arg4);
    console.log(arg5);
    return;
  }
};

export { adTracking, adClickTracking };
